import React from "react";
import {
    Button,
    FormGroup,
    Modal,
    Label,
    Col,
    Input,
    Row,
    Card,
    Form,
} from "reactstrap";
import CircularProgress from '@material-ui/core/CircularProgress';
import DataTable from 'react-data-table-component';
import Checkbox from '@material-ui/core/Checkbox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import ReactBSAlert from "react-bootstrap-sweetalert";
import axios from 'axios';

interface IModalRepresentanteProps {
    cifEmpresa: string;
    companyId: string;
    isOpen: boolean;
    toggle: any;
    representante: Function;
    //    generateComparative?: Function;
}

interface IModalRepresentanteState {
    cups: string,
    //representanteSel:any[],
    user: any,
    sip: any,
    cifEmpresa: string,
    representantes: any[],
    representanteSeleccionado: any[],
    alert: any
}
const sortIcon = <ArrowDownward />;

const columnsDatosRepresentante = [

    { selector: row => row.nombreRepresentante, name: 'NOMBRE', sortable: true, width: '150px', format: (row => capitalize(row.nombreRepresentante)) },
    { selector: row => row.apellido1Representante, name: 'APELLIDO 1', sortable: true, width: '180px', center: true, format: (row => capitalize(row.apellido1Representante)) },
    { selector: row => row.apellido2Representante, name: 'APELLIDO 2', sortable: true, width: '180px', center: true, format: (row => capitalize(row.apellido2Representante)) },
    { selector: row => row.NIFRepresentante, name: 'CIF', sortable: true, width: '100px', center: true, format: (row => capitalize(row.NIFRepresentante)) },
    { selector: row => row.CargoRepresentante, name: 'CARGO', sortable: true, width: '100px', center: true, format: (row => capitalize(row.CargoRepresentante)) },
    { selector: row => row.telefonoRepresentante, name: 'TELEFONO', width: '100px', sortable: true, center: true, format: (row => capitalize(row.telefonoRepresentante)) }
];

const capitalize = (s) => {
    if (typeof s !== 'string') return ''
    return s.toLowerCase().charAt(0).toUpperCase() + s.slice(1)
}

const customStyles = {
    headRow: {
        style: {
            border: 'none',

        },
    },
    headCells: {
        style: {
            color: '#202124',
            fontSize: '12px',
            fontWeight: 'bold',
            wordBreak: 'break-word',
            paddingLeft: '8px',
            paddingRight: '8px',
            minWidth: '55px'

        },
    },
    cells: {
        style: {
            paddingLeft: '8px',
            paddingRight: '8px',
            minWidth: '55px',
            wordBreak: 'break-word',
            fontFamily: '"Open Sans", sans-serif;'
        },
    },
    rows: {
        highlightOnHoverStyle: {
            backgroundColor: 'rgb(230, 250, 250)',
            borderBottomColor: '#FFFFFF',
            borderRadius: '25px',
            outline: '1px solid #FFFFFF',
            fontSize: '12px',
        },
    },
    pagination: {
        style: {
            border: 'none',
        },
    },
};


const Circular = () => (
    // we need to add some padding to circular progress to keep it from activating our scrollbar
    <div style={{ padding: '24px' }}>
        <CircularProgress size={75} />
    </div>
);


export default class ContratoRepresentanteFreeModal extends React.Component<IModalRepresentanteProps, IModalRepresentanteState> {
    public formComparativeRef: any = null;
    public formSaveComparativeRef: any = null;
    public state: IModalRepresentanteState = {
        // --- Data
        cups: "",
        user: {},
        sip: {},
        cifEmpresa: "",
        representantes: [],
        representanteSeleccionado: [],
        alert: null
    };

    componentWillReceiveProps(newProps) {
        this.setState({ cifEmpresa: newProps.cifEmpresa });
        console.log('VALOR DE CIFEMPRESA')
        console.log('ESTOY EN COMPONENTEWILLRECEIVEPROPS')
    }

    componentDidMount() {
        console.log('ESTOY EN COMPONENTdidMount')
        this.formComparativeRef = React.createRef();
        this.fetchItems();
    }

    hideAlert =  ()=> {
        this.setState({
            alert: null
        });
    };

    warningAlert =  ()=> {
        this.setState({
            alert: (
                <ReactBSAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title= "Representante No seleccionado"
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="warning"
                    confirmBtnText="Ok"
                    btnSize=""
                >
                    Debe selecccionar un representante para el contrato
         </ReactBSAlert>
            )
        });
    };

    warningAlertDB =  ()=>{
        this.setState({
            alert: (
                <ReactBSAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Error en la base de datos"
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="warning"
                    confirmBtnText="Ok"
                    btnSize=""
                >
                    Existe un error en la Base de datos.
         </ReactBSAlert>
            )
        });
    };



    fetchItems = async () => {

        const parsed = await JSON.parse(await localStorage.getItem("user")!);
        const userId = parsed.id;
        const companyIdUser = Number(parsed.companyId);
        const empresa = this.props.cifEmpresa

        var dataFilters = {
            'idCompany': companyIdUser,
            'cups': "",
            'Usuario': "",
            'NIFRepresentante': "",
            'idContrato': ""
        };

        const valores = JSON.stringify(dataFilters)
        const headers = {
            'Authorization': 'Bearer my-token',
            'My-Custom-Header': 'foobar',
            'x-api-key': '$2y$10$bcT1Ukm4V/6/z5GvitZv0unu8I91mpixuwUO6z5CyEGev9DDDY28W'
        };

        let direccionFile = "https://datapi.psgestion.es/representante/listado?id=" + companyIdUser + "&user=" + userId;

        await axios.get(direccionFile, { headers, params: { valores } }).
            then(result => {
                console.log('resultado del listados')
                console.log(result.data.rows)
                let data = result.data.rows
                this.setState({ representantes: data })

            }).catch((error) => {
                console.log('Failure')
                this.warningAlertDB()
            });

    };


    handleChange = state => {
        // eslint-disable-next-line no-console
        console.log('state', state.selectedRows[0]);
        console.log(state.selectedCount)

        var contrato = '';


        console.log('VALOR DE this.state', this.state)    //
        if (state.selectedCount === 1) {
            var existeRepre = ''


            this.setState({ representanteSeleccionado: state.selectedRows[0] });

        }
    }










    representante = async (event) => {
        if (this.state.representanteSeleccionado) {
            let seleccion = this.state.representanteSeleccionado;
            console.log(this.props)
            this.props.representante({
                representante: seleccion
            });
            this.props.toggle();

            return;
        }
this.warningAlert  ()

    }



    render() {


        //console.log(this.props)

        return (
            <>
                <Modal
                    size="lg"
                    className="modal-dialog-centered modal-sip-comparative-free"
                    isOpen={this.props.isOpen}
                    toggle={this.props.toggle}>
                    <div className="modal-body">
                        <Form innerRef={this.formComparativeRef}>
                            <Row>
                                <Card className="shadow">
                                    <DataTable
                                        title="REPRESENTANTES"
                                        columns={columnsDatosRepresentante}
                                        data={this.state.representantes}
                                        selectableRows
                                        selectableRowsComponent={Checkbox} // Pass the function only
                                        selectableRowsComponentProps={{ inkdisabled: "true" }} // optionally, pass Material Ui supported props down to our custom checkbox
                                        selectableRowsSingle
                                        sortIcon={sortIcon} // use a material icon for our sort icon. rdt will rotate the icon 180 degrees for you           fixedHeader = {true}
                                        noDataComponent={"Sin datos para mostrar"}
                                        pagination
                                        paginationPerPage={10}
                                        paginationRowsPerPageOptions={[10, 15, 20, 25, 30, 40]}
                                        onSelectedRowsChange={this.handleChange}
                                        highlightOnHover
                                        striped
                                        pointerOnHover
                                        selectableRowsHighlight
                                        customStyles={customStyles}
                                        contextMessage={{ singular: 'Representante', plural: 'Representantes', message: 'seleccionado' }}
                                        subHeader
                                        progressComponent={<Circular />}
                                    />
                                </Card>
                            </Row>

                        </Form>

                    </div>
{this.state.alert}
                    <div className="modal-footer">
                        <Button
                            onClick={this.props.toggle}
                            color="secondary"
                            data-dismiss="modal"
                            type="button">
                            Cerrar
                        </Button>
                        <Button onClick={this.representante} color="primary" type="button">
                            Guardar Representante
                        </Button>
                    </div>
                </Modal>
            </>
        );
    }
}
