import React from "react";
import classnames from "classnames";
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import LoadingOverlay from "react-loading-overlay";
import moment from "moment";
import { withApollo } from "react-apollo";

// core components
import SupplyInformation from "../../../components/Cards/SupplyInformation";
import RateInformation from "../../../components/Cards/RateInformation";
import Header from "../../../components/Headers/Header";
import PriceAtrTable from "../../../components/PriceAtr/PriceAtrTable";
import SipsTable from "../../../components/Sips/SipsTable";
import SaveModal from "./SaveModal";
import { LIST_PRICEATR } from "../../../queries/price-atr/price-atr.graphql";
import { VIEW_SIPS } from "../../../queries/sips/sips.graphql";
import { LIST_SIPS_CONSUMOS } from "../../../queries/sips-consumo/sip-consumo";
import {
  CREATE_SIPS_COMPARATIVAS,
  LIST_SIPS_COMPARATIVAS_BY_CUP,
} from "../../../queries/sips-comparativa/sip-comparativa";

interface PriceFields {
  fechaPrecio: string,
  precioP1: string,
  precioP2?: string,
  precioP3?: string,
  precioP4?: string,
  precioP5?: string,
  precioP6?: string,
}
const priceFields: PriceFields = {
  fechaPrecio: "Fecha",
  precioP1: "Precio P1",
  precioP2: "Precio P2",
  precioP3: "Precio P3",
  precioP4: "Precio P4",
  precioP5: "Precio P5",
  precioP6: "Precio P6",
};
interface ConsumptionFields {
  fechaFinMesConsumo: string,
  consumoEnergiaActivaEnWhP1?: string,
  consumoEnergiaActivaEnWhP2?: string,
  consumoEnergiaActivaEnWhP3?: string,
  consumoEnergiaActivaEnWhP4?: string,
  consumoEnergiaActivaEnWhP5?: string,
  consumoEnergiaActivaEnWhP6?: string,
}
const consumptionsFields: ConsumptionFields = {
  fechaFinMesConsumo: "Fecha",
  consumoEnergiaActivaEnWhP1: "Energia Activa kWhP1",
  consumoEnergiaActivaEnWhP2: "Energia Activa kWhP2",
  consumoEnergiaActivaEnWhP3: "Energia Activa kWhP3",
  consumoEnergiaActivaEnWhP4: "Energia Activa kWhP4",
  consumoEnergiaActivaEnWhP5: "Energia Activa kWhP5",
  consumoEnergiaActivaEnWhP6: "Energia Activa kWhP6",
};

const consumptionsExpandableFields = {};

interface IComparativeIndexState {
  cups: string;
  user: any;
  sip: any;
  consumptionsFields: any;
  prices: any[];
  rates: any[];
  consumptionsFiltered: any[];
  comparatives: any[];
  isLoading: boolean;
  tabSelected: number;
  rateTabSelected: number;
  resume: any;
  inputs: {
    fee: string;
    prsch: string;
  };
  consumptions: any[];
  origininalRenderTabsState: {
    resume: any;
    inputs: {
      fee: string;
      prsch: string;
    };
    consumptions: any[];
  };
  saveModalOpened: boolean;
}

class SipComparativeIndex extends React.Component<any, any> {
  public formComparativeRef: any = null;
  public formSaveComparativeRef: any = null;
  public state: IComparativeIndexState = {
    cups: "",
    user: {},
    sip: {},
    consumptionsFields: {},
    prices: [],
    rates: [],
    consumptionsFiltered: [],
    comparatives: [],
    isLoading: false,
    tabSelected: 0,
    rateTabSelected: 0,
    resume: {},
    inputs: {
      fee: "0",
      prsch: "0",
    },
    consumptions: [],
    origininalRenderTabsState: {
      inputs: {
        fee: "0",
        prsch: "0",
      },
      resume: {},
      consumptions: [],
    },
    saveModalOpened: false,
  };

  componentDidMount() {
    const {
      match: { params },
    } = this.props;

    const user = localStorage.getItem("user");
    if (user) {
      const parsed = JSON.parse(user);
      this.setState({ user: parsed });
    }

    this.setState(
      {
        cups: params.cups,
        consumptionsFields: consumptionsFields,
        isLoading: true,
      },
      this.fetchSip
    );
    this.formComparativeRef = React.createRef();
    this.fetchComparatives(params.cups);
  }

  toggleNavs = (event, state, index) => {
    event.preventDefault();

    this.setState({
      [state]: index,
    });
  };

  toggleModal = (event, state) => {
    event.preventDefault();

    this.setState({
      [state]: !this.state[state],
    });
  };

  fetchComparatives = async (cups) => {
    await this.props.client
      .query({
        query: LIST_SIPS_COMPARATIVAS_BY_CUP,
        fetchPolicy: "no-cache",
        variables: { cups: cups },
      })
      .then((result) => {
        let data = result.data.comparativasByCups;
        console.log("Comparativas cargadas :::>", data);
        this.setState({ comparatives: data });
      });
  };

  fetchSip = async () => {
    await this.props.client
      .query({
        query: VIEW_SIPS,
        variables: { cups: this.state.cups },
      })
      .then((result) => {
        const sip = result.data.viewSips[0];
        this.setState({
          sip: sip,
          modalInputs: {
            idComercializadora: sip.codigoComercializadora,
            nombreOferta: "",
            correoContacto: "",
            esAprobada: false,
          },
        });
        this.fetchItems();
      });
  };

  fetchItems = async () => {
    const user: any = this.state.user;
    const codigoTarifaATR: any = this.state.sip;
    await this.props.client
      .query({
        query: LIST_PRICEATR,
        variables: {
          companyId: user.companyId,
          codigoTarifaATREnVigor: codigoTarifaATR.codigoTarifaATREnVigor,
        },
      })
      .then((result) => {
        const data = result.data.listPricesByCompany;
        const rates = data.map((value) => {
          return {
            id: value.codigoTarifaATREnVigor,
            desc: value.tarifaATR,
            periods: value.NumeroPeriodos,
          };
        });
        const uniqueRates = rates.filter(
          (obj) => !rates[obj.id] && (rates[obj.id] = true)
        );
        const prices: any = [];
        const resume: any = {};
        data.map((value) => {
          if (!prices.hasOwnProperty(value.codigoTarifaATREnVigor)) {
            prices[value.codigoTarifaATREnVigor] = [];
          }
          if (!resume.hasOwnProperty(value.codigoTarifaATREnVigor)) {
            resume[value.codigoTarifaATREnVigor] = {
              previousYear: {
                precioP1: 0,
                precioP2: 0,
                precioP3: 0,
                precioP4: 0,
                precioP5: 0,
                precioP6: 0,
                numMonths: 0,
              },
              last12Months: {
                precioP1: 0,
                precioP2: 0,
                precioP3: 0,
                precioP4: 0,
                precioP5: 0,
                precioP6: 0,
                numMonths: 0,
              },
            };
          }

          const previousYear = new Date().getFullYear() - 1;
          const firstOfPreviousYear = new Date(
            previousYear + "-01-01 00:00:00"
          ).getTime();
          const previous12Months = new Date(
            previousYear + "-" + (new Date().getMonth() + 1) + "-01 00:00:00"
          ).getTime();

          const dataDate = new Date(value.fechaPrecio);
          const dataTimestamp = dataDate.getTime();
          const dataYear = dataDate.getFullYear();
          if (dataTimestamp >= firstOfPreviousYear) {
            if (dataYear === previousYear) {
              resume[value.codigoTarifaATREnVigor].previousYear = {
                precioP1:
                  resume[value.codigoTarifaATREnVigor].previousYear.precioP1 +
                  parseFloat(value.precioP1),
                precioP2:
                  resume[value.codigoTarifaATREnVigor].previousYear.precioP2 +
                  parseFloat(value.precioP2),
                precioP3:
                  resume[value.codigoTarifaATREnVigor].previousYear.precioP3 +
                  parseFloat(value.precioP3),
                precioP4:
                  resume[value.codigoTarifaATREnVigor].previousYear.precioP4 +
                  parseFloat(value.precioP4),
                precioP5:
                  resume[value.codigoTarifaATREnVigor].previousYear.precioP5 +
                  parseFloat(value.precioP5),
                precioP6:
                  resume[value.codigoTarifaATREnVigor].previousYear.precioP6 +
                  parseFloat(value.precioP6),
                numMonths:
                  resume[value.codigoTarifaATREnVigor].previousYear.numMonths +
                  1,
              };
            }
            if (dataTimestamp >= previous12Months) {
              resume[value.codigoTarifaATREnVigor].last12Months = {
                precioP1:
                  resume[value.codigoTarifaATREnVigor].last12Months.precioP1 +
                  parseFloat(value.precioP1),
                precioP2:
                  resume[value.codigoTarifaATREnVigor].last12Months.precioP2 +
                  parseFloat(value.precioP2),
                precioP3:
                  resume[value.codigoTarifaATREnVigor].last12Months.precioP3 +
                  parseFloat(value.precioP3),
                precioP4:
                  resume[value.codigoTarifaATREnVigor].last12Months.precioP4 +
                  parseFloat(value.precioP4),
                precioP5:
                  resume[value.codigoTarifaATREnVigor].last12Months.precioP5 +
                  parseFloat(value.precioP5),
                precioP6:
                  resume[value.codigoTarifaATREnVigor].last12Months.precioP6 +
                  parseFloat(value.precioP6),
                numMonths:
                  resume[value.codigoTarifaATREnVigor].last12Months.numMonths +
                  1,
              };
            }
            prices[value.codigoTarifaATREnVigor].push(value);
          }

          return true;
        });
        this.setState({
          rates: uniqueRates,
          prices: prices,
          resume: resume,
          origininalRenderTabsState: {
            ...this.state.origininalRenderTabsState,
            resume: resume,
          },
        });
        this.fetchConsumption();
      });
  };

  fetchConsumption = async () => {
    await this.props.client
      .query({
        query: LIST_SIPS_CONSUMOS,
        variables: { cups: this.state.cups },
      })
      .then((result) => {
        const data = result.data.listSipsConsumos;
        this.setState({
          consumptions: data,
          origininalRenderTabsState: {
            ...this.state.origininalRenderTabsState,
            consumptions: data,
          },
        });
        return this.filterConsumption(data).then(data => {
          return data.map((value) => {
            return Object.keys(value).reduce((obj, key) => {
              let val = value[key];
              if (key !== "fechaFinMesConsumo") {
                val = val + ' Kw/h';
              }

              obj[key] = val;

              return obj;
            }, {});
          });
        });
      })
      .then((data) => {
        const slicedFields = this.getConsumptionFieldsSliced();
        this.setState({
          consumptionsFields: slicedFields,
          consumptionsFiltered: data,
          isLoading: false,
        });
      });
  };

  getConsumptionFieldsSliced() {
    const sip: any = this.state.sip;
    const rate: any = this.state.rates.find(
      (rate: any) => rate.id === sip.codigoTarifaATREnVigor
    );

    if (rate) {
      switch (this.state.sip.codigoTarifaATREnVigor) {
        case '004':
        case '006':
          delete consumptionsFields.consumoEnergiaActivaEnWhP2;
          break;
      }
      return Object.keys(consumptionsFields)
        .slice(0, parseInt(rate.periods) + 1)
        .reduce((result, key) => {
          result[key] = consumptionsFields[key];

          return result;
        }, {});
    } else {
      return {};
    }
  }

  filterConsumption = async (data) => {
    const slicedFields = this.getConsumptionFieldsSliced();
    return await data
      .filter((value) => {
        const previousYear = new Date().getFullYear() - 1;
        const dataTimestamp = new Date(value.fechaFinMesConsumo).getTime();
        const firstOfPreviousYear = new Date(
          previousYear + "-01-01 00:00:00"
        ).getTime();

        return dataTimestamp >= firstOfPreviousYear ? true : false;
      })
      .map((value) => {
        const sliced = Object.keys(value)
          .filter((key) => Object.keys(slicedFields).includes(key))
          .reduce((obj, key) => {
            let val = value[key];
            if (key === "fechaFinMesConsumo") {
              const dateValue = new Date(val);

              val = moment
                .parseZone(dateValue.toISOString().split("T")[0])
                .format("DD-MM-YYYY");
            } else {
              val = parseFloat(val);
            }

            obj[key] = val;

            return obj;
          }, {});

        return sliced;
      });
  };

  beforeRenderItem = (index, item) => {
    if (index.includes("fechaPrecio") && item.value) {
      const dateValue = new Date(item.value);
      const month = dateValue.getMonth() + 1;
      item.value =
        (month <= 9 ? "0" + month : month) + "-" + dateValue.getFullYear();
    }
    if (index.includes("precio") && item.value) {
      item.value = item.value + "€";
    }
    return true;
  };

  onChangeField = async (event) => {
    const name = event.target.name;
    let value = event.target.value;
    const inputs = this.state.inputs;
    inputs[name] = value;
    if (value === "" || value === null) {
      inputs[name] = 0;
    }
    this.setState({ inputs });
  };

  onSave = async (modalData) => {
    const now = new Date().toISOString();
    const user: any = this.state.user;
    const sip: any = this.state.sip;

    const data: any = {
      ...{
        userId: Number(user.id),
        companyId: Number(user.companyId),
        nombreOferta: "",
        cups: this.state.cups,
        fechaPropuesta: moment
          .parseZone(now.split("T")[0])
          .format("YYYY-MM-DD"),
        idComercializadora: sip.codigoComercializadora,
        nombreComercializadora: sip.comercializadora,
        codigoPostalPS: sip.codigoPostalPS,
        codigoMunicipioPS: sip.codigoMunicipioPS,
        nombreMunicipioPS: sip.nombreMunicipioPS,
        codigoProvinciaPS: sip.codigoProvinciaPS,
        nombreProvinciaPS: sip.nombreProvinciaPS,
        codigoAutonomiaPS: sip.codigoAutonomiaPS,
        nombreAutonomiaPS: sip.nombreAutonomiaPS,
        esAprobada: false,
        datosPropuesta: JSON.stringify({
          fee: this.state.inputs.fee,
          prsch: this.state.inputs.prsch,
          resume: this.state.resume,
          consumptions: this.state.consumptions,
        }),
        correoContacto: "",
      },
      ...modalData,
    };

    await this.props.client
      .mutate({
        mutation: CREATE_SIPS_COMPARATIVAS,
        variables: { data: data },
      })
      .then(() => {
        console.log("recarga...");
        this.fetchComparatives(this.state.cups);
        this.forceUpdate();
      });
  };

  comparativeSelected = async (event) => {
    let id = event.target.value;
    let comparative: any = this.state.comparatives.filter(
      (item: any) => item.id === id
    )[0];
    if (comparative) {
      let { fee, prsch, resume, consumptions } = JSON.parse(
        comparative.datosPropuesta
      );
      this.setState({
        inputs: { fee: fee, prsch: prsch },
        resume: resume,
        consumptions: consumptions,
      });
    } else {
      this.setState({
        inputs: {
          fee: this.state.origininalRenderTabsState.inputs.fee,
          prsch: this.state.origininalRenderTabsState.inputs.prsch,
        },
        resume: this.state.origininalRenderTabsState.resume,
        consumptions: this.state.origininalRenderTabsState.consumptions,
      });
    }
  };

  renderRates() {
    if (this.state.rates.length) {
      const tabs = this.renderTabs();
      const tabsContent = this.renderRatesTabsContent();
      return <>
        <Nav
          className="nav-fill flex-column flex-md-row"
          id="rate-tabs"
          tabs
          role="tablist"
        >
          {tabs}
        </Nav>
        <TabContent
          activeTab={"tabs" + this.state.rateTabSelected}
        >
          {tabsContent}
        </TabContent>
      </>
    } else {
      return <p>Actualmente no dispone de datos de costes.</p>
    }
  }

  renderConsumptions() {
    if (this.state.rates.length) {
      const tabs = this.renderTabs();
      const tabsContent = this.renderConsumptionsTabsContent();
      return <>
        <Nav
          className="nav-fill flex-column flex-md-row"
          id="rate-tabs"
          tabs
          role="tablist"
        >
          {tabs}
        </Nav>
        <TabContent
          activeTab={"tabs" + this.state.rateTabSelected}
        >
          {tabsContent}
        </TabContent>
      </>
    } else {
      return <p>Actualmente no dispone de datos de costes.</p>
    }
  }

  renderTabs() {
    let defaultTab = (
      <NavItem key="0">
        <NavLink
          key="000"
          aria-selected={this.state.rateTabSelected === 0}
          className={classnames("mb-sm-3 mb-md-0 ", {
            active: this.state.rateTabSelected === 0,
          })}
          onClick={(e) => this.toggleNavs(e, "rateTabSelected", 0)}
          href="#"
          role="tab"
        >
          Resumen
        </NavLink>
      </NavItem>
    );

    let ratesTabs = this.state.rates.map((rate: any, index) => {
      return (
        <NavItem key={"r" + index}>
          <NavLink
            key={rate.id}
            aria-selected={this.state.rateTabSelected === rate.id}
            className={classnames("mb-sm-3 mb-md-0", {
              active: this.state.rateTabSelected === rate.id,
            })}
            onClick={(e) => this.toggleNavs(e, "rateTabSelected", rate.id)}
            href="#"
            role="tab"
          >
            {rate.desc}
          </NavLink>
        </NavItem>
      );
    });

    ratesTabs.unshift(defaultTab);

    return ratesTabs;
  }

  renderRatesTabsContent() {
    try {
      const defaultTabContent = this.state.rates.map((rate: any, index) => {
        switch (this.state.sip.codigoTarifaATREnVigor) {
          case '004':
          case '006':
            delete priceFields.precioP2;
            break;
        }
        const slicedFields = Object.keys(priceFields)
          .slice(1, parseInt(rate.periods) + 1)
          .reduce((result, key) => {
            result[key] = priceFields[key];

            return result;
          }, {});
        const resume: any = this.state.resume[rate.id];
        const previousYear: any = resume.previousYear;
        const lisPrevious = [previousYear].map((value) => {
          const sliced = Object.keys(value)
            .filter((key) => Object.keys(slicedFields).includes(key))
            .reduce((obj, key) => {
              obj[key] = value[key];
              return obj;
            }, {});

          return Object.keys(sliced).map((key, index) => {
            const fee = parseFloat(this.state.inputs.fee);
            const prsch = parseFloat(this.state.inputs.prsch);
            const price =
              parseFloat((sliced[key] / value.numMonths).toFixed(6)) +
              fee / 1000 +
              prsch;

            return (
              <li key={"lp" + index}>
                {priceFields[key]}: {price}€
              </li>
            );
          });
        });
        const ulPrevious = <ul>{lisPrevious}</ul>;

        const last12Months: any = resume.last12Months;
        const lisLast12Months = [last12Months].map((value) => {
          const sliced = Object.keys(value)
            .filter((key) => Object.keys(slicedFields).includes(key))
            .reduce((obj, key) => {
              obj[key] = value[key];
              return obj;
            }, {});

          return Object.keys(sliced).map((key, index) => {
            const fee = parseFloat(this.state.inputs.fee);
            const prsch = parseFloat(this.state.inputs.prsch);
            const price = (
              sliced[key] / value.numMonths +
              fee / 1000 +
              prsch
            ).toFixed(6);

            return (
              <li key={"ll" + index}>
                {priceFields[key]}: {price}€
              </li>
            );
          });
        });
        const ulLast12Months = <ul>{lisLast12Months}</ul>;

        return (
          <Card
            key={"c" + index}
            className="col-sm-12 col-md-6 col-lg-4 col-xl-3"
          >
            <CardBody>
              <CardTitle>{rate.desc}</CardTitle>
              <div className="card-text">
                2019:
                <br />
                {ulPrevious}
                <br />
                Ultimos 12 Meses.:
                <br />
                {ulLast12Months}
              </div>
            </CardBody>
          </Card>
        );
      });

      const defaultTab = (
        <TabPane key="default" tabId="tabs0">
          <div className="row card-stats">{defaultTabContent}</div>
        </TabPane>
      );

      const tabsContent = this.state.rates.map((rate: any, index) => {
        switch (this.state.sip.codigoTarifaATREnVigor) {
          case '004':
          case '006':
            delete priceFields.precioP2;
            break;
        }
        const slicedFields = Object.keys(priceFields)
          .slice(0, parseInt(rate.periods) + 1)
          .reduce((result, key) => {
            result[key] = priceFields[key];

            return result;
          }, {});
        const data: any = this.state.prices[rate.id];
        const items = data.map((value) => {
          return Object.keys(value)
            .filter((key) => Object.keys(slicedFields).includes(key))
            .reduce((obj, key) => {
              let val = value[key];
              if (key !== "fechaPrecio") {
                const fee = parseFloat(this.state.inputs.fee);
                const prsch = parseFloat(this.state.inputs.prsch);

                val = (parseFloat(val) + fee / 1000 + prsch).toFixed(6);
              }
              obj[key] = val;

              return obj;
            }, {});
        });

        return (
          <TabPane key={"t" + index} tabId={"tabs" + rate.id}>
            <PriceAtrTable
              id={rate.id}
              key={rate.id}
              fields={slicedFields}
              items={items}
              beforeRenderItem={this.beforeRenderItem}
            ></PriceAtrTable>
          </TabPane>
        );
      });

      tabsContent.unshift(defaultTab);

      return tabsContent;
    } catch (e) {
      console.error("Data can't be rendered");
    }
  }

  renderConsumptionsTabsContent() {
    const defaultTab = (
      <TabPane key="default" tabId="tabs0">
        <div className="row card-stats">
          <SipsTable
            hideViewMoreButton={true}
            items={this.state.consumptionsFiltered}
            fields={this.state.consumptionsFields}
            expandableFields={consumptionsExpandableFields}>
          </SipsTable>
        </div>
      </TabPane>
    );

    const tabsContent = this.state.rates.map((rate: any, index) => {
      switch (this.state.sip.codigoTarifaATREnVigor) {
        case '004':
        case '006':
          delete priceFields.precioP2;
          break;
      }
      const slicedFields = Object.keys(priceFields)
        .slice(0, parseInt(rate.periods) + 1)
        .reduce((result, key) => {
          result[key] = priceFields[key];

          return result;
        }, {});
      const data: any = this.state.prices[rate.id];
      const consumptions = this.state.consumptions;
      const items = data.map((value) => {
        const date = moment(value.fechaPrecio);
        const result = Object.keys(value)
          .filter((key) => Object.keys(slicedFields).includes(key))
          .reduce((obj, key) => {
            let val = value[key];
            if (key !== "fechaPrecio") {
              const fee = parseFloat(this.state.inputs.fee) / 1000;
              const prsch = parseFloat(this.state.inputs.prsch) / 1000;
              const consumption = consumptions.find((c) => c.year === date.year() && c.month === date.month());
              const str = `consumoEnergiaActivaEnWh${key.replace('precio', '')}`;

              val = consumption ? ((consumption[str] * parseFloat(val)) + fee + prsch).toFixed(2) : null;
            }
            obj[key] = val;

            return obj;
          }, {});

        return result;
      });

      return (
        <TabPane key={"t" + index} tabId={"tabs" + rate.id}>
          <PriceAtrTable
            id={rate.id}
            key={rate.id}
            fields={slicedFields}
            items={items}
            beforeRenderItem={this.beforeRenderItem}
          ></PriceAtrTable>
        </TabPane>
      );
    });

    tabsContent.unshift(defaultTab);

    return tabsContent;
  }

  render() {
    const ratesHtml = this.renderRates();
    const consumptionsHtml = this.renderConsumptions();

    return (
      <>
        <Header title="Comparativa de contrato indexada" />
        <Container id="comparative" className=" mt--7" fluid>
          <Row className="card-deck">
            <SupplyInformation
              isLoading={this.state.isLoading}
              sip={this.state.sip}
              cardClass="col-6"
            ></SupplyInformation>
            <RateInformation
              isLoading={this.state.isLoading}
              sip={this.state.sip}
              cardClass="col-6"
            ></RateInformation>
          </Row>
          <Row>
            <div className="col">
              <Card className="mt-3 shadow">
                <CardBody>
                  <div className="text-left">
                    <button
                      className="btn btn-sm btn-primary"
                      onClick={this.props.history.goBack}
                    >
                      Atrás
                    </button>
                  </div>
                  <CardTitle></CardTitle>
                  <Form innerRef={this.formComparativeRef}>
                    <div className="row d-flex">
                      <Col md="3" lg="2">
                        <FormGroup>
                          <Label className="form-control-label" htmlFor="fee">
                            FEE
                          </Label>
                          <InputGroup>
                            <Input
                              id="fee"
                              placeholder="0"
                              name="fee"
                              onChange={this.onChangeField}
                              value={this.state.inputs.fee}
                              type="text"
                              className="text-right"
                            />
                            <InputGroupAddon addonType="append">
                              <InputGroupText>€/MWh</InputGroupText>
                            </InputGroupAddon>
                          </InputGroup>
                        </FormGroup>
                      </Col>
                      <Col md="3" lg="2">
                        <FormGroup>
                          <Label className="form-control-label" htmlFor="prsch">
                            PRSCh
                          </Label>
                          <InputGroup>
                            <Input
                              id="prsch"
                              placeholder="0"
                              name="prsch"
                              onChange={this.onChangeField}
                              value={this.state.inputs.prsch}
                              type="text"
                              className="text-right"
                            />
                            <InputGroupAddon addonType="append">
                              <InputGroupText>€/MWh</InputGroupText>
                            </InputGroupAddon>
                          </InputGroup>
                        </FormGroup>
                      </Col>
                      <div className="col-md-6 col-lg-4 ml-auto">
                        <FormGroup>
                          <Label
                            className="form-control-label"
                            htmlFor="idComparativa"
                          >
                            Propuestas disponibles
                          </Label>
                          <Input
                            id="comparativaId"
                            type="select"
                            onChange={this.comparativeSelected}
                          >
                            <option value="none">Cargar Propuesta</option>
                            {this.state.comparatives.map((item: any, index) => (
                              <option value={item.id} key={index}>
                                {item.nombreOferta}
                              </option>
                            ))}
                          </Input>
                        </FormGroup>
                      </div>
                    </div>
                    <Row>
                      <Col md="3" lg="4">
                        <div className="text-right">
                          <button
                            className="btn btn-sm btn-primary"
                            onClick={(event) =>
                              this.toggleModal(event, "saveModalOpened")
                            }
                          >
                            Guardar
                          </button>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                  <br />
                  <Nav
                    className="nav-fill flex-column flex-md-row"
                    id="section-tabs"
                    tabs
                    role="tablist"
                  >
                    <NavItem key="section0">
                      <NavLink
                        key="rateSection"
                        aria-selected={this.state.tabSelected === 0}
                        className={classnames("mb-sm-3 mb-md-0", {
                          active: this.state.tabSelected === 0,
                        })}
                        onClick={(e) => this.toggleNavs(e, "tabSelected", 0)}
                        href="#"
                        role="tab"
                      >
                        Tarifas
                      </NavLink>
                    </NavItem>
                    <NavItem key="section1">
                      <NavLink
                        key="rateConsumption"
                        aria-selected={this.state.tabSelected === 1}
                        className={classnames("mb-sm-3 mb-md-0", {
                          active: this.state.tabSelected === 1,
                        })}
                        onClick={(e) => this.toggleNavs(e, "tabSelected", 1)}
                        href="#"
                        role="tab"
                      >
                        Consumos
                      </NavLink>
                    </NavItem>
                  </Nav>
                  <TabContent
                    activeTab={"sectionTabs" + this.state.tabSelected}
                  >
                    <TabPane key="sectionTabs0" tabId="sectionTabs0">
                      <LoadingOverlay
                        active={this.state.isLoading}
                        spinner
                        text="Cargando..."
                      >
                        <div className="nav-wrapper">
                          { ratesHtml }
                        </div>
                      </LoadingOverlay>
                    </TabPane>
                    <TabPane key="sectionTabs1" tabId="sectionTabs1">
                      <LoadingOverlay
                        active={this.state.isLoading}
                        spinner
                        text="Cargando..."
                      >
                      <div className="nav-wrapper">
                        { consumptionsHtml }
                      </div>
                      </LoadingOverlay>
                    </TabPane>
                  </TabContent>
                </CardBody>
              </Card>
            </div>
          </Row>
        </Container>

        <SaveModal
          isOpen={this.state.saveModalOpened}
          toggle={(event) => this.toggleModal(event, "saveModalOpened")}
          onSave={this.onSave}
          sip = {this.state.sip}
          ubi ={this.state.cups}
          nombrePropuesta={""}
          companyId={""}
          userId={""}
        ></SaveModal>
      </>
    );
  }
}

export default withApollo(SipComparativeIndex);
