import React from "react";
import Reflux from "reflux";
import { UserStore } from "../../store/UserStore";
// reactstrap components
import {
  Card,
  CardHeader,
  CardFooter,
  Container,
  Row,
  Col,
  Button,
//  FormGroup,
   Form,
    CardBody, ListGroup, ListGroupItem
} from "reactstrap";
import { Link } from 'react-router-dom';
import LoadingOverlay from "react-loading-overlay";
import moment from "moment";

import { Chart,
  Interval,
  Tooltip,
  //Line,
  Axis,
  Coordinate,
  //Point,
  registerTheme,
  //Legend,
  //getTheme,
  //G2,
  //Label,
  //View,
  //Guide,
  //Shape,
  //Facet,Util
} from 'bizcharts';
  import DataSet from "@antv/data-set";

// core components
import Header from "../../components/Headers/Header";
import SipsFilter from "../../components/Sips/SipsFilterEmpre";
//import SipsTable from "../../components/Sips/SipsTable";
import { withApollo } from "react-apollo";

import Pagination from "../../components/Pagination/Pagination";
import { LIST_BUSINESS, LIST_SIPS, EXPORT_SIPS } from "../../queries/sips/sips.graphql";
import { LIST_SIPS_UBICACION_CIF } from "../../queries/ubicacion/sip-ubicacion";
import Environment from "../../constants/environment";
import DataTable from 'react-data-table-component';
//import Checkbox from '@material-ui/core/Checkbox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import memoize from 'memoize-one';
//import CircularProgress from '@material-ui/core/CircularProgress';
//import Icon from '@material-ui/icons/Apps';


registerTheme('default', {
  defaultColor: '#505050',
  colors10: [ "#2ec7c9",
  "#b6a2de",
  "#5ab1ef",
  "#ffb980",
  "#d87a80",
  "#8d98b3",
  "#e5cf0d",
  "#97b552",
  "#95706d",
  "#dc69aa"],
  colors20: [            "#2ec7c9",
  "#b6a2de",
  "#5ab1ef",
  "#ffb980",
  "#d87a80",
  "#8d98b3",
  "#e5cf0d",
  "#97b552",
  "#95706d",
  "#dc69aa",
  "#07a2a4",
  "#9a7fd1",
  "#588dd5",
  "#f5994e",
  "#c05050",
  "#59678c",
  "#c9ab00",
  "#7eb00a",
  "#6f5553",
  "#c14089"],
  geometries: {
    interval: {
      rect: {
        active: { style: { stroke: '#5AD8A6', lineWidth: 1 } },
        inactive: { style: { fillOpacity: 0.3, strokeOpacity: 0.3 } },
selected: {},
}
    }
  }
})

const userFromStorage = localStorage.getItem("user");

const parsed = JSON.parse(userFromStorage||'{}');

const showIndexButtonCol = parsed.indexada;
const showFreeMarketButtonCol = parsed.mercadolibre;

const sortIcon = <ArrowDownward />;

const columns = memoize(clickHandler => [
{
// eslint-disable-next-line react/button-has-type
//cell: () => <button className="btn btn-primary btn-sm btn-circle" onClick={clickHandler}></button>,
//cell: row => <div><div style={{ fontWeight: 700 }}>{row.title}</div>{row.summary}</div>,

cell: row => <Link
  className="btn btn-primary btn-sm btn-circle"
  data-toggle="modal"
  to={{ pathname: "sip-details/" + row.cups }}
  id = "buscartooltip"
>
  <i className="pe-7s-search text-white"></i>
</Link>,

ignoreRowClick: true,
allowOverflow: true,
button: true,
name: 'INFO',
minWidth: '55px'

},
{
cell: row => <Link
  className="btn btn-light btn-sm btn-circle"
  data-toggle="modal"
  to={{ pathname: "sip-comparative-index/" + row.cups }}
   id = "indextooltip"
>
  <i className="pe-7s-date text-white"></i>
</Link>,

ignoreRowClick: true,
allowOverflow: true,
button: true,
omit: showIndexButtonCol,
name: 'INDEX',
minWidth: '55px'

},
{
cell: row => <Link
  className="btn btn-info btn-sm btn-circle"
  data-toggle="modal"
  to={{ pathname: "sip-comparative-free/" + row.cups }}
  id = "fijotooltip"
>
  <i className="pe-7s-calculator text-white"></i>
</Link>,

ignoreRowClick: true,
allowOverflow: true,
button: true,
omit: showFreeMarketButtonCol,
name: 'FIJO',
minWidth: '55px'

},


{
  selector: row => row['ProblemaIdString'],
  //cell: row => <Icon style={{ fill: '#43a047' }} />,
  name: 'P',
  sortable: true,
  width: '40px',
  center: true,
  conditionalCellStyles: [
    {
      when: row => row.ProblemaIdString === "",

      style: {
        backgroundColor: 'rgba(255, 255, 255, 0)',
        color: 'white',
        fontSize: '8px',
        width: '20px',
        borderRadius: '5px',
        '&:hover': {
          cursor: 'pointer',
        },
      },
    },

			{
				when: row => row.ProblemaIdString !== "",
				style: {
          backgroundImage: 'url(/error.png)',
          backgroundRepeat: 'no-repeat',
          backgroundSize: '30px',
          backgroundPosition: 'center',
          //height: "50vh",
					//backgroundColor: 'rgba(248, 148, 6, 0.9)',
					color: 'rgba(250, 250, 250, 0)',
          fontSize: '8px',
          //width: '48px',
          //width: `calc(100vw + 48px)`,
          borderRadius: '5px',
					'&:hover': {
						cursor: 'pointer',

					},
				},
			},
    ]


},


  { selector: row => row['cups'], name: 'CUPS',  width: '190px',		style: {
      color: '#202124',
      fontSize: '12px',
      fontWeight: 500,

    },},

  { selector: row => row['nombreCompletoTitular'], name: 'TITULAR', sortable: true, style: {
      color: 'rgba(0,0,0,.54)',
    },},
  { selector: row => row['CIF'], name: 'CIF', sortable: true,},
  { selector: row => row['nombreEmpresaDistribuidora'], name: 'DISTRIBUIDORA PRINCIPAL', sortable: true, center: true, wrap: true},
  { selector: row => row['empresaDistribuidora'], name: 'DISTRIBUIDORA', sortable: true, },
  { selector: row => row['comercializadora'], name: 'COMERCIALIZADORA',sortable: true, width: '210px', center: true, wrap: true },
  { selector: row => row['direccion_ps'], name: 'DIRECCION' },
  { selector: row => row['localidad_ps'], name: 'LOCALIDAD', sortable: true },
  { selector: row => row['codigoPostalPS'], name: 'CODIGO POSTAL', sortable: true, },
  { selector: row => row['nombreProvinciaPS'], name: 'PROVINCIA', sortable: true, },
  { selector: row => row['tarifaATR'], name: 'TARIFA', sortable: true, },
  { selector: row => row['kWhAnual'], name: 'kWh ANUAL', sortable: true,},
  { selector: row => row['fechaUltimoMovimientoContrato'], name: 'ULTIMO MOVIMIENTO' , sortable: true, format: (row => moment(row.fechaUltimoMovimientoContrato).isValid() ? moment(row.fechaUltimoMovimientoContrato).format('DD-MM-YYYY') : "")},
  { selector: row => row['fechaUltimoCambioComercializador'], name: 'ULTIMO CAMBIO COMERCIAL', sortable: true, format: (row => moment(row.fechaUltimoCambioComercializador).isValid() ? moment(row.fechaUltimoCambioComercializador).format('DD-MM-YYYY') : "")},


]);

const columnsDatosR1 = [
  { selector: row => row['Id'], name: 'ATR', sortable: true, width: '120px', style: {
      color: 'rgba(0,0,0,.54)',
    },},
  { selector: row => row['TotalContador'], name: 'Nº Contratos', sortable: true, width: '120px', center: true},
  { selector: row => row['TotalConsumoAnual'], name: 'T. Energía Contratos MWh', width: '210px', sortable: true, center: true, format: (row => Math.round(Number(row.TotalConsumoAnual)/1000))},
];

const columnsDatosR2 = [
  { selector: row => row['Id'], name: 'Comercializadora', sortable: true, width: '230px', style: {
      color: 'rgba(0,0,0,.54)',
    },},
  { selector: row => row['TotalContador'],name: 'Nº Contratos', sortable: true,width: '120px', center: true},
  { selector: row => row['TotalConsumoAnual'], name: 'T. Energía Contratos MWh', width: '210px', sortable: true, center: true, format: (row => Math.round(Number(row.TotalConsumoAnual)/1000)) },
];


const customStyles = {
	headRow: {
		style: {
			border: 'none',

		},
	},
	headCells: {
		style: {
			color: '#202124',
			fontSize: '12px',
      fontWeight: 'bold',
      wordBreak: 'break-word',
      paddingLeft: '8px',
      paddingRight: '8px',
      minWidth: '55px'

		},
	},
  cells: {
  style: {
    paddingLeft: '8px',
    paddingRight: '8px',
    minWidth: '55px',
    wordBreak: 'break-word',
    fontFamily: '"Open Sans", sans-serif;'
  },
},
	rows: {
		highlightOnHoverStyle: {
			backgroundColor: 'rgb(230, 250, 250)',
			borderBottomColor: '#FFFFFF',
			borderRadius: '25px',
			outline: '1px solid #FFFFFF',
      fontSize: '12px',
		},
	},
	pagination: {
		style: {
			border: 'none',
		},
	},
};



/*const cols = {
  TotalConsumoAnual: {
    formatter: val => {
      val = val * 100 + '%';
      return val;
    },
  },
};
*/
const scale = {
  TotalConsumoAnual: {
    min: 0
  }
}

class Empresa extends Reflux.Component<any, any> {
  lastFiltersEmpre = {};
  state = {
    UserStore:{},
    empresaAut:false,
    isLoading: false,
    isSearching: false,
    copiedText: null,
    currentPage: 0,
    pageSize: 0,
    pageCount: 0,
    count: 0,
    page: 1,
    limit: 200,
   paginatedData: {items:[{CIF:''}]},
   paginatedDataTemp: {items:[]},
    showIndexButton: false,
    showFreeMarketButton: false,
    sumOfEnergy: 0,
    sumOfEnergyLast: 0,
    cupsGroupedBydistri: [],
    cupsGroupedBydistriEnergy: [],
    cupsGroupedByATREnergy: [],
    cupsGroupedByComer: [],
    cupsGroupedByATR: [],
    cupsGroupedByProvincia: [],
    cupsGroupedByComercialEnergy :[],
    cupsGroupedByComercialEnergyCont:[],
    EnergiaTotalATRCont:[],
    datosEmpresa:{
      CIF: "",
CNAE: "",
Calle: "",
CodigoPostal: "",
Consolidada: "",
Ejercicio: "",
FormaJuridica: "",
IngExplota: "",
LiteralCNAE: "",
Localidad: "",
Marca: "",
Nombre: "",
Proveedor: "",
Provincia: "",
Telefono: "",
Web: ""

    }
  };


  constructor(props) {
    super(props);
    this.store = UserStore;
  }


  UNSAFE_componentWillReceiveProps (){

    console.log(this.state.UserStore['data'])

    if(Object.keys(this.state.paginatedData.items[0]).length > 2){
      console.log('ENTRA EN EL OBJETO y TIENE DATOS')
      console.log(this.state.paginatedData.items)
      const data2 = this.state.paginatedData.items
       console.log(this.state.UserStore['data'])
      this.setState({
            paginatedDataTemp: data2})

    } else {
      console.log('ENTRA EN EL OBJETO y no TIENE DATOS')
      console.log(this.state.paginatedData.items)
       console.log(this.state.UserStore['data'])
      const data2:any[] = [];}
  const usuario:any = JSON.parse(JSON.stringify(this.state.UserStore['data']))

  console.log('VALOR DE USUARIO ----------------->', usuario)


  //const showIndexButtonCol = usuario.indexada;
  //const showFreeMarketButtonCol = usuario.mercadolibre;
  const companyNameGlobal = usuario.company;
  const companyIdUser = usuario.companyId;

  const userGroupId = usuario.user_group_id
  const userId = usuario.id
  const empresaAut = usuario.empresa



  //  console.log('VALORES DE indexbutton  ',showIndexButtonCol )
  //const showFreeMarketButtonCol = this.state.UserStore.data.mercadolibre;

  //const companyNameGlobal = this.state.UserStore.data.company;
  //const companyIdUser = this.state.UserStore.data.companyId;

  //const userGroupId = this.state.UserStore.data.user_group_id
  //const userId = this.state.UserStore.data.id

  //  console.log('VALORES DE ',usuario)

    if (userGroupId === 1000){
      this.setState({
        activeUser: true
      })};

    this.setState({
      companyNameGlobal,
      companyIdUser,
      userGroupId,
      userId,
      empresaAut

    });

  }

/*
  componentWillMount (){
    if(Object.keys(this.state.paginatedData.items[0]).length > 2){
      console.log('ENTRA EN EL OBJETO y TIENE DATOS')
      console.log(this.state.paginatedData.items)
      const data2 = this.state.paginatedData.items
       console.log(this.state.UserStore['data'])
      this.setState({
            paginatedDataTemp: data2})

    } else {
      console.log('ENTRA EN EL OBJETO y no TIENE DATOS')
      console.log(this.state.paginatedData.items)
       console.log(this.state.UserStore['data'])
      const data2:any[] = [];}

  };

  */

  componentDidMount() {
    let showIndexButton = false;
    let showFreeMarketButton = false;
    const userFromStorage = localStorage.getItem("user");
    if (userFromStorage) {
      try {
        const parsed = JSON.parse(userFromStorage);

        showIndexButton = parsed.indexada;
        showFreeMarketButton = parsed.mercadolibre;
      } catch (e) {}
    }
    this.setState({ showIndexButton, showFreeMarketButton });

  }

  onSubmit = (filters) => {
    this.lastFiltersEmpre = filters;
    this.setState({ page: 1 }, () => {
      this.fetchItems(this.lastFiltersEmpre);
    });
  };

  export = async () => {
    let isLoading = true;
    this.setState({ isLoading });
    const filters = this.lastFiltersEmpre;
    let { page, limit } = this.state;
    console.log('VALORES DE filters Y THIS.STATE');
    console.log(filters)
    console.log('-------------------------')
    console.log(this.state);
    if (filters["limit"]) {
      limit = parseInt(filters["limit"]);
      //delete filters["limit"];
    }
    //limit = this.state.pageSize;

    const sips = await this.props.client.query({
      query: EXPORT_SIPS,
      variables: { page, limit, filters },
    });
    const file = sips.data.sipsExportCSV;
    let tempLink = document.createElement("a");
    tempLink.href = Environment.apiUrl.replace("/graphql", "") + file;

    tempLink.click();
    isLoading = false;
    this.setState({ isLoading });
  };

  fetchItems = async (filters) => {
    let cif = ""
    let isLoading = true;
    const isSearching = true;
    this.setState({ isLoading, isSearching });
    this.lastFiltersEmpre = filters;

    if (this.lastFiltersEmpre.hasOwnProperty("limit")) {
      this.state.limit = parseInt(filters.limit, 200);
    }
    if (
      (!this.state.limit || this.state.limit === 0) &&
      (this.state.pageSize !== null || this.state.pageSize > 1)
    ) {
      this.state.limit = this.state.pageSize;
    }

    let { page, limit } = this.state;

    if (filters["limit"]) {
      limit = parseInt(filters["limit"]);
      delete filters["limit"];
    }

    if(filters["CIF"]){
      cif = filters["CIF"];
    }

    if (limit === 0 && this.state.pageSize > 1) {
      limit = this.state.pageSize;
    }

    await this.props.client.query({
      query: LIST_SIPS,
      variables: { page, limit, filters },
    }).then( res => {

      const {
        currentPage,
        pageSize,
        pageCount,
        count,
        items,
      } = res.data.listSips;

      const paginatedData = { items };

      const sumOfEnergy = paginatedData.items.reduce((sum, currentValue) => {
        return sum + Number(currentValue.ConsumoAnualTotalActiva);
        }, 0);
      console.log(sumOfEnergy);

      const sumOfEnergyLast = paginatedData.items.reduce((sum, currentValue) => {
        return sum + Math.round(Number(currentValue.ConsumoAnualTotalActivaAnterior));
        }, 0);
      console.log(sumOfEnergyLast);

      const groupBy = (array, key) => {
        return array.reduce((result, currentValue) => {
          (result[currentValue.distribuidora] = result[currentValue.distribuidora] || []).push(
            currentValue
          );
          return result;
        }, {});
      };

      const groupByComer = (array, key) => {
        return array.reduce((result, currentValue) => {
          (result[currentValue.comercializadora] = result[currentValue.comercializadora] || []).push(
            currentValue
          );
          return result;
        }, {});
      };

      const groupByATR = (array, key) => {
        return array.reduce((result, currentValue) => {
          (result[currentValue.tarifaATR] = result[currentValue.tarifaATR] || []).push(
            currentValue
          );
          return result;
        }, {});
      };



      const cupsGroupedBydistri = groupBy(paginatedData.items, "distribuidora");
      const cupsGroupedByComer = groupByComer(paginatedData.items, "comercializadora");
      const cupsGroupedByATR = groupByATR(paginatedData.items, "tarifaATR");

      var EnergiaTotalDistribuidora  : string[] = [];


      paginatedData.items.reduce(function(res, value) {
        if (!res[value.distribuidora]) {
          res[value.distribuidora] = { Id: value.distribuidora, TotalConsumoAnual: 0 };
          EnergiaTotalDistribuidora.push(res[value.distribuidora])
        }
        res[value.distribuidora].TotalConsumoAnual += Number(value.ConsumoAnualTotalActiva);
        return res;
      }, {});

      var EnergiaTotalComercial  : string[] = [];
      paginatedData.items.reduce(function(res, value) {
        if (!res[value.comercializadora]) {
          res[value.comercializadora] = { Id: value.comercializadora, TotalConsumoAnual: 0 };
          EnergiaTotalComercial.push(res[value.comercializadora])
        }
        res[value.comercializadora].TotalConsumoAnual += Number(value.ConsumoAnualTotalActiva);
        return res;
      }, {});


var EnergiaTotalComercialCont  : string[] = [];
paginatedData.items.reduce(function(res, value) {
  if (!res[value.comercializadora]) {
    res[value.comercializadora] = { Id: value.comercializadora, TotalContador:0, TotalConsumoAnual: 0 };
    EnergiaTotalComercialCont.push(res[value.comercializadora])
  }
  res[value.comercializadora].TotalContador += 1;
  res[value.comercializadora].TotalConsumoAnual += Number(value.ConsumoAnualTotalActiva);
  return res;
}, {});




      var EnergiaTotalATR : string[] = [];
      paginatedData.items.reduce(function(res, value) {
        if (!res[value.tarifaATR]) {
          res[value.tarifaATR] = { Id: value.tarifaATR, TotalConsumoAnual: 0 };
          EnergiaTotalATR.push(res[value.tarifaATR])
        }
        res[value.tarifaATR].TotalConsumoAnual += Number(value.ConsumoAnualTotalActiva);
        return res;
      }, {});

      var EnergiaTotalATRCont : string[] = [];
      paginatedData.items.reduce(function(res, value) {
        if (!res[value.tarifaATR]) {
          res[value.tarifaATR] = { Id: value.tarifaATR, TotalContador: 0, TotalConsumoAnual: 0  };
          EnergiaTotalATRCont.push(res[value.tarifaATR])
        }
        res[value.tarifaATR].TotalContador += 1;
        res[value.tarifaATR].TotalConsumoAnual += Number(value.ConsumoAnualTotalActiva);
        return res;
      }, {});


      var EnergiaTotalProvincia : string[] = [];
      paginatedData.items.reduce(function(res, value) {
        if (!res[value.nombreProvinciaPS]) {
          res[value.nombreProvinciaPS] = { Id: value.nombreProvinciaPS, TotalContador: 0, TotalConsumoAnual: 0  };
          EnergiaTotalProvincia.push(res[value.nombreProvinciaPS])
        }
        res[value.nombreProvinciaPS].TotalContador += 1;
        res[value.nombreProvinciaPS].TotalConsumoAnual += Number(value.ConsumoAnualTotalActiva);
        return res;
      }, {});

      console.log('Contador ATR')
      console.log(EnergiaTotalATRCont)

      //const rows = EnergiaTotalDistribuidora.map(function(x){ return x.map(function(y){ return y === null? "Sin Valor": y})});
      //const rows = EnergiaTotalDistribuidora.map(EnergiaTotalDistribuidora => ({

        //      like: EnergiaTotalDistribuidora.Id === null ? "Sin valor": EnergiaTotalDistribuidora.like
        //   }))

        var fixedArray = EnergiaTotalComercial.map(function(item) {
          console.log(item)
    if (item['Id'] === null) {
        item['Id'] = "SIN DATOS";
    }
    return item;
});


var fixedArrayCont = EnergiaTotalComercialCont.map(function(item) {
  console.log(item)
if (item['Id'] === null) {
item['Id'] = "SIN DATOS";
}
return item;
});


var fixedArrayProvincia = EnergiaTotalProvincia.map(function(item) {
  console.log(item)
if (item['Id'] === null) {
item['Id'] = "SIN DATOS";
}
return item;
});

      console.log('VALOR DE Energia total')
      console.log(EnergiaTotalComercial)
      console.log(fixedArray)

      const   cupsGroupedBydistriEnergy = EnergiaTotalDistribuidora
      const   cupsGroupedByATREnergy = EnergiaTotalATR
      const   cupsGroupedByComercialEnergy = EnergiaTotalComercial
      const cupsGroupedByComercialEnergyCont = EnergiaTotalComercialCont
      const cupsGroupedByProvincia = EnergiaTotalProvincia

      console.log('VALORES REDUCE')
      console.log(cupsGroupedBydistri)
      console.log(cupsGroupedBydistriEnergy)



      isLoading = false;

      if (count === 0){

       this.setState({
         paginatedData: {items:[{CIF:''}]},
         paginatedDataTemp:  {items:[]},
         sumOfEnergy: sumOfEnergy,
         sumOfEnergyLast: sumOfEnergyLast,
         cupsGroupedBydistri: cupsGroupedBydistri,
         cupsGroupedBydistriEnergy: cupsGroupedBydistriEnergy,
         cupsGroupedByATREnergy: cupsGroupedByATREnergy,
         cupsGroupedByComer: cupsGroupedByComer,
         cupsGroupedByATR: cupsGroupedByATR,
         cupsGroupedByComercialEnergy: cupsGroupedByComercialEnergy,
         EnergiaTotalATRCont: EnergiaTotalATRCont,
         cupsGroupedByComercialEnergyCont: cupsGroupedByComercialEnergyCont,
         cupsGroupedByProvincia: cupsGroupedByProvincia,
         currentPage,
         pageSize,
         pageCount,
         count,
         isLoading,
         datosEmpresa:[]
         })
       } else {



      this.setState({
        paginatedData,
        paginatedDataTemp: paginatedData,
        sumOfEnergy: sumOfEnergy,
        sumOfEnergyLast: sumOfEnergyLast,
        cupsGroupedBydistri: cupsGroupedBydistri,
        cupsGroupedBydistriEnergy: cupsGroupedBydistriEnergy,
        cupsGroupedByATREnergy: cupsGroupedByATREnergy,
        cupsGroupedByComer: cupsGroupedByComer,
        cupsGroupedByATR: cupsGroupedByATR,
        cupsGroupedByComercialEnergy: cupsGroupedByComercialEnergy,
        EnergiaTotalATRCont: EnergiaTotalATRCont,
        cupsGroupedByComercialEnergyCont: cupsGroupedByComercialEnergyCont,
        cupsGroupedByProvincia: cupsGroupedByProvincia,
        currentPage,
        pageSize,
        pageCount,
        count,
        isLoading,
        datosEmpresa:[]
      });
    }
    })
    .catch( err => {
      isLoading = false;
      this.setState({isLoading});
    })

/* Aquí empieza  la parte de la Búsqueda de CIF




   console.log('VALORES DE FILTERS')
   console.log(filters)
   console.log(cif)

    this.props.client.query({
      query: LIST_BUSINESS,
      variables: { cif},
    }).then( res => {
      console.log('estoy dentro del segundo query en empresa')
      const data = res.data.listBusiness;
      console.log(res)

      if (data == null){
      //const datosEmpresa = { data };
      const datosEmpresa = {
        CIF: "",
  CNAE: "",
  Calle: "",
  CodigoPostal: "",
  Consolidada: "",
  Ejercicio: "",
  FormaJuridica: "",
  IngExplota: "",
  LiteralCNAE: "",
  Localidad: "",
  Marca: "",
  Nombre: "",
  Proveedor: "",
  Provincia: "",
  Telefono: "",
  Web: ""
      }
      console.log('DENTRO DEL IF SI ES NULO')
      console.log(datosEmpresa);

      this.setState({
        datosEmpresa: datosEmpresa,

      });
    } else {const datosEmpresa = res.data.listBusiness;
        console.log('DENTRO DEL IF SI NOOOO ES NULO')
      console.log(datosEmpresa);

      this.setState({
        datosEmpresa: datosEmpresa,

      });}
  })
    .catch( err => {
      isLoading = false;
      this.setState({isLoading});
    })




      await this.props.client
        .query({
          query: LIST_SIPS_UBICACION_CIF,
          variables: { cif: cif },
        })
        .then((result) => {
          //Sip
          const data = result.data.findTitular;

          const titular = { data };

          this.setState({
              titular,
            });
        }).catch( err => {
          isLoading = false;
          this.setState({isLoading});
        })

*/


console.log('VALOR DE THIS STATE ANTES DE CIF')
console.log(this.state)
if(this.state.count>0){
//if(Object.keys(this.state.paginatedData.items[0]).length > 2){
  cif = this.state.paginatedData.items[0].CIF;
  console.log('VALOR DE cif -----> línea 375')
  console.log(cif)
     if (cif != undefined || null){
console.log('VALOR DE cif -----> línea 378')
console.log(cif)
       await this.props.client.query({
         query: LIST_BUSINESS,
         variables: {cif},
       }).then( res => {
         console.log('estoy dentro del segundo query en multips')
         const data = res.data.listBusiness;
         console.log(res)

         if (data == null){
         //const datosEmpresa = { data };
         const datosEmpresa = {
           CIF: "",
     CNAE: "",
     Calle: "",
     CodigoPostal: "",
     Consolidada: "",
     Ejercicio: "",
     FormaJuridica: "",
     IngExplota: "",
     LiteralCNAE: "",
     Localidad: "",
     Marca: "",
     Nombre: "",
     Proveedor: "",
     Provincia: "",
     Telefono: "",
     Web: ""
         }
         console.log('DENTRO DEL IF SI ES NULO')
         console.log(datosEmpresa);

         this.setState({
           datosEmpresa: datosEmpresa,

         });
       } else {const datosEmpresa = res.data.listBusiness;
           console.log('DENTRO DEL IF SI NOOOO ES NULO')
         console.log(datosEmpresa);

         this.setState({
           datosEmpresa: datosEmpresa,

         });}
       })
       .catch( err => {
         isLoading = false;
         this.setState({isLoading});
       })

       await this.props.client
         .query({
           query: LIST_SIPS_UBICACION_CIF,
           variables: { cif: cif },
         })
         .then((result) => {
           //Sip
           const data = result.data.findTitular;

           console.log('VALOR DE TITULAR -------------------> Línea 410')
           console.log(data)

           const titular = { data };

           this.setState({
               titular: titular,
             });
         }).catch( err => {
           isLoading = false;
           this.setState({isLoading});
         })


     }
}






  };

  onPageClick = (page) => {
    this.setState({ page }, () => {
      this.fetchItems(this.lastFiltersEmpre);
    });
  };

  handleChange = state => {
  // eslint-disable-next-line no-console
  console.log('state', state.selectedRows);
  // eslint-disable-next-line react/no-unused-state
  this.setState({ selectedRows: state.selectedRows });
};

  handleButtonClick = () => {
    // eslint-disable-next-line no-console
    console.log('clicked');
  };

  beforeRenderItem = (index, item) => {
    if (index.includes("fecha") && item.value) {
      const dateValue = new Date(item.value);
      item.value = dateValue.toISOString().split("T")[0];
    }
    return true;
  };



  render() {
    console.log('DENTRO DE Empresa')
    console.log(this.state)


    let noResults = <></>;
    if (this.state.paginatedData.items.length === 0 && !this.state.isLoading) {
      if (this.state.isSearching) {
        noResults = (
          <p className="alert alert-danger">No se encontraron resultados</p>
        );
      } else {
        noResults = (
          <p className="alert alert-info">
            Realice su busqueda utilizando el formulario de arriba
          </p>
        );
      }
    }
    const empresa:any = this.state.datosEmpresa
    const contATR = this.state.EnergiaTotalATRCont
    const contComer = this.state.cupsGroupedByComercialEnergyCont
    console.log('VALOR DE EMPRESA')
    console.log(empresa)
    const data = this.state.cupsGroupedBydistriEnergy;
    const data2= this.state.cupsGroupedByComercialEnergy;
    console.log(this.state.cupsGroupedByATREnergy)
    const data4 = this.state.cupsGroupedByATREnergy;
    const data5 = this.state.cupsGroupedByProvincia;
    const sumOfEnergy = Math.round(Number(this.state.sumOfEnergy)/1000);
    const sumOfEnergyLast = Math.round(Number(this.state.sumOfEnergyLast)/1000);
    const ds = new DataSet();
    //const ds2 = new DataSet();
    const dv = ds.createView().source(data4);
    const dv2 = ds.createView().source(data2);
    const dv3 = ds.createView().source(data5);
    console.log(dv)
    dv.source(data4).transform({
      type: "sort-by",
      fields: ['TotalConsumoAnual'],
      order: 'DESC',

});
dv2.source(data2).transform({
  type: "sort-by",
  fields: ['TotalConsumoAnual'],
  order: 'DESC',

});

if(this.state.empresaAut) {
  return (
      <>
        <Header title="Listado" />
        <Container className=" mt--7" fluid>
          <SipsFilter onSubmit={this.onSubmit}></SipsFilter>

<h3>Datos del registro de empresa</h3>
          <Card className="mb-4">


          <CardHeader>



            <Form className="needs-validation" noValidate>
                                <div className="form-row">
                                  <Col xs="3" md="2">
                                    <label
                                      className="form-control-label"
                                      htmlFor="validationCustom01"
                                    >
                                      CIF
                                    </label>
                                    <Col md={{ size: "auto" }}>
                                      <span>{empresa.CIF}</span>
                                    </Col>

                                  </Col>

                                  <Col xs="3" md="4">
                                    <label
                                      className="form-control-label"
                                      htmlFor="validationCustomUsername"
                                    >
                                      Nombre
                                    </label>
                                    <Col md={{ size: "auto" }}>
                                      <span>{empresa.Nombre}</span>
                                    </Col>
                                  </Col>

                                  <Col xs="3" md="3">
                                    <label
                                      className="form-control-label"
                                      htmlFor="validationCustom01"
                                    >
                                      Forma Jurídica
                                    </label>
                                    <Col md={{ size: "auto" }}>
                                      <span>{empresa.FormaJuridica}</span>
                                    </Col>
                                    </Col>
                                </div>


                                <div className="form-row">

                                <Col xs="3" md="4">
                                    <label
                                      className="form-control-label"
                                      htmlFor="validationCustom02"
                                    >
                                      Marca
                                    </label>
                                    <Col md={{ size: "auto" }}>
                                      <span>{empresa.Marca}</span>
                                    </Col>

                                  </Col>

                                  <Col xs="1" md="1">
                                    <label
                                      className="form-control-label"
                                      htmlFor="validationCustom02"
                                    >
                                      CNAE
                                    </label>
                                    <Col md={{ size: "auto" }}>
                                      <span>{empresa.CNAE}</span>
                                    </Col>

                                  </Col>
                                  <Col xs="3" md="3">
                                    <label
                                      className="form-control-label"
                                      htmlFor="validationCustomUsername"
                                    >
                                      Literal CNAE
                                    </label>
                                    <Col md={{ size: "auto" }}>
                                      <span>{empresa.LiteralCNAE}</span>
                                    </Col>
                                  </Col>

                                </div>


                                <div className="form-row">

                                <Col xs="3" md="4">
                                    <label
                                      className="form-control-label"
                                      htmlFor="validationCustom02"
                                    >
                                      Calle
                                    </label>
                                    <Col md={{ size: "auto" }}>
                                      <span>{empresa.Calle}</span>
                                    </Col>

                                  </Col>

                                  <Col xs="2" md="2">
                                    <label
                                      className="form-control-label"
                                      htmlFor="validationCustom02"
                                    >
                                      Localidad
                                    </label>
                                    <Col md={{ size: "auto" }}>
                                      <span>{empresa.Localidad}</span>
                                    </Col>

                                  </Col>

                                  <Col xs="2" md="2">
                                    <label
                                      className="form-control-label"
                                      htmlFor="validationCustomUsername"
                                    >
                                      Provincia
                                    </label>
                                    <Col md={{ size: "auto" }}>
                                      <span>{empresa.Provincia}</span>
                                    </Col>
                                  </Col>
                                  <Col xs="2" md="2">
                                    <label
                                      className="form-control-label"
                                      htmlFor="validationCustomUsername"
                                    >
                                      Código Postal
                                    </label>
                                    <Col md={{ size: "auto" }}>
                                      <span>{empresa.CodigoPostal}</span>
                                    </Col>
                                  </Col>

                                </div>

                                <div className="form-row">

                                <Col xs="2" md="2">
                                    <label
                                      className="form-control-label"
                                      htmlFor="validationCustom02"
                                    >
                                      Telefono
                                    </label>
                                    <Col md={{ size: "auto" }}>
                                      <span>{empresa.Telefono}</span>
                                    </Col>

                                  </Col>

                                  <Col xs="2" md="2">
                                    <label
                                      className="form-control-label"
                                      htmlFor="validationCustom02"
                                    >
                                      Web
                                    </label>
                                    <Col md={{ size: "auto" }}>
                                      <span>{empresa.Web}</span>
                                    </Col>

                                  </Col>

                                  <Col xs="3" md="4">
                                    <label
                                      className="form-control-label"
                                      htmlFor="validationCustomUsername"
                                    >
                                      Proveedor del dato
                                    </label>
                                    <Col md={{ size: "auto" }}>
                                      <span>{empresa.Proveedor}</span>
                                    </Col>
                                  </Col>


                                </div>

                              </Form>



            </CardHeader>
          </Card>

          <h3>Información Resumen De Los Puntos de Suministro</h3>
          <Card id="EnergyInformation" className="card-deck row">
            <CardBody>

                <Row>

                  <Col xs="4" md="6">
                    <ListGroup>
                      <ListGroupItem><strong>Consumo Energía Año Actual:</strong> {sumOfEnergy} MWh</ListGroupItem>

                    </ListGroup>

                  </Col>
                  <Col xs="4" md="6">
                    <ListGroup>

                      <ListGroupItem><strong>Consumo Energía Año Pasado:</strong> {sumOfEnergyLast} MWh</ListGroupItem>
                    </ListGroup>

                  </Col>

                </Row>
                <div className="row">
                  <div className="col-md-6 col-xl-6">
                    <div className="card-stats card">
                  <div className="card-body">
                    <div className = "row">
                <DataTable
                     title="DATOS GENERALES CONTRATOS"
                     columns={columnsDatosR1}
                     data = {contATR}
                     sortIcon={sortIcon} // use a material icon for our sort icon. rdt will rotate the icon 180 degrees for you
                     fixedHeader = {true}
                     onSelectedRowsChange={this.handleChange}
                     keyField = "cups"
                     highlightOnHover
                     striped
                     pointerOnHover
                     selectableRowsHighlight
                     customStyles={customStyles}
                     contextMessage = {{ singular: 'cup', plural: 'cups', message: 'seleccionados' }}
                     noDataComponent = {"Sin datos para mostrar"}

                   />
                 </div>
            </div>
            </div>
            </div>
            <div className="col-md-6 col-xl-6">
              <div className="card-stats card">
                <div className="card-body">
                  <div className = "row">

                   <DataTable
                        title="DATOS GENERALES COMERCIALIZADORA"
                        columns={columnsDatosR2}
                        data = {contComer}
                        sortIcon={sortIcon} // use a material icon for our sort icon. rdt will rotate the icon 180 degrees for you
                        fixedHeader = {true}
                        onSelectedRowsChange={this.handleChange}
                        keyField = "cups"
                        highlightOnHover
                        striped
                        pointerOnHover
                        selectableRowsHighlight
                        customStyles={customStyles}
                        contextMessage = {{ singular: 'cup', plural: 'cups', message: 'seleccionados' }}
                        noDataComponent = {"Sin datos para mostrar"}

                      />
                    </div>
        </div>
        </div>
        </div>
        </div>

            </CardBody>
          </Card>







          <h3>Gráficos Informativos</h3>





      <div className="row">
        <div className="col-md-6 col-xl-6">
          <div className="card-stats card">
        <div className="card-body">
          <div className = "row">
            <h5 className = "text-uppercase text-muted mb-0 card-title"> Tarifa ATR kWh Anual</h5>

      <Chart
               data={dv}
               height={300}
               width={400}
               padding={[20,80,40,60]}
               autoFit
               placeholder
             >
               <Coordinate transpose />
               <Axis
                 name="Id"
               />
               <Axis name="TotalConsumoAnual" visible={false} />
               <Tooltip />
               <Interval
                 position="Id*TotalConsumoAnual"
                 label="TotalConsumoAnual"
                 color="Id"
                 />

             </Chart>

           </div>
      </div>
      </div>
      </div>
        <div className="col-md-6 col-xl-6">
          <div className="card-stats card">
            <div className="card-body">
              <div className = "row">
                <h5 className = "text-uppercase text-muted mb-0 card-title"> Comercializadora kWh Anual</h5>

             <Chart
                      data={dv2}
                      height={300}
                      width={400}
                      padding={[20,80,40,180]}
                      autoFit
                      placeholder
                    >
                      <Coordinate transpose />
                      <Axis
                        name="Id"
                      />
                      <Axis name="TotalConsumoAnual" visible={false} />
                      <Tooltip />
                      <Interval
                        position="Id*TotalConsumoAnual"
                        label="TotalConsumoAnual"
                        color="Id"
                      />
                    </Chart>
                  </div>
      </div>
      </div>
      </div>
      </div>
      <div className="row">
        <div className="col-md-6 col-xl-6">
          <div className="card-stats card">
        <div className="card-body">
          <div className = "row">
            <h5 className = "text-uppercase text-muted mb-0 card-title"> Consumos agrupados por Distribuidoras kWh Anual</h5>

      <Chart height={300} scale={scale} autoFit data={data} interactions={['active-region']} appendPadding={[20, 0, 0, 0]}>
              <Interval position="Id*TotalConsumoAnual" color="Id" />
              <Tooltip shared />
            </Chart>
          </div>
      </div>
      </div>
      </div>

      <div className="col-md-6 col-xl-6">
        <div className="card-stats card">
          <div className="card-body">
            <div className = "row">
              <h5 className = "text-uppercase text-muted mb-0 card-title"> Provincia kWh Anual</h5>

           <Chart
                    data={dv3}
                    height={300}
                    width={400}
                    padding={[20,80,40,180]}
                    autoFit
                    placeholder
                  >
                    <Coordinate transpose />
                    <Axis
                      name="Id"
                    />
                    <Axis name="TotalConsumoAnual" visible={false} />
                    <Tooltip />
                    <Interval
                      position="Id*TotalConsumoAnual"
                      label="TotalConsumoAnual"
                      color="Id"
                    />
                  </Chart>
                </div>
    </div>
    </div>
    </div>
      </div>


          <Row>
            <div className="col">
              <Card className="shadow">
                <LoadingOverlay
                  active={this.state.isLoading}
                  spinner
                  text="Cargando sips..."
                >
                  <CardHeader className="border-0">
                    <Row>
                      <Col md="8">
                        <h3 className="mb-0">SIPS</h3>
                      </Col>
                      <Col md="4" className="text-right">
                        <Button
                          onClick={this.export}
                          size="sm"
                          disabled={this.state.paginatedData.items.length === 0}
                        >
                          Exportar a CSV
                        </Button>
                      </Col>
                    </Row>
                  </CardHeader>
               <DataTable
                    title="Puntos de Suministros"
                    columns={columns(this.handleButtonClick)}
                    data = {this.state.paginatedDataTemp.items}
                    sortIcon={sortIcon} // use a material icon for our sort icon. rdt will rotate the icon 180 degrees for you
                    fixedHeader = {true}
                    onSelectedRowsChange={this.handleChange}
                    keyField = "cups"
                    highlightOnHover
      				      striped
      				      pointerOnHover
                    selectableRowsHighlight
                    customStyles={customStyles}
                    contextMessage = {{ singular: 'cup', plural: 'cups', message: 'seleccionados' }}
                    noDataComponent = {"Sin datos para mostrar"}

                  />
                  <CardFooter className="py-4">
                    {noResults}

                    <nav aria-label="...">
                      <Pagination
                        {...this.state}
                        onPageClick={this.onPageClick}
                      ></Pagination>
                    </nav>
                  </CardFooter>
                </LoadingOverlay>
              </Card>
            </div>
  </Row>
        </Container>
      </>
    );
  } else {
    return (
      <>
        <Header title={"No tienes permisos para acceder a este apartado. Consulte con nuestro equipo comercial"}/>
      </>
    );
  }

  }
}

export default withApollo(Empresa);
