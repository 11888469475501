import gql from "graphql-tag";

export const LIST_SIPSGAS_CONSUMOS = gql`
  query($cups: String!) {
    listSipsGasConsumos(cups: $cups) {
      cups
      fechaInicioMesConsumo
      fechaFinMesConsumo
      year
      month
      codigoTarifaPeaje
      consumoEnWhP1
      consumoEnWhP2
      caudalMedioEnWhdia
      caudaMinimoDiario
      caudaMaximoDiario
      porcentajeConsumoNocturno
      codigoTipoLectura
    }
  }
`;

export const VIEW_SIPSGAS_CONSUMOS_TOTAL = gql`
  query($cups: String!) {
    viewSipsGasConsumoAnual(cups: $cups) {
      cups
      year
      codigoTarifaPeaje
      ConsumoAnualEnWhP1
      ConsumoAnualEnWhP2
      ConsumoAnualTotal
    }
  }
`;
