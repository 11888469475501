import React from "react";
import {
    Button,
    FormGroup,
	Modal,
	Label,
	Col,
	Input,
    Row,
    Form,
} from "reactstrap";
import { Multiselect } from "multiselect-react-dropdown";
import ReactBSAlert from "react-bootstrap-sweetalert";
import client from "../../api/client";
import { LIST_COMERCIAL_PRODUCTOS } from "../../queries/comercial-producto/comercial-producto";


interface IModalComparativeMultiProps {
    itemsSelected: Array<any>;
    isOpen: boolean;
    toggleMulti: any;
    sip: any;
    periods: number[];
    tarifaATR:any;
    tarifaATRDescripcion:any;
    generateComparative?: Function;
}

interface IModalComparativeMultiState {
    form: {
        idProducto: any []
        costeOperativo: number
        costeOperativoPotencia: number
    },
    cups: string,
    user: any,
    sip: any,
    periods: number[],
    itemsSelected: any[],
    comercialProductos: any[],
    alert:any
}

export default class SipComparativeFreeMultiModal extends React.Component<IModalComparativeMultiProps,IModalComparativeMultiState> {
	public formComparativeRef: any = null;
	public formSaveComparativeRef: any = null;
	public state: IModalComparativeMultiState = {
        // --- Data
        form: {
            costeOperativo: 0,
            costeOperativoPotencia: 0,
            idProducto: []
        },
		cups: "",
		user: {},
        sip: {},
        periods: [],
        itemsSelected: [],
        comercialProductos: [],
        alert: null
	};

    UNSAFE_componentWillReceiveProps(newProps) {
        this.setState({itemsSelected: newProps.itemsSelected});
    }

	componentDidMount() {
        this.formComparativeRef = React.createRef();
        this.fetchItems();
    }

	fetchItems = async () => {

    var periodosCupsPotencia = ''
    var periodosCupString = ''

    if (['001', '004', '005', '006', '007', '008','018'].indexOf(this.props.tarifaATR) >= 0) {
      periodosCupString = '3';
      periodosCupsPotencia = '2';
    } else  if (this.props.tarifaATR === '003' || this.props.tarifaATR === '019' ) {
      periodosCupString = '4';
      periodosCupsPotencia = '6';
    } else {
      periodosCupString = '6';
      periodosCupsPotencia = '6';

    }

      const companyUser = await JSON.parse(await localStorage.getItem("user")!);
      const companyIdUser = companyUser.companyId;


     await client.query({
            query: LIST_COMERCIAL_PRODUCTOS,
			variables: { companyId: companyIdUser, periodos: periodosCupString, periodosPotencia: periodosCupsPotencia},
        })
        .then((result) => {
            let data = result.data.comercialProductos;

            this.setState({ comercialProductos: data })
        });
	}

    hideAlert = () => {
      this.setState({
        alert: null
      });
    };

    warningAlert = () => {
     this.setState({
       alert: (
         <ReactBSAlert
           warning
           style={{ display: "block", marginTop: "-100px" }}
           title="Error en selección"
           onConfirm={() => this.hideAlert()}
           onCancel={() => this.hideAlert()}
           confirmBtnBsStyle="warning"
           confirmBtnText="Ok"
           btnSize=""
         >
           Es necesario seleccionar al menos dos productos
         </ReactBSAlert>
       )
     });
   };

	generateComparative = async ( event ) => {
        if(this.props.generateComparative )
        {

            if( this.state.form.idProducto.length > 1  )
            {
              //let producto:any[] = this.state.comercialProductos.filter( item => Number(item.id) === Number(this.state.form.idProducto) )[0];
              let producto:any[] = this.state.form.idProducto;

                this.props.generateComparative({
                    productoSeleccionado: producto,
                    costeOperativo: Number(this.state.form.costeOperativo),
                    costeOperativoPotencia: Number(this.state.form.costeOperativoPotencia)
                });
                this.props.toggleMulti();
                this.setState({
                    form: {
                        costeOperativo: Number(this.state.form.costeOperativo),
                        costeOperativoPotencia:  Number(this.state.form.costeOperativoPotencia),
                        idProducto: producto
                    }
                })
                return;
            } else {
              this.warningAlert();
              return;
            }
//            alert('Producto No seleccionado');
        }
    }

    onChangeField = async ( event ) => {
        const name = event.target.name;
        let value = event.target.value;
        let inputs = {};
        if ( value !== "" || value !== null)
        {
            inputs[name] = value;
            this.setState({
                form: {
                    ...this.state.form,
                    ...inputs
                }
            });
        }
    }

    onSelect = (selectedList, selectedItem) => {


        let productos:any[] = selectedList

        this.setState({
            form: {
                //costeOperativo: 0,
                ...this.state.form,
                idProducto: productos
            }
        });
    };


	render() {
        const sip: any = this.props.sip;
        const form: any =  this.state.form;
        const productos: any[] = this.state.comercialProductos;

		return (
			<>
				<Modal
                    size="m"
                    className="modal-dialog-centered modal-sip-comparative-free"
                    isOpen={this.props.isOpen}
                    toggle={this.props.toggleMulti}>
                    <div className="modal-body">
                        <Row>
                            <div className="modal-info">
                                <h1>PRODUCTOS</h1>
                            </div>
                        </Row>
                        <Form innerRef={this.formComparativeRef}>
                            <Row>
                                <Col>
                                    <FormGroup>
                                        <Label className="form-control-label" htmlFor="multipro">
                                            Tarifa de acceso
                                        </Label>
                                        <Input
                                            id="tarifaAcceso"
                                            placeholder=""
                                            name="tarifaAcceso"
                                            value={this.props.tarifaATRDescripcion}
                                            type="text"
                                            readOnly />
                                    </FormGroup>

                                    <Multiselect
                                    options = {productos}
                                    //{productos.map((item:any, index) => <option value={item.id} key={index}>{item.nombreOferta}</option>)} // Options to display in the dropdown
                                    //selectedValues={this.state.selectedValue} // Preselected value to persist in dropdown
                                    onSelect={this.onSelect} // Function will trigger on select event
                                    //onRemove={this.onRemove} // Function will trigger on remove event
                                    displayValue="nombreOferta" // Property name to display in the dropdown options
                                    //showCheckbox={true}
                                    closeOnSelect={true}
                                    />

{this.state.alert}
                                    <FormGroup>
                                        <Label className="form-control-label" htmlFor="costeOperativomulti">
                                            CO ENERGIA c€/kWh
                                        </Label>
                                        <Input
                                            id="costeOperativo"
                                            placeholder=""
                                            value={form.costeOperativo}
                                            name="costeOperativo"
                                            type="number"
                                            onChange={this.onChangeField}
                                            required/>
                                    </FormGroup>

                                    <FormGroup>
                                        <Label className="form-control-label" htmlFor="costeOperativoPotenciamulti">
                                            CO POTENCIA c€/kWh
                                        </Label>
                                        <Input
                                            id="costeOperativoPotencia"
                                            placeholder=""
                                            value={form.costeOperativoPotencia}
                                            name="costeOperativoPotencia"
                                            type="number"
                                            onChange={this.onChangeField}
                                            required/>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Form>
                    </div>
                    <div className="modal-footer">
	                    <Button
	                        onClick={this.props.toggleMulti}
	                        color="secondary"
	                        data-dismiss="modal"
	                        type="button">
	                        Cerrar
	                    </Button>
	                    <Button onClick={this.generateComparative} color="primary" type="button">
	                        Generar Comparativa
	                    </Button>
                    </div>
                </Modal>
			</>
		);
	}
}
