import Environment from '../../constants/environment';
import React, { forwardRef } from "react";
import Reflux from "reflux";
import axios from 'axios';
import memoize from 'memoize-one';
import client from "../../api/client";
//import DatePicker from "react-datepicker";
import { validateSpanishId } from 'spain-id'
//import FormData from 'form-data';
// reactstrap components
import {
    Card, Container, Row, CardTitle, CardBody, Label,
    FormGroup,
    Input, FormText,
    Form, Col, Button,
    UncontrolledTooltip, CardHeader, Collapse
} from "reactstrap";
import {
    Link,
    useRouteMatch
} from 'react-router-dom';

import { CSVLink, CSVDownload } from "react-csv";

// core components
import Header from "../../components/Headers/Header";
import SipsOption from "../../components/Sips/SipsOption";


import { withApollo } from "react-apollo";
import { LIST_DICMUNICIPIOS } from "../../queries/options/dic-option.graphql";

import moment from "moment";
import 'moment/locale/es'

import ReactBSAlert from "react-bootstrap-sweetalert";

import GetUserData from "../../components/User/GetUserData";
import { UserStore } from "../../store/UserStore";
import DataTable from 'react-data-table-component';
import Checkbox from '@material-ui/core/Checkbox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
//import memoize from 'memoize-one';
import CircularProgress from '@material-ui/core/CircularProgress';
import { saveAs } from 'file-saver';

var DatePicker = require("reactstrap-date-picker");

const FileDownload = require('js-file-download');
const formato = require('xml-formatter');
const spanishDayLabels = ['Dom', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sab'];
const spanishMonthLabels = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];

const fileStatuses = [
    "UploadFailed",
    "Initial",
    "Selected",
    "Uploading",
    "Uploaded",
    "RemoveFailed",
    "Removing",
];


const columnsFiles = memoize(clickHandler => [
    {
        selector: row => row.Key, name: 'NOMBRE DEL FICHERO', sortable: true, width: '550px', style: {
            color: 'rgba(0,0,0,.54)',
        },
    },

    {
        // eslint-disable-next-line react/button-has-type
        cell: (row) => <Button onClick={clickHandler} color="info" size="sm" className="float-right" id={row.Key} value={row.Key}>Ver</Button>,
        ignoreRowClick: true,
        allowOverflow: true,
        button: true,
    },
    { selector: row => row.LastModified, name: 'Ultima Modificación', sortable: true, width: '180px', center: false },
    { selector: row => row.Size, name: 'Tamaño en Bytes', sortable: true, width: '150px', center: false },

]);



const hint = <span>My custom hint</span>;
const note = <span>My custom note</span>;
const sortIcon = <ArrowDownward />;

const columnsDatosComercial = [

    { selector: row => row.nombreComercial, name: 'NOMBRE', sortable: true, width: '200px', format: (row => capitalize(row.nombreComercial)) },
    { selector: row => row.apellido1Comercial, name: 'APELLIDO 1', sortable: true, width: '240px', center: true, format: (row => capitalize(row.apellido1Comercial)) },
    { selector: row => row.apellido2Comercial, name: 'APELLIDO 2', sortable: true, width: '210px', center: true, format: (row => capitalize(row.apellido2Comercial)) },
    { selector: row => row.NIFComercial, name: 'CIF', sortable: true, width: '100px', center: true, format: (row => capitalize(row.NIFComercial)) },
    { selector: row => row.CargoComercial, name: 'CARGO', sortable: true, width: '250px', center: true, format: (row => capitalize(row.CargoComercial)) },
    { selector: row => row.telefonoComercial, name: 'TELEFONO', width: '180px', sortable: true, center: true, format: (row => capitalize(row.telefonoComercial)) }
];

const capitalize = (s) => {
    if (typeof s !== 'string') return ''
    return s.toLowerCase().charAt(0).toUpperCase() + s.slice(1)
}

const customStyles = {
    headRow: {
        style: {
            border: 'none',

        },
    },
    headCells: {
        style: {
            color: '#202124',
            fontSize: '12px',
            fontWeight: 'bold',
            wordBreak: 'break-word',
            paddingLeft: '8px',
            paddingRight: '8px',
            minWidth: '55px'

        },
    },
    cells: {
        style: {
            paddingLeft: '8px',
            paddingRight: '8px',
            minWidth: '55px',
            wordBreak: 'break-word',
            fontFamily: '"Open Sans", sans-serif;'
        },
    },
    rows: {
        highlightOnHoverStyle: {
            backgroundColor: 'rgb(230, 250, 250)',
            borderBottomColor: '#FFFFFF',
            borderRadius: '25px',
            outline: '1px solid #FFFFFF',
            fontSize: '12px',
        },
    },
    pagination: {
        style: {
            border: 'none',
        },
    },
};


const Circular = () => (
    // we need to add some padding to circular progress to keep it from activating our scrollbar
    <div style={{ padding: '24px' }}>
        <CircularProgress size={75} />
    </div>
);





class Comercial extends Reflux.Component<any, any> {
    public maxFileSize = 3;
    public fileInput: any = null;
    public lecturasRef: any = [];
    public inputEl: any = null;
    public formRef: any = null;
    public validFileExtensions = ["jpg", "jpeg", "png"];
    lastFilters = {};
    state = {
        filters: {
            nombreSearch: "",
            apellidoSearch: "",
            cifSearch: ""
        },
        UserStore: {},
        nombreFichero: "",
        isLoading: false,
        copiedText: null,
        currentPage: 0,
        pageSize: 0,
        pageCount: 0,
        count: 0,
        contrato: "",
        tipoContrato: "",
        ComercialDetailTotal: [],
        ComercialSeleccionado: false,
        idComercial: '',
        ComercialDetail: {
            nombreComercial:'',
            apellido1Comercial:'',
            apellido2Comercial:'',
            NIFComercial:'',
            idComercial:'',
            idComercialInterno: 0,
            telefonoComercial:'',
            correoComercial:'',
            comisionYear1: 0,
            comisionYear2: 0,
            idCompany: 0,
            zonaGeograficaComercial: "",
            idResponsable: "",
            Responsable: "",
            idUser:'',
            createdAt:'',
            updatedAt:''
        },
        formComercial: {
            nombreComercial:'',
            apellido1Comercial:'',
            apellido2Comercial:'',
            NIFComercial:'',
            idComercial:'',
            idComercialInterno: 0,
            telefonoComercial:'',
            correoComercial:'',
            comisionYear1: 0,
            comisionYear2: 0,
            idCompany: 0,
            zonaGeograficaComercial: "",
            idResponsable: "",
            Responsable: "",
            idUser:'',
            createdAt:'',
            updatedAt:''
        },

        companyIdUser: '',
        companyNameGlobal: '',
        userGroupId: 1000,
        userId: '',

        saveDataComercial: false,
        updateDataComercial: false,
        saveComercialButtonDisabled: true,
        updateComercialButtonDisabled: true,
        saveXMLButtonDisabled: true,
        buscarButtonDisabled: true,
        NIFComercialError: false,
        inputDisabled: false,
        show: false,
        imgCollection: [],
        uploadButtonDisabled: true,
        showFilters: true,
        toggledClearRows: false,
        cupsError: false,
        cifError: false,
        locale: 'es',
        inputNombreDisabled: false,
        inputCifDisabled: false,
        inputApellidosDisabled: false,
        filesContrato: [],
        createdAt: '',
        fechaBaja: '',
        codigoPostalComercialError : false,
        dicMunicipios: [],
        alert: null
    };

    sipsCSVData: any = [];
    viewSipsConsumoAnualCSVData: any = [];
    sipsConsumosCSVData: any = [];
    sipstotal: any = [];






    constructor(props) {
        super(props);
        this.store = UserStore;
        this.formRef = React.createRef();

        this.fileInput = React.createRef();
        this.lecturasRef = React.createRef();
        this.inputEl = React.createRef();

    }




    componentWillReceiveProps() {

        const usuario: any = JSON.parse(JSON.stringify(this.state.UserStore['data']))

        const companyNameGlobal = usuario.company;
        const companyIdUser = usuario.companyId;

        const userGroupId = usuario.user_group_id
        const userId = usuario.id



        if (userGroupId === 1000) {
            this.setState({
                activeUser: true
            })
        };

        this.setState({
            companyNameGlobal,
            companyIdUser,
            userGroupId,
            userId

        });
    }

    componentDidMount() {
        this.fetchItems();
    }

//////////////////////////////////////////////////////////////////////////////////////7

hideAlert = () => {
    this.setState({
        alert: null
    });
};

warningAlert = () => {
    this.setState({
        alert: (
            <ReactBSAlert
                warning
                style={{ display: "block", marginTop: "-100px" }}
                title="Error en la base de datos"
                onConfirm={() => this.hideAlert()}
                onCancel={() => this.hideAlert()}
                confirmBtnBsStyle="warning"
                confirmBtnText="Ok"
                btnSize=""
            >
                Existe un error en la Base de datos.
 </ReactBSAlert>
        )
    });
};

warningAlertNIF = () => {
    this.setState({
        alert: (
            <ReactBSAlert
                warning
                style={{ display: "block", marginTop: "-100px" }}
                title="No existen registros que cumplan los requisitos"
                onConfirm={() => this.hideAlert()}
                onCancel={() => this.hideAlert()}
                confirmBtnBsStyle="warning"
                confirmBtnText="Ok"
                btnSize=""
            >
             Revise los requisitos introducidos
 </ReactBSAlert>
        )
    });
};

warningAlertFiltros = () => {
    this.setState({
        alert: (
            <ReactBSAlert
                warning
                style={{ display: "block", marginTop: "-100px" }}
                title="Existen campos obligatorios sin información"
                onConfirm={() => this.hideAlert()}
                onCancel={() => this.hideAlert()}
                confirmBtnBsStyle="warning"
                confirmBtnText="Ok"
                btnSize=""
            >
             Revise los datos introducidos
 </ReactBSAlert>
        )
    });
};

confirmAlertSave = () => {
    this.setState({
        alert: (
            <ReactBSAlert
                warning
                style={{ display: "block", marginTop: "-100px" }}
                title="¿Desea crear el comercial?"
                onConfirm={() => this.hideAlert()}
                onCancel={() => this.onSave()}
                showCancel
                confirmBtnBsStyle="secondary"
                confirmBtnText="Cancelar"
                cancelBtnBsStyle="danger"
                cancelBtnText="Guardar"
                btnSize=""
            >
                No se podrá volver atrás!
        </ReactBSAlert>
        )
    });
};

confirmAlertUpdate = () => {
    this.setState({
        alert: (
            <ReactBSAlert
                warning
                style={{ display: "block", marginTop: "-100px" }}
                title="¿Desea actualizar los datos del comercial?"
                onConfirm={() => this.hideAlert()}
                onCancel={() => this.onUpdate()}
                showCancel
                confirmBtnBsStyle="secondary"
                confirmBtnText="Cancelar"
                cancelBtnBsStyle="danger"
                cancelBtnText="Actualizar"
                btnSize=""
            >
                No se podrá volver atrás!
        </ReactBSAlert>
        )
    });
};

successAlert = () => {
    this.setState({
        alert: (
            <ReactBSAlert
                success
                style={{ display: "block", marginTop: "-100px" }}
                title="Correcto"
                onConfirm={() => this.hideAlert()}
                onCancel={() => this.hideAlert()}
                confirmBtnBsStyle="success"
                confirmBtnText="Ok"
                btnSize=""
            >
                Comercial guardado correctamente
    </ReactBSAlert>
        )
    });
};

//////////////////////////////////////////////////////////////////////////////////////

    fetchItems = async () => {

        await client.query({
            query: LIST_DICMUNICIPIOS,
            variables: {}
        })
            .then((result) => {
                let data = result.data.listDicMunicipios;
                this.setState({ dicMunicipios: data })
            });
    };

//////////////////////////////////////////////////////////////////////////////////


    fetchComercial = async () => {

        var companyIdUser = Number(this.state.companyIdUser);
        var user = this.state.userId.toString()
        var CIF = this.state.filters.cifSearch
        var nombre = this.state.filters.nombreSearch
        var apellidos = this.state.filters.apellidoSearch

        var dataFilters = {
            'idCompany': companyIdUser,
            'cups': "",
            'Usuario': "",
            'NIFComercial': CIF,
            'nombreComercial': nombre,
            'apellido1Comercial': apellidos
        };

        const valores = JSON.stringify(dataFilters)
        const headers = {
            'Authorization': 'Bearer my-token',
            'My-Custom-Header': 'foobar',
            'x-api-key': '$2y$10$bcT1Ukm4V/6/z5GvitZv0unu8I91mpixuwUO6z5CyEGev9DDDY28W'
        };

        let direccionFile = "https://datapi.psgestion.es/Comercial/listado?id=" + companyIdUser + "&user=" + user;

        await axios.get(direccionFile, { headers, params: { valores } }).
            then(result => {

                if(result.data.rows.length>0){
                let data = result.data.rows

                this.setState({ ComercialDetailTotal: result.data.rows});

            } else {
            this.warningAlertNIF()
                };

            }).catch((error) => {
                console.log('Failure')
                //this.warningAlertDB()
                this.warningAlert()
            });

    };


    validarCUPS = (cups) => {
        let ret = false;
        const reCUPS = /^[A-Z]{2}(\d{4}\d{12})([A-Z]{2})(\d[FPCRXYZ])?$/i;
        if (reCUPS.test(cups)) {
            const mCUPS = cups.toUpperCase().match(reCUPS);
            const [, cups16, control] = mCUPS;
            const letters = [
                'T', 'R', 'W', 'A', 'G', 'M',
                'Y', 'F', 'P', 'D', 'X', 'B',
                'N', 'J', 'Z', 'S', 'Q', 'V',
                'H', 'L', 'C', 'K', 'E',
            ];

            const cup16Mod = +cups16 % 529,
                quotient = Math.floor(cup16Mod / letters.length),
                remainder = cup16Mod % letters.length;

            ret = (control === letters[quotient] + letters[remainder]);
        }

        return ret
    };

    onPageClick = (page) => { };

    onChangeField = (event) => {
        event.persist()

            };

    onChangeFieldSearch = (event) => {
        event.persist()
        const name = event.target.name;
        let value = event.target.value;
        const filters = this.state.filters;

        filters[name] = value;
        /* if (event.target.type === "select-one" && value === "0") {
           delete filters[name];
         }

         if (value === "" || value === null) {
           delete filters[name];
         }
*/




        if (event.target.name === 'nombreSearch' && event.target.value.length == 0) {
            this.setState({
                cupsError: false,
                buscarButtonDisabled: true,
                inputCifDisabled: false,
                inputNombreDisabled: false,
                inputApellidosDisabled: false
            })
        } else if (event.target.name === 'nombreSearch' && event.target.value.length > 0) {

            this.setState({
                inputCifDisabled: true,
                inputApellidosDisabled: true,
                buscarButtonDisabled: false

            })

        }



        if (event.target.name === 'cifSearch' && event.target.value.length == 0) {


            this.setState({

                cifError: false,
                buscarButtonDisabled: true,
                inputCifDisabled: false,
                inputNombreDisabled: false,
                inputApellidosDisabled: false
            })
        } else if (event.target.name === 'cifSearch' && event.target.value.length > 0) {



            const result = validateSpanishId(event.target.value)


            if (result === true) {
                this.setState({
                    cifError: false,
                    buscarButtonDisabled: false,
                    inputNombreDisabled: true,
                    inputApellidosDisabled: true,

                    //isDisabledCIF: false
                })
            } else {
                this.setState({
                    cifError: true,
                    buscarButtonDisabled: true,
                    inputNombreDisabled: true,
                    inputApellidosDisabled: true,
                })
            }
        }




        if (event.target.name === 'apellidoSearch' && event.target.value.length == 0) {
            this.setState({
                buscarButtonDisabled: true,
                inputCifDisabled: false,
                inputNombreDisabled: false,
                inputApellidosDisabled: false
            })
        } else if (event.target.name === 'apellidoSearch' && event.target.value.length > 0) {

            this.setState({

                inputCifDisabled: true,
                inputNombreDisabled: true,
                buscarButtonDisabled: false
            })
        }


        this.setState({ filters });

    }



    onChangeFieldComercial = (event) => {
        event.persist()

        var formData = { ...this.state.formComercial };
        var target = event.target;
        var value =
            event.target.type === "checkbox"
                ? event.target.checked
                : event.target.value;
        const name = event.target.name;

        if (this.state.ComercialSeleccionado) {

            if (event.target.name == 'nombreComercial') {
                //console.log('VALOR NOMBRE Comercial ------> ', value, event.target.name)
                //this.setState ({ComercialDetail.nombreComercial : event.target.value});
                this.setState({ updateDataComercial: true });
                this.setState({
                    saveComercialButtonDisabled: true,
                    updateComercialButtonDisabled: false
                })
            }
            if (event.target.name === 'NIFComercial') {
                const result = validateSpanishId(event.target.value)
                //if(event.target.value){
                if (result === true) {
                    this.setState({
                        NIFComercialError: false,
                        updateDataComercial: true,
                        saveComercialButtonDisabled: true,
                        updateComercialButtonDisabled: false

                        //isDisabledCIF: false
                    })
                } else {
                    this.setState({
                        NIFComercialError: true,
                        updateDataComercial: false,
                        saveComercialButtonDisabled: true,
                        updateComercialButtonDisabled: true
                    })
                }
            }

            if (event.target.name == 'apellido1Comercial') {
                //this.setState ({ComercialDetail.nombreComercial : event.target.value});
                this.setState({ updateDataComercial: true });
                this.setState({
                    saveComercialButtonDisabled: true,
                    updateComercialButtonDisabled: false
                })
            }
            if (event.target.name == 'apellido2Comercial') {
                //this.setState ({ComercialDetail.nombreComercial : event.target.value});
                this.setState({ updateDataComercial: true });
                this.setState({
                    saveComercialButtonDisabled: true,
                    updateComercialButtonDisabled: false
                })
            }

            if (event.target.name == 'correoComercial') {
                //this.setState ({ComercialDetail.nombreComercial : event.target.value});
                this.setState({ updateDataComercial: true });
                this.setState({
                    saveComercialButtonDisabled: true,
                    updateComercialButtonDisabled: false
                })
            }

            if (event.target.name == 'telefonoComercial') {
                //this.setState ({ComercialDetail.nombreComercial : event.target.value});
                this.setState({ saveDataComercial: true });
                this.setState({
                    saveComercialButtonDisabled: true,
                    updateComercialButtonDisabled: false
                })
            }


        } else {

            if (event.target.name == 'nombreComercial') {
                //console.log('VALOR NOMBRE Comercial ------> ', value, event.target.name)
                //this.setState ({ComercialDetail.nombreComercial : event.target.value});
                this.setState({ saveDataComercial: true });
                this.setState({
                    saveComercialButtonDisabled: false,
                    updateComercialButtonDisabled: true
                })
            }
            if (event.target.name === 'NIFComercial') {
                const result = validateSpanishId(event.target.value)
                //if(event.target.value){
                if (result === true) {
                    this.setState({
                        NIFComercialError: false,
                        saveDataComercial: true,
                        saveComercialButtonDisabled: false,
                        updateComercialButtonDisabled: true

                        //isDisabledCIF: false
                    })
                } else {
                    this.setState({
                        NIFComercialError: true,
                        saveDataComercial: false,
                        saveComercialButtonDisabled: true,
                        updateComercialButtonDisabled: true
                    })
                }
            }

            if (event.target.name == 'apellido1Comercial') {
                //this.setState ({ComercialDetail.nombreComercial : event.target.value});
                this.setState({ saveDataComercial: true });
                this.setState({
                    saveComercialButtonDisabled: false,
                    updateComercialButtonDisabled: true
                })
            }
            if (event.target.name == 'apellido2Comercial') {
                //this.setState ({ComercialDetail.nombreComercial : event.target.value});
                this.setState({ saveDataComercial: true });
                this.setState({
                    saveComercialButtonDisabled: false,
                    updateComercialButtonDisabled: true
                })
            }

            if (event.target.name == 'correoComercial') {
                //this.setState ({ComercialDetail.nombreComercial : event.target.value});
                this.setState({ saveDataComercial: true });
                this.setState({
                    saveComercialButtonDisabled: false,
                    updateComercialButtonDisabled: true
                })
            }

            if (event.target.name == 'telefonoComercial') {
                //this.setState ({ComercialDetail.nombreComercial : event.target.value});
                this.setState({ saveDataComercial: true });
                this.setState({
                    saveComercialButtonDisabled: false,
                    updateComercialButtonDisabled: true
                })
            }

        }

        //console.log(formData)
        formData[name] = value;
        //if (value === "" || value === null) {
        //    formData[name] = 0;
        //}
        this.setState({ formComercial: formData });

    };


    showFiltersToggle = () => {
        const showFilters = !this.state.showFilters;

        this.setState({ showFilters });
    };

    getFilterIcon = () => {
        return this.state.showFilters ? "ni-bold-up" : "ni-bold-down";
    };


    ComercialSeleccionado = async (event) => {

        this.setState({
            ComercialSeleccionado: event.Comercial,
            // },
            //showList: false,
        });
        //this.setState({ ComercialDetail: result.data.ComercialData[0] });
        //    console.log('XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX')
        //console.log('VALORE DE ComercialDetail  ------> ', result.data.ComercialData[0])
        //    this.setState({fechaContrato: result.data.contratoInfoById[0].fechaContrato});
        // let existeFechaAlta = result.data.getComercialInfo[0].fechaFirmaContrato
        // let existeFechaBaja = result.data.getComercialInfo[0].fechaActivacion
        this.setState({ idComercial: event.Comercial.idComercial });
        this.setState({
            formComercial: {

                nombreComercial: event.Comercial.nombreComercial,
                apellido1Comercial: event.Comercial.apellido1Comercial,
                apellido2Comercial: event.Comercial.apellido2Comercial,
                NIFComercial: event.Comercial.NIFComercial,
                idComercial: event.Comercial.idComercial,
                idComercialInterno: event.Comercial.idComercialInterno,
                telefonoComercial: event.Comercial.telefonoComercial,
                correoComercial: event.Comercial.correoComercial,
                comisionYear1: parseFloat(event.Comercial.comisionYear1),
                comisionYear2: parseFloat(event.Comercial.comisionYear2),
                idCompany: Number(event.Comercial.idCompany),
                zonaGeograficaComercial: event.Comercial.zonaGeograficaComercial,
                idResponsable: event.Comercial.idResponsable,
                Responsable: event.Comercial.Responsable,
                idUser: event.Comercial.idUser,
                createdAt: event.Comercial.createdAt,
                updatedAt: event.Comercial.updatedAt

            },
            saveDataComercial: true,
            saveComercialButtonDisabled: false,
        });
        //console.log(this.state)
    };


    clearForm = () => {
        //const filters = this.filters; ---> Eliminado porque no cambiaba campos
        const filters = {};
        this.setState({
            filters: {
                nombreSearch: '',
                cifSearch: '',
                apellidoSearch: ''
            },
            inputCifDisabled: false,
            inputNombreDisabled: false,
            inputApellidosDisabled: false,
            inputDisabled: false,
            buscarButtonDisabled: true,
            ComercialSeleccionado: false,
            ComercialDetailTotal: [],
            formComercial: {
                nombreComercial:'',
                apellido1Comercial:'',
                apellido2Comercial:'',
                NIFComercial:'',
                idComercial:'',
                idComercialInterno: 0,
                telefonoComercial:'',
                correoComercial:'',
                comisionYear1: 0,
                comisionYear2: 0,
                idCompany: 0,
                zonaGeograficaComercial: "",
                idResponsable: "",
                Responsable: "",
                idUser:'',
                createdAt:'',
                updatedAt:''
            },
            toggledClearRows: !this.state.toggledClearRows,
            saveComercialButtonDisabled: true,
            updateComercialButtonDisabled: true,
            createdAt: '',
            fechaBaja: ''
        });

        this.formRef.current.reset();

    };


    buscarComercial = async () => {


                var companyIdUser = this.state.companyIdUser;
        var user = this.state.userId.toString()
        var CIF = this.state.filters.cifSearch
        var nombre = this.state.filters.nombreSearch
        var apellidos = this.state.filters.apellidoSearch

        var dataFilters = {
            'idCompany': "",
            'cups': "",
            'Usuario': "",
            'NIFComercial': CIF,
            'nombreComercial': nombre,
            'apellido1Comercial': apellidos
        };

        const valores = JSON.stringify(dataFilters)
        const headers = {
            'Authorization': 'Bearer my-token',
            'My-Custom-Header': 'foobar',
            'x-api-key': '$2y$10$bcT1Ukm4V/6/z5GvitZv0unu8I91mpixuwUO6z5CyEGev9DDDY28W'
        };

        let direccionFile = "https://datapi.psgestion.es/Comercial/listado?id=" + companyIdUser + "&user=" + user;

        await axios.get(direccionFile, { headers, params: { valores } }).
            then(result => {

                if(result.data.rows.length>0){
                let data = result.data.rows
                this.setState({ ComercialDetailTotal: result.data.rows });

            } else {
            this.warningAlertNIF();
                };

            }).catch((error) => {
                console.log('Failure')
                this.warningAlert()

            });

    };



    showTable = () => {
        if (this.state.show === false) {
            this.setState({
                show: true
            });

            const reftp = document.getElementById("tablaLecturas")
            reftp ?.scrollIntoView({ behavior: "smooth" })

} else {

            this.setState({
                show: false
            });
        }
    }




    updateHandler(props) {

        if (this.state.updateDataComercial && ( this.state.formComercial.nombreComercial && this.state.formComercial.apellido1Comercial && this.state.formComercial.apellido2Comercial && this.state.formComercial.telefonoComercial && this.state.formComercial.correoComercial)) {

            this.confirmAlertUpdate()

        } else {
            this.warningAlertFiltros()
        }
    };




    saveHandler(props) {

        if (this.state.saveDataComercial && (this.state.formComercial.nombreComercial && this.state.formComercial.apellido1Comercial && this.state.formComercial.apellido2Comercial && this.state.formComercial.telefonoComercial && this.state.formComercial.correoComercial)) {

            this.confirmAlertSave()

        } else {

            this.warningAlertFiltros()
        }

    };



    onUpdate = async () => {


        if (this.state.updateDataComercial == true) {


            //const fechaActivacion = this.state.fechaActivacion;
            const companyId = this.state.companyIdUser;
            const userId = this.state.userId.toString();

            //const idContrato = this.state.contrato;

            var createdAt = this.state.formComercial.createdAt;
            var updatedAt = this.state.formComercial.updatedAt;

            if (!createdAt) {
                createdAt = moment().format("YYYY-MM-DDTHH:mm:ss");
            }
            if (!updatedAt) {
                updatedAt = moment().format("YYYY-MM-DDTHH:mm:ss");
            }



                var datosComercial = {

                     nombreComercial: this.state.formComercial.nombreComercial.toUpperCase(),
                     apellido1Comercial: this.state.formComercial.apellido1Comercial.toUpperCase(),
                     apellido2Comercial: this.state.formComercial.apellido2Comercial.toUpperCase(),
                     NIFComercial: this.state.formComercial.NIFComercial.toUpperCase(),
                     idComercial: this.state.formComercial.idComercial,
                     idComercialInterno: this.state.formComercial.idComercialInterno,
                     telefonoComercial: this.state.formComercial.telefonoComercial,
                     correoComercial: this.state.formComercial.correoComercial,
                     comisionYear1: this.state.formComercial.comisionYear1,
                     comisionYear2: this.state.formComercial.comisionYear2,
                     idCompany: Number(companyId),
                     zonaGeograficaComercial: this.state.formComercial.zonaGeograficaComercial,
                     idResponsable: this.state.formComercial.idResponsable,
                     Responsable: this.state.formComercial.Responsable.toUpperCase(),
                     idUser: userId,
                     createdAt: createdAt,
                     updatedAt: updatedAt

                }


                var resultadoUpdate: any = [];
                var resultadoView: any = [];



                const headers = {
                    'Authorization': 'Bearer my-token',
                    'My-Custom-Header': 'foobar',
                    'x-api-key': '$2y$10$bcT1Ukm4V/6/z5GvitZv0unu8I91mpixuwUO6z5CyEGev9DDDY28W'
                };
                let direccionFile = "https://datapi.psgestion.es/Comercial/create?id="+companyId+"&user="+userId;


                axios.post(direccionFile, datosComercial, { headers, responseType: 'json'})
                     .then (res=> {
                                console.log('Actualización correcta', res)
                                console.log('Datos del registro: ', res.data)
                                this.setState({
                                    updateDataComercial: false,
                                    updateComercialButtonDisabled: true
                                })
                                this.successAlert()

                     }).catch (error =>{
                                console.log ('Failure')
                                console.log('Error en el proceso: ', error)
                                //notifyErrorDB();
                     });


            await this.fetchComercial();


        }

    };


    onSave = async () => {

        //const fechaActivacion = this.state.fechaActivacion;
        const companyId = this.state.companyIdUser;
        const userId = this.state.userId.toString();


        //const idContrato = this.state.contrato;


        var createdAt = this.state.formComercial.createdAt;
        if (!createdAt) {
            createdAt = moment().format("YYYY-MM-DDTHH:mm:ss");
        }

        var updatedAt = null

        if (this.state.saveDataComercial == true) {

            var datosComercial = {

                nombreComercial: this.state.formComercial.nombreComercial.toUpperCase(),
                apellido1Comercial: this.state.formComercial.apellido1Comercial.toUpperCase(),
                apellido2Comercial: this.state.formComercial.apellido2Comercial.toUpperCase(),
                NIFComercial: this.state.formComercial.NIFComercial.toUpperCase(),
                idComercial: this.state.formComercial.idComercial,
                idComercialInterno: this.state.formComercial.idComercialInterno,
                telefonoComercial: this.state.formComercial.telefonoComercial,
                correoComercial: this.state.formComercial.correoComercial,
                comisionYear1: this.state.formComercial.comisionYear1,
                comisionYear2: this.state.formComercial.comisionYear2,
                idCompany: Number(companyId),
                zonaGeograficaComercial: this.state.formComercial.zonaGeograficaComercial,
                idResponsable: this.state.formComercial.idResponsable,
                Responsable: this.state.formComercial.Responsable,
                idUser: userId,
                createdAt: createdAt,
                updatedAt: updatedAt

            }


            var resultadoUpdate: any = [];
            var resultadoView: any = [];



            const headers = {
                'Authorization': 'Bearer my-token',
                'My-Custom-Header': 'foobar',
                'x-api-key': '$2y$10$bcT1Ukm4V/6/z5GvitZv0unu8I91mpixuwUO6z5CyEGev9DDDY28W'
            };
            let direccionFile = "https://datapi.psgestion.es/Comercial/create?id="+companyId+"&user="+userId;


            axios.post(direccionFile, datosComercial, { headers, responseType: 'json'})
                 .then (res=> {
                            console.log('Actualización correcta', res)
                            console.log('Datos del registro: ', res.data)
                            this.setState({
                                saveDataComercial: false,
                                saveComercialButtonDisabled: true
                            });
                            this.successAlert()

                 }).catch (error =>{
                            console.log ('Failure')
                            console.log('Error en el proceso: ', error)
                            //notifyErrorDB();
                 });


            // /cliente/create

            this.clearForm();

        }

    };


    handleChangeDate = (value, formattedValue, id) => {

        var formData = { ...this.state.formComercial };


        const name = id;
        var valueDay = formattedValue


        if (this.state.ComercialSeleccionado) {

            if (name == 'createdAt') {
                //console.log('VALOR NOMBRE Comercial ------> ', value, event.target.name)
                //this.setState ({ComercialDetail.nombreComercial : event.target.value});
                this.setState({
                    updateDataComercial: true,
                    createdAt: value,
                    saveComercialButtonDisabled: true,
                    updateComercialButtonDisabled: false
                });

            }

            if (name == 'fechaBaja') {
                //this.setState ({ComercialDetail.nombreComercial : event.target.value});
                this.setState({
                    updateDataComercial: true,
                    fechaBaja: value,
                    saveComercialButtonDisabled: true,
                    updateComercialButtonDisabled: false
                });
            }
        }
        //console.log(formData)
        formData[name] = valueDay;
        //if (value === "" || value === null) {
        //    formData[name] = 0;
        //}
        this.setState({ formComercial: formData });
    };

    handleChange = state => {
        // eslint-disable-next-line no-console
        //console.log('state', state.selectedRows[0]);
        //console.log(state.selectedCount)

        var Comercial = '';

        if (state.selectedCount === 1) {
            var existeComercial = ''
            Comercial = state.selectedRows[0].idComercial;

            this.setState({ ComercialDetail: state.selectedRows[0] });
            let existeFechaAlta = state.selectedRows[0].createdAt
            let existeFechaBaja = state.selectedRows[0].fechaBaja
            existeComercial = state.selectedRows[0].idComercial

            if (existeFechaAlta && existeFechaBaja) {

                this.setState({
                    inputDisabled: true,
                    updateComercialButtonDisabled: false
                })
            } else if (!existeFechaAlta && !existeFechaBaja) {
                this.setState({
                    inputDisabled: false,
                    updateComercialButtonDisabled: true
                })
            }

            if (existeComercial) {

                let data = state.selectedRows[0]
                let fechaAltaAux = ""
                let fechaBajaAux = ""

                if (data.createdAt) {
                    fechaAltaAux = data.createdAt
                }

                if (data.fechaBaja) {
                    fechaBajaAux = data.fechaBajaAux
                }

                this.setState({ idComercial: this.state.ComercialDetail.idComercial });
                this.setState({
                    formComercial: {

                        nombreComercial: state.selectedRows[0].nombreComercial,
                        apellido1Comercial: state.selectedRows[0].apellido1Comercial,
                        apellido2Comercial: state.selectedRows[0].apellido2Comercial,
                        NIFComercial: state.selectedRows[0].NIFComercial,
                        idComercial: state.selectedRows[0].idComercial,
                        idComercialInterno: state.selectedRows[0].idComercialInterno,
                        telefonoComercial: state.selectedRows[0].telefonoComercial,
                        correoComercial: state.selectedRows[0].correoComercial,
                        comisionYear1: parseFloat(state.selectedRows[0].comisionYear1),
                        comisionYear2: parseFloat(state.selectedRows[0].comisionYear2),
                        idCompany: Number(state.selectedRows[0].idCompany),
                        zonaGeograficaComercial: state.selectedRows[0].zonaGeograficaComercial,
                        idResponsable: state.selectedRows[0].idResponsable,
                        Responsable: state.selectedRows[0].Responsable,
                        idUser: state.selectedRows[0].idUser,
                        createdAt: state.selectedRows[0].createdAt,
                        updatedAt: state.selectedRows[0].updatedAt

                    },
                    ComercialSeleccionado: true,
                    createdAt:fechaAltaAux,
                    fechaBaja: fechaBajaAux
                });

            } else {
                this.setState({
                    ComercialSeleccionado: false,
                    formComercial: {
                        nombreComercial:'',
                        apellido1Comercial:'',
                        apellido2Comercial:'',
                        NIFComercial:'',
                        idComercial:'',
                        idComercialInterno: 0,
                        telefonoComercial:'',
                        correoComercial:'',
                        comisionYear1: 0,
                        comisionYear2: 0,
                        idCompany: 0,
                        zonaGeograficaComercial: "",
                        idResponsable: "",
                        Responsable: "",
                        idUser:'',
                        createdAt:'',
                        updatedAt:''
                    }
                });
            }
        } else {

            this.setState({
                ComercialSeleccionado: false,
                inputDisabled: false,
                updateComercialButtonDisabled: true,
                formComercial: {
                    nombreComercial:'',
                    apellido1Comercial:'',
                    apellido2Comercial:'',
                    NIFComercial:'',
                    idComercial:'',
                    idComercialInterno: 0,
                    telefonoComercial:'',
                    correoComercial:'',
                    comisionYear1: 0,
                    comisionYear2: 0,
                    idCompany: 0,
                    zonaGeograficaComercial: "",
                    idResponsable: "",
                    Responsable: "",
                    idUser:'',
                    createdAt:'',
                    updatedAt:''
                }
            });



        };

    }



    handleClearRows = () => {
        this.setState({ toggledClearRows: !this.state.toggledClearRows })
    }

    render() {

        const formComercial: any = this.state.formComercial;
        //console.log('VALOR DE THIS:STATE EN RENDER SIPDETAILSCONTRATOS ------> ', this.state)
        const createdAt = this.state.createdAt
        const fechaBaja = this.state.fechaBaja

if(this.state.userGroupId == 1000) {
        return (
            <>
                <Header title={'Información Comercial'} />
                <Container className=" mt--7" fluid>

                    <Card className="mb-4">
                        <CardHeader>
                            <Button
                                onClick={this.showFiltersToggle}
                                size="sm"
                                className="float-right"
                            >
                                <i className={"ni " + this.getFilterIcon()} />
                            </Button>
                            <h3 className="mb-0">Filtrar</h3>
                        </CardHeader>
                        <Collapse isOpen={this.state.showFilters}>
                            <CardBody>
                                <Form innerRef={this.formRef}>
                                    <Row>
                                        <Col md="12" lg="3">
                                            <FormGroup>
                                                <Label className="form-control-label" htmlFor="ComercialsRef">
                                                    NOMBRE COMERCIAL
                                  </Label>
                                                <Input
                                                    id="nombreSearch"
                                                    placeholder="Ingresar nombre del Comercial"
                                                    name="nombreSearch"
                                                    onChange={this.onChangeFieldSearch}
                                                    value={this.state.filters.nombreSearch}
                                                    type="text"
                                                    disabled={this.state.inputNombreDisabled}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md="12" lg="3">
                                            <FormGroup>
                                                <Label className="form-control-label" htmlFor="apellidoSearch">
                                                    APELLIDOS
                                  </Label>
                                                <Input
                                                    id="apellidoSearch"
                                                    placeholder="Ingresar apellidos del Comercial"
                                                    name="apellidoSearch"
                                                    onChange={this.onChangeFieldSearch}
                                                    value={this.state.filters.apellidoSearch}
                                                    type="text"
                                                    disabled={this.state.inputApellidosDisabled}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col lg="2">
                                            <FormGroup>
                                                <Label className="form-control-label" htmlFor="cifSearch">
                                                    DNI/CIF
                                  </Label>
                                                <Input
                                                    id="cifSearch"
                                                    placeholder="Ingresar CIF del Comercial"
                                                    name="cifSearch"
                                                    onChange={this.onChangeFieldSearch}
                                                    value={this.state.filters.cifSearch}
                                                    type="text"
                                                    disabled={this.state.inputCifDisabled}
                                                />
                                                {this.state.cifError ? <FormText color="red">Comprobar CIF/DNI</FormText> : ''}
                                            </FormGroup>
                                        </Col>
                                        <Col className="text-right">
                                            <Button onClick={this.clearForm}>
                                                <i className={"ni ni-fat-remove"} /> Limpiar
                                    </Button>
                                            <Button
                                                color="primary"
                                                onClick={this.buscarComercial}
                                                disabled={this.state.buscarButtonDisabled}
                                            >
                                                <i className={"bi bi-search"} /> Buscar
                                    </Button>
                                        </Col>
                                    </Row>
                                    <Card className="shadow">
                                        <DataTable
                                            title="LISTADO DE COMERCIALES"
                                            columns={columnsDatosComercial}
                                            data={this.state.ComercialDetailTotal}
                                            selectableRows
                                            //selectableRowsComponent={radio} // Pass the function only
                                            selectableRowsComponentProps={{ type: 'radio' }}// optionally, pass Material Ui supported props down to our custom checkbox
                                            selectableRowsSingle
                                            sortIcon={sortIcon} // use a material icon for our sort icon. rdt will rotate the icon 180 degrees for you           fixedHeader = {true}
                                            noDataComponent={"Sin datos para mostrar"}
                                            pagination
                                            paginationPerPage={10}
                                            paginationRowsPerPageOptions={[10, 15, 20, 25, 30, 40]}
                                            onSelectedRowsChange={this.handleChange}
                                            clearSelectedRows={this.state.toggledClearRows}
                                            highlightOnHover
                                            striped
                                            pointerOnHover
                                            selectableRowsHighlight
                                            customStyles={customStyles}
                                            contextMessage={{ singular: 'Comercial', plural: 'Comercials', message: 'seleccionado' }}
                                            subHeader
                                            progressComponent={<Circular />}
                                        />
                                    </Card>
                                </Form>
                            </CardBody>
                        </Collapse>
                    </Card>




                    <Row>
                        <div className="col">
                            <Card className="shadow">
                                <CardBody>
                                    <Row>
                                        <Col>
                                            <button
                                                className="btn btn-sm btn-primary"
                                                onClick={this.props.history.goBack}
                                            >
                                                Atrás
                                                                </button>
                                        </Col>
                                        <Col className="col-auto d-flex">
                                            <Card className="p-3 bg-light">

                                                <FormGroup>
                                                    <Col>
                                                        <Button disabled={this.state.updateComercialButtonDisabled} id="UpdateComercialProptooltip" color="danger" size="m" type="button" className="btn-icon btn-2"
                                                            onClick={() => { this.updateHandler(this.state.updateDataComercial) }}
                                                        >
                                                            <span className="btn-inner--icon">
                                                                <i className="bi bi-person-lines-fill" style={{ fontSize:25 }}></i>
                                                            </span>
                                                            <span className="btn-inner--text">Actualizar</span>
                                                            <UncontrolledTooltip delay={0} placement="top" target="UpdateComercialProptooltip">
                                                                MODIFICAR COMERCIAL EXISTENTE
                                                    </UncontrolledTooltip>
                                                        </Button>

                                                        <Button disabled={this.state.saveComercialButtonDisabled} id="guardarComercialProptooltip" color="info" size="m" type="button" className="btn-icon btn-2"
                                                            onClick={() => { this.saveHandler(this.state.saveDataComercial) }}
                                                        >
                                                            <span className="btn-inner--icon">
                                                                <i className="bi bi-person-plus-fill" style={{ fontSize:25 }}></i>
                                                            </span>
                                                            <span className="btn-inner--text">Insertar</span>
                                                            <UncontrolledTooltip delay={0} placement="top" target="guardarComercialProptooltip">
                                                                CREAR COMERCIAL NUEVO
                                                                            </UncontrolledTooltip>
                                                        </Button>
                                                    </Col>
                                                </FormGroup>

                                            </Card>
                                        </Col>
                                    </Row>


                                    <div>
                                        <CardTitle>DATOS DEL COMERCIAL</CardTitle>
                                    </div>

                                    <Row>
                                        <Col>
                                            <FormGroup>

                                                <Label className="form-control-label" htmlFor="nombreComercial">
                                                    Nombre Comercial
                                                                                    </Label>
                                                <Input
                                                    className="text-primary"
                                                    id="nombreComercial"
                                                    placeholder=""
                                                    name="nombreComercial"
                                                    onChange={this.onChangeFieldComercial}
                                                    value={formComercial.nombreComercial}
                                                    type="text"
                                                    required
                                                    disabled={this.state.inputDisabled}
                                                />
                                            </FormGroup>
                                        </Col>

                                        <Col>
                                            <FormGroup>

                                                <Label className="form-control-label" htmlFor="apellido1Comercial">
                                                    Primer Apellido
                                                                                    </Label>
                                                <Input
                                                    id="apellido1Comercial"
                                                    placeholder=""
                                                    name="apellido1Comercial"
                                                    onChange={this.onChangeFieldComercial}
                                                    value={formComercial.apellido1Comercial}
                                                    type="text"
                                                    required
                                                    disabled={this.state.inputDisabled}
                                                />
                                            </FormGroup>
                                        </Col>


                                        <Col>
                                            <FormGroup>

                                                <Label className="form-control-label" htmlFor="apellido2Comercial">
                                                    Segundo Apellido
                                                                                    </Label>
                                                <Input
                                                    id="apellido2Comercial"
                                                    placeholder=""
                                                    name="apellido2Comercial"
                                                    onChange={this.onChangeFieldComercial}
                                                    value={formComercial.apellido2Comercial}
                                                    type="text"
                                                    required
                                                    disabled={this.state.inputDisabled}
                                                />
                                            </FormGroup>
                                        </Col>



                                        <Col>
                                            <FormGroup>
                                                <Label className="form-control-label" htmlFor="NIFComercial">
                                                    NIF/CIF
                                                                                    </Label>
                                                <Input
                                                    id="NIFComercial"
                                                    placeholder=""
                                                    name="NIFComercial"
                                                    onChange={this.onChangeFieldComercial}
                                                    value={formComercial.NIFComercial}
                                                    type="text"
                                                    required
                                                    disabled={this.state.inputDisabled}
                                                />
                                                {this.state.NIFComercialError ? <FormText color="red">Comprobar NIF/CIF</FormText> : ''}
                                            </FormGroup>
                                        </Col>

                                        </Row>

                                        <Row>
                                            <Col md="2">
                                                <FormGroup>

                                                    <Label className="form-control-label" htmlFor="telefonoComercial">
                                                        Teléfono Contacto
                                                                                        </Label>
                                                    <Input
                                                        className="text-primary"
                                                        id="telefonoComercial"
                                                        placeholder=""
                                                        name="telefonoComercial"
                                                        onChange={this.onChangeFieldComercial}
                                                        value={formComercial.telefonoComercial}
                                                        type="text"
                                                        required
                                                        disabled={this.state.inputDisabled}
                                                    />
                                                </FormGroup>
                                            </Col>

                                            <Col md="3">
                                                <FormGroup>

                                                    <Label className="form-control-label" htmlFor="correoComercial">
                                                        Correo Contacto
                                                                                        </Label>
                                                    <Input
                                                        id="correoComercial"
                                                        placeholder=""
                                                        name="correoComercial"
                                                        onChange={this.onChangeFieldComercial}
                                                        value={formComercial.correoComercial}
                                                        type="text"
                                                        required
                                                        disabled={this.state.inputDisabled}
                                                    />
                                                </FormGroup>
                                            </Col>


                                        </Row>

                                        <Row>
                                            <Col md="2">
                                                <FormGroup>

                                                    <Label className="form-control-label" htmlFor="comisionYear1">
                                                        COMISION AÑO 1
                                                                                        </Label>
                                                    <Input
                                                        id="comisionYear1"
                                                        placeholder=""
                                                        name="comisionYear1"
                                                        step="0.001"
                                                        onChange={this.onChangeFieldComercial}
                                                        value={formComercial.comisionYear1||''}
                                                        type="number"
                                                        required
                                                        disabled={this.state.inputDisabled}
                                                    />

                                                </FormGroup>
                                            </Col>

                                            <Col md="2">
                                                <FormGroup>

                                                    <Label className="form-control-label" htmlFor="comisionYear2">
                                                        COMISION AÑO 2
                                                                                        </Label>
                                                    <Input
                                                        id="comisionYear2"
                                                        placeholder=""
                                                        name="comisionYear2"
                                                        step="0.001"
                                                        onChange={this.onChangeFieldComercial}
                                                        value={formComercial.comisionYear2||''}
                                                        type="number"
                                                        required
                                                        disabled={this.state.inputDisabled}
                                                    />

                                                </FormGroup>
                                            </Col>

                                            <Col md="2">
                                                <FormGroup>

                                                    <Label className="form-control-label" htmlFor="zonaGeograficaComercial">
                                                        ZONA GEOGRAFICA
                                                                                        </Label>
                                                    <Input
                                                        className="text-primary"
                                                        id="zonaGeograficaComercial"
                                                        placeholder=""
                                                        name="zonaGeograficaComercial"
                                                        onChange={this.onChangeFieldComercial}
                                                        value={formComercial.zonaGeograficaComercial}
                                                        type="text"
                                                        required
                                                        disabled={this.state.inputDisabled}
                                                    />
                                                </FormGroup>
                                            </Col>

                                            <Col md="3">
                                                <FormGroup>

                                                    <Label className="form-control-label" htmlFor="Responsable">
                                                        RESPONSABLE
                                                                                        </Label>
                                                    <Input
                                                        id="Responsable"
                                                        placeholder=""
                                                        name="Responsable"
                                                        onChange={this.onChangeFieldComercial}
                                                        value={formComercial.Responsable}
                                                        type="text"
                                                        required
                                                        disabled={this.state.inputDisabled}
                                                    />
                                                </FormGroup>
                                            </Col>


                                        </Row>



                                    <Row>

                                        <Col md="2">
                                            <FormGroup>

                                                <Label className="form-control-label" htmlFor="createdAt">
                                                    Fecha Alta Registro
                                                                                    </Label>

                                                <DatePicker id="createdAt"
                                                    value={createdAt}
                                                    onChange={(v, f) => this.handleChangeDate(v, f, "createdAt")}
                                                    instanceCount={1}
                                                    dateFormat={"DD-MM-YYYY"}
                                                    dayLabels={spanishDayLabels}
                                                    monthLabels={spanishMonthLabels}
                                                    disabled={this.state.inputDisabled}

                                                />

                                            </FormGroup>

                                        </Col>


                                        <Col md="2">
                                            <FormGroup>

                                                <Label className="form-control-label" htmlFor="fechaBaja">
                                                    Fecha Baja Registro
                                                </Label>

                                                <DatePicker id="fechaBaja"
                                                    value={fechaBaja}
                                                    onChange={(v, f) => this.handleChangeDate(v, f, "fechaBaja")}
                                                    instanceCount={2}
                                                    dateFormat={"DD-MM-YYYY"}
                                                    dayLabels={spanishDayLabels}
                                                    monthLabels={spanishMonthLabels}
                                                    disabled={this.state.inputDisabled}

                                                />


                                            </FormGroup>
                                        </Col>

                                    </Row>



                                </CardBody>
                            </Card>
                            {this.state.alert}
                        </div>
                    </Row>
                </Container>

            </>
        );
    } else {
      return (
        <>
          <Header title={"No tienes permisos para acceder a este apartado. Consulte con nuestro equipo comercial"}/>
        </>
      );
    }
    }
}


export default withApollo(Comercial);
