
/*eslint-disable*/
import React from "react";
import LoadingOverlay from "react-loading-overlay";

// reactstrap components
import { Card, CardBody, CardTitle, Col, ListGroup, ListGroupItem, Row } from "reactstrap";


interface RateGasInformationInterface {
  isLoading: boolean,
  cardClass?: string,
  sip: {};
}
class RateGasInformation extends React.Component<RateGasInformationInterface> {
  constructor(props) {
    super(props);
  }

  renderCircle(top, bottom) {
    return <Row className="border-pot d-flex ml-0 text-center mt-2" key={top}>
      <Col className="col-auto pt-2 pb-2 radius-pot bg-energia">
        {top}
      </Col>
      <Col className="col pl-4 pr-4 pt-2 pb-2 ml-1 bg-units">
        {bottom} kWh
      </Col>
    </Row>;
  }

  renderCircleP(top, bottom) {
    return <Row className="border-pot d-flex ml-0 text-center mt-2" key={top}>
      <Col className="col-auto pt-2 pb-2 radius-pot bg-potencia">
        {top}
      </Col>
      <Col className="col pl-4 pr-4 pt-2 pb-2 ml-1 bg-units">
        {bottom} kW
      </Col>
    </Row>;
  }

  renderCircles() {
    const sip: any = this.props.sip;
    var numeroPeriodos = 0;
    if(sip.codigoPeajeEnVigor == '35'){
      numeroPeriodos = 2;
    } else{numeroPeriodos = 1};
    const consumptions: any = [];
    for (var i = 1; i <= numeroPeriodos; i++) {
      let consumoEnergiaActiva = `ConsumoAnualEnWhP${i}`;
      let consumo = parseFloat(sip[consumoEnergiaActiva]);

            const element = this.renderCircle(`P${i}`, consumo);
      consumptions.push(element);
    }

    return consumptions;
  }



  render() {
    const sip: any = this.props.sip;

    const consumptions: any = this.renderCircles();
    const Whanual = (parseInt(sip.ConsumoAnualEnWhP1) + parseInt(sip.ConsumoAnualEnWhP2))

    return (
      // <Card id="rateInformation" className={this.props.cardClass}>
      //   <CardBody>
      //     <CardTitle><h2>Información de la tarifa</h2></CardTitle>
          <LoadingOverlay
            active={this.props.isLoading}
            spinner
            text="Cargando...">
            <Row>
              <Col>
                <div id="consumptions">
                  {consumptions}
              </div>
              </Col>
            </Row>




          </LoadingOverlay>
        // </CardBody>
      //</Card>
    );
  }
}

export default RateGasInformation;
