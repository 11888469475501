/*eslint-disable*/
import React from "react";
import LoadingOverlay from "react-loading-overlay";

// reactstrap components
import { Card, CardBody, CardTitle, Col, ListGroup, ListGroupItem, Row } from "reactstrap";
import { isEmptyBindingElement } from "typescript";
import moment from "moment";


interface SupplyInformationInterface {
  isLoading: boolean,
  cardClass?: string,
  sip: {};
}
class SupplyInformation extends React.Component<SupplyInformationInterface> {
  constructor(props) {
    super(props);
  }

  render() {
    const sip: any = this.props.sip;
    if(sip.cups === undefined) {
      return '';
    }
    console.log('RELACION DE FECHAS  ---->')
    console.log(sip.fechaAltaSuministro)
      console.log(sip.fechaUltimoCambioComercializador)
        console.log(sip.fechaUltimoMovimientoContrato)
          console.log(sip.fechaEmisionBIE)
            console.log(sip.fechaEmisionAPM)

            const releaseDate = moment(sip.fechaAltaSuministro).isValid()?moment(sip.fechaAltaSuministro).format('DD-MM-YYYY') : "";
            const lastChangeMarketer = moment(sip.fechaUltimoCambioComercializador).isValid()?moment(sip.fechaUltimoCambioComercializador).format('DD-MM-YYYY') : "";
            const lastContractMovement = moment(sip.fechaUltimoMovimientoContrato).isValid()?moment(sip.fechaUltimoMovimientoContrato).format('DD-MM-YYYY') : "";
            const bieDate = moment(sip.fechaEmisionBIE).isValid()?moment(sip.fechaEmisionBIE).format('DD-MM-YYYY') : "";
            const apmDate = moment(sip.fechaEmisionAPM).isValid()?moment(sip.fechaEmisionAPM).format('DD-MM-YYYY') : "";

  /*const releaseDate = new Date(sip.fechaAltaSuministro).toISOString().split("T")[0];
    const lastChangeMarketer = new Date(sip.fechaUltimoCambioComercializador).toISOString().split("T")[0];
    const lastContractMovement = new Date(sip.fechaUltimoMovimientoContrato).toISOString().split("T")[0];
    const bieDate = sip.fechaEmisionBIE !== null ? new Date(sip.fechaEmisionBIE).toISOString().split("T")[0] : null;
    const apmDate = sip.fechaEmisionAPM !== null ? new Date(sip.fechaEmisionAPM).toISOString().split("T")[0] : null;*/
    const nonContratableReason = sip.codigoPSContratable == 0 ? <ListGroupItem><strong>Motivo no contratable:</strong> {sip.motivoEstadoNoContratable}</ListGroupItem> : '';

    return (
      <Card id="supplyInformation" className={this.props.cardClass}>
        <CardBody>
          <CardTitle>Información del suministro</CardTitle>
          <LoadingOverlay
            active={this.props.isLoading}
            spinner
            text="Cargando...">
            <Row>
              <Col md="6">
                <ListGroup>
                  <ListGroupItem><strong>CUPS:</strong> {sip.cups}</ListGroupItem>
                  <ListGroupItem><strong>Titular:</strong> {sip.nombreCompletoTitular}</ListGroupItem>
                  <ListGroupItem><strong>NIF/CIF:</strong> {sip.CIF}</ListGroupItem>
                  <ListGroupItem><strong>Direccion:</strong> {sip.direccion_ps}</ListGroupItem>
                  <ListGroupItem><strong>Provincia:</strong> {sip.nombreProvinciaPS}</ListGroupItem>
                  <ListGroupItem><strong>Localidad:</strong> {sip.nombreMunicipioPS}</ListGroupItem>
                  <ListGroupItem><strong>Codigo Autoconsumo:</strong> {sip.codigoAutoconsumo}</ListGroupItem>
                  <ListGroupItem><strong>Punto suministro contratable:</strong> {sip.codigoPSContratable == 1 || sip.codigoPSContratable == null ? 'Si' : 'No' }</ListGroupItem>
                  {nonContratableReason}
                  </ListGroup>
              </Col>
              <Col md="6">
                <ListGroup>
                <ListGroupItem><strong>CNAE:</strong> {sip.CNAE}</ListGroupItem>
                <ListGroupItem><strong>Fecha de alta:</strong> {releaseDate}</ListGroupItem>
                  <ListGroupItem><strong>Ultimo cambio comercializador:</strong> {lastChangeMarketer}</ListGroupItem>
                  <ListGroupItem><strong>Ultimo movimiento contrato:</strong> {lastContractMovement}</ListGroupItem>
                  <ListGroupItem><strong>Fecha Emision BIE:</strong> {bieDate}</ListGroupItem>
                  <ListGroupItem><strong>Fecha Emision APM:</strong> {apmDate}</ListGroupItem>
                  <ListGroupItem><strong>Bono Social:</strong> { sip.aplicacionBonoSocial === 0 ? 'No' : 'Si' }</ListGroupItem>
                  <ListGroupItem><strong>Impagos:</strong> { sip.informacionImpagos > 0 ? sip.informacionImpagos+'€' : '-' }</ListGroupItem>

                </ListGroup>
              </Col>
            </Row>
          </LoadingOverlay>
        </CardBody>
      </Card>
    );
  }
}

export default SupplyInformation;
