import Reflux from "reflux";
import { UserStore, UserStoreActions } from "../../store/UserStore";
import { withApollo } from "react-apollo";
import { GET_USER_DATA } from "../../queries/users/users.queries";
import {store} from 'state-pool'

class GetUserData extends Reflux.Component {
  constructor(props) {
    super(props);
    this.store = UserStore;
  }
  async componentDidMount() {
    const userFromStorage = localStorage.getItem("user");

    if (userFromStorage) {
      try {
        const parsed = JSON.parse(userFromStorage);

        UserStoreActions.update(parsed);
      } catch (e) {}
    }
    const resp = await this.props.client.query({
      query: GET_USER_DATA,
    });

    const user = resp.data.userData;
    store.setState("userGlobal",user)
    localStorage.setItem("user", JSON.stringify(user));
    UserStoreActions.update(user);
  }
  render() {
    return null;
  }
}

export default withApollo(GetUserData);
