import gql from "graphql-tag";

export const CREATE_SIPS_COMPARATIVAS_LIBRE = gql`
	mutation createComparativaLibre( $data: ComparativaLibreInput! ) {
		createComparativaLibre( data: $data ){
			companyId
			nombreOferta
			idComercializadora
			nombreComercializadora
			idComercializadoraActual
			nombreComercializadoraActual
			datosPreciosActual
			userId
			fechaPropuesta
			codigoPostalPS
			codigoMunicipioPS
			nombreMunicipioPS
			codigoProvinciaPS
			nombreProvinciaPS
			codigoAutonomiaPS
			nombreAutonomiaPS
			esAprobada
			datosPropuesta
			correoContacto
			cups
			tipo
			datosContrato
			razonSocial
			 NIF
			 DireccionFiscal
			 CodigoPostalTitular
			 TelefonoFijo
			 TelefonMovil
			 Email
			 TipoCliente
			 ObservacionesCliente
			 DireccionPS
			 CodigoPostalPS
			 TelefonoPS
			 ObservacionesPuntoSuministro
			 GDPR
			 nombreTitular
			 apellido1Titular
			 apellido2Titular
			 desAutonomiaTitular
			 desProvinciaTitular
			 desMunicipioTitular
			 tipoViaTitular
			 viaTitular
			 numFincaTitular
			 portalTitular
			 escaleraTitular
			 pisoTitular
			 puertaTitular
			 iban
			 tipoViaPS
			 viaPS
			 numFincaPS
			 portalPS
			 escaleraPS
			 pisoPS
			 puertaPS



		}
	}
`;

export const CREATE_SIPS_COMPARATIVAS_LIBRE_MP = gql`
	mutation createComparativaLibreMP( $data: ComparativaLibreInput! ) {
		createComparativaLibreMP( data: $data ){
			companyId
			nombreOferta
			idComercializadora
			nombreComercializadora
			idComercializadoraActual
			nombreComercializadoraActual
			datosPreciosActual
			userId
			fechaPropuesta
			codigoPostalPS
			codigoMunicipioPS
			nombreMunicipioPS
			codigoProvinciaPS
			nombreProvinciaPS
			codigoAutonomiaPS
			nombreAutonomiaPS
			esAprobada
			datosPropuesta
			correoContacto
			cups
			tipo
			datosContrato
			razonSocial
			 NIF
			 DireccionFiscal
			 CodigoPostalTitular
			 TelefonoFijo
			 TelefonMovil
			 Email
			 TipoCliente
			 ObservacionesCliente
			 DireccionPS
			 CodigoPostalPS
			 TelefonoPS
			 ObservacionesPuntoSuministro
			 GDPR
			 nombreTitular
			 apellido1Titular
			 apellido2Titular
			 desAutonomiaTitular
			 desProvinciaTitular
			 desMunicipioTitular
			 tipoViaTitular
			 viaTitular
			 numFincaTitular
			 portalTitular
			 escaleraTitular
			 pisoTitular
			 puertaTitular
			 iban
			 tipoViaPS
			 viaPS
			 numFincaPS
			 portalPS
			 escaleraPS
			 pisoPS
			 puertaPS



		}
	}
`;


export const LIST_SIPS_COMPARATIVAS_LIBRE_BY_CUP = gql`
query comparativasLibresByCups( $cups: String! ) {
	comparativasLibresByCups( cups: $cups ){
		id
		companyId
		nombreOferta
		idComercializadora
		nombreComercializadora
		idComercializadoraActual
		nombreComercializadoraActual
		datosPreciosActual
		userId
		fechaPropuesta
		codigoPostalPS
		codigoMunicipioPS
		nombreMunicipioPS
		codigoProvinciaPS
		nombreProvinciaPS
		codigoAutonomiaPS
		nombreAutonomiaPS
		esAprobada
		datosPropuesta
		correoContacto
		cups
		tipo
	}
}
`;


export const DATA_SIPS_COMPARATIVA_LIBRE_BY_ID = gql`
query comparativaLibreData( $id: Int! ) {
	comparativaLibreData( id: $id ){
		id
		companyId
		nombreOferta
		idComercializadora
		nombreComercializadora
		idComercializadoraActual
		nombreComercializadoraActual
		datosPreciosActual
		userId
		fechaPropuesta
		codigoPostalPS
		codigoMunicipioPS
		nombreMunicipioPS
		codigoProvinciaPS
		nombreProvinciaPS
		codigoAutonomiaPS
		nombreAutonomiaPS
		esAprobada
		datosPropuesta
		correoContacto
		cups
		datosContrato
		tipo


	}
}
`;

export const UPDATE_PROPUESTA_APROBADA = gql`
	query updateComparativaLibreAprobada( $id: id, $data: data ) {
		updateComparativaLibreAprobada(id: $id, data: $data ){
id
datosContrato

		}
	}
`;
