import React, { Component } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  Label,
  FormGroup,
  Input,
  Row,
  Button,
  Col,
  Collapse,
  FormText,
  CustomInput,
} from "reactstrap";
import DatePicker from "react-datepicker";

interface PropsInterface {
  onSubmit: Function;
}

export default class SipsGasMassiveExportsFilter extends Component<PropsInterface> {
  state = {
    showFilters: true,
    filters: {
      cups: "",
      startDate: new Date(),
      endDate: null,
    },
    dateRangePicker: {
      selection: {
        startDate: null,
        endDate: null,
        key: "selection",
      },
    },
  };

  showFiltersToggle = () => {
    const showFilters = !this.state.showFilters;
    this.setState({ showFilters });
  };

  getFilterIcon = () => {
    return this.state.showFilters ? "ni-bold-up" : "ni-bold-down";
  };

  onChangeField = (event) => {
    const name = event.target.name;
    let value = event.target.value;
    const filters = this.state.filters;
    if (name === "cups" || name === "CIF") {
      value = value.split("\n").join(",");
    }
    filters[name] = value;
    if (event.target.type === "select-one" && value === "0") {
      delete filters[name];
    }

    if (value === "" || value === null) {
      delete filters[name];
    }

    this.setState({ filters });
  };

  onChangeCheckbox = async (event) => {
    const name = event.target.name;
    const value = event.target.value === "on";
    const filters = this.state.filters;
    filters[name] = value;

    await this.setState({ filters });
  };

  async setDate(date, key) {
    const filters = this.state.filters;
    filters[key] = date;
    if (key === "startDate") {
      filters["endDate"] = null;
    }

    await this.setState({ filters });
  }

  submit = async () => {
    const filters = this.state.filters;
    await this.setState({ filters });
    this.props.onSubmit(this.state.filters);
  };

  render() {
    const buttonDisabled = typeof this.state.filters["cups"] != "string";

    return (
      <Card className="mb-4">
        <CardHeader>
          <Button
            onClick={this.showFiltersToggle}
            size="sm"
            className="float-right"
          >
            <i className={"ni " + this.getFilterIcon()} />
          </Button>
          <h3 className="mb-0">Exportación</h3>
        </CardHeader>
        <Collapse isOpen={this.state.showFilters}>
          <CardBody>
            {/* CUPS AND CIF */}
            <Row>
              <Col md="12" lg="6">
                <FormGroup>
                  <Label
                    className="form-control-label"
                    htmlFor="example5cols3Input"
                  >
                    CUPS
                  </Label>
                  <Input
                    id="cups"
                    name="cups"
                    type="textarea"
                    value={this.state.filters.cups}
                    onChange={this.onChangeField}
                  ></Input>
                  <FormText color="muted">
                    Para seleccionar multiples escribe el id del cup y presiona
                    enter
                  </FormText>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="12" lg="6">
                <Label
                  className="form-control-label"
                  htmlFor="example5cols3Input"
                >
                  Información a exportar
                </Label>
                <FormGroup>
                  <CustomInput
                    type="checkbox"
                    id="checkbox_suministro"
                    name="exportCups"
                    label="Suministro"
                    inline={false}
                    checked={true}
                    className={"mb-1"}
                    onChange={this.onChangeCheckbox}
                  />
                  <CustomInput
                    type="checkbox"
                    id="checkbox_lecturas"
                    name="exportMeasures"
                    label="Lecturas"
                    inline={false}
                    className={"mb-1"}
                    onChange={this.onChangeCheckbox}
                    checked={true}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="12">
                <Label className="form-control-label mb-3">
                  Periodo a exportar
                </Label>
              </Col>
              <Col md="6" lg="3">
                <FormGroup>
                  <Label className="form-control-label">Desde</Label>
                  <DatePicker
                    dateFormat="dd/MM/yyyy"
                    selected={this.state.filters.startDate}
                    onChange={(date) => this.setDate(date, "startDate")}
                    locale="es"
                    placeholderText="DD/MM/YYYY"
                    customInput={<Input placeholder="DD/MM/YYYY" />}
                    calendarClassName="app-calendar"
                  />
                </FormGroup>
              </Col>
              <Col md="6" lg="3">
                <FormGroup>
                  <Label className="form-control-label">Hasta</Label>
                  <DatePicker
                    dateFormat="dd/MM/yyyy"
                    minDate={this.state.filters.startDate}
                    selected={this.state.filters.endDate}
                    onChange={(date) => this.setDate(date, "endDate")}
                    locale="es"
                    placeholderText="DD/MM/YYYY"
                    customInput={<Input placeholder="DD/MM/YYYY" />}
                    calendarClassName="app-calendar"
                  />
                </FormGroup>
              </Col>
            </Row>
            <Button
              disabled={buttonDisabled}
              onClick={this.submit}
              className={"mt-2"}
            >
              Exportar
            </Button>
          </CardBody>
        </Collapse>
      </Card>
    );
  }
}
