import Environment from '../../constants/environment';
import React, { forwardRef }  from "react";
import Reflux from "reflux";
import axios from 'axios';
import memoize from 'memoize-one';
import DatePicker from "react-datepicker";
import { validateSpanishId } from 'spain-id';
import {fromExcelDate} from 'js-excel-date-convert';
import { CSVLink, CSVDownload } from "react-csv";
//import FormData from 'form-data';
// reactstrap components
import { Card, Container, Row, CardTitle, CardBody,   Label,
    FormGroup,
    Input,   FormText,
    Form, Col, Button,
    UncontrolledTooltip} from "reactstrap";

    import LoadingOverlay from "react-loading-overlay";
    //import { CSVLink } from "react-csv";

    // core components
    import Header from "../../components/Headers/Header";

    import { withApollo } from "react-apollo";
    import {
        VIEW_SIPS_CONSUMOS_TOTAL,
        LIST_SIPS_CONSUMOS,
    } from "../../queries/sips-consumo/sip-consumo";
    import { VIEW_CONTRATO, UPDATE_VIEW_CONTRATO, DATA_REPRE_CONTRATO, UPDATE_REPRE_CONTRATO } from "../../queries/sips-comparativa/contratacion";

    import SipsTable from "../../components/Sips/SipsTable";
    import moment from "moment";
    import {  toast } from 'react-toastify';
    import 'react-toastify/dist/ReactToastify.css';

    import { ExternalDropZone, Upload } from "@progress/kendo-react-upload";
    import GetUserData from "../../components/User/GetUserData";
    import { UserStore } from "../../store/UserStore";
    import DataTable from 'react-data-table-component';
    import Checkbox from '@material-ui/core/Checkbox';
    import ArrowDownward from '@material-ui/icons/ArrowDownward';
    //import memoize from 'memoize-one';
    import CircularProgress from '@material-ui/core/CircularProgress';
    import { parse, isValid, format } from 'date-fns';
    import { es } from 'date-fns/locale';
    import { saveAs } from 'file-saver';
    const FileDownload = require('js-file-download');
    const formato = require('xml-formatter');

    const notify = () => toast.success(
        "Contrato actualizado correctamente",
        {
            position: "bottom-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });

        const highPriorityItemsFields = {
            cups: "CUPS",
        };
        const lowPriorityItemsFields = {
            CIF: "CIF",
        };

        const sipsConsumosTotalFields = {
            year: "Año",
        };

        const fileStatuses = [
            "UploadFailed",
            "Initial",
            "Selected",
            "Uploading",
            "Uploaded",
            "RemoveFailed",
            "Removing",
        ];

        const sipsConsumosTotalExpandableFields = {};

        const sipsConsumosFields = {
            fechaFinMesConsumo: "Fecha",

        };

        const sipsConsumosExpandableFields = {};

        const uploadRef = React.createRef<Upload>();

        const hint = <span>My custom hint</span>;
        const note = <span>My custom note</span>;
        const sortIcon = <ArrowDownward />;
        const capitalize = (s) => {
          if (typeof s !== 'string') return ''
          return s.toLowerCase().charAt(0).toUpperCase() + s.slice(1)
        }



        const columnsFiles = memoize(clickHandler  =>    [
            { selector: row => row['Trading day'] , name: 'DIA SESION', sortable: true, width: '130px', format: (row =>  moment(fromExcelDate(row['Trading day'])).format("DD/MM/YYYY"))},
            { selector:  row => row.Product, name: 'PRODUCTO', sortable: true, width: '110px', center: true, format: (row => capitalize(row.Product)) },
            { selector:  row => row.Area, name: 'AREA', sortable: true, width: '80px', center: true, format: (row => capitalize(row.Area)) },
            { selector:  row => row['Place of delivery'], name: 'ENTREGA', sortable: true, width: '120px', center: true},
            { selector:  row => row['First Day Delivery'], name: 'PRIMER DIA ENTREGA', sortable: true, width: '140px', center: true, format: (row =>  moment(fromExcelDate(row['First Day Delivery'])).format("DD/MM/YYYY"))},
            { selector:  row => row['Last Day Delivery'], name: 'ULTIMO DIA ENTREGA', sortable: true, width: '140px', center: true, format: (row =>  moment(fromExcelDate(row['Last Day Delivery'])).format("DD/MM/YYYY"))},
{ selector:  row => row['MIBGAS Daily Price\n[EUR/MWh]'], name: 'MIBGAS PRECIO', sortable: true, width: '160px', center: true },
            { selector:  row => row['Minimum Daily Price\n[EUR/MWh]'],  name: 'PRECIO MINIMO', sortable: true, width: '160px', center: true },
            { selector:  row => row['Maximum Daily Price\n[EUR/MWh]'], name: 'PRECIO MAXIMO', sortable: true, width: '160px', center: true },


]);




        const customStyles = {
            headRow: {
                style: {
                    border: 'none',

                },
            },
            headCells: {
                style: {
                    color: '#202124',
                    fontSize: '12px',
                    fontWeight: 'bold',
                    wordBreak: 'break-word',
                    paddingLeft: '8px',
                    paddingRight: '8px',
                    minWidth: '55px'

                },
            },
            cells: {
                style: {
                    paddingLeft: '8px',
                    paddingRight: '8px',
                    minWidth: '55px',
                    wordBreak: 'break-word',
                    fontFamily: '"Open Sans", sans-serif;'
                },
            },
            rows: {
                highlightOnHoverStyle: {
                    backgroundColor: 'rgb(230, 250, 250)',
                    borderBottomColor: '#FFFFFF',
                    borderRadius: '25px',
                    outline: '1px solid #FFFFFF',
                    fontSize: '12px',
                },
            },
            pagination: {
                style: {
                    border: 'none',
                },
            },
        };

        /*
        const CustomInput = forwardRef((inputProps, ref) => {
        const { value, onChange } = inputProps
        return (
        <div>
        <input
        value={value}
        onChange={onChange}
        placeholder='Pick a date'
        ref={ref}
        id="fechaActivacion3"
        placeholder="DD/MM/YYYY"
        />
        </div>
    )
})
*/

class cargaMibgas extends Reflux.Component<any, any> {
    public maxFileSize = 3;
    public fileInput: any = null;
public inputEl: any = null;
    public validFileExtensions = ["jpg", "jpeg", "png"];
    lastFilters = {};
    state = {
        UserStore:{},
        nombreFichero: "",
        isLoading: false,
        copiedText: null,
        currentPage: 0,
        pageSize: 0,
        pageCount: 0,
        count: 0,
        contrato: "",
        tipoContrato:"",
        page: 1,
        limit: 10,
        sipsConsumosTotal: [],
        sipsConsumos: [],
        sipsDetail: [],
        contratoDetail: {fechaActivacion:"",
        fechaFirmaContrato: '',
        fechaVencimientoContrato: '',
        CIF:'',
        desMunicipioTitular: '',
        tipoViaTitular: '',
        viaTitular: '',
        numFincaTitular: '',
        portalTitular: '',
        escaleraTitular: '',
        pisoTitular: '',
        puertaTitular:'',
        cups:'',
        idContrato:'',
        idTarifaPeaje:'',
        nombreProducto:'',
        precioTEP1:0,
        precioTEP3:0,
        nombreTitular:'',
        apellido1Titular:'',
        apellido2Titular:'',
        desProvinciaTitular:'',
        iban:''

    },
    isLoadingSips: true,
    isLoadingConsumos: true,
    isLoadingConsumosTotales: true,
    changeLogoLoading: false,
    updateProfileLoading: false,
    paginatedData: {
        items: [],
    },
    files: [],
    events: [],
    filePreviews: {},
    mibgasPrice: [],
    companyIdUser: '',
    companyNameGlobal: '',
    userGroupId: 1000,
    userId:'',

    fechaActivacion: '',
    fechaBajaContrato: '' ,
    fechaContrato: '',
    fechaFinContrato: '',
    fechaFirmaContrato: '',
    fechaInicioContrato: '',
    fechaVencimientoContrato: '',
    fechaActivacionFlag: 0,
    fechaFirmaContratoFlag: 0,
    fechaVencimientoContratoFlag: 0,
    saveData:false,
    saveDataRepre:false,
    saveProductButtonDisabled: true,
    contratoButtonDisabled: false,
    saveXMLButtonDisabled: true,
    idComercializadoraActualContrato:'',
    nombreComercializadoraActualContrato:'',
    cups:'',
    CIF:'',
    consumoAnualCup:'',
    idRepresentante:'',
    representanteDetail:[],
    formRepre:{
        CIFRepre:'',
        CargoRepre: '',
        apellido1Representante: '',
        apellido2Representante: '',
        companyIdRepresentante:'',
        correoRepresentante: '',
        idRepresentante: '',
        idRepresentanteInterno: 0,
        nombreRepresentante: '',
        telefonoRepresentante: ''
    },
    CIFRepreError: false,
    inputDisabled: false,
    imgCollection: [],
saveButtonDisabled:true,
ultimaRemesa:""

};

sipsCSVData: any = [];
viewSipsConsumoAnualCSVData: any = [];
sipsConsumosCSVData: any = [];




constructor(props) {
    super(props);
    this.store = UserStore;
    this.onFileChange = this.onFileChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);

    this.fileInput = React.createRef();
    this.inputEl = React.createRef();


}

componentWillReceiveProps (){

    console.log(this.props)
    console.log(this.state)

    const usuario:any = JSON.parse(JSON.stringify(this.state.UserStore['data']))

    console.log('VALOR DE USUARIO ----------------->', usuario)



    const companyNameGlobal = usuario.company;
    const companyIdUser = usuario.companyId;

    const userGroupId = usuario.user_group_id
    const userId = usuario.id



    if (userGroupId === 1000){
        this.setState({
            activeUser: true
        })};

        this.setState({
            companyNameGlobal,
            companyIdUser,
            userGroupId,
            userId

        });
    }




    componentDidMount() {
        const {
            match: { params },
        } = this.props;

        console.log('VALORES DE MATCH ENVIADOS POR GESTION CONTRATO', params)
        //this.setState({ contrato: params.cups,
        //    tipoContrato: params.tipo }, this.fetchItems);
        //this.fetchItems();
        this.setState({ contrato: params.cups,
            tipoContrato: params.tipo });
            //this.fetchRepre();
        }






        export = () => {};




        onFileChange(e) {
e.persist()

            this.setState(
                    ({ imgCollection }) => ({
                      imgCollection:e.target.files
                    }), () => {
                      this.setState(({ imgCollection }) => ({
                        imgCollection:e.target.files
                      }))
                    } )

            //this.setState({ imgCollection: e.target.files })
console.log('VALOR DE SELECCION DE FILES -----> ', e.target.files)

            let tamañoFiles = e.target.files.length;
console.log('VALOR DE SELECCION DE FILES logitud -----> ', tamañoFiles)

            if (tamañoFiles > 0){
                this.setState(
                        ({ saveButtonDisabled }) => ({
                          saveButtonDisabled:false
                        }), () => {
                          this.setState(({ saveButtonDisabled }) => ({
                            saveButtonDisabled:false
                          }))
                        } )

} else {                this.setState(
                        ({ saveButtonDisabled }) => ({
                          saveButtonDisabled:true
                        }), () => {
                          this.setState(({ saveButtonDisabled }) => ({
                            saveButtonDisabled:true
                          }))
                        } )}

        }

        onSubmit(e) {
            e.preventDefault()

            var formData = new FormData();
            for (const key of Object.keys(this.state.imgCollection)) {
                formData.append('files', this.state.imgCollection[key])
            }
            var ultimaRemesa =""

            const headers = {
                'x-api-key': '$2y$10$bcT1Ukm4V/6/z5GvitZv0unu8I91mpixuwUO6z5CyEGev9DDDY28W',
                "Content-Type": "multipart/form-data"
            };
            let direccionFile = "https://datapi.psgestion.es/files/upload/mibgas?id="+this.state.companyIdUser;

            //let direccionFileb = "http://78.47.44.246:4000/api/upload-images";
            //    let direccionFileList = "https://datapi.psgestion.es/files?id="+this.state.companyIdUser;
            console.log(direccionFile)
            axios.post(direccionFile, formData, {headers}
            ).then (result => {
                console.log('resultado de la subida')
                console.log (result.data);
                //ultimaRemesa = result.data.idRemesa
                //this.setState({ultimaRemesa: result.data.idRemesa})
                this.setState({mibgasPrice: result.data})
                this.setState({imgCollection:[]})
                this.setState({saveButtonDisabled: true})
                this.inputEl.current.value = ''
        }).catch (function() {
                console.log ('Failure')
            });

}




readUploadedFileAsText = (inputFile) => {

    console.log('DENTRO DE LA FUNCION READUPLODADED-----> ', inputFile)
    const temporaryFileReader = new FileReader();

    return new Promise((resolve, reject) => {
        temporaryFileReader.onerror = () => {
            temporaryFileReader.abort();
            reject(new DOMException("Problem parsing input file."));
        };

        temporaryFileReader.onload = () => {
            resolve(temporaryFileReader.result);
        };
        //temporaryFileReader.readAsText(inputFile);
        temporaryFileReader.readAsDataURL(inputFile);
    });
};




handleButtonClick = (state) => {
    // eslint-disable-next-line no-console
    const id = state.target.id;
    const file = state.target.value
    const headers = {
        'Authorization': 'Bearer my-token',
        'My-Custom-Header': 'foobar',
        'x-api-key': '$2y$10$bcT1Ukm4V/6/z5GvitZv0unu8I91mpixuwUO6z5CyEGev9DDDY28W'
    };

    let direccionFileList = "https://datapi.psgestion.es/files/download?id="+this.state.companyIdUser+"&contrato="+this.state.contrato+"&file="+file;

    console.log(direccionFileList)
    axios.get(direccionFileList,
        { headers,
            responseType: 'blob' }).
            then((response) => {
                FileDownload(response.data, file);
            }).catch (function() {
                console.log ('Failure')
            });



        };


        showFile = (blob) => {
            var newBlob = new Blob([blob], {type: "application/pdf"})
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                window.navigator.msSaveOrOpenBlob(newBlob);
                return;
            }
            const data = window.URL.createObjectURL(newBlob);
            var link = document.createElement('a');
            link.href = data;
            link.download="contratoGasCalordom.pdf";
            link.click();
            setTimeout(function(){
                window.URL.revokeObjectURL(data);
            }, 100);
        }



        render() {
            const disableExport =
            this.state.isLoadingSips ||
            this.state.isLoadingConsumos ||
            this.state.isLoadingConsumosTotales ||
            this.state.isLoadingSips;
            const disableExportClass = disableExport ? " disabled" : "";

            console.log('VALOR DE THIS:STATE EN RENDER SIPDETAILSCONTRATOS ------> ', this.state)



            return (
                <>
                <Header title={'Carga de Datos Precios MIBGAS'} />
                <Container className=" mt--7" fluid>
                    <Row>
                        <div className="col">
                            <Card className="shadow">
                                <CardBody>
                                    <Row>
                                        <Col>
                                            <button
                                                className="btn btn-sm btn-primary"
                                                onClick={this.props.history.goBack}
                                                >
                                                    Atrás
                                                </button>
                                            </Col>



                                        </Row>
                                    </CardBody>
                                </Card>



                                <Card className="mt-3 shadow">
                                    <CardBody>
                                        <CardTitle>CARGA DE FICHEROS DE LECTURAS DE GAS</CardTitle>
                                        <Row>

                                                    <Col md="12" lg="5">
                                                    <Form onSubmit={this.onSubmit}>

                                                            <input type="file" name="files" className="custom-file-input" onChange={this.onFileChange} ref={this.inputEl}   multiple />

                                                            <button  disabled={this.state.saveButtonDisabled} id = "gpsProptooltip" type="submit" color="#2dce89"  className="btn btn-primary btn-sm btn-circle" >
                                                                     <span className="btn-inner--icon">
                                                                         <i className={"bi bi-cloud-arrow-up"} style={{ fontSize: 25 }}></i>
                                                                     </span>
                                                                     <span className="btn-inner--text">Subir Ficheros</span>
                                                                     <UncontrolledTooltip delay={0} placement="top" target="gpsProptooltip">
                                                                         SUBIR FICHEROS XML AL SERVIDOR
                                                                     </UncontrolledTooltip>
                                                                 </button>
</Form>
</Col>
</Row>



                                            <FormText color="muted">
                                                Seleccionar los fichero XML para subir al servidor y procesar
                                            </FormText>


                                        <Row id="contratos_resumen" className="card-deck mt-2">
                                            <Card className="shadow">
                                                <DataTable
                                                    title= "PRECIO DIARIO MIBGAS"
                                                    columns={columnsFiles(this.handleButtonClick)}
                                                    data = {this.state.mibgasPrice}
                                                    sortIcon={sortIcon} // use a material icon for our sort icon. rdt will rotate the icon 180 degrees for you           fixedHeader = {true}
                                                    noDataComponent = {"Sin datos para mostrar"}
                                                    pagination
                                                    paginationPerPage = {10}
                                                    paginationRowsPerPageOptions = {	[10, 15, 20, 25, 30, 40]}

                                                    highlightOnHover
                                                    striped
                                                    pointerOnHover
                                                    selectableRowsHighlight

                                                    customStyles={customStyles}

                                                    subHeader
                                                    subHeaderComponent={
                                                    <div style={{ display: 'flex', alignItems: 'center' }}>

                                                        <CSVLink
                                                          data={this.state.mibgasPrice}
                                                          filename={"PreciosMibGas.csv"}
                                                          className="btn btn-default btn-sm btn-circle"
                                                            color="default"
                                                          target="_blank"
                                                            separator={";"}
                                                        >

                                                          <span className="btn-inner--icon">
                                                              <i className={"bi bi-file-earmark-spreadsheet"} style={{ fontSize: 25 }}></i>
                                                          </span>
                                                          <span className="btn-inner--text">Fichero CSV</span>

                                                        </CSVLink>
                                                    </div>
                                                    }

                                                />
                                            </Card>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </div>
                        </Row>
                    </Container>
                </>
            );
        }
    }


    export default withApollo(cargaMibgas);
