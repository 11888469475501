import Index from "./views/Index";
import Login from "./views/Auth/Login";
import Profile from "./views/users/Profile";
import Recover from "./views/Auth/Recover";
import Reset from "./views/Auth/Reset";
import Sips from "./views/sips/Sips";
import Reports from "./views/reports/Sips"
import Empresa from "./views/empresa/Empresa"
import Multips from "./views/multips/MultipsElectricidad"
//import MultipsNew from "./views/multipsNew/MultipsElectricidad"
import SipComparativeIndex from "./views/sips/comparatives/SipComparativeIndex";
import SipComparativeFree from "./views/sips/comparatives/SipComparativeFree";
import SipDetails from "./views/sips/SipDetails";
import SipsMassiveExports from "./views/sips/SipsMassiveExports";
import ComercialProductoDocumento from "./views/sips/comparatives/ComercialProductoDocumento";
import ComercialProductoPropuestaDocumento from "./views/documentos/ComercialProductoPropuestaDocumento";
import GestionPropuesta from "./views/GestionPropuestas/GestionPropuestas";
import PropuestasControlPanel from "./views/GestionPropuestas/PropuestasControlPanel";
import { HomeFilled } from '@ant-design/icons';
import SipsGas from "./views/sipsGas/SipsGas"
import SipsGasMassiveExports from "./views/sipsGas/SipsGasMassiveExports";
import SipGasDetails from "./views/sipsGas/SipGasDetails";
import SipGasComparativeFree from "./views/sipsGas/comparativesGas/SipGasComparativeFree";
import ComercialGasProductoDocumento from "./views/sipsGas/comparativesGas/ComercialGasProductoDocumento";
import MultipsGas from "./views/multipsgas/MultipsGas";
import GestionContratos from "./views/GestionPropuestas/GestionContratos";
//import SipDetailsContratos from "./views/GestionPropuestas/SipDetailsContratos";
import SipDetallesContratos from "./views/GestionPropuestas/SipDetallesContratos";
import SipDetallesPropuestas from "./views/GestionPropuestas/SipDetallesPropuestas";

import LecturasContratos from "./views/GestionPropuestas/LecturasContratos";
//import SipDetailsLecturasContratos from "./views/GestionPropuestas/SipDetailsLecturasContratos";
import Contrato from "./views/contratos/Contrato";
import SipGasDetailsExtend from "./views/contratos/SipGasDetailsExtend";
import SipDetailsExtend from "./views/contratos/SipDetailsExtend";
import Representante from "./views/admin/representante";
import Comercial from "./views/admin/comercial";
import Producto from "./views/admin/producto";
import crearProducto from "./views/admin/crearProducto";
import Cliente from "./views/admin/cliente";
import crearCliente from "./views/admin/crearCliente";
import facturasDistribuidora from "./views/admin/factura";
import facturasCliente from "./views/admin/facturaCliente";
import facturacion from "./views/admin/facturacion";
import facturacionERP from "./views/admin/facturacionERP";
import cargaMibgas from "./views/gestionDatos/cargaMibgas";
import Propuesta from "./views/admin/propuesta";
import ProductoConsulta from "./views/consultas/productoConsulta";
import ClienteConsulta from "./views/consultas/clienteConsulta";
import Comision from "./views/consultas/comisiones";
import RepresentanteConsulta from "./views/consultas/representanteConsulta";

var routes = [
  {
    path: "/index",
    name: "Inicio",
    icon: "bi bi-app-indicator",
    component: Index,
    layout: "/admin",
    key: 'index',
  },
  {
    path: "/user-profile",
    name: "Perfil",
    icon: "pe-7s-graph1",
    component: Profile,
    invisible: true,
    layout: "/admin",
    key: 'user-profile'
  },
  {
    path: "/login",
    name: "Login",
    icon: "ni ni-key-25",
    component: Login,
    layout: "/auth",
    key: 'login'
  },
  {
    path: "/recover",
    name: "Recover",
    icon: "ni ni-key-25",
    component: Recover,
    layout: "/auth",
    key: 'recover'
  },
  {
    path: "/reset",
    name: "Reset",
    icon: "ni ni-key-25",
    component: Reset,
    layout: "/auth",
    key: 'reset'
  },
  {
    collapse: true,
    name: "PS Electricidad",
    miniName: "PS",
    state: "MultiLinea",
    hasSubmenu: true,
    icon: "bi bi-lightning-charge-fill text-yellow",
    layout: "/admin",
    key: 'sips',
    views: [
      {
        path: "/sips",
        name: "Listado suministros",
        icon: "bi bi-lightning-charge-fill text-yellow",
        component: Sips,
        layout: "/admin",
        key: 'sips-listado',
      },
      {
        path: "/sips-massive-export",
        name: "Exportación masiva",
        icon: "bi bi-files-alt text-yellow",
        component: SipsMassiveExports,
        layout: "/admin",
        key: 'sips-exportacion',
      },
      {
        path: "/reports",
        name: "Listado PS global",
        icon: "bi bi-collection text-yellow",
        component: Reports,
        layout: "/admin",
        key: 'sips-listado-global',
      },
    ]
  },
  {
    collapse: true,
    name: "PS Gas",
    miniName: "PSG",
    state: "MultiLineaGas",
    hasSubmenu: true,
    icon: "fas fa-burn text-blue",
    layout: "/admin",
    key: 'sipsGas',
    views: [
      {
        path: "/sipsGas",
        name: "Listado suministros Gas",
        icon: "fas fa-fire text-blue",
        component: SipsGas,
        layout: "/admin",
        key: 'sipsGas-listado',
      },
      {
        path: "/sipsGas-massive-export",
        name: "Exportación masiva Gas",
        icon: "bi bi-files-alt text-blue",
        component: SipsGasMassiveExports,
        layout: "/admin",
        key: 'sipsGas-exportacion',
      },
    ]
  },
  // {
        // collapse: true,
        // name: "EMPRESAS",
        // miniName: "EMP",
        // state: "MultiEmpresa",
        // hasSubmenu: true,
        // icon: "ni ni-single-copy-04 text-pink",
        // layout: "/admin",
        // key: 'empresa-listado',
        // views: [
/*      {
        path: "/empresa",
        name: "Listado empresas",
        icon: "fas fa-industry text-black",
        component: Empresa,
        layout: "/admin",
        key: 'empresa-listado-detalle',
      },*/
  //   ]
  // },
  // {
  //       collapse: true,
  //       name: "INFORMES",
  //       miniName: "IPS",
  //       state: "MultiInforme",
  //       hasSubmenu: true,
  //       icon: "ni ni-single-copy-04 text-pink",
  //       layout: "/admin",
  //       key: 'sips-listado',
  //       views: [
/*      {
        path: "/reports",
        name: "Listado PS global",
        icon: "bi bi-collection",
        component: Reports,
        layout: "/admin",
        key: 'sips-listado-global',
      }, */
    // ]
  // },
  {
        // collapse: true,
        name: "Prop. Electricidad multipunto",
        miniName: "PMP",
        component: Multips,
        // state: "MultiCollapsePs",
        // hasSubmenu: false,
        icon: "fas fa-file-invoice text-yellow",
        layout: "/admin",
        path: "/multips",
        key: 'multipunto-listado',
      //   views: [
      //   {
      //     path: "/multips",
      //     name: "Crear Propuestas Multipunto",
      //     component: Multips,
      //     layout: "/admin",
      //     key: 'multipunto-listado-detalle',
      //   }
      // ]
  },
  {
        // collapse: true,
        name: "Prop. Gas multipunto",
        miniName: "PMPG",
        component: MultipsGas,
        // state: "MultiCollapsePs",
        // hasSubmenu: false,
        icon: "fas fa-file-invoice text-blue",
        layout: "/admin",
        path: "/multipsgas",
        key: 'multipuntogas-listado',
      //   views: [
      //   {
      //     path: "/multips",
      //     name: "Crear Propuestas Multipunto",
      //     component: Multips,
      //     layout: "/admin",
      //     key: 'multipunto-listado-detalle',
      //   }
      // ]
  },
  {
    path: "/sip-details/:cups",
    name: "Detalles Suministro",
    icon: "iconClassName",
    component: SipDetails,
    invisible: true,
    layout: "/admin",
    key: 'sips-details'
  },
   {
    path: "/sip-contrato-detalle/:cups/:tipo",
    name: "Datos Contrato",
    icon: "iconClassName",
    component: SipDetallesContratos,
    invisible: true,
    layout: "/admin",
    key: 'sips-datos-contrato'
  },
  {
   path: "/sip-propuesta-detalle/:cups/:tipo",
   name: "Datos Propuesta",
   icon: "iconClassName",
   component: SipDetallesPropuestas,
   invisible: true,
   layout: "/admin",
   key: 'sips-datos-propuesta'
 },
  {
    path: "/nuevo-producto/:tipo",
    name: "Nuevo Producto",
    icon: "iconClassName",
    component: crearProducto,
    invisible: true,
    layout: "/admin",
    key: 'crear-producto'
  },
  {
    path: "/nuevo-cliente/",
    name: "Nuevo Cliente",
    icon: "iconClassName",
    component: crearCliente,
    invisible: true,
    layout: "/admin",
    key: 'crear-cliente'
  },
  {
    path: "/sipGas-details/:cups",
    name: "Detalles Suministro Gas",
    icon: "iconClassName",
    component: SipGasDetails,
    invisible: true,
    layout: "/admin",
    key: 'sipsGas-details'
  },
  /*{
    path: "/sipGas-details-extend/:cups",
    name: "Detalles Suministro Gas Extendido",
    icon: "iconClassName",
    component: SipGasDetailsExtend,
    invisible: true,
    layout: "/admin",
    key: 'sipsGas-details-extend'
  }, */
  {
    path: "/sip-comparative-index/:cups",
    name: "Comparativa Contrato Indexado",
    component: SipComparativeIndex,
    invisible: true,
    layout: "/admin",
    key: 'sips-comparative-index'
  },
/*{
path: "/sip-contrato-lecturas/:cups",
name: "Contrato Gas Lecturas y Facturación",
component: SipDetailsLecturasContratos,
invisible: true,
layout: "/admin",
key: 'sip-contrato-lecturas'
},*/
{
    path: "/sip-comparative-free/:cups",
    name: "Comparativa Contrato Precio Fijo",
    component: SipComparativeFree,
    invisible: true,
    layout: "/admin",
    key: 'sips-comparative-free'
  },
  {
    path: "/sip-comparative-print/:id",
    name: "Comparativa Producto Precio Fijo Imprimir",
    component: ComercialProductoDocumento,
    invisible: true,
    layout: "/print",
    key: 'sips-comparative-print'
  },
  {
    path: "/sip-propuesta-print/:id/:compania",
    name: "Comparativa Producto Precio Fijo Imprimir",
    component: ComercialProductoPropuestaDocumento,
    invisible: true,
    layout: "/print",
    key: 'sips-propuesta-print'
  },
  {
    path: "/sipgas-comparative-print/:id/:compania",
    name: "Comparativa Producto Gas Precio Fijo Imprimir",
    component: ComercialGasProductoDocumento,
    invisible: true,
    layout: "/print",
    key: 'sipsgas-comparative-print'
  },
  {
    path: "/sip-detalles-extendidos/:id",
    name: "Detalles de consumos Gas",
    component: SipGasDetailsExtend,
    invisible: true,
    layout: "/consumos",
    key: 'sipsgas-comparative-detalles'
  },
  {
    path: "/sip-detalles-extendidos-elec/:id",
    name: "Detalles de consumos Eléctricos",
    component: SipDetailsExtend,
    invisible: true,
    layout: "/consumos",
    key: 'sipselec-comparative-detalles'
  },
  {
    path: "/sipGas-comparative-free/:cups",
    name: "Comparativa Contrato Precio Fijo",
    component: SipGasComparativeFree,
    invisible: true,
    layout: "/admin",
    key: 'sipsGas-comparative-free'
  },
  {
    collapse: true,
    name: "Dashboard propuestas",
    miniName: "PCt",
    state: "multiCollapse2",
    hasSubmenu: true,
    icon: "bi bi-graph-up",
    layout: "/admin",
    key: 'GestionPropuesta',
    views: [
  {
    path: "/GestionPropuesta",
    name: "Gestión Propuesta",
    icon: "ni ni-single-copy-04",
    component: GestionPropuesta,
    layout: "/admin",
    key: 'GestionPropuestaT',
  },
  {
    path: "/GestionContratos",
    name: "Gestión Contratos",
    icon: "ni ni-single-copy-04",
    component: GestionContratos,
    layout: "/admin",
    key: 'GestionContratosT',
  },
  {
    path: "/Panel-Control-Propuestas",
    name: "Cuadro de mando propuestas",
    icon: "bi bi-kanban",
    component: PropuestasControlPanel,
    layout: "/admin",
    key: 'control-panel-propuestas',
  },
    ]
  },
  {
    collapse: true,
    name: "Consulta Datos",
    miniName: "CD",
    state: "multiCollapseConsulta",
    hasSubmenu: true,
    icon: "bi bi-search",
    layout: "/admin",
    key: 'ConsultaSeleccion',
    views: [
  {
    path: "/infoproducto",
    name: "Productos",
    icon: "bi bi-file-earmark-ruled",
    component: ProductoConsulta,
    layout: "/admin",
    key: 'ProductoConsulta',
  },
  {
    path: "/infocliente",
    name: "Clientes",
    icon: "bi bi-person-square",
    component: ClienteConsulta,
    layout: "/admin",
    key: 'ClienteConsulta',
  },
  {
    path: "/inforepresentante",
    name: "Representantes",
    icon: "bi bi-briefcase",
    component: RepresentanteConsulta,
    layout: "/admin",
    key: 'RepresentanteConsulta',
  },
  {
    path: "/comisiones",
    name: "Comisiones",
    icon: "bi bi-person-square",
    component: Comision,
    layout: "/admin",
    key: 'comisionesComercial',
  },
  {
    path: "/empresa",
    name: "Listado empresas",
    icon: "fas fa-industry text-black",
    component: Empresa,
    layout: "/admin",
    key: 'empresa-listado-detalle',
  }
  ]
  },
  {
    collapse: true,
    name: "Contratación Adm.",
    miniName: "CAD",
    state: "multiCollapseContratacion",
    hasSubmenu: true,
    icon: "bi bi-intersect",
    layout: "/admin",
    key: 'ContratacionAdmin',
    views: [
  {
    path: "/Contrato",
    name: "Administración de contratos",
    icon: "bi bi-inboxes",
    component: Contrato,
    layout: "/admin",
    key: 'ContratacionAdminView',
  },
  {
    path: "/LecturasContratos",
    name: "Lecturas Contratos",
    icon: "bi bi-file-arrow-down",
    component: LecturasContratos,
    layout: "/admin",
    key: 'GestionContratosL',
  },
{
      path: "/facturasDistribuidoras",
      name: "Facturas Distribuidoras",
      icon: "bi bi-file-earmark-medical",
      component: facturasDistribuidora,
      layout: "/admin",
      key: 'FacturasDistribuidorasList',
    },
    {
          path: "/facturasClientes",
          name: "Facturas Clientes",
          icon: "bi bi-file-earmark-ruled",
          component: facturasCliente,
          layout: "/admin",
          key: 'FacturasClientesList',
        },
        {
              path: "/facturacion",
              name: "Facturación",
              icon: "bi bi-cash-stack",
              component: facturacion,
              layout: "/admin",
              key: 'FacturacionProceso',
            },
            {
                  path: "/facturacionEnvioErp",
                  name: "Envío Facturas - ERP",
                  icon: "bi bi-file-arrow-up",
                  component: facturacionERP,
                  layout: "/admin",
                  key: 'FacturacionERPProceso',
                },
  {
    path: "/representante",
    name: "Representantes Contratos",
    icon: "bi bi-briefcase",
    component: Representante,
    layout: "/admin",
    key: 'RepresentanteL',
  },{
    path: "/comercial",
    name: "Comercial",
    icon: "bi bi-briefcase",
    component: Comercial,
    layout: "/admin",
    key: 'ComercialData',
  },
  {
    path: "/cliente",
    name: "Clientes",
    icon: "bi bi-file-earmark-person",
    component: Cliente,
    layout: "/admin",
    key: 'ClienteList',
  },
  {
    path: "/producto",
    name: "Productos",
    icon: "bi bi-cart3",
    component: Producto,
    layout: "/admin",
    key: 'ProductoList',
  }
/*,
  {
    path: "/propuesta",
    name: "Propuestas",
    icon: "bi bi-cart3",
    component: Propuesta,
    layout: "/admin",
    key: 'PropuestaList',
  }*/
]
},
{
  collapse: true,
  name: "Gestión Datos",
  miniName: "GD",
  state: "multiCollapseContratacion",
  hasSubmenu: true,
  icon: "bi bi-intersect",
  layout: "/admin",
  key: 'GestionDatosAdmin',
  views: [
{
  path: "/gestionMibgas",
  name: "Carga de datos MIBGAS",
  icon: "bi bi-inboxes",
  component: cargaMibgas,
  layout: "/admin",
  key: 'cargaMibgasdata',
}
]
}



];
export default routes;
