//import Password from './Password'
//import { Mailer } from 'nodemailer-react'
import Environment from '../../../constants/environment';
import React from "react";
import Reflux from "reflux";
import axios from 'axios';
import { withApollo } from "react-apollo";
import { Card, CardHeader, CardTitle, CardBody, Form, ListGroup, ListGroupItem, Input, Row, Table, FormText, Col, Label, Button, UncontrolledTooltip } from "reactstrap";
import { Bar, HorizontalBar } from "react-chartjs-2";
import SipsTable from "../../../components/Sips/SipsTable";
import LoadingOverlay from "react-loading-overlay";
import { DATA_SIPS_COMPARATIVA_LIBRE_BY_ID } from "../../../queries/sips-comparativa/sip-comparativa-libre";
import RateInformation from "../../../components/Cards/RateInformationDocument";
import { VIEW_SIPS } from "../../../queries/sips/sips.graphql";
import GetUserData from "../../../components/User/GetUserData";
import { UserStore } from "../../../store/UserStore";
import { PDFExport, savePDF } from "@progress/kendo-react-pdf";
import emailjs from 'emailjs-com';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "../../../assets/scss/documento.scss";
import DataTable from 'react-data-table-component';
//const nodemailer = require("nodemailer");
//import { Mailer } from 'nodemailer-react'

//import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";

const columnsDatosPropuesta = [
    {
        selector: row => row['periodo'], name: 'PERIODO', sortable: false, width: '80px', style: {
            color: 'rgba(0,0,0,.54)',
        },
    },
    { selector: row => row['precioPotencia'], name: 'PRECIO POTENCIA €/KW DÍA', sortable: false, width: '190px', center: true },
    { selector: row => row['precioEnergia'], name: 'PRECIO ENERGÍA €/KWH', width: '170px', sortable: false, center: true },
];

const columnsDatosPropuestaActual = [
    {
        selector: row => row['periodo'], name: 'PERIODO', sortable: false, width: '80px', style: {
            color: 'rgba(0,0,0,.54)',
        },
    },
    { selector: row => row['precioPotencia'], name: 'PRECIO POTENCIA €/KW DÍA', sortable: false, width: '190px', center: true },
    { selector: row => row['precioEnergia'], name: 'PRECIO ENERGÍA €/KWH', width: '170px', sortable: false, center: true },
];

const notify = () => toast.success(
    "Correo enviado correctamente",
    {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });

const notifyError = () => toast.error(
    "Existe un problema con el servicio de correos",
    {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });

const notifyEnvio = () => toast.info(
    "Preparando el fichero para su envío por correo",
    {
        position: "top-center",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });

const comercialProductoFields = {
    periodo: "Periodo",
    precioPotencia: "Precio potencia €/kW día",
    precioEnergia: "Precio Energía €/kWh"
};

const comercialProductoExpandableFields = {};

const customStyles = {
    headRow: {
        style: {
            border: 'none',

        },
    },
    headCells: {
        style: {
            color: '#202124',
            fontSize: '12px',
            fontWeight: 'bold',
            wordBreak: 'break-word',
            paddingLeft: '8px',
            paddingRight: '8px',
            minWidth: '55px'

        },
    },
    cells: {
        style: {
            paddingLeft: '8px',
            paddingRight: '8px',
            minWidth: '55px',
            wordBreak: 'break-word',
            fontFamily: '"Open Sans", sans-serif;'
        },
    },
    rows: {
        highlightOnHoverStyle: {
            backgroundColor: 'rgb(230, 250, 250)',
            borderBottomColor: '#FFFFFF',
            borderRadius: '25px',
            outline: '1px solid #FFFFFF',
            fontSize: '12px',
        },
    },
    pagination: {
        style: {
            border: 'none',
        },
    },
};



//const container = React.useRef<HTMLDivElement>(null);
//  const pdfExportComponent = React.useRef<PDFExport>(null);
/*  const exportPDFWithMethod = () => {
    let element = container.current || document.body;
    savePDF(element, {
      paperSize: "auto",
      margin: 40,
      fileName: `Report for ${new Date().getFullYear()}`,
    });
  };
  const exportPDFWithComponent = () => {
    if (pdfExportComponent.current) {
      pdfExportComponent.current.save();
    }
  };
*/





class ComercialProductoDocumento extends Reflux.Component {
    public container: any = null;
    public fileInput: any = null;
    public pdfExportComponent: any = null;
    public formActualRef: any = null;
    public formProductoRef: any = null;
    public datosContrato: any = null;
    //public datosPropuestaTest: any =null
    public state: any = {
        id: null,
        // --- Data
        formProducto: {
            serviciosAdicionales: 0,
            alquilerEquipoMedida: 0,

            sumPotencia: 0,
            sumEnergia: 0,
            iePercent: 0,
            impuestoElectrico: 0,
            iva: 0,
            bi: 0,
            total: 0,

            penalizacionPotencia: 0,
            penalizacionEnergia: 0
        },
        formActual: {

            serviciosAdicionales: 0,
            alquilerEquipoMedida: 0,

            sumPotencia: 0,
            sumEnergia: 0,
            iePercent: 0,
            impuestoElectrico: 0,
            iva: 0,
            bi: 0,
            total: 0,

            penalizacionPotencia: 0,
            penalizacionEnergia: 0,

            precioTP1: 0,
            precioTP2: 0,
            precioTP3: 0,
            precioTP4: 0,
            precioTP5: 0,
            precioTP6: 0,
            precioTEP1: 0,
            precioTEP2: 0,
            precioTEP3: 0,
            precioTEP4: 0,
            precioTEP5: 0,
            precioTEP6: 0,
        },
        totalComparacion: {
            ahorroAnual: 0,
            ahorroPercent: 0,
            ahorroPeriodo: 0
        },
        isLoading: true,
        sip: {},

        periods: [],
        itemsTabla: [],
        itemsTablaActual: [],
        producto: {},
        costeOperativo: 0,
        costeOperativoPotencia: 0,
        correoContacto: '',
        nombreFichero: '',
        datafile: "",
        uploadedFileContents: null,
        control: 0,
        datosPropuestaTest: { itemsPeriodos: [] },
        productoTarifas: {}
        //paginatedData: {items:[{CIF:''}]},


    };

    constructor(props) {
        super(props);
        this.store = UserStore;


        this.container = React.createRef<HTMLDivElement>();
        this.pdfExportComponent = React.createRef<PDFExport>();
        this.fileInput = React.createRef();
    }


    componentDidMount() {

        this.formActualRef = React.createRef();
        this.formProductoRef = React.createRef();
        const {
            match: { params },
        } = this.props;

        const user = localStorage.getItem("user");

        if (user) {
            const parsed = JSON.parse(user);
            this.setState({ user: parsed });
        }
        const valueArray = params.id.split("_");
        let id = valueArray[0]
        let compania = valueArray[1]

        this.setState({
            ...this.state,
            id: id,
            compania: compania
        });

        this.fetchComparativaLibre(id, compania)

        this.setState({

            control: 1
        }
        );

    }


    exportPDFWithMethod = () => {
        let element = this.container.current || document.body;
        savePDF(element, {
            paperSize: "auto",
            margin: 40,
            fileName: `Report for ${new Date().getFullYear()}`,
        });
    };

    exportPDFWithComponentclick = () => {
        if (this.pdfExportComponent.current) {
            this.pdfExportComponent.current.save();
        }
    };

    //SendEmail = async (e: React.ChangeEvent<HTMLInputElement>)  => {
    SendEmail = async (event) => {
        event.persist()
        console.log('VALOR DE EVENT ----->', event)
        console.log('He entrado en el correo')
        console.log(this.state.nombreFichero)
        console.log('Valor del Fichero')
        //console.log(this.fileInput.current.files[0].name)
        console.log('VALOR DE EVENT ----->', event)
        console.log(event.target.files);
        var selectedFile: any = []
        var evento: any = []
        if (event !== null && event.target.files !== null) {
            console.log('NO ES NULO')
            selectedFile = event.target.files;
            evento = event
            var fileContents: string = ""

        }

        var file: string | ArrayBuffer = '';
        var fileName = "";
        if (selectedFile.length > 0) {
            console.log('ESTOY DENTRO DEL SENDEMAIL Y EN SELECTED FILES >0')
            let fileToLoad = selectedFile[0];
            try {
                const fileContents = await this.readUploadedFileAsText(fileToLoad)
                this.setState({
                    uploadedFileContents: fileContents
                });
            } catch (e) {
                console.warn(e.message)
            }


            /*
                   fileName = fileToLoad.name;
                   const fileReader = new FileReader();
                  // Onload of file read the file content
                  //    if(event!=null && event.target.files != null) {

          if(event!==null && event) {
                 fileReader.onload = function(event: Event) {
                //  if(evento!=null) {
                //console.log(this.result)
                const file64 =  event!==null?fileReader.result:""
                console.log(file64)
                file = file64!== null?file64:""
                      //file = e.target.result;
                      // Print data in console
                      //console.log(file);

                 }
                 console.log('VALOR DE FILE---->', file)
                 this.setState({
                   datafile: file
                 })
               }
                 console.log('VALOR DE fileToLoad----->',fileToLoad)
                 var pdf64 = fileReader.readAsDataURL(fileToLoad);
                 console.log(pdf64)
                 console.log(file)
                 */
        }

        //let fileName = this.fileInput.current.files[0].name;
        //let fileReader = new FileReader();
        /*      fileReader.onload = function(fileLoadedEvent) {
                    file = fileLoadedEvent.target.result;
                    // Print data in console
                    console.log(file);
                };
                fileReader.readAsDataURL(fileToLoad);
        */
        var templateParams = {
            to_name: this.state.correoContacto,
            from_name: this.state.producto.nombreComercializadora,
            producto: this.state.producto.nombreOferta,
            cup: this.state.cups,
            correoContacto: this.state.correoContacto,
            nombrePropuesta: this.state.nombreFichero + ".pdf",
            variable_myhirj5: this.state.uploadedFileContents
        }


        console.log('VALORES DE templateParams---->', templateParams)
        notifyEnvio();


        emailjs.send("service_dynargyMail", "template_ctuhpkd", templateParams, "user_9fdpYny52CeShK5yj0B3u")
            .then(function (response) {
                console.log('SUCCESS!', response.status, response.text);
                notify();
            }, function (error) {
                console.log('FAILED...', error);
                notifyError();
            });;



    };

    readUploadedFileAsText = (inputFile) => {

        console.log('DENTRO DE LA FUNCION READUPLODADED-----> ', inputFile)
        const temporaryFileReader = new FileReader();

        return new Promise((resolve, reject) => {
            temporaryFileReader.onerror = () => {
                temporaryFileReader.abort();
                reject(new DOMException("Problem parsing input file."));
            };

            temporaryFileReader.onload = () => {
                resolve(temporaryFileReader.result);
            };
            //temporaryFileReader.readAsText(inputFile);
            temporaryFileReader.readAsDataURL(inputFile);
        });
    };



    fetchSip = async () => {

        await this.props.client
            .query({
                query: VIEW_SIPS,
                variables: { cups: this.state.cups },
            })
            .then((result) => {
                //Sip
                const sip = result.data.viewSips[0];
                this.setState({
                    ...this.state,
                    sip: sip,
                    isLoading: false,
                });
            });
    }



    fetchComparativaLibre = async (id,compania)=> {

        const idOferta = id;
        const idCompania = compania;
        //const IdOferta = this.state.contrato;

        var dataFilters = {
            'idOferta': idOferta,
            'idCompania': idCompania
        };

        const valores = JSON.stringify(dataFilters)
        const headers = {
            'Authorization': 'Bearer my-token',
            'My-Custom-Header': 'foobar',
            'x-api-key': '$2y$10$bcT1Ukm4V/6/z5GvitZv0unu8I91mpixuwUO6z5CyEGev9DDDY28W'
        };
        let direccionFile = "https://datapi.psgestion.es/propuesta/detallePropuestasCups?id=" + idOferta;

        axios.get(direccionFile, { headers, params: { valores } }).
            then(result => {
                console.log('resultado del listados')
                console.log(result.data.rows)
                const data = result.data.rows[0];
                console.log('valor de data : ', data)
                console.log('valor de data.T_Propuesta.actual.datosAdicionales: ', data.T_Propuesta.actual.datosAdicionales)
                console.log('valor de data.T_Propuesta.producto.datos: ', data.T_Propuesta.producto.datos)
                console.log('valor de data: ', data)

                let datosPreciosActual = data.T_Propuesta.actual.datosAdicionales
                let datosPropuesta = data.T_Propuesta.producto.datos;
                let datosContrato = data;
                //let test = JSON.stringify(data.datosPropuesta);
                let test2 = data.T_Propuesta.producto;
                //console.log(test)
                /*console.log(test2)
                console.log('VALOR DE DATOSPROPUESTA.ITEMSPERIODOS test 2',test2.itemsPeriodos );

                console.log('VALOR DE DATA---->', data)
                console.log(data.cups)
                console.log(datosPropuesta)
                console.log('VALORES DE DATA.DATOSPROPUESTAS --->', data.datosPropuesta)
                console.log('VALOR DE DATOSPROPUESTA.ITEMSPERIODOS ',datosPropuesta.itemsPeriodos );
                //let test3 =  this.parseJsonAsyncFunc(data.datosPropuesta);
                */
                console.log('VALOR DE DATA ----> ', data)
                console.log('VALOR DE datosPreciosActual ------------------> ', datosPreciosActual)
                console.log('VALOR DE datosPropuesta ----> ', datosPropuesta)
                console.log('VALOR DE datosContrato ------> ', datosContrato)

                if (datosContrato.OfertaTipo == "P") {

                    let formActual = datosContrato.T_Propuesta.actual.datos


                    var itemsTablaActual: any = [];
                    // console.log("TABLAAAA",itemsTabla[0]);


                    itemsTablaActual[0] = { 'periodo': 'P1', 'precioEnergia': formActual.precioTEP1, 'precioPotencia': formActual.precioTP1 };
                    itemsTablaActual[1] = { 'periodo': 'P2', 'precioEnergia': formActual.precioTEP2, 'precioPotencia': formActual.precioTP2 };
                    itemsTablaActual[2] = { 'periodo': 'P3', 'precioEnergia': formActual.precioTEP3, 'precioPotencia': formActual.precioTP3 };
                    itemsTablaActual[3] = { 'periodo': 'P4', 'precioEnergia': formActual.precioTEP4, 'precioPotencia': formActual.precioTP4 };
                    itemsTablaActual[4] = { 'periodo': 'P5', 'precioEnergia': formActual.precioTEP5, 'precioPotencia': formActual.precioTP5 };
                    itemsTablaActual[5] = { 'periodo': 'P6', 'precioEnergia': formActual.precioTEP6, 'precioPotencia': formActual.precioTP6 };



                    let nombreFichero = 'Propuesta_' + data.cups + (new Date().getFullYear()).toString();
                    this.setState({
                        ...this.state,
                        formProducto: datosPropuesta,
                        formActual: datosPreciosActual,
                        totalComparacion: data.T_Propuesta.producto.totalComparacion,
                        cups: data.cups,
                        periods: datosPropuesta.periodos,
                        itemsTabla: test2.itemsPeriodos,
                        itemsTablaActual: itemsTablaActual,
                        producto: datosContrato.T_Propuesta.producto.producto,
                        costeOperativo: datosPropuesta.costeOperativo,
                        costeOperativoPotencia: datosPropuesta.costeOperativoPotencia,
                        correoContacto: data.correoContacto,
                        nombreFichero: nombreFichero,
                        datosContrato: datosContrato,
                        datosPropuestaTest: datosPropuesta
                    },
                        this.fetchSip)
                } else if (datosContrato.OfertaTipo == "MP") {

                    let nombreFichero = 'Propuesta_' + data.cups + (new Date().getFullYear()).toString();

                    console.log("data", data)
                    console.log("Datos precios actual", datosPreciosActual)
                    console.log("Datos propuesta", datosPropuesta)
                    console.log("Datos contrato", datosContrato)

                    this.setState({
                        ...this.state,
                        formProducto: datosPropuesta.datos,
                        totalComparacion: data.T_Propuesta.producto.totalComparacion,
                        cups: data.cups,
                        periods: datosPropuesta.periodos,
                        itemsTabla: datosPropuesta.itemsPeriodos,
                        itemsTablaActual: itemsTablaActual,
                        producto: datosContrato.T_PropuestaComercial,
                        productoTarifas: datosContrato.T_PropuestaComercialDatosIndividual,
                        costeOperativo: datosContrato.T_PropuestaComercial.GastoOperativo,
                        costeOperativoPotencia: datosContrato.T_PropuestaComercial.GastoOperativoPotencia,
                        correoContacto: data.correoContacto,
                        nombreFichero: nombreFichero,
                        datosContrato: datosContrato,
                        datosPropuestaTest: datosPropuesta
                    },
                        this.fetchSip)
                }
            }).catch(function () {
                console.log('Failure')
                //notifyErrorDB();
            });


    }

    tipoContrato = (props) => {

        const tipoTarifa = props.tipoTarifa;
        const totalComparacion = props.totalComparacion
        const totalComparacionProducto = props.producto
        const styleFullWith = { "width": "100%" };
        if (tipoTarifa === 'P') {
            return (
                <Row className="card-deck w-100">
                    <Card style={styleFullWith} className="mt-3 mb-3 card-document">
                        <CardBody>
                            <Row className="">
                                <h1 className="col">
                                    Resumen ahorros
                                </h1>
                            </Row>
                            <Row className="mt-4">
                                <Col className="col-auto pt-2 pb-2 mr-2">
                                    <h2>Ahorro periodo seleccionado:</h2>
                                </Col>

                                <Col className="col-auto mr-2 d-flex bg-energia radius-pot text-center align-items-center">
                                    <h3 className="mt-2 mb-2 justify-content-center align-self-center text-center">{totalComparacion.ahorroPeriodo}€</h3>
                                </Col>

                                <Col className="col-auto d-flex bg-units radius-pot text-center align-items-center">
                                    <h3 className="mt-2 mb-2 justify-content-center align-self-center text-center">{totalComparacion.ahorroPercent}%</h3>
                                </Col>

                                <Col className="col-auto pt-2 pb-2 ml-6">
                                    <h2>Ahorro anual:</h2>
                                </Col>

                                <Col className="col-auto d-flex bg-energia radius-pot text-center align-items-center">
                                    <h3 className="mt-2 mb-2 justify-content-center align-self-center">{totalComparacion.ahorroAnual}€</h3>
                                </Col>

                            </Row>
                        </CardBody>
                    </Card>
                </Row>
            )
        } else {
            return (
                <Row className="card-deck w-100">
                    <Card style={styleFullWith} className="mt-3 mb-3 card-document">
                        <CardBody>
                            <Row className="">
                                <h1 className="col">
                                    Resumen costes
                                </h1>
                            </Row>
                            <Row className="mt-4">
                                <Col className="col-auto pt-2 pb-2 ml-6">
                                    <h2>Coste energía:</h2>
                                </Col>

                                <Col className="col-auto d-flex bg-energia radius-pot text-center align-items-center">
                                    <h3 className="mt-2 mb-2 justify-content-center align-self-center">{(totalComparacionProducto.costeTotalPropuestaEnergia).toFixed(2)}€</h3>
                                </Col>

                                <Col className="col-auto pt-2 pb-2 ml-6">
                                    <h2>Coste potencia:</h2>
                                </Col>

                                <Col className="col-auto d-flex bg-energia radius-pot text-center align-items-center">
                                    <h3 className="mt-2 mb-2 justify-content-center align-self-center">{(totalComparacionProducto.costeTotalPropuestaPotencia).toFixed(2)}€</h3>
                                </Col>

                                <Col className="col-auto pt-2 pb-2 ml-6">
                                    <h2>Coste anual:</h2>
                                </Col>

                                <Col className="col-auto d-flex bg-energia radius-pot text-center align-items-center">
                                    <h3 className="mt-2 mb-2 justify-content-center align-self-center">{(totalComparacionProducto.costeTotalPropuesta).toFixed(2)}€</h3>
                                </Col>
                            </Row>
                            <Row className="mt-4">
                                <Col className="col-auto pt-2 pb-2 ml-6">
                                    <h4>Los costes reflejados en el documento llevan incluidos las diferentes tasas e impuestos asociados al consumo eléctrico</h4>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Row>)
        }
    }


    datosPropuesta = (props) => {

        const tipoTarifa = props.tipoTarifa;
        const formProducto = props.formProducto


        if (tipoTarifa === 'P') {
            return (
                <Row className="panel-data-resolve pb-0">
                    <div className="col">
                        <div className="item-data">
                            <h4 className="">Término Potencia</h4>
                            <div className="">{formProducto.sumPotencia}€</div>
                        </div>
                        <div className="item-data">
                            <h4>Penalización Potencia</h4>
                            <div className="">{formProducto.penalizacionPotencia}€</div>
                        </div>
                        <div className="item-data">
                            <h4 className="">I.E. 5,11%</h4>
                            <div className="">{formProducto.iePercent}€</div>
                        </div>
                        <div className="item-data">
                            <h4 className="">Servicios Adicionales</h4>
                            <div className="">{formProducto.serviciosAdicionales}€</div>
                        </div>
                        <div className="item-data">
                            <h4 className="">IVA 21%</h4>
                            <div className="">{formProducto.iva}€</div>
                        </div>
                        {/* </div>
                <div className="col"> */}
                        <div className="item-data">
                            <h4>Término Energía</h4>
                            <div className="">{formProducto.sumEnergia}€</div>
                        </div>
                        <div className="item-data">
                            <h4>Penalización Energía</h4>
                            <div className="">{formProducto.penalizacionEnergia}€</div>
                        </div>
                        <div className="item-data">
                            <h4 className="">Base Impuesto Eléctrico</h4>
                            <div className="">{formProducto.impuestoElectrico}€</div>
                        </div>
                        <div className="item-data">
                            <h4 className="">Alquiler del Equipo a Medida</h4>
                            <div className="">{formProducto.alquilerEquipoMedida}€</div>
                        </div>
                        <div className="item-data">
                            <h4 className="">Base imponible</h4>
                            <div className="">{formProducto.bi}€</div>
                        </div>
                        <div className="item-data bg-total align-middle p-2">
                            <h2>Total</h2>
                            <div><h2>{formProducto.total}€</h2></div>
                        </div>
                    </div>
                </Row>

            )
        } else {
            return (
                <div>
                </div>)
        }
    }

    datosPropuestaActual = (props) => {

        const tipoTarifa = props.tipoTarifa;
        const formActual = props.formActual


        if (tipoTarifa === 'P') {
            return (
                <Row className="panel-data-resolve pb-0">
                    <div className="col">
                        <div className="item-data">
                            <h4 className="">Término Potencia</h4>
                            <div className="">{formActual.sumPotencia}€</div>
                        </div>
                        <div className="item-data">
                            <h4 className="">Penalización Potencia</h4>
                            <div className="">{formActual.penalizacionPotencia}€</div>
                        </div>
                        <div className="item-data">
                            <h4 className="">I.E. 5,11%</h4>
                            <div className="">{formActual.iePercent}€</div>
                        </div>
                        <div className="item-data">
                            <h4 className="">Servicios Adicionales</h4>
                            <div className="">{formActual.serviciosAdicionales}€</div>
                        </div>
                        <div className="item-data">
                            <h4 className="">IVA 21%</h4>
                            <div className="">{formActual.iva}€</div>
                        </div>
                        {/* </div>
                <div className="col"> */}
                        <div className="item-data">
                            <h4>Término Energía</h4>
                            <div className="">{formActual.sumEnergia}€</div>
                        </div>
                        <div className="item-data">
                            <h4>Penalización Energía</h4>
                            <div className="">{formActual.penalizacionEnergia}€</div>
                        </div>
                        <div className="item-data">
                            <h4 className="">Base Impuesto Eléctrico</h4>
                            <div className="">{formActual.impuestoElectrico}€</div>
                        </div>
                        <div className="item-data">
                            <h4 className="">Alquiler del Equipo a Medida</h4>
                            <div className="">{formActual.alquilerEquipoMedida}€</div>
                        </div>
                        <div className="item-data">
                            <h4 className="">Base imponible</h4>
                            <div className="">{formActual.bi}€</div>
                        </div>
                        <div className="item-data bg-total align-middle p-2">
                            <h2>Total</h2>
                            <div><h2>{formActual.total}€</h2></div>
                        </div>
                    </div>
                </Row>

            )
        } else {
            return (
                <div>
                </div>)
        }
    }



    graficoAhorro = (props) => {

        const tipoTarifa = props.tipoTarifa;
        const dataAhorro = props.data
        const options = props.options
        const datosContrato = props.datosContrato

        if (tipoTarifa === 'P') {
            return (
                <div>
                    <h2 className='title'>Resumen costes</h2>
                    <div>
                        <Bar data={dataAhorro} width={80} height={40} options={options} />
                    </div>
                </div>
            )
        } else {
            return (
                <div>

                    <p className="ml-5 mb-3">
                        Esta propuesta ha sido realizada por el representante:<br></br>
                    </p>

                    <ListGroup>
                        <ListGroupItem className="list-item-documento"><strong>Nombre:</strong> {datosContrato.T_Representante.nombreRepresentante}</ListGroupItem>
                        <ListGroupItem className="list-item-documento"><strong>Apellido:</strong> {datosContrato.T_Representante.apellido1Representante}</ListGroupItem>
                        <ListGroupItem className="list-item-documento"><strong>NIF/CIF:</strong> {datosContrato.T_Representante.NIFRepresentante}</ListGroupItem>
                        <ListGroupItem className="list-item-documento"><strong>Cargo:</strong> {datosContrato.T_Representante.CargoRepresentante}</ListGroupItem>
                        <ListGroupItem className="list-item-documento"><strong>Correo Elec.:</strong> {datosContrato.T_Representante.correoRepresentante}</ListGroupItem>
                        <ListGroupItem className="list-item-documento"><strong>Teléfono:</strong> {datosContrato.T_Representante.telefonoRepresentante}</ListGroupItem>
                        <ListGroupItem className="list-item-documento"><strong>Fecha Propuesta:</strong> {datosContrato.fechaPropuesta}</ListGroupItem>
                    </ListGroup>


                </div>)
        }
    }

    render() {
        const isLoading = this.state.isLoading;
        const sip = this.state.sip;
        //var itemsTablapro = this.state.datosPropuestaTest.itemsPeriodos;
        const totalComparacion = this.state.totalComparacion;
        const producto = this.state.formProducto;
        const productoTarifas = this.state.productoTarifas;
        const formActual = this.state.formActual;
        const formProducto = this.state.formProducto;
        const periodos = this.state.periods;
        const cups = this.state.cups;
        const styleFullWith = { "width": "100%" };
        const correoContacto = this.state.correoContacto;
        const datosContrato = this.state.datosContrato;

        console.log(correoContacto)
        console.log('VALOR DE DATOS CONTRATOS ------>', datosContrato)
        console.log('DENTRO DEL DOCUMENTO')
        console.log(this.state)
        console.log(this.props)
        const nombreFichero = this.state.nombreFichero
        //              fileName={`Report for ${cups} ${new Date().getFullYear()}`}

        //PLOTS DATA

        let ofertaTipo = "";
        console.log("sip", sip);
        console.log("datooos", this.state.datosContrato);
        console.log("FORMPRODUCTO", this.state);
        if (this.state.datosContrato === undefined) {
            ofertaTipo = "";
        } else {
            ofertaTipo = this.state.datosContrato.OfertaTipo;
        }

        var data: any = {}
        var dataAhorro: any = {}
        let options = {}


        if (ofertaTipo === "P") {
            var datasetProducto = [0];
            datasetProducto.length = 0;
            datasetProducto[datasetProducto.length] = formProducto.sumEnergia;
            datasetProducto[datasetProducto.length] = formProducto.sumPotencia;
            datasetProducto[datasetProducto.length] = formProducto.serviciosAdicionales;
            datasetProducto[datasetProducto.length] = formProducto.alquilerEquipoMedida;
            datasetProducto[datasetProducto.length] = (formProducto.sumEnergia + formProducto.sumPotencia + formProducto.serviciosAdicionales + formProducto.alquilerEquipoMedida);
            var datasetActual = [0];
            datasetActual.length = 0;
            datasetActual[datasetActual.length] = formActual.sumEnergia;
            datasetActual[datasetActual.length] = formActual.sumPotencia;
            datasetActual[datasetActual.length] = formActual.serviciosAdicionales;
            datasetActual[datasetActual.length] = formActual.alquilerEquipoMedida;
            var datasetAhorro = this.state.totalComparacion.datosAhorro;
            datasetActual[datasetActual.length] = (formActual.sumEnergia + formActual.sumPotencia + formActual.serviciosAdicionales + formActual.alquilerEquipoMedida);

            data = {
                labels: ['Coste Energía', 'Costes Potencia', 'Costes Servicios', 'Costes Equipos', 'Total'],
                datasets: [
                    {
                        label: 'Resumen Producto Ofertado',
                        data: datasetProducto,
                        backgroundColor: 'rgb(255, 99, 132)',
                    },
                    {
                        label: 'Resumen Factura Actual',
                        data: datasetActual,
                        backgroundColor: 'rgb(54, 162, 235)',
                    },
                ],
            }

            dataAhorro = {
                labels: ['Total Coste Actual', 'Total Propuesta', 'Ahorro'],
                datasets: [
                    {
                        label: 'Resumen de coste',
                        data: datasetAhorro,
                        backgroundColor: [
                            'rgba(54, 162, 235, 0.2)',
                            'rgba(255, 99, 132, 0.2)',
                            'rgba(255, 206, 86, 0.2)',
                            'rgba(75, 192, 192, 0.2)',
                            'rgba(153, 102, 255, 0.2)',
                            'rgba(255, 159, 64, 0.2)',
                        ],
                        borderColor: [
                            'rgba(54, 162, 235, 1)',
                            'rgba(255, 99, 132, 1)',
                            'rgba(255, 206, 86, 1)',
                            'rgba(75, 192, 192, 1)',
                            'rgba(153, 102, 255, 1)',
                            'rgba(255, 159, 64, 1)',
                        ],
                        borderWidth: 1,
                    },
                ],
            }

            options = {
                maintainAspectRatio: true,
                legend: {
                    display: true,
                    labels: {
                        fontColor: 'rgb(255, 99, 132)'
                    },
                    scales: {
                        yAxes: [
                            {
                                ticks: {
                                    beginAtZero: true,
                                },
                            },
                        ],
                    },
                },
            }

        } else if (ofertaTipo === "MP") {

            var datasetProducto = [0];
            datasetProducto.length = 0;
            datasetProducto[datasetProducto.length] = datosContrato.T_PropuestaComercialDatosIndividual.costeTotalPropuestaEnergia;
            datasetProducto[datasetProducto.length] = datosContrato.T_PropuestaComercialDatosIndividual.costeTotalPropuestaPotencia;
            datasetProducto[datasetProducto.length] = 0;
            datasetProducto[datasetProducto.length] = 0;
            datasetProducto[datasetProducto.length] = datosContrato.T_PropuestaComercialDatosIndividual.costeTotalPropuesta;


            data = {
                labels: ['Coste Energía', 'Costes Potencia', 'Costes Servicios', 'Costes Equipos', 'Total'],
                datasets: [
                    {
                        label: 'Resumen Producto Ofertado',
                        data: datasetProducto,
                        backgroundColor: 'rgb(255, 99, 132)',
                    }

                ],
            }

            dataAhorro = {
                labels: ['Total Coste Actual', 'Total Propuesta', 'Ahorro'],
                datasets: [
                    {
                        label: 'Resumen de coste',
                        data: datasetAhorro,
                        backgroundColor: [
                            'rgba(54, 162, 235, 0.2)',
                            'rgba(255, 99, 132, 0.2)',
                            'rgba(255, 206, 86, 0.2)',
                            'rgba(75, 192, 192, 0.2)',
                            'rgba(153, 102, 255, 0.2)',
                            'rgba(255, 159, 64, 0.2)',
                        ],
                        borderColor: [
                            'rgba(54, 162, 235, 1)',
                            'rgba(255, 99, 132, 1)',
                            'rgba(255, 206, 86, 1)',
                            'rgba(75, 192, 192, 1)',
                            'rgba(153, 102, 255, 1)',
                            'rgba(255, 159, 64, 1)',
                        ],
                        borderWidth: 1,
                    },
                ],
            }

            options = {
                maintainAspectRatio: true,
                legend: {
                    display: true,
                    labels: {
                        fontColor: 'rgb(255, 99, 132)'
                    },
                    scales: {
                        yAxes: [
                            {
                                ticks: {
                                    beginAtZero: true,
                                },
                            },
                        ],
                    },
                },
            }


        }


        //var nombreproducto = producto.nombreOferta || producto.nombreProducto

        var nombreproducto = producto.nombreProducto

        console.log('THIS.STATE EN RENDER ---------------> ', this.state)
        //Hola {this.state.UserStore.data.first_name}!
        let ofertaTipoContrato = "P";
        console.log("sip", sip);
        console.log("datooos", this.state.datosContrato);
        console.log("FORMPRODUCTO", this.state);
        if (this.state.datosContrato === undefined) {
            ofertaTipo = "P";
        } else {
            ofertaTipo = this.state.datosContrato.OfertaTipo;
        }


        return (
            <>
                <GetUserData></GetUserData>

                <Row className="card-deck mb-3">
                    <Card id="supplyInformation" className={this.props.cardClass}>
                        <CardBody className="p-4">
                            <Row>
                                <Col md="2">
                                    <div className="col">

                                        <CardBody>
                                            <Button className="btn-icon" color="primary" type="button" id="imprimirProptooltip" onClick={this.exportPDFWithComponentclick} >
                                                <span className="btn-inner--icon mr-1">
                                                    <i className="fas fa-file-pdf fa-3x" />
                                                </span>
                                                <span className="btn-inner--text">Exportar PDF</span>
                                                <UncontrolledTooltip delay={0} placement="top" target="imprimirProptooltip">
                                                    EXPORTAR PDF
                                                </UncontrolledTooltip>
                                            </Button>
                                        </CardBody>
                                    </div>
                                </Col>
                                <Col md="6">
                                    <div className="col">
                                        <CardBody>
                                            <Form>
                                                <div className="custom-file">
                                                    <input
                                                        className="custom-file-input lg"
                                                        id="customFileLang"
                                                        lang="es"
                                                        type="file"
                                                        ref={this.fileInput}
                                                        onChange={this.SendEmail}
                                                    />
                                                    <FormText color="muted">
                                                        Seleccionar propuesta para su envío por correo electrónico
                                                    </FormText>
                                                </div>
                                            </Form>
                                        </CardBody>
                                    </div>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Row>

                <PDFExport
                    ref={this.pdfExportComponent}
                    paperSize="auto"
                    margin={40}
                    fileName={`${nombreFichero}`}
                    author="PsGestion"
                >
                    <div id="document-page" ref={this.container} className="border p-4 mb-5 shadow">
                        <LoadingOverlay
                            active={isLoading}
                            spinner
                            text="Cargando...">

                            <Row className="row-grid align-items-center">
                                <Col className="order-md-2" md="4">
                                    <img
                                        alt=''
                                        className="img-fluid"
                                        src={
                                            this.state.UserStore.data.app_logo
                                                ? this.state.UserStore.data.app_logo
                                                : require("../../../assets/img/brand/dynargy_logo_verde_h.png")
                                        }
                                    />
                                </Col>
                            </Row>
                            <h1 className="mt-5 ml-5 mr-5 mb-4">Documento resumen producto: {nombreproducto}</h1>

                            <Row className="">
                                <p className="ml-5 mb-3">
                                    Hola!<br></br>
                                    Gracias por confiar en nuestro servicio. A continuación le mostramos los datos más relevantes de la propuesta realizada por uno de nuestros agentes.
                                </p>
                            </Row>

                            <Row className="card-deck" style={styleFullWith}>
                                <Card className="mt-3 mb-3 p-3 card-document">
                                    <CardBody>
                                        <Row>
                                            <Col md="6">
                                                <CardTitle className="info-title"><h2>Información del suministro</h2></CardTitle>
                                                <ListGroup>
                                                    <ListGroupItem className="list-item-documento"><strong>CUPS:</strong> {sip.cups}</ListGroupItem>
                                                    <ListGroupItem className="list-item-documento"><strong>Titular:</strong> {sip.nombreCompletoTitular}</ListGroupItem>
                                                    <ListGroupItem className="list-item-documento"><strong>NIF/CIF:</strong> {sip.CIF}</ListGroupItem>
                                                    <ListGroupItem className="list-item-documento"><strong>Direccion:</strong> {sip.direccion_ps}</ListGroupItem>
                                                    <ListGroupItem className="list-item-documento"><strong>Provincia:</strong> {sip.nombreProvinciaPS}</ListGroupItem>
                                                    <ListGroupItem className="list-item-documento"><strong>Localidad:</strong> {sip.nombreMunicipioPS}</ListGroupItem>
                                                    <ListGroupItem className="list-item-documento"><strong>Consumo Anual:</strong> {sip.kWhAnual} Kw</ListGroupItem>
                                                    <ListGroupItem className="list-item-documento"><strong>Tarifa actual:</strong> {sip.tarifaATR}</ListGroupItem>
                                                </ListGroup>
                                            </Col>
                                            <Col md="6">
                                                <CardTitle className="info-title"><h2>Consumos punto suministro</h2></CardTitle>
                                                <RateInformation
                                                    isLoading={isLoading}
                                                    sip={sip}
                                                    cardClass="col-6 card-document">
                                                </RateInformation>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <CardTitle className="mt-4 info-title"><h2>Resumen producto {nombreproducto}</h2></CardTitle>
                                                <Row>
                                                    <Col><strong>Precio P1: </strong><br></br>{producto.precioPotenciaP1 || this.state.productoTarifas.precioPotenciaAnualP1}</Col>
                                                    <Col><strong>Precio P2: </strong><br></br>{producto.precioPotenciaP2 || this.state.productoTarifas.precioPotenciaAnualP2}</Col>
                                                    <Col><strong>Precio P3: </strong><br></br>{producto.precioPotenciaP3 || this.state.productoTarifas.precioPotenciaAnualP3}</Col>
                                                    <Col><strong>Precio P4: </strong><br></br>{producto.precioPotenciaP4 || this.state.productoTarifas.precioPotenciaAnualP4}</Col>
                                                    <Col><strong>Precio P5: </strong><br></br>{producto.precioPotenciaP5 || this.state.productoTarifas.precioPotenciaAnualP5}</Col>
                                                    <Col><strong>Precio P6: </strong><br></br>{producto.precioPotenciaP6 || this.state.productoTarifas.precioPotenciaAnualP6}</Col>
                                                </Row>
                                                <Row className="mt-2">
                                                    <Col><strong>Precio E1: </strong><br></br>{producto.precioEnergiaP1 || this.state.productoTarifas.precioEnergiaAnualP1}</Col>
                                                    <Col><strong>Precio E2: </strong><br></br>{producto.precioEnergiaP2 || this.state.productoTarifas.precioEnergiaAnualP2}</Col>
                                                    <Col><strong>Precio E3: </strong><br></br>{producto.precioEnergiaP3 || this.state.productoTarifas.precioEnergiaAnualP3}</Col>
                                                    <Col><strong>Precio E4: </strong><br></br>{producto.precioEnergiaP4 || this.state.productoTarifas.precioEnergiaAnualP4}</Col>
                                                    <Col><strong>Precio E5: </strong><br></br>{producto.precioEnergiaP5 || this.state.productoTarifas.precioEnergiaAnualP5}</Col>
                                                    <Col><strong>Precio E6: </strong><br></br>{producto.precioEnergiaP6 || this.state.productoTarifas.precioEnergiaAnualP6}</Col>
                                                </Row>
                                            </Col>
                                        </Row>

                                    </CardBody>
                                </Card>
                            </Row>

                            <this.tipoContrato tipoTarifa={ofertaTipo} totalComparacion={totalComparacion} producto={this.state.productoTarifas} />


                            <Row className="card-deck" style={styleFullWith}>

                                <Card className="mt-3 mb-3 p-3 card-document">
                                    <h2 className='title'>Costes anuales</h2>
                                    <div>
                                        <Bar data={data} width={80} height={40} options={options} />
                                    </div>
                                </Card>

                                <Card className="mt-3 mb-3 p-3 card-document">

                                    <this.graficoAhorro tipoTarifa={ofertaTipo} data={dataAhorro} options={options} datosContrato={datosContrato} />

                                </Card>
                            </Row>

                            <Row className="card-deck" style={styleFullWith}>

                                <Card className="mt-3 mb-3 card-billing-documento card-document">
                                    <Form innerRef={this.formProductoRef}>
                                        <CardBody>
                                            <Row className="">
                                                <h2 className="col">
                                                    Facturación {producto.nombreOferta}
                                                </h2>
                                            </Row>
                                            <Row>
                                                <DataTable

                                                    columns={columnsDatosPropuesta}
                                                    data={this.state.itemsTabla}

                                                    fixedHeader={true}


                                                    striped
                                                    pointerOnHover

                                                    customStyles={customStyles}

                                                    noDataComponent={"Sin datos para mostrar"}

                                                />
                                            </Row>

                                            <this.datosPropuesta tipoTarifa={ofertaTipo} formProducto={formProducto} />



                                        </CardBody>
                                    </Form>
                                </Card>

                                <Card className="mt-3 mb-3 card-billing-documento card-document">
                                    <Form innerRef={this.formActualRef}>
                                        <CardBody>
                                            <Row className="">
                                                <h2 className="col">
                                                    Facturación comercializadora actual
                                                </h2>
                                            </Row>
                                            <Row>
                                                <DataTable

                                                    columns={columnsDatosPropuestaActual}
                                                    data={this.state.itemsTablaActual}

                                                    fixedHeader={true}

                                                    striped
                                                    pointerOnHover

                                                    customStyles={customStyles}

                                                    noDataComponent={"Sin datos para mostrar"}

                                                />

                                            </Row>

                                            <this.datosPropuestaActual tipoTarifa={ofertaTipo} formActual={formActual} />


                                        </CardBody>
                                    </Form>
                                </Card>

                            </Row>

                            <Row className="m-3 border-top">
                                <p className="float-right text-right mt-2">@ PsGestion</p>
                                <p>
                                    <small>
                                        Los precios del producto que se muestran en este documento son vigentes para el próximo mes a partir de la fecha de la propuesta. Se han calculado en base a los datos existentes de consumos suministrados por las distribuidoras eléctricas, las comercializadoras y el cliente, si no se especifica de otro modo. Estos cálculos son orientativos y pueden sufrir variaciones ajenas al agente.
                                        <br></br>
                                        Las opciones de pago y de cancelación dependen de las condiciones particulares de la comercializadora elegída. Comprueba sus condiciones antes de reservar.
                                        <br></br>
                                        Te recordamos que estos e-mails son automáticos: no los respondas, por favor.
                                    </small>
                                </p>
                            </Row>

                        </LoadingOverlay>
                    </div>
                </PDFExport>
                <ToastContainer
                    position="bottom-center"
                    autoClose={3000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
            </>
        );
    }
}

export default withApollo(ComercialProductoDocumento);
