import React from "react";
import {
    Button,
    FormGroup,
	Modal,
	Label,
	Col,
	Input,
    Row,
    Form,
} from "reactstrap";
import { Multiselect } from "multiselect-react-dropdown";
import client from "../../../api/client";
import { LIST_COMERCIAL_PRODUCTOSGAS } from "../../../queries/comercial-producto/comercial-producto";

interface IModalComparativeMultiProps {
    itemsSelected: Array<any>;
    isOpen: boolean;
    toggleMulti: any;
    sip: any;
    periods: number[];
    generateComparative?: Function;
}

interface IModalComparativeMultiState {
    form: {
        idProducto: any []
        costeOperativo: number
    },
    cups: string,
    user: any,
    sip: any,
    periods: number[],
    itemsSelected: any[],
    comercialProductos: any[]
}

export default class SipGasComparativeFreeMultiModal extends React.Component<IModalComparativeMultiProps,IModalComparativeMultiState> {
	public formComparativeRef: any = null;
	public formSaveComparativeRef: any = null;
	public state: IModalComparativeMultiState = {
        // --- Data
        form: {
            costeOperativo: 0,
            idProducto: []
        },
		cups: "",
		user: {},
        sip: {},
        periods: [],
        itemsSelected: [],
        comercialProductos: []
	};

    componentWillReceiveProps(newProps) {
        this.setState({itemsSelected: newProps.itemsSelected});
    }

	componentDidMount() {
        this.formComparativeRef = React.createRef();
        this.fetchItems();
    }

	fetchItems = async () => {
console.log('VALOR DE this.props.sip en sipComparativeFreeModal ------------->',this.props)
console.log('VALOR DE this.state en sipComparativeFreeModal ------------->',this.state)
    var periodosCupsPotencia = ''
    var periodosCupString = ''

    if (['31', '32', '33', '34'].indexOf(this.props.sip.codigoPeajeEnVigor) >= 0) {
      periodosCupString = '1';
    } else {
      periodosCupString = '2';
    }


      const companyUser = await JSON.parse(await localStorage.getItem("user")!);
      const companyIdUser = companyUser.companyId;
      //const periodosCupString =this.props.sip.NumeroPeriodos;
      //const periodosCupsPotencia = this.props.sip.NumeroPeriodosPotencia
      console.log('NOMNRE uSER', companyIdUser, companyUser)
      const consumoGasAnual= this.props.sip.ConsumoAnualTotal;
      const tarifa = this.props.sip.codigoPeajeEnVigor;

      await client.query({
             query: LIST_COMERCIAL_PRODUCTOSGAS,
       variables: { companyId: companyIdUser, periodos: periodosCupString, consumoGasAnual: consumoGasAnual, tarifa:tarifa },
         })
         .then((result) => {
             let data = result.data.comercialGasProductos;

             this.setState({ comercialProductos: data })
         });
      }

	generateComparative = async ( event ) => {
        if(this.props.generateComparative )
        {
          console.log('DENTRO DE generateComparative')
          console.log(this.state.form)
          console.log('VALOR DE this.props.generateComparative ----->', this.props.generateComparative)
            if( this.state.form.idProducto.length > 1  )
            {
              console.log('DENTRO DE generateComparative IF')
              console.log(this.state.form)
              //let producto:any[] = this.state.comercialProductos.filter( item => Number(item.id) === Number(this.state.form.idProducto) )[0];
              let producto:any[] = this.state.form.idProducto;
                console.log(producto)
                this.props.generateComparative({
                    productoSeleccionado: producto,
                    costeOperativo: Number(this.state.form.costeOperativo)
                });
                this.props.toggleMulti();
                this.setState({
                    form: {
                        costeOperativo: 0,
                        idProducto: []
                    }
                })
                return;
            } else {
              alert('Es neceario seleccionar al menos dos productos');
              return;
            }
//            alert('Producto No seleccionado');
        }
    }

    onChangeField = async ( event ) => {
        const name = event.target.name;
        let value = event.target.value;
        let inputs = {};
        console.log('DENTO DE ONCHANGEFIELD')
        console.log(value)
        console.log(this.state.form)
        if ( value !== "" || value !== null)
        {
            inputs[name] = value;
            this.setState({
                form: {
                    ...this.state.form,
                    ...inputs
                }
            });
        }
    }

    onSelect = (selectedList, selectedItem) => {
        console.log('dentro de OnSelect 2D');
        console.log(selectedList)
        console.log(selectedItem)
        let productos:any[] = selectedList
        console.log('VALORES DE productos')
        console.log(productos)
        //console.log(this.state.form)

        this.setState({
            form: {
                //costeOperativo: 0,
                ...this.state.form,
                idProducto: productos
            }
        });
    };


	render() {
        const sip: any = this.props.sip;
        const form: any =  this.state.form;
        const productos: any[] = this.state.comercialProductos;
        var peajeString = sip.codigoPeajeEnVigor;
        var peaje='';

        if (peajeString != undefined){


        const insertAt = (str, sub, pos) => `${str.slice(0, pos)}${sub}${str.slice(pos)}`;
         peaje = insertAt(peajeString, '.', 1);}

		return (
			<>
				<Modal
                    size="sm"
                    className="modal-dialog-centered modal-sip-comparative-free"
                    isOpen={this.props.isOpen}
                    toggle={this.props.toggleMulti}>
                    <div className="modal-body">
                        <Row>
                            <div className="modal-info">
                              <Row>
                                <h1>PRODUCTOS GAS</h1>
                                </Row>

                                  </div>
                        </Row>
                    <h4> CONSUMO ULTIMOS 12 MESES {sip.ConsumoAnualTotal}</h4>
                        <Form innerRef={this.formComparativeRef}>
                            <Row>
                                <Col>
                                    <FormGroup>
                                        <Label className="form-control-label" htmlFor="multipro">
                                            Tarifa de acceso Actual
                                        </Label>
                                        <Input
                                            id="tarifaAcceso"
                                            placeholder=""
                                            name="tarifaAcceso"
                                            value={peaje}
                                            type="text"
                                            readOnly />
                                    </FormGroup>

                                    <Multiselect
                                    options = {productos}
                                    //{productos.map((item:any, index) => <option value={item.id} key={index}>{item.nombreOferta}</option>)} // Options to display in the dropdown
                                    //selectedValues={this.state.selectedValue} // Preselected value to persist in dropdown
                                    onSelect={this.onSelect} // Function will trigger on select event
                                    //onRemove={this.onRemove} // Function will trigger on remove event
                                    displayValue="nombreOferta" // Property name to display in the dropdown options
                                    />


                                    <FormGroup>
                                        <Label className="form-control-label" htmlFor="costeOperativomulti">
                                            CO c€/kWh
                                        </Label>
                                        <Input
                                            id="costeOperativo"
                                            placeholder=""
                                            value={form.costeOperativo}
                                            name="costeOperativo"
                                            type="number"
                                            onChange={this.onChangeField}
                                            required/>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Form>
                    </div>
                    <div className="modal-footer">
	                    <Button
	                        onClick={this.props.toggleMulti}
	                        color="secondary"
	                        data-dismiss="modal"
	                        type="button">
	                        Cerrar
	                    </Button>
	                    <Button onClick={this.generateComparative} color="primary" type="button">
	                        Generar Comparativa
	                    </Button>
                    </div>
                </Modal>
			</>
		);
	}
}
