import Environment from '../../constants/environment';
//import ContratoRepresentanteFreeModal from "./ContratoRepresentanteModal";
//import SipGasDetailsExtend from "./SipGasDetailsExtend";

import React, { forwardRef } from "react";
import Reflux from "reflux";
import axios from 'axios';
// reactstrap components
import {
    Card, Container, Row, CardTitle, CardBody, Label,
    FormGroup,
    Input, FormText,
    Form, Col, Button,
    UncontrolledTooltip, CardHeader, Collapse
} from "reactstrap";
import {
    Link,
    useRouteMatch
} from 'react-router-dom';
import LoadingOverlay from "react-loading-overlay";
import { CSVLink, CSVDownload } from "react-csv";

// core components
import Header from "../../components/Headers/Header";

import { withApollo } from "react-apollo";
import moment from "moment";
import 'moment/locale/es'

import ReactBSAlert from "react-bootstrap-sweetalert";

import GetUserData from "../../components/User/GetUserData";
import { UserStore } from "../../store/UserStore";
import { saveAs } from 'file-saver';
import SipsOption from "../../components/Sips/SipsOption";
import SipsGasOption from "../../components/SipsGas/SipsGasOption";

var DatePicker = require("reactstrap-date-picker");

const FileDownload = require('js-file-download');
const formato = require('xml-formatter');
const spanishDayLabels = ['Dom', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sab'];
const spanishMonthLabels = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];

const capitalize = (s) => {
    if (typeof s !== 'string') return ''
    return s.toLowerCase().charAt(0).toUpperCase() + s.slice(1)
}

class crearProducto extends Reflux.Component<any, any> {
    public maxFileSize = 3;
    public fileInput: any = null;
    public lecturasRef: any = [];
    public inputEl: any = null;
    public formRef: any = null;
    public validFileExtensions = ["jpg", "jpeg", "png"];
    lastFilters = {};
    state = {
        filters: {
            nombreProductoSearch: "",
            idSearch: "",
            cifSearch: "",
            tipoContratoSearch: "",
            codeComercializadora: "",
            tipoEstadoSearch: "",
            productoHasta: "",
            productoDesde: ""
        },
        UserStore: {},
        nombreFichero: "",
        isLoading: false,
        copiedText: null,
        currentPage: 0,
        pageSize: 0,
        pageCount: 0,
        count: 0,
        contrato: "",
        tipoContrato: "",
        productoDetailTotal: [],
        productoSeleccionado: false,
        idProducto: '',
        productoDetail: {
            TarifaDesc: "",
            atencionCliente: "",
            caracteristicas: "",
            coIncluido: false,
            companyId: 0,
            componentesRegulados: false,
            condicionesPenalizacion: "",
            condicionesRevisionPrecios: "",
            consumoMaximoElectricidad: "0",
            consumoMaximoGas: "0",
            consumoMinimoElectricidad: "0",
            consumoMinimoGas: "0",
            descuentoTE: "0",
            descuentoTP: "0",
            esActiva: false,
            facturacion: "",
            id: "",
            idComercializadora: "",
            infoAdicional: "",
            limitaciones: "",
            nombreComercializadora: "",
            nombreOferta: "",
            nombreDocumentoContrato:"",
            ofertaInternet: false,
            ofertaOficina: false,
            ofertaTel: false,
            peaje: "",
            penalizacionMaxElec: "0",
            penalizacionMaxGas: "0",
            periocidadRevisionPrecios: "",
            periodoValidez: "",
            periodoValidezDesde: "",
            periodoValidezHasta: "",
            potenciaMaximaElectricidad: "0",
            potenciaMinimaElectricidad: "0",
            precioAlquilerContadore: "0",
            precioServicios: "0",
            precioTEP1: '0',
            precioTEP2: '0',
            precioTEP3: '0',
            precioTEP4: '0',
            precioTEP5: '0',
            precioTEP6: '0',
            precioTP1: '0',
            precioTP2: '0',
            precioTP3: '0',
            precioTP4: '0',
            precioTP5: '0',
            precioTP6: '0',
            precioTPPeriodo: '0',
            precioTPTipo: '0',
            serviciosObligatorios: false,
            tarifaATR: "",
            verde: true,
            webContrato: "",
            webOferta: "",
            fechaAltaProducto: '',
            fechaBajaProducto: '',
            tipoProducto:'',
            precioTE1:'0',
            precioTE2:'0',
            precioTFijo:'0',
            factork:'0',
            comisionContratoNuevo:"0",
            comisionContratoRenovacion:"0",
            comisionConsumoAgente1:"0",
            comisionConsumoAgente2:"0",
            comisionConsumoAdmin:"0",
            comisionGOAgente1: "0",
            comisionGOAgente2:"0"
        },
        formProducto: {
            TarifaDesc: "",
            atencionCliente: "",
            caracteristicas: "",
            coIncluido: false,
            companyId: 0,
            componentesRegulados: false,
            condicionesPenalizacion: "",
            condicionesRevisionPrecios: "",
            consumoMaximoElectricidad: "0",
            consumoMaximoGas: "0",
            consumoMinimoElectricidad: "0",
            consumoMinimoGas: "0",
            descuentoTE: "0",
            descuentoTP: "0",
            esActiva: false,
            facturacion: "",
            id: "",
            idComercializadora: "",
            infoAdicional: "",
            limitaciones: "",
            nombreComercializadora: "",
            nombreOferta: "",
            nombreDocumentoContrato:"",
            ofertaInternet: false,
            ofertaOficina: false,
            ofertaTel: false,
            peaje: "",
            penalizacionMaxElec: "0",
            penalizacionMaxGas: "0",
            periocidadRevisionPrecios: "",
            periodoValidez: "",
            periodoValidezDesde: "",
            periodoValidezHasta: "",
            potenciaMaximaElectricidad: "0",
            potenciaMinimaElectricidad: "0",
            precioAlquilerContadore: "0",
            precioServicios: "0",
            precioTEP1: '0',
            precioTEP2: '0',
            precioTEP3: '0',
            precioTEP4: '0',
            precioTEP5: '0',
            precioTEP6: '0',
            precioTP1: '0',
            precioTP2: '0',
            precioTP3: '0',
            precioTP4: '0',
            precioTP5: '0',
            precioTP6: '0',
            precioTPPeriodo: '0',
            precioTPTipo: '0',
            serviciosObligatorios: false,
            tarifaATR: "",
            verde: true,
            webContrato: "",
            webOferta: "",
            fechaAltaProducto: '',
            fechaBajaProducto: '',
            tipoProducto:'',
            precioTE1:'0',
            precioTE2:'0',
            precioTFijo:'0',
            factork:'0',
            comisionContratoNuevo:"0",
            comisionContratoRenovacion:"0",
            comisionConsumoAgente1:"0",
            comisionConsumoAgente2:"0",
            comisionConsumoAdmin:"0",
            comisionGOAgente1: "0",
            comisionGOAgente2:"0"
        },

        companyIdUser: '',
        companyNameGlobal: '',
        userGroupId: 1000,
        userId: '',
        esAgencia: false,
        esComercializadora: false,
        esAdFincas: false,
        saveDataProducto: false,
        updateDataProducto: false,
        saveProductoButtonDisabled: true,
        updateProductoButtonDisabled: true,
        saveXMLButtonDisabled: true,
        buscarButtonDisabled: true,
        CIFRepreError: false,
        inputDisabled: false,
        show: false,
        imgCollection: [],
        saveButtonDisabled: true,
        showFilters: true,
        toggledClearRows: false,
        cupsError: false,
        cifError: false,
        locale: 'es',
        inputNombreProductoDisabled: false,
        inputCifDisabled: false,
        inputIdDisabled: false,
        productoDesde: '',
        productoHasta: '',
        fechaAltaProducto: '',
        fechaBajaProducto: '',
        periodoValidezHasta: '',
        periodoValidezDesde: '',
        tipoProducto:'L',
        inputDisabledP3456: false,
        inputDisabledE456: false,
        alert: null,


    };

    sipsCSVData: any = [];
    viewSipsConsumoAnualCSVData: any = [];
    sipsConsumosCSVData: any = [];
    sipstotal: any = [];





    constructor(props) {
        super(props);
        this.store = UserStore;
        this.formRef = React.createRef();

    }



    componentDidMount() {
        const {
            match: { params },
        } = this.props;

        console.log('VALORES DE MATCH ENVIADOS POR GESTION CONTRATO', params)
        this.setState({ tipoProducto: params.tipo});

            //this.fetchRepre();
        };

///////////////////////////////////////////////////////////////////////////////////////////////////


hideAlert = () => {
    this.setState({
        alert: null
    });
};

warningAlert = () => {
    this.setState({
        alert: (
            <ReactBSAlert
                warning
                style={{ display: "block", marginTop: "-100px" }}
                title="Error en la base de datos"
                onConfirm={() => this.hideAlert()}
                onCancel={() => this.hideAlert()}
                confirmBtnBsStyle="warning"
                confirmBtnText="Ok"
                btnSize=""
            >
                Existe un error en la Base de datos.
 </ReactBSAlert>
        )
    });
};

warningAlertNIF = () => {
    this.setState({
        alert: (
            <ReactBSAlert
                warning
                style={{ display: "block", marginTop: "-100px" }}
                title="No existen registros que cumplan los requisitos"
                onConfirm={() => this.hideAlert()}
                onCancel={() => this.hideAlert()}
                confirmBtnBsStyle="warning"
                confirmBtnText="Ok"
                btnSize=""
            >
                Revise los requisitos introducidos
 </ReactBSAlert>
        )
    });
};

warningAlertFiltros = () => {
    this.setState({
        alert: (
            <ReactBSAlert
                warning
                style={{ display: "block", marginTop: "-100px" }}
                title="Existen campos obligatorios sin información"
                onConfirm={() => this.hideAlert()}
                onCancel={() => this.hideAlert()}
                confirmBtnBsStyle="warning"
                confirmBtnText="Ok"
                btnSize=""
            >
                Revise los datos introducidos
 </ReactBSAlert>
        )
    });
};

confirmAlertSave = () => {
    this.setState({
        alert: (
            <ReactBSAlert
                warning
                style={{ display: "block", marginTop: "-100px" }}
                title="¿Desea crear el producto?"
                onConfirm={() => this.hideAlert()}
                onCancel={() => this.onSave()}
                showCancel
                confirmBtnBsStyle="secondary"
                confirmBtnText="Cancelar"
                cancelBtnBsStyle="danger"
                cancelBtnText="Guardar"
                btnSize=""
            >
                No se podrá volver atrás!
        </ReactBSAlert>
        )
    });
};

confirmAlertUpdate = () => {
    this.setState({
        alert: (
            <ReactBSAlert
                warning
                style={{ display: "block", marginTop: "-100px" }}
                title="¿Desea actualizar los datos del producto?"
                onConfirm={() => this.hideAlert()}
                onCancel={() => this.onUpdate()}
                showCancel
                confirmBtnBsStyle="secondary"
                confirmBtnText="Cancelar"
                cancelBtnBsStyle="danger"
                cancelBtnText="Actualizar"
                btnSize=""
            >
                No se podrá volver atrás!
        </ReactBSAlert>
        )
    });
};

successAlert = () => {
    this.setState({
        alert: (
            <ReactBSAlert
                success
                style={{ display: "block", marginTop: "-100px" }}
                title="Correcto"
                onConfirm={() => this.hideAlert()}
                onCancel={() => this.hideAlert()}
                confirmBtnBsStyle="success"
                confirmBtnText="Ok"
                btnSize=""
            >
                Producto guardado correctamente
    </ReactBSAlert>
        )
    });
};



//////////////////////////////////////////////////////////////////////////////////////7









    componentWillReceiveProps() {

        const usuario: any = JSON.parse(JSON.stringify(this.state.UserStore['data']))

        console.log('VALORES DE USUARIO EN componentWillReceiveProps')
        console.log(usuario)

        const companyNameGlobal = usuario.company;
        const companyIdUser = usuario.companyId;

        const userGroupId = usuario.user_group_id
        const userId = usuario.id
        const esComercializadora = usuario.comercializadora
        const esAgencia = usuario.agencia
        const esAdFincas = usuario.adFincas



        if (userGroupId === 1000) {
            this.setState({
                activeUser: true
            })
        };

        this.setState({
            companyNameGlobal,
            companyIdUser,
            userGroupId,
            userId,
            esComercializadora,
            esAgencia,
            esAdFincas

        });
    }



    fetchRepre = async () => {
        console.log('fetchRepre VALOR DE THIS.STATE -------> ', this.state)

        console.log('VALOR DE this.state.companyIdUser', this.state.companyIdUser)
    };


    onChangeFieldProducto = (event) => {
        event.persist()

        var formData = { ...this.state.formProducto };
        var target = event.target;
        var value =
            event.target.type === "checkbox"
                ? event.target.checked
                : event.target.value;
        const name = event.target.name;
        console.log(event.target.name)
        console.log(event.target.value)

        const nameFilters = ['nombreComercializadora', 'nombreOferta', 'nombreDocumentoContrato','TarifaDesc', 'companyId', 'esActiva', 'fechaAltaProducto', 'fechaBajaProducto', 'id', 'idComercializadora', 'tarifaATR', 'peaje', 'coIncluido', 'verde', 'facturacion', 'precioTEP1', 'precioTEP2', 'precioTEP3', 'precioTEP5', 'precioTEP4', 'precioTEP6', 'precioTP1', 'precioTP2', 'precioTP3',
            'precioTP4', 'precioTP5', 'precioTP6', 'precioTPPeriodo', 'precioTPTipo', 'periocidadRevisionPrecios', 'periodoValidezDesde', 'periodoValidezHasta', 'ofertaInternet', 'ofertaOficina', 'ofertaTel',
            'serviciosObligatorios', 'infoAdicional', 'caracteristicas', 'comisionContratoNuevo','comisionContratoRenovacion','comisionConsumoAgente1','comisionConsumoAgente2','comisionConsumoAdmin','comisionGOAgente1','comisionGOAgente2'];


            if (nameFilters.indexOf(event.target.name) != -1) {
                this.setState({ saveDataProducto: true });
                this.setState({
                    saveProductoButtonDisabled: false,
                    updateProductoButtonDisabled: true
                })
            }

            if (event.target.name == 'idComercializadora') {
                //this.setState ({productoDetail.nombreOferta : event.target.value});
                this.setState({ saveDataProducto: true });
                this.setState({
                    saveProductoButtonDisabled: false,
                    updateProductoButtonDisabled: true
                })
                const NombreComercializadora = event.target.selectedOptions[0].label

                console.log(formData)
                formData['nombreComercializadora'] = NombreComercializadora;
                //if (value === "" || value === null) {
                //    formData[name] = 0;
                //}
                this.setState({ formProducto: formData });
            }


            if (event.target.name == 'tarifaATR') {
                //this.setState ({productoDetail.nombreOferta : event.target.value});
                this.setState({ saveDataProducto: true });
                this.setState({
                    saveProductoButtonDisabled: false,
                    updateProductoButtonDisabled: true
                })
                const NombreTarifa = event.target.selectedOptions[0].label

                if(event.target.name == 'tarifaATR' && event.target.value == '018'){
                    this.setState({
                        inputDisabledP3456: true,
                        inputDisabledE456: true

                    })
                    } else if (event.target.name == 'tarifaATR' && event.target.value != '018'){
                        this.setState({
                            inputDisabledP3456: false,
                            inputDisabledE456: false

                        })
                    }

                console.log(formData)
                formData['TarifaDesc'] = NombreTarifa;
                //if (value === "" || value === null) {
                //    formData[name] = 0;
                //}
                this.setState({ formProducto: formData });
            }

            if (value == 'Selecciona...'){

                  value = ""
                }


                if (value === "true" || value == "false") {
                    value = JSON.parse(value);
                }

        console.log(formData)
        formData[name] = value;
        //if (value === "" || value === null) {
        //    formData[name] = 0;
        //}
        this.setState({ formProducto: formData });

    };


    showFiltersToggle = () => {
        const showFilters = !this.state.showFilters;

        this.setState({ showFilters });
    };

    getFilterIcon = () => {
        return this.state.showFilters ? "ni-bold-up" : "ni-bold-down";
    };



    clearForm = () => {
        //const filters = this.filters; ---> Eliminado porque no cambiaba campos
        const filters = {};
        this.setState({
            filters: {
                nombreProductoSearch: '',
                cifSearch: '',
                idSearch: ''
            },
            inputCifDisabled: false,
            inputNombreProductoDisabled: false,
            inputIdDisabled: false,
            inputDisabled: false,
            buscarButtonDisabled: true,
            productoSeleccionado: false,
            productoDetailTotal: [],
            formProducto: {
                TarifaDesc: "",
                atencionCliente: "",
                caracteristicas: "",
                coIncluido: false,
                companyId: 0,
                componentesRegulados: false,
                condicionesPenalizacion: "",
                condicionesRevisionPrecios: "",
                consumoMaximoElectricidad: "0",
                consumoMaximoGas: "0",
                consumoMinimoElectricidad: "0",
                consumoMinimoGas: "0",
                descuentoTE: "0",
                descuentoTP: "0",
                esActiva: false,
                facturacion: "",
                id: "",
                idComercializadora: "",
                infoAdicional: "",
                limitaciones: "",
                nombreComercializadora: "",
                nombreOferta: "",
                nombreDocumentoContrato:"",
                ofertaInternet: false,
                ofertaOficina: false,
                ofertaTel: false,
                peaje: "",
                penalizacionMaxElec: "0",
                penalizacionMaxGas: "0",
                periocidadRevisionPrecios: "",
                periodoValidez: "",
                periodoValidezDesde: "",
                periodoValidezHasta: "",
                potenciaMaximaElectricidad: "0",
                potenciaMinimaElectricidad: "0",
                precioAlquilerContadore: "0",
                precioServicios: "0",
                precioTEP1: '0',
                precioTEP2: '0',
                precioTEP3: '0',
                precioTEP4: '0',
                precioTEP5: '0',
                precioTEP6: '0',
                precioTP1: '0',
                precioTP2: '0',
                precioTP3: '0',
                precioTP4: '0',
                precioTP5: '0',
                precioTP6: '0',
                precioTPPeriodo: '0',
                precioTPTipo: '0',
                serviciosObligatorios: false,
                tarifaATR: "",
                verde: true,
                webContrato: "",
                webOferta: "",
                fechaAltaProducto: '',
                fechaBajaProducto: '',
                tipoProducto:'',
                precioTE1:'0',
                precioTE2:'0',
                precioTFijo:'0',
                factork: '0',
                comisionContratoNuevo:"0",
                comisionContratoRenovacion:"0",
                comisionConsumoAgente1:"0",
                comisionConsumoAgente2:"0",
                comisionConsumoAdmin:"0",
                comisionGOAgente1: "0",
                comisionGOAgente2:"0"
            },
            toggledClearRows: !this.state.toggledClearRows,
            saveProductoButtonDisabled: true,
            updateProductoButtonDisabled: true,
            fechaAltaProducto: '',
            fechaBajaProducto: ''
        });

        this.formRef.current.reset();

    };


    saveHandler(props) {

        if (this.state.saveDataProducto && (this.state.formProducto.nombreOferta && this.state.formProducto.nombreComercializadora)) {

            this.confirmAlertSave()

        } else {

            this.warningAlertFiltros()
        }

    };



    onUpdate = async () => {
        console.log('ESTAMOS EN ONSAVE VALORE DE THIS.STATE-------->', this.state.updateDataProducto)

    };


    onSave = async () => {
        console.log('ESTAMOS EN ONSAVE VALORE DE THIS.STATE-------->', this.state.saveDataProducto)

        //const fechaActivacion = this.state.fechaActivacion;
        const companyId = this.state.companyIdUser.toString();
        const userId = this.state.userId.toString();

        if (this.state.saveDataProducto == true) {

            console.log('ENTRA EN SAVE DATOS producto')
            console.log('DATOS DE THIS.STATE   --------> ', this.state)

            var fechaAltaProducto = this.state.formProducto.fechaAltaProducto;
            if (!fechaAltaProducto) {
                const now = new Date().toISOString();
                fechaAltaProducto = moment().format("YYYY-MM-DDTHH:mm:ss");
            }
            var fechaBajaProducto = this.state.formProducto.fechaBajaProducto;

            if (!fechaBajaProducto) {

                fechaBajaProducto = ''
            }

var productoDataUpdate = {}

if(this.state.tipoProducto == 'L'){

            let peaje = '3'
            if( this.state.formProducto.tarifaATR == '018'){
                peaje = '3'
                }  else if (this.state.formProducto.tarifaATR == '019'){
                    peaje = '4'
                } else if (this.state.formProducto.tarifaATR != '019' && this.state.formProducto.tarifaATR != '018' ){
                        peaje ='6'
                    };

            productoDataUpdate = {
                'TarifaDesc': this.state.formProducto.TarifaDesc?this.state.formProducto.TarifaDesc.toUpperCase():'',
                'atencionCliente': this.state.formProducto.atencionCliente?this.state.formProducto.atencionCliente.toUpperCase():'',
                'caracteristicas': this.state.formProducto.caracteristicas?this.state.formProducto.caracteristicas.toUpperCase():'',
                'coIncluido': this.state.formProducto.coIncluido,
                'companyId': this.state.companyIdUser,
                'componentesRegulados': this.state.formProducto.componentesRegulados,
                'condicionesPenalizacion': this.state.formProducto.condicionesPenalizacion?this.state.formProducto.condicionesPenalizacion.toUpperCase():'',
                'condicionesRevisionPrecios': this.state.formProducto.condicionesRevisionPrecios?this.state.formProducto.condicionesRevisionPrecios.toUpperCase():'',
                'consumoMaximoElectricidad' :parseFloat(this.state.formProducto.consumoMaximoElectricidad),
                'consumoMinimoElectricidad': parseFloat(this.state.formProducto.consumoMinimoElectricidad),
                'descuentoTE': parseFloat(this.state.formProducto.descuentoTE),
                'descuentoTP': parseFloat(this.state.formProducto.descuentoTP),
                'esActiva': this.state.formProducto.esActiva,
                'facturacion': this.state.formProducto.facturacion,
                'id': '',
                'idComercializadora': this.state.formProducto.idComercializadora,
                'infoAdicional': this.state.formProducto.infoAdicional?this.state.formProducto.infoAdicional.toUpperCase():'',
                'limitaciones': this.state.formProducto.limitaciones?this.state.formProducto.limitaciones.toUpperCase():'',
                'nombreComercializadora': this.state.formProducto.nombreComercializadora.toUpperCase(),
                'nombreOferta': this.state.formProducto.nombreOferta.toUpperCase(),
                'nombreDocumentoContrato': this.state.formProducto.nombreDocumentoContrato.toUpperCase(),
                'ofertaInternet': this.state.formProducto.ofertaInternet,
                'ofertaOficina': this.state.formProducto.ofertaOficina,
                'ofertaTel': this.state.formProducto.ofertaTel,
                'peaje': peaje,
                'penalizacionMaxElec': this.state.formProducto.penalizacionMaxElec,
                'penalizacionMaxGas': this.state.formProducto.penalizacionMaxGas,
                'periocidadRevisionPrecios': this.state.formProducto.periocidadRevisionPrecios,
                'periodoValidez': this.state.formProducto.periodoValidez,
                'periodoValidezDesde': this.state.formProducto.periodoValidezDesde,
                'periodoValidezHasta': this.state.formProducto.periodoValidezHasta,
                'potenciaMaximaElectricidad':  parseFloat(this.state.formProducto.potenciaMaximaElectricidad),
                'potenciaMinimaElectricidad':  parseFloat(this.state.formProducto.potenciaMinimaElectricidad),
                'precioAlquilerContadore':  parseFloat(this.state.formProducto.precioAlquilerContadore),
                'precioServicios':  parseFloat(this.state.formProducto.precioServicios),
                'precioTEP1': parseFloat(this.state.formProducto.precioTEP1),
                'precioTEP2': parseFloat(this.state.formProducto.precioTEP2),
                'precioTEP3': parseFloat(this.state.formProducto.precioTEP3),
                'precioTEP4': parseFloat(this.state.formProducto.precioTEP4),
                'precioTEP5': parseFloat(this.state.formProducto.precioTEP5),
                'precioTEP6': parseFloat(this.state.formProducto.precioTEP6),
                'precioTP1': parseFloat(this.state.formProducto.precioTP1),
                'precioTP2': parseFloat(this.state.formProducto.precioTP2),
                'precioTP3': parseFloat(this.state.formProducto.precioTP3),
                'precioTP4': parseFloat(this.state.formProducto.precioTP4),
                'precioTP5': parseFloat(this.state.formProducto.precioTP5),
                'precioTP6': parseFloat(this.state.formProducto.precioTP6),
                'precioTPPeriodo': parseFloat(this.state.formProducto.precioTPPeriodo.replace(",", ".")),
                'precioTPTipo': parseFloat(this.state.formProducto.precioTPTipo.replace(",", ".")),
                'serviciosObligatorios': this.state.formProducto.serviciosObligatorios,
                'tarifaATR': this.state.formProducto.tarifaATR,
                'verde': this.state.formProducto.verde,
                'webContrato': this.state.formProducto.webContrato,
                'webOferta': this.state.formProducto.webOferta,
                'fechaAltaProducto': fechaAltaProducto,
                'fechaBajaProducto': fechaBajaProducto,
                'tipoProducto': this.state.tipoProducto,
                'comisionContratoNuevo':parseFloat(this.state.formProducto.comisionContratoNuevo),
                'comisionContratoRenovacion':parseFloat(this.state.formProducto.comisionContratoRenovacion),
                'comisionConsumoAgente1':parseFloat(this.state.formProducto.comisionConsumoAgente1),
                'comisionConsumoAgente2':parseFloat(this.state.formProducto.comisionConsumoAgente2),
                'comisionConsumoAdmin':parseFloat(this.state.formProducto.comisionConsumoAdmin),
                'comisionGOAgente1': parseFloat(this.state.formProducto.comisionGOAgente1),
                'comisionGOAgente2':parseFloat(this.state.formProducto.comisionGOAgente2)

            };
} else {

    let peaje = '1'
    if( this.state.formProducto.tarifaATR == '018'){
        peaje = '3'
        }  else if (this.state.formProducto.tarifaATR == '019'){
            peaje = '4'
        } else if (this.state.formProducto.tarifaATR != '019' && this.state.formProducto.tarifaATR != '018' ){
                peaje ='1'
            };

    productoDataUpdate = {
        'TarifaDesc': this.state.formProducto.TarifaDesc?this.state.formProducto.TarifaDesc.toUpperCase():'',
        'atencionCliente': this.state.formProducto.atencionCliente?this.state.formProducto.atencionCliente.toUpperCase():'',
        'caracteristicas': this.state.formProducto.caracteristicas?this.state.formProducto.caracteristicas.toUpperCase():'',
        'coIncluido': this.state.formProducto.coIncluido,
        'companyId': this.state.companyIdUser,
        'componentesRegulados': this.state.formProducto.componentesRegulados,
        'condicionesPenalizacion': this.state.formProducto.condicionesPenalizacion?this.state.formProducto.condicionesPenalizacion.toUpperCase():'',
        'condicionesRevisionPrecios': this.state.formProducto.condicionesRevisionPrecios?this.state.formProducto.condicionesRevisionPrecios.toUpperCase():'',
        'consumoMaximoElectricidad' :parseFloat(this.state.formProducto.consumoMaximoElectricidad),
        'consumoMaximoGas': parseFloat(this.state.formProducto.consumoMaximoGas),
        'consumoMinimoGas': parseFloat(this.state.formProducto.consumoMinimoGas),
        'descuentoTE': parseFloat(this.state.formProducto.descuentoTE),
        'descuentoTP': parseFloat(this.state.formProducto.descuentoTP),
        'esActiva': this.state.formProducto.esActiva,
        'facturacion': this.state.formProducto.facturacion,
        'id': '',
        'idComercializadora': this.state.formProducto.idComercializadora,
        'infoAdicional': this.state.formProducto.infoAdicional?this.state.formProducto.infoAdicional.toUpperCase():'',
        'limitaciones': this.state.formProducto.limitaciones?this.state.formProducto.limitaciones.toUpperCase():'',
        'nombreComercializadora': this.state.formProducto.nombreComercializadora.toUpperCase(),
        'nombreOferta': this.state.formProducto.nombreOferta.toUpperCase(),
        'nombreDocumentoContrato': this.state.formProducto.nombreDocumentoContrato.toUpperCase(),
        'ofertaInternet': this.state.formProducto.ofertaInternet,
        'ofertaOficina': this.state.formProducto.ofertaOficina,
        'ofertaTel': this.state.formProducto.ofertaTel,
        'peaje': peaje,
        'penalizacionMaxGas': this.state.formProducto.penalizacionMaxGas,
        'periocidadRevisionPrecios': this.state.formProducto.periocidadRevisionPrecios,
        'periodoValidez': this.state.formProducto.periodoValidez,
        'periodoValidezDesde': this.state.formProducto.periodoValidezDesde,
        'periodoValidezHasta': this.state.formProducto.periodoValidezHasta,
        'precioAlquilerContadore':  parseFloat(this.state.formProducto.precioAlquilerContadore),
        'precioServicios':  parseFloat(this.state.formProducto.precioServicios),
        'precioTPPeriodo': parseFloat(this.state.formProducto.precioTPPeriodo),
        'precioTPTipo': parseFloat(this.state.formProducto.precioTPTipo),
        'serviciosObligatorios': this.state.formProducto.serviciosObligatorios,
        'tarifaATR': this.state.formProducto.tarifaATR,
        'verde': this.state.formProducto.verde,
        'webContrato': this.state.formProducto.webContrato,
        'webOferta': this.state.formProducto.webOferta,
        'fechaAltaProducto': fechaAltaProducto,
        'fechaBajaProducto': fechaBajaProducto,
        'tipoProducto': this.state.tipoProducto,
        'precioTE1':parseFloat(this.state.formProducto.precioTE1),
        'precioTE2':parseFloat(this.state.formProducto.precioTE2),
        'precioTFijo':parseFloat(this.state.formProducto.precioTFijo),
        'factork':parseFloat(this.state.formProducto.factork),
        'comisionContratoNuevo':parseFloat(this.state.formProducto.comisionContratoNuevo),
        'comisionContratoRenovacion':parseFloat(this.state.formProducto.comisionContratoRenovacion),
        'comisionConsumoAgente1':parseFloat(this.state.formProducto.comisionConsumoAgente1),
        'comisionConsumoAgente2':parseFloat(this.state.formProducto.comisionConsumoAgente2),
        'comisionConsumoAdmin':parseFloat(this.state.formProducto.comisionConsumoAdmin),
        'comisionGOAgente1': parseFloat(this.state.formProducto.comisionGOAgente1),
        'comisionGOAgente2':parseFloat(this.state.formProducto.comisionGOAgente2)
    };

}



            //const fechaActivacion = this.state.fechaActivacion;
            const companyId = this.state.companyIdUser.toString();
            const userId = this.state.userId.toString();
            const idContrato = this.state.contrato;
            var resultadoUpdate: any = [];
            var resultadoView: any = [];



            const headers = {
                'Authorization': 'Bearer my-token',
                'My-Custom-Header': 'foobar',
                'x-api-key': '$2y$10$bcT1Ukm4V/6/z5GvitZv0unu8I91mpixuwUO6z5CyEGev9DDDY28W'
            };
            let direccionFile = "https://datapi.psgestion.es/producto/create?id="+this.state.companyIdUser+"&user="+userId;


            axios.post(direccionFile, productoDataUpdate, { headers, responseType: 'blob'})
                 .then (res=> {
                            console.log('Actualización correcta', res)
                            console.log('Datos del registro: ', res.data)
                            this.successAlert();
                            this.clearForm();
                 }).catch (error =>{
                            console.log ('Failure')
                            console.log('Error en el proceso: ', error)
                            this.warningAlert();
                 });

//            await this.fetchRepre();


        }


            this.setState({
                        saveDataProducto: false,
                        saveProductoButtonDisabled: true
                    });
            this.clearForm();
    };


    handleChangeDate = (value, formattedValue, id) => {

        console.log('VALORES DE FECHA DE ALATA')
        console.log(value)
        console.log(formattedValue)
        console.log(id)

        var formData = { ...this.state.formProducto };


        const name = id;
        var valueDay = formattedValue




        if (name == 'productoDesde') {
            //console.log('VALOR NOMBRE REPRESENTANTE ------> ', value, event.target.name)
            //this.setState ({productoDetail.nombreOferta : event.target.value});
            this.setState({ productoDesde: value });

        }

        if (name == 'productoHasta') {
            //this.setState ({productoDetail.nombreOferta : event.target.value});
            this.setState({ productoHasta: value });
        }


        if (name == 'fechaAltaProducto') {
            //console.log('VALOR NOMBRE REPRESENTANTE ------> ', value, event.target.name)
            //this.setState ({productoDetail.nombreOferta : event.target.value});
            this.setState({ fechaAltaProducto: value });

        }

        if (name == 'fechaBajaProducto') {
            //this.setState ({productoDetail.nombreOferta : event.target.value});
            this.setState({ fechaBajaProducto: value });
        }

        if (name == 'periodoValidezDesde') {
            //console.log('VALOR NOMBRE REPRESENTANTE ------> ', value, event.target.name)
            //this.setState ({productoDetail.nombreOferta : event.target.value});
            this.setState({ periodoValidezDesde: value });

        }

        if (name == 'periodoValidezHasta') {
            //this.setState ({productoDetail.nombreOferta : event.target.value});
            this.setState({ periodoValidezHasta: value });
        }


        console.log(formData)
        formData[name] = valueDay;
        //if (value === "" || value === null) {
        //    formData[name] = 0;
        //}
        this.setState({ formProducto: formData });

    };

///////////////////////////////////////////////////////////////////////////////////////////////////////


    handleClearRows = () => {
        this.setState({ toggledClearRows: !this.state.toggledClearRows })
    }




    tipoLecturaSelect = (props) => {
        const tipoContrato = props.tipoContrato;

        if (tipoContrato === 'G') {
    return (

    <React.Fragment>
        <Col md="12" lg="4">
            <FormGroup>
                <Label
                    className="form-control-label"
                    htmlFor="idComercializadora"
                >
                    Comercializadora
                                                </Label>
                <SipsOption
                    placeholder="Seleccionar comercializadora"
                    id="idComercializadora"
                    name="idComercializadora"
                    value={this.state.formProducto.idComercializadora}
                    query="LIST_MARKETERS_GAS"
                    onChange={this.onChangeFieldProducto}
                />
            </FormGroup>
        </Col>

        <Col>
            <FormGroup>

                <Label className="form-control-label" htmlFor="nombreComercializadora">
                    Comercializadora
                                                    </Label>
                <Input
                    id="nombreComercializadora"
                    placeholder=""
                    name="nombreComercializadora"
                    onChange={this.onChangeFieldProducto}
                    value={this.state.formProducto.nombreComercializadora}
                    type="text"
                    required
                    disabled={this.state.inputDisabled}
                />
            </FormGroup>
        </Col>

        <Col md="1" lg="1">
            <FormGroup>
                <Label
                    className="form-control-label"
                    htmlFor="tarifaATR"
                >
                    Tarifas                                                                                    </Label>
                <SipsGasOption
                    placeholder="Seleccionar tarifa"
                    id="tarifaATR"
                    name="tarifaATR"
                    value={this.state.formProducto.tarifaATR}
                    query="LIST_RATESGAS"
                    onChange={this.onChangeFieldProducto}
                />
            </FormGroup>
        </Col>

        <Col>
            <FormGroup>

                <Label className="form-control-label" htmlFor="TarifaDesc">
                    Tarifa Desc.
                </Label>
                <Input
                    id="TarifaDesc"
                    placeholder=""
                    name="TarifaDesc"
                    onChange={this.onChangeFieldProducto}
                    value={this.state.formProducto.TarifaDesc}
                    type="text"
                    required
                    disabled={this.state.inputDisabled}
                />
            </FormGroup>
        </Col>

    </React.Fragment>

    )

    } else  {
    return(
    <React.Fragment>

        <Col md="12" lg="4">
            <FormGroup>
                <Label
                    className="form-control-label"
                    htmlFor="idComercializadora"
                >
                    Comercializadora
                                                </Label>
                <SipsOption
                    placeholder="Seleccionar comercializadora"
                    id="idComercializadora"
                    name="idComercializadora"
                    value={this.state.formProducto.idComercializadora}
                    query="LIST_MARKETERS"
                    onChange={this.onChangeFieldProducto}
                />
            </FormGroup>
        </Col>

        <Col>
            <FormGroup>

                <Label className="form-control-label" htmlFor="nombreComercializadora">
                    Comercializadora
                </Label>
                <Input
                    id="nombreComercializadora"
                    placeholder=""
                    name="nombreComercializadora"
                    onChange={this.onChangeFieldProducto}
                    value={this.state.formProducto.nombreComercializadora}
                    type="text"
                    required
                    disabled={this.state.inputDisabled}
                />
            </FormGroup>
        </Col>

        <Col md="1" lg="1">
            <FormGroup>
                <Label
                    className="form-control-label"
                    htmlFor="tarifaATR"
                >
                    Tarifas
                </Label>
                <SipsOption
                    placeholder="Seleccionar tarifa"
                    id="tarifaATR"
                    name="tarifaATR"
                    value={this.state.formProducto.tarifaATR}
                    query="LIST_RATES"
                    onChange={this.onChangeFieldProducto}
                />
            </FormGroup>
        </Col>

        <Col>
            <FormGroup>

                <Label className="form-control-label" htmlFor="TarifaDesc">
                    Tarifa Desc.
                </Label>
                <Input
                    id="TarifaDesc"
                    placeholder=""
                    name="TarifaDesc"
                    onChange={this.onChangeFieldProducto}
                    value={this.state.formProducto.TarifaDesc}
                    type="text"
                    required
                    disabled={this.state.inputDisabled}
                />
            </FormGroup>
        </Col>

    </React.Fragment>

    )

    }
};

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////

//<this.comision tipoComision={formProducto.coIncluido} />
comision = (props) => {
        const tipoComision = props.tipoComision;

        if (tipoComision === true) {
    return (

    <React.Fragment>

<Col md="2" sm="2" lg="2">
    <FormGroup>
        <Label
            className="form-control-label"
            htmlFor="comisionConsumoAgente1"
        >
            CONSUMO AGENTE 1 AÑO
        </Label>
        <Input
            className="text-primary"
            id="comisionConsumoAgente1"
            placeholder="0"
            name="comisionConsumoAgente1"
            min={0}
            onChange={this.onChangeFieldProducto}
            value={this.state.formProducto.comisionConsumoAgente1}
            type="number"
            required
        />

        <FormText color="muted">
            Introduzca el porcentaje sobre consumos Anual
    </FormText>
    </FormGroup>
</Col>

<Col md="2" sm="2" lg="2">
    <FormGroup>
        <Label
            className="form-control-label"
            htmlFor="comisionConsumoAgente2"
        >
            CONSUMO AGENTE 2 AÑO
        </Label>
        <Input
            className="text-primary"
            id="comisionConsumoAgente2"
            placeholder="0"
            name="comisionConsumoAgente2"
            min={0}
            onChange={this.onChangeFieldProducto}
            value={this.state.formProducto.comisionConsumoAgente2}
            type="number"
            required
        />

        <FormText color="muted">
            Introduzca el porcentaje sobre consumos Anual
    </FormText>
    </FormGroup>
</Col>

<Col md="2" sm="2" lg="2">
    <FormGroup>
        <Label
            className="form-control-label"
            htmlFor="comisionConsumoAdmin"
        >
            COMISION CONSUMO ADMIN
        </Label>
        <Input
            className="text-primary"
            id="comisionConsumoAdmin"
            placeholder="0"
            name="comisionConsumoAdmin"
            min={0}
            onChange={this.onChangeFieldProducto}
            value={this.state.formProducto.comisionConsumoAdmin}
            type="number"
            required
        />

        <FormText color="muted">
            Introduzca el porcentaje sobre consumos Anual
    </FormText>
    </FormGroup>
</Col>
</React.Fragment>

)

} else  {
return(
<React.Fragment>

    <Col md="2" sm="2" lg="2">
        <FormGroup>
            <Label
                className="form-control-label"
                htmlFor="comisionGOAgente1"
            >
                PORCENTAJE GO NIVEL 1
            </Label>
            <Input
                className="text-primary"
                id="comisionGOAgente1"
                placeholder="0"
                name="comisionGOAgente1"
                min={0}
                onChange={this.onChangeFieldProducto}
                value={this.state.formProducto.comisionGOAgente1}
                type="number"
                required
            />

            <FormText color="muted">
                Introduzca el porcentaje sobre consumos Anual
        </FormText>
        </FormGroup>
    </Col>

    <Col md="2" sm="2" lg="2">
        <FormGroup>
            <Label
                className="form-control-label"
                htmlFor="comisionGOAgente2"
            >
                PORCENTAJE GO NIVEL 2
            </Label>
            <Input
                className="text-primary"
                id="comisionGOAgente2"
                placeholder="0"
                name="comisionGOAgente2"
                min={0}
                onChange={this.onChangeFieldProducto}
                value={this.state.formProducto.comisionGOAgente2}
                type="number"
                required
            />

            <FormText color="muted">
                Introduzca el porcentaje sobre consumos Anual
        </FormText>
        </FormGroup>
    </Col>

    </React.Fragment>

)

}
};

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////


tipoContratoPeriodos = (props) => {
        const tipoContrato = props.tipoContrato;

        if (tipoContrato === 'G') {
    return (

    <React.Fragment>

        <Row>

            <Col md="2" lg="2">
                <FormGroup>

                    <Label className="form-control-label" htmlFor="precioTE1">
                        TERMINO VAR. ENERGIA P1
                                                                        </Label>
                    <Input
                        className="text-primary"
                        id="precioTE1"
                        placeholder=""
                        name="precioTE1"
                        onChange={this.onChangeFieldProducto}
                        value={this.state.formProducto.precioTE1}
                        type="number"
                        required
                        disabled={this.state.inputDisabled}
                    />
                </FormGroup>
            </Col>
            <Col md="2" lg="2">
                <FormGroup>

                    <Label className="form-control-label" htmlFor="precioTE2">
                        TERMINO VAR. ENERGIA P2
                                                                        </Label>
                    <Input
                        className="text-primary"
                        id="precioTE2"
                        placeholder=""
                        name="precioTE2"
                        onChange={this.onChangeFieldProducto}
                        value={this.state.formProducto.precioTE2}
                        type="number"
                        required
                        disabled={this.state.inputDisabled}
                    />
                </FormGroup>
            </Col>

            <Col md="2" lg="2">
                <FormGroup>

                    <Label className="form-control-label" htmlFor="factork">
                        Factor K
                                                                        </Label>
                    <Input
                        className="text-primary"
                        id="factork"
                        placeholder=""
                        name="factork"
                        onChange={this.onChangeFieldProducto}
                        value={this.state.formProducto.factork}
                        type="number"
                        required
                        disabled={this.state.inputDisabled}
                    />
                </FormGroup>
            </Col>

        </Row>
        <Row>
            <Col md="2" lg="2">
                <FormGroup>

                    <Label className="form-control-label" htmlFor="precioTFijo">
                        TERMINO FIJO ENERGIA
                                                                </Label>
                    <Input
                        className="text-primary"
                        id="precioTFijo"
                        placeholder=""
                        name="precioTFijo"
                        onChange={this.onChangeFieldProducto}
                        value={this.state.formProducto.precioTFijo}
                        type="number"
                        required
                        disabled={this.state.inputDisabled}
                    />
                </FormGroup>
            </Col>

        </Row>


    </React.Fragment>

    )

    } else  {
    return(
    <React.Fragment>

        <Row>

            <Col>
                <FormGroup>

                    <Label className="form-control-label" htmlFor="precioTEP1">
                        TERMINO ENERGIA P1
                                                                        </Label>
                    <Input
                        className="text-primary"
                        id="precioTEP1"
                        placeholder=""
                        name="precioTEP1"
                        onChange={this.onChangeFieldProducto}
                        value={this.state.formProducto.precioTEP1}
                        type="number"
                        required
                        disabled={this.state.inputDisabled}
                    />
                </FormGroup>
            </Col>
            <Col>
                <FormGroup>

                    <Label className="form-control-label" htmlFor="precioTEP2">
                        TERMINO ENERGIA P2
                                                                        </Label>
                    <Input
                        className="text-primary"
                        id="precioTEP2"
                        placeholder=""
                        name="precioTEP2"
                        onChange={this.onChangeFieldProducto}
                        value={this.state.formProducto.precioTEP2}
                        type="number"
                        required
                        disabled={this.state.inputDisabled}
                    />
                </FormGroup>
            </Col>


            <Col>
                <FormGroup>
                    <Label className="form-control-label" htmlFor="precioTEP3">
                        TERMINO ENERGIA P3
                                                                        </Label>
                    <Input
                        className="text-primary"
                        id="precioTEP3"
                        placeholder=""
                        name="precioTEP3"
                        onChange={this.onChangeFieldProducto}
                        value={this.state.formProducto.precioTEP3}
                        type="number"
                        required
                        disabled={this.state.inputDisabled}
                    />
                </FormGroup>
            </Col>

            <Col>
                <FormGroup>
                    <Label className="form-control-label" htmlFor="precioTEP4">
                        TERMINO ENERGIA P4
                                                                        </Label>
                    <Input
                        className="text-primary"
                        id="precioTEP4"
                        placeholder=""
                        name="precioTEP4"
                        onChange={this.onChangeFieldProducto}
                        value={this.state.formProducto.precioTEP4}
                        type="number"
                        required
                        disabled={this.state.inputDisabledE456}
                    />
                </FormGroup>
            </Col>

            <Col>
                <FormGroup>
                    <Label className="form-control-label" htmlFor="precioTEP5">
                        TERMINO ENERGIA P5
                                                                    </Label>
                    <Input
                        className="text-primary"
                        id="precioTEP5"
                        placeholder=""
                        name="precioTEP5"
                        onChange={this.onChangeFieldProducto}
                        value={this.state.formProducto.precioTEP5}
                        type="number"
                        required
                        disabled={this.state.inputDisabledE456}
                    />
                </FormGroup>
            </Col>

            <Col>
                <FormGroup>
                    <Label className="form-control-label" htmlFor="precioTEP6">
                        TERMINO ENERGIA P6
                                                                    </Label>
                    <Input
                        className="text-primary"
                        id="precioTEP6"
                        placeholder=""
                        name="precioTEP6"
                        onChange={this.onChangeFieldProducto}
                        value={this.state.formProducto.precioTEP6}
                        type="number"
                        required
                        disabled={this.state.inputDisabledE456}
                    />
                </FormGroup>
            </Col>

        </Row>
        <Row>
            <Col>
                <FormGroup>

                    <Label className="form-control-label" htmlFor="precioTP1">
                        TERMINO POTENCIA P1
                                                                </Label>
                    <Input
                        className="text-primary"
                        id="precioTP1"
                        placeholder=""
                        name="precioTP1"
                        onChange={this.onChangeFieldProducto}
                        value={this.state.formProducto.precioTP1}
                        type="number"
                        required
                        disabled={this.state.inputDisabled}
                    />
                </FormGroup>
            </Col>
            <Col>
                <FormGroup>

                    <Label className="form-control-label" htmlFor="precioTP2">
                        TERMINO POTENCIA P2
                                                                </Label>
                    <Input
                        className="text-primary"
                        id="precioTP2"
                        placeholder=""
                        name="precioTP2"
                        onChange={this.onChangeFieldProducto}
                        value={this.state.formProducto.precioTP2}
                        type="number"
                        required
                        disabled={this.state.inputDisabled}
                    />
                </FormGroup>
            </Col>


            <Col>
                <FormGroup>
                    <Label className="form-control-label" htmlFor="precioTP3">
                        TERMINO POTENCIA P3
                                                                </Label>
                    <Input
                        className="text-primary"
                        id="precioTP3"
                        placeholder=""
                        name="precioTP3"
                        onChange={this.onChangeFieldProducto}
                        value={this.state.formProducto.precioTP3}
                        type="number"
                        required
                        disabled={this.state.inputDisabledP3456}
                    />
                </FormGroup>
            </Col>

            <Col>
                <FormGroup>
                    <Label className="form-control-label" htmlFor="precioTP4">
                        TERMINO POTENCIA P4
                                                                </Label>
                    <Input
                        className="text-primary"
                        id="precioTP4"
                        placeholder=""
                        name="precioTP4"
                        onChange={this.onChangeFieldProducto}
                        value={this.state.formProducto.precioTP4}
                        type="number"
                        required
                        disabled={this.state.inputDisabledP3456}
                    />
                </FormGroup>
            </Col>

            <Col>
                <FormGroup>
                    <Label className="form-control-label" htmlFor="precioTP5">
                        TERMINO POTENCIA P5
                                                            </Label>
                    <Input
                        className="text-primary"
                        id="precioTP5"
                        placeholder=""
                        name="precioTP5"
                        onChange={this.onChangeFieldProducto}
                        value={this.state.formProducto.precioTP5}
                        type="number"
                        required
                        disabled={this.state.inputDisabledP3456}
                    />
                </FormGroup>
            </Col>

            <Col>
                <FormGroup>
                    <Label className="form-control-label" htmlFor="precioTP6">
                        TERMINO POTENCIA P6
                                                            </Label>
                    <Input
                        className="text-primary"
                        id="precioTP6"
                        placeholder=""
                        name="precioTP6"
                        onChange={this.onChangeFieldProducto}
                        value={this.state.formProducto.precioTP6}
                        type="number"
                        required
                        disabled={this.state.inputDisabledP3456}
                    />
                </FormGroup>
            </Col>

        </Row>


    </React.Fragment>

    )

    }
}





    render() {

        const formProducto: any = this.state.formProducto;
        console.log('VALOR DE THIS:STATE EN RENDER SIPDETAILSCONTRATOS ------> ', this.state)
        const fechaAltaProducto = this.state.fechaAltaProducto
        const fechaBajaProducto = this.state.fechaBajaProducto

        const productoDesde = this.state.productoDesde
        const productoHasta = this.state.productoHasta

        const periodoValidezHasta = this.state.periodoValidezHasta
        const periodoValidezDesde = this.state.periodoValidezDesde



        var textoTipoProducto = '';
        if (this.state.tipoProducto == 'G') {
            textoTipoProducto = 'Gas';
        } else {
            textoTipoProducto = 'Electricidad';
        }

        return (
            <>
                <Header title={'Nuevo Producto ' + textoTipoProducto} />
                <Container className=" mt--7" fluid>

                    <Card className="mb-4">
                        <CardHeader>
                            <Button
                                onClick={this.showFiltersToggle}
                                size="sm"
                                className="float-right"
                            >
                                <i className={"ni " + this.getFilterIcon()} />
                            </Button>
                            <h3 className="mb-0">Productos</h3>
                        </CardHeader>
                        <Collapse isOpen={this.state.showFilters}>
                            <CardBody>
                                <Form innerRef={this.formRef}>
                                    <Row>

                                        <Col className="text-right">
                                            <Button onClick={this.clearForm}>
                                                <i className={"ni ni-fat-remove"} /> Limpiar
                                    </Button>

                                        </Col>
                                    </Row>



                                </Form>
                            </CardBody>
                        </Collapse>
                    </Card>




                    <Row>
                        <div className="col">
                            <Card className="shadow">
                                <CardBody>
                                    <Row>
                                        <Col>
                                            <button
                                                className="btn btn-sm btn-primary"
                                                onClick={this.props.history.goBack}
                                            >
                                                Atrás
                                            </button>
                                        </Col>
                                        <Col className="col-auto d-flex">
                                            <Card className="p-3 bg-light">
                                        <FormGroup>

                                                <Button disabled={this.state.saveProductoButtonDisabled} id="guardarRepreProptooltip" color="danger" size="m" type="button" className="btn-icon btn-2"
                                                    onClick={() => { this.saveHandler(this.state.saveDataProducto) }}
                                                >
                                                    <span className="btn-inner--icon">
                                                        <i className="fas fa-save" style={{ fontSize: 30 }}></i>
                                                    </span>
                                                    <span className="btn-inner--text">Guardar</span>
                                                    <UncontrolledTooltip delay={0} placement="top" target="guardarRepreProptooltip">
                                                        CREAR PRODUCTO NUEVO
                                                    </UncontrolledTooltip>
                                                </Button>

                                        </FormGroup>
                                    </Card>
                                    </Col>

                                    </Row>


                                    <div>
                                        <CardTitle>PRODUCTO</CardTitle>
                                    </div>

                                    <Row>
                                        <Col>
                                            <FormGroup>

                                                <Label className="form-control-label" htmlFor="nombreOferta">
                                                    Nombre Producto
                                                                                    </Label>
                                                <Input
                                                    className="text-primary"
                                                    id="nombreOferta"
                                                    placeholder=""
                                                    name="nombreOferta"
                                                    maxLength={80}
                                                    onChange={this.onChangeFieldProducto}
                                                    value={formProducto.nombreOferta}
                                                    type="text"
                                                    required
                                                    disabled={this.state.inputDisabled}
                                                />
                                            </FormGroup>
                                        </Col>


<this.tipoLecturaSelect tipoContrato={this.state.tipoProducto} />


                                        <Col md="2" lg="2">
                                            <FormGroup>
                                                <Label className="form-control-label" for="tipoesActiva">ACTIVA?</Label>
                                                <Input type="select" name="esActiva" id="esActiva"
                                                    onChange={this.onChangeFieldProducto}
                                                    value={formProducto.esActiva}>
                                                    <option selected>Selecciona...</option>
                                                    <option value="true">ACTIVA</option>
                                                    <option value="false">BAJA</option>
                                                </Input>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>

                                        <Col md="2" lg="2">
                                            <FormGroup>
                                                <Label className="form-control-label" for="tipocoIncluido">CO INCLUIDO</Label>
                                                <Input type="select" name="coIncluido" id="coIncluido"
                                                    onChange={this.onChangeFieldProducto}
                                                    value={formProducto.coIncluido}>
                                                    <option selected>Selecciona...</option>
                                                    <option value="true">SI</option>
                                                    <option value="false">NO</option>
                                                </Input>
                                            </FormGroup>
                                        </Col>

                                        <Col md="2" lg="2">
                                            <FormGroup>
                                                <Label className="form-control-label" for="tipoverde">VERDE</Label>
                                                <Input type="select" name="verde" id="verde"
                                                    onChange={this.onChangeFieldProducto}
                                                    value={formProducto.verde}>
                                                    <option selected>Selecciona...</option>
                                                    <option value="true">SI</option>
                                                    <option value="false">NO</option>
                                                </Input>
                                            </FormGroup>
                                        </Col>

                                        <Col md="2">
                                            <FormGroup>

                                                <Label className="form-control-label" htmlFor="fechaAltaProducto">
                                                    Fecha Alta Registro
                                                                                    </Label>

                                                <DatePicker id="fechaAltaProducto"
                                                    value={fechaAltaProducto}
                                                    onChange={(v, f) => this.handleChangeDate(v, f, "fechaAltaProducto")}
                                                    instanceCount={1}
                                                    dateFormat={"DD-MM-YYYY"}
                                                    dayLabels={spanishDayLabels}
                                                    monthLabels={spanishMonthLabels}
                                                    disabled={this.state.inputDisabled}

                                                />

                                            </FormGroup>

                                        </Col>


                                        <Col md="2">
                                            <FormGroup>

                                                <Label className="form-control-label" htmlFor="fechaBajaProducto">
                                                    Fecha Baja Registro
                                                                                    </Label>

                                                <DatePicker id="fechaBajaProducto"
                                                    value={fechaBajaProducto}
                                                    onChange={(v, f) => this.handleChangeDate(v, f, "fechaBajaProducto")}
                                                    instanceCount={2}
                                                    dateFormat={"DD-MM-YYYY"}
                                                    dayLabels={spanishDayLabels}
                                                    monthLabels={spanishMonthLabels}
                                                    disabled={this.state.inputDisabled}

                                                />


                                            </FormGroup>
                                        </Col>

                                    </Row>

                                </CardBody>
                            </Card>

                            <Card className="shadow">
                                <CardBody>
                                    <div>
                                        <CardTitle>TARIFAS</CardTitle>
                                    </div>
                                    <Row>
                                        <Col md="2" lg="2">
                                            <FormGroup>
                                                <Label className="form-control-label" for="precioTPTipo">Periodo Precio</Label>
                                                <Input type="select" name="precioTPTipo" id="precioTPTipo"
                                                    onChange={this.onChangeFieldProducto}
                                                    value={formProducto.precioTPTipo}>
                                                    <option selected>Selecciona...</option>
                                                    <option value="DIARIO">DIARIO</option>
                                                    <option value="ANUAL">ANUAL</option>
                                                </Input>
                                            </FormGroup>
                                        </Col>

                                        <Col md="2" lg="2">
                                            <FormGroup>
                                                <Label className="form-control-label" for="periocidadRevisionPrecios">Duración Contrato</Label>
                                                <Input type="select" name="periocidadRevisionPrecios" id="periocidadRevisionPrecios"
                                                    onChange={this.onChangeFieldProducto}
                                                    value={formProducto.periocidadRevisionPrecios}>
                                                    <option selected>Selecciona...</option>
                                                    <option value="ANUAL">12 MESES</option>
                                                    <option value="BIANUAL">24 MESES</option>
                                                    <option value="TRIANUAL">36 MESES</option>
                                                </Input>
                                            </FormGroup>
                                        </Col>

                                        <Col md="2">
                                            <FormGroup>

                                                <Label className="form-control-label" htmlFor="periodoValidezDesde">
                                                    Oferta Válida Desde
                                                </Label>

                                                <DatePicker id="periodoValidezDesde"
                                                    value={periodoValidezDesde}
                                                    onChange={(v, f) => this.handleChangeDate(v, f, "periodoValidezDesde")}
                                                    instanceCount={1}
                                                    dateFormat={"DD-MM-YYYY"}
                                                    dayLabels={spanishDayLabels}
                                                    monthLabels={spanishMonthLabels}
                                                    disabled={this.state.inputDisabled}

                                                />

                                            </FormGroup>

                                        </Col>


                                        <Col md="2">
                                            <FormGroup>

                                                <Label className="form-control-label" htmlFor="periodoValidezHasta">
                                                    Oferta Válida Hasta
                                                </Label>

                                                <DatePicker id="periodoValidezHasta"
                                                    value={periodoValidezHasta}
                                                    onChange={(v, f) => this.handleChangeDate(v, f, "periodoValidezHasta")}
                                                    instanceCount={2}
                                                    dateFormat={"DD-MM-YYYY"}
                                                    dayLabels={spanishDayLabels}
                                                    monthLabels={spanishMonthLabels}
                                                    disabled={this.state.inputDisabled}

                                                />


                                            </FormGroup>
                                        </Col>

                                    </Row>

                                <this.tipoContratoPeriodos tipoContrato={this.state.tipoProducto} />


                                </CardBody>
                            </Card>

                            <Card className="shadow">

                                <CardBody>
                                    <div>
                                        <CardTitle>COMISIONES</CardTitle>
                                    </div>
                                <Row>

                                    <Col md="2" sm="2" lg="2">
                                        <FormGroup>
                                            <Label
                                                className="form-control-label"
                                                htmlFor="comisionContratoNuevo"
                                            >
                                                COMISION CONTRATO NUEVO
                                            </Label>
                                            <Input
                                                className="text-primary"
                                                id="comisionContratoNuevo"
                                                placeholder=""
                                                name="comisionContratoNuevo"
                                                min={0}
                                                onChange={this.onChangeFieldProducto}
                                                value={formProducto.comisionContratoNuevo}
                                                type="text"
                                                required
                                            />

                                            <FormText color="muted">
                                                Introduzca la cantidad en euros
                                        </FormText>
                                        </FormGroup>
                                    </Col>

                                    <Col md="2" sm="2" lg="2">
                                        <FormGroup>
                                            <Label
                                                className="form-control-label"
                                                htmlFor="comisionContratoRenovacion"
                                            >
                                                COMISION CONTRATO RENOVACION
                                            </Label>
                                            <Input
                                                className="text-primary"
                                                id="comisionContratoRenovacion"
                                                placeholder="0"
                                                name="comisionContratoRenovacion"
                                                min={0}
                                                onChange={this.onChangeFieldProducto}
                                                value={formProducto.comisionContratoRenovacion}
                                                type="number"
                                                required
                                            />

                                            <FormText color="muted">
                                                Introduzca la cantidad en euros
                                        </FormText>
                                        </FormGroup>
                                    </Col>


<this.comision tipoComision={formProducto.coIncluido} />

                                </Row>

                                </CardBody>
                                <CardBody>
                                    <div>
                                        <CardTitle>OTROS DATOS</CardTitle>
                                    </div>

                                    <Row>
                                        <Col md="2" lg="2">
                                            <FormGroup>
                                                <Label className="form-control-label" for="ofertaInternet">OFERTA INTERNET</Label>
                                                <Input type="select" name="ofertaInternet" id="ofertaInternet"
                                                    onChange={this.onChangeFieldProducto}
                                                    value={formProducto.ofertaInternet}>
                                                    <option selected>Selecciona...</option>
                                                    <option value="true">SI</option>
                                                    <option value="false">NO</option>
                                                </Input>
                                            </FormGroup>
                                        </Col>
                                        <Col md="2" lg="2">
                                            <FormGroup>
                                                <Label className="form-control-label" for="ofertaOficina">OFERTA OFICINA</Label>
                                                <Input type="select" name="ofertaOficina" id="ofertaOficina"
                                                    onChange={this.onChangeFieldProducto}
                                                    value={formProducto.ofertaOficina}>
                                                    <option selected>Selecciona...</option>
                                                    <option value="true">SI</option>
                                                    <option value="false">NO</option>
                                                </Input>
                                            </FormGroup>
                                        </Col>
                                        <Col md="2" lg="2">
                                            <FormGroup>
                                                <Label className="form-control-label" for="ofertaTel">OFERTA TELEFONICA</Label>
                                                <Input type="select" name="ofertaTel" id="ofertaTel"
                                                    onChange={this.onChangeFieldProducto}
                                                    value={formProducto.ofertaTel}>
                                                    <option selected>Selecciona...</option>
                                                    <option value="true">SI</option>
                                                    <option value="false">NO</option>
                                                </Input>
                                            </FormGroup>
                                        </Col>
                                        <Col md="2" lg="2">
                                            <FormGroup>
                                                <Label className="form-control-label" for="serviciosObligatorios">SERVICIOS OBLIGATORIOS</Label>
                                                <Input type="select" name="serviciosObligatorios" id="serviciosObligatorios"
                                                    onChange={this.onChangeFieldProducto}
                                                    value={formProducto.serviciosObligatorios}>
                                                    <option selected>Selecciona...</option>
                                                    <option value="true">SI</option>
                                                    <option value="false">NO</option>
                                                </Input>
                                            </FormGroup>
                                        </Col>
                                        <Col md="3" sm="3" lg="3">
                                            <FormGroup>
                                                <Label
                                                    className="form-control-label"
                                                    htmlFor="nombreDocumentoContrato"
                                                >
                                                    NOMBRE DOCUMENTO CONTRATO
                                                </Label>
                                                <Input
                                                    className="text-primary"
                                                    id="nombreDocumentoContrato"
                                                    placeholder=""
                                                    name="nombreDocumentoContrato"
                                                    maxLength={80}
                                                    onChange={this.onChangeFieldProducto}
                                                    value={formProducto.nombreDocumentoContrato}
                                                    type="text"
                                                    required
                                                />

                                                <FormText color="muted">
                                                    Introduzca el nombre del documento formato PDF necesario para generar el contrato
                                            </FormText>
                                            </FormGroup>
                                        </Col>

                                    </Row>
                                    <Row>

                                        <Col md="6" sm="12" lg="6">
                                            <FormGroup>
                                                <Label
                                                    className="form-control-label"
                                                    htmlFor="infoAdicional"
                                                >
                                                    INFORMACION ADICIONAL
                                                </Label>
                                                <Input
                                                    id="infoAdicional"
                                                    name="infoAdicional"
                                                    type="textarea"
                                                    style={{
                                                        resize: "none"
                                                    }}
                                                    value={formProducto.infoAdicional}
                                                    onChange={this.onChangeFieldProducto}
                                                ></Input>
                                                <FormText color="muted">
                                                    Introduzca la información que desee que se refleje como información para el agente o consumidor
                                            </FormText>
                                            </FormGroup>
                                        </Col>

                                        <Col md="6" sm="12" lg="6">
                                            <FormGroup>
                                                <Label
                                                    className="form-control-label"
                                                    htmlFor="caracteristicas"
                                                >
                                                    CARACTERISTICAS
                                                </Label>
                                                <Input
                                                    id="caracteristicas"
                                                    name="caracteristicas"
                                                    type="textarea"
                                                    style={{
                                                        resize: "none"
                                                    }}
                                                    value={formProducto.caracteristicas}
                                                    onChange={this.onChangeFieldProducto}
                                                ></Input>
                                                <FormText color="muted">
                                                    Introduzca las características que desee que se refleje como información para el agente o consumidor
                                            </FormText>
                                            </FormGroup>
                                        </Col>

                                    </Row>

                                </CardBody>
                            </Card>

    {this.state.alert}

                        </div>
                    </Row>
                </Container>

            </>
        );
    }
}


export default withApollo(crearProducto);
