import gql from 'graphql-tag';

export const LIST_DEALERS = gql`
query listDealers{
    listDealers{
        Id,
        Empresa
    }
}
`
export const LIST_RATES = gql`
query listRates{
    listRates{
      Id,
      Descripcion
    }
}
`
export const LIST_AUTONOMOUS_COMMUNITIES = gql`
query listAutonomousCommunities{
    listAutonomousCommunities{
      autonomia_id,
      nombre
    }
  }
`

export const LIST_MARKETERS = gql`
query listMarketers{
  listMarketers{
      Id,
      Empresa
    }
  }
`

export const LIST_MARKETERS_GAS = gql`
query listMarketersGas{
  listMarketersGas{
      Id,
      Empresa
    }
  }
`
export const LIST_PROVINCES = gql`
query listProvinces{
    listProvinces{
      provinciaId,
      nombre
    }
  }
`
export const LIST_CNAE = gql`
query listCNAE{
    listCNAE{
     cnaeId,
      actividadCNAE
    }
  }
`

export const LIST_LOCALITIES = gql`
query listLocalities{
    listLocalities{
      Cmunicipio,
      Nombre
    }
  }
`

export const LIST_LOCALITIESNAME = gql`
query listLocalities{
    listLocalities{
      Cmunicipio,
      Nombre
    }
  }
`

export const LIST_USERS = gql`
query listUsers($companyId: String!) {
    listUsers(companyId: $companyId) {
      id,
      companyId,
      email,
      first_name,
      last_name,
      user_group_id
    }
  }
`

export const LIST_USER = gql`
query listUser($userId: String!, $companyId: String!) {
    listUser(userId: $userId, companyId: $companyId) {
      id,
      companyId,
      email,
      first_name,
      last_name,
      user_group_id
    }
  }
`
export const LIST_ESTADOS_PROPUESTAS= gql`
query estadosPropuestas{
    estadosPropuestas{
      Id,
      Descripcion
    }
}
`
export const LIST_ESTADOS_CONTRATOS = gql`
query estadosContratos{
    estadosContratos{
      Id,
      Descripcion
    }
}
`
