
export const priceFields = {
    fechaPrecio: "Fecha",
    precioP1: "Precio P1",
    precioP2: "Precio P2",
    precioP3: "Precio P3",
    precioP4: "Precio P4",
    precioP5: "Precio P5",
    precioP6: "Precio P6"
};

export const consumptionsFields = {
    fechaFinMesConsumo: "Fecha",
    consumoEnergiaActivaEnWhP1: "Energia Activa kWhP1",
    consumoEnergiaActivaEnWhP2: "Energia Activa kWhP2",
    consumoEnergiaActivaEnWhP3: "Energia Activa kWhP3",
    consumoEnergiaActivaEnWhP4: "Energia Activa kWhP4",
    consumoEnergiaActivaEnWhP5: "Energia Activa kWhP5",
    consumoEnergiaActivaEnWhP6: "Energia Activa kWhP6"
};


export const consumptionsExpandableFields = {};

export const sipsConsumosExpandableFields = {};

export const sipsConsumosFields = {
    fechaInicioMesConsumo: 'Fecha Inicio',
    fechaFinMesConsumo: "Fecha Fin",
    codigoTarifaATR: "Codigo ATR",
    consumoEnergiaActivaEnWhP1: "Energia Activa kWhP1",
    consumoEnergiaActivaEnWhP2: "Energia Activa kWhP2",
    consumoEnergiaActivaEnWhP3: "Energia Activa kWhP3",
    consumoEnergiaActivaEnWhP4: "Energia Activa kWhP4",
    consumoEnergiaActivaEnWhP5: "Energia Activa kWhP5",
    consumoEnergiaActivaEnWhP6: "Energia Activa kWhP6",
    consumoEnergiaReactivaEnVArhP1: "Energia Reactiva kVArhP1",
    consumoEnergiaReactivaEnVArhP2: "Energia Reactiva kVArhP2",
    consumoEnergiaReactivaEnVArhP3: "Energia Reactiva kVArhP3",
    consumoEnergiaReactivaEnVArhP4: "Energia Reactiva kVArhP4",
    consumoEnergiaReactivaEnVArhP5: "Energia Reactiva kVArhP5",
    consumoEnergiaReactivaEnVArhP6: "Energia Reactiva kVArhP6",
    potenciaDemandadaEnWP1: "Potencia demandada kWP1",
    potenciaDemandadaEnWP2: "Potencia demandada kWP2",
    potenciaDemandadaEnWP3: "Potencia demandada kWP3",
    potenciaDemandadaEnWP4: "Potencia demandada kWP4",
    potenciaDemandadaEnWP5: "Potencia demandada kWP5",
    potenciaDemandadaEnWP6: "Potencia demandada kWP6",
    codigoDHEquipoDeMedida: "Codigo Equipo",
    codigoTipoLectura: "Codigo tipo lectura",
  };
