import gql from 'graphql-tag';

export const LIST_DEALERSGAS = gql`
query listDealersGas{
    listDealersGas{
        Id,
        Empresa
    }
}
`
export const LIST_RATESGAS = gql`
query listRatesGas{
    listRatesGas{
      Id,
      Descripcion
    }
}
`
export const LIST_AUTONOMOUS_COMMUNITIES = gql`
query listAutonomousCommunities{
    listAutonomousCommunities{
      autonomia_id,
      nombre
    }
  }
`

export const LIST_PROVINCESGAS = gql`
query listProvincesGas{
    listProvincesGas{
      provinciaId,
      nombre
    }
  }
`
export const LIST_LOCALITIESGAS = gql`
query listLocalitiesGas{
    listLocalitiesGas{
      Cmunicipio,
      Nombre
    }
  }
`
export const LIST_USERS = gql`
query listUsers($companyId: String!) {
    listUsers(companyId: $companyId) {
      id,
      companyId,
      email,
      first_name,
      last_name,
      user_group_id
    }
  }
`

export const LIST_USER = gql`
query listUser($userId: String!, $companyId: String!) {
    listUser(userId: $userId, companyId: $companyId) {
      id,
      companyId,
      email,
      first_name,
      last_name,
      user_group_id
    }
  }
`
