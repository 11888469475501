import React from "react";
import { withApollo } from "react-apollo";
import { Container } from "reactstrap";
import LoadingOverlay from "react-loading-overlay";

import Header from "../../components/Headers/Header";
import { MASSIVE_EXPORT_SIPSGAS } from "../../queries/sips/sipsGas.graphql";
import Environment from "../../constants/environment";
import SipsGasMassiveExportsFilter from "../../components/SipsGas/SipsGasMassiveExportsFilter";

import Reflux from "reflux";
import { UserStore } from "../../store/UserStore";

class SipsGasMassiveExports extends Reflux.Component<any, any> {
  lastFilters = {
    cups: "",
  };
  state = {
    UserStore:{},
    isLoading: false,
    isSearching: false,
    copiedText: null,
    currentPage: 0,
    pageSize: 0,
    pageCount: 0,
    count: 0,
    page: 1,
    limit: 10,
    gas:false,
    paginatedData: {
      items: [],
    },
  };

  constructor(props) {
    super(props);
    this.store = UserStore;
  }

  componentWillReceiveProps (){
    //console.log(this.props)
  console.log(this.props)
  console.log(this.state)
  //console.log('VALOR DE STATE.USERSTORE ANTES  EN PROPS -------------->', this.state.UserStore)

  //  const userFromStorage = localStorage.getItem("user");

  //  const parsed = JSON.parse(userFromStorage||'{}');

  //  const showIndexButtonCol = parsed.indexada;
  //  const showFreeMarketButtonCol = parsed.mercadolibre;
  //
  //  const companyNameGlobal = parsed.company;
  //  const companyIdUser = parsed.companyId;

  //  const userGroupId = parsed.user_group_id
  //  const userId = parsed.id
  const usuario:any = JSON.parse(JSON.stringify(this.state.UserStore['data']))

  console.log('VALOR DE USUARIO ----------------->', usuario)


  //const showIndexButtonCol = usuario.indexada;
  //const showFreeMarketButtonCol = usuario.mercadolibre;
  const companyNameGlobal = usuario.company;
  const companyIdUser = usuario.companyId;

  const userGroupId = usuario.user_group_id
  const userId = usuario.id
  const gas = usuario.gas



  //  console.log('VALORES DE indexbutton  ',showIndexButtonCol )
  //const showFreeMarketButtonCol = this.state.UserStore.data.mercadolibre;

  //const companyNameGlobal = this.state.UserStore.data.company;
  //const companyIdUser = this.state.UserStore.data.companyId;

  //const userGroupId = this.state.UserStore.data.user_group_id
  //const userId = this.state.UserStore.data.id

  //  console.log('VALORES DE ',usuario)

    if (userGroupId === 1000){
      this.setState({
        activeUser: true
      })};

    this.setState({
      companyNameGlobal,
      companyIdUser,
      userGroupId,
      userId,
      gas

    });
  }


  onSubmit = (filters) => {
    this.lastFilters = filters;

    const cups = filters.cups.split(",");
    let f = filters;
    f.cups = cups;
    this.export(f);
  };

  export = async (filters) => {
    let isLoading = true;
    this.setState({ isLoading });
    const sips = await this.props.client.query({
      query: MASSIVE_EXPORT_SIPSGAS,
      variables: filters,
    });
    const file = sips.data.sipsGasMassiveExportCSV;
    let tempLink = document.createElement("a");
    tempLink.href = Environment.apiUrl.replace("/graphql", "") + file;

    tempLink.click();
    isLoading = false;
    this.setState({ isLoading });
  };

  render() {
    if(this.state.gas) {
    return (
      <>
        <Header title="Exportación masiva de suministros de Gas" />
        <Container className=" mt--7" fluid>
          <LoadingOverlay
            active={this.state.isLoading}
            spinner
            text="Generando archivo..."
          >
            <SipsGasMassiveExportsFilter onSubmit={this.onSubmit} />
          </LoadingOverlay>
        </Container>
      </>
    );
  } else {
    return (
      <>
        <Header title={"No tienes permisos para acceder a este apartado. Consulte con nuestro equipo comercial"}/>
      </>
    );
  }
  }
}

export default withApollo(SipsGasMassiveExports);
