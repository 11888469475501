import React from "react";
import Reflux from "reflux";
import axios from 'axios';
import { customAlphabet } from "nanoid";
import {
    Button,
    Card,
    CardBody,
    Label,
    CardTitle,
    Container,
    FormGroup,
    Input,
    Col,
    UncontrolledTooltip,
    Row,
} from "reactstrap";
import { withApollo } from "react-apollo";
import LoadingOverlay from "react-loading-overlay";
import moment from "moment";
import 'moment/locale/es'
//import { uuid } from "uuidv4";
//import { uuid } from 'uuidv4';
import { validateSpanishId, spainIdType } from 'spain-id'
import uuid from "react-uuid";


// core components
import SipsTable from "../../../components/Sips/SipsTableNew";
import SupplyInformation from "../../../components/Cards/SupplyInformation";
import RateInformation from "../../../components/Cards/RateInformation";
import Header from "../../../components/Headers/Header";
import { VIEW_SIPS } from "../../../queries/sips/sips.graphql";
import { consumptionsFields, sipsConsumosFields } from "./SipComparativesData";
import { LIST_SIPS_UBICACION } from "../../../queries/ubicacion/sip-ubicacion";
import { LIST_SIPS_CONSUMOS } from "../../../queries/sips-consumo/sip-consumo";
import SipComparativeFreeModal from "./SipComparativeFreeModal";
import SipComparativeFreeMultiModal from "./SipComparativeFreeMultiModal";
import ComercialProducto from "./ComercialProducto";
import ComercialMultiProducto from "./ComercialMultiProducto"
import SaveModal from "./SaveModal";
import ReactBSAlert from "react-bootstrap-sweetalert";
import GetUserData from "../../../components/User/GetUserData";
import { UserStore } from "../../../store/UserStore";

const alphabet = '0123456789ABCD';
const nanoid = customAlphabet(alphabet, 11);

interface IComparativeFreeState {
    cups: string;
    UserStore: any;
    user: any;
    sip: any;
    nombrePropuesta: string;
    ubicacion: any,
    consumptionsFields: any;
    sipsConsumos: any[];
    periodos: number[];
    producto: {
        active: boolean;
        productoSeleccionado: any;
        costeOperativo: number;
        costeOperativoPotencia: number;
        esNuevoContrato: boolean;
    };
    productoMulti: {
        active: boolean;
        productoSeleccionado: any[];
        costeOperativo: number;
        costeOperativoPotencia: number;
        esNuevoContrato: boolean;
    };
    tableHeaders: any[];
    comparatives: any[];
    isDisabled: boolean;
    isLoading: boolean;
    isLoadingConsumos: boolean;
    itemsSelected: any[];
    openModalComparative: boolean;
    openModalMultiComparative: boolean;
    comercialProductoSelected: any;
    showList: boolean;
    dataComparativeTarget: any;
    saveModalOpened: boolean;
    datosContrato: {
        Usuario: string,
        fechaPropuesta: string,
        FechaAprobacion: string,
        IdOferta: string,
        T_Titular: {
            RazonSocial: string, //":"MANUEL GONZALEZ",--> nombre_completo_titular
            NIF: string, //"C19345456", --> CIF
            DireccionFiscal: string, // "C/ FELIPE SOLANO ANTELO 8A 8º3", ---> direccion_titular
            CodigoPostalTitular: string, //"08630",----> codigo_postal_titular
            TelefonoFijo: string, //"949201893", ----> telefono_fijo
            TelefonMovil: string, //"689105332", ----> telefono
            Email: string, //"demo@demo.com", --->email_contacto
            TipoCliente: string, //"COMUNIDAD DE PROPIETARIOS", ---> tipo_cliente
            ObservacionesCliente: string,
            GDPR: boolean, //"OPCIONAL O NULL",----> observaciones
        },
        T_Representante: {
            nombreRepresentante: string,
            apellido1Representante: string,
            apellido2Representante: string,
            NIFRepresentante: string,
            idRepresentante: string,
            idRepresentanteInterno: number,
            CargoRepresentante: string,
            telefonoRepresentante: string,
            correoRepresentante: string,

        },
        T_PuntoSuministro: {
            Direccion: string, // "C/ FELIPE SOLANO ANTELO 8A 8º3", ---> direccion_ps
            CodigoPostal: string, //"08630", ----> codigo_postal_ps
            Telefono: string, //"689105332", ----> telefono;
            ObservacionesPuntoSuministro: string, // "opcional o null" ----> observaciones_ps
        },
        T_CUPs: {
            Distribuidora: string, //"IBERDROLA", ----> distribuidora
            CUPsEle: string, //"ES0022000008945731EY", ------> cupShort
            Tarifa: string, //"2.0 DHS", ----> tarifaATR
            P1: string, //"35.25", ----> potenciasContratadasEnWP1
            P2: string, //"35.25", ----> potenciasContratadasEnWP2
            P3: string, //"35.25", ----> potenciasContratadasEnWP3
            P4: string, //"35.25", ----> potenciasContratadasEnWP4
            P5: string, //"35.25", ----> potenciasContratadasEnWP5
            P6: string, //"25.25", ----> potenciasContratadasEnWP6
            PotMaxBie: string, //"5265.25",   ----->Bie
            TipoServicio: string, //"1 Eléctrico, 2 Gas",  ----> TipoServicio
            Consumo: string, //"525663.25",    -----> sumaConsumoEnergiaActivaTotal
        },
        T_PropuestaComercial: {
            PorcentajeAhorro: string, //"0.00",
            ImporteAhorro: string, //"0.00",
            Estado: string, //"P",
            Comercializadora: string, //"AUDAX RENOVABLES S.A.",
            Producto: string, //"CLASSIC",
            IdProducto: string,
            Anexo: string, //"12 MESES T2",
            TipoPrecio: string, //"0 Fijo 1 Indexado",
            ObservacionesProducto: string, //"opcinal o null",
            GastoOpertativo: string, //"0.00 SOLO CUANDO ES CORPORATE",
            idservicio: string,
            idComercializadora: string,
            nombreServicio: string,
            precioServicio: string,
            productSelected: any
        },

    },
    rates2X: any[];
    rates3X: any[];
    rates2XP: any[];
    rates1XP: any[];
    rates3XP: any[];
    rates6XP: any[];
    rates1XE: any[];
    rates2XE: any[];
    rates3XE: any[];
    companyID: any;
    userId: any;
    nombreAgente: any;
    companyName: any;
    correoAgente: any;
    rates6XE: any[];
    comparativeTemp: {
        producto: any,
        enabledSave: boolean,
        actual: any,
        comparativaGlobal: any,
        propuestasAnalizadas: any
    };
    alert: any
}

interface IComparativeFreeProps { }

class SipComparativeFree extends Reflux.Component<any, any> {
    private dataToSave = {
        companyId: null,
        nombreOferta: null,
        idComercializadora: null,
        nombreComercializadora: null,
        idComercializadoraActual: null,
        nombreComercializadoraActual: null,
        datosPreciosActual: null,
        userId: null,
        fechaPropuesta: null,
        codigoPostalPS: null,
        codigoMunicipioPS: null,
        nombreMunicipioPS: null,
        codigoProvinciaPS: null,
        nombreProvinciaPS: null,
        codigoAutonomiaPS: null,
        nombreAutonomiaPS: null,
        esAprobada: false,
        datosPropuesta: null,
        correoContacto: null,
        cups: null,
        //datosContrato: null,
    };
    //public comparativeTemp: any = null;
    //this.state.comparativeTemp.producto
    public enabledSave: boolean = false;
    public formComparativeRef: any = null;
    public formSaveComparativeRef: any = null;
    public UserStore: any = {};

    public state: IComparativeFreeState = {
        comparativeTemp: {
            producto: [{ tarifaATR: '000' }],
            enabledSave: false,
            actual: [],
            comparativaGlobal: {},
            propuestasAnalizadas: {}
        },
        cups: "",
        user: {},
        sip: {},
        ubicacion: {},
        nombrePropuesta: "",
        consumptionsFields: {},
        sipsConsumos: [],
        itemsSelected: [],
        comercialProductoSelected: null,
        tableHeaders: [],
        comparatives: [],
        periodos: [],
        producto: {
            active: false,
            productoSeleccionado: null,
            costeOperativo: 0,
            costeOperativoPotencia: 0,
            esNuevoContrato: true
        },
        productoMulti: {
            active: false,
            productoSeleccionado: [],
            costeOperativo: 0,
            costeOperativoPotencia: 0,
            esNuevoContrato: true
        },

        // --- View State
        isDisabled: true,
        isLoading: true,
        isLoadingConsumos: true,
        openModalComparative: false,
        openModalMultiComparative: false,
        showList: true,
        dataComparativeTarget: null,
        saveModalOpened: false,
        datosContrato: {
            Usuario: "",
            fechaPropuesta: "",
            FechaAprobacion: "",
            IdOferta: "",
            T_Titular: {
                RazonSocial: "", //":"MANUEL GONZALEZ",--> nombre_completo_titular
                NIF: "", //"C19345456", --> CIF
                DireccionFiscal: "", // "C/ FELIPE SOLANO ANTELO 8A 8º3", ---> direccion_titular
                CodigoPostalTitular: "", //"08630",----> codigo_postal_titular
                TelefonoFijo: "", //"949201893", ----> telefono_fijo
                TelefonMovil: "", //"689105332", ----> telefono
                Email: "", //"demo@demo.com", --->email_contacto
                TipoCliente: "", //"COMUNIDAD DE PROPIETARIOS", ---> tipo_cliente
                ObservacionesCliente: "",
                GDPR: false, //"OPCIONAL O NULL",----> observaciones
            },
            T_Representante: {
                nombreRepresentante: "",
                apellido1Representante: "",
                apellido2Representante: "",
                NIFRepresentante: "",
                idRepresentante: "",
                idRepresentanteInterno: 0,
                CargoRepresentante: "",
                telefonoRepresentante: "",
                correoRepresentante: "",

            },
            T_PuntoSuministro: {
                Direccion: "", // "C/ FELIPE SOLANO ANTELO 8A 8º3", ---> direccion_ps
                CodigoPostal: "", //"08630", ----> codigo_postal_ps
                Telefono: "", //"689105332", ----> telefono;
                ObservacionesPuntoSuministro: "", // "opcional o null" ----> observaciones_ps
            },
            T_CUPs: {
                Distribuidora: "", //"IBERDROLA", ----> distribuidora
                CUPsEle: "", //"ES0022000008945731EY", ------> cupShort
                Tarifa: "", //"2.0 DHS", ----> tarifaATR
                P1: "0", //"35.25", ----> potenciasContratadasEnWP1
                P2: "0", //"35.25", ----> potenciasContratadasEnWP2
                P3: "0", //"35.25", ----> potenciasContratadasEnWP3
                P4: "0", //"35.25", ----> potenciasContratadasEnWP4
                P5: "0", //"35.25", ----> potenciasContratadasEnWP5
                P6: "0", //"25.25", ----> potenciasContratadasEnWP6
                PotMaxBie: "", //"5265.25",   ----->Bie
                TipoServicio: "", //"1 Eléctrico, 2 Gas",  ----> TipoServicio
                Consumo: "", //"525663.25",    -----> sumaConsumoEnergiaActivaTotal
            },
            T_PropuestaComercial: {
                PorcentajeAhorro: "", //"0.00",
                ImporteAhorro: "", //"0.00",
                Estado: "", //"P",
                Comercializadora: "", //"AUDAX RENOVABLES S.A.",
                Producto: "", //"CLASSIC",
                IdProducto: "",
                Anexo: "", //"12 MESES T2",
                TipoPrecio: "", //"0 Fijo 1 Indexado",
                ObservacionesProducto: "", //"opcinal o null",
                GastoOpertativo: "", //"0.00 SOLO CUANDO ES CORPORATE",
                idservicio: "",
                idComercializadora: "",
                nombreServicio: "",
                precioServicio: "",
                productSelected: null

            },
        },
        rates2X: [
            '001',
            '004',
            '005',
            '006',
            '007',
            '008'
        ],
        rates3X: [
            '011',
            '003',
            '012',
            '013',
            '014',
            '015',
            '016'
        ],
        rates2XP: [
            '018',
        ],
        rates1XP: [
            '001',
            '004',
            '005',
            '006',
            '007',
            '008'
        ],
        rates3XP: [
            '011',
            '003'
        ],
        rates6XP: [
            '012',
            '013',
            '014',
            '015',
            '016',
            '019',
            '020',
            '021',
            '022',
            '023',
            '024',
            '025'
        ],
        rates1XE: [
            '001',
            '005'
        ],
        rates2XE: [
            '004',
            '006'
        ],
        rates3XE: [
            '011',
            '003',
            '007',
            '008',
            '018'
        ],
        rates6XE: [
            '012',
            '013',
            '014',
            '015',
            '016',
            '019',
            '020',
            '021',
            '022',
            '023',
            '024',
            '025'
        ],
        companyID: "",
        userId: "",
        nombreAgente: "",
        companyName: "",
        correoAgente: "",
        UserStore: {},
        alert: null
    };

    constructor(props: IComparativeFreeProps) {
        super(props);
        this.showModal = this.showModal.bind(this);
        this.store = UserStore;
        this.showMultiModal = this.showMultiModal.bind(this);
        this.toggle = this.toggle.bind(this);
        this.toggleMulti = this.toggleMulti.bind(this);
        this.comparativeDataHandler = this.comparativeDataHandler.bind(this);
        //this.FunctionComponentWithRef = this.FunctionComponentWithRef.bind(this);

        this.changeSave = this.changeSave.bind(this);
    }



    comparativeDataHandler(data) {

        this.state.comparativeTemp = data;
        this.enabledSave = this.state.comparativeTemp.enabledSave
        let enabledSaveTemp: boolean = this.state.comparativeTemp.enabledSave

        // // console.log("VALOR DE comparativeTemp", this.state.comparativeTemp);
        // console.log("VALOR DE comparativeTemp DATA ", data);

        //// console.log("Previous Parent State", data);

    }



    componentDidMount() {
        const {
            match: { params },
        } = this.props;

        //const user = localStorage.getItem("user");
        //if (user) {
        //const parsed = JSON.parse(user);
        //this.setState({ user: parsed });
        //}


        const usuario: any = JSON.parse(JSON.stringify(this.state.UserStore['data']))


        const companyNameGlobal = usuario.company;
        const companyIdUser = usuario.companyId;

        const userGroupId = usuario.user_group_id
        const userId = usuario.id
        const nombreAgente = usuario.fullname
        const correoAgente = usuario.email

        //this.setState({ companyID :companyIdUser,
        //userId: userId});
        //any,companyIdUser, companyNameUser });


        this.setState(
            {
                cups: params.cups,
                consumptionsFields: consumptionsFields,
                companyID: companyIdUser,
                userId: userId,
                user: userId,
                companyName: companyNameGlobal,
                nombreAgente: nombreAgente,
                correoAgente: correoAgente

            },
            () => {

                this.fetchSip();
                this.fetchUbi();
                this.fetchComparatives();
            }
        );
        this.formComparativeRef = React.createRef();

    }


    hideAlert = () => {
        this.setState({
            alert: null
        });
    };

    successAlert = () => {
        this.setState({
            alert: (
                <ReactBSAlert
                    success
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Correcto"
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="success"
                    confirmBtnText="Ok"
                    btnSize=""
                >
                    Propuesta comerciale guardada correctamente
                </ReactBSAlert>
            )
        });
    };


    warningAlert = () => {
        this.setState({
            alert: (
                <ReactBSAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Sin consumos seleccionados"
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="warning"
                    confirmBtnText="Ok"
                    btnSize=""
                >
                    Tienes que seleccionar lecturas de consumos para continuar
                </ReactBSAlert>
            )
        });
    };

    warningAlertProducto = () => {
        this.setState({
            alert: (
                <ReactBSAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Sin producto seleccionado"
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="warning"
                    confirmBtnText="Ok"
                    btnSize=""
                >
                    Tienes que seleccionar un producto para poder guardar
                </ReactBSAlert>
            )
        });
    };


    fetchSip = async () => {
        let tableHeaders = {};
        let periodos: number[] = [];
        await this.props.client
            .query({
                query: VIEW_SIPS,
                variables: { cups: this.state.cups },
            })
            .then((result) => {
                //Sip
                const sip = result.data.viewSips[0];

                //Header tabla
                let codigoEquipo = "codigoDHEquipoDeMedida";
                let codigoTipoLectura = "codigoTipoLectura";
                let fechaInicioMes = "fechaInicioMesConsumo"
                let fechaFinMes = "fechaFinMesConsumo";
                let codigoTarifa = "codigoTarifaATR";

                tableHeaders[fechaInicioMes] = sipsConsumosFields[fechaInicioMes];
                tableHeaders[fechaFinMes] = sipsConsumosFields[fechaFinMes];
                tableHeaders[codigoTarifa] = sipsConsumosFields[codigoTarifa];

                var periodosNuevos = 0
                if (sip.codigoTarifaATREnVigor == '001' || sip.codigoTarifaATREnVigor == '004' || sip.codigoTarifaATREnVigor == '005' || sip.codigoTarifaATREnVigor == '006' || sip.codigoTarifaATREnVigor == '007' || sip.codigoTarifaATREnVigor == '008' || sip.codigoTarifaATREnVigor == '018') {
                    periodosNuevos = 3
                } else { periodosNuevos = 6 }

                for (let i = 1; i <= periodosNuevos; i++) {
                    let consumoAnual = `ConsumoAnualActivaP${i}`;
                    //  if (sip[consumoAnual] > 0) {
                    let consumoEnergiaActiva = `consumoEnergiaActivaEnWhP${i}`;
                    let consumoEnergiaReactiva = `consumoEnergiaReactivaEnVArhP${i}`;
                    let potenciaDemandada = `potenciaDemandadaEnWP${i}`;
                    tableHeaders[consumoEnergiaActiva] =
                        sipsConsumosFields[consumoEnergiaActiva];
                    tableHeaders[consumoEnergiaReactiva] =
                        sipsConsumosFields[consumoEnergiaReactiva];
                    tableHeaders[potenciaDemandada] =
                        sipsConsumosFields[potenciaDemandada];
                    periodos.push(i);
                    //}
                }

                tableHeaders[codigoEquipo] = sipsConsumosFields[codigoEquipo];
                tableHeaders[codigoTipoLectura] = sipsConsumosFields[codigoTipoLectura];

                this.setState(
                    {
                        sip: sip,
                        isLoading: false,
                        tableHeaders: tableHeaders,
                        periodos: periodos,
                    },
                    this.fetchUbi
                );
            });
    };

    //---------------------------------------//

    fetchUbi = async () => {
        await this.props.client
            .query({
                query: LIST_SIPS_UBICACION,
                variables: { cups: this.state.cups },
            })
            .then((result) => {
                //Sip
                const ubi = result.data.findUbicacion;

                this.setState(
                    {
                        ubicacion: ubi,
                    },
                    this.fetchItems
                );
            });
    };



    fetchItems = async () => {
        await this.props.client
            .query({
                query: LIST_SIPS_CONSUMOS,
                variables: { cups: this.state.cups },
            })
            .then((result) => {
                let listSipsConsumos = [
                    ...result.data.listSipsConsumos.map((row) => {
                        //Header tabla

                        // console.log('VALOR DEVUELTOS EN consulta -------> ', row)
                        let rowResult: any = {};

                        let codigoEquipo = "codigoDHEquipoDeMedida";
                        let codigoTipoLectura = "codigoTipoLectura";
                        let fechaInicioMes = "fechaInicioMesConsumo";
                        let fechaFinMes = "fechaFinMesConsumo";
                        let codigoTarifa = "codigoTarifaATR";

                        const dateValueInicio = new Date(row[fechaInicioMes]);
                        let dateInicio = dateValueInicio.toISOString().split("T")[0];
                        dateInicio = moment
                            .parseZone(dateValueInicio.toISOString().split("T")[0])
                            .format("DD-MM-YYYY");

                        const dateValueFin = new Date(row[fechaFinMes]);
                        let dateFin = dateValueFin.toISOString().split("T")[0];
                        dateFin = moment
                            .parseZone(dateValueFin.toISOString().split("T")[0])
                            .format("DD-MM-YYYY");

                        rowResult[fechaInicioMes] = dateInicio;
                        rowResult[fechaFinMes] = dateFin;
                        rowResult[codigoTarifa] = row[codigoTarifa];
                        for (let i of this.state.periodos) {
                            let consumoEnergiaActiva = `consumoEnergiaActivaEnWhP${i}`;
                            let consumoEnergiaReactiva = `consumoEnergiaReactivaEnVArhP${i}`;
                            let potenciaDemandada = `potenciaDemandadaEnWP${i}`;

                            rowResult[consumoEnergiaActiva] = parseFloat(row[consumoEnergiaActiva]);

                            rowResult[consumoEnergiaReactiva] = parseFloat(row[consumoEnergiaReactiva]) || 0;
                            rowResult[potenciaDemandada] = parseFloat(row[potenciaDemandada]);
                            /*switch(row[codigoTarifa]) {
                              case '3':
                              case '7':
                              case '8':
                              case '11':
                              rowResult[consumoEnergiaActiva] += parseFloat(row[`consumoEnergiaActivaEnWhP${i+3}`]);
                              break;
                            }*/
                        }

                        rowResult[codigoEquipo] = row[codigoEquipo];
                        rowResult[codigoTipoLectura] = row[codigoTipoLectura];

                        if (
                            !moment()
                                .add(-1, "year")
                                .isAfter(moment(rowResult[fechaFinMes], "DD-MM-YYYY"))
                        ) {
                            rowResult.id = this.uuid();


                            return rowResult;
                        }
                        return false;
                    }),
                ].filter((item) => item);
                //// console.log(this.state);
                this.setState({
                    sipsConsumos: listSipsConsumos,
                    isLoadingConsumos: false,
                });
                this.forceUpdate();
            });
    };

    selectedItems = async (event) => {
        let items: any[] = [];
        event.map((id) => {
            items.push({
                ...this.state.sipsConsumos.filter((item) => item.id === id)[0],
            });
            return id;
        });
        this.setState({
            itemsSelected: items,
            producto: {
                ...this.state.producto,
                active: false,
            },
        });
    };

    uuid = () => {
        var dt = new Date().getTime();
        var uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
            /[xy]/g,
            function (c) {
                var r = (dt + Math.random() * 16) % 16 | 0;
                dt = Math.floor(dt / 16);
                const uidObj = c === "x" ? r : (r & 0x3) | 0x8;

                return uidObj.toString(16);
            }
        );
        return uuid;
    };

    toggle() {
        this.setState({
            openModalComparative: !this.state.openModalComparative,
        });
    }

    toggleMulti() {
        this.setState({
            openModalMultiComparative: !this.state.openModalMultiComparative,
        });
    }

    toggleModal = (event, state) => {
        event.preventDefault();

        //// console.log("MODAL",state);
        //// console.log("product?",this.comparativeTemp.producto);
        // if(this.comparativeTemp.producto.producto[0] == undefined){ //Si ya tenemos producto significa que no es un array
        //   this.setState({
        //     [state]: !this.state[state],
        //   });
        // }else{
        //   alert("No has seleccionado ningun producto.");
        // }
        this.setState({
            [state]: !this.state[state],
        });

    };

    fetchComparatives = async () => {

        const companyIdAux = this.state.companyID.toString();
        const userIdAux = this.state.userId.toString();
        const cups = this.state.cups
        //const idContrato = this.state.contrato;

        var dataFilters = {
            'compania': companyIdAux,
            'cups': cups,
            'usuario': userIdAux
        };

        var valores = JSON.stringify(dataFilters)

        const headers = {
            'Authorization': 'Bearer my-token',
            'My-Custom-Header': 'foobar',
            'x-api-key': '$2y$10$bcT1Ukm4V/6/z5GvitZv0unu8I91mpixuwUO6z5CyEGev9DDDY28W'
        };
        let direccionFile = "https://datapi.psgestion.es/propuesta/listadoPropuestasCups?id=" + companyIdAux + "&user=" + userIdAux;


        await axios.get(direccionFile, { headers, params: { valores } })
            .then(res => {
            console.log('Actualización correcta', res.data)
            console.log('Datos del registro: ', res.data.rows)
            let data = res.data.rows
            // console.log("Comparativas cargadas :::>", data);
            this.setState({ comparatives: data });
        }).catch(error => {
            console.log('Failure')
            console.log('Error en el proceso: ', error)
            //notifyErrorDB();
        });
    };

    showModal() {
        this.setState({
            openModalComparative: true,
        });
    }

    showMultiModal() {
        this.setState({
            openModalMultiComparative: true,
        });
    }

    openComparativeSection = async (event) => {
        // console.log('DENTR DE openCOMPARATIVESECTION')
        // console.log(event)
        this.setState({
            producto: {
                active: true,
                productoSeleccionado: event.productoSeleccionado,
                costeOperativo: event.costeOperativo,
                costeOperativoPotencia: event.costeOperativoPotencia,
                esNuevoContrato: event.esNuevoContrato
            },
            showList: false,
        });
        //// console.log(this.state)
    };

    openComparativeMultiSection = async (event) => {
        // console.log('DENTR DE openCOMPARATIVEMULTISECTION')
        // console.log(event)
        this.setState({
            productoMulti: {
                active: true,
                productoSeleccionado: event.productoSeleccionado,
                costeOperativo: event.costeOperativo,
                costeOperativoPotencia: event.costeOperativoPotencia,
                esNuevoContrato: event.esNuevoContrato
            },
            showList: false,
        });
        //// console.log("comparativeMultiSection", this.state)
    };

    onSave = async (modalData) => {

        const idOferta = nanoid();

        //const idUser = nanoid();

        const now = new Date().toISOString();
        //// console.log('THIS COMPARATIVE TEMP PRODUCT')
        //  // console.log('THIS COMPARATIVE TEMP PRODUCT',this.comparativeTemp);
        // console.log('THIS DATA TO SAVE');
        // console.log(this.dataToSave);
        const user: any = this.state.user;
        const sip: any = this.state.sip;

        let potenciaP1 = 0;
        let potenciaP2 = 0;
        let potenciaP3 = 0;
        let potenciaP4 = 0;
        let potenciaP5 = 0;
        let potenciaP6 = 0;

        const producto = this.state.comparativeTemp.producto
            ? JSON.stringify(this.state.comparativeTemp.producto)
            : null;
        const productoJSON = this.state.comparativeTemp.producto
            ? this.state.comparativeTemp.producto
            : {};
        const actual = this.state.comparativeTemp.actual
            ? JSON.stringify(this.state.comparativeTemp.actual)
            : null;
        const actualJSON = this.state.comparativeTemp.actual
            ? this.state.comparativeTemp.actual
            : {};
        const datosContrato = JSON.stringify("");

        const globalJSON = this.state.comparativeTemp.comparativaGlobal
            ? this.state.comparativeTemp.comparativaGlobal
            : {};

        const propuestasAnalizadas = this.state.comparativeTemp.propuestasAnalizadas ? this.state.comparativeTemp.propuestasAnalizadas : {}

        const dataModal: any = {
            ...{
                ...this.dataToSave
            },
            ...modalData,
        };


        console.log('VALORES DE dataModal')
        console.log(dataModal)
        console.log('VALORES DE modaData')
        console.log(modalData)
        console.log('VALOR DE THIS _____------> ', this)

        var tarifaATRContrato = "";
        //// console.log(typeof producto.productoSeleccionado.tarifaATR)
        //// console.log(this.state.producto)
        var seleccion = ''
        if (this.state.comparativeTemp.producto.producto.tarifaATR) {
            seleccion = this.state.comparativeTemp.producto.producto.tarifaATR

        }

        if (seleccion == "018") {
            tarifaATRContrato = '2.0TD'
        } else if (seleccion == "019") {
            tarifaATRContrato = '3.0TD'
        } else if (seleccion == "020") {
            tarifaATRContrato = '6.1TD'
        } else if (seleccion == "021") {
            tarifaATRContrato = '6.2TD'
        } else if (seleccion == "022") {
            tarifaATRContrato = '6.3TD'
        } else if (seleccion == "023") {
            tarifaATRContrato = '6.4TD'
        } else if (seleccion == "024") {
            tarifaATRContrato = '3.0TDVE'
        } else if (seleccion == "025") {
            tarifaATRContrato = '6.1TDVE'
        };

        if (this.state.rates1XP.includes(sip.codigoTarifaATREnVigor) && seleccion == "018") {
            potenciaP1 += (Number(this.state.comparativeTemp.producto.datos.potenciaContratoP1) || 0);
            potenciaP2 += (Number(this.state.comparativeTemp.producto.datos.potenciaContratoP2) || 0);
        } else if (this.state.rates1XP.includes(sip.codigoTarifaATREnVigor) && this.state.rates1XP.includes(seleccion)) {
            potenciaP1 += (Number(this.state.comparativeTemp.producto.datos.potenciaContratoP1) || 0);
        } else if (this.state.rates2XP.includes(sip.codigoTarifaATREnVigor)) {
            potenciaP1 += (Number(this.state.comparativeTemp.producto.datos.potenciaContratoP1) || 0);
            potenciaP2 += (Number(this.state.comparativeTemp.producto.datos.potenciaContratoP2) || 0);
        } else if (this.state.rates3XP.includes(sip.codigoTarifaATREnVigor) && (seleccion == "019" || seleccion == "020")) {
            potenciaP1 += (Number(this.state.comparativeTemp.producto.datos.potenciaContratoP1) || 0);
            potenciaP2 += (Number(this.state.comparativeTemp.producto.datos.potenciaContratoP2) || 0);
            potenciaP3 += (Number(this.state.comparativeTemp.producto.datos.potenciaContratoP3) || 0);
            potenciaP4 += (Number(this.state.comparativeTemp.producto.datos.potenciaContratoP4) || 0);
            potenciaP5 += (Number(this.state.comparativeTemp.producto.datos.potenciaContratoP5) || 0);
            potenciaP6 += (Number(this.state.comparativeTemp.producto.datos.potenciaContratoP6) || 0);
        } else if (this.state.rates3XP.includes(sip.codigoTarifaATREnVigor) && this.state.rates3XP.includes(seleccion)) {
            potenciaP1 += (Number(this.state.comparativeTemp.producto.datos.potenciaContratoP1) || 0);
            potenciaP2 += (Number(this.state.comparativeTemp.producto.datos.potenciaContratoP2) || 0);
            potenciaP3 += (Number(this.state.comparativeTemp.producto.datos.potenciaContratoP3) || 0);
        } else {
            potenciaP1 += (Number(this.state.comparativeTemp.producto.datos.potenciaContratoP1) || 0);
            potenciaP2 += (Number(this.state.comparativeTemp.producto.datos.potenciaContratoP2) || 0);
            potenciaP3 += (Number(this.state.comparativeTemp.producto.datos.potenciaContratoP3) || 0);
            potenciaP4 += (Number(this.state.comparativeTemp.producto.datos.potenciaContratoP4) || 0);
            potenciaP5 += (Number(this.state.comparativeTemp.producto.datos.potenciaContratoP5) || 0);
            potenciaP6 += (Number(this.state.comparativeTemp.producto.datos.potenciaContratoP6) || 0);
        }

        var codigoPostal = dataModal.CodigoPostalTitular.padStart(5, "0")
        var codigoPostalPS = dataModal.CodigoPostalPS.padStart(5, "0")



        console.log('THIS COMPARATIVE TEMP PRODUCT')
        console.log('THIS COMPARATIVE TEMP PRODUCT', this.state.comparativeTemp);
        console.log('THIS DATA TO SAVE');
        console.log(this.dataToSave);
        console.log('THis STATES -------------------> ', this.state)

        let gastoOperativo = "0.00"
        let gastoOperativoPotencia = "0.00"
        if (!this.state.comparativeTemp.producto.producto.coIncluido) {
            gastoOperativo = this.state.comparativeTemp.producto.costeOperativo.toString() //"0.00 SOLO CUANDO ES CORPORATE",
            gastoOperativoPotencia = this.state.comparativeTemp.producto.costeOperativoPotencia.toString()
        };

        var fechaPropuesta = moment
            .parseZone(now.split("T")[0])
            .format("YYYY-MM-DD");
        var check = (moment(fechaPropuesta, "YYYY-MM-DD"));
        console.log('VALOR DE CHECK -------------------> ', check)
        var monthPropuesta = Number(check.format('M'));
        var yearPropuesta = check.year();
        console.log('VALOR DE yearPropuesta -------------------> ', yearPropuesta)



        var fechaAprobacion = moment
            .parseZone(now.split("T")[0])
            .format("YYYY-MM-DD");
        check = (moment(fechaAprobacion, "YYYY-MM-DD"));
        var monthAprobacion = Number(check.format('M'));
        var yearAprobacion = check.year();

        var currentDate = moment().format("YYYY-MM-DDTHH:mm:ss");

        console.log('VALOR DE FECHAS CURRENT DATE -------> ', currentDate)

        //var local_date= moment.utc(currentDate).local().format('YYYY-MM-DDTHH:mm:ss');

        //console.log('VALOR DE FECHAS local_date -------> ', local_date)

        var partes = currentDate.slice(0).split('T');

        //const now = new Date().toISOString();

        var date = partes[0]
        var hour = partes[1]

        var currentDateAprobacion = moment().format("YYYY-MM-DDTHH:mm:ss");
        //var local_date_aprobacion= moment.utc(currentDate).local().format('YYYY-MM-DDTHH:mm:ss');
        var partesAprobacion = currentDateAprobacion.slice(0).split('T');

        //const now = new Date().toISOString();

        var dateAprobacion = partesAprobacion[0]
        var hourAprobacion = partesAprobacion[1]
        var TipoID = "CIF"

        const result = validateSpanishId(dataModal.NIF)

        if (result === true) {
            const resultTipo = spainIdType(dataModal.NIF)
            console.log('VALOR DE RESULTADO DE VALIDATE Tipo -------> ', resultTipo)
            TipoID = resultTipo.toUpperCase();
        }


        if (dataModal.esAprobada === false) {

            var datosContratoP = {
                Usuario: this.state.userId.toString(),
                compania: this.state.companyID,
                fechaPropuesta: date,
                horaPropuesta: hour,
                mesPropuesta: monthPropuesta,
                anioPropuesta: yearPropuesta,
                fechaAprobacion: '',
                horaAprobacion: '',
                mesAprobacion: 0,
                anioAprobacion: 0,
                esAprobada: false,
                fechaDenegacion: "",
                horaDenegacion: "",
                mesDenegacion: 0,
                anioDenegacion: 0,
                esDenegada: false,
                IdOferta: idOferta,
                IdOfertaInterna: "",
                nombreOferta: dataModal.nombreOferta,
                cups: this.state.sip.cups,
                tipoCliente: dataModal.TipoCliente,
                tipoEnergia: "ELECTRICIDAD",
                estado: "ALTA",
                estadoId: "01",
                OfertaTipo: "P",
                ofertaTipoId: "0",
                T_Titular: {
                    NombreTitular: dataModal.nombreTitular?.toUpperCase() || '',
                    Apellido1Titular: dataModal.apellido1Titular?.toUpperCase() || '',
                    Apellido2Titular: dataModal.apellido2Titular?.toUpperCase() || '',
                    NIF: dataModal.NIF, //this.state.ubicacion.CIF,
                    TipoTitular: TipoID,
                    TipoViaTitular: dataModal.tipoViaTitular?.toUpperCase() || '',
                    ViaTitular: dataModal.viaTitular?.toUpperCase() || '',
                    NumFincaTitular: dataModal.numFincaTitular?.toUpperCase() || '',
                    PortalTitular: dataModal.portalTitular?.toUpperCase() || '',
                    EscaleraTitular: dataModal.escaleraTitular?.toUpperCase() || '',
                    PisoTitular: dataModal.pisoTitular?.toUpperCase() || '',
                    PuertaTitular: dataModal.puertaTitular?.toUpperCase() || '',
                    CodigoPostalTitular: codigoPostal,
                    AutonomiaTitular: dataModal.desAutonomiaTitular?.toUpperCase() || '',
                    ProvinciaTitular: dataModal.desProvinciaTitular?.toUpperCase() || '',
                    MunicipioTitular: dataModal.desMunicipioTitular?.toUpperCase() || '',
                    TelefonoFijo: dataModal.TelefonoFijo, //this.state.ubicacion.telefono_fijo,
                    TelefonMovil: dataModal.TelefonMovil, //this.state.ubicacion.telefono,
                    Email: dataModal.correoContacto, //this.state.ubicacion.email_contacto,
                    TipoCliente: dataModal.TipoCliente, //this.state.ubicacion.tipo_cliente,
                    ObservacionesCliente: dataModal.ObservacionesCliente,
                    GDPR: dataModal.GDPR,
                    IBAN: dataModal.iban,
                    CNAE: this.state.sip.CNAE,
                    actividadCNAE: this.state.sip.actividadCNAE,
                },
                T_Representante: {
                    nombreRepresentante: dataModal.nombreRepresentante?.toUpperCase() || '',
                    apellido1Representante: dataModal.apellido1Representante?.toUpperCase() || '',
                    apellido2Representante: dataModal.apellido2Representante?.toUpperCase() || '',
                    NIFRepresentante: dataModal.NIFRepresentante?.toUpperCase() || '',
                    TipoTitularRepresentante: dataModal.tipoTitularRepresentante?.toUpperCase() || '',
                    idRepresentante: dataModal.idRepresentante,
                    idRepresentanteInterno: dataModal.idRepresentanteInterno,
                    CargoRepresentante: dataModal.CargoRepresentante?.toUpperCase() || '',
                    telefonoRepresentante: dataModal.telefonoRepresentante,
                    correoRepresentante: dataModal.correoRepresentante,
                },
                T_PuntoSuministro: {
                    TipoViaPS: dataModal.tipoViaPS?.toUpperCase() || '',
                    ViaPS: dataModal.viaPS?.toUpperCase() || '',
                    NumFincaPS: dataModal.numFincaPS?.toUpperCase() || '',
                    PortalPS: dataModal.portalPS?.toUpperCase() || '',
                    EscaleraPS: dataModal.escaleraPS?.toUpperCase() || '',
                    PisoPS: dataModal.pisoPS?.toUpperCase() || '',
                    PuertaPS: dataModal.puertaPS?.toUpperCase() || '',
                    CodigoPostalPS: codigoPostalPS,
                    NombreMunicipioPS: dataModal.nombreMunicipioPS?.toUpperCase() || '',
                    codigoMunicipioPS: sip.codigoMunicipioPS,
                    NombreProvinciaPS: dataModal.nombreProvinciaPS.toUpperCase(),
                    codigoProvinciaPS: sip.codigoProvinciaPS,
                    AutonomiaPS: dataModal.desAutonomiaPS?.toUpperCase() || '',
                    codigoAutonomiaPS: sip.codigoAutonomiaPS,
                    TelefonoPS: dataModal.TelefonoPS,
                    ObservacionesPuntoSuministro: dataModal.ObservacionesPuntoSuministro
                },
                T_CUPs: {
                    Distribuidora: this.state.sip.distribuidora,
                    CUPsEle: this.state.sip.cups,
                    Tarifa: tarifaATRContrato,
                    P1: potenciaP1,
                    P2: potenciaP2,
                    P3: potenciaP3,
                    P4: potenciaP4,
                    P5: potenciaP5,
                    P6: potenciaP6,
                    PotMaxBie: this.state.sip.potenciaMaximaBIEW,
                    TipoServicio: "1",
                    Consumo: this.state.sip.ConsumoAnualTotalActiva.toString(),
                    esViviendaHabitual: this.state.sip.esViviendaHabitual,
                    fechaCaducidadAPM: this.state.sip.fechaCaducidadAPM,
                    fechaCaducidadBIE: this.state.sip.fechaCaducidadBIE,
                    fechaEmisionAPM: this.state.sip.fechaEmisionAPM,
                    fechaEmisionBIE: this.state.sip.fechaEmisionBIE,
                    fechaLimiteDerechosReconocidos: this.state.sip.fechaLimiteDerechosReconocidos,
                    fechaUltimaLectura: this.state.sip.fechaUltimaLectura,
                    fechaUltimoCambioComercializador: this.state.sip.fechaUltimoCambioComercializador,
                    fechaUltimoMovimientoContrato: this.state.sip.fechaUltimoMovimientoContrato,
                    importeDepositoGarantiaEuros: this.state.sip.importeDepositoGarantiaEuros,
                    informacionImpagos: this.state.sip.informacionImpagos,
                    codigoAutoconsumo: this.state.sip.codigoAutoconsumo,
                    descAutoconsumo: this.state.sip.descAutoconsumo,
                    codigoTelegestion: this.state.sip.codigoTelegestion,
                    tipoTelegestion: this.state.sip.tipoTelegestion,
                    proEquipoMedida: this.state.sip.proEquipoMedida,
                    propiedadICP: this.state.sip.propiedadICP,
                    relacionTransformacionIntensidad: this.state.sip.relacionTransformacionIntensidad,
                    tarifaATR: this.state.sip.tarifaATR,
                    tensionEm: this.state.sip.tensionEm,
                    tipoContrato: this.state.sip.tipoContrato,
                    valorDerechosAccesoW: this.state.sip.valorDerechosAccesoW,
                    valorDerechosExtensionW: this.state.sip.valorDerechosExtensionW,
                    Tension: this.state.sip.Tension,
                    accesoContador: this.state.sip.accesoContador
                },
                T_PropuestaComercial: {
                    PorcentajeAhorro: this.state.comparativeTemp.producto.totalComparacion.ahorroPercent.toString(),
                    ImporteAhorro: this.state.comparativeTemp.producto.totalComparacion.ahorroAnual.toString(),
                    Estado: "P", //"P",
                    Comercializadora: this.state.comparativeTemp.producto.producto.nombreComercializadora, //"AUDAX RENOVABLES S.A.",
                    Producto: this.state.comparativeTemp.producto.producto.nombreOferta, //"CLASSIC",
                    TarifaPeaje: this.state.comparativeTemp.producto.producto.tarifaATR,
                    Tarifa: tarifaATRContrato,
                    IdProducto: this.state.comparativeTemp.producto.producto.id.toString(),
                    Anexo: this.state.comparativeTemp.producto.producto.nombreDocumentoContrato,
                    //this.state.comparativeTemp.producto.producto.condicionesPenalizacion, //"12 MESES T2",
                    TipoPrecio: "0", //"0 Fijo 1 Indexado",
                    ObservacionesProducto: "",
                    //this.state.comparativeTemp.producto.producto.condicionesRevisionPrecios, //"opcinal o null",
                    GastoOperativo: gastoOperativo, //"0.00 SOLO CUANDO ES CORPORATE",
                    GastoOperativoPotencia: gastoOperativoPotencia,
                    idServicio: this.state.comparativeTemp.producto.datos.idServicio,
                    idComercializadora: this.state.comparativeTemp.producto.datos.idComercializadora?.padStart(4, "0") || '',
                    nombreServicio: this.state.comparativeTemp.producto.datos.nombreServicio,
                    precioServicio: this.state.comparativeTemp.producto.datos.precioServicio.toString(), //"0.00 SOLO CUANDO NO TIENE SERVICIO CONTRATADO",
                    ComercializadoraProcedencia: dataModal.idComercializadora,
                    idComercializadoraProcedencia: dataModal.nombreComercializadora?.padStart(4, "0") || '',
                    comisionContratoFija: this.state.comparativeTemp.producto.datos.comisionContratoFija,
                    comisionContratoConsumoAgente1: this.state.comparativeTemp.producto.datos.comisionContratoConsumoAgente1,
                    comisionContratoConsumoAgente2: this.state.comparativeTemp.producto.datos.comisionContratoConsumoAgente2,
                    comisionContratoConsumoAdmin: this.state.comparativeTemp.producto.datos.comisionContratoConsumoAdmin,
                    esNuevoContrato: this.state.comparativeTemp.producto.esNuevoContrato,
                    propuestaGlobal: globalJSON
                },
                T_PropuestasAnalizadas: {
                    propuestasAnalizadas: propuestasAnalizadas
                },
                T_DatosAgenteComercial: {
                    usuario: this.state.userId,
                    nombreUsuario: this.state.nombreAgente,
                    compania: this.state.companyID,
                    nombreCompania: this.state.companyName,
                    correoAgente: this.state.correoAgente,
                    zonaGeografica: ""
                },
                T_Propuesta: {
                    producto: productoJSON,
                    actual: actualJSON,
                },
                T_Seguimiento: [{
                    usuario: this.state.userId,
                    nombreUsuario: this.state.nombreAgente,
                    compania: this.state.companyID,
                    fecha: date,
                    hora: hour,
                    mes: monthPropuesta,
                    anio: yearPropuesta,
                    accion: "ALTA",
                    idAccion: "01",
                    observacion: ""
                }]
            }
        } else {

            var datosContratoP = {
                Usuario: this.state.userId.toString(),
                compania: this.state.companyID,
                fechaPropuesta: date,
                horaPropuesta: hour,
                mesPropuesta: monthPropuesta,
                anioPropuesta: yearPropuesta,
                fechaAprobacion: dateAprobacion,
                horaAprobacion: hourAprobacion,
                mesAprobacion: monthAprobacion,
                anioAprobacion: yearAprobacion,
                esAprobada: true,
                fechaDenegacion: "",
                horaDenegacion: "",
                mesDenegacion: 0,
                anioDenegacion: 0,
                esDenegada: false,
                IdOferta: idOferta,
                IdOfertaInterna: "",
                nombreOferta: dataModal.nombreOferta,
                cups: this.state.sip.cups,
                tipoCliente: dataModal.TipoCliente,
                tipoEnergia: "ELECTRICIDAD",
                estado: "APROBADA",
                estadoId: "50",
                OfertaTipo: "P",
                ofertaTipoId: "0",
                T_Titular: {
                    NombreTitular: dataModal.nombreTitular?.toUpperCase() || '',
                    Apellido1Titular: dataModal.apellido1Titular?.toUpperCase() || '',
                    Apellido2Titular: dataModal.apellido2Titular?.toUpperCase() || '',
                    NIF: dataModal.NIF.toUpperCase(), //this.state.ubicacion.CIF,
                    TipoTitular: TipoID,
                    TipoViaTitular: dataModal.tipoViaTitular?.toUpperCase() || '',
                    ViaTitular: dataModal.viaTitular?.toUpperCase() || '',
                    NumFincaTitular: dataModal.numFincaTitular?.toUpperCase() || '',
                    PortalTitular: dataModal.portalTitular?.toUpperCase() || '',
                    EscaleraTitular: dataModal.escaleraTitular?.toUpperCase() || '',
                    PisoTitular: dataModal.pisoTitular?.toUpperCase() || '',
                    PuertaTitular: dataModal.puertaTitular?.toUpperCase() || '',
                    CodigoPostalTitular: codigoPostal?.padStart(4, "0") || '',
                    AutonomiaTitular: dataModal.desAutonomiaTitular?.toUpperCase() || '',
                    ProvinciaTitular: dataModal.desProvinciaTitular?.toUpperCase() || '',
                    MunicipioTitular: dataModal.desMunicipioTitular?.toUpperCase() || '',
                    TelefonoFijo: dataModal.TelefonoFijo, //this.state.ubicacion.telefono_fijo,
                    TelefonMovil: dataModal.TelefonMovil, //this.state.ubicacion.telefono,
                    Email: dataModal.correoContacto, //this.state.ubicacion.email_contacto,
                    TipoCliente: dataModal.TipoCliente, //this.state.ubicacion.tipo_cliente,
                    ObservacionesCliente: dataModal.ObservacionesCliente,
                    GDPR: dataModal.GDPR,
                    IBAN: dataModal.iban,
                    CNAE: this.state.sip.CNAE,
                    actividadCNAE: this.state.sip.actividadCNAE,

                },
                T_Representante: {
                    nombreRepresentante: dataModal.nombreRepresentante?.toUpperCase() || '',
                    apellido1Representante: dataModal.apellido1Representante?.toUpperCase() || '',
                    apellido2Representante: dataModal.apellido2Representante?.toUpperCase() || '',
                    NIFRepresentante: dataModal.NIFRepresentante?.toUpperCase() || '',
                    TipoTitularRepresentante: dataModal.tipoTitularRepresentante?.toUpperCase() || '',
                    idRepresentante: dataModal.idRepresentante,
                    idRepresentanteInterno: dataModal.idRepresentanteInterno,
                    CargoRepresentante: dataModal.CargoRepresentante?.toUpperCase() || '',
                    telefonoRepresentante: dataModal.telefonoRepresentante,
                    correoRepresentante: dataModal.correoRepresentante,
                },
                T_PuntoSuministro: {
                    TipoViaPS: dataModal.tipoViaPS?.toUpperCase() || '',
                    ViaPS: dataModal.viaPS?.toUpperCase() || '',
                    NumFincaPS: dataModal.numFincaPS?.toUpperCase() || '',
                    PortalPS: dataModal.portalPS?.toUpperCase() || '',
                    EscaleraPS: dataModal.escaleraPS?.toUpperCase() || '',
                    PisoPS: dataModal.pisoPS?.toUpperCase() || '',
                    PuertaPS: dataModal.puertaPS?.toUpperCase() || '',
                    CodigoPostalPS: codigoPostalPS?.padStart(4, "0") || '',
                    NombreMunicipioPS: dataModal.nombreMunicipioPS?.toUpperCase() || '',
                    codigoMunicipioPS: sip.codigoMunicipioPS,
                    NombreProvinciaPS: dataModal.nombreProvinciaPS?.toUpperCase() || '',
                    codigoProvinciaPS: sip.codigoProvinciaPS,
                    AutonomiaPS: dataModal.desAutonomiaPS?.toUpperCase() || '',
                    codigoAutonomiaPS: sip.codigoAutonomiaPS,
                    TelefonoPS: dataModal.TelefonoPS,
                    ObservacionesPuntoSuministro: dataModal.ObservacionesPuntoSuministro?.toUpperCase() || '',
                },
                T_CUPs: {
                    Distribuidora: this.state.sip.distribuidora,
                    CUPsEle: this.state.sip.cups,
                    Tarifa: tarifaATRContrato,
                    P1: potenciaP1,
                    P2: potenciaP2,
                    P3: potenciaP3,
                    P4: potenciaP4,
                    P5: potenciaP5,
                    P6: potenciaP6,
                    PotMaxBie: this.state.sip.potenciaMaximaBIEW,
                    TipoServicio: "1",
                    Consumo: this.state.sip.ConsumoAnualTotalActiva.toString(),
                    esViviendaHabitual: this.state.sip.esViviendaHabitual,
                    fechaCaducidadAPM: this.state.sip.fechaCaducidadAPM,
                    fechaCaducidadBIE: this.state.sip.fechaCaducidadBIE,
                    fechaEmisionAPM: this.state.sip.fechaEmisionAPM,
                    fechaEmisionBIE: this.state.sip.fechaEmisionBIE,
                    fechaLimiteDerechosReconocidos: this.state.sip.fechaLimiteDerechosReconocidos,
                    fechaUltimaLectura: this.state.sip.fechaUltimaLectura,
                    fechaUltimoCambioComercializador: this.state.sip.fechaUltimoCambioComercializador,
                    fechaUltimoMovimientoContrato: this.state.sip.fechaUltimoMovimientoContrato,
                    importeDepositoGarantiaEuros: this.state.sip.importeDepositoGarantiaEuros,
                    informacionImpagos: this.state.sip.informacionImpagos,
                    codigoAutoconsumo: this.state.sip.codigoAutoconsumo,
                    descAutoconsumo: this.state.sip.descAutoconsumo,
                    codigoTelegestion: this.state.sip.codigoTelegestion,
                    tipoTelegestion: this.state.sip.tipoTelegestion,
                    proEquipoMedida: this.state.sip.proEquipoMedida,
                    propiedadICP: this.state.sip.propiedadICP,
                    relacionTransformacionIntensidad: this.state.sip.relacionTransformacionIntensidad,
                    tarifaATR: this.state.sip.tarifaATR,
                    tensionEm: this.state.sip.tensionEm,
                    tipoContrato: this.state.sip.tipoContrato,
                    valorDerechosAccesoW: this.state.sip.valorDerechosAccesoW,
                    valorDerechosExtensionW: this.state.sip.valorDerechosExtensionW,
                    Tension: this.state.sip.Tension,
                    accesoContador: this.state.sip.accesoContador
                },
                T_PropuestaComercial: {
                    PorcentajeAhorro: this.state.comparativeTemp.producto.totalComparacion.ahorroPercent.toString(),
                    ImporteAhorro: this.state.comparativeTemp.producto.totalComparacion.ahorroAnual.toString(),
                    Estado: "P", //"P",
                    Comercializadora: this.state.comparativeTemp.producto.producto.nombreComercializadora, //"AUDAX RENOVABLES S.A.",
                    Producto: this.state.comparativeTemp.producto.producto.nombreOferta, //"CLASSIC",
                    TarifaPeaje: this.state.comparativeTemp.producto.producto.tarifaATR,
                    Tarifa: tarifaATRContrato,
                    IdProducto: this.state.comparativeTemp.producto.producto.id,
                    Anexo: this.state.comparativeTemp.producto.producto.nombreDocumentoContrato,
                    //this.state.comparativeTemp.producto.producto.condicionesPenalizacion, //"12 MESES T2",
                    TipoPrecio: "0", //"0 Fijo 1 Indexado",
                    ObservacionesProducto: "",
                    //this.state.comparativeTemp.producto.producto.condicionesRevisionPrecios, //"opcinal o null",
                    GastoOperativo: gastoOperativo, //"0.00 SOLO CUANDO ES CORPORATE",
                    GastoOperativoPotencia: gastoOperativoPotencia,
                    idServicio: this.state.comparativeTemp.producto.datos.idServicio,
                    idComercializadora: this.state.comparativeTemp.producto.datos.idComercializadora?.padStart(4, "0") || '',
                    nombreServicio: this.state.comparativeTemp.producto.datos.nombreServicio,
                    precioServicio: this.state.comparativeTemp.producto.datos.precioServicio.toString(), //"0.00 SOLO CUANDO NO TIENE SERVICIO CONTRATADO",
                    ComercializadoraProcedencia: dataModal.idComercializadora?.padStart(4, "0") || '',
                    idComercializadoraProcedencia: dataModal.nombreComercializadora,
                    comisionContratoFija: this.state.comparativeTemp.producto.datos.comisionContratoFija,
                    comisionContratoConsumoAgente1: this.state.comparativeTemp.producto.datos.comisionContratoConsumoAgente1,
                    comisionContratoConsumoAgente2: this.state.comparativeTemp.producto.datos.comisionContratoConsumoAgente2,
                    comisionContratoConsumoAdmin: this.state.comparativeTemp.producto.datos.comisionContratoConsumoAdmin,
                    esNuevoContrato: this.state.comparativeTemp.producto.esNuevoContrato,
                    propuestaGlobal: globalJSON
                },
                T_PropuestasAnalizadas: {
                    propuestasAnalizadas: propuestasAnalizadas
                },
                T_DatosAgenteComercial: {
                    usuario: this.state.userId,
                    nombreUsuario: this.state.nombreAgente,
                    compania: this.state.companyID,
                    nombreCompania: this.state.companyName,
                    correoAgente: this.state.correoAgente,
                    zonaGeografica: ""
                },
                T_Propuesta: {
                    producto: productoJSON,
                    actual: actualJSON
                },
                T_Seguimiento: [{
                    usuario: this.state.userId,
                    nombreUsuario: this.state.nombreAgente,
                    compania: this.state.companyID,
                    fecha: date,
                    hora: hour,
                    mes: monthPropuesta,
                    anio: yearPropuesta,
                    accion: "ALTA",
                    idAccion: "01",
                    observacion: ""
                }, {
                    usuario: this.state.userId,
                    nombreUsuario: this.state.nombreAgente,
                    compania: this.state.companyID,
                    fecha: dateAprobacion,
                    hora: hourAprobacion,
                    mes: monthAprobacion,
                    anio: yearAprobacion,
                    accion: "APROBADA",
                    idAccion: "50",
                    observacion: ""
                }]
            };

        };
        //const datosContratoJSON = JSON.stringify("");
        const datosContratoJSON: string = JSON.stringify(datosContratoP).toString();
        //// console.log('DATOS DE DATOSCONTRATOP');
        //// console.log(datosContratoP);
        //// console.log(datosContratoJSON);

        //// console.log('THIs DATA MODAL TO SAVE ANTES DEL SAVE');
        //// console.log(dataModal);
        var data: any = []

/*    if (dataModal.esAprobada === false) {


            data = {
                ...{
                    ...this.dataToSave,
                    companyId: this.state.companyID,
                    nombreOferta: "",
                    idComercializadora: null,
                    nombreComercializadora: null,
                    idComercializadoraActual: sip.codigoComercializadora,
                    nombreComercializadoraActual: sip.comercializadora,
                    datosPreciosActual: actual,
                    userId: Number(this.state.userId),
                    fechaPropuesta: moment
                        .parseZone(now.split("T")[0])
                        .format("YYYY-MM-DD"),
                    codigoPostalPS: sip.codigoPostalPS,
                    codigoMunicipioPS: sip.codigoMunicipioPS,
                    nombreMunicipioPS: sip.nombreMunicipioPS,
                    codigoProvinciaPS: sip.codigoProvinciaPS,
                    nombreProvinciaPS: sip.nombreProvinciaPS,
                    codigoAutonomiaPS: sip.codigoAutonomiaPS,
                    nombreAutonomiaPS: sip.nombreAutonomiaPS,
                    esAprobada: false,
                    datosPropuesta: producto,
                    correoContacto: "",
                    cups: this.state.cups,
                    tipo: "L",
                    datosContrato: datosContratoJSON

                },
                ...modalData,
            };
        } else {
            data = {
                ...{
                    ...this.dataToSave,
                    companyId: this.state.companyID,
                    nombreOferta: "",
                    idComercializadora: null,
                    nombreComercializadora: null,
                    idComercializadoraActual: sip.codigoComercializadora,
                    nombreComercializadoraActual: sip.comercializadora,
                    datosPreciosActual: actual,
                    userId: Number(this.state.userId),
                    fechaPropuesta: moment
                        .parseZone(now.split("T")[0])
                        .format("YYYY-MM-DD"),
                    codigoPostalPS: sip.codigoPostalPS,
                    codigoMunicipioPS: sip.codigoMunicipioPS,
                    nombreMunicipioPS: sip.nombreMunicipioPS,
                    codigoProvinciaPS: sip.codigoProvinciaPS,
                    nombreProvinciaPS: sip.nombreProvinciaPS,
                    codigoAutonomiaPS: sip.codigoAutonomiaPS,
                    nombreAutonomiaPS: sip.nombreAutonomiaPS,
                    esAprobada: true,
                    datosPropuesta: producto,
                    correoContacto: "",
                    cups: this.state.cups,
                    tipo: "L",
                    datosContrato: datosContratoJSON

                },
                ...modalData,
            };


        }
        */

        console.log('THIs DATA TO SAVE ANTES DEL SAVE');
        console.log(data);
        console.log('THIS STATE ANTES DEL SAVE');
        console.log(this.state)

        /*await this.props.client
            .mutate({
                mutation: CREATE_SIPS_COMPARATIVAS_LIBRE,
                variables: { data: data },
            })
            .then((result) => {
                // console.log("guardado", result);
                this.successAlert();

                this.clearInvoices();
                this.fetchComparatives();
            })
            .then(() => {
                this.forceUpdate();
            });
*/


        const companyIdAux = this.state.companyID.toString();
        const userIdAux = this.state.userId.toString();
        //const idContrato = this.state.contrato;


        const headers = {
            'Authorization': 'Bearer my-token',
            'My-Custom-Header': 'foobar',
            'x-api-key': '$2y$10$bcT1Ukm4V/6/z5GvitZv0unu8I91mpixuwUO6z5CyEGev9DDDY28W'
        };
        let direccionFile = "https://datapi.psgestion.es/propuesta/create?id=" + companyIdAux + "&user=" + userIdAux;


        axios.post(direccionFile, datosContratoP, { headers, responseType: 'json' })
            .then(res => {
                console.log('Actualización correcta', res)
                console.log('Datos del registro: ', res.data)
                this.successAlert();
                this.clearInvoices();
                this.fetchComparatives();
            }).catch(error => {
                console.log('Failure')
                console.log('Error en el proceso: ', error)
                //notifyErrorDB();
            });


    };

    openModalComparativa = async (event) => {
        // console.log('Entra en openModalComparativa')
        await this.closeComparativeSection();
        if (this.state.itemsSelected.length) {
            this.showModal();
            return;
        }
        //alert("Seleccione consumos para realizar la comparativa");
        this.warningAlert();
    };


    openModalMultiComparativa = async (event) => {
        // console.log('Entra en openModalMultiComparativa')
        await this.closeComparativeSection();
        if (this.state.itemsSelected.length) {
            this.showMultiModal();
            return;
        }
        //alert("Seleccione consumos para realizar la comparativa");
        this.warningAlert()
    };

    closeComparativeSection = async () => {
        // console.log('Entra en closeComparativeSection')

        this.setState({
            producto: {
                active: false,
                producto: null,
                costeOperativo: 0,
                costeOperativoPotencia: 0,
                esNuevoContrato: true
            },
        });
    };

    comparativeSelected = async (event) => {
        let idUnico = event.target.value;
        let id = idUnico+"_"+this.state.companyID
        const win = window.open("/print/sip-comparative-print/" + id, "_blank");
        if (win != null) {
            win.focus();
        }
    };

    clearInvoices = () => {
        //this.state.comparativeTemp = [];
        //// console.log('Dentro de clearInvoices ----> ', this.state)
        this.setState({
            producto: {
                active: false,
                producto: null,
                costeOperativo: 0,
                costeOperativoPotencia: 0,
                esNuevoContrato: true
            },
            showList: true,
            productSelected: null
        });
        if (this.state.productoMulti) {
            //// console.log('Dentro de clearInvoices Multi ----> ', this.state)
            this.setState({
                productoMulti: {
                    active: false,
                    productoSeleccionado: null,
                    costeOperativo: 0,
                    costeOperativoPotencia: 0,
                    esNuevoContrato: true

                },
                showList: true,
                productSelected: null
            });
        }

    };

    /*

   FunctionComponentWithRef = () => {

    // console.log('dentro de functionComponentWithRef');
    // console.log("VALOR DE this.enabledSave", this.enabledSave);
    const enabledSave = this.enabledSave;
    //this.setState({enabledSave: enabledSave});
    // console.log(enabledSave);
    //if (!enabledSave){
    return (
            <div className="text-right">
                  <Button

                    onClick={(event) =>
                      this.toggleModal(event, "saveModalOpened")
                    }
                    className="btn btn-sm btn-primary"
                  >
                    Guardar Oferta
                        </Button>
              </div>
    );
    //}
  };
  */
    changeSave = (e) => {
        // console.log('VALORES DE e en changeSave ----> ', e.target.value.length , ' valor de this.enabledSave ', this.enabledSave, ' isdiabled ',this.state.isDisabled )
        let s = true;
        if (e.target.value.length && this.enabledSave == true) {
            s = false;
            this.setState({ nombrePropuesta: e.target.value })
        }
        if (s === true) {
            // console.log(' dentro de s VALORES DE e en changeSave ----> ', e.target.value.length , ' valor de this.enabledSave ', this.enabledSave, ' isdiabled ',this.state.isDisabled )
            // console.log( 'VALOR DE ThIS ---->', this)
            if (e.target.value.length > 0) {//alert("Recuerde seleccionar un producto para guardar la propuesta comercial");}
                this.warningAlertProducto()
            }
        } else {
            this.setState({ isDisabled: s });
        }
    }


    render() {

        const disable_button = this.enabledSave
        //// console.log('VALOR PARA EL BOTON DE GUARDAR');
        const showComercialProducto = !this.state.producto.active
            ? { display: "none" }
            : {};
        const showComercialProductoSave = !this.state.producto.active
            ? { display: "none" }
            : {};
        const showComercialMultiProducto = !this.state.productoMulti.active
            ? { display: "none" }
            : {};
        const showComercialMultiProductoSave = !this.state.productoMulti.active
            ? { display: "none" }
            : {};
        const showList = !this.state.showList ? { display: "none" } : {};
        //// console.log('ENTRA EN RENDER PARA freeModal')
        //// console.log(this.state)
        //// console.log("log10", this.state.sipsConsumos);
        //// console.log("log11", this.state.tableHeaders);


        let freeModal = <></>;
        if (this.state.sip.NumeroPeriodos) {
            if (this.state.openModalComparative == true && this.state.openModalMultiComparative == false) {
                //// console.log('ENTRA EN RENDER PARA freeModal SIMPLE')
                freeModal = (
                    <SipComparativeFreeModal
                        sip={this.state.sip}
                        itemsSelected={this.state.itemsSelected}
                        isOpen={this.state.openModalComparative}
                        toggle={this.toggle}
                        periods={this.state.periodos}
                        generateComparative={this.openComparativeSection}
                    ></SipComparativeFreeModal>
                );
            } else if (this.state.openModalComparative == false && this.state.openModalMultiComparative == true) {
                //// console.log('ENTRA EN RENDER PARA freeModal MULTI')
                freeModal = (
                    <SipComparativeFreeMultiModal
                        sip={this.state.sip}
                        itemsSelected={this.state.itemsSelected}
                        isOpen={this.state.openModalMultiComparative}
                        toggleMulti={this.toggleMulti}
                        periods={this.state.periodos}
                        generateComparative={this.openComparativeMultiSection}
                    ></SipComparativeFreeMultiModal>
                );
            }
        }

        return (
            <>
                <Header title="Comparativa de Contrato Precio Fijo" />
                <Container id="comparative" className=" mt--7" fluid>
                    <Row className="card-deck">
                        <SupplyInformation
                            isLoading={this.state.isLoading}
                            sip={this.state.sip}
                            cardClass="col-6"
                        ></SupplyInformation>
                        <RateInformation
                            isLoading={this.state.isLoading}
                            sip={this.state.sip}
                            cardClass="col-6"
                        ></RateInformation>
                    </Row>

                    <Row >
                        <div className="col m-2 d-flex">
                            <Button color="primary" size="sm" type="button"
                                className="btn-icon btn-sm btn-circle"
                                onClick={this.props.history.goBack}
                            >
                                <span className="bi bi-box-arrow-left"> Volver</span>
                            </Button>



                            <Button color="secondary" size="sm" type="button" className="btn-icon btn-sm btn-circle"
                                onClick={this.clearInvoices}
                            >

                                <span className="bi bi-eraser"> Limpiar</span>

                            </Button>
                        </div>
                    </Row>


                    <div className="card-deck">


                        <Card className="col m-3  p-2 bg-light d-flex justify-content-end">
                            <CardBody>


                                <Row>
                                    <Col>
                                        <FormGroup>
                                            <Input
                                                id="nombreOferta-id"
                                                placeholder="Introduzca el nombre de la comparativa"
                                                name="nombreOferta"
                                                onChange={this.changeSave}
                                                type="text"
                                                required
                                            />
                                        </FormGroup>

                                    </Col>
                                    <Col>
                                        <Button
                                            disabled={this.state.isDisabled}
                                            onClick={(event) =>
                                                this.toggleModal(event, "saveModalOpened")
                                            }
                                            className="btn btn-sm btn-primary"
                                        >
                                            Guardar Oferta
                                        </Button>

                                    </Col>
                                </Row>




                            </CardBody>
                        </Card>


                        <Card className="col m-3  p-2 bg-light d-flex justify-content-end">
                            <CardBody>

                                <FormGroup>

                                    <Button id="gpsProptooltip" color="primary" size="m" type="button" className="btn-icon btn-sm btn-circle"
                                        onClick={this.openModalComparativa}
                                    >
                                        <span className="btn-inner--icon">
                                            <i className="fas fa-file-invoice fa-2x" style={{ fontSize: 30 }}></i>
                                        </span>
                                        <span className="btn-inner--text">Comparativas Monoproducto</span>
                                        <UncontrolledTooltip delay={0} placement="top" target="gpsProptooltip">
                                            Comparativa de propuesta/factura a partir de un producto comercial
                                        </UncontrolledTooltip>
                                    </Button>
                                </FormGroup>

                            </CardBody>
                        </Card>
                        <Card className="col m-3  p-2 bg-light d-flex justify-content-end">
                            <CardBody>

                                <FormGroup>

                                    <Button id="glecturaProptooltip" color="primary" size="m" type="button" className="btn-icon btn-sm btn-circle"
                                        onClick={this.openModalMultiComparativa}
                                    >
                                        <span className="btn-inner--icon">
                                            <i className="fas fa-file-invoice fa-2x" style={{ fontSize: 30 }}></i>
                                        </span>
                                        <span className="btn-inner--text"> Comparativas Multiproducto</span>
                                        <UncontrolledTooltip delay={0} placement="top" target="glecturaProptooltip">
                                            Comparativa de propuesta/factura a partir de varios productos comerciales
                                        </UncontrolledTooltip>
                                    </Button>

                                </FormGroup>

                            </CardBody>
                        </Card>
                    </div>




                    <Row>
                        <div className="col m-2 d-flex justify-content-end">
                            <FormGroup>
                                <Input
                                    id="comparativaId"
                                    type="select"
                                    onChange={this.comparativeSelected}
                                    hidden={this.state.comparatives.length > 0 ? false : true}
                                >
                                    <option value="none">Cargar Comparativa</option>
                                    {this.state.comparatives.map((item: any, index) => (
                                        <option value={item.id} key={index}>
                                            {item.nombreOferta}
                                        </option>
                                    ))}
                                </Input>
                            </FormGroup>
                        </div>
                    </Row>

                    {this.state.alert}
                    <Row className="card-deck" style={showList}>
                        <Card className="mt-3 shadow">
                            <CardBody>
                                <CardTitle>Consumos</CardTitle>
                                <LoadingOverlay
                                    active={this.state.isLoadingConsumos}
                                    spinner
                                    text="Cargando..."
                                >

                                    <Row>
                                        <SipsTable
                                            checkBoxButton={true}
                                            hideViewMoreButton={true}
                                            items={this.state.sipsConsumos}
                                            fields={this.state.tableHeaders}
                                            selectedItem={this.selectedItems}
                                            expandableFields={false}
                                        ></SipsTable>
                                    </Row>
                                </LoadingOverlay>
                            </CardBody>
                        </Card>
                    </Row>
                    generateComparative


                    <Row className="card-deck" style={showComercialProducto}>
                        <ComercialProducto
                            sip={this.state.sip}
                            ubi={this.state.ubicacion}
                            producto={this.state.producto}
                            periods={this.state.periodos}
                            itemsConsumo={this.state.itemsSelected}
                            output={this.comparativeDataHandler}
                        ></ComercialProducto>
                    </Row>
                    <Row className="card-deck" style={showComercialMultiProducto}>
                        <ComercialMultiProducto
                            sip={this.state.sip}
                            ubi={this.state.ubicacion}
                            productoMulti={this.state.productoMulti}
                            periods={this.state.periodos}
                            itemsConsumo={this.state.itemsSelected}
                            output={this.comparativeDataHandler}
                        >
                        </ComercialMultiProducto>
                    </Row>
                </Container>

                {freeModal}

                <SaveModal
                    isOpen={this.state.saveModalOpened}
                    toggle={(event) => this.toggleModal(event, "saveModalOpened")}
                    onSave={this.onSave}
                    sip={this.state.sip}
                    ubi={this.state.ubicacion}
                    nombrePropuesta={this.state.nombrePropuesta}
                    companyId={this.state.companyID}
                    userId={this.state.userId}
                ></SaveModal>

            </>
        );
    }
}

export default withApollo(SipComparativeFree);
