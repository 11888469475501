import gql from "graphql-tag";


export const DATA_COMPANY_CONTRATO = gql`
query contratacionCompanyData($companyId: String!) {
	contratacionCompanyData(companyId: $companyId) {
		companyId
		totalContratos
		totalFechaContrato
		totalRenovados
		totalContratosGeneradoEnviado
		totalFirmaContrato
		totalFechaActivacion

	}
}
`;

export const DATA_CONTRATO_GENERAL_COMPANY = gql`
query contratacionInfoById($companyId: String!, $userId: String!, $datos: ContratacionInput!) {
	contratacionInfoById(companyId: $companyId, userId: $userId, datos: $datos) {
		idContrato
		idPropuesta
	  idCliente
		cliente
		CIF
		idTipoContrato
		cups
		fechaContrato
		esRenovacion
		existePropRenovacion
		fechaInicioContrato
		duracionContrato
		fechaVencimientoContrato
		fechaFirmaContrato
		fechaActivacion
		fechaFinContrato
		contratoGeneradoEnviado
		esBajaContrato
		fechaBajaContrato
		idTarifaPeaje
		idProducto
		nombreProducto
		idOfertaTipo
		ofertaTipo
		ofertaTipoId
		empleado

	}
}
`;


export const VIEW_CONTRATO = gql`
query contratoInfoById($companyId: String!, $userId: String!, $idContrato: String!) {
	contratoInfoById(companyId: $companyId, userId: $userId, idContrato: $idContrato) {
		idContrato
		idPropuesta
		userId
		companyId
		idCliente
		idTipoContrato
		cups,
		fechaContrato
		esRenovacion
		modRenovacion
		existePropRenovacion
		fechaInicioContrato
		duracionContrato
		fechaVencimientoContrato
		fechaFirmaContrato
		fechaActivacion
		fechaFinContrato
		referenciaContrato
		observacionesContrato
		idTipoSeguimiento
		contratoGeneradoEnviado
		esBajaContrato
		fechaBajaContrato
		justificacionBajaContrato
		rutaDocumento
		idTarifaPeaje
		PotenciaContratadaWP1
		PotenciaContratadaWP2
		PotenciaContratadaWP3
		PotenciaContratadaWP4
		PotenciaContratadaWP5
		PotenciaContratadaWP6
		importeAhorro
		porcentajeAhorro
		observacionCup
		consumoAnualCup
		caudalDiarioGas
		estadoCups
		idContratoComercializadora
		estadoSinComercialiazdora
		idComercializadora
		nombreComercializadora
		idProducto
		nombreProducto
		precioTEP1
		precioTEP2
		precioTEP3
		precioTEP4
		precioTEP5
		precioTEP6
		precioTP1
		precioTP2
		precioTP3
		precioTP4
		precioTP5
		precioTP6
		costeOperativo
		costeOperativoPotencia
		descuentoTerminoEnergia
		descuentoTerminoPotencia
		idBanco
		descBanco
		iban
		createdAt
		updatedAt
		createdBy
		updatedBy
		idOfertaTipo
		ofertaTipo
		ofertaTipoId
		CIF
		idTipoTitular
		descIdTipoTitular
		nombreTitular
		apellido1Titular
		apellido2Titular
		codigoProvinciaTitular
		desProvinciaTitular
		codigoPostalTitular
		municipioTitular
		desMunicipioTitular
		tipoViaTitular
		viaTitular
		numFincaTitular
		portalTitular
		escaleraTitular
		pisoTitular
		puertaTitular
		esViviendaHabitual
		CNAE
		actividadCNAE
		codigoAutonomiaPS
		desAutonomiaPS
		nombreMunicipioPS
		codigoPostalPS
		nombreProvinciaPS
		tipoViaPS
		viaPS
		numFincaPS
		portalPS
		escaleraPS
		pisoPS
		puertaPS
		nombreAutonomiaPS
		datosContrato
		tarifaPeaje
		tipoPrecio
		tarifaPeajeGas
		nombreOferta
		fechaPropuesta
		idComercializadoraActualContrato
		nombreComercializadoraActualContrato
idRepresentante


	}
}
`;

export const VIEW_CONTRATO_FILTER = gql`
query contratoInfoByfilters($companyId: String!, $userId: String!, $cups: String!, $CIF: String!, $idContrato: String!) {
	contratoInfoByfilters(companyId: $companyId, userId: $userId, cups: $cups ,CIF: $CIF, idContrato: $idContrato) {
		idContrato
		idPropuesta
		userId
		companyId
		idCliente
		idTipoContrato
		cups,
		fechaContrato
		esRenovacion
		modRenovacion
		existePropRenovacion
		fechaInicioContrato
		duracionContrato
		fechaVencimientoContrato
		fechaFirmaContrato
		fechaActivacion
		fechaFinContrato
		referenciaContrato
		observacionesContrato
		idTipoSeguimiento
		contratoGeneradoEnviado
		esBajaContrato
		fechaBajaContrato
		justificacionBajaContrato
		rutaDocumento
		idTarifaPeaje
		PotenciaContratadaWP1
		PotenciaContratadaWP2
		PotenciaContratadaWP3
		PotenciaContratadaWP4
		PotenciaContratadaWP5
		PotenciaContratadaWP6
		importeAhorro
		porcentajeAhorro
		observacionCup
		consumoAnualCup
		caudalDiarioGas
		estadoCups
		idContratoComercializadora
		estadoSinComercialiazdora
		idComercializadora
		nombreComercializadora
		idProducto
		nombreProducto
		precioTEP1
		precioTEP2
		precioTEP3
		precioTEP4
		precioTEP5
		precioTEP6
		precioTP1
		precioTP2
		precioTP3
		precioTP4
		precioTP5
		precioTP6
		costeOperativo
		costeOperativoPotencia
		descuentoTerminoEnergia
		descuentoTerminoPotencia
		idBanco
		descBanco
		iban
		createdAt
		updatedAt
		createdBy
		updatedBy
		idOfertaTipo
		ofertaTipo
		ofertaTipoId
		CIF
		idTipoTitular
		descIdTipoTitular
		nombreTitular
		apellido1Titular
		apellido2Titular
		codigoProvinciaTitular
		desProvinciaTitular
		codigoPostalTitular
		municipioTitular
		desMunicipioTitular
		tipoViaTitular
		viaTitular
		numFincaTitular
		portalTitular
		escaleraTitular
		pisoTitular
		puertaTitular
		esViviendaHabitual
		CNAE
		actividadCNAE
		codigoAutonomiaPS
		desAutonomiaPS
		nombreMunicipioPS
		codigoPostalPS
		nombreProvinciaPS
		tipoViaPS
		viaPS
		numFincaPS
		portalPS
		escaleraPS
		pisoPS
		puertaPS
		nombreAutonomiaPS
		datosContrato
		tarifaPeaje
		tipoPrecio
		tarifaPeajeGas
		nombreOferta
		fechaPropuesta
		idComercializadoraActualContrato
		nombreComercializadoraActualContrato
idRepresentante


	}
}
`;

export const UPDATE_VIEW_CONTRATO = gql`
query updateContratoInfoById($companyId: String!, $userId: String!, $idContrato: String!, $fechaFirmaContrato:String!, $fechaVencimientoContrato: String!, $fechaActivacion: String! ) {
	updateContratoInfoById(companyId: $companyId, userId: $userId, idContrato: $idContrato, fechaFirmaContrato: $fechaFirmaContrato, fechaVencimientoContrato: $fechaVencimientoContrato, fechaActivacion: $fechaActivacion) {
idContrato
idPropuesta
userId
companyId
idCliente
idTipoContrato
cups
fechaContrato
esRenovacion
modRenovacion
existePropRenovacion
fechaInicioContrato
duracionContrato
fechaVencimientoContrato
fechaFirmaContrato
fechaActivacion
fechaFinContrato
referenciaContrato
observacionesContrato
idTipoSeguimiento
contratoGeneradoEnviado
esBajaContrato
fechaBajaContrato
justificacionBajaContrato
rutaDocumento
idTarifaPeaje
PotenciaContratadaWP1
PotenciaContratadaWP2
PotenciaContratadaWP3
PotenciaContratadaWP4
PotenciaContratadaWP5
PotenciaContratadaWP6
importeAhorro
porcentajeAhorro
observacionCup
consumoAnualCup
caudalDiarioGas
estadoCups
idContratoComercializadora
estadoSinComercialiazdora
idComercializadora
nombreComercializadora
idProducto
nombreProducto
precioTEP1
precioTEP2
precioTEP3
precioTEP4
precioTEP5
precioTEP6
precioTP1
precioTP2
precioTP3
precioTP4
precioTP5
precioTP6
costeOperativo
costeOperativoPotencia
descuentoTerminoEnergia
descuentoTerminoPotencia
idBanco
descBanco
iban
createdAt
updatedAt
createdBy
updatedBy
idOfertaTipo
ofertaTipo
ofertaTipoId


	}
}
`;




export const DATA_ULTIMOS_CONTRATOS_RESUMEN = gql`
query contratacionUltimas($companyId: String!, $userId: String!) {
	contratacionUltimas(companyId: $companyId, userId: $userId) {
		idContrato
		idPropuesta
		cliente
		CIF
		idTipoContrato
		cups
		fechaContrato
		existePropRenovacion
		esRenovacion
		existePropRenovacion
		fechaInicioContrato
		duracionContrato
		fechaVencimientoContrato
	fechaFirmaContrato
		fechaActivacion
		fechaFinContrato
		contratoGeneradoEnviado
		empleado
	}
}
`;
export const CHART_CONTRATO_TOTAL = gql`
query totalContratosTrimestre($time: String!, $userId: String!, $companyId: String!, $userGroupId: String!) {
    totalContratosTrimestre(time: $time, userId: $userId, companyId: $companyId, userGroupId: $userGroupId) {
	    label: numeroMes
        data: totalContratos
        anio
    }
}
`;

export const CHART_CONTRATO_ACEPTADOS = gql`
query aceptadosContratosTrimestre($time: String!, $userId: String!, $companyId: String!, $userGroupId: String!) {
    aceptadosContratosTrimestre(time: $time, userId: $userId, companyId: $companyId, userGroupId: $userGroupId) {
	    mes: numeroMes
        totalRenovados: totalRenovados
        anio
				totalNuevos: totalNuevos
    }
}
`


//CONSULTAS PARA REPRESENTANTE DE UNA SOCIEDAD


export const VIEW_REPRE_FILTER = gql`
query representanteInfoByfilters($companyId: String!, $userId: String!, $nombre: String!, $CIF: String!, $apellidos: String!) {
	representanteInfoByfilters(companyId: $companyId, userId: $userId, nombre: $nombre ,CIF: $CIF, apellidos: $apellidos) {
		idRepresentanteInterno
		idRepresentante
		nombreRepresentante
		apellido1Representante
		apellido2Representante
		CIFRepre
		CargoRepre
		telefonoRepresentante
		correoRepresentante
		companyIdRepresentante
		fechaAltaRepre
        fechaBajaRepre
		codigoAutonomiaRepre
		codigoPostalRepre
		codigoProvinciaRepre
		desAutonomiaRepre
		desMunicipioRepre
		desProvinciaRepre
		escaleraRepre
		municipioRepre
		numFincaRepre
		pisoRepre
		portalRepre
		puertaRepre
		tipoViaRepre
		viaRepre


	}
}
`;



export const DATA_REPRE_CONTRATO = gql`
query representanteData($idRepresentante: String!) {
	representanteData(idRepresentante: $idRepresentante) {
		idRepresentanteInterno
		idRepresentante
		nombreRepresentante
		apellido1Representante
		apellido2Representante
		CIFRepre
		CargoRepre
		telefonoRepresentante
		correoRepresentante
		companyIdRepresentante
		fechaAltaRepre
        fechaBajaRepre
		codigoAutonomiaRepre
		codigoPostalRepre
		codigoProvinciaRepre
		desAutonomiaRepre
		desMunicipioRepre
		desProvinciaRepre
		escaleraRepre
		municipioRepre
		numFincaRepre
		pisoRepre
		portalRepre
		puertaRepre
		tipoViaRepre
		viaRepre

	}
}
`;

export const UPDATE_REPRE_CONTRATO = gql`
query updateRepresentanteData($idRepresentanteInterno: Float!, $idRepresentante: String!, $idContrato: String!, $nombreRepresentante: String!, $apellido1Representante:String!, $apellido2Representante: String!, $CIFRepre: String!, $CargoRepre: String!, $telefonoRepresentante: String!, $correoRepresentante: String!, $companyIdRepresentante: String!, $CIFEmpresa: String!, $fechaAltaRepre:String!, $fechaBajaRepre:String!, $codigoAutonomiaRepre:String!, $codigoPostalRepre:String!,  $codigoProvinciaRepre:String!,  $desAutonomiaRepre:String!, $desMunicipioRepre:String!, $desProvinciaRepre:String!, $escaleraRepre:String!, $municipioRepre:String!, $numFincaRepre:String!, $pisoRepre:String!, $portalRepre:String!,$puertaRepre:String!,$tipoViaRepre:String!,$viaRepre:String!) {
	updateRepresentanteData(idRepresentanteInterno: $idRepresentanteInterno, idRepresentante: $idRepresentante, idContrato: $idContrato, nombreRepresentante: $nombreRepresentante, apellido1Representante: $apellido1Representante, apellido2Representante: $apellido2Representante, CIFRepre: $CIFRepre, CargoRepre: $CargoRepre, telefonoRepresentante: $telefonoRepresentante, correoRepresentante: $correoRepresentante, companyIdRepresentante: $companyIdRepresentante, CIFEmpresa: $CIFEmpresa, fechaAltaRepre: $fechaAltaRepre, fechaBajaRepre: $fechaBajaRepre, codigoAutonomiaRepre : $codigoAutonomiaRepre,codigoPostalRepre: $codigoPostalRepre, codigoProvinciaRepre: $codigoProvinciaRepre, desAutonomiaRepre: $desAutonomiaRepre,desMunicipioRepre: $desMunicipioRepre,desProvinciaRepre: $desProvinciaRepre,escaleraRepre: $escaleraRepre,municipioRepre: $municipioRepre,numFincaRepre: $numFincaRepre,pisoRepre: $pisoRepre,portalRepre: $portalRepre,puertaRepre: $puertaRepre,tipoViaRepre: $tipoViaRepre, viaRepre: $viaRepre) {
		idRepresentanteInterno
		idRepresentante
		nombreRepresentante
		apellido1Representante
		apellido2Representante
		CIFRepre
		CargoRepre
		telefonoRepresentante
		correoRepresentante
		companyIdRepresentante
		fechaAltaRepre
		fechaBajaRepre
		codigoAutonomiaRepre
		codigoPostalRepre
		codigoProvinciaRepre
		desAutonomiaRepre
		desMunicipioRepre
		desProvinciaRepre
		escaleraRepre
		municipioRepre
		numFincaRepre
		pisoRepre
		portalRepre
		puertaRepre
		tipoViaRepre
		viaRepre



	}
}
`;

export const CREATE_REPRE_CONTRATO = gql`
query createRepresentanteData($idContrato: String!, $nombreRepresentante: String!, $apellido1Representante:String!, $apellido2Representante: String!, $CIFRepre: String!, $CargoRepre: String!, $telefonoRepresentante: String!, $correoRepresentante: String!, $companyIdRepresentante: String!, $CIFEmpresa: String!,$fechaAltaRepre:String!, $codigoAutonomiaRepre:String!, $codigoPostalRepre:String!,  $codigoProvinciaRepre:String!,  $desAutonomiaRepre:String!, $desMunicipioRepre:String!, $desProvinciaRepre:String!, $escaleraRepre:String!, $municipioRepre:String!, $numFincaRepre:String!, $pisoRepre:String!, $portalRepre:String!,$puertaRepre:String!,$tipoViaRepre:String!,$viaRepre:String!  ) {
	createRepresentanteData(idContrato: $idContrato, nombreRepresentante: $nombreRepresentante, apellido1Representante: $apellido1Representante, apellido2Representante: $apellido2Representante, CIFRepre: $CIFRepre, CargoRepre: $CargoRepre, telefonoRepresentante: $telefonoRepresentante, correoRepresentante: $correoRepresentante, companyIdRepresentante: $companyIdRepresentante, CIFEmpresa: $CIFEmpresa, fechaAltaRepre: $fechaAltaRepre, codigoAutonomiaRepre : $codigoAutonomiaRepre,codigoPostalRepre: $codigoPostalRepre, codigoProvinciaRepre: $codigoProvinciaRepre, desAutonomiaRepre: $desAutonomiaRepre,desMunicipioRepre: $desMunicipioRepre,desProvinciaRepre: $desProvinciaRepre,escaleraRepre: $escaleraRepre,municipioRepre: $municipioRepre,numFincaRepre: $numFincaRepre,pisoRepre: $pisoRepre,portalRepre: $portalRepre,puertaRepre: $puertaRepre,tipoViaRepre: $tipoViaRepre, viaRepre: $viaRepre) {
		nombreRepresentante
		apellido1Representante
		apellido2Representante
		CIFRepre
		CargoRepre
		telefonoRepresentante
		correoRepresentante
		companyIdRepresentante
		idRepresentante
		idRepresentanteInterno
		fechaAltaRepre
		fechaBajaRepre
		codigoAutonomiaRepre
		codigoPostalRepre
		codigoProvinciaRepre
		desAutonomiaRepre
		desMunicipioRepre
		desProvinciaRepre
		escaleraRepre
		municipioRepre
		numFincaRepre
		pisoRepre
		portalRepre
		puertaRepre
		tipoViaRepre
		viaRepre

	}
}
`;


export const DATA_REPRE_CONTRATO_GENERAL = gql`
query representanteDataGeneral( $companyIdRepresentante: String!, $CIFEmpresa: String!) {
	representanteDataGeneral( companyIdRepresentante: $companyIdRepresentante, CIFEmpresa: $CIFEmpresa) {
		idRepresentanteInterno
		idRepresentante
		nombreRepresentante
		apellido1Representante
		apellido2Representante
		CIFRepre
		CargoRepre
		telefonoRepresentante
		correoRepresentante
		companyIdRepresentante
		fechaAltaRepre
        fechaBajaRepre
		codigoAutonomiaRepre
		codigoPostalRepre
		codigoProvinciaRepre
		desAutonomiaRepre
		desMunicipioRepre
		desProvinciaRepre
		escaleraRepre
		municipioRepre
		numFincaRepre
		pisoRepre
		portalRepre
		puertaRepre
		tipoViaRepre
		viaRepre

	}
}
`;


;

/*
export const DATA_ULTIMOS_CONTRATOS = gql`
query comparativasUltimas($companyId: String!, $userId: String!) {
	comparativasUltimas(companyId: $companyId, userId: $userId) {
		id
		nombreOferta
		esAprobada
		fechaPropuesta
		empleado
	}
}
`;









export const CLIENT_DATA = gql`
query getClientData($companyId: String!, $CIF: String!) {
	getClientData(companyId: $companyId, CIF: $CIF) {
idCliente
idTipoTitular
descIdTipoTitular
nombreTitular
apellido1Titular
apellido2Titular
codigoProvinciaTitular
desProvinciaTitular
codigoPostalTitular
municipioTitular
desMunicipioTitular
tipoViaTitular
viaTitular
numFincaTitular
portalTitular
escaleraTitular
pisoTitular
puertaTitular
esViviendaHabitual
CNAE
actividadCNAE
CIF
codigoAutonomiaPS
desAutonomiaPS
idBanco
descBanco
iban
idCompany
correoElectronico
telefonoFijo
telefonoMovil

	}
}
`;

export const DATA_COMPANY_COMPARATIVA_FULL = gql`
query comparativaFullCompanyData($companyId: String!, $datos: ComparativaLibreInput!) {
	comparativaFullCompanyData(companyId: $companyId, datos: $datos) {
		companyId
		totalContratos
		totalAceptados
		ultimaFecha
		comercial
	}
}
`;



export const DATA_ULTIMOS_CONTRATOS = gql`
query comparativasUltimas($companyId: String!, $userId: String!) {
	comparativasUltimas(companyId: $companyId, userId: $userId) {
		id
		nombreOferta
		esAprobada
		fechaPropuesta
		empleado
	}
}
`;



export const DATA_OFERTA = gql`
query comparativaInfoOferta($idOferta: String!, $userId: String!, $userGI: String!) {
	comparativaInfoOferta(idOferta: $idOferta, userId: $userId, userGI: $userGI) {
		id
		userId
		cups
		nombreOferta
		datosPreciosActual
		fechaPropuesta
		codigoPostalPS
		nombreMunicipioPS
		nombreProvinciaPS
		nombreAutonomiaPS
		esAprobada
		datosPropuesta
		correoContacto
	}
}
`;

export const GET_DATOS_PROPUESTA = gql`
query datosPropuesta($companyId: String!) {
	datosPropuesta(companyId: $companyId) {
		datosPropuesta
	}
}
`;

export const CHART_TOTAL = gql`
query totalTrimestre($time: String!, $userId: String!, $companyId: String!) {
    totalTrimestre(time: $time, userId: $userId, companyId: $companyId) {
	    label: numeroMes
        data: totalContratos
        anio
    }
}
`;

export const CHART_ACEPTADOS = gql`
query ($time: String!, $userId: String!, $companyId: String!) {
    aceptadosTrimestre(time: $time, userId: $userId, companyId: $companyId) {
	    mes: numeroMes
        totalAceptados: totalAceptados
        anio
				totalEnEspera: totalEnEspera
    }
}
`;

export const CHART_ACEPTADOS_BIS = gql`
query ($time: String!, $userId: String!, $companyId: String!) {
    aceptadosTrimestre(time: $time, userId: $userId, companyId: $companyId) {
	    label: numeroMes
        data: totalAceptados
        anio
    }

    esperaTrimestre(time: $time, userId: $userId, companyId: $companyId) {
	    label: numeroMes
        data: totalEspera
        anio
    }
}
`;


export const CHART_OFERTA_VENDIDA = gql`
query ofertaVendida($time: String!, $companyId: String!) {
    ofertaVendida(time: $time, companyId: $companyId) {
	    label: nombreOferta
        data: numeroAprobados
    }
}
`;

export const CHART_OFERTA_PROVINCIA = gql`
query ofertaProvincia($time: String!, $companyId: String!) {
    ofertaProvincia(time: $time, companyId: $companyId) {
	    label: nombreProvinciaPS
        data: totalContratos
    }
}
`;

export const GET_MEDIA_COMERCIALIZADORA = gql`
query mediaComercializadora($companyId : String!, $marketer: String!, $time: String!) {
    mediaComercializadora(companyId : $companyId, marketer: $marketer, time: $time) {
	    label: nombreComercializadora
        data: mediaPrecio
    }
}
`;

export const GET_MEDIA_PROPUESTAS = gql`
query mediaPropuestas($companyId: String!, $time: String!) {
    mediaPropuestas(companyId: $companyId, time: $time) {
	    label: numeroMes
        data: mediaPrecio
    }
}
`;

export const GET_MEDIA_COMERCIAL = gql`
query mediaComercial($companyId: String!, $time: String!, $comercial: String!) {
    mediaComercial(companyId: $companyId, time: $time, comercial: $comercial) {
	    label: comercial
        data: mediaPrecio
    }
}
`;

export const GET_COMERCIALIZADORA_PROVINCIA = gql`
query comercializadoraProvincia($companyId: String!, $time: String!) {
    comercializadoraProvincia(companyId: $companyId, time: $time) {
	    label: nombreProvinciaPS
        data: mediaPrecio
        nombreComercializadora
    }
}
`;

export const GET_PROVINCIA_COMERCIALIZADORA = gql`
query provinciaComercializadora($companyId: String!, $time: String!) {
    provinciaComercializadora(companyId: $companyId, time: $time) {
	    label: nombreComercializadora
        data: mediaPrecio
        nombreProvinciaPS
    }
}
`;

// **************************************************************************************************

export const GET_AVERAGE_P20A = gql`
query ($companyId: String!, $codeATR: String!, $time: String!) {
    periodo1(companyId: $companyId, codeATR: $codeATR, time: $time) {
	    label: nombreComercializadora
        data: mediaPrecio
    }
}
`;

export const GET_AVERAGE_P30A = gql`
query ($companyId: String!, $codeATR: String!, $time: String!) {
    periodo1(companyId: $companyId, codeATR: $codeATR, time: $time) {
	    label: nombreComercializadora
        data: mediaPrecio
    }

    periodo2(companyId: $companyId, codeATR: $codeATR, time: $time) {
	    label: nombreComercializadora
        data: mediaPrecio
    }

    periodo3(companyId: $companyId, codeATR: $codeATR, time: $time) {
	    label: nombreComercializadora
        data: mediaPrecio
    }
}
`;

export const GET_AVERAGE_P21A = gql`
query mediaP21A($companyId: String!, $codeATR: String!, $time: String!) {
    periodo1(companyId: $companyId, codeATR: $codeATR, time: $time) {
	    label: nombreComercializadora
        data: mediaPrecio
    }
}
`;

export const GET_AVERAGE_P20DHS = gql`
query ($companyId: String!, $codeATR: String!, $time: String!) {
    periodo1(companyId: $companyId, codeATR: $codeATR, time: $time) {
	    label: nombreComercializadora
        data: mediaPrecio
    }
    periodo2(companyId: $companyId, codeATR: $codeATR, time: $time) {
	    label: nombreComercializadora
        data: mediaPrecio
    }
    periodo3(companyId: $companyId, codeATR: $codeATR, time: $time) {
	    label: nombreComercializadora
        data: mediaPrecio
    }
}
`;

export const GET_AVERAGE_P21DHS = gql`
query ($companyId: String!, $codeATR: String!, $time: String!) {
    periodo1(companyId: $companyId, codeATR: $codeATR, time: $time) {
	    label: nombreComercializadora
        data: mediaPrecio
    }
    periodo2(companyId: $companyId, codeATR: $codeATR, time: $time) {
	    label: nombreComercializadora
        data: mediaPrecio
    }
    periodo3(companyId: $companyId, codeATR: $codeATR, time: $time) {
	    label: nombreComercializadora
        data: mediaPrecio
    }
}
`;

export const GET_AVERAGE_P31A = gql`
query ($companyId: String!, $codeATR: String!, $time: String!) {
    periodo1(companyId: $companyId, codeATR: $codeATR, time: $time) {
	    label: nombreComercializadora
        data: mediaPrecio
    }
    periodo2(companyId: $companyId, codeATR: $codeATR, time: $time) {
	    label: nombreComercializadora
        data: mediaPrecio
    }
    periodo3(companyId: $companyId, codeATR: $codeATR, time: $time) {
	    label: nombreComercializadora
        data: mediaPrecio
    }
}
`;

export const GET_AVERAGE_P20DHA = gql`
query ($companyId: String!, $codeATR: String!, $time: String!) {
    periodo1(companyId: $companyId, codeATR: $codeATR, time: $time) {
	    label: nombreComercializadora
        data: mediaPrecio
    }
    periodo2(companyId: $companyId, codeATR: $codeATR, time: $time) {
	    label: nombreComercializadora
        data: mediaPrecio
    }
}
`;

export const GET_AVERAGE_P21DHA = gql`
query ($companyId: String!, $codeATR: String!, $time: String!) {
    periodo1(companyId: $companyId, codeATR: $codeATR, time: $time) {
	    label: nombreComercializadora
        data: mediaPrecio
    }
    periodo2(companyId: $companyId, codeATR: $codeATR, time: $time) {
	    label: nombreComercializadora
        data: mediaPrecio
    }
}
`;
*/
