import gql from 'graphql-tag';

export const GET_COMERCIAL_DETAIL = gql`
query{
    getComercialDetail{
      supplier_id
      num_sites
      kwhanual
      supplier_name
      supplier_code_ree
      persona_fisica_total_sites
      persona_fisica_sumkwhanual
      persona_juridica_total_sites
      persona_juridica_sumkwhanual
    }
  }
  `