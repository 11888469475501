import React from "react";
import { Input } from "reactstrap";

import {
  LIST_DEALERSGAS,
  LIST_RATESGAS,
  LIST_AUTONOMOUS_COMMUNITIES,
//  LIST_MARKETERS,
  LIST_LOCALITIESGAS,
  LIST_PROVINCESGAS,
//  LIST_USER,
//  LIST_USERS
} from "./SipsGasOption.graphql";
import { Query } from "react-apollo";

const queries = {
  LIST_DEALERSGAS: {
    query: LIST_DEALERSGAS,
    name: "listDealersGas",
    key: "Id",
    value: "Empresa",
  },
  LIST_RATESGAS: {
    query: LIST_RATESGAS,
    name: "listRatesGas",
    key: "Id",
    value: "Descripcion",
  },
  LIST_AUTONOMOUS_COMMUNITIES: {
    query: LIST_AUTONOMOUS_COMMUNITIES,
    name: "listAutonomousCommunities",
    key: "autonomia_id",
    value: "nombre",
  },
  LIST_LOCALITIESGAS: {
    query: LIST_LOCALITIESGAS,
    name: "listLocalitiesGas",
    key: "Cmunicipio",
    value: "Nombre",
  },
  LIST_PROVINCESGAS: {
    query: LIST_PROVINCESGAS,
    name: "listProvincesGas",
    key: "provinciaId",
    value: "nombre",
  },
  /*
  LIST_USERS: {
    query: LIST_USERS,
    name: "listUsers",
    key: "id",
    value: "first_name",

  },
  LIST_USER: {
    query: LIST_USER,
    name: "listUser",
    key: "id",
    value: "first_name",
  },*/
};

interface SipsGasOptionInterface
  extends React.InputHTMLAttributes<HTMLInputElement> {
  query: string;
  invalid?: boolean;
}

class SipsGasOption extends React.Component<SipsGasOptionInterface> {
  render() {
    const query = queries[this.props.query];
    return (
      <Input
        {...this.props}
        type="select"
        placeholder={
          this.props.placeholder ? this.props.placeholder : "Selecciona..."
        }
        invalid={this.props.invalid ? this.props.invalid : false}
      >
        <Query query={query.query}>
          {
          ((result:any) => {

           const { loading, error, data } = result;
            if (loading) return <option>Loading…</option>;
            if (error) return <option>Error :(</option>;

            let c = 1;
            const ret = data[query.name].map((item) => {
              c++;
              return (
                <option key={c} value={item[query.key]}>
                  {item[query.value]}
                </option>
              );
            });
            ret.unshift(
              <option value="0" key={0}>
                {this.props.placeholder
                  ? this.props.placeholder
                  : "Seleccionar..."}
              </option>
            );
            return ret;
          })}
        </Query>



      </Input>
    );
  }
}

export default SipsGasOption;
