
/*eslint-disable*/
import React from "react";
import LoadingOverlay from "react-loading-overlay";

// reactstrap components
import { Card, CardBody, CardTitle, Col, ListGroup, ListGroupItem, Row } from "reactstrap";


interface RateInformationInterface {
  isLoading: boolean,
  cardClass?: string,
  sip: {};
}
class RateInformation extends React.Component<RateInformationInterface> {
  constructor(props) {
    super(props);
  }

  renderCircle(top, bottom) {
    return <div className="btn rounded-circle" key={top}>
      <span className="btn-inner">
        {top}
      </span>
      <span className="btn-inner">
        {bottom} Kw
      </span>
    </div>;
  }

  renderCircleP(top, bottom) {
    return <div className="btn rounded-circle-p" key={top}>
      <span className="btn-inner-p">
        {top}
      </span>
      <span className="btn-inner-p">
        {bottom} Kw
      </span>
    </div>;
  }

  renderCircles() {
    const sip: any = this.props.sip;
    const numeroPeriodos = parseInt(sip.NumeroPeriodos);
    const consumptions: any = [];
    for (var i = 1; i <= numeroPeriodos; i++) {
      let consumoEnergiaActiva = `ConsumoAnualActivaP${i}`;
      let consumo = parseFloat(sip[consumoEnergiaActiva]);

      switch(sip.codigoTarifaATREnVigor) {
        case '003':
        case '007':
        case '008':
        case '011':
          consumoEnergiaActiva = `ConsumoAnualActivaP${i+3}`;
          consumo += parseFloat(sip[consumoEnergiaActiva]);
          break;
        case '004':
        case '006':
          if (i === 2) {
            //i++;
            consumoEnergiaActiva = `ConsumoAnualActivaP${i}`;
            consumo = parseFloat(sip[consumoEnergiaActiva]);
          }
          break;
      }
      const element = this.renderCircle(`P${i}`, consumo);
      consumptions.push(element);
    }

    return consumptions;
  }

  renderCirclesPot() {
    const sip: any = this.props.sip;
    const numeroPeriodos = parseInt(sip.NumeroPeriodos);
    const consumptionsPot: any = [];
    for (var i = 1; i <= numeroPeriodos; i++) {
      let consumoPotenciaActiva = `potenciasContratadasEnWP${i}`;
      let consumop = parseFloat(sip[consumoPotenciaActiva]);

      switch(sip.codigoTarifaATREnVigor) {
        case '001':
              break;
        case '005':
              break;
        case '003':
        consumoPotenciaActiva = `potenciasContratadasEnWP${i+3}`;
        consumop += parseFloat(sip[consumoPotenciaActiva]);
        break;
        case '007':
              break;
        case '008':
break;
        case '011':
          consumoPotenciaActiva = `potenciasContratadasEnWP${i+3}`;
          consumop += parseFloat(sip[consumoPotenciaActiva]);
          break;
        case '004':
break;
        case '006':
break;
      }
      const element2 = this.renderCircleP(`Pot. P${i} `, consumop);
      //const element2 = this.renderCirclesPot();
      consumptionsPot.push(element2);
    }

    return consumptionsPot;
  }

  render() {
    const sip: any = this.props.sip;

    const consumptions: any = this.renderCircles();
    const consumptionsPot: any = this.renderCirclesPot();

    return (
      <Card id="rateInformation" className={this.props.cardClass}>
        <CardBody>
          <CardTitle>Información de la tarifa</CardTitle>
          <LoadingOverlay
            active={this.props.isLoading}
            spinner
            text="Cargando...">
            <Row>
              <Col md="12">
                <ListGroup>
                  <ListGroupItem><strong>Distribuidora:</strong> {sip.empresaDistribuidora}</ListGroupItem>
                  <ListGroupItem><strong>Comercializadora:</strong> {sip.comercializadora}</ListGroupItem>
                  <ListGroupItem><strong>Consumo Anual:</strong> {sip.kWhAnual} Kw</ListGroupItem>
                  <ListGroupItem><strong>Tarifa actual:</strong> {sip.tarifaATR}</ListGroupItem>
                  <div id="consumptions">
                    {consumptions}
                  </div>
                </ListGroup>
                <div id="consumptionsPot">
                  {consumptionsPot}
                </div>
              </Col>
            </Row>
          </LoadingOverlay>
        </CardBody>
      </Card>
    );
  }
}

export default RateInformation;
