import React from "react";
import { Card, Col, CardTitle, CardBody, Form, Input, Row, Table, FormGroup, Label } from "reactstrap";
//import { Bar } from '@reactchartjs/react-chart.js';
//import { withApollo } from "react-apollo";
import client from "../../../api/client";
import { Bar, HorizontalBar } from "react-chartjs-2";
import SipsTable from "../../../components/Sips/SipsTable";
import { LIST_COMERCIAL_SERVICIOS } from "../../../queries/comercial-producto/comercial-producto";
import moment from 'moment';

const comercialProductoFields = {
    periodo: "Período",
    precioEnergia: "Precio Término Energía €/kWh",
    precioPotencia: "Precio Término Fijo €/mes",
    coeficiente: "Coeficiente Cálculo"

};

const comercialProductoExpandableFields = {};


interface IComercialProductoProps {
    sip: any,
    periods: number[],
    producto: {
        active: boolean,
        productoSeleccionado: any,
        costeOperativo: number,
        mibGasPrice: number
    };
    itemsConsumo: any[],
    output: any,
    //ubi: any
}

interface IComercialProductoState {
    sip: any,
    ubi: any,
    periods: number[],

    active: boolean
    producto: any
    adFincas: boolean,
    agencia: boolean,
    comercial: boolean,
    comercialServicios: any[],
    costeOperativo: number;
    rates1XP: any[],
    rates2X: any[],
    rates3X: any[],
    rates2XP: any[],
    rates3XP: any[],
    rates6XP: any[],
    rates1XE: any[],
    rates2XE: any[],
    rates3XE: any[],
    rates6XE: any[],
    ratesMonth1: any[],
    ratesMonth2: any[],
    ratesMonth3: any[],
    ratesMonth4: any[],

    itemsTabla: any[];

    idservicio: any,
    idComercializadora: any,
    nombreComercializadora: any,
    nombreServicio: any,
    precioServicio: any,
    mibGasPrice: number,

    formProducto: {
        sumPotencia: number,
        sumEnergia: number,
        iePercent: number,
        impuestoElectrico: number,
        peajeSalidaTerminoVariableConsumo: number,
        peajeSalidaTerminoFijoCliente: number,
        peajeSalidaTransporteTerminoVariableConsumo: number,
        peajeSalidaTransporteTerminoFijoCliente: number,
        otrosCostesRegasificacionTerminoFijoCliente: number,
        cargoTerminoFijoCliente: number,
        cuotaGts: number,
        tasaCnmc:number,
        factork:number,
        iva: number;
        bi: number;
        total: number;
        serviciosAdicionalesMonth: number;
        serviciosAdicionales: number;
        alquilerEquipoMedidaMonth: number;
        alquilerEquipoMedida: number;
        codigoATR: any;
        numDaysNew: number;
        numMonthNew: number;
        fechaDesdeConsumo: any;
        fechaHastaConsumo: any;
        datasetProducto: number[],
        EnergiaActiva: any[],
        Potencia: any[],
        potenciaP1: number,
        potenciaP2: number,
        energiaActivaP1: number,
        energiaActivaP2: number,
        idServicio: any,
        idComercializadora: any,
        //nombreComercializadora: any,
        nombreServicio: any,
        precioServicio: number,
        descuentoMonth: number,
        descuento: number,
        comisionContratoFija: number,
        comisionContratoConsumoAgente1: number,
        comisionContratoConsumoAgente2: number,
        comisionContratoConsumoAdmin: number

    },
    formActual: {

        precioTP1: number,
        precioTP2: number,


        precioTEP1: number,
        precioTEP2: number,

        serviciosAdicionalesMonth: number;
        serviciosAdicionales: number;
        alquilerEquipoMedidaMonth: number;
        alquilerEquipoMedida: number;
        peajeSalidaTerminoVariableConsumo: number,
        peajeSalidaTerminoFijoCliente: number,
        peajeSalidaTransporteTerminoVariableConsumo: number,
        peajeSalidaTransporteTerminoFijoCliente: number,
        otrosCostesRegasificacionTerminoFijoCliente: number,
        cargoTerminoFijoCliente: number,
        cuotaGts: number,
        tasaCnmc:number,
        factork:number,
        codigoATR: any;

        numDaysNew: number;
        numMonthNew: number;
        fechaDesdeConsumo: any;
        fechaHastaConsumo: any;

        sumPotencia: number;
        sumEnergia: number;
        iePercent: number;
        impuestoElectrico: number;
        iva: number;
        bi: number;
        total: number;

        datasetActual: number[],
        datasetLeyenda: any[],
        descuentoMonth: number,
        descuento: number,

    },
    totalComparacion: {
        ahorroAnual: number,
        ahorroPercent: number,
        ahorroPeriodo: number,
        datosAhorro: number[]
    },
    itemsConsumo: any[];
}


export default class


    ComercialGasProducto extends React.Component<IComercialProductoProps, IComercialProductoState>
{
    public formActualRef: any = null;
    public formProductoRef: any = null;
    public state: IComercialProductoState = {
        formProducto: {
            descuento: 0,
            descuentoMonth: 0,
            serviciosAdicionalesMonth: 0,
            serviciosAdicionales: 0,
            alquilerEquipoMedidaMonth: 0,
            alquilerEquipoMedida: 0,
            peajeSalidaTerminoVariableConsumo: 0,
            peajeSalidaTerminoFijoCliente: 0,
            peajeSalidaTransporteTerminoVariableConsumo: 0,
            peajeSalidaTransporteTerminoFijoCliente: 0,
            otrosCostesRegasificacionTerminoFijoCliente: 0,
            cargoTerminoFijoCliente: 0,
            cuotaGts: 0,
            tasaCnmc:0,
            factork: 0,
            codigoATR: 0,


            sumPotencia: 0,
            sumEnergia: 0,
            iePercent: 0,
            impuestoElectrico: 0,
            iva: 0,
            bi: 0,
            total: 0,

            EnergiaActiva: [],
            Potencia: [],

            potenciaP1: 0,

            potenciaP2: 0,


            energiaActivaP1: 0,
            energiaActivaP2: 0,



            numDaysNew: 0,
            numMonthNew: 0,
            fechaDesdeConsumo: null,
            fechaHastaConsumo: null,



            datasetProducto: [],
            idServicio: null,
            idComercializadora: null,
            //    nombreComercializadora: null,
            nombreServicio: null,
            precioServicio: 0,
            comisionContratoFija: 0,
            comisionContratoConsumoAgente1: 0,
            comisionContratoConsumoAgente2: 0,
            comisionContratoConsumoAdmin: 0


        },
        formActual: {

            descuento: 0,
            descuentoMonth: 0,

            serviciosAdicionalesMonth: 0,
            serviciosAdicionales: 0,
            alquilerEquipoMedidaMonth: 0,
            alquilerEquipoMedida: 0,
            peajeSalidaTerminoVariableConsumo: 0,
            peajeSalidaTerminoFijoCliente: 0,
            peajeSalidaTransporteTerminoVariableConsumo: 0,
            peajeSalidaTransporteTerminoFijoCliente: 0,
            otrosCostesRegasificacionTerminoFijoCliente: 0,
            cargoTerminoFijoCliente: 0,
            cuotaGts: 0,
            tasaCnmc:0,
            factork: 0,
            codigoATR: 0,

            numDaysNew: 0,
            numMonthNew: 0,
            fechaDesdeConsumo: null,
            fechaHastaConsumo: null,

            sumPotencia: 0,
            sumEnergia: 0,
            iePercent: 0,
            impuestoElectrico: 0,
            iva: 0,
            bi: 0,
            total: 0,


            datasetActual: [],
            datasetLeyenda: [],

            precioTP1: 0,
            precioTP2: 0,

            precioTEP1: 0,
            precioTEP2: 0,

        },
        totalComparacion: {
            ahorroAnual: 0,
            ahorroPercent: 0,
            ahorroPeriodo: 0,
            datosAhorro: []
        },
        comercialServicios: [],
        rates2X: [
            '001',
            '004',
            '005',
            '006',
            '007',
            '008'
        ],
        rates3X: [
            '011',
            '003',
            '012',
            '013',
            '014',
            '015',
            '016'
        ],
        rates2XP: [
            '018',
        ],
        rates1XP: [
            '001',
            '004',
            '005',
            '006',
            '007',
            '008'
        ],
        rates3XP: [
            '011',
            '003'
        ],
        rates6XP: [
            '012',
            '013',
            '014',
            '015',
            '016',
            '019',
            '020',
            '021',
            '022',
            '023',
            '024',
            '025'
        ],
        rates1XE: [
            '001',
            '005'
        ],
        rates2XE: [
            '004',
            '006'
        ],
        rates3XE: [
            '011',
            '003',
            '007',
            '008',
            '018'
        ],
        rates6XE: [
            '012',
            '013',
            '014',
            '015',
            '016',
            '019',
            '020',
            '021',
            '022',
            '023',
            '024',
            '025'
        ],
        ratesMonth1: [1, 2, 7, 12],
        ratesMonth2: [3, 11],
        ratesMonth3: [4, 5, 10],
        ratesMonth4: [6, 8, 9],



        idservicio: null,
        idComercializadora: null,
        nombreComercializadora: null,
        nombreServicio: null,
        precioServicio: null,

        adFincas: false,
        agencia: true,
        comercial: false,

        active: false,
        sip: {},
        ubi: {},
        periods: [],
        itemsTabla: [],
        itemsConsumo: [],
        producto: {},
        costeOperativo: 0,
        mibGasPrice: 0
    };

    componentWillReceiveProps(newProps: IComercialProductoProps) {

        let inputState: any = { ...newProps };

        if (newProps.sip) {
            var state = Object.assign(this.state);
            this.setState(state);
        }


        if (newProps.producto.productoSeleccionado) {
            let newItemsTabla: any[] = [];
            delete inputState.producto;
            this.fetchItems()

            const producto = { ...newProps.producto.productoSeleccionado };

            const costeOperativo = newProps.producto.costeOperativo;
            let periodos: number[] = [];

            var periodosNuevos = 0;
            if (this.state.sip.codigoPeajeEnVigor == '35') {
                periodosNuevos = 2;
            } else { periodosNuevos = 1 };

            for (let i = 1; i <= periodosNuevos; i++) {
                let consumoAnual = `ConsumoAnualEnWhP${i}`
                if (parseFloat(this.state.sip[consumoAnual].replace(',','.').replace(' ','')) >= 0 && i <= periodosNuevos) {

                    let precioEnergiaField = `precioTE${i}`;
                    if (i < 2) {
                        var precioPotenciaField = `precioTFijo`;
                    } else {
                        var precioPotenciaField = ``;
                    }
                    let periodo = `P${i}`;
                    periodos.push(i);
                    var precioPotencia = producto[precioPotenciaField];
                    if (i === 1) {
                        precioPotencia = producto.precioTFijo;
                    }
                    else { precioPotencia = null };
                    var coeficiente = producto.factork;
                    let precioEnergia = producto[precioEnergiaField] + costeOperativo;
                    newItemsTabla.push({ periodo: periodo, precioPotencia: precioPotencia, precioEnergia: precioEnergia, coeficiente: coeficiente });
                }
            }

            inputState.producto = producto;
            inputState.itemsTabla = newItemsTabla;
            inputState.costeOperativo = costeOperativo;
            inputState.mibGasPrice = newProps.producto.mibGasPrice;
            inputState.active = newProps.producto.active;
            this.setState({
                ...this.state,
                ...inputState
            }, () => {
                this.renderFacturaProducto();
                this.renderFacturaActual();

            })
        }
        else {
            this.setState({
                ...this.state,
                ...inputState
            })
        }
    }

    componentDidMount() {

        this.formActualRef = React.createRef();
        this.formProductoRef = React.createRef();

        let adFincas = false
let agencia =  true
let comercial =  false

        const userFromStorage = localStorage.getItem("user");
        if (userFromStorage) {
          try {
            const parsed = JSON.parse(userFromStorage);

            adFincas = parsed.adFincas;
            agencia = parsed.agencia;
            comercial = parsed.comercial;


          } catch (e) {}
        }
        this.setState({ adFincas, agencia,comercial });



    }


    fetchItems = async () => {

        // aquí empieza la selección de servicios por Comercializadora

        const companyUser = await JSON.parse(await localStorage.getItem("user")!);
        var idComercializadora = this.state.producto.idComercializadora;
        if (idComercializadora === null) {
            idComercializadora = '0000'
        };


        await client.query({
            query: LIST_COMERCIAL_SERVICIOS,
            variables: { idComercializadora: idComercializadora },
        })
            .then((result) => {
                let data = result.data.comercialServicios;

                this.setState({ comercialServicios: data })
            });
    }




    onChangeFieldFormActual = async (event) => {
        let inputs: any = await this.onChangeField(event);
        if (inputs) {
            this.setState({
                formActual: {
                    ...this.state.formActual,
                    ...inputs
                }
            }, this.renderFacturaActual);
        }
    }

    onChangeFieldFormProducto = async (event) => {
        let inputs: any = await this.onChangeField(event);
        if (inputs) {
            this.setState({
                formProducto: {
                    ...this.state.formProducto,
                    ...inputs
                }
            }, this.renderFacturaProducto);
        }
    }

    onChangeFieldFormProductoServicio = async (event) => {
        let inputs: any = await this.onChangeField(event);
        let opcion = parseInt(inputs.idServicio)
        let idservicio = null;
        let idComercializadora = null;
        let nombreComercializadora = null;
        let nombreServicio = null;
        let precioServicio = null;

        /*
                idservicio: null,
                idComercializadora: null,
                nombreComercializadora: null,
                nombreServicio: null,
                precioServicio: 0,

                id: "09180001"
        idComercializadora: "0918"
        infoAdicional: null
        nombreComercializadora: "ALDRO ENERGIA Y SOLUCIONES S.L"
        nombreServicio: "Exprés24"
        precio: 5.9
        */

        this.state.comercialServicios.map(row => {

            let fila = parseInt(row['id']);

            if (fila === opcion) {
                idservicio = row['id'];
                idComercializadora = row['idComercializadora'];
                nombreComercializadora = row['nombreComercializadora'];
                nombreServicio = row['nombreServicio'];
                precioServicio = row['precio'];
            }
            return row;
        });


        {
            this.setState({
                idservicio: idservicio,
                idComercializadora: idComercializadora,
                nombreComercializadora: nombreComercializadora,
                nombreServicio: nombreServicio,
                precioServicio: precioServicio

            }, this.renderFacturaProducto);
        }

    }

    private calculateAhorro = (periodos: number[], facturaActual: any, facturaProducto: any) => {


        let totalFacturaActual = facturaActual.total;
        let totalFacturaProducto = facturaProducto.total;

        let ahorroPeriodo = this.roundNumber((totalFacturaActual - totalFacturaProducto));
        let ahorroPercent = this.roundNumber(ahorroPeriodo * 100 / totalFacturaActual);
        let datosAhorro = [0];
        datosAhorro.length = 0;
        datosAhorro[datosAhorro.length] = totalFacturaActual;
        datosAhorro[datosAhorro.length] = totalFacturaProducto;
        datosAhorro[datosAhorro.length] = ahorroPeriodo;

        return {
            ahorroAnual: ahorroPeriodo,
            ahorroPercent: isNaN(ahorroPercent) ? 0 : ahorroPercent,
            ahorroPeriodo: ahorroPeriodo,
            datosAhorro: datosAhorro
        }
    }
    private calculatePrecioProducto = (periodos: number[], producto: any, factura: any) => {
        let {
            serviciosAdicionales,
            serviciosAdicionalesMonth,
            alquilerEquipoMedidaMonth,
            alquilerEquipoMedida,
            peajeSalidaTerminoVariableConsumo,
            peajeSalidaTerminoFijoCliente,
            peajeSalidaTransporteTerminoVariableConsumo,
            peajeSalidaTransporteTerminoFijoCliente,
            otrosCostesRegasificacionTerminoFijoCliente,
            cargoTerminoFijoCliente,
            cuotaGts,
            tasaCnmc,
            factorKCoeficiente,
            codigoATR,
            sumPotencia,
            sumEnergia,
            iePercent,
            impuestoElectrico,
            descuento,
            descuentoMonth,
            iva,
            bi,
            total,
            numDaysNew,
            numMonthNew,
            fechaDesdeConsumo,
            fechaHastaConsumo,
            Potencia,
            idComercializadora,
            idServicio,
            //        nombreComercializadora ,
            nombreServicio,
            precioServicio,
            comisionContratoFija,
            comisionContratoConsumoAgente1,
            comisionContratoConsumoAgente2,
            comisionContratoConsumoAdmin

            //datasetProducto
        } = factura;

        const sip = this.state.sip;
        const ubi = this.state.ubi;
        let mibGasPrice = this.state.mibGasPrice;
        let consumoPotencia = {};
        let consumoEnergia = {};
        let precioPotencia = {};
        let precioEnergia = {};
        let factork = {};
        factorKCoeficiente = 0;
        //let numDays;

        fechaDesdeConsumo = null;
        fechaHastaConsumo = null;
        let potenciaP1 = 0;
        let potenciaP2 = 0;


        let totalEnergiaActiva = 0;

        let energiaActivaP1 = 0;
        let energiaActivaP2 = 0;


        var consumoEnergiaTotal = 0;
        let consumoCaudalMedioDia = 0;
        sumEnergia = 0;
        sumPotencia = 0;

        numDaysNew = 0;
        numMonthNew = 0;
        let monthLectura = 0;


    this.state.itemsConsumo.map(row => {

            if (fechaDesdeConsumo === null) {
                fechaDesdeConsumo = row['fechaInicioMesConsumo'];
                numDaysNew += Math.round(moment.duration(moment(row['fechaFinMesConsumo'], "DD-MM-YYYY").diff(moment(row['fechaInicioMesConsumo'], "DD-MM-YYYY"))).asDays());
                numMonthNew += (moment.duration(moment(row['fechaFinMesConsumo'], "DD-MM-YYYY").diff(moment(row['fechaInicioMesConsumo'], "DD-MM-YYYY"))).asMonths()).toFixed(2);
            } else if (moment(fechaDesdeConsumo, "DD-MM-YYYY") > moment(row['fechaInicioMesConsumo'], "DD-MM-YYYY")) {
                fechaDesdeConsumo = row['fechaInicioMesConsumo'];
                numDaysNew += Math.round(moment.duration(moment(row['fechaFinMesConsumo'], "DD-MM-YYYY").diff(moment(row['fechaInicioMesConsumo'], "DD-MM-YYYY"))).asDays());

                numMonthNew += (moment.duration(moment(row['fechaFinMesConsumo'], "DD-MM-YYYY").diff(moment(row['fechaInicioMesConsumo'], "DD-MM-YYYY"))).asMonths()).toFixed(2);
            }
            return row;
        });


        numMonthNew = numMonthNew.replace(/^0+/, '');

        this.state.itemsConsumo.map(row => {
            potenciaP1 += (Number(sip.potenciasContratadasEnWP1) || 0);
            potenciaP2 += (Number(sip.potenciasContratadasEnWP1) || 0);
            return row;
        });


        this.state.itemsConsumo.map(row => {
            if (sip.codigoPeajeEnVigor != '35') {
                energiaActivaP1 += this.roundNumber((Number(row['consumoEnWhP1']) || 0));

            } else {
                energiaActivaP1 += this.roundNumber((Number(row['consumoEnWhP1']) || 0));
                energiaActivaP2 += this.roundNumber((Number(row['consumoEnWhP2']) || 0));
            }
            return row;
        });



        this.state.itemsConsumo.map(row => {
            if (fechaHastaConsumo === null) {
                fechaHastaConsumo = row['fechaFinMesConsumo'];
            } else if (moment(fechaHastaConsumo, "DD-MM-YYYY") < moment(row['fechaFinMesConsumo'], "DD-MM-YYYY")) {
                fechaDesdeConsumo = row['fechaFinMesConsumo'];
            }

            return row;
        });


        periodos.map(periodo => {

            precioEnergia[`P${periodo}`] = 0;
            precioPotencia[`P${periodo}`] = 0;
            factork[`P${periodo}`] = 0;
            consumoPotencia[`P${periodo}`] = 0;
            consumoEnergia[`P${periodo}`] = 0;
            //potencia[`P${periodo}`] = 0;
            //numDays = 0;

            Object.keys(producto).map(key => {
                //Producto Energia
                if (key === `precioTE${periodo}`) {
                    precioEnergia[`P${periodo}`] = Number(producto[key]);

                }
                //Producto Potencia
                if (key === `precioTFijo`) {
                    //if(periodo > 1 && this.state.rates2X.includes(this.state.sip.codigoTarifaATREnVigor)){
                    //  precioPotencia[`P${periodo}`] = 0
                    //} else {
                    precioPotencia[`P${periodo}`] = Number(producto[key])
                }
                //}
                if (key === `factork`) {
                    //if(periodo > 1 && this.state.rates2X.includes(this.state.sip.codigoTarifaATREnVigor)){
                    //  precioPotencia[`P${periodo}`] = 0
                    //} else {
                    factork[`P${periodo}`] = Number(producto[key])
                }
                //}
                return key;
            });

            this.state.itemsConsumo.map(row => {

                consumoPotencia[`P${periodo}`] += Number(row[`potenciaDemandadaEnWP${periodo}`]) || 0;


                consumoEnergia[`P${periodo}`] += (Number(row[`consumoEnWhP${periodo}`]) || 0);
                consumoEnergiaTotal += (Number(row[`consumoEnWhP${periodo}`]) || 0);
                consumoCaudalMedioDia += (Number(row[`caudalMedioEnWhdia`]) / 1000 || 0);
                //numDays += moment(row['fechaFinMesConsumo'], "DD-MM-YYYY").daysInMonth();
                //numDays += moment.duration(moment(row['fechaFinMesConsumo'], "DD-MM-YYYY").diff(moment(row['fechaInicioMesConsumo'], "DD-MM-YYYY"))).asDays();

                return row;

            });

            if (precioEnergia[`P${periodo}`] === 0) {

                sumEnergia += (consumoEnergia[`P${periodo}`] * ((mibGasPrice/1000) + (factork[`P${periodo}`]-descuentoMonth)));
                factorKCoeficiente = factork[`P${periodo}`]-descuentoMonth
            } else {
                sumEnergia += (consumoEnergia[`P${periodo}`] * precioEnergia[`P${periodo}`]);
            }



            //sumEnergia += (consumoEnergia[`P${periodo}`] * precioEnergia[`P${periodo}`]);
            //sumEnergia += (consumoEnergia[`P${periodo}`] * factork[`P${periodo}`]);


            if (periodo === 1) {
                //const potenciaContratada = sip[`potenciasContratadasEnWP${periodo}`];(periodo)
                const potenciaContratada = potenciaP1;
                sumPotencia += (precioPotencia[`P${periodo}`]) * numMonthNew;
            }

            if (periodo === 2) {
                //const potenciaContratada = sip[`potenciasContratadasEnWP${periodo}`];(periodo)
                const potenciaContratada = potenciaP2;
                sumPotencia += (precioPotencia[`P${periodo}`]) * numMonthNew;
            }



            return periodo;
        });

        peajeSalidaTerminoVariableConsumo = 0;
        otrosCostesRegasificacionTerminoFijoCliente = 0;
        peajeSalidaTerminoFijoCliente = 0;
        peajeSalidaTransporteTerminoFijoCliente = 0;
        cargoTerminoFijoCliente = 0;

        if (sip.codigoTelemedida == "0") {
            // NO TIENE TELEMEDIDA
            if (sip.codigoPeajeEnVigor == "32") {
                peajeSalidaTerminoVariableConsumo = consumoEnergiaTotal * 0.016710
                peajeSalidaTerminoFijoCliente = (27.412410 / 365) * numDaysNew
                peajeSalidaTransporteTerminoFijoCliente = (3.103158 / 365) * numDaysNew
                cargoTerminoFijoCliente = (0.72 / 365) * numDaysNew
            }
            if ((sip.codigoPeajeEnVigor == "RL01" || sip.codigoPeajeEnVigor == "R1")) {
                peajeSalidaTerminoVariableConsumo = consumoEnergiaTotal * 0.016710
                peajeSalidaTerminoFijoCliente = (27.412410 / 365) * numDaysNew
                peajeSalidaTransporteTerminoFijoCliente = (3.103158 / 365) * numDaysNew
                cargoTerminoFijoCliente = (0.72 / 365) * numDaysNew
            }
            if ((sip.codigoPeajeEnVigor == "RL02" || sip.codigoPeajeEnVigor == "R2")) {
                peajeSalidaTerminoVariableConsumo = consumoEnergiaTotal * 0.013331
                peajeSalidaTerminoFijoCliente = (68.423783 / 365) * numDaysNew
                peajeSalidaTransporteTerminoFijoCliente = (10.721055 / 365) * numDaysNew
                cargoTerminoFijoCliente = (1.29 / 365) * numDaysNew
            }
            if ((sip.codigoPeajeEnVigor == "RL03" || sip.codigoPeajeEnVigor == "R3")) {
                peajeSalidaTerminoVariableConsumo = consumoEnergiaTotal * 0.010809
                peajeSalidaTerminoFijoCliente = (173.086523 / 365) * numDaysNew
                peajeSalidaTransporteTerminoFijoCliente = (27.686154 / 365) * numDaysNew
                cargoTerminoFijoCliente = (2.56 / 365) * numDaysNew
            }
            if ((sip.codigoPeajeEnVigor == "RL04" || sip.codigoPeajeEnVigor == "R4")) {
                peajeSalidaTerminoVariableConsumo = consumoEnergiaTotal * 0.012235
                peajeSalidaTerminoFijoCliente = (159.202402 / 365) * numDaysNew
                cargoTerminoFijoCliente = (12.42 / 365) * numDaysNew
            }
            if ((sip.codigoPeajeEnVigor == "RL05" || sip.codigoPeajeEnVigor == "R5")) {
                peajeSalidaTransporteTerminoFijoCliente = (760.326435 / 365) * numDaysNew
                cargoTerminoFijoCliente = (57.49 / 365) * numDaysNew
                if (sip.codigoPresion == "01") {
                    peajeSalidaTerminoVariableConsumo = consumoEnergiaTotal * 0.011621
                    peajeSalidaTerminoFijoCliente = (544.731766 / 365) * numDaysNew
                } else {
                    peajeSalidaTerminoVariableConsumo = consumoEnergiaTotal * 0.003035
                    peajeSalidaTerminoFijoCliente = (2787.205393 / 365) * numDaysNew
                }
            }
            if ((sip.codigoPeajeEnVigor == "RL06" || sip.codigoPeajeEnVigor == "R6")) {
                peajeSalidaTransporteTerminoFijoCliente = (2923.990565 / 365) * numDaysNew
                cargoTerminoFijoCliente = (219.43 / 365) * numDaysNew
                if (sip.codigoPresion == "01") {
                    peajeSalidaTerminoVariableConsumo = consumoEnergiaTotal * 0.005037
                    peajeSalidaTerminoFijoCliente = (10934.440729 / 365) * numDaysNew
                } else {
                    peajeSalidaTerminoVariableConsumo = consumoEnergiaTotal * 0.001957
                    peajeSalidaTerminoFijoCliente = (7498.928757 / 365) * numDaysNew
                }
            }

            otrosCostesRegasificacionTerminoFijoCliente = (12.929063 / 365) * numDaysNew

        } else if (sip.codigoTelemedida == "1") {
            // TIENE TELEMEDIDA
            if (sip.codigoPeajeEnVigor == "32") {
                peajeSalidaTerminoVariableConsumo = consumoEnergiaTotal * 0.004149
                otrosCostesRegasificacionTerminoFijoCliente = (consumoCaudalMedioDia * (0.852271 / 365)) * numDaysNew
                cargoTerminoFijoCliente = (consumoCaudalMedioDia * (0.0472650 / 365)) * numDaysNew
            }
            if ((sip.codigoPeajeEnVigor == "RL01" || sip.codigoPeajeEnVigor == "R1")) {
                peajeSalidaTerminoVariableConsumo = consumoEnergiaTotal * 0.004149
                otrosCostesRegasificacionTerminoFijoCliente = (consumoCaudalMedioDia * (0.852271 / 365)) * numDaysNew
                cargoTerminoFijoCliente = (consumoCaudalMedioDia * (0.0472650 / 365)) * numDaysNew

            }
            if ((sip.codigoPeajeEnVigor == "RL02" || sip.codigoPeajeEnVigor == "R2")) {
                peajeSalidaTerminoVariableConsumo = consumoEnergiaTotal * 0.002863
                otrosCostesRegasificacionTerminoFijoCliente = (consumoCaudalMedioDia * (0.246837 / 365)) * numDaysNew
                cargoTerminoFijoCliente = (consumoCaudalMedioDia * (0.0246090 / 365)) * numDaysNew

            }
            if ((sip.codigoPeajeEnVigor == "RL03" || sip.codigoPeajeEnVigor == "R3")) {
                peajeSalidaTerminoVariableConsumo = consumoEnergiaTotal * 0.002185
                otrosCostesRegasificacionTerminoFijoCliente = (consumoCaudalMedioDia * (0.095711 / 365)) * numDaysNew
                cargoTerminoFijoCliente = (consumoCaudalMedioDia * (0.0189530 / 365)) * numDaysNew

            }
            if ((sip.codigoPeajeEnVigor == "RL04" || sip.codigoPeajeEnVigor == "R4")) {
                peajeSalidaTerminoVariableConsumo = consumoEnergiaTotal * 0.002465
                otrosCostesRegasificacionTerminoFijoCliente = (consumoCaudalMedioDia * (0.016650 / 365)) * numDaysNew
                cargoTerminoFijoCliente = (consumoCaudalMedioDia * (0.0159940 / 365)) * numDaysNew

            }
            if ((sip.codigoPeajeEnVigor == "RL05" || sip.codigoPeajeEnVigor == "R5")) {
                cargoTerminoFijoCliente = (consumoCaudalMedioDia * (0.0155020 / 365)) * numDaysNew
                if (sip.codigoPresion == "01") {
                    peajeSalidaTerminoVariableConsumo = consumoEnergiaTotal * 0.010014
                    otrosCostesRegasificacionTerminoFijoCliente = (consumoCaudalMedioDia * (0.003486 / 365)) * numDaysNew

                } else {
                    peajeSalidaTerminoVariableConsumo = consumoEnergiaTotal * 0.001428
                    otrosCostesRegasificacionTerminoFijoCliente = (consumoCaudalMedioDia * (0.003486 / 365)) * numDaysNew

                }
            }
            if ((sip.codigoPeajeEnVigor == "RL06" || sip.codigoPeajeEnVigor == "R6")) {
                cargoTerminoFijoCliente = (consumoCaudalMedioDia * (0.0154050 / 365)) * numDaysNew

                if (sip.codigoPresion == "01") {
                    peajeSalidaTerminoVariableConsumo = consumoEnergiaTotal * 0.001296
                    otrosCostesRegasificacionTerminoFijoCliente = (consumoCaudalMedioDia * (0.000908 / 365)) * numDaysNew

                } else {
                    peajeSalidaTerminoVariableConsumo = consumoEnergiaTotal * 0.001296
                    otrosCostesRegasificacionTerminoFijoCliente = (consumoCaudalMedioDia * (0.000908 / 365)) * numDaysNew

                }
            }
            if ((sip.codigoPeajeEnVigor == "RL07" || sip.codigoPeajeEnVigor == "R7")) {
                cargoTerminoFijoCliente = (consumoCaudalMedioDia * (0.0153810 / 365)) * numDaysNew

                if (sip.codigoPresion == "01") {
                    peajeSalidaTerminoVariableConsumo = consumoEnergiaTotal * 0.000895
                    otrosCostesRegasificacionTerminoFijoCliente = (consumoCaudalMedioDia * (0.00256 / 365)) * numDaysNew

                } else {
                    peajeSalidaTerminoVariableConsumo = consumoEnergiaTotal * 0.001029
                    otrosCostesRegasificacionTerminoFijoCliente = (consumoCaudalMedioDia * (0.00256 / 365)) * numDaysNew

                }
            }
            if ((sip.codigoPeajeEnVigor == "RL08" || sip.codigoPeajeEnVigor == "R8")) {
                peajeSalidaTerminoVariableConsumo = consumoEnergiaTotal * 0.000634
                otrosCostesRegasificacionTerminoFijoCliente = (consumoCaudalMedioDia * (0.000096 / 365)) * numDaysNew
                cargoTerminoFijoCliente = (consumoCaudalMedioDia * (0.0115375 / 365)) * numDaysNew

            }
            if ((sip.codigoPeajeEnVigor == "RL09" || sip.codigoPeajeEnVigor == "R9")) {
                peajeSalidaTerminoVariableConsumo = consumoEnergiaTotal * 0.000539
                otrosCostesRegasificacionTerminoFijoCliente = (consumoCaudalMedioDia * (0.000035 / 365)) * numDaysNew
                cargoTerminoFijoCliente = (consumoCaudalMedioDia * (0.015373 / 365)) * numDaysNew

            }
            if ((sip.codigoPeajeEnVigor == "RL10" || sip.codigoPeajeEnVigor == "R10")) {
                peajeSalidaTerminoVariableConsumo = consumoEnergiaTotal * 0.000435
                otrosCostesRegasificacionTerminoFijoCliente = (consumoCaudalMedioDia * (0.000013 / 365)) * numDaysNew
                cargoTerminoFijoCliente = (consumoCaudalMedioDia * (0.015372 / 365)) * numDaysNew

            }
            if ((sip.codigoPeajeEnVigor == "RL11" || sip.codigoPeajeEnVigor == "R11")) {
                peajeSalidaTerminoVariableConsumo = consumoEnergiaTotal * 0.000112
                otrosCostesRegasificacionTerminoFijoCliente = (consumoCaudalMedioDia * (0.000002 / 365)) * numDaysNew
                cargoTerminoFijoCliente = (consumoCaudalMedioDia * (0.015371 / 365)) * numDaysNew

            }
            peajeSalidaTransporteTerminoFijoCliente = (consumoCaudalMedioDia * (0.204626 / 365)) * numDaysNew
        }


        if(producto.coIncluido){


            comisionContratoFija = producto.comisionContratoNuevo
            comisionContratoConsumoAgente1 = parseFloat(sip.ConsumoAnualTotal) * producto.comisionConsumoAgente1
            comisionContratoConsumoAgente2 = this.roundNumber(parseFloat(sip.ConsumoAnualTotal) * producto.comisionConsumoAgente2)
            comisionContratoConsumoAdmin = parseFloat(sip.ConsumoAnualTotal) * producto.comisionConsumoAdmin


        } else if (!producto.coIncluido){
            comisionContratoFija = producto.comisionContratoNuevo
            comisionContratoConsumoAgente1 = (this.state.costeOperativo * parseFloat(sip.ConsumoAnualTotal)) * (producto.comisionGOAgente1/100)
            comisionContratoConsumoAgente2 = 0
            comisionContratoConsumoAdmin = comisionContratoConsumoAgente1*(producto.comisionGOAgente2/100)

        }

        peajeSalidaTransporteTerminoVariableConsumo = 0
        peajeSalidaTransporteTerminoVariableConsumo = consumoEnergiaTotal * 0.000017


        tasaCnmc = (peajeSalidaTransporteTerminoVariableConsumo + peajeSalidaTransporteTerminoFijoCliente + cargoTerminoFijoCliente + otrosCostesRegasificacionTerminoFijoCliente + peajeSalidaTerminoVariableConsumo + peajeSalidaTerminoFijoCliente ) * 0.010930000000
        cuotaGts= (peajeSalidaTransporteTerminoVariableConsumo + peajeSalidaTransporteTerminoFijoCliente + otrosCostesRegasificacionTerminoFijoCliente + peajeSalidaTerminoVariableConsumo + peajeSalidaTerminoFijoCliente ) * 0.00140


        descuento = this.roundNumber(this.percentage(sumEnergia, descuentoMonth) * numMonthNew);
        sumEnergia = this.roundNumber(this.roundNumber(sumEnergia) - descuento);
        sumPotencia = this.roundNumber(sumPotencia);

        iePercent = this.roundNumber((consumoEnergiaTotal) * 0.00234);
        impuestoElectrico = 0;
        serviciosAdicionales = this.roundNumber(this.state.precioServicio * numMonthNew);
        alquilerEquipoMedida = this.roundNumber(alquilerEquipoMedidaMonth * numMonthNew);
        bi = this.roundNumber((serviciosAdicionales + alquilerEquipoMedida + iePercent + sumEnergia + sumPotencia+tasaCnmc+cuotaGts+peajeSalidaTransporteTerminoVariableConsumo + peajeSalidaTransporteTerminoFijoCliente + cargoTerminoFijoCliente + otrosCostesRegasificacionTerminoFijoCliente + peajeSalidaTerminoVariableConsumo + peajeSalidaTerminoFijoCliente));
        iva = this.roundNumber((bi * 0.21));
        total = this.roundNumber((bi + iva));
        codigoATR = producto.tarifaATR;
        idServicio = this.state.idservicio;
        idComercializadora = this.state.idComercializadora;
        //nombreComercializadora = this.state.nombreComercializadora;
        nombreServicio = this.state.nombreServicio;

        precioServicio = this.roundNumber(this.state.precioServicio);



        return {
            serviciosAdicionalesMonth: serviciosAdicionalesMonth,
            serviciosAdicionales: serviciosAdicionales,
            alquilerEquipoMedidaMonth: alquilerEquipoMedidaMonth,
            alquilerEquipoMedida: alquilerEquipoMedida,
            descuento: descuento,
            descuentoMonth: descuentoMonth,
            codigoATR: codigoATR,
            sumPotencia: sumPotencia,
            sumEnergia: sumEnergia,
            iePercent: iePercent,
            impuestoElectrico: impuestoElectrico,
            peajeSalidaTerminoVariableConsumo: this.roundNumber(peajeSalidaTerminoVariableConsumo),
            peajeSalidaTerminoFijoCliente: this.roundNumber(peajeSalidaTerminoFijoCliente),
            peajeSalidaTransporteTerminoVariableConsumo: this.roundNumber(peajeSalidaTransporteTerminoVariableConsumo),
            peajeSalidaTransporteTerminoFijoCliente: this.roundNumber(peajeSalidaTransporteTerminoFijoCliente),
            otrosCostesRegasificacionTerminoFijoCliente: this.roundNumber(otrosCostesRegasificacionTerminoFijoCliente),
            cargoTerminoFijoCliente: this.roundNumber(cargoTerminoFijoCliente),
            cuotaGts: this.roundNumber(cuotaGts),
            tasaCnmc: this.roundNumber(tasaCnmc),
            factork: factorKCoeficiente,
            iva: iva,
            bi: bi,
            total: total,
            numDaysNew: numDaysNew,
            numMonthNew: numMonthNew,
            fechaDesdeConsumo: fechaDesdeConsumo,
            fechaHastaConsumo: fechaHastaConsumo,
            EnergiaActiva: ["0"],
            Potencia: ["0"],
            potenciaP1: potenciaP1,
            potenciaP2: potenciaP2,

            energiaActivaP1: energiaActivaP1,
            energiaActivaP2: energiaActivaP2,


            idServicio: idServicio,
            idComercializadora: idComercializadora,
            //nombreComercializadora: nombreComercializadora,
            nombreServicio: nombreServicio,
            precioServicio: precioServicio,
            comisionContratoFija:this.roundNumber(comisionContratoFija),
            comisionContratoConsumoAgente1:this.roundNumber(comisionContratoConsumoAgente1),
            comisionContratoConsumoAgente2:comisionContratoConsumoAgente2,
            comisionContratoConsumoAdmin:this.roundNumber(comisionContratoConsumoAdmin)


        }

    }

    private calculatePrecioActual = (periodos: number[], factura: any) => {

        let {
            serviciosAdicionalesMonth,
            serviciosAdicionales,
            alquilerEquipoMedidaMonth,
            alquilerEquipoMedida,
            peajeSalidaTerminoVariableConsumo,
            peajeSalidaTerminoFijoCliente,
            peajeSalidaTransporteTerminoVariableConsumo,
            peajeSalidaTransporteTerminoFijoCliente,
            otrosCostesRegasificacionTerminoFijoCliente,
            cargoTerminoFijoCliente,
            cuotaGts,
            tasaCnmc,
            factorKCoeficiente,
            factork,
            codigoATR,
            sumPotencia,
            sumEnergia,
            iePercent,
            impuestoElectrico,
            iva,
            bi,
            total,
            numDaysNew,
            numMonthNew,
            fechaDesdeConsumo,
            fechaHastaConsumo,
            descuento,
            descuentoMonth,


        } = factura;

        const sip = this.state.sip;
        let consumoPotencia = {};
        let consumoEnergia = {};
        let precioPotencia = {};
        let precioEnergia = {};
let mibGasPrice = this.state.mibGasPrice;
        //let numDays;
        fechaDesdeConsumo = null;
        fechaHastaConsumo = null;
        var consumoEnergiaTotal = 0;

        let consumoCaudalMedioDia = 0;

        sumEnergia = 0;
        sumPotencia = 0;


        numDaysNew = 0;
        numMonthNew = 0;

        const ubi = this.state.ubi;
        //let factork = {};
        factorKCoeficiente = 0;

        this.state.itemsConsumo.map(row => {

            if (fechaDesdeConsumo === null) {
                fechaDesdeConsumo = row['fechaInicioMesConsumo'];
                numDaysNew += Math.round(moment.duration(moment(row['fechaFinMesConsumo'], "DD-MM-YYYY").diff(moment(row['fechaInicioMesConsumo'], "DD-MM-YYYY"))).asDays());
                numMonthNew += (moment.duration(moment(row['fechaFinMesConsumo'], "DD-MM-YYYY").diff(moment(row['fechaInicioMesConsumo'], "DD-MM-YYYY"))).asMonths()).toFixed(2);
            } else if (moment(fechaDesdeConsumo, "DD-MM-YYYY") > moment(row['fechaInicioMesConsumo'], "DD-MM-YYYY")) {
                fechaDesdeConsumo = row['fechaInicioMesConsumo'];
                numDaysNew += Math.round(moment.duration(moment(row['fechaFinMesConsumo'], "DD-MM-YYYY").diff(moment(row['fechaInicioMesConsumo'], "DD-MM-YYYY"))).asDays());

                numMonthNew += (moment.duration(moment(row['fechaFinMesConsumo'], "DD-MM-YYYY").diff(moment(row['fechaInicioMesConsumo'], "DD-MM-YYYY"))).asMonths()).toFixed(2);
            }
            return row;
        });




        this.state.itemsConsumo.map(row => {
            if (fechaHastaConsumo === null) {
                fechaHastaConsumo = row['fechaFinMesConsumo'];
            } else if (moment(fechaHastaConsumo, "DD-MM-YYYY") < moment(row['fechaFinMesConsumo'], "DD-MM-YYYY")) {
                fechaDesdeConsumo = row['fechaFinMesConsumo'];
            }

            return row;
        });




        periodos.map(periodo => {

            precioEnergia[`P${periodo}`] = 0;
            precioPotencia[`P${periodo}`] = 0;
            consumoPotencia[`P${periodo}`] = 0;
            consumoEnergia[`P${periodo}`] = 0;


            this.state.itemsConsumo.map(row => {
                consumoPotencia[`P${periodo}`] += Number(row[`potenciaDemandadaEnWP${periodo}`]) || 0;
                consumoEnergia[`P${periodo}`] += (Number(row[`consumoEnWhP${periodo}`]) || 0);
                consumoEnergiaTotal += (Number(row[`consumoEnWhP${periodo}`]) || 0);
                consumoCaudalMedioDia += (Number(row[`caudalMedioEnWhdia`]) / 1000 || 0);
                //numDays += moment(row['fechaFinMesConsumo'], "DD-MM-YYYY").daysInMonth();
                //numDays += moment.duration(moment(row['fechaFinMesConsumo'], "DD-MM-YYYY").diff(moment(row['fechaInicioMesConsumo'], "DD-MM-YYYY"))).asDays();
                return row;
            });


            if (precioEnergia[`P${periodo}`] === 0) {

                sumEnergia += (consumoEnergia[`P${periodo}`] * ((mibGasPrice/1000) + this.state.formActual[`factork`]));
                factorKCoeficiente =  this.state.formActual[`factork`]
            } else {
                sumEnergia += (consumoEnergia[`P${periodo}`] * precioEnergia[`P${periodo}`]);
            }



            //sumEnergia += (consumoEnergia[`P${periodo}`] * this.state.formActual[`factork`]);
            //sumEnergia += (consumoEnergia[`P${periodo}`] * precioEnergia[`P${periodo}`]);


            if (periodo === 1) {
                //const potenciaContratada = sip[`potenciasContratadasEnWP${periodo}`];(periodo)
                //const potenciaContratada = potenciaP1;
                sumPotencia += (this.state.formActual[`precioTP${periodo}`]) * numMonthNew;
            }

            if (periodo === 2) {
                //const potenciaContratada = sip[`potenciasContratadasEnWP${periodo}`];(periodo)
                //const potenciaContratada = potenciaP2;
                sumPotencia += (this.state.formActual[`precioTP${periodo}`]) * numMonthNew;
            }

            return periodo;
        });



        peajeSalidaTerminoVariableConsumo = 0;
        otrosCostesRegasificacionTerminoFijoCliente = 0;
        peajeSalidaTerminoFijoCliente = 0;
        peajeSalidaTransporteTerminoFijoCliente = 0;
        cargoTerminoFijoCliente = 0;

        if (sip.codigoTelemedida == "0") {
            // NO TIENE TELEMEDIDA
            if (sip.codigoPeajeEnVigor == "32") {
                peajeSalidaTerminoVariableConsumo = consumoEnergiaTotal * 0.016710
                peajeSalidaTerminoFijoCliente = (27.412410 / 365) * numDaysNew
                peajeSalidaTransporteTerminoFijoCliente = (3.103158 / 365) * numDaysNew
                cargoTerminoFijoCliente = (0.72 / 365) * numDaysNew
            }
            if ((sip.codigoPeajeEnVigor == "RL01" || sip.codigoPeajeEnVigor == "R1")) {
                peajeSalidaTerminoVariableConsumo = consumoEnergiaTotal * 0.016710
                peajeSalidaTerminoFijoCliente = (27.412410 / 365) * numDaysNew
                peajeSalidaTransporteTerminoFijoCliente = (3.103158 / 365) * numDaysNew
                cargoTerminoFijoCliente = (0.72 / 365) * numDaysNew
            }
            if ((sip.codigoPeajeEnVigor == "RL02" || sip.codigoPeajeEnVigor == "R2")) {
                peajeSalidaTerminoVariableConsumo = consumoEnergiaTotal * 0.013331
                peajeSalidaTerminoFijoCliente = (68.423783 / 365) * numDaysNew
                peajeSalidaTransporteTerminoFijoCliente = (10.721055 / 365) * numDaysNew
                cargoTerminoFijoCliente = (1.29 / 365) * numDaysNew
            }
            if ((sip.codigoPeajeEnVigor == "RL03" || sip.codigoPeajeEnVigor == "R3")) {
                peajeSalidaTerminoVariableConsumo = consumoEnergiaTotal * 0.010809
                peajeSalidaTerminoFijoCliente = (173.086523 / 365) * numDaysNew
                peajeSalidaTransporteTerminoFijoCliente = (27.686154 / 365) * numDaysNew
                cargoTerminoFijoCliente = (2.56 / 365) * numDaysNew
            }
            if ((sip.codigoPeajeEnVigor == "RL04" || sip.codigoPeajeEnVigor == "R4")) {
                peajeSalidaTerminoVariableConsumo = consumoEnergiaTotal * 0.012235
                peajeSalidaTerminoFijoCliente = (159.202402 / 365) * numDaysNew
                cargoTerminoFijoCliente = (12.42 / 365) * numDaysNew
            }
            if ((sip.codigoPeajeEnVigor == "RL05" || sip.codigoPeajeEnVigor == "R5")) {
                peajeSalidaTransporteTerminoFijoCliente = (760.326435 / 365) * numDaysNew
                cargoTerminoFijoCliente = (57.49 / 365) * numDaysNew
                if (sip.codigoPresion == "01") {
                    peajeSalidaTerminoVariableConsumo = consumoEnergiaTotal * 0.011621
                    peajeSalidaTerminoFijoCliente = (544.731766 / 365) * numDaysNew
                } else {
                    peajeSalidaTerminoVariableConsumo = consumoEnergiaTotal * 0.003035
                    peajeSalidaTerminoFijoCliente = (2787.205393 / 365) * numDaysNew
                }
            }
            if ((sip.codigoPeajeEnVigor == "RL06" || sip.codigoPeajeEnVigor == "R6")) {
                peajeSalidaTransporteTerminoFijoCliente = (2923.990565 / 365) * numDaysNew
                cargoTerminoFijoCliente = (219.43 / 365) * numDaysNew
                if (sip.codigoPresion == "01") {
                    peajeSalidaTerminoVariableConsumo = consumoEnergiaTotal * 0.005037
                    peajeSalidaTerminoFijoCliente = (10934.440729 / 365) * numDaysNew
                } else {
                    peajeSalidaTerminoVariableConsumo = consumoEnergiaTotal * 0.001957
                    peajeSalidaTerminoFijoCliente = (7498.928757 / 365) * numDaysNew
                }
            }

            otrosCostesRegasificacionTerminoFijoCliente = (12.929063 / 365) * numDaysNew

        } else if (sip.codigoTelemedida == "1") {
            // TIENE TELEMEDIDA
            if (sip.codigoPeajeEnVigor == "32") {
                peajeSalidaTerminoVariableConsumo = consumoEnergiaTotal * 0.004149
                otrosCostesRegasificacionTerminoFijoCliente = (consumoCaudalMedioDia * (0.852271 / 365)) * numDaysNew
                cargoTerminoFijoCliente = (consumoCaudalMedioDia * (0.0472650 / 365)) * numDaysNew
            }
            if ((sip.codigoPeajeEnVigor == "RL01" || sip.codigoPeajeEnVigor == "R1")) {
                peajeSalidaTerminoVariableConsumo = consumoEnergiaTotal * 0.004149
                otrosCostesRegasificacionTerminoFijoCliente = (consumoCaudalMedioDia * (0.852271 / 365)) * numDaysNew
                cargoTerminoFijoCliente = (consumoCaudalMedioDia * (0.0472650 / 365)) * numDaysNew

            }
            if ((sip.codigoPeajeEnVigor == "RL02" || sip.codigoPeajeEnVigor == "R2")) {
                peajeSalidaTerminoVariableConsumo = consumoEnergiaTotal * 0.002863
                otrosCostesRegasificacionTerminoFijoCliente = (consumoCaudalMedioDia * (0.246837 / 365)) * numDaysNew
                cargoTerminoFijoCliente = (consumoCaudalMedioDia * (0.0246090 / 365)) * numDaysNew

            }
            if ((sip.codigoPeajeEnVigor == "RL03" || sip.codigoPeajeEnVigor == "R3")) {
                peajeSalidaTerminoVariableConsumo = consumoEnergiaTotal * 0.002185
                otrosCostesRegasificacionTerminoFijoCliente = (consumoCaudalMedioDia * (0.095711 / 365)) * numDaysNew
                cargoTerminoFijoCliente = (consumoCaudalMedioDia * (0.0189530 / 365)) * numDaysNew

            }
            if ((sip.codigoPeajeEnVigor == "RL04" || sip.codigoPeajeEnVigor == "R4")) {
                peajeSalidaTerminoVariableConsumo = consumoEnergiaTotal * 0.002465
                otrosCostesRegasificacionTerminoFijoCliente = (consumoCaudalMedioDia * (0.016650 / 365)) * numDaysNew
                cargoTerminoFijoCliente = (consumoCaudalMedioDia * (0.0159940 / 365)) * numDaysNew

            }
            if ((sip.codigoPeajeEnVigor == "RL05" || sip.codigoPeajeEnVigor == "R5")) {
                cargoTerminoFijoCliente = (consumoCaudalMedioDia * (0.0155020 / 365)) * numDaysNew
                if (sip.codigoPresion == "01") {
                    peajeSalidaTerminoVariableConsumo = consumoEnergiaTotal * 0.010014
                    otrosCostesRegasificacionTerminoFijoCliente = (consumoCaudalMedioDia * (0.003486 / 365)) * numDaysNew

                } else {
                    peajeSalidaTerminoVariableConsumo = consumoEnergiaTotal * 0.001428
                    otrosCostesRegasificacionTerminoFijoCliente = (consumoCaudalMedioDia * (0.003486 / 365)) * numDaysNew

                }
            }
            if ((sip.codigoPeajeEnVigor == "RL06" || sip.codigoPeajeEnVigor == "R6")) {
                cargoTerminoFijoCliente = (consumoCaudalMedioDia * (0.0154050 / 365)) * numDaysNew

                if (sip.codigoPresion == "01") {
                    peajeSalidaTerminoVariableConsumo = consumoEnergiaTotal * 0.001296
                    otrosCostesRegasificacionTerminoFijoCliente = (consumoCaudalMedioDia * (0.000908 / 365)) * numDaysNew

                } else {
                    peajeSalidaTerminoVariableConsumo = consumoEnergiaTotal * 0.001296
                    otrosCostesRegasificacionTerminoFijoCliente = (consumoCaudalMedioDia * (0.000908 / 365)) * numDaysNew

                }
            }
            if ((sip.codigoPeajeEnVigor == "RL07" || sip.codigoPeajeEnVigor == "R7")) {
                cargoTerminoFijoCliente = (consumoCaudalMedioDia * (0.0153810 / 365)) * numDaysNew

                if (sip.codigoPresion == "01") {
                    peajeSalidaTerminoVariableConsumo = consumoEnergiaTotal * 0.000895
                    otrosCostesRegasificacionTerminoFijoCliente = (consumoCaudalMedioDia * (0.00256 / 365)) * numDaysNew

                } else {
                    peajeSalidaTerminoVariableConsumo = consumoEnergiaTotal * 0.001029
                    otrosCostesRegasificacionTerminoFijoCliente = (consumoCaudalMedioDia * (0.00256 / 365)) * numDaysNew

                }
            }
            if ((sip.codigoPeajeEnVigor == "RL08" || sip.codigoPeajeEnVigor == "R8")) {
                peajeSalidaTerminoVariableConsumo = consumoEnergiaTotal * 0.000634
                otrosCostesRegasificacionTerminoFijoCliente = (consumoCaudalMedioDia * (0.000096 / 365)) * numDaysNew
                cargoTerminoFijoCliente = (consumoCaudalMedioDia * (0.0115375 / 365)) * numDaysNew

            }
            if ((sip.codigoPeajeEnVigor == "RL09" || sip.codigoPeajeEnVigor == "R9")) {
                peajeSalidaTerminoVariableConsumo = consumoEnergiaTotal * 0.000539
                otrosCostesRegasificacionTerminoFijoCliente = (consumoCaudalMedioDia * (0.000035 / 365)) * numDaysNew
                cargoTerminoFijoCliente = (consumoCaudalMedioDia * (0.015373 / 365)) * numDaysNew

            }
            if ((sip.codigoPeajeEnVigor == "RL10" || sip.codigoPeajeEnVigor == "R10")) {
                peajeSalidaTerminoVariableConsumo = consumoEnergiaTotal * 0.000435
                otrosCostesRegasificacionTerminoFijoCliente = (consumoCaudalMedioDia * (0.000013 / 365)) * numDaysNew
                cargoTerminoFijoCliente = (consumoCaudalMedioDia * (0.015372 / 365)) * numDaysNew

            }
            if ((sip.codigoPeajeEnVigor == "RL11" || sip.codigoPeajeEnVigor == "R11")) {
                peajeSalidaTerminoVariableConsumo = consumoEnergiaTotal * 0.000112
                otrosCostesRegasificacionTerminoFijoCliente = (consumoCaudalMedioDia * (0.000002 / 365)) * numDaysNew
                cargoTerminoFijoCliente = (consumoCaudalMedioDia * (0.015371 / 365)) * numDaysNew

            }
            peajeSalidaTransporteTerminoFijoCliente = (consumoCaudalMedioDia * (0.204626 / 365)) * numDaysNew
        }

        peajeSalidaTransporteTerminoVariableConsumo = 0
        peajeSalidaTransporteTerminoVariableConsumo = consumoEnergiaTotal * 0.000017


        tasaCnmc = (peajeSalidaTransporteTerminoVariableConsumo + peajeSalidaTransporteTerminoFijoCliente + cargoTerminoFijoCliente + otrosCostesRegasificacionTerminoFijoCliente + peajeSalidaTerminoVariableConsumo + peajeSalidaTerminoFijoCliente ) * 0.010930000000
        cuotaGts= (peajeSalidaTransporteTerminoVariableConsumo + peajeSalidaTransporteTerminoFijoCliente + otrosCostesRegasificacionTerminoFijoCliente + peajeSalidaTerminoVariableConsumo + peajeSalidaTerminoFijoCliente ) * 0.00140



        descuento = this.roundNumber(this.percentage(sumEnergia, descuentoMonth) * numMonthNew);

        sumEnergia = this.roundNumber(this.roundNumber(sumEnergia) - descuento);
        sumPotencia = this.roundNumber(sumPotencia);


        iePercent = this.roundNumber((consumoEnergiaTotal) * 0.00234);
        impuestoElectrico = 0;
        serviciosAdicionales = this.roundNumber(serviciosAdicionalesMonth * numMonthNew);
        alquilerEquipoMedida = this.roundNumber(alquilerEquipoMedidaMonth * numMonthNew);
        bi = this.roundNumber((serviciosAdicionales + alquilerEquipoMedida + iePercent + sumEnergia + sumPotencia+tasaCnmc+cuotaGts+peajeSalidaTransporteTerminoVariableConsumo + peajeSalidaTransporteTerminoFijoCliente + cargoTerminoFijoCliente + otrosCostesRegasificacionTerminoFijoCliente + peajeSalidaTerminoVariableConsumo + peajeSalidaTerminoFijoCliente));
        iva = this.roundNumber((bi * 0.21));
        total = this.roundNumber((bi + iva));
        codigoATR = this.state.sip.codigoPeajeEnVigor;

        return {
            serviciosAdicionalesMonth: serviciosAdicionalesMonth,
            serviciosAdicionales: serviciosAdicionales,
            alquilerEquipoMedidaMonth: alquilerEquipoMedidaMonth,
            alquilerEquipoMedida: alquilerEquipoMedida,
            peajeSalidaTerminoVariableConsumo: this.roundNumber(peajeSalidaTerminoVariableConsumo),
            peajeSalidaTerminoFijoCliente: this.roundNumber(peajeSalidaTerminoFijoCliente),
            peajeSalidaTransporteTerminoVariableConsumo: this.roundNumber(peajeSalidaTransporteTerminoVariableConsumo),
            peajeSalidaTransporteTerminoFijoCliente: this.roundNumber(peajeSalidaTransporteTerminoFijoCliente),
            otrosCostesRegasificacionTerminoFijoCliente: this.roundNumber(otrosCostesRegasificacionTerminoFijoCliente),
            cargoTerminoFijoCliente: this.roundNumber(cargoTerminoFijoCliente),
            cuotaGts: this.roundNumber(cuotaGts),
            tasaCnmc: this.roundNumber(tasaCnmc),
            factork: factork,
            codigoATR: codigoATR,
            sumPotencia: sumPotencia,
            sumEnergia: sumEnergia,
            iePercent: iePercent,
            impuestoElectrico: impuestoElectrico,
            iva: iva,
            bi: bi,
            total: total,

            numDaysNew: numDaysNew,
            numMonthNew: numMonthNew,
            fechaDesdeConsumo: fechaDesdeConsumo,
            fechaHastaConsumo: fechaHastaConsumo,
            descuento: descuento,
            descuentoMonth: descuentoMonth,

        }

    }




    private roundNumber(number: number) {
        return (Math.round(number * 100) / 100)
    }


    private percentage(num, per) {
        return (num / 100) * per;
    }

    public renderFacturaProducto = () => {
        let factura: any = this.calculatePrecioProducto(this.state.periods, this.state.producto, this.state.formProducto);
        let ahorro: any = this.calculateAhorro(this.state.periods, this.state.formActual, factura);
        let codigoATR: any = this.state.sip.codigoTarifaATREnVigor;
        this.setState({
            formProducto: {
                serviciosAdicionalesMonth: factura.serviciosAdicionalesMonth,
                serviciosAdicionales: factura.serviciosAdicionales,
                alquilerEquipoMedida: factura.alquilerEquipoMedida,
                alquilerEquipoMedidaMonth: factura.alquilerEquipoMedidaMonth,
                peajeSalidaTerminoVariableConsumo: factura.peajeSalidaTerminoVariableConsumo,
                peajeSalidaTerminoFijoCliente: factura.peajeSalidaTerminoFijoCliente,
                peajeSalidaTransporteTerminoFijoCliente: factura.peajeSalidaTransporteTerminoFijoCliente,
                peajeSalidaTransporteTerminoVariableConsumo: factura.peajeSalidaTransporteTerminoVariableConsumo,
                otrosCostesRegasificacionTerminoFijoCliente: factura.otrosCostesRegasificacionTerminoFijoCliente,
                cargoTerminoFijoCliente: factura.cargoTerminoFijoCliente,
                cuotaGts: factura.cuotaGts,
                tasaCnmc: factura.tasaCnmc,
                factork: factura.factork,
                codigoATR: codigoATR,
                sumPotencia: factura.sumPotencia,
                sumEnergia: factura.sumEnergia,
                iePercent: factura.iePercent,
                impuestoElectrico: factura.impuestoElectrico,
                iva: factura.iva,
                bi: factura.bi,
                total: factura.total,

                descuento: factura.descuento,
                descuentoMonth: factura.descuentoMonth,

                numDaysNew: factura.numDaysNew,
                numMonthNew: factura.numMonthNew,
                fechaDesdeConsumo: factura.fechaDesdeConsumo,
                fechaHastaConsumo: factura.fechaHastaConsumo,
                datasetProducto: factura.datasetProducto,
                EnergiaActiva: ["0"],
                Potencia: ["0"],
                potenciaP1: factura.potenciaP1,
                potenciaP2: factura.potenciaP2,


                energiaActivaP1: factura.energiaActivaP1,
                energiaActivaP2: factura.energiaActivaP2,


                idServicio: factura.idServicio,
                nombreServicio: factura.nombreServicio,
                precioServicio: factura.precioServicio,
                idComercializadora: factura.idComercializadora,

                comisionContratoFija:factura.comisionContratoFija,
                comisionContratoConsumoAgente1:factura.comisionContratoConsumoAgente1,
                comisionContratoConsumoAgente2:factura.comisionContratoConsumoAgente2,
                comisionContratoConsumoAdmin:factura.comisionContratoConsumoAdmin


            },
            totalComparacion: {
                ...ahorro
            }
        }, this.stateOutput)
    }

    public stateOutput() {
        let { formActual, formProducto, producto, itemsTabla, costeOperativo, totalComparacion, periods } = this.state;
        let save: boolean = true;
        this.props.output(
            {
                actual: {
                    datos: formActual,
                },
                producto: {
                    datos: formProducto,
                    producto: producto,
                    itemsPeriodos: itemsTabla,
                    periodos: periods,
                    costeOperativo: costeOperativo,
                    totalComparacion: totalComparacion
                },
                enabledSave: save
            }
        );
    }

    public renderFacturaActual = () => {

        let factura: any = this.calculatePrecioActual(this.state.periods, this.state.formActual);
        let ahorro: any = this.calculateAhorro(this.state.periods, factura, this.state.formProducto);
        let codigoATR: any = this.state.sip.codigoTarifaATREnVigor;

        this.setState({
            formActual: {
                ...this.state.formActual,
                serviciosAdicionales: factura.serviciosAdicionales,
                serviciosAdicionalesMonth: factura.serviciosAdicionalesMonth,
                alquilerEquipoMedida: factura.alquilerEquipoMedida,
                alquilerEquipoMedidaMonth: factura.alquilerEquipoMedidaMonth,
                peajeSalidaTerminoVariableConsumo: factura.peajeSalidaTerminoVariableConsumo,
                peajeSalidaTerminoFijoCliente: factura.peajeSalidaTerminoFijoCliente,
                peajeSalidaTransporteTerminoFijoCliente: factura.peajeSalidaTransporteTerminoFijoCliente,
                peajeSalidaTransporteTerminoVariableConsumo: factura.peajeSalidaTransporteTerminoVariableConsumo,
                otrosCostesRegasificacionTerminoFijoCliente: factura.otrosCostesRegasificacionTerminoFijoCliente,
                cargoTerminoFijoCliente: factura.cargoTerminoFijoCliente,
                cuotaGts: factura.cuotaGts,
                tasaCnmc: factura.tasaCnmc,
                factork: factura.factork,
                codigoATR: codigoATR,
                sumPotencia: factura.sumPotencia,
                sumEnergia: factura.sumEnergia,
                iePercent: factura.iePercent,
                impuestoElectrico: factura.impuestoElectrico,
                iva: factura.iva,
                bi: factura.bi,
                total: factura.total,
                numDaysNew: factura.numDaysNew,
                numMonthNew: factura.numMonthNew,
                fechaDesdeConsumo: factura.fechaDesdeConsumo,
                fechaHastaConsumo: factura.fechaHastaConsumo,
                descuento: factura.descuento,
                descuentoMonth: factura.descuentoMonth,

            },
            totalComparacion: {
                ...ahorro
            }
        }, this.stateOutput);
    }

    private onChangeField = async (event) => {

        const name = event.target.name;
        let value = event.target.value;

        let inputs = {};
        if (value !== "" || value !== null) {
            inputs[name] = Number(value);
            return inputs;

        }
        return false;
    };

    comision=() =>{

    const agente = this.state.agencia
    const adFincas = this.state.adFincas
    const comercial = this.state.comercial

    if(agente && adFincas){
    return (
    <React.Fragment>
        <Col md="3" xl="3">
            <Card className="card-stats">
                <CardBody>
                    <Row>
                        <div className="col">
                            <CardTitle
                                tag="h5"
                                className="text-uppercase text-muted mb-0"
                            >
                                COMISION POR TIPO CONTRATO
            </CardTitle>
                            <span className="h4 font-weight-bold mb-0">
                                {this.state.formProducto.comisionContratoFija} €/Año
                            </span>
                        </div>
                        <Col className="col-auto">
                            <div className="icon icon-shape bg-gradient-red text-white rounded-circle shadow">
                                <i className="bi bi-currency-euro" />
                            </div>
                        </Col>
                    </Row>

                </CardBody>
            </Card>
        </Col>
        <Col md="3" xl="3">
            <Card className="card-stats">
                <CardBody>
                    <Row>
                        <div className="col">
                            <CardTitle
                                tag="h5"
                                className="text-uppercase text-muted mb-0"
                            >
                                COMISION CONSUMO AGENTE
            </CardTitle>
                            <span className="h4 font-weight-bold mb-0">
                                {this.state.formProducto.comisionContratoConsumoAgente1} €/Año
                            </span>
                        </div>
                        <Col className="col-auto">
                            <div className="icon icon-shape bg-gradient-green text-white rounded-circle shadow">
                                <i className="bi bi-currency-euro" />
                            </div>
                        </Col>
                    </Row>

                </CardBody>
            </Card>
        </Col>

        <Col md="3" xl="3">
            <Card className="card-stats">
                <CardBody>
                    <Row>
                        <div className="col">
                            <CardTitle
                                tag="h5"
                                className="text-uppercase text-muted mb-0"
                            >
                                COMISION CONSUMO AD. FINCAS
            </CardTitle>
                            <span className="h4 font-weight-bold mb-0">
                                {this.state.formProducto.comisionContratoConsumoAdmin} €/Año

                            </span>
                        </div>
                        <Col className="col-auto">
                            <div className="icon icon-shape bg-gradient-blue text-white rounded-circle shadow">
                                <i className="bi bi-currency-euro" />
                            </div>
                        </Col>
                    </Row>

                </CardBody>
            </Card>
        </Col>
    </React.Fragment>
    );
    } else{
        return (
        <React.Fragment>

        </React.Fragment>
        );


    }
    };

    comision2=() =>{

    const agente = this.state.agencia
    const adFincas = this.state.adFincas
    const comercial = this.state.comercial

    if (!agente && adFincas){
        return (
        <React.Fragment>
            <Col md="3" xl="3">
                <Card className="card-stats">
                    <CardBody>
                        <Row>
                            <div className="col">
                                <CardTitle
                                    tag="h5"
                                    className="text-uppercase text-muted mb-0"
                                >
                                    COMISION CONSUMO AD. FINCAS
                </CardTitle>
                                <span className="h4 font-weight-bold mb-0">
                                    {this.state.formProducto.comisionContratoConsumoAdmin} €/Año

                                </span>
                            </div>
                            <Col className="col-auto">
                                <div className="icon icon-shape bg-gradient-blue text-white rounded-circle shadow">
                                    <i className="bi bi-currency-euro" />
                                </div>
                            </Col>
                        </Row>

                    </CardBody>
                </Card>
            </Col>
        </React.Fragment>
        );


    } else {
        return (
            <React.Fragment>

            </React.Fragment>
            );

    }
    };



    comision3=() =>{

    const agente = this.state.agencia
    const adFincas = this.state.adFincas
    const comercial = this.state.comercial

    if(agente && !adFincas){
    return (
        <React.Fragment>
            <Col md="3" xl="3">
                <Card className="card-stats">
                    <CardBody>
                        <Row>
                            <div className="col">
                                <CardTitle
                                    tag="h5"
                                    className="text-uppercase text-muted mb-0"
                                >
                                    COMISION POR TIPO CONTRATO
                </CardTitle>
                                <span className="h4 font-weight-bold mb-0">
                                    {this.state.formProducto.comisionContratoFija} €/Año
                                </span>
                            </div>
                            <Col className="col-auto">
                                <div className="icon icon-shape bg-gradient-red text-white  shadow">
                                    <i className="bi bi-currency-euro" />
                                </div>
                            </Col>
                        </Row>

                    </CardBody>
                </Card>
            </Col>
            <Col md="3" xl="3">
                <Card className="card-stats">
                    <CardBody>
                        <Row>
                            <div className="col">
                                <CardTitle
                                    tag="h5"
                                    className="text-uppercase text-muted mb-0"
                                >
                                    COMISION CONSUMO AGENTE
                </CardTitle>
                                <span className="h4 font-weight-bold mb-0">
                                    {this.state.formProducto.comisionContratoConsumoAgente1} €/Año
                                </span>
                            </div>
                            <Col className="col-auto">
                                <div className="icon icon-shape bg-gradient-green text-white shadow">
                                    <i className="bi bi-currency-euro" />
                                </div>
                            </Col>
                        </Row>

                    </CardBody>
                </Card>
            </Col>


        </React.Fragment>
        );

    } else {
    return(

     <React.Fragment>
         </React.Fragment>
    );
    }

    };




    render() {
        const itemsTabla = this.state.itemsTabla;
        const precioServicio = this.state.precioServicio;
        const totalComparacion = this.state.totalComparacion;
        const producto = this.state.producto;
        const formActual = this.state.formActual;
        const formProducto = this.state.formProducto;
        const limitacion = this.state.producto.limitaciones;
        const info = this.state.producto.infoAdicional;
        const condiciones = this.state.producto.condicionesPenalizacion;
        const servicios: any[] = this.state.comercialServicios;
        const mibGasPrice= this.state.mibGasPrice;
        var datasetProducto = [0];
        datasetProducto.length = 0;
        datasetProducto[datasetProducto.length] = formProducto.sumEnergia;
        datasetProducto[datasetProducto.length] = formProducto.serviciosAdicionales;
        datasetProducto[datasetProducto.length] = formProducto.alquilerEquipoMedida;
        datasetProducto[datasetProducto.length] = (formProducto.sumEnergia + formProducto.sumPotencia + formProducto.serviciosAdicionales + formProducto.alquilerEquipoMedida);
        var datasetActual = [0];
        datasetActual.length = 0;
        datasetActual[datasetActual.length] = formActual.sumEnergia;
        datasetActual[datasetActual.length] = formActual.serviciosAdicionales;
        datasetActual[datasetActual.length] = formActual.alquilerEquipoMedida;
        // datasetProducto.push(this.state.serviciosAdicionales);
        // datasetProducto.push(this.state.alquilerEquipoMedida);
        var datasetAhorro = this.state.totalComparacion.datosAhorro;
        datasetActual[datasetActual.length] = (formActual.sumEnergia + formActual.sumPotencia + formActual.serviciosAdicionales + formActual.alquilerEquipoMedida);

        //const periodosInicial = this.state.periods;
        if (this.state.sip.NumeroPeriodos === "3") {
            var periodos = [1, 2, 3]
        } else { periodos = this.state.periods; };

        const data = {
            labels: ['Coste Energía', 'Costes Servicios', 'Costes Equipos', 'Total'],
            datasets: [
                {
                    label: 'Resumen Producto Ofertado',
                    data: datasetProducto,
                    backgroundColor: 'rgb(255, 99, 132)',
                },
                {
                    label: 'Resumen Factura Actual',
                    data: datasetActual,
                    backgroundColor: 'rgb(54, 162, 235)',
                },
            ],
        }


        const dataAhorro = {
            labels: ['Total Coste Actual', 'Total Propuesta', 'Ahorro'],
            datasets: [
                {
                    label: 'Resumen de coste',
                    data: datasetAhorro,
                    backgroundColor: [
                        'rgba(54, 162, 235, 0.2)',
                        'rgba(255, 99, 132, 0.2)',
                        'rgba(255, 206, 86, 0.2)',
                        'rgba(75, 192, 192, 0.2)',
                        'rgba(153, 102, 255, 0.2)',
                        'rgba(255, 159, 64, 0.2)',
                    ],
                    borderColor: [
                        'rgba(54, 162, 235, 1)',
                        'rgba(255, 99, 132, 1)',
                        'rgba(255, 206, 86, 1)',
                        'rgba(75, 192, 192, 1)',
                        'rgba(153, 102, 255, 1)',
                        'rgba(255, 159, 64, 1)',
                    ],
                    borderWidth: 1,
                },
            ],
        }

        const options = {
            maintainAspectRatio: true,
            legend: {
                display: true,
                labels: {
                    fontColor: 'rgb(255, 99, 132)'
                },
                scales: {
                    yAxes: [
                        {
                            ticks: {
                                beginAtZero: true,
                            },
                        },
                    ],
                },
            },
        }

/* para añadir en caso de contratos TUR

<div className="item-data">
    <h5 className="">Término Fijo {producto.precioTPTipo} {formProducto.numMonthNew} meses</h5>
    <div className="">{formProducto.sumPotencia}€</div>
</div>

<div className="item-data">
    <h5>Término Energía {formProducto.numMonthNew} meses</h5>
    <div className="">{formProducto.sumEnergia}€</div>
</div>

*/
/* para añadir en caso de contratos TUR en factura Actual
<div className="item-data">
    <h5 className="">Término Energía {formActual.numMonthNew} meses</h5>
    <div className="">{formActual.sumEnergia}€</div>
</div>

<div className="item-data">
    <h5 className="">Descuento sobre consumo Energía</h5>
    <div className="">
        <input type="number" step="1.0" name="descuentoMonth" onChange={this.onChangeFieldFormActual} value={formActual.descuentoMonth}></input>€

  </div>
    <h5 className="">Total Descuentos</h5>
    <div>{formActual.descuento}%</div>
</div>
*/


        return (
            <>

            <Row  className="margin-reset w-100">
            <Card className="mt-1 mb-1 shadow card-billing-template">
            <CardBody>
            <Row className="">
            <h3 className="col item-header">
            PROPUESTA ESTUDIO. {producto.nombreOferta}

            </h3>

            <this.comision/>
            <this.comision2 />
            <this.comision3 />
            </Row>
            </CardBody>
            </Card>
            </Row>

                <Row className="margin-reset w-100">

                    <Card className="mt-3 mb-3 shadow card-billing-template flex">
                        <Form innerRef={this.formProductoRef}>

                            <CardBody>

                                <Row>
                                    <div className="col">
                                        <div className="item-data">
                                            <h5 className="">Consumos desde {formProducto.fechaDesdeConsumo} hasta {formProducto.fechaHastaConsumo}</h5>
                                        </div>
                                    </div>
                                </Row>

                                <Row>
                                    <SipsTable
                                        hideViewMoreButton={true}
                                        items={itemsTabla}
                                        fields={comercialProductoFields}
                                        expandableFields={comercialProductoExpandableFields}>
                                    </SipsTable>
                                </Row>
                                <Row className="panel-data-resolve">
                                    <div className="col">
                                        <div className="item-data">
                                            <h5>Peaje salida red local. Término fijo cliente {formProducto.numDaysNew} días</h5>
                                            <div className="">{formProducto.peajeSalidaTerminoFijoCliente}€</div>
                                        </div>

                                        <div className="item-data">
                                            <h5>Peaje salida red transporte. Término fijo cliente {formProducto.numDaysNew} días</h5>
                                            <div className="">{formProducto.peajeSalidaTransporteTerminoFijoCliente}€</div>
                                        </div>


                                        <div className="item-data">
                                            <h5>Cargo. Término fijo cliente {formProducto.numDaysNew} días</h5>
                                            <div className="">{formProducto.cargoTerminoFijoCliente}€</div>
                                        </div>


                                        <div className="item-data">
                                            <h5>Cuota del GTS </h5>
                                            <div className="">{formProducto.cuotaGts}€</div>
                                        </div>


                                        <div className="item-data">
                                            <h5>Tasa CNMC </h5>
                                            <div className="">{formProducto.tasaCnmc}€</div>
                                        </div>

                                        <div className="item-data">
                                            <h5 className="">I.E. Hidrocarburos 0,00234 €/kWh</h5>
                                            <div className="">{formProducto.iePercent}€</div>
                                        </div>

                                        <FormGroup>
                                            <Label className="form-control-label"
                                                htmlFor="idServicio">
                                                Seleccione un servicio
                                        </Label>
                                            <span hidden={servicios.length > 0 ? true : false}><br /> No existen servicios para este producto</span>
                                            <Input
                                                id="idServicio"
                                                placeholder=""
                                                name="idServicio"
                                                onChange={this.onChangeFieldFormProductoServicio}
                                                hidden={servicios.length > 0 ? false : true}
                                                type="select">
                                                <option value="">Seleccionar Servicio</option>
                                                {servicios.map((item: any, index) => <option value={item.id} key={index}>{item.nombreServicio}</option>)}
                                            </Input>
                                        </FormGroup>

                                        <div className="item-data">
                                            <h5 className="">Servicios Adicionales por Mes</h5>
                                            <div className="">
                                                <input type="number" step="0.01" name="serviciosAdicionalesMonth" onChange={this.onChangeFieldFormProducto} value={precioServicio}></input>€

                                        </div>
                                            <h5 className="">Total Servicios</h5>
                                            <div>{formProducto.serviciosAdicionales}€</div>
                                        </div>

                                    </div>
                                    <div className="col">

                                        <div className="item-data">
                                            <h5>Peaje salida red local. Término variable consumo {formProducto.numMonthNew} meses</h5>
                                            <div className="">{formProducto.peajeSalidaTerminoVariableConsumo}€</div>
                                        </div>

                                        <div className="item-data">
                                            <h5>Peaje salida red transporte. Término variable consumo {formProducto.numMonthNew} meses</h5>
                                            <div className="">{formProducto.peajeSalidaTransporteTerminoVariableConsumo}€</div>
                                        </div>

                                        <div className="item-data">
                                            <h5>Otros costes de regasificación. Término fijo cliente {formProducto.numDaysNew} días</h5>
                                            <div className="">{formProducto.otrosCostesRegasificacionTerminoFijoCliente}€</div>
                                        </div>

                                        <div className="item-data">
                                            <h5>Término variable consumo {formProducto.numDaysNew} días. Precio MIBGAS {mibGasPrice} Coeficiente {formProducto.factork}</h5>
                                            <div className="">{formProducto.sumEnergia}€</div>
                                        </div>

                                        <div className="item-data">
                                            <h5 className="">Alquiler del Equipo a Medida por Mes</h5>
                                            <div className="">
                                                <input type="number" step="0.01" name="alquilerEquipoMedidaMonth" onChange={this.onChangeFieldFormProducto} value={formProducto.alquilerEquipoMedidaMonth}></input>€
                                        </div>
                                            <h5 className="">Total Alquiler</h5>
                                            <div>{formProducto.alquilerEquipoMedida}€</div>
                                        </div>
                                        <div className="item-data">
                                            <h5 className="">Descuento sobre coeficiente</h5>
                                            <div className="">
                                                <input type="number" step="1.0" name="descuentoMonth" onChange={this.onChangeFieldFormProducto} value={formProducto.descuentoMonth}></input>€

                                          </div>

                                        </div>
                                        <div className="item-data">
                                            <h5 className="">BASE IMPONIBLE</h5>
                                            <div className="">{formProducto.bi}€</div>
                                        </div>
                                        <div className="item-data">
                                            <h5 className="">IVA 21%</h5>
                                            <div className="">{formProducto.iva}€</div>
                                        </div>

                                    </div>
                                </Row>
                                <Row className="">
                                    <div className="col item-total">
                                        <h2>
                                            TOTAL
                                    </h2>
                                        <div>
                                            {formProducto.total}€
                                    </div>
                                    </div>
                                </Row>
                            </CardBody>
                        </Form>
                    </Card>

                </Row>

                <Row className="margin-reset w-100">

                    <Card className="mt-3 mb-3 shadow card-billing-template">
                        <Form innerRef={this.formActualRef}>
                            <CardBody>
                                <Row className="">
                                    <h2 className="col item-header">
                                        Simulación factura comercializadora actual
                                </h2>
                                </Row>
                                <Row>
                                    <Table className="table-actual align-items-center table-flush" responsive key="table-data">
                                        <thead>
                                            <th>Periodo Lectura</th>
                                            <th>PRECIO TÉRMINO ENERGÍA €/KWH</th>
                                            <th>PRECIO TÉRMINO FIJO €/MES €/kWh</th>
                                            <th>COEFICIENTE K MIBGAS</th>
                                        </thead>
                                        <tbody>
                                            {periodos.includes(1) ?
                                                <tr>
                                                    <td>P1</td>
                                                    <td>
                                                        <div className="item-data">
                                                            {formActual.precioTEP1}
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="item-data">
                                                            {formActual.precioTP1}
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="input-control">
                                                            <Input type="number" step="0.01" id="factork" name="factork" onChange={this.onChangeFieldFormActual} value={formActual.factork} />
                                                        </div>
                                                    </td>
                                                </tr>
                                                : ''}
                                            {periodos.includes(2) ?
                                                <tr>
                                                    <td>P2</td>
                                                    <td>
                                                        <div className="input-control">
                                                            <Input type="number" step="0.01" id="precioTP2" name="precioTP2" onChange={this.onChangeFieldFormActual} value={formActual.precioTEP2} />

                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="input-control">
                                                            <Input type="number" step="0.01" id="precioTEP2" name="precioTEP2" onChange={this.onChangeFieldFormActual} value={formActual.precioTP2} />

                                                        </div>
                                                    </td>
                                                </tr>
                                                : ''}
                                        </tbody>
                                    </Table>
                                </Row>
                                <Row className="panel-data-resolve">
                                    <div className="col">

                                        <div className="item-data">
                                            <h5>Peaje salida red local. Término fijo cliente {formActual.numDaysNew} días</h5>
                                            <div className="">{formActual.peajeSalidaTerminoFijoCliente}€</div>
                                        </div>

                                        <div className="item-data">
                                            <h5>Peaje salida red transporte. Término fijo cliente {formActual.numDaysNew} días</h5>
                                            <div className="">{formActual.peajeSalidaTransporteTerminoFijoCliente}€</div>
                                        </div>


                                        <div className="item-data">
                                            <h5>Cargo. Término fijo cliente {formActual.numDaysNew} días</h5>
                                            <div className="">{formActual.cargoTerminoFijoCliente}€</div>
                                        </div>


                                        <div className="item-data">
                                            <h5>Cuota del GTS </h5>
                                            <div className="">{formActual.cuotaGts}€</div>
                                        </div>


                                        <div className="item-data">
                                            <h5>Tasa CNMC </h5>
                                            <div className="">{formActual.tasaCnmc}€</div>
                                        </div>

                                        <div className="item-data">
                                            <h5 className="">I.E. Hidrocarburos 0,00234 €/kWh</h5>
                                            <div className="">{formActual.iePercent}€</div>
                                        </div>


                                        <div className="item-data">
                                            <h5 className="">Servicios Adicionales por mes</h5>
                                            <div className="">
                                                <input type="number" step="0.01" name="serviciosAdicionalesMonth" onChange={this.onChangeFieldFormActual} value={formActual.serviciosAdicionalesMonth}></input>€

                                          </div>
                                            <h5 className="">Total Servicios</h5>
                                            <div>{formActual.serviciosAdicionales}€</div>
                                        </div>



</div>
                                    <div className="col">


                                        <div className="item-data">
                                            <h5>Peaje salida red local. Término variable consumo {formActual.numMonthNew} meses</h5>
                                            <div className="">{formActual.peajeSalidaTerminoVariableConsumo}€</div>
                                        </div>

                                        <div className="item-data">
                                            <h5>Peaje salida red transporte. Término variable consumo {formActual.numMonthNew} meses</h5>
                                            <div className="">{formActual.peajeSalidaTransporteTerminoVariableConsumo}€</div>
                                        </div>

                                        <div className="item-data">
                                            <h5>Otros costes de regasificación. Término fijo cliente {formActual.numDaysNew} días</h5>
                                            <div className="">{formActual.otrosCostesRegasificacionTerminoFijoCliente}€</div>
                                        </div>

                                        <div className="item-data">
                                            <h5>Término variable consumo {formActual.numDaysNew} días. Precio MIBGAS {mibGasPrice} Coeficiente {formActual.factork}</h5>
                                            <div className="">{formActual.sumEnergia}€</div>
                                        </div>

                                        <div className="item-data">
                                            <h5 className="">Alquiler del Equipo a Medida por mes</h5>
                                            <div className="">
                                                <input type="number" step="any" name="alquilerEquipoMedidaMonth" onChange={this.onChangeFieldFormActual} value={formActual.alquilerEquipoMedidaMonth}></input>€
                                        </div>
                                            <h5 className="">Total Alquiler</h5>
                                            <div>{formActual.alquilerEquipoMedida}€</div>
                                        </div>


                                        <div className="item-data">
                                            <h5 className="">BASE IMPONIBLE</h5>
                                            <div className="">{formActual.bi}€</div>
                                        </div>
                                        <div className="item-data">
                                            <h5 className="">IVA 21%</h5>
                                            <div className="">{formActual.iva}€</div>
                                        </div>


                                        <div className="item-data">
                                            <h5>Término Fijo {formActual.numMonthNew} meses</h5>
                                            <div className="">{formActual.sumPotencia}€</div>
                                        </div>


                                    </div>
                                </Row>
                                <Row className="">
                                    <div className="col item-total">
                                        <h2>
                                            TOTAL
                                    </h2>
                                        <div>
                                            {formActual.total}€
                                    </div>
                                    </div>
                                </Row>




                                <Row className="">
                                    <div className="col  sm">
                                        <h5>
                                            {limitacion}
                                        </h5>
                                    </div>
                                </Row>

                                <Row className="">
                                    <div className="col sm">
                                        <h5>
                                            {info}
                                        </h5>
                                    </div>
                                </Row>
                                <Row className="">
                                    <div className="col sm">
                                        <h5>
                                            {condiciones}
                                        </h5>
                                    </div>
                                </Row>

                            </CardBody>
                        </Form>
                    </Card>

                </Row>

                <Row className="margin-reset w-100">
                    <Card className="mt-3 mb-3 shadow card-billing-results">
                        <CardBody>

                            <>

                                <Card className="mt-3 shadow">
                                    <CardBody>
                                        <Row className="margin-reset w-100">
                                            <h2 className="col item-header">
                                                Comparativa costes periodo facturación
	                            		</h2>
                                        </Row>
                                        <Row className="card-deck">
                                            <div className="col">
                                                <div className='header'>
                                                    <h2 className='title'>Resumen Costes Sin Impuestos</h2>
                                                </div>
                                                <Bar data={data} width={80}
                                                    height={40} options={options} />
                                            </div>
                                            <Card className="">

                                                <Table className="table-actual align-items-center table-flush" responsive key="table-data">
                                                    <thead>
                                                        <th>Periodo Lectura</th>
                                                        <th>Consumo En Wh P1</th>
                                                        <th>Consumo En Wh P2</th>
                                                    </thead>
                                                    <tbody>
                                                        {periodos.includes(1) ?
                                                            <tr>
                                                                <td>P1</td>

                                                                <td>
                                                                    <div className="item-data">
                                                                        <div className="">{formProducto.energiaActivaP1} kW</div>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                            : ''}
                                                        {periodos.includes(2) ?
                                                            <tr>
                                                                <td>P2</td>
                                                                <td>
                                                                    <div className="item-data">
                                                                        <div className="">{formProducto.energiaActivaP2} kW</div>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                            : ''}


                                                    </tbody>
                                                </Table>

                                            </Card>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </>



                            <Card className="mt-3 shadow">
                                <CardBody>

                                    <Row className="margin-reset w-100">

                                        <h2 className="col item-header">
                                            Ahorro periodo facturación
			                					</h2>
                                    </Row>
                                    <Row className="card-deck">
                                        <div className="col">
                                            <div className='header'>
                                                <h2 className='title'>Resumen Costes</h2>
                                            </div>
                                            <HorizontalBar width={40}
                                                height={20} data={dataAhorro} options={options} />
                                        </div>

                                        <Card className="">
                                            <CardBody>
                                                <div className="col-sm">
                                                    <div className="item-data">
                                                        <h6 className="">TOTAL COSTE ACTUAL: </h6>
                                                        <div>{formActual.total} €</div>
                                                    </div>
                                                    <div className="item-data">
                                                        <h6 className="">TOTAL PROPUESTA AHORRO: </h6>
                                                        <div>{formProducto.total} €</div>
                                                    </div>
                                                    <div className="item-data">
                                                        <h6 className="">AHORRO ANUAL: </h6>
                                                        <div>{totalComparacion.ahorroAnual} €</div>
                                                    </div>
                                                    <div className="item-data">
                                                        <h6 className="">AHORRO PERIODO %: </h6>
                                                        <div>{totalComparacion.ahorroPercent}%</div>
                                                    </div>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </Row>
                                </CardBody>
                            </Card>

                        </CardBody>
                    </Card>
                </Row>

            </>
        );
    }
}
