import React from "react";
import memoize from 'memoize-one';
// reactstrap components
import {
    Card,
    CardHeader,
    Container,
    Row,
    Col,
    Button,
    FormGroup,
    Form, Input,
    CardBody, CardTitle, Label
} from "reactstrap";
import { Link } from 'react-router-dom';
//import LoadingOverlay from "react-loading-overlay";
import axios from 'axios';
import moment from "moment";
import 'moment/locale/es'
import ReactBSAlert from "react-bootstrap-sweetalert";

// core components
import Header from "../../components/Headers/Header";

import SipsOption from "../../components/Sips/SipsOption";
//import SipsTable from "../../components/Sips/SipsTable";
import { Query, withApollo } from "react-apollo";

import { chartOptions } from "../../variables/charts";

//import Pagination from "../../components/Pagination/Pagination";
//import { LIST_BUSINESS, LIST_SIPS, EXPORT_SIPS } from "../../queries/sips/sips.graphql";

import {
    CHART_CONTRATO_TOTAL,
    CHART_CONTRATO_ACEPTADOS
} from "../../queries/sips-comparativa/contratacion";

import {
    LIST_USERS
} from "../../components/Sips/SipsOption.graphql";
//import timestampToDate from "../../utils/timestampToDate";
import formatData from "../../utils/formatData";
//import Environment from "../../constants/environment";
import DataTable from 'react-data-table-component';
import Checkbox from '@material-ui/core/Checkbox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
//import memoize from 'memoize-one';
import CircularProgress from '@material-ui/core/CircularProgress';
//import Icon from '@material-ui/icons/Apps';
//import { uuid } from 'uuidv4'
//import {useGlobalState} from 'state-pool'
import Reflux from "reflux";
import { UserStore } from "../../store/UserStore";

//const radioStyle = {
//  display: "flex",
//  justifyContent: "space-between",
//};

const userFromStorage = localStorage.getItem("user");

const parsed = JSON.parse(userFromStorage || '{}');

//const showIndexButtonCol = parsed.indexada;
//const showFreeMarketButtonCol = parsed.mercadolibre;

const companyNameGlobal = parsed.company;
const CompanyIdUser = parsed.companyId;

const userGroupId = parsed.user_group_id
const userId = parsed.id


const sortIcon = <ArrowDownward />;

const capitalize = (s) => {
    if (typeof s !== 'string') return ''
    return s.toLowerCase().charAt(0).toUpperCase() + s.slice(1)
}


const Circular = () => (
    // we need to add some padding to circular progress to keep it from activating our scrollbar
    <div style={{ padding: '24px' }}>
        <CircularProgress size={75} />
    </div>
);

const rowDisabledCriteria = row => row.esAprobada;

const columnsDatos = memoize(clickHandler => [
    /*  {
      // eslint-disable-next-line react/button-has-type
      cell: () => <button onClick={clickHandler}>Aprobar</button>,
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      name: 'APROBAR'
    },*/
    /*{cell: row =>  <Link target="_blank" data-toggle="modal" to={{pathname : "/print/sip-comparative-print/" + row.id}}>{row.nombreOferta}</Link>
    ,
    ignoreRowClick: true,
      allowOverflow: true,
      name: 'NOMBRE DEL CONTRATO',
      minWidth: '55px',
      width: '210px'

    },*/
    { selector: row => row.idContrato, name: 'ID CONTRATO', sortable: true, width: '200px', format: (row => capitalize(row.idContrato)) },
    {
        // eslint-disable-next-line react/button-has-type
        //cell: () => <button className="btn btn-primary btn-sm btn-circle" onClick={clickHandler}></button>,
        //cell: row => <div><div style={{ fontWeight: 700 }}>{row.title}</div>{row.summary}</div>,

        cell: row => <Link
            className="btn btn-primary btn-sm btn-circle"
            data-toggle="modal"
            to={{ pathname: "sip-contrato-detalle/" + row.idContrato + "/" + row.tipoEnergia?.substring(0, 1)||""}}
            id="buscartooltip"
        >
            <i className="pe-7s-search text-white"></i>
        </Link>,

        ignoreRowClick: true,
        allowOverflow: true,
        button: true,
        name: 'INFO',
        minWidth: '55px'

    },
    {
        // eslint-disable-next-line react/button-has-type
        cell: (row) => <Button onClick={clickHandler} color="info" size="sm" className="float-right" id={row.tipoEnergia} value={row.IdOferta}>Detalles</Button>,
        ignoreRowClick: true,
        allowOverflow: true,
        button: true,
    },
    { selector: row => row.cliente, name: 'CLIENTE', sortable: true, width: '240px', center: true, format: (row => capitalize(row.T_Titular.NombreTitular)+" "+capitalize(row.T_Titular.Apellido1Titular)) },
    { selector: row => row.cups, name: 'CUPS', sortable: true, width: '210px', center: true, format: (row => capitalize(row.cups)) },
    { selector: row => row.tipoEnergia, name: 'TIPO CONTRATO', sortable: true, width: '100px', center: true, format: (row => capitalize(row.tipoEnergia)) },
    { selector: row => row.T_PropuestaComercial.Producto, name: 'PRODUCTO', sortable: true, width: '250px', center: true, format: (row => capitalize(row.T_PropuestaComercial.Producto)) },
    {
        selector: row => row.esRenovacion, name: 'RENOVACION', sortable: true, width: '140px', center: true, style: {
            color: 'rgba(0,0,0,.54)',
        }, format: (row => row.esRenovacion ? "RENOVACION" : "NUEVO")
    },
    { selector: row => row.fechaContrato, name: 'FECHA CONTRATO', width: '180px', sortable: true, center: true, format: (row =>moment(row.fechaContrato, 'YYYY-MM-DD').format('DD-MM-YYYY')) },
    { selector: row => row.fechaFirmaContrato, name: 'FECHA FIRMA CONTRATO', width: '180px', sortable: true, center: true, format: (row => moment(row.fechaFirmaContrato, 'YYYY-MM-DD', true).isValid() ? moment(row.fechaFirmaContrato, 'YYYY-MM-DD').format('DD-MM-YYYY') : "") },

    { selector: row => row.fechaInicioContrato, name: 'FECHA INICIO CONTRATO', width: '180px', sortable: true, center: true, format: (row => moment(row.fechaInicioContrato, 'YYYY-MM-DD', true).isValid() ? moment(row.fechaInicioContrato, 'YYYY-MM-DD').format('DD-MM-YYYY') : "") },
    { selector: row => row.fechaFinContrato, name: 'FECHA FIN CONTRATO', width: '180px', sortable: true, center: true, format: (row => moment(row.fechaFinContrato, 'YYYY-MM-DD', true).isValid() ? moment(row.fechaFinContrato, 'YYYY-MM-DD').format('DD-MM-YYYY') : "")  },

    { selector: row => row.fechaActivacion, name: 'FECHA ACTIVACION CONTRATO', width: '180px', sortable: true, center: true, format: (row => moment(row.fechaActivacion, 'YYYY-MM-DD', true).isValid() ? moment(row.fechaActivacion, 'YYYY-MM-DD').format('DD-MM-YYYY') : "")  },
    { selector: row => row.fechaVencimientoContrato, name: 'FECHA VENC. CONTRATO', width: '180px', sortable: true, center: true, format: (row => moment(row.fechaVencimientoContrato, 'YYYY-MM-DD', true).isValid() ? moment(row.fechaVencimientoContrato, 'YYYY-MM-DD').format('DD-MM-YYYY') : "")  },
    { selector: row => row.fechaBajaContrato, name: 'FECHA VENC. CONTRATO', width: '180px', sortable: true, center: true, format: (row => moment(row.fechaBajaContrato, 'YYYY-MM-DD', true).isValid() ? moment(row.fechaBajaContrato, 'YYYY-MM-DD').format('DD-MM-YYYY') : "")  },
    {
        selector: row => row.esBajaContrato, name: 'BAJA', sortable: true, width: '140x', center: true, style: {
            color: 'rgba(0,0,0,.54)',
        }, format: (row => row.esBajaContrato ? "BAJA" : "ACTIVO")
    },
    { selector: row => row.T_DatosAgenteComercial.nombreUsuario, name: 'AGENTE COMERCIAL', sortable: true, width: '250px', center: true, format: (row => capitalize(row.T_DatosAgenteComercial.nombreUsuario)) },
    { selector: row => row.OfertaTipo, name: 'TIPO', sortable: true, width: '100px', center: true, format: (row => capitalize(row.OfertaTipo)) },
    { selector: row => row.ofertaTipoId, name: 'SEQ', sortable: true, width: '100px', center: true },
]);


const columnsDatosGenerales = [
    {
        cell: row => <h5 className="mb-0"> {companyNameGlobal} </h5>,

        ignoreRowClick: true,
        allowOverflow: true,
        name: 'EMPRESA',
        minWidth: '55px',
        width: '200px'

    },
    {
        selector: row => row.totalContratos, name: 'TOTAL CONTRATOS', sortable: true, width: '210px', style: {
            color: 'rgba(0,0,0,.54)',
        },
    },
    { selector: row => row.totalFechaContrato, name: 'TOTAL FECHA CONTRATO', sortable: true, width: '210px', center: false },
    { selector: row => row.totalContratos, name: 'TOTAL CONTRATOS', sortable: true, width: '210px', center: false },
    { selector: row => row.totalRenovados, name: 'TOTAL RENOVACION', sortable: true, width: '210px', center: false },
    { selector: row => row.totalContratosGeneradoEnviado, name: 'TOTAL CONTRATOS GNERADOS', sortable: true, width: '210px', center: false },
    { selector: row => row.totalFirmaContrato, name: 'TOTAL FIRMADOS', sortable: true, width: '210px', center: false },
    { selector: row => row.totalFechaActivacion, name: 'TOTAL FECHA ACTIVACION', sortable: true, width: '210px', center: false },

];



const columnsDatos10Last = memoize(clickHandler => [
    { selector: row => row.cliente, name: 'CLIENTE', sortable: true, width: '240px', format: (row => capitalize(row.T_Titular.NombreTitular +" " +row.T_Titular.Apellido1Titular)) },

    {
        // eslint-disable-next-line react/button-has-type
        cell: (row) => <Button onClick={clickHandler} color="info" size="sm" className="float-right" id={row.dTipoContrato} value={row.IdOferta}>Detalles</Button>,
        ignoreRowClick: true,
        allowOverflow: true,
        button: true,
    },
    { selector: row => row.cups, name: 'CUPS', sortable: true, width: '220px', center: true, format: (row => capitalize(row.cups)) },
    {
        selector: row => row.esRenovacion, name: 'RENOVACION', sortable: true, width: '140px', center: true, style: {
            color: 'rgba(0,0,0,.54)',
        }, format: (row => row.esRenovacion ? "RENOVACION" : "NUEVO")
    },
    { selector: row => row.fechaContrato, name: 'FECHA CONTRATO', width: '180px', sortable: true, center: true, format: (row => moment(row.fechaContrato, 'YYYY-MM-DD').format('DD-MM-YYYY')) },
    { selector: row => row.empleado, name: 'AGENTE COMERCIAL', sortable: true, width: '210px', center: true, format: (row => capitalize(row.T_DatosAgenteComercial.nombreComercial)+ " "+capitalize(row.T_DatosAgenteComercial.apellido1Comercial)) },
    { selector: row => row.fechaFirmaContrato, name: 'FECHA FIRMA CONTRATO', width: '180px', sortable: true, center: true, format: (row => moment(row.fechaFirmaContrato, 'YYYY-MM-DD', true).isValid() ? moment(row.fechaFirmaContrato, 'YYYY-MM-DD').format('DD-MM-YYYY') : "") },

    { selector: row => row.fechaInicioContrato, name: 'FECHA INICIO CONTRATO', width: '180px', sortable: true, center: true, format: (row => moment(row.fechaInicioContrato, 'YYYY-MM-DD', true).isValid() ?  moment(row.fechaInicioContrato, 'YYYY-MM-DD').format('DD-MM-YYYY')  : "") },
    { selector: row => row.fechaFinContrato, name: 'FECHA FIN CONTRATO', width: '180px', sortable: true, center: true, format: (row => moment(row.fechaFinContrato, 'YYYY-MM-DD', true).isValid() ?  moment(row.fechaFinContrato, 'YYYY-MM-DD').format('DD-MM-YYYY')  : "") },

]);


const customStyles = {
    headRow: {
        style: {
            border: 'none',

        },
    },
    headCells: {
        style: {
            color: '#202124',
            fontSize: '12px',
            fontWeight: 'bold',
            wordBreak: 'break-word',
            paddingLeft: '8px',
            paddingRight: '8px',
            minWidth: '55px'

        },
    },
    cells: {
        style: {
            paddingLeft: '8px',
            paddingRight: '8px',
            minWidth: '55px',
            wordBreak: 'break-word',
            fontFamily: '"Open Sans", sans-serif;'
        },
    },
    rows: {
        highlightOnHoverStyle: {
            backgroundColor: 'rgb(230, 250, 250)',
            borderBottomColor: '#FFFFFF',
            borderRadius: '25px',
            outline: '1px solid #FFFFFF',
            fontSize: '12px',
        },
    },
    pagination: {
        style: {
            border: 'none',
        },
    },
};



class GestionContratos extends Reflux.Component<any, any> {
    lastFiltersEmpre = {};
    state = {
        UserStore: {},
        isLoading: false,
        isSearching: false,
        copiedText: null,
        currentPage: 0,
        pageSize: 0,
        pageCount: 0,
        count: 0,
        page: 1,
        limit: 200,
        paginatedData: { items: [{ CIF: '' }] },
        paginatedDataTemp: { items: [] },
        showIndexButton: false,
        showFreeMarketButton: false,
        sumOfEnergy: 0,
        sumOfEnergyLast: 0,
        cupsGroupedBydistri: [],
        cupsGroupedBydistriEnergy: [],
        cupsGroupedByATREnergy: [],
        cupsGroupedByComer: [],
        cupsGroupedByATR: [],
        cupsGroupedByProvincia: [],
        cupsGroupedByComercialEnergy: [],
        cupsGroupedByComercialEnergyCont: [],
        EnergiaTotalATRCont: [],
        datosEmpresa: {
            CIF: "",
            CNAE: "",
            Calle: "",
            CodigoPostal: "",
            Consolidada: "",
            Ejercicio: "",
            FormaJuridica: "",
            IngExplota: "",
            LiteralCNAE: "",
            Localidad: "",
            Marca: "",
            Nombre: "",
            Proveedor: "",
            Provincia: "",
            Telefono: "",
            Web: ""

        },
        filters: {
            comercial: '',
            fechaInicio: '',
            fechaFin: '',
            codeComercializadora: '',
            codeComercializadoraGas: '',
            codigoAutonomiaPS: '',
            codigoMunicipioPS: '',
            provincia: '',
            contrato: '',
            esRenovacion: false,
            esBajaContrato: false
        },
        alert: null,
        setFilters: {
            comercial: '',
            fechaInicio: '',
            fechaFin: '',
            codeComercializadora: '',
            codeComercializadoraGas: '',
            codigoAutonomiaPS: '',
            codigoMunicipioPS: '',
            provincia: '',
            contrato: '',
            esRenovacion: false,
            esBajaContrato: false

        },
        dataAceptadosContratos: [],
        codegas: '',
        codeele: '',
        companyIdUser: '',
        companyNameUser: '',
        userGroupId: 1000,
        userId: '',
        dataCountContratos: [],
        dataResumenContratos:{
            totalFirmaContrato: 0,
            totalContratos: 0,
            totalEnEspera: 0,
            totalContratosGeneradoEnviado: 0,
totalFechaContrato:0,
            totalFechaActivacion:0
        },
        dataUltimosContratos: [],
        dataGeneralContratos: [],
        ListaUsuarios: [
            {
                id: "0",
                first_name: "sin datos",
            },
        ],
        activeUsers: false,
        activeUser: false,
        selectedRows: [],
        user: '',
        producto: [],
        selectProductButtonDisabled: true,
        toggledClearRows: false,
        setPending: true,
        dataContractTrin: [],
        alineado: 'right',
        comerListDisabled: false,
        comerGasListDisabled: false,
        dataIsReturned1: false,
        dataIsReturned2: false,
        contratos: false

    };

    constructor(props) {
        super(props);
        this.store = UserStore;

    }

    componentWillReceiveProps() {
        //console.log(this.props)
        console.log(this.props)
        console.log(this.state)

        const usuario: any = JSON.parse(JSON.stringify(this.state.UserStore['data']))

        console.log('VALOR DE USUARIO ----------------->', usuario)

        const companyNameGlobal = usuario.company;
        const companyIdUser = usuario.companyId;

        const userGroupId = usuario.user_group_id
        const userId = usuario.id

        let showIndexButton = usuario.indexada;
        let showFreeMarketButton = usuario.mercadolibre;
        let companyNameUser = usuario.company;
        let contratos = usuario.contratos

        if (userGroupId === 1000) {
            this.setState({
                activeUser: true
            })
        };

        this.setState({
            companyNameGlobal,
            companyIdUser,
            userGroupId,
            userId,
            showIndexButton,
            showFreeMarketButton,
            companyNameUser,
            contratos

        });
    }

    UNSAFE_componentWillMount() {

        console.log('VALORES DE THIS.STATE. EN COMPONENT WILL MOu-nt-------->', this.state)


        var userFromStorageTemp = localStorage.getItem("user");
        console.log(userFromStorageTemp)
        var parsedTemp = JSON.parse(userFromStorageTemp || '{}');
        var userIdTemp = parsedTemp.id;
        var companyIdUserTemp = parsedTemp.companyId;
        var userGroupId = parsedTemp.user_group_id;
        var company = parsedTemp.company;

     
        this.setState({

            companyIdUser: companyIdUserTemp,
            userId: userIdTemp,
            userGroupId: userGroupId,
            companyNameUser: company

        });


    };


    componentDidMount() {

        const {
            match: { params },
        } = this.props;


        this.QueryContratos();
        //this.ContractsCount();
        //this.AcceptedCount();

        this.QueryResumenContratos();
        //  this.QueryUsers();


    }

    submit = () => {

        console.log('VALOR DE THIS.STATE EN SUBMIT ----------------> ,', this.state)
        if (this.state.setFilters.fechaInicio && !this.state.setFilters.fechaFin || !this.state.setFilters.fechaInicio && this.state.setFilters.fechaFin) {
            this.warningAlert();

        } else {

            this.QueryContratos();

        }

    };


    hideAlert = () => {
        this.setState({
            alert: null
        });
    };

    warningAlert = () => {
        this.setState({
            alert: (
                <ReactBSAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Error en las fechas"
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="warning"
                    confirmBtnText="Ok"
                    btnSize=""
                >
                    Debe poner rango de búsqueda en los campos de fechas. Revise los datos introducidos
   </ReactBSAlert>
            )
        });
    };

    warningAlertDB = () => {
        this.setState({
            alert: (
                <ReactBSAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Error en la base de datos"
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="warning"
                    confirmBtnText="Ok"
                    btnSize=""
                >
                    Existe un error en la Base de datos.
       </ReactBSAlert>
            )
        });
    };



    onChangeField = (event) => {


        const name = event.target.name;
        let value = event.target.value;
        var setFilters = this.state.setFilters;

        setFilters[name] = value;
        if (event.target.type === "select-one" && value === "0") {
            delete setFilters[name];
        }


        if (value === "" || value === null) {
            delete setFilters[name];
        }



        console.log('VALOR DE setFilters ------------->>>> ', setFilters)

        if (name == 'codeComercializadoraGas' && event.target.type === "select-one" && value === "0") {
            this.setState({
                codegas: '',
                comerListDisabled: false
            })
        } else if (name == 'codeComercializadoraGas' && event.target.type === "select-one" && value != "0") {
            this.setState({
                codegas: value,
                comerListDisabled: true
            })
        }

        if (name == 'codeComercializadora' && event.target.type === "select-one" && value === "0") {
            this.setState({
                codeele: '',
                comerGasListDisabled: false
            })
        } else if (name == 'codeComercializadora' && event.target.type === "select-one" && value != "0") {
            this.setState({
                codeele: value,
                comerGasListDisabled: true
            })
        }

        this.setState({ setFilters });
    };

    QueryContratos = async () => {
        let isLoading = true;
        const isSearching = true;
        let { page, limit } = this.state;
        //  const isSearching = true;
        this.setState({ isLoading, isSearching });
        //this.lastFiltersEmpre = filters;

        if (this.lastFiltersEmpre.hasOwnProperty("limit")) {
            this.state.limit = parseInt('200');
        }
        if (
            (!this.state.limit || this.state.limit === 0) &&
            (this.state.pageSize !== null || this.state.pageSize > 1)
        ) {
            this.state.limit = this.state.pageSize;
        }

        //let { page, limit } = this.state;

        const companyId = this.state.companyIdUser.toString()
        const userId = '-1'
        var codeComercializadora = ''
        var datos = this.state.setFilters


        if ( !datos.codeComercializadora && datos.codeComercializadoraGas) {
            codeComercializadora= datos.codeComercializadoraGas

        } else if (!datos.codeComercializadoraGas && datos.codeComercializadora) {
            codeComercializadora= datos.codeComercializadora

        }

        if (this.state.codegas != '' && !this.state.codeele) {

            codeComercializadora = this.state.codegas
        }
        if (this.state.codeele != '' && !this.state.codegas) {

            codeComercializadora = this.state.codeele
        }

        if (this.state.codeele == '' && this.state.codegas == '') {

            codeComercializadora = ''
        }



        var userIdAux = this.state.userId.toString();
        var userQuery = this.state.userId.toString();
        //const IdOferta = this.state.contrato;
        if (this.state.userGroupId == 1000) {
            userQuery = datos.comercial
        } else {
            userQuery = this.state.userId.toString();
        }


        var dataFilters = {
            'compania': "",
            'cups': "",
            'Usuario': userQuery,
            'NIF': "",
            'idContrato': datos.contrato,
            'codigoMunicipioPS': datos.codigoMunicipioPS,
            'codigoAutonomiaPS': datos.codigoAutonomiaPS,
            'codigoProvinciaPS': datos.provincia,
            'idComercializadora': codeComercializadora,
            'fechaAprobacionHasta': datos.fechaFin,
            'esRenovacion': datos.esRenovacion,
            'esBajaContrato': datos.esBajaContrato
        };
        var contratoDato: any = []





        var valores = JSON.stringify(dataFilters)
        const headers = {
            'Authorization': 'Bearer my-token',
            'My-Custom-Header': 'foobar',
            'x-api-key': '$2y$10$bcT1Ukm4V/6/z5GvitZv0unu8I91mpixuwUO6z5CyEGev9DDDY28W'
        };
        let direccionFile = "https://datapi.psgestion.es/contrato/listadomulti?id=" + companyId + "&user=" + userId;

        await axios.get(direccionFile, { headers, params: { valores } }).
            then(result => {
                console.log('resultado del listados')
                console.log(result.data.rows)
                let data = result.data.rows
                var longData = data.length
                console.log(longData)
                var longitud = 10;
                if (longData < 10){
                    longitud = longData
                }
                var arr2: string[] = [];
                    for( var n = 0; n < longitud; n++){
                        console.log('valor de data ---->', data)
                        arr2.push(data[n]);
                        }

                this.setState({
                    dataGeneralContratos: data,
                    dataUltimosContratos: arr2,
                    setPending: false
                });
            }).catch((error) => {
                console.log('Failure ', error)
                this.warningAlertDB();
            });


};

    QueryResumenContratos = async () => {

        const companyId = this.state.companyIdUser.toString()
        const userId = '-1'
        var datos = this.state.setFilters
        var userIdAux = this.state.userId.toString();
        var userQuery = this.state.userId.toString();
        //const IdOferta = this.state.contrato;
        if (this.state.userGroupId == 1000) {
            userIdAux = "1000"
        } else {
            userIdAux = this.state.userId.toString();
        }

    const headers = {
            'Authorization': 'Bearer my-token',
            'My-Custom-Header': 'foobar',
            'x-api-key': '$2y$10$bcT1Ukm4V/6/z5GvitZv0unu8I91mpixuwUO6z5CyEGev9DDDY28W'
        };
        let direccionFile = "https://datapi.psgestion.es/contrato/resumen?id=" + companyId + "&user=" + userIdAux;

        await axios.get(direccionFile, { headers }).
            then(result => {
                console.log('resultado del listados')
                console.log(result.data.rows)
                let data = result.data.rows[0]

                this.setState({
                    dataResumenContratos: data
                });
            }).catch((error) => {
                console.log('Failure ', error)
                this.warningAlertDB();
            });

    };

    ContractsCount = async () => {
        let isLoading = true;
        const isSearching = true;

        const companyId = this.state.companyIdUser.toString()

        const userId = this.state.userId.toString()
        const time = ''
        const userGroupId = this.state.userGroupId.toString()
        await this.props.client.query({
            query: CHART_CONTRATO_TOTAL,
            variables: { time, userId, companyId, userGroupId },
        }).then(res => {
            //console.log('DATOS DEVUELTOS POR DATA_count_CONTRATOS ------>', res)
            const data = res.data;
            if (data) {
                const chartData = formatData(data);
                //console.log('VALOR DE contratos chartdata ----> ', chartData)
                this.setState({
                    dataCountContratos: chartData,
                    dataIsReturn1: true
                });
            } else {
                this.setState({
                    dataCountContratos: [],
                    dataIsReturn1: true
                });

            }
        })
            .catch(err => {
                isLoading = false;
                this.setState({ isLoading });
            })
    };


    AcceptedCount = async () => {
        let isLoading = true;
        const isSearching = true;
        const filters = this.lastFiltersEmpre;


        const companyId = this.state.companyIdUser.toString()
        const userId = this.state.userId.toString()
        const time = ''
        const userGroupId = this.state.userGroupId.toString()
        await this.props.client.query({
            query: CHART_CONTRATO_ACEPTADOS,
            variables: { time, userId, companyId, userGroupId },
        }).then(res => {
            //console.log('DATOS DEVUELTOS POR DATA_ACCEPT_CONTRATOS ------>', res)
            const data = res.data.aceptadosContratosTrimestre;

            /*const dataaceptados = res.data.aceptadosTrimestre;
            const dataenespera = res.data.esperaTrimestre;
            let dataContractTrin:any[] = [];
            dataaceptados.map((row) => {
              console.log(row)
            //for(var i=0;i<=dataaceptados.length;i++){
              let fecha = row['anio']+"-"+row['label'];
              let estado = "Aceptados";
              let valor = parseInt(row['data']);
              //console.log('VALOR DE FECHA ACEPTADO ----> ', fechaAceptado)

              dataContractTrin.push({ fecha: fecha, estado: estado, valor: valor});



            //}
          })
          dataenespera.map((row) => {
            console.log(row)
          //for(var i=0;i<=dataaceptados.length;i++){
            let fecha = row['anio']+"-"+row['label'];
            let estado = "No Aceptados";
            let valor = parseInt(row['data']);
            //console.log('VALOR DE FECHA ACEPTADO ----> ', fechaAceptado)

            dataContractTrin.push({ fecha: fecha, estado: estado, valor: valor});

          //}
        })
           console.log('VALOR DE DATACONTRACTTRIN --->', dataContractTrin)
        */
            this.setState({
                dataAceptadosContratos: data,
                dataIsReturn2: true
                //dataContractTrin : dataContractTrin
            });
        })
            .catch(err => {
                isLoading = false;
                this.setState({ isLoading });
            })
    };


    QueryUsers = async () => {
        let isLoading = true;
        const isSearching = true;




        const companyId = this.state.companyIdUser.toString()
        const userId = ''
        const time = ''
        await this.props.client.query({
            query: LIST_USERS,
            variables: { companyId },
        }).then(res => {
            //console.log('DATOS DEVUELTOS POR DATA_ACCEPT_CONTRATOS ------>', res)
            const data = res.data;

            this.setState({
                ListaUsuarios: data
            });
        })
            .catch(err => {
                isLoading = false;
                this.setState({ isLoading });
            })
    };



    //handleButtonClick = () => {
    // eslint-disable-next-line no-console
    //console.log('clicked');
    //};


    handleClearRows = () => {
        this.setState({ toggledClearRows: !this.state.toggledClearRows })
    }

    handleButtonClick = (state) => {
        // eslint-disable-next-line no-console
        state.persist()
        const tipo = state.target.id;
        const id = state.target.value
        const compania = this.state.companyIdUser
        console.log('VALORES DE state', state)
        if (tipo == 'L' || tipo == "ELECTRICIDAD") {
            console.log('entra')

            const win = window.open("/print/sip-propuesta-print/" + id+"/"+compania, "_blank");
            if (win != null) {
                win.focus();
            }
        } else if (tipo == 'G' || tipo == "GAS") {
            const win = window.open("/print/sipgas-comparative-print/" + id+"/"+compania, "_blank");
            if (win != null) {
                win.focus();
            }

        }

        //  console.log(e);
    };

    alerta = (e) => {
        // eslint-disable-next-line no-console
        console.log('clicked');
        console.log(e);
    };

    render() {
        console.log('DENTRO DE Empresa')
        console.log(this.state)
        //console.log(this.props)
        var titulo10 = '';
        if (userGroupId === 1000) {
            titulo10 = "ULTIMOS 10 CONTRATOS - " + companyNameGlobal;
        } else {
            titulo10 = "TUS ULTIMOS CONTRATOS";
        }

        const propuestasGeneral = this.state.dataGeneralContratos;
        //console.log('VALORES DE this.state.dataCountContratos ---->', this.state.dataCountContratos)
        const datosTrimestre = { totalTrimestre: this.state.dataCountContratos };

        const ListaUsuarios = this.state.ListaUsuarios;
        const companyIdUser = this.state.companyIdUser;
        const company = this.state.companyNameUser





        if (this.state.contratos) {
            return (
                <>
                    <Header title={parsed.company !== "" ? "Detalles | Contratos " + company : "Detalles | Resumen de contratos"} />
                    <Container id="resumen_general" className="mt--7" fluid>
                        <Row className="margin-reset w-100">

                            <Col md="6" xl="3">
                                <Card className="card-stats">
                                    <CardBody>
                                        <Row>
                                            <div className="col">
                                                <CardTitle
                                                    tag="h5"
                                                    className="text-uppercase text-muted mb-0"
                                                >
                                                    TOTAL CONTRATOS
                        </CardTitle>
                                                <span className="h2 font-weight-bold mb-0">
                                                    {this.state.dataResumenContratos.totalContratos || 0}
                                                </span>
                                            </div>
                                            <Col className="col-auto">
                                                <div className="icon icon-shape bg-gradient-red text-white rounded-circle shadow">
                                                    <i className="bi bi-files" />
                                                </div>
                                            </Col>
                                        </Row>
                                        <p className="mt-3 mb-0 text-sm">
                                            <span className="text-nowrap">Contratos totales últimos 3 meses</span>
                                        </p>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col md="6" xl="3">
                                <Card className="card-stats">
                                    <CardBody>
                                        <Row>
                                            <div className="col">
                                                <CardTitle
                                                    tag="h5"
                                                    className="text-uppercase text-muted mb-0"
                                                >
                                                    ACEPTADOS
                        </CardTitle>
                                                <span className="h2 font-weight-bold mb-0">
                                                    {this.state.dataResumenContratos.totalFechaContrato || 0}
                                                </span>
                                            </div>
                                            <Col className="col-auto">
                                                <div className="icon icon-shape bg-gradient-green text-white rounded-circle shadow">
                                                    <i className="bi bi-check2-circle" />
                                                </div>
                                            </Col>
                                        </Row>
                                        <p className="mt-3 mb-0 text-sm">
                                            {" "}
                                            <span className="text-nowrap">Contratos aceptados últimos 3 meses</span>
                                        </p>
                                    </CardBody>
                                </Card>
                            </Col>

                            <Col md="6" xl="3">
                                <Card className="card-stats">
                                    <CardBody>
                                        <Row>
                                            <div className="col">
                                                <CardTitle
                                                    tag="h5"
                                                    className="text-uppercase text-muted mb-0"
                                                >
                                                    FIRMADOS
                            </CardTitle>
                                                <span className="h2 font-weight-bold mb-0">
                                                    {this.state.dataResumenContratos.totalFirmaContrato || 0}
                                                </span>
                                            </div>
                                            <Col className="col-auto">
                                                <div className="icon icon-shape bg-gradient-green text-white rounded-circle shadow">
                                                    <i className="bi bi-file-medical" />
                                                </div>
                                            </Col>
                                        </Row>
                                        <p className="mt-3 mb-0 text-sm">
                                            {" "}
                                            <span className="text-nowrap">Contratos firmados últimos 3 meses</span>
                                        </p>
                                    </CardBody>
                                </Card>
                            </Col>

                            <Col md="6" xl="3">
                                <Card className="card-stats">
                                    <CardBody>
                                        <Row>
                                            <div className="col">
                                                <CardTitle
                                                    tag="h5"
                                                    className="text-uppercase text-muted mb-0"
                                                >
                                                    FECHA DE ACTIVACION
                        </CardTitle>
                                                <span className="h2 font-weight-bold mb-0">
                                                    {this.state.dataResumenContratos.totalFechaActivacion || 0}
                                                </span>
                                            </div>
                                            <Col className="col-auto">
                                                <div className="icon icon-shape bg-gradient-blue text-white rounded-circle shadow">
                                                    <i className="bi bi-lightning" />
                                                </div>
                                            </Col>
                                        </Row>
                                        <p className="mt-3 mb-0 text-sm">
                                            {" "}
                                            <span className="text-nowrap">Contratos con fecha de activación asignada</span>
                                        </p>
                                    </CardBody>
                                </Card>
                            </Col>


                            <Col md="6" xl="3">
                                <Card className="card-stats">
                                    <CardBody>
                                        <Row>
                                            <div className="col">
                                                <CardTitle
                                                    tag="h5"
                                                    className="text-uppercase text-muted mb-0"
                                                >
                                                    CONTRATOS ENVIADOS
                        </CardTitle>
                                                <span className="h2 font-weight-bold mb-0">
                                                    {this.state.dataResumenContratos.totalContratosGeneradoEnviado || 0}
                                                </span>
                                            </div>
                                            <Col className="col-auto">
                                                <div className="icon icon-shape bg-gradient-red text-white rounded-circle shadow">
                                                    <i className="bi bi-send-check" />
                                                </div>
                                            </Col>
                                        </Row>
                                        <p className="mt-3 mb-0 text-sm">
                                            {" "}
                                            <span className="text-nowrap"> Contratos enviados a comercializadora</span>
                                        </p>
                                    </CardBody>
                                </Card>
                            </Col>

                        </Row>


                        <Row id="contratos_resumen" className="card-deck mt-2">
                            <Card className="shadow">
                                <DataTable
                                    title={titulo10}
                                    columns={columnsDatos10Last(this.handleButtonClick)}

                                    data={this.state.dataUltimosContratos}
                                    sortIcon={sortIcon} // use a material icon for our sort icon. rdt will rotate the icon 180 degrees for you
                                    fixedHeader={true}
                                    noDataComponent={"Sin datos para mostrar"}
                                    progressPending={this.state.setPending}
                                    progressComponent={<Circular />}
                                    dense={true}

                                />
                            </Card>
                        </Row>
                    </Container>
                    <Container id="contratos_empresa" className="mt-3" fluid>



                        <Row id="filtros_contratos" className="card-deck mt-5">
                            <Card className="shadow">
                                <CardHeader>

                                    <h3 className="mb-0">{userGroupId === 1000 ? "Filtrar contratos" : "Filtra tus contratos"}</h3>
                                </CardHeader>
                                <CardBody>
                                    <Form>
                                        <Row>
                                            <Col md="4">


                                                <FormGroup>
                                                    <Label className="form-control-label" htmlFor="comercial">
                                                        Nombre Agente Comercial
                </Label><br />

                                                    {this.state.activeUser ? <><Input type="select" defaultValue={'DEFAULT'} name="comercial" id="comercial" onChange={this.onChangeField}>
                                                        <option value=""> --Selecciona un comercial-- </option>
                                                        <Query query={LIST_USERS} variables={{ companyId: companyIdUser.toString() }}>
                                                            {({ data }) => {
                                                                if (data && data.listUsers.length !== 0) {
                                                                    return data.listUsers.map((item, key) => (
                                                                        <option key={key} value={item.id}>{item.first_name}</option>
                                                                    ));
                                                                }

                                                                return null;
                                                            }}
                                                        </Query>
                                                    </Input></> : null}
                                                </FormGroup>
                                            </Col>
                                            <Col md="4">
                                                <FormGroup>
                                                    <Label className="form-control-label" htmlFor="contrato">
                                                        Nombre contrato
                </Label>
                                                    <Input type="text" name="contrato" id="contrato"
                                                        placeholder="Nombre del contrato..."
                                                        value={this.state.setFilters.contrato}
                                                        onChange={this.onChangeField}
                                                    />
                                                </FormGroup>
                                            </Col>

                                        </Row>
                                        <Row>
                                            <Col md="4">
                                                <FormGroup>
                                                    <Label className="form-control-label" htmlFor="comercializadora">
                                                        Comercializadora Electricidad
              </Label><br />
                                                    <SipsOption
                                                        placeholder="Seleccionar comercializadora"
                                                        id="codeComercializadora"
                                                        name="codeComercializadora"
                                                        value={this.state.setFilters.codeComercializadora}
                                                        query="LIST_MARKETERS"
                                                        onChange={this.onChangeField}
                                                        disabled={this.state.comerListDisabled}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md="4">
                                                <FormGroup>
                                                    <Label
                                                        className="form-control-label"
                                                        htmlFor="ComercializadoraGas"
                                                    >
                                                        Comercializadora Gas
                    </Label>
                                                    <SipsOption
                                                        placeholder="Seleccionar comercializadora"
                                                        id="codeComercializadoraGas"
                                                        name="codeComercializadoraGas"
                                                        value={this.state.setFilters.codeComercializadoraGas}
                                                        query="LIST_MARKETERS_GAS"
                                                        onChange={this.onChangeField}
                                                        disabled={this.state.comerGasListDisabled}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col md="4">
                                                <FormGroup>
                                                    <Label className="form-control-label" htmlFor="fechaInicio">Fecha inicio</Label>
                                                    <Input type="date" name="fechaInicio" id="fechaInicio" placeholder="Fecha inicio"
                                                        onChange={this.onChangeField}
                                                        value={this.state.setFilters.fechaInicio}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md="4">
                                                <FormGroup>
                                                    <Label className="form-control-label" htmlFor="fechaFin">Fecha fin</Label>
                                                    <Input type="date" name="fechaFin" id="fechaFin" placeholder="Fecha fin"
                                                        onChange={this.onChangeField}
                                                        value={this.state.setFilters.fechaFin}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md="4">
                                                <br />
                                                <FormGroup>
                                                    <Label className="form-control-label" htmlFor="esRenovacion">
                                                        <Input
                                                            id="esRenovacion"
                                                            name="esRenovacion"
                                                            checked={this.state.setFilters.esRenovacion}
                                                            onChange={e => this.setState({ setFilters: { esRenovacion: e.target.checked } })}
                                                            type="checkbox"
                                                        />{' '}
                                                        Contrato Renovación
                </Label>
                                                </FormGroup>
                                                <FormGroup>
                                                    <Label className="form-control-label" >
                                                        <Input
                                                            id="esBajaContrato"
                                                            name="esBajaContrato"
                                                            checked={this.state.setFilters.esBajaContrato}
                                                            onChange={e => this.setState({ setFilters: { esBajaContrato: e.target.checked } })}
                                                            type="checkbox"
                                                        />{' '}
                                                        Contrato Baja
              </Label>

                                                </FormGroup>

                                            </Col>
                                        </Row>
                                        <Button color="success" size="sm" className="float-right"
                                            onClick={this.submit}
                                        >
                                            <i className={"bi bi-search"} /> Buscar
          </Button>
                                    </Form>
                                </CardBody>
                            </Card>
                        </Row>


                        <Row id="contratos_resumen" className="card-deck mt-2">
                            <Card className="shadow">
                                <DataTable
                                    title="LISTADO DE CONTRATOS"
                                    columns={columnsDatos(this.handleButtonClick)}
                                    data={propuestasGeneral}
                                    sortIcon={sortIcon} // use a material icon for our sort icon. rdt will rotate the icon 180 degrees for you           fixedHeader = {true}
                                    noDataComponent={"Sin datos para mostrar"}
                                    pagination
                                    paginationPerPage={10}
                                    paginationRowsPerPageOptions={[10, 15, 20, 25, 30, 40]}
                                    selectableRowDisabled={rowDisabledCriteria}
                                    clearSelectedRows={this.state.toggledClearRows}
                                    highlightOnHover
                                    striped
                                    pointerOnHover
                                    selectableRowsHighlight
                                    customStyles={customStyles}
                                    contextMessage={{ singular: 'Contrato', plural: 'Contratos', message: 'seleccionadas' }}
                                    subHeader
                                    progressPending={this.state.setPending}
                                    progressComponent={<Circular />}
                                />
                            </Card>
                        </Row>
                        {this.state.alert}



                    </Container>
                </>
            );
        } else {
            return (
                <>
                    <Header title={"No tienes permisos para acceder a este apartado"} />
                </>
            );
        }
    }
}

export default withApollo(GestionContratos);
