import gql from "graphql-tag";

export const LIST_COMERCIAL_PRODUCTOS = gql`
query comercialProductos($companyId: Int!, $periodos: String!, $periodosPotencia: String!) {
    comercialProductos(companyId: $companyId, peaje: $periodos, NumeroPeriodosPotencia: $periodosPotencia) {
        id
        companyId
        nombreOferta
        idComercializadora
        nombreComercializadora
        caractiristicas
        infoAdicional
        precioTP1
        precioTP2
        precioTP3
        precioTP4
        precioTP5
        precioTP6
        precioTEP1
        precioTEP2
        precioTEP3
        precioTEP4
        precioTEP5
        precioTEP6
        descuentoTE
        descuentoTP
        peaje
        consumoMinimoGas
        consumoMaximoGas
        consumoMinimoElectricidad
        consumoMaximoElectricidad
        potenciaMinimaElectricidad
        potenciaMaximaElectricidad
        periodoValidex
        limitaciones
        condicionesPenalizacion
        penalizacionMaxElec
        penalizacionMaxGas
        ofertaTel
        ofertaInternet
        ofertaOficina
        facturacion
        periodicidadRevisionPrecios
        condicionesRevisionPrecios
        componentesRegulados
        atencionCliente
        webOferta
        webContrato
        serviciosObligatorios
        precioServicios
        precioAlquilerContadore
        serviciosNoObligatorios
        precioTPTipo
        tarifaATR
        precioTPPeriodo
        verde
        periodoValidezDesde
        periodoValidezHasta
        esActiva
      coIncluido
    }
    }
`;

export const LIST_COMERCIAL_PRODUCTOSGAS = gql`
query comercialGasProductos($companyId: Int!, $periodos: String!, $consumoGasAnual: String!, $tarifa: String!) {
    comercialGasProductos(companyId: $companyId, peaje: $periodos, consumoGasAnual: $consumoGasAnual, tarifa: $tarifa ) {
      id
      nombreOferta
      idComercializadora
      nombreComercializadora
      caracteristicas
      infoAdicional
      precioTE1
      precioTE2
      precioTFijo
      descuentoTE
      peaje
      consumoMinimoGas
      consumoMaximoGas
      periodoValidez
      limitaciones
      condicionesPenalizacion
      penalizacionMaxGas
      ofertaTel
      ofertaInternet
      ofertaOficina
      facturacion
      periodicidadRevisionPrecios
      condicionesRevisionPrecios
      componentesRegulados
      atencionCliente
      webOferta
      webContrato
      serviciosObligatorios
      precioServicios
      precioAlquilerContadore
      serviciosNoObligatorios
      tarifaATR
      verde
      periodoValidezDesde
      periodoValidezHasta
      esActiva
      companyId

    }
    }
`;

export const DATA_COMERCIAL_PRODUCTOGAS_BY_ID = gql`
query comercialGasProductoData( $id: Int) {
    comercialGasProductoData( id: $id){
      id
      nombreOferta
      idComercializadora
      nombreComercializadora
      caracteristicas
      infoAdicional
      precioTE1
      precioTE2
      precioTFijo
      descuentoTE
      peaje
      consumoMinimoGas
      consumoMaximoGas
      periodoValidez
      limitaciones
      condicionesPenalizacion
      penalizacionMaxGas
      ofertaTel
      ofertaInternet
      ofertaOficina
      facturacion
      periodicidadRevisionPrecios
      condicionesRevisionPrecios
      componentesRegulados
      atencionCliente
      webOferta
      webContrato
      serviciosObligatorios
      precioServicios
      precioAlquilerContadore
      serviciosNoObligatorios
      tarifaATR
      verde
      periodoValidezDesde
      periodoValidezHasta
      esActiva
      companyId
    }
    }
`;

export const DATA_COMERCIAL_PRODUCTO_BY_ID = gql`
query comercialProductoData( $id: Int) {
    comercialProductoData( id: $id){
        id
        companyId
        nombreOferta
        idComercializadora
        nombreComercializadora
        caractiristicas
        infoAdicional
        precioTP1
        precioTP2
        precioTP3
        precioTP4
        precioTP5
        precioTP6
        precioTEP1
        precioTEP2
        precioTEP3
        precioTEP4
        precioTEP5
        precioTEP6
        descuentoTE
        descuentoTP
        peaje
        consumoMinimoGas
        consumoMaximoGas
        consumoMinimoElectricidad
        consumoMaximoElectricidad
        potenciaMinimaElectricidad
        potenciaMaximaElectricidad
        periodoValidex
        limitaciones
        condicionesPenalizacion
        penalizacionMaxElec
        penalizacionMaxGas
        ofertaTel
        ofertaInternet
        ofertaOficina
        facturacion
        periodicidadRevisionPrecios
        condicionesRevisionPrecios
        componentesRegulados
        atencionCliente
        webOferta
        webContrato
        serviciosObligatorios
        precioServicios
        precioAlquilerContadore
        serviciosNoObligatorios
        precioTPTipo
        tarifaATR
        precioTPPeriodo
        verde
        periodoValidezDesde
        periodoValidezHasta
        esActiva
    }
    }
`;

export const LIST_COMERCIAL_SERVICIOS = gql`
query comercialServicios($idComercializadora: String!) {
    comercialServicios(idComercializadora: $idComercializadora) {
      id
      idComercializadora
      nombreComercializadora
      nombreServicio
      caracteristicas
      infoAdicional
      precio
      tipo
      descuento
      esActiva
      ambito
    }
    }
`;
