import React, { Component } from "react";
import { CustomInput, Table, UncontrolledTooltip} from "reactstrap";

import { Link } from "react-router-dom";
import shortid from 'shortid';

interface SipsTableInterface {
  fields: Object;
  expandableFields: Object;
  items: Array<any>;
  hideViewMoreButton?: Boolean;
  showIndexButton?: Boolean;
  showFreeMarketButton?: Boolean;
  checkBoxButton?: Boolean;
  showProblemInfo?: Boolean;
  parentState?: Object;
  beforeRenderItem?: Function;
  selectedItem?: Function
}
interface SipsTableStateInterface {
  expandedRows: Array<string>;
  selected: Array<any>;
}

export default class SipsTable extends Component<
  SipsTableInterface,
  SipsTableStateInterface
> {

  state = {
    expandedRows: new Array<string>(),
    selected:  new Array<any>()
  };

  async componentDidMount() {
  }

  handleRowClick(rowId: string) {

    const currentExpandedRows = this.state.expandedRows;
    const isRowCurrentlyExpanded = currentExpandedRows.includes(rowId);

    const newExpandedRows = isRowCurrentlyExpanded
      ? currentExpandedRows.filter((id) => id !== rowId)
      : currentExpandedRows.concat(rowId);

    this.setState({ expandedRows: newExpandedRows });
  }

  renderHeader = () => {
    const keys = Object.values(this.props.fields);

    const keysTh = keys.map((item) => {
      return (
        <th scope="col" key={item}>
          {item}
        </th>
      );
    });

    let ViewMoreButtonSpace =
      this.props.hideViewMoreButton && !this.props.showProblemInfo ? null : (
        <th></th>
      );

    let CheckBoxButtonSpace =
      !this.props.checkBoxButton ? null : (
        <th></th>
      );


    return (
      <thead className="thead-light">
        <tr>
          {CheckBoxButtonSpace}
          {ViewMoreButtonSpace}
          {keysTh}
        </tr>
      </thead>
    );
  };

  renderItem = (item) => {

    //console.log("ITEM en sipstable",item);
    if(Object.keys(item).length < 2){
      return null;
    }

    function isEmpty(obj) {
      for (var key in obj) {
        if (obj.hasOwnProperty(key)) return false;
      }
      return true;
    }
    let clickCallback = () => this.handleRowClick(item.cups);
    //console.log(this.props.expandableFields);
    const hasExtraFields = !isEmpty(this.props.expandableFields);
    //const hasExtraFields = "";
    if (!hasExtraFields) {
      clickCallback = () => null;
    }

    const keys = Object.keys(this.props.fields);
    // console.log("keyssss",this.props.fields);
    const tds = keys.map((index) => {
      let value = item[index];
      let renderField = true;
      let objItem = { value, item };
      // console.log(objItem);
      if (this.props.beforeRenderItem) {
        renderField = this.props.beforeRenderItem(index, objItem);
      }
      // console.log("keysTR",<td key={index}>{renderField ? objItem.value : ""}</td>);
      return <td key={index}>{renderField ? objItem.value : ""}</td>;
    });
    //console.log('VALOR DE keys');
    //console.log(keys);
    //console.log('VALOR DE tds');
    //console.log(tds);



    const expandableKeys = Object.keys(this.props.expandableFields);
    const expandableData = expandableKeys.map((index) => {
      const value = item[index];
      let objItemExpandable = { value, item };
      let renderField = true;
      if (this.props.beforeRenderItem) {
        renderField = this.props.beforeRenderItem(index, objItemExpandable);
      }
      if (!renderField) {
        return null;
      }
      return objItemExpandable.value ? (
        <p className="mb-0" key={index}>
          <strong>{this.props.expandableFields[index] + ": "}</strong> {objItemExpandable.value}
        </p>
      ) : null;
    });

    const haveProblem =
      typeof item["ProblemaId"] != "undefined" && item["ProblemaId"].length > 0;
    let problemWarning: any = null;
    if (haveProblem) {
      const toolTipId = "ProblemToolTip" + item.cups;
      problemWarning = (
        <>
          <span className="btn btn-danger btn-sm btn-circle" id={toolTipId}>
            <i className="pe-7s-info text-white"></i>
          </span>
          <UncontrolledTooltip placement="right" target={toolTipId}>
            {item.ProblemaIdString}
          </UncontrolledTooltip>
        </>
      );
    }
    const ViewMoreButtonContent = this.props.hideViewMoreButton ? null : (
      <Link
        className="btn btn-primary btn-sm btn-circle"
        data-toggle="modal"
        to={{ pathname: "sip-details/" + item.cups }}
        id = "buscartooltip"
      >
            <UncontrolledTooltip delay={0} placement="top" target="buscartooltip">
        Detalles del PS
      </UncontrolledTooltip>
        <i className="pe-7s-search text-white"></i>
      </Link>
    );

    const ComparativeIndexButtonContent = this.props.showIndexButton ? (
      <Link
        className="btn btn-light btn-sm btn-circle"
        data-toggle="modal"
        to={{ pathname: "sip-comparative-index/" + item.cups }}
         id = "indextooltip"
      >
                  <UncontrolledTooltip delay={0} placement="top" target="indextooltip">
        Propuesta Indexada
      </UncontrolledTooltip>
        <i className="pe-7s-date text-white"></i>
      </Link>


    ) : null;

    const ComparativeFreeButtonContent = this.props.showFreeMarketButton ? (
        <img
            src={'/logo/'+item.comercializadora+'.png'}
            style={{width: '100px', height: '35px'}}
        />
        ):null;


    // const CheckBoxButton = false;

    // if('ahorroAnual' && 'ahorroPercent' in this.props.fields){
    //   console.log("mecccc")

    //   let defaultSelection = this.state.selected.length == 0? true : false;

    //   CheckBoxButton =
    //   !this.props.checkBoxButton ? null : (
    //     <CustomInput
    //       id={item.id + '_item'}
    //       name={item.id + '_item'}
    //       type="checkbox"
    //       label=" "
    //       inline={true}
    //       value={item.id}
    //       className={"mb-1"}
    //       onChange={this.onChangeCheckbox}
    //       checked={ this.state.selected.includes( item.id )? true : defaultSelection }
    //     />
    //   );

    // }else{
      // console.log("ID!!",item.id);
      const CheckBoxButton =
      !this.props.checkBoxButton ? null : (
        <CustomInput
          id={this.getId(item)+'_item'}//{item.id + '_item'}
          name={this.getId(item) + '_item'}
          type="checkbox"
          label=" "
          inline={true}
          value={this.getId(item)}
          className={"mb-1"}
          onChange={this.onChangeCheckbox}
          checked={this.checked(item)}
        />
      );

    // }


    const ViewMoreButton =
      this.props.hideViewMoreButton && !this.props.showProblemInfo ? null : (
        <td colSpan={1}>
          {ViewMoreButtonContent}
          {ComparativeIndexButtonContent}
          {ComparativeFreeButtonContent}
          {problemWarning}
        </td>
      );

    const checkBoxButtonRender =
    !this.props.checkBoxButton ? null : (
      <td colSpan={1}>
        {CheckBoxButton}
        {ComparativeFreeButtonContent}
      </td>
    )


    const itemClassName = hasExtraFields ? "cursor-pointer" : "";
    const itemRendered = [
      <tr key={item.cups} onClick={clickCallback} className={itemClassName}>
        {checkBoxButtonRender}
        {tds}
      </tr>,
    ];

    if (this.state.expandedRows.includes(item.cups)) {
      const expandableRow = (
        <tr key={"expandable-" + item.cups}>
          <td colSpan={keys.length}>{expandableData}</td>
        </tr>
      );

      itemRendered.push(expandableRow);
    }

    return itemRendered;
  };


  onChangeCheckbox = ( event ) => {

    //console.log("EVENTT",event.target.value);

    let items:any[] = [ ...this.state.selected ];
    let id:any = event.target.value;

    // console.log("ITEMS",items,event.target.value)
  //  console.log(id,event.target.value);
    if('ahorroAnual' && 'ahorroPercent' in this.props.fields){
      items = [id] //radio button mode
    }else{
      //multi select mode
      if( items.indexOf( id ) === -1  )
      {
        items.push(id);
      }
      else
      {
        items = [ ...this.state.selected.filter( itemId => itemId !== id ) ]
      }
    }


    this.setState( { selected: items });

    if(this.props.selectedItem)
    {
      this.props.selectedItem( [...items ])
    }

  }

  getId = (item)=>{
    if(item.id != undefined){
      return item.id
    }else{
      return item.cups
    }
  }

  checked = (item) => {
    // if('ahorroAnual' && 'ahorroPercent' in this.props.fields){
    //   let defaultSelection = false;
    //   console.log(this.state.selected.length);
    //   console.log(this.props.items);
    //   if(this.state.selected.length == 0 && item == this.props.items[0]){
    //     defaultSelection = true;
    //   }
    //   return this.state.selected.includes( item.id )? true: defaultSelection;
    // }else{
    //   return this.state.selected.includes( item.id )? true: false
    // }
    //console.log("selected in TABLENEW", this.state.selected);
    if(item.id != undefined){
      return this.state.selected.includes( item.id )? true: false;
    }else{
      return this.state.selected.includes( item.cups )? true: false;
    }

  }

  render() {

    //console.log("Table start",this.props.items);
    const items = this.props.items
      ? this.props.items.map(this.renderItem)
      : null;
    // console.log("TABLE END",items);
    return (
      <Table className="align-items-center table-flush" responsive key={"ST-" + shortid.generate()}>
        {this.renderHeader()}
        <tbody key={"STB-"+shortid.generate()}>{items}</tbody>
      </Table>
    );
  }
}
