import gql from 'graphql-tag';

export const LIST_DICMUNICIPIOS = gql`
query {
    listDicMunicipios{
      Cmunicipio,
      Nombre
      CPro
DescProvincia
CodAuto
DescAutonomia
    }
  }
`;

export const LIST_DICMUNICIPIOSPOSTAL = gql`
query listDicMunicipiosPostal{
  listDicMunicipiosPostal{
      codigoPostal,
      Cmunicipio,
      entidadsingularnombre,
      Nombre
      CPro
DescProvincia
CodAuto
DescAutonomia
    }
  }
`;

export const LIST_DICMUNICIPIOSPOSTALSHORT = gql`
query listDicMunicipiosPostalShort( $codigoPostal: String!){
  listDicMunicipiosPostalShort(codigoPostal: $codigoPostal){
      codigoPostal,
      Cmunicipio,
      entidadsingularnombre,
      Nombre
      CPro
DescProvincia
CodAuto
DescAutonomia
    }
  }
`;
