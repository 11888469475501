import React from "react";

// reactstrap components
import { Card, CardBody, CardTitle, Container, Row, Col } from "reactstrap";
import { withApollo } from "react-apollo";
import { Link } from 'react-router-dom';
import axios from 'axios';
import moment from "moment";
import 'moment/locale/es';
import Reflux from "reflux";
import { UserStore } from "../../store/UserStore";

import { GET_COMERCIAL_DETAIL } from "../../queries/comercial/detail";
import { GET_USER_DATA } from "../../queries/users/users.queries";
import numberWithPoints from "../../utils/numberWithPoints";
import { ThunderboltFilled, FireFilled } from '@ant-design/icons';
import listaSubmin from "../../assets/img/icons/custom/lista_submin.svg";
import listaSubminGas from "../../assets/img/icons/custom/lista_submin_gas.svg";
import listaEmpresas from "../../assets/img/icons/custom/lista_empresas.svg";
import listadoPS from "../../assets/img/icons/custom/listadoPS.svg";
import propuestaMulti from "../../assets/img/icons/custom/propuestaMulti.svg";
import gestionPropuestas from "../../assets/img/icons/custom/gestion_propuestas.svg";
import gestionContratos from "../../assets/img/icons/custom/gestion_contratos.svg";
//import { PieChart, Pie, Sector, BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { scaleOrdinal } from 'd3-scale';
import { schemeCategory10 } from 'd3-scale-chromatic';

//import * as echarts from 'echarts'
import ReactEChartsCore from 'echarts-for-react/lib/core';
// Import the echarts core module, which provides the necessary interfaces for using echarts.
import * as echarts from 'echarts/core';
// Import charts, all with Chart suffix
import {
  LineChart,
  BarChart,
 PieChart,
  // ScatterChart,
  // RadarChart,
  // MapChart,
  // TreeChart,
  // TreemapChart,
  // GraphChart,
  // GaugeChart,
  // FunnelChart,
  // ParallelChart,
  // SankeyChart,
  // BoxplotChart,
  // CandlestickChart,
  // EffectScatterChart,
  // LinesChart,
  // HeatmapChart,
  // PictorialBarChart,
  // ThemeRiverChart,
  // SunburstChart,
  // CustomChart,
} from 'echarts/charts';

// import components, all suffixed with Component
import {
  // GridSimpleComponent,
  GridComponent,
  // PolarComponent,
  // RadarComponent,
  // GeoComponent,
  // SingleAxisComponent,
  // ParallelComponent,
  // CalendarComponent,
  // GraphicComponent,
  // ToolboxComponent,
  TooltipComponent,
  // AxisPointerComponent,
  // BrushComponent,
  TitleComponent,
  // TimelineComponent,
  // MarkPointComponent,
  // MarkLineComponent,
  // MarkAreaComponent,
   LegendComponent,
  // LegendScrollComponent,
  // LegendPlainComponent,
  // DataZoomComponent,
  // DataZoomInsideComponent,
  // DataZoomSliderComponent,
  VisualMapComponent,
  // VisualMapContinuousComponent,
  // VisualMapPiecewiseComponent,
  // AriaComponent,
  // TransformComponent,
  DatasetComponent,
} from 'echarts/components';
// Import renderer, note that introducing the CanvasRenderer or SVGRenderer is a required step
import {
  CanvasRenderer,
  // SVGRenderer,
} from 'echarts/renderers';
//import ReactECharts from 'echarts-for-react';
echarts.use(
  [TitleComponent, TooltipComponent, GridComponent, BarChart,LineChart,PieChart,LegendComponent,VisualMapComponent, DatasetComponent,CanvasRenderer]
);


echarts.registerTheme('roma', {
        "color": [
            "#3fb1e3",
            "#6be6c1",
            "#626c91",
            "#a0a7e6",
            "#c4ebad",
            "#96dee8"
        ],
        "backgroundColor": "rgba(252,252,252,0)",
        "textStyle": {},
        "title": {
            "textStyle": {
                "color": "#666666"
            },
            "subtextStyle": {
                "color": "#999999"
            }
        },
        "line": {
            "itemStyle": {
                "borderWidth": "2"
            },
            "lineStyle": {
                "width": "3"
            },
            "symbolSize": "8",
            "symbol": "emptyCircle",
            "smooth": false
        },
        "radar": {
            "itemStyle": {
                "borderWidth": "2"
            },
            "lineStyle": {
                "width": "3"
            },
            "symbolSize": "8",
            "symbol": "emptyCircle",
            "smooth": false
        },
        "bar": {
            "itemStyle": {
                "barBorderWidth": 0,
                "barBorderColor": "#ccc"
            }
        },
        "pie": {
            "itemStyle": {
                "borderWidth": 0,
                "borderColor": "#ccc"
            }
        },
        "scatter": {
            "itemStyle": {
                "borderWidth": 0,
                "borderColor": "#ccc"
            }
        },
        "boxplot": {
            "itemStyle": {
                "borderWidth": 0,
                "borderColor": "#ccc"
            }
        },
        "parallel": {
            "itemStyle": {
                "borderWidth": 0,
                "borderColor": "#ccc"
            }
        },
        "sankey": {
            "itemStyle": {
                "borderWidth": 0,
                "borderColor": "#ccc"
            }
        },
        "funnel": {
            "itemStyle": {
                "borderWidth": 0,
                "borderColor": "#ccc"
            }
        },
        "gauge": {
            "itemStyle": {
                "borderWidth": 0,
                "borderColor": "#ccc"
            }
        },
        "candlestick": {
            "itemStyle": {
                "color": "#e6a0d2",
                "color0": "transparent",
                "borderColor": "#e6a0d2",
                "borderColor0": "#3fb1e3",
                "borderWidth": "2"
            }
        },
        "graph": {
            "itemStyle": {
                "borderWidth": 0,
                "borderColor": "#ccc"
            },
            "lineStyle": {
                "width": "1",
                "color": "#cccccc"
            },
            "symbolSize": "8",
            "symbol": "emptyCircle",
            "smooth": false,
            "color": [
                "#3fb1e3",
                "#6be6c1",
                "#626c91",
                "#a0a7e6",
                "#c4ebad",
                "#96dee8"
            ],
            "label": {
                "color": "#ffffff"
            }
        },
        "map": {
            "itemStyle": {
                "areaColor": "#eeeeee",
                "borderColor": "#aaaaaa",
                "borderWidth": 0.5
            },
            "label": {
                "color": "#ffffff"
            },
            "emphasis": {
                "itemStyle": {
                    "areaColor": "rgba(63,177,227,0.25)",
                    "borderColor": "#3fb1e3",
                    "borderWidth": 1
                },
                "label": {
                    "color": "#3fb1e3"
                }
            }
        },
        "geo": {
            "itemStyle": {
                "areaColor": "#eeeeee",
                "borderColor": "#aaaaaa",
                "borderWidth": 0.5
            },
            "label": {
                "color": "#ffffff"
            },
            "emphasis": {
                "itemStyle": {
                    "areaColor": "rgba(63,177,227,0.25)",
                    "borderColor": "#3fb1e3",
                    "borderWidth": 1
                },
                "label": {
                    "color": "#3fb1e3"
                }
            }
        },
        "categoryAxis": {
            "axisLine": {
                "show": true,
                "lineStyle": {
                    "color": "#cccccc"
                }
            },
            "axisTick": {
                "show": false,
                "lineStyle": {
                    "color": "#333"
                }
            },
            "axisLabel": {
                "show": true,
                "color": "#999999"
            },
            "splitLine": {
                "show": true,
                "lineStyle": {
                    "color": [
                        "#eeeeee"
                    ]
                }
            },
            "splitArea": {
                "show": false,
                "areaStyle": {
                    "color": [
                        "rgba(250,250,250,0.05)",
                        "rgba(200,200,200,0.02)"
                    ]
                }
            }
        },
        "valueAxis": {
            "axisLine": {
                "show": true,
                "lineStyle": {
                    "color": "#cccccc"
                }
            },
            "axisTick": {
                "show": false,
                "lineStyle": {
                    "color": "#333"
                }
            },
            "axisLabel": {
                "show": true,
                "color": "#999999"
            },
            "splitLine": {
                "show": true,
                "lineStyle": {
                    "color": [
                        "#eeeeee"
                    ]
                }
            },
            "splitArea": {
                "show": false,
                "areaStyle": {
                    "color": [
                        "rgba(250,250,250,0.05)",
                        "rgba(200,200,200,0.02)"
                    ]
                }
            }
        },
        "logAxis": {
            "axisLine": {
                "show": true,
                "lineStyle": {
                    "color": "#cccccc"
                }
            },
            "axisTick": {
                "show": false,
                "lineStyle": {
                    "color": "#333"
                }
            },
            "axisLabel": {
                "show": true,
                "color": "#999999"
            },
            "splitLine": {
                "show": true,
                "lineStyle": {
                    "color": [
                        "#eeeeee"
                    ]
                }
            },
            "splitArea": {
                "show": false,
                "areaStyle": {
                    "color": [
                        "rgba(250,250,250,0.05)",
                        "rgba(200,200,200,0.02)"
                    ]
                }
            }
        },
        "timeAxis": {
            "axisLine": {
                "show": true,
                "lineStyle": {
                    "color": "#cccccc"
                }
            },
            "axisTick": {
                "show": false,
                "lineStyle": {
                    "color": "#333"
                }
            },
            "axisLabel": {
                "show": true,
                "color": "#999999"
            },
            "splitLine": {
                "show": true,
                "lineStyle": {
                    "color": [
                        "#eeeeee"
                    ]
                }
            },
            "splitArea": {
                "show": false,
                "areaStyle": {
                    "color": [
                        "rgba(250,250,250,0.05)",
                        "rgba(200,200,200,0.02)"
                    ]
                }
            }
        },
        "toolbox": {
            "iconStyle": {
                "borderColor": "#999999"
            },
            "emphasis": {
                "iconStyle": {
                    "borderColor": "#666666"
                }
            }
        },
        "legend": {
            "textStyle": {
                "color": "#999999"
            }
        },
        "tooltip": {
            "axisPointer": {
                "lineStyle": {
                    "color": "#cccccc",
                    "width": 1
                },
                "crossStyle": {
                    "color": "#cccccc",
                    "width": 1
                }
            }
        },
        "timeline": {
            "lineStyle": {
                "color": "#626c91",
                "width": 1
            },
            "itemStyle": {
                "color": "#626c91",
                "borderWidth": 1
            },
            "controlStyle": {
                "color": "#626c91",
                "borderColor": "#626c91",
                "borderWidth": 0.5
            },
            "checkpointStyle": {
                "color": "#3fb1e3",
                "borderColor": "#3fb1e3"
            },
            "label": {
                "color": "#626c91"
            },
            "emphasis": {
                "itemStyle": {
                    "color": "#626c91"
                },
                "controlStyle": {
                    "color": "#626c91",
                    "borderColor": "#626c91",
                    "borderWidth": 0.5
                },
                "label": {
                    "color": "#626c91"
                }
            }
        },
        "visualMap": {
            "color": [
                "#2a99c9",
                "#afe8ff"
            ]
        },
        "dataZoom": {
            "backgroundColor": "rgba(255,255,255,0)",
            "dataBackgroundColor": "rgba(222,222,222,1)",
            "fillerColor": "rgba(114,230,212,0.25)",
            "handleColor": "#cccccc",
            "handleSize": "100%",
            "textStyle": {
                "color": "#999999"
            }
        },
        "markPoint": {
            "label": {
                "color": "#ffffff"
            },
            "emphasis": {
                "label": {
                    "color": "#ffffff"
                }
            }
        }
    });


echarts.registerTheme('macarons', {
    "color": [
        "#2ec7c9",
        "#b6a2de",
        "#5ab1ef",
        "#ffb980",
        "#d87a80",
        "#8d98b3",
        "#e5cf0d",
        "#97b552",
        "#95706d",
        "#dc69aa",
        "#07a2a4",
        "#9a7fd1",
        "#588dd5",
        "#f5994e",
        "#c05050",
        "#59678c",
        "#c9ab00",
        "#7eb00a",
        "#6f5553",
        "#c14089"
    ],
    "backgroundColor": "rgba(0,0,0,0)",
    "textStyle": {},
    "title": {
        "textStyle": {
            "color": "#008acd"
        },
        "subtextStyle": {
            "color": "#aaaaaa"
        }
    },
    "line": {
        "itemStyle": {
            "borderWidth": 1
        },
        "lineStyle": {
            "width": 2
        },
        "symbolSize": 3,
        "symbol": "emptyCircle",
        "smooth": true
    },
    "radar": {
        "itemStyle": {
            "borderWidth": 1
        },
        "lineStyle": {
            "width": 2
        },
        "symbolSize": 3,
        "symbol": "emptyCircle",
        "smooth": true
    },
    "bar": {
        "itemStyle": {
            "barBorderWidth": 0,
            "barBorderColor": "#ccc"
        }
    },
    "pie": {
        "itemStyle": {
            "borderWidth": 0,
            "borderColor": "#ccc"
        }
    },
    "scatter": {
        "itemStyle": {
            "borderWidth": 0,
            "borderColor": "#ccc"
        }
    },
    "boxplot": {
        "itemStyle": {
            "borderWidth": 0,
            "borderColor": "#ccc"
        }
    },
    "parallel": {
        "itemStyle": {
            "borderWidth": 0,
            "borderColor": "#ccc"
        }
    },
    "sankey": {
        "itemStyle": {
            "borderWidth": 0,
            "borderColor": "#ccc"
        }
    },
    "funnel": {
        "itemStyle": {
            "borderWidth": 0,
            "borderColor": "#ccc"
        }
    },
    "gauge": {
        "itemStyle": {
            "borderWidth": 0,
            "borderColor": "#ccc"
        }
    },
    "candlestick": {
        "itemStyle": {
            "color": "#d87a80",
            "color0": "#2ec7c9",
            "borderColor": "#d87a80",
            "borderColor0": "#2ec7c9",
            "borderWidth": 1
        }
    },
    "graph": {
        "itemStyle": {
            "borderWidth": 0,
            "borderColor": "#ccc"
        },
        "lineStyle": {
            "width": 1,
            "color": "#aaaaaa"
        },
        "symbolSize": 3,
        "symbol": "emptyCircle",
        "smooth": true,
        "color": [
            "#2ec7c9",
            "#b6a2de",
            "#5ab1ef",
            "#ffb980",
            "#d87a80",
            "#8d98b3",
            "#e5cf0d",
            "#97b552",
            "#95706d",
            "#dc69aa",
            "#07a2a4",
            "#9a7fd1",
            "#588dd5",
            "#f5994e",
            "#c05050",
            "#59678c",
            "#c9ab00",
            "#7eb00a",
            "#6f5553",
            "#c14089"
        ],
        "label": {
            "color": "#eeeeee"
        }
    },
    "map": {
        "itemStyle": {
            "areaColor": "#dddddd",
            "borderColor": "#eeeeee",
            "borderWidth": 0.5
        },
        "label": {
            "color": "#d87a80"
        },
        "emphasis": {
            "itemStyle": {
                "areaColor": "rgba(254,153,78,1)",
                "borderColor": "#444",
                "borderWidth": 1
            },
            "label": {
                "color": "rgb(100,0,0)"
            }
        }
    },
    "geo": {
        "itemStyle": {
            "areaColor": "#dddddd",
            "borderColor": "#eeeeee",
            "borderWidth": 0.5
        },
        "label": {
            "color": "#d87a80"
        },
        "emphasis": {
            "itemStyle": {
                "areaColor": "rgba(254,153,78,1)",
                "borderColor": "#444",
                "borderWidth": 1
            },
            "label": {
                "color": "rgb(100,0,0)"
            }
        }
    },
    "categoryAxis": {
        "axisLine": {
            "show": true,
            "lineStyle": {
                "color": "#008acd"
            }
        },
        "axisTick": {
            "show": true,
            "lineStyle": {
                "color": "#333"
            }
        },
        "axisLabel": {
            "show": true,
            "color": "#333"
        },
        "splitLine": {
            "show": false,
            "lineStyle": {
                "color": [
                    "#eee"
                ]
            }
        },
        "splitArea": {
            "show": false,
            "areaStyle": {
                "color": [
                    "rgba(250,250,250,0.3)",
                    "rgba(200,200,200,0.3)"
                ]
            }
        }
    },
    "valueAxis": {
        "axisLine": {
            "show": true,
            "lineStyle": {
                "color": "#008acd"
            }
        },
        "axisTick": {
            "show": true,
            "lineStyle": {
                "color": "#333"
            }
        },
        "axisLabel": {
            "show": true,
            "color": "#333"
        },
        "splitLine": {
            "show": true,
            "lineStyle": {
                "color": [
                    "#eee"
                ]
            }
        },
        "splitArea": {
            "show": true,
            "areaStyle": {
                "color": [
                    "rgba(250,250,250,0.3)",
                    "rgba(200,200,200,0.3)"
                ]
            }
        }
    },
    "logAxis": {
        "axisLine": {
            "show": true,
            "lineStyle": {
                "color": "#008acd"
            }
        },
        "axisTick": {
            "show": true,
            "lineStyle": {
                "color": "#333"
            }
        },
        "axisLabel": {
            "show": true,
            "color": "#333"
        },
        "splitLine": {
            "show": true,
            "lineStyle": {
                "color": [
                    "#eee"
                ]
            }
        },
        "splitArea": {
            "show": true,
            "areaStyle": {
                "color": [
                    "rgba(250,250,250,0.3)",
                    "rgba(200,200,200,0.3)"
                ]
            }
        }
    },
    "timeAxis": {
        "axisLine": {
            "show": true,
            "lineStyle": {
                "color": "#008acd"
            }
        },
        "axisTick": {
            "show": true,
            "lineStyle": {
                "color": "#333"
            }
        },
        "axisLabel": {
            "show": true,
            "color": "#333"
        },
        "splitLine": {
            "show": true,
            "lineStyle": {
                "color": [
                    "#eee"
                ]
            }
        },
        "splitArea": {
            "show": false,
            "areaStyle": {
                "color": [
                    "rgba(250,250,250,0.3)",
                    "rgba(200,200,200,0.3)"
                ]
            }
        }
    },
    "toolbox": {
        "iconStyle": {
            "borderColor": "#2ec7c9"
        },
        "emphasis": {
            "iconStyle": {
                "borderColor": "#18a4a6"
            }
        }
    },
    "legend": {
        "textStyle": {
            "color": "#333333"
        }
    },
    "tooltip": {
        "axisPointer": {
            "lineStyle": {
                "color": "#008acd",
                "width": "1"
            },
            "crossStyle": {
                "color": "#008acd",
                "width": "1"
            }
        }
    },
    "timeline": {
        "lineStyle": {
            "color": "#008acd",
            "width": 1
        },
        "itemStyle": {
            "color": "#008acd",
            "borderWidth": 1
        },
        "controlStyle": {
            "color": "#008acd",
            "borderColor": "#008acd",
            "borderWidth": 0.5
        },
        "checkpointStyle": {
            "color": "#2ec7c9",
            "borderColor": "#2ec7c9"
        },
        "label": {
            "color": "#008acd"
        },
        "emphasis": {
            "itemStyle": {
                "color": "#a9334c"
            },
            "controlStyle": {
                "color": "#008acd",
                "borderColor": "#008acd",
                "borderWidth": 0.5
            },
            "label": {
                "color": "#008acd"
            }
        }
    },
    "visualMap": {
        "color": [
            "#5ab1ef",
            "#e0ffff"
        ]
    },
    "dataZoom": {
        "backgroundColor": "rgba(47,69,84,0)",
        "dataBackgroundColor": "#efefff",
        "fillerColor": "rgba(182,162,222,0.2)",
        "handleColor": "#008acd",
        "handleSize": "100%",
        "textStyle": {
            "color": "#333333"
        }
    },
    "markPoint": {
        "label": {
            "color": "#eeeeee"
        },
        "emphasis": {
            "label": {
                "color": "#eeeeee"
            }
        }
    }
});

const colors = scaleOrdinal(schemeCategory10).range();

class PropuestasControlPanel extends Reflux.Component<any, any> {
    state = {
        UserStore: {},
        companyIdUser: '',
        userName: '',
        companyNameUser: '',
        userGroupId: 1000,
        userId: '',
        idCard: "",
        first_name1: "",
        last_name1: "",
        showIndexButton: false,
        showFreeMarketButton: false,
        isLoading2: false,
        dataResumenContratos: {
            totalAceptados: 0,
            totalContratos: 0,
            totalEnEspera: 0,
            ultimaFecha: ''
        },
        dataResumenContratosChart0: [{
            name: "",
            contratos: 0
        }],
        dataResumenContratosChart1: [{
            name: "",
            contratos: 0
        }],
        dataResumenContratosFirmados: {
            totalFirmaContrato: 0,
            totalContratos: 0,
            totalEnEspera: 0,
            totalContratosGeneradoEnviado: 0,
            totalFechaContrato: 0,
            totalFechaActivacion: 0
        },
        dataResumenContratosFirmadosChart: [{
            name: "Contrato Totales",
            totalFirmaContrato: 0,
            totalContratos: 0,
            totalEnEspera: 0,
            totalContratosGeneradoEnviado: 0,
            totalFechaContrato: 0,
            totalFechaActivacion: 0
        }],
        dataChart1:[{
            Aceptados: 0,
            Anno: 0,
            Baja: 0,
            Contratos: 0,
            Denegados: 0,
            EnEspera: 0,
            Mes: 1,
            Renovacion: 0
}],
dataChartContrato1G:[{
    ConsumoAnual: 0,
    ConsumoAnual2: 0,
    Fecha: "",
    Producto: "",
    TarifaPeaje: "B6",
    TotalProducto: 0,
    TotalTarifa: 0,
    TotalTipoEnergia: 0,
    tipoEnergia: "GAS"
}],
dataChartContrato1E:[{
    ConsumoAnual: 0,
    ConsumoAnual2: 0,
    Fecha: "",
    Producto: "",
    TarifaPeaje: "B6",
    TotalProducto: 0,
    TotalTarifa: 0,
    TotalTipoEnergia: 0,
    tipoEnergia: "GAS"
}],
        isLoading: false,
        data: {
            supplier_id: 0,
            num_sites: 0,
            kwhanual: 0,
            supplier_name: "",
            supplier_code_ree: "",
            persona_fisica_total_sites: 0,
            persona_fisica_sumkwhanual: 0,
            persona_juridica_total_sites: 0,
            persona_juridica_sumkwhanual: 0,
        },
        dataAux: [{
            label: "",
            B5: 0
        }]
    };


    constructor(props) {
        super(props);
        this.store = UserStore;
    }


    componentDidMount() {

        this.getUserData();
        this.fetchItems();

    };

    async getUserData() {

        const resp = await this.props.client.query({
            query: GET_USER_DATA,
        });

        const user = resp.data.userData;

        this.setState({

            first_name1: user.first_name,
            last_name1: user.last_name,
        });
        this.QueryResumenContratosAlt(user.email, user.companyId, user.id, user.user_group_id);
        this.QueryResumenContratosFirmadosAlt(user.email, user.companyId, user.id, user.user_group_id);

        this.QueryResumenChart(user.email, user.companyId, user.id, user.user_group_id);
        this.QueryResumenChartContrato(user.email, user.companyId, user.id, user.user_group_id);

}

    componentWillReceiveProps() {


        const usuario: any = JSON.parse(JSON.stringify(this.state.UserStore['data']))

        const companyNameGlobal = usuario.company;
        const companyIdUser = usuario.companyId;

        const userGroupId = usuario.user_group_id
        const userId = usuario.id
        const userName = usuario.first_name + " " + usuario.last_name
        const idCard = usuario.email

        let showIndexButton = usuario.indexada;
        let showFreeMarketButton = usuario.mercadolibre;
        let companyNameUser = usuario.company;

        if (userGroupId === 1000) {
            this.setState({
                activeUser: true
            })
        };

        this.setState({
            companyNameGlobal,
            companyIdUser,
            userGroupId,
            userId,
            showIndexButton,
            userName,
            showFreeMarketButton,
            companyNameUser,
            idCard

        });
    }


    UNSAFE_componentWillMount() {


        var userFromStorageTemp = localStorage.getItem("user");
        var parsedTemp = JSON.parse(userFromStorageTemp || '{}');
        var userIdTemp = parsedTemp.id;
        var companyIdUserTemp = parsedTemp.companyId;
        var userGroupId = parsedTemp.user_group_id;
        var company = parsedTemp.company;
        var idCard = parsedTemp.email;

        this.setState({

            companyIdUser: companyIdUserTemp,
            userId: userIdTemp,
            userGroupId: userGroupId,
            companyNameUser: company,
            idCard: idCard

        });

    };

    ///////////////////////////////////////////////////////////////////////////////////////////

    fetchItems = async () => {
        let isLoading = true;
        this.setState({ isLoading });

        const sips = await this.props.client.query({
            query: GET_COMERCIAL_DETAIL,
        });
        const data = sips.data.getComercialDetail;

        isLoading = false;
        this.setState({ data, isLoading });
    };



QueryResumenChart = async (idcard1, companyIdUser1, userId1, userGroupId1) => {

    let isLoading = true;
    const isSearching = true;
    const companyId = companyIdUser1.toString()
    const userId = '-1'


    var userIdAux = userId1.toString();
    var userQuery = userId1.toString();
    var idCard = idcard1;

    if (userGroupId1 === 1000) {
        userIdAux = "1000"
    } else {
        userIdAux = userId1.toString();
    }

   var userTime = "S"

    const headers = {
        'Authorization': 'Bearer my-token',
        'My-Custom-Header': 'foobar',
        'x-api-key': '$2y$10$bcT1Ukm4V/6/z5GvitZv0unu8I91mpixuwUO6z5CyEGev9DDDY28W'
    };
    let direccionFile = "https://datapi.psgestion.es/cuadro/chartAceptados?id=" + companyId + "&user=" + userIdAux+ "&time=" + userTime+"";


    await axios.get(direccionFile, { headers }).
        then(result => {

            const data = result.data.rows

            this.setState({
                dataChart1: data
                        });
        }).catch((error) => {
            console.log('Failure ', error)
        });

};
    //////////////////////////////////////////////////////////////////////////////////////

    QueryResumenChartContrato = async (idcard1, companyIdUser1, userId1, userGroupId1) => {

        let isLoading = true;
        const isSearching = true;
        const companyId = companyIdUser1.toString()
        const userId = '-1'


        var userIdAux = userId1.toString();
        var userQuery = userId1.toString();
        var idCard = idcard1;

        if (userGroupId1 === 1000) {
            userIdAux = "1000"
        } else {
            userIdAux = userId1.toString();
        }

       var userTime = "S"

        const headers = {
            'Authorization': 'Bearer my-token',
            'My-Custom-Header': 'foobar',
            'x-api-key': '$2y$10$bcT1Ukm4V/6/z5GvitZv0unu8I91mpixuwUO6z5CyEGev9DDDY28W'
        };
        let direccionFile = "https://datapi.psgestion.es/cuadro/chartContratos?id=" + companyId + "&user=" + userIdAux+ "&time=" + userTime+"";

        await axios.get(direccionFile, { headers }).
            then(result => {

                const data = result.data.rows

                this.setState({
                    dataChartContrato1G: data
                            });
            }).catch((error) => {
                console.log('Failure ', error)
            });

    };
        //////////////////////////////////////////////////////////////////////////////////////



    QueryResumenContratosAlt = async (idcard1, companyIdUser1, userId1, userGroupId1) => {

        let isLoading = true;
        const isSearching = true;
        const companyId = companyIdUser1.toString()
        const userId = '-1'


        var userIdAux = userId1.toString();
        var userQuery = userId1.toString();
        var idCard = idcard1;

        if (userGroupId1 === 1000) {
            userIdAux = "1000"
        } else {
            userIdAux = userId1.toString();
        }

        const headers = {
            'Authorization': 'Bearer my-token',
            'My-Custom-Header': 'foobar',
            'x-api-key': '$2y$10$bcT1Ukm4V/6/z5GvitZv0unu8I91mpixuwUO6z5CyEGev9DDDY28W'
        };
        let direccionFile = "https://datapi.psgestion.es/propuesta/resumen?id=" + companyId + "&user=" + userIdAux;

        await axios.get(direccionFile, { headers }).
            then(result => {

                const data = result.data.rows[0]
                let dataAux = result.data.rows[0]

                let newDataChart: any = data;

                const keys = Object.keys(data);
                const res: any[] = [];
                res.push({
                    'name': keys[1],
                    'contratos': data[keys[1]]
                });

                //delete newDataChart['totalContratos'];
                //delete newDataChart['ultimaFecha'];

                const keys1 = Object.keys(newDataChart);
                const res1: any = [];
                for (let i = 0; i < keys1.length; i++) {
                    if (i == 1 || i == 3) { } else {
                        res1.push({
                            'name': keys1[i],
                            'value': newDataChart[keys1[i]]
                        });
                    }
                };

                this.setState({
                    dataResumenContratos: data,
                    //dataResumenContratosChart: result2
                    dataResumenContratosChart0: res,
                    dataResumenContratosChart1: res1,
                    isLoading2: false
                });
            }).catch((error) => {
                console.log('Failure ', error)
            });

    };



    ////////////////////////////////////////////////////////////////////////////////////////////


    QueryResumenContratosFirmadosAlt = async (idcard1, companyIdUser1, userId1, userGroupId1) => {

        let isLoading = true;
        const isSearching = true;
        const companyId = companyIdUser1.toString()
        const userId = '-1'

        var userIdAux = userId1.toString();
        var userQuery = userId1.toString();
        var idCard = idcard1;

        if (userGroupId1 === 1000) {

            userIdAux = "1000"
        } else {
            userIdAux = userId1.toString();
        }

        const headers = {
            'Authorization': 'Bearer my-token',
            'My-Custom-Header': 'foobar',
            'x-api-key': '$2y$10$bcT1Ukm4V/6/z5GvitZv0unu8I91mpixuwUO6z5CyEGev9DDDY28W'
        };
        let direccionFile = "https://datapi.psgestion.es/contrato/resumen?id=" + companyId + "&user=" + userIdAux + "&idcard=" + idCard;

        await axios.get(direccionFile, { headers }).
            then((result: any) => {

                let data = result.data.rows[0]

                let newDataChart: any = data;

                let result2 = Object
                    .keys(newDataChart)
                    .map(k => ({ "name": k, "contratos": newDataChart[k] }));


                let newDataChartAux: any[] = []

                //console.log('objeto newDataChart: ', typeof (newDataChart), " valores: ", newDataChart)

                newDataChart['name'] = "Contratos Totales";

                //console.log('valor newDataChart ', newDataChart)

                newDataChartAux.push(newDataChart)

                //console.log('valor newDataChartAux ', newDataChartAux)

                /*
                            console.log('Tipo de data: ', typeof(data), ' data: ', data)

                            let dataContractTrin:any[] = [];

                            data.map((row) => {
                              console.log(row)
                            //for(var i=0;i<=dataaceptados.length;i++){
                              let name = row['anio']+"-"+row['label'];
                              let estado = "Aceptados";
                              let valor = parseInt(row['data']);
                              //console.log('VALOR DE FECHA ACEPTADO ----> ', fechaAceptado)

                              dataContractTrin.push({ fecha: fecha, estado: estado, valor: valor});

                            //}
                          })
                */




                this.setState({
                    dataResumenContratosFirmados: data,
                    dataResumenContratosFirmadosChart: result2
                });
            }).catch((error) => {
                console.log('Failure ', error)
            });

    };

    ///////////////////////////////////////////////////////////////////////////////////////////



    render() {

        var datosAux = [];
        var datosAuxE = [];

        this.state.dataChartContrato1G.forEach(value => {
            let existe = false;
            if (datosAux.length > 0) {
                datosAux.forEach(value2 => {


                    // @ts-ignore
                    //if( value.Fecha === value2.Fecha && value.TarifaPeaje === value2.TarifaPeaje) {
                    if (value.TarifaPeaje === value2.name) {

                        // @ts-ignore
                        value2.value = value2.value + value.TotalTarifa;
                        // @ts-ignore

                        value2.energia = value2.energia + value.ConsumoAnual2
                        existe = true;
                        return existe;
                    }
                });
                if (!existe) {
if(value.tipoEnergia === 'GAS'){
                    datosAux.push({
                        // @ts-ignore
                        //date : value.Fecha,
                        // @ts-ignore
                        name: value.TarifaPeaje,
                        // @ts-ignore
                        value: value.TotalTarifa,
                        // @ts-ignore
                        energia: value.ConsumoAnual2
                    })
                }
            }
            } else {
if(value.tipoEnergia === 'GAS'){
                datosAux.push({
                    // @ts-ignore
                    //date : value.Fecha,
                    // @ts-ignore
                    name: value.TarifaPeaje,
                    // @ts-ignore
                    value: value.TotalTarifa,
                    // @ts-ignore
                    energia: value.ConsumoAnual2
                })
            }
        }
        });

        this.state.dataChartContrato1G.forEach(value => {
            let existe = false;
            if (datosAuxE.length > 0) {
                datosAuxE.forEach(value2E => {

                    // @ts-ignore
                    //if( value.Fecha === value2.Fecha && value.TarifaPeaje === value2.TarifaPeaje) {
                    if (value.TarifaPeaje === value2E.name) {

                            // @ts-ignore
                            value2E.value = value2E.value + value.TotalTarifa;
                            // @ts-ignore

                            value2E.energia = value2E.energia + value.ConsumoAnual
                            existe = true;
                            return existe;
                        }

                });
                if (!existe) {
if(value.tipoEnergia === 'ELECTRICIDAD'){

                    datosAuxE.push({
                        // @ts-ignore
                        //date : value.Fecha,
                        // @ts-ignore
                        name: value.TarifaPeaje,
                        // @ts-ignore
                        value: value.TotalTarifa,
                        // @ts-ignore
                        energia: value.ConsumoAnual
                    })
                }
            }
            } else {
if(value.tipoEnergia === 'ELECTRICIDAD'){
                datosAuxE.push({
                    // @ts-ignore
                    //date : value.Fecha,
                    // @ts-ignore
                    name: value.TarifaPeaje,
                    // @ts-ignore
                    value: value.TotalTarifa,
                    // @ts-ignore
                    energia: value.ConsumoAnual
                })
            }
        }
        });

        var option = {
            title: {
                text: 'Resumen de Propuestas',
                left: 'left'
            },
            grid: {
                left: '3%',
                right: '4%',
                bottom: '3%'
            },
            tooltip: {
                trigger: 'item'
            },
            legend: {
                top: 'middle',
                left: 'right',
                orient: 'vertical'
            },
            series: [
                {
                    name: 'Propuestas',
                    type: 'pie',
                    radius: ['40%', '70%'],
                    avoidLabelOverlap: false,
                    itemStyle: {
                        borderRadius: 10,
                        borderColor: '#fff',
                        borderWidth: 2
                    },
                    label: {
                        show: false,
                        position: 'center'
                    },
                    emphasis: {
                        label: {
                            show: true,
                            fontSize: '30',
                            fontWeight: 'bold'
                        }
                    },
                    labelLine: {
                        show: false
                    },
                    data: this.state.dataResumenContratosChart1
                }
            ]
        };


        var option2 = {
            dataset: {
                dimensions: ['name', 'contratos'],
                source: this.state.dataResumenContratosFirmadosChart
            },
            tooltip: {
                trigger: 'axis'
            },
            grid: {
                left: '3%',
                right: '4%',
                bottom: '3%'
            },
            xAxis: {
                type: 'category'
            },
            yAxis: {},
            visualMap: {
                orient: 'horizontal',
                left: 'center',
                min: 10,
                max: 100,
                text: ['+', '-'],
                // Map the score column to color
                dimension: 0,

            },
            series: [
                {

                    type: 'bar',
                    barWidth: '60%',
                    encode: {
                        x: 'name',
                        y: 'contratos'
                    }

                }
            ]
        };

        var option3 = {
            title: {
                text: 'Resumen de Contratos',
                left: 'left',
                padding: 10
            },
            legend: {
                top: 'middle',
                left: 'right',
                orient: 'vertical'
            },
            dataset: {

                dimensions: ['Anno', 'Mes', 'Contratos', 'Aceptados', 'EnEspera', 'Denegados', 'Renovacion', 'Baja'],
                source: this.state.dataChart1
            },
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'shadow'
                }
            },
            grid: {
                left: '3%',
                right: '4%',
                bottom: '3%',
                containLabel: true
            },
            xAxis: {
                type: 'category',
                boundaryGap: false,
            },
            yAxis: {
                type: 'value'
            },
            series: [

                {
                    name: 'Contratos',
                    type: 'line',
                    smooth: true,
                    encode: {
                        x: 'Mes',
                        y: 'Contratos'
                    }
                },
                {
                    name: 'Aceptados',
                    type: 'line',
                    smooth: true,
                    encode: {
                        x: 'Mes',
                        y: 'Aceptados'
                    }
                },
                {
                    name: 'En Espera',
                    type: 'line',
                    smooth: true,
                    encode: {
                        x: 'Mes',
                        y: 'EnEspera'
                    }
                },
                {
                    name: 'Denegados',
                    type: 'line',
                    smooth: true,
                    encode: {
                        x: 'Mes',
                        y: 'Denegados'
                    }
                }

            ]
        };


        var option4 = {
            title: {
                text: 'Resumen de Peajes Contratados',
                left: 'left'
            },
            grid: {
                left: '3%',
                right: '4%',
                bottom: '3%'
            },
            tooltip: {
                trigger: 'item'
            },
            legend: {
                top: 'middle',
                left: 'right',
                orient: 'vertical'
            },
            series: [
                {
                    name: 'Tarifas',
                    type: 'pie',
                    radius: ['40%', '70%'],
                    avoidLabelOverlap: false,
                    itemStyle: {
                        borderRadius: 10,
                        borderColor: '#fff',
                        borderWidth: 2
                    },
                    label: {
                        show: false,
                        position: 'center'
                    },
                    emphasis: {
                        label: {
                            show: true,
                            fontSize: '30',
                            fontWeight: 'bold'
                        }
                    },
                    labelLine: {
                        show: false
                    },
                    data: datosAux
                }
            ]
        };

        var option5 = {
            title: {
                text: ' Contratos Gas/Energía Contratada',
                left: 'left'
            },
            dataset: {
                dimensions: ['name', 'value', 'energia'],
                source: datosAux
            },
            tooltip: {
                trigger: 'axis'
            },
            grid: {
    left: '15%',
    right: '14%'
  },
            xAxis: {
                type: 'category'
            },
            yAxis: [
    {
      type: 'value',
      name: 'Número Contratos',
      position: 'left',
      alignTicks: true,
      axisLine: {
        show: true,
        lineStyle: {
          color: colors[0]
        }
      },
          },
    {
      type: 'value',
      name: 'MW Contratados',
      position: 'right',
      alignTicks: true,
      axisLine: {
        show: true,
        lineStyle: {
          color: colors[1]
        },
        axisLabel: {
          formatter: '{value} MW'
        }
      },
    }],
            series: [
                {
                    name:'Contratos',
                    type: 'bar',
yAxisIndex: 0,
                    barWidth: '60%',
                    encode: {
                        x: 'name',

                    }

                },
                {
                    name:'GWh Contratados Anual',
                    type: 'line',
                    yAxisIndex: 1,
                    barWidth: '60%',
                    encode: {
                        y: 'energia',

                    }

                }
            ]
        };

        var option6 = {
            title: {
                text: ' Contratos Electricidad/Energía Contratada',
                left: 'left'
            },
            dataset: {
                dimensions: ['name', 'value', 'energia'],
                source: datosAuxE
            },
            tooltip: {
                trigger: 'axis'
            },
            grid: {
        left: '15%',
        right: '14%'
        },
            xAxis: {
                type: 'category'
            },
            yAxis: [
        {
        type: 'value',
        name: 'Número Contratos',
        position: 'left',
        alignTicks: true,
        axisLine: {
        show: true,
        lineStyle: {
          color: colors[0]
        }
        },
          },
        {
        type: 'value',
        name: 'MW Contratados',
        position: 'right',
        alignTicks: true,
        axisLine: {
        show: true,
        lineStyle: {
          color: colors[1]
        },
        axisLabel: {
          formatter: '{value} MW'
        }
        },
        }],
            series: [
                {
                    name:'Contratos',
                    type: 'bar',
        yAxisIndex: 0,
                    barWidth: '60%',
                    encode: {
                        x: 'name',

                    }

                },
                {
                    name:'GWh Contratados Anual',
                    type: 'line',
                    yAxisIndex: 1,
                    barWidth: '60%',
                    encode: {
                        y: 'energia',

                    }

                }
            ]
        };



        return (
            <>
                <div className="header bg-gradient-info pb-8 pt-5 pt-lg-5">

                    <Container fluid>
                        <Row className="margin-reset w-100">
                            <Col md="6" xl="6">
                                <Card className="card-stats">
                                    <CardBody>
                                        <ReactEChartsCore
                                            echarts={echarts}
                                            option={option}
                                            style={{ height: 300, width: 500 }}
                                            theme={"macarons"}
                                        />
                                    </CardBody>
                                </Card>
                            </Col>

                            <Col md="6" xl="3">
                                <Card className="card-stats">
                                    <CardBody>
                                        <Row>
                                            <div className="col">
                                                <CardTitle
                                                    tag="h5"
                                                    className="text-uppercase text-muted mb-0"
                                                >
                                                    TOTAL PROPUESTAS
         </CardTitle>
                                                <span className="h2 font-weight-bold mb-0">
                                                    {this.state.dataResumenContratos.totalContratos || 0}
                                                </span>
                                            </div>
                                            <Col className="col-auto">
                                                <div className="icon icon-shape bg-gradient-red text-white rounded-circle shadow">
                                                    <i className="bi bi-files" />
                                                </div>
                                            </Col>
                                        </Row>
                                        <p className="mt-3 mb-0 text-sm">
                                            <span className="text-nowrap">Propuesta totales realizadas</span>
                                        </p>
                                    </CardBody>
                                </Card>

                                <Card className="card-stats">
                                    <CardBody>
                                        <Row>
                                            <div className="col">
                                                <CardTitle
                                                    tag="h5"
                                                    className="text-uppercase text-muted mb-0"
                                                >
                                                    FECHA ULT. PROPUESTA
                                         </CardTitle>
                                                <span className="h2 font-weight-bold mb-0">
                                                    {this.state.dataResumenContratos.ultimaFecha || "SIN DATOS"}
                                                </span>
                                            </div>
                                            <Col className="col-auto">
                                                <div className="icon icon-shape bg-gradient-red text-white rounded-circle shadow">
                                                    <i className="bi bi-calendar-event" />
                                                </div>
                                            </Col>
                                        </Row>
                                        <p className="mt-3 mb-0 text-sm">
                                            {" "}
                                            <span className="text-nowrap">Fecha última propuesta</span>
                                        </p>
                                    </CardBody>
                                </Card>
                            </Col>

                    {  /*      <Col md="6" xl="6">
                                <Card className="card-stats">
                                    <CardBody>
                                        <ReactEChartsCore
                                            option={option2}
                                            style={{ height: 300, width: 500 }}
                                            theme={"roma"}
                                        />
                                    </CardBody>
                                </Card>
                            </Col>*/}
                        </Row>
                    </Container>

                    <Container fluid>
                        <Row className="margin-reset w-100">
                            <Col md="6" xl="6">
                                <Card className="card-stats">
                                    <CardBody>
                                        <ReactEChartsCore
                                            echarts={echarts}
                                            option={option3}
                                            style={{ height: 300, width: 500 }}
                                            theme={"macarons"}
                                        />
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col Col md="6" xl="3">
                                <Card className="card-stats">
                                    <CardBody>
                                        <Row>
                                            <div className="col">
                                                <CardTitle
                                                    tag="h5"
                                                    className="text-uppercase text-muted mb-0"
                                                >
                                                    CONTRATOS ACEPTADOS
                            </CardTitle>
                                                <span className="h2 font-weight-bold mb-0">
                                                    {this.state.dataResumenContratosFirmados.totalFechaContrato || 0}
                                                </span>
                                            </div>
                                            <Col className="col-auto">
                                                <div className="icon icon-shape bg-gradient-green text-white rounded-circle shadow">
                                                    <i className="bi bi-check2-circle" />
                                                </div>
                                            </Col>
                                        </Row>
                                        <p className="mt-3 mb-0 text-sm">
                                            {" "}
                                            <span className="text-nowrap">Contratos aceptados últimos 3 meses</span>
                                        </p>
                                    </CardBody>
                                </Card>

                                <Card className="card-stats">
                                    <CardBody>
                                        <Row>
                                            <div className="col">
                                                <CardTitle
                                                    tag="h5"
                                                    className="text-uppercase text-muted mb-0"
                                                >
                                                    CONTRATOS FIRMADOS
                            </CardTitle>
                                                <span className="h2 font-weight-bold mb-0">
                                                    {this.state.dataResumenContratosFirmados.totalFirmaContrato || 0}
                                                </span>
                                            </div>
                                            <Col className="col-auto">
                                                <div className="icon icon-shape bg-gradient-green text-white rounded-circle shadow">
                                                    <i className="bi bi-file-medical" />
                                                </div>
                                            </Col>
                                        </Row>
                                        <p className="mt-3 mb-0 text-sm">
                                            {" "}
                                            <span className="text-nowrap">Contratos firmados últimos 3 meses</span>
                                        </p>
                                    </CardBody>
                                </Card>
                            </Col>


                        </Row>
                    </Container>

                    <Container fluid>
                        <Row className="margin-reset w-100">
                            <Col md="6" xl="6">
                                <Card className="card-stats">
                                    <CardBody>
                                        <ReactEChartsCore
                                            echarts={echarts}
                                            option={option4}
                                            style={{ height: 300, width: 400 }}
                                            theme={"macarons"}
                                        />
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col md="6" xl="6">
                                <Card className="card-stats">
                                    <CardBody>
                                        <ReactEChartsCore
                                            echarts={echarts}
                                            option={option5}
                                            style={{ height: 300, width: 400 }}
                                            theme={"roma"}
                                        />
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>

                    <Container fluid>
                        <Row className="margin-reset w-100">

                            <Col md="6" xl="6">
                                <Card className="card-stats">
                                    <CardBody>
                                        <ReactEChartsCore
                                            echarts={echarts}
                                            option={option6}
                                            style={{ height: 300, width: 400 }}
                                            theme={"roma"}
                                        />
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>

                    <Container fluid>
                        <div className="header-body pb-2 pt-1 ">
                            <Row>
                                <h2 className="font-weight-bold mb-0">DATOS RESUMEN SIPS (CNMC) </h2 >
                            </Row>
                        </div>
                    </Container>
                    <Container fluid>

                        <Row className="margin-reset w-100">
                            <Col lg="6" xl="4">
                                <Card className="card-stats mb-4 mb-xl-0">
                                    <CardBody>
                                        <Row>
                                            <div className="col">
                                                <CardTitle
                                                    tag="h5"
                                                    className="text-uppercase text-muted mb-0"
                                                >
                                                    CUPS
                          </CardTitle>
                                                <span className="h2 font-weight-bold mb-0">
                                                    {numberWithPoints(this.state.data.num_sites)}
                                                </span>
                                            </div>
                                            <Col className="col-auto">
                                                <div className="icon icon-shape bg-danger text-white rounded-circle shadow">
                                                    <i className="fas fa-chart-bar" />
                                                </div>
                                            </Col>
                                        </Row>
                                        <p className="mt-3 mb-0 text-muted text-sm">
                                            <span className="text-success mr-2">
                                                <i className="fa fa-charging-station" />{" "}
                                                {numberWithPoints(
                                                    Math.round(this.state.data.kwhanual / 1000000)
                                                )}
                                            </span>{" "}
                                            <span className="text-nowrap">Gwh</span>
                                        </p>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col lg="6" xl="4">
                                <Card className="card-stats mb-4 mb-xl-0">
                                    <CardBody>
                                        <Row>
                                            <div className="col">
                                                <CardTitle
                                                    tag="h5"
                                                    className="text-uppercase text-muted mb-0"
                                                >
                                                    P. fisicas
                          </CardTitle>
                                                <span className="h2 font-weight-bold mb-0">
                                                    {numberWithPoints(
                                                        this.state.data.persona_fisica_total_sites
                                                    )}
                                                </span>
                                            </div>
                                            <Col className="col-auto">
                                                <div className="icon icon-shape bg-warning text-white rounded-circle shadow">
                                                    <i className="fas fa-chart-pie" />
                                                </div>
                                            </Col>
                                        </Row>
                                        <p className="mt-3 mb-0 text-muted text-sm">
                                            <span className="text-success mr-2">
                                                <i className="fa fa-charging-station" />{" "}
                                                {numberWithPoints(
                                                    Math.round(
                                                        this.state.data.persona_fisica_sumkwhanual /
                                                        1000000
                                                    )
                                                )}
                                            </span>{" "}
                                            <span className="text-nowrap">Gwh</span>
                                        </p>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col lg="6" xl="4">
                                <Card className="card-stats mb-4 mb-xl-0">
                                    <CardBody>
                                        <Row>
                                            <div className="col">
                                                <CardTitle
                                                    tag="h5"
                                                    className="text-uppercase text-muted mb-0"
                                                >
                                                    P. jurídicas
                          </CardTitle>
                                                <span className="h2 font-weight-bold mb-0">
                                                    {numberWithPoints(
                                                        this.state.data.persona_juridica_total_sites
                                                    )}
                                                </span>
                                            </div>
                                            <Col className="col-auto">
                                                <div className="icon icon-shape bg-yellow text-white rounded-circle shadow">
                                                    <i className="fas fa-users" />
                                                </div>
                                            </Col>
                                        </Row>
                                        <p className="mt-3 mb-0 text-muted text-sm">
                                            <span className="text-success mr-2">
                                                <i className="fa fa-charging-station" />{" "}
                                                {numberWithPoints(
                                                    Math.round(
                                                        this.state.data.persona_juridica_sumkwhanual /
                                                        1000000
                                                    )
                                                )}
                                            </span>{" "}
                                            <span className="text-nowrap">Gwh</span>
                                        </p>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>

                    </Container>
                </div>
            </>
        );
    }
}

export default withApollo(PropuestasControlPanel);
