function compare( a, b ) {
	if ( a.fecha < b.fecha ){
		return -1;
	}
	if ( a.fecha > b.fecha ){
		return 1;
	}
	return 0;
}

export default (data) => {
	let newData = [];
	let objeto;

	const months = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];
	// @ts-ignore
	Object.values(data)[0].forEach((element) => {
		objeto = {
			data: parseInt(element.data),
			label: months[parseInt(element.label)-1],
			fecha: element.anio + '-'+element.label
		};

		// @ts-ignore
		newData.push(objeto);
	})

	newData.sort(compare);
	return newData;
}
