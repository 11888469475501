
/*eslint-disable*/
import React from "react";
import LoadingOverlay from "react-loading-overlay";

// reactstrap components
import { Card, CardBody, CardTitle, Col, ListGroup, ListGroupItem, Row } from "reactstrap";


interface RateGasInformationInterface {
  isLoading: boolean,
  cardClass?: string,
  sip: {};
}
class RateGasInformation extends React.Component<RateGasInformationInterface> {
  constructor(props) {
    super(props);
  }

  renderCircle(top, bottom) {
    return <div className="btn rounded-circle" key={top}>
      <span className="btn-inner">
        {top}
      </span>
      <span className="btn-inner">
        {bottom} Kw
      </span>
    </div>;
  }


  renderCircles() {
    const sip: any = this.props.sip;
    var numeroPeriodos = 0;
    if(sip.codigoPeajeEnVigor == '35'){
      numeroPeriodos = 2;
    } else{numeroPeriodos = 1};

    //const numeroPeriodos = parseInt(sip.NumeroPeriodos);
    const consumptions: any = [];
    for (var i = 1; i <= numeroPeriodos; i++) {
      let consumoEnergiaActiva = `ConsumoAnualEnWhP${i}`;
      let consumo = parseFloat(sip[consumoEnergiaActiva]);

      const element = this.renderCircle(`P${i}`, consumo);
      consumptions.push(element);
    }

    return consumptions;
  }


  render() {
    const sip: any = this.props.sip;

    const consumptions: any = this.renderCircles();
    const Whanual = (parseInt(sip.ConsumoAnualEnWhP1) + parseInt(sip.ConsumoAnualEnWhP2))

    return (
      <Card id="rateInformation" className={this.props.cardClass}>
        <CardBody>
          <CardTitle>Información de la tarifa</CardTitle>
          <LoadingOverlay
            active={this.props.isLoading}
            spinner
            text="Cargando...">
            <Row>
              <Col md="12">
                <ListGroup>
                  <ListGroupItem><strong>Distribuidora:</strong> {sip.nombreEmpresaDistribuidora}</ListGroupItem>
                  <ListGroupItem><strong>Consumo Anual:</strong> {Whanual} Kw</ListGroupItem>
                  <ListGroupItem><strong>Tarifa actual:</strong> {sip.codigoPeajeEnVigor}</ListGroupItem>
                  <ListGroupItem><strong>Descripción Tarifa actual:</strong> {sip.desCodigoPeajeEnVigor}</ListGroupItem>
                  <div id="consumptions">
                    {consumptions}
                  </div>
                </ListGroup>
              </Col>
            </Row>
          </LoadingOverlay>
        </CardBody>
      </Card>
    );
  }
}

export default RateGasInformation;
