import React from "react";
import {
    Button,
    FormGroup,
	Modal,
	Label,
	Col,
	Input,
    Row,
    Form,
} from "reactstrap";
import ReactBSAlert from "react-bootstrap-sweetalert";

//import client from "../../../api/client";
import axios from 'axios';
//import { LIST_COMERCIAL_PRODUCTOSGAS } from "../../../queries/comercial-producto/comercial-producto";


interface IModalComparativeProps {
    itemsSelected: Array<any>;
    isOpen: boolean;
    toggle: any;
    sip: any;
    periods: number[];
    generateComparative?: Function;
}

interface IModalComparativeState {
    form: {
        idProducto: any
        costeOperativo: number,
        costeOperativoPotencia: number,
        mibGasPrice: number

    },
    cups: string,
    user: any,
    sip: any,
    periods: number[],
    itemsSelected: any[],
    comercialProductos: any[],
    mibGasPrice: number,
    alert: any
}

export default class SipGasComparativeFreeModal extends React.Component<IModalComparativeProps,IModalComparativeState> {
	public formComparativeRef: any = null;
	public formSaveComparativeRef: any = null;
	public state: IModalComparativeState = {
        // --- Data
        form: {
            costeOperativo: 0,
            costeOperativoPotencia: 0,
            idProducto: null,
            mibGasPrice: 0
        },
		cups: "",
		user: {},
        sip: {},
        periods: [],
        itemsSelected: [],
        comercialProductos: [],
        mibGasPrice:0,
        alert: null
	};

    componentWillReceiveProps(newProps) {
        this.setState({itemsSelected: newProps.itemsSelected});
    }

	componentDidMount() {
        this.formComparativeRef = React.createRef();
        this.fetchItems();
    }

	fetchItems = async () => {
    //console.log('VALOR DE this.props.sip en sipComparativeGasFreeModal ------------->',this.props)


    //if (this.props.sip.codigoTarifaATREnVigor == '001' ||
    var periodosCupsPotencia = ''
    var periodosCupString = ''

    if (['31', '32', '33', '34'].indexOf(this.props.sip.codigoPeajeEnVigor) >= 0) {
      periodosCupString = '1';
    } else {
      periodosCupString = '2';
    }

      const companyUser = await JSON.parse(await localStorage.getItem("user")!);
      const companyIdUser = companyUser.companyId;
      const consumoGasAnual= this.props.sip.ConsumoAnualTotal;
      const tarifa = this.props.sip.codigoPeajeEnVigor;



      var dataFilters = {
          'companyId': "",
          'idComercializadora': "",
          'id': "",
          'nombreOferta': "",
          'tipoContrato': "G",
          'esActiva': true,
          'periodoValidezDesde':"",
          'periodoValidezHasta': "",
          'peaje': periodosCupString,
          //'consumoGasAnual': consumoGasAnual,
          'tarifaATR': this.props.sip.codigoPeajeEnVigor
      };

  //'periodosPotencia': periodosCupsPotencia

      const valores = JSON.stringify(dataFilters)
      const headers = {
          'Authorization': 'Bearer my-token',
          'My-Custom-Header': 'foobar',
          'x-api-key': '$2y$10$bcT1Ukm4V/6/z5GvitZv0unu8I91mpixuwUO6z5CyEGev9DDDY28W'
      };
      let direccionFile = "https://datapi.psgestion.es/producto/listado?id=" + companyIdUser;

      axios.get(direccionFile, { headers, params: { valores } }).
          then(result => {
              //console.log('resultado del listados')
              if(result.data.rows.length>0){
              let data = result.data.rows
              //console.log('resultado del listados', data)
              this.setState({ comercialProductos: data });

              } else {
              this.warningAlertNIF()
              };
          }).catch((error) => {
              console.log('Failure')
              this.warningAlertDB();
          });


              let direccionFileMibgas = "https://datapi.psgestion.es/facturas/mibGasPrice?id=" + companyIdUser;

              axios.get(direccionFileMibgas, { headers }).
                  then(result => {
                      //console.log('resultado del listados mibgas ',result)
                      if(result.data.length>0){
                      let data = result.data[0]
                     //console.log('resultado del listados mibgas data', data)
                      this.setState({ mibGasPrice: data.mibgasprecio });

                      } else {
                      this.warningAlertNIF()
                      };
                  }).catch((error) => {
                      console.log('Failure')
                      this.warningAlertDB();
                  });



    };

    hideAlert = () => {
        this.setState({
            alert: null
        });
    };


        warningAlert = () => {
            this.setState({
                alert: (
                    <ReactBSAlert
                        warning
                        style={{ display: "block", marginTop: "-100px" }}
                        title="Error en selección"
                        onConfirm={() => this.hideAlert()}
                        onCancel={() => this.hideAlert()}
                        confirmBtnBsStyle="warning"
                        confirmBtnText="Ok"
                        btnSize=""
                    >
                        Es necesario seleccionar un producto
             </ReactBSAlert>
                )
            });
        };

        warningAlertDB = () => {
            this.setState({
                alert: (
                    <ReactBSAlert
                        warning
                        style={{ display: "block", marginTop: "-100px" }}
                        title="Error en la base de datos"
                        onConfirm={() => this.hideAlert()}
                        onCancel={() => this.hideAlert()}
                        confirmBtnBsStyle="warning"
                        confirmBtnText="Ok"
                        btnSize=""
                    >
                        Existe un error en la Base de datos.
             </ReactBSAlert>
                )
            });
        };


        warningAlertNIF = () => {
            this.setState({
                alert: (
                    <ReactBSAlert
                        warning
                        style={{ display: "block", marginTop: "-100px" }}
                        title="No existen registros que cumplan los requisitos"
                        onConfirm={() => this.hideAlert()}
                        onCancel={() => this.hideAlert()}
                        confirmBtnBsStyle="warning"
                        confirmBtnText="Ok"
                        btnSize=""
                    >
                        Revise los requisitos introducidos
         </ReactBSAlert>
                )
            });
        };


	generateComparative = async ( event ) => {
        if(this.props.generateComparative )
        {
            if( this.state.form.idProducto )
            {
                let producto = this.state.comercialProductos.filter( item => item.id === this.state.form.idProducto)[0];
                this.props.generateComparative({
                    productoSeleccionado: producto,
                    costeOperativo: Number(this.state.form.costeOperativo),
                    costeOperativoPotencia: Number(this.state.form.costeOperativoPotencia),
                    mibGasPrice: Number(this.state.mibGasPrice)

                });
                this.props.toggle();
                this.setState({
                    form: {
                        costeOperativo: 0,
                        costeOperativoPotencia: 0,
                        mibGasPrice: 0,
                        idProducto: null
                    }
                })
                return;
            }else{
            this.warningAlert();
            }
        }
    }

    onChangeField = async ( event ) => {
        const name = event.target.name;
        let value = event.target.value;
        let inputs = {};

        if ( value !== "" || value !== null)
        {
            inputs[name] = value;
            this.setState({
                form: {
                    ...this.state.form,
                    ...inputs
                }
            });
        }
    }

	render() {
        const sip: any = this.props.sip;
        const form: any =  this.state.form;
        const productos: any[] = this.state.comercialProductos;
        const mibGasPrice = this.state.mibGasPrice;
        var peajeString = sip.codigoPeajeEnVigor;
        var peaje='';

        if (peajeString != undefined){


        const insertAt = (str, sub, pos) => `${str.slice(0, pos)}${sub}${str.slice(pos)}`;
         peaje = insertAt(peajeString, '.', 1);}

		return (
			<>
				<Modal
                    size="sm"
                    className="modal-dialog-centered modal-sip-comparative-free"
                    isOpen={this.props.isOpen}
                    toggle={this.props.toggle}>
                    <div className="modal-body">
                        <Row>
                            <div className="modal-info">
                              <Row>
                                <h1>PRODUCTOS GAS</h1>
                                </Row>

                                  </div>
                        </Row>
                    <h4> CONSUMO ULTIMOS 12 MESES {sip.ConsumoAnualTotal}</h4>
                        <Form innerRef={this.formComparativeRef}>
                            <Row>
                                <Col>
                                    <FormGroup>
                                        <Label className="form-control-label" htmlFor="fee">
                                            Tarifa de acceso Actual
                                        </Label>
                                        <Input
                                            id="tarifaAcceso"
                                            placeholder=""
                                            name="tarifaAcceso"
                                            value={peaje}
                                            type="text"
                                            readOnly />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label className="form-control-label"
                                            htmlFor="idProducto">
                                            Seleccione un producto
                                        </Label>
                                        <span hidden={ productos.length > 0 ? true : false }><br /> No existen productos para tus criterios de búsqueda</span>
                                        <Input
                                            id="idProducto"
                                            placeholder=""
                                            name="idProducto"
                                            onChange={this.onChangeField}
                                            hidden={ productos.length > 0 ? false : true }
                                            type="select" multiselect>
                                                <option value="">Seleccionar Producto</option>
                                                { productos.map((item:any, index) => <option value={item.id} key={index}>{item.nombreOferta}</option>) }

                                        </Input>
                                    </FormGroup>
{this.state.alert}

                                    <FormGroup>
                                        <Label className="form-control-label" htmlFor="costeOperativo">
                                            CO c€/kWh
                                        </Label>
                                        <Input
                                            id="costeOperativo"
                                            placeholder=""
                                            value={form.costeOperativo}
                                            name="costeOperativo"
                                            type="number"
                                            onChange={this.onChangeField}
                                            required/>
                                    </FormGroup>

                                    <FormGroup>
                                        <Label className="form-control-label" htmlFor="mibGasPrice">
                                            Precio Medio MIBGAS (Ultimo mes)
                                        </Label>
                                        <Input
                                            id="mibGasPrice"
                                            placeholder=""
                                            value={mibGasPrice}
                                            name="mibGasPrice"
                                            type="number"
                                            required/>
                                    </FormGroup>



                                </Col>
                            </Row>
                        </Form>
                    </div>
                    <div className="modal-footer">
	                    <Button
	                        onClick={this.props.toggle}
	                        color="secondary"
	                        data-dismiss="modal"
	                        type="button">
	                        Cerrar
	                    </Button>
	                    <Button onClick={this.generateComparative} color="primary" type="button">
	                        Generar Comparativa
	                    </Button>
                    </div>
                </Modal>
			</>
		);
	}
}
