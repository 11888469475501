import React from "react";
import {
  Button,
  FormGroup,
  Modal,
  Label,
  Col,
  Input,
  Row,
  Form,
  FormText,
  } from "reactstrap";

  import client from "../../api/client";
import axios from 'axios';
  //import Multiselect from 'multiselect-react-dropdown';
  import SipsOption from "../../components/Sips/SipsOption";
import { validateSpanishId, spainIdType  } from 'spain-id'
  import { LIST_DICMUNICIPIOS } from "../../queries/options/dic-option.graphql";
import ReactBSAlert from "react-bootstrap-sweetalert";
  import { ToastContainer, toast } from 'react-toastify';

const ibantools = require('ibantools');

const notifyError = () => toast.error(
    "No existe cliente con el CIF/NIF introducido",
    {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });


const notifyErrorCIF = () => toast.error(
  "Introduzca CIF/NIF para poder hacer la búsqueda",
  {
    position: "top-center",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });


  interface IModalProps {
    isOpen: boolean;
    toggle: any;
    onSave?: Function;
    sip: any;
    ubi: any;
    nombrePropuesta: string;
    companyId:any;
    userId:any;
  }

  interface IModalState {
    form: {
      nombreOferta: string;
      correoContacto: string;
      idComercializadora: string;
      nombreComercializadora: string;
      esAprobada: boolean;
      razonSocial: any;
      NIF: any;
      DireccionFiscal: any;
      nombreTitular: any;
      apellido1Titular: any;
      apellido2Titular: any;
      tipoTitular:any;
      nombreRepresentante: any;
      apellido1Representante: any;
      apellido2Representante: any;
      NIFRepresentante: any;
      CargoRepresentante:any;
      idRepresentante: any;
      idRepresentanteInterno: any;
      telefonoRepresentante: any;
      correoRepresentante: any;
      companyIdRepresentante: any;
      desAutonomiaTitular: any;
      desProvinciaTitular: any;
      desMunicipioTitular: any;
      tipoViaTitular: any;
      viaTitular: any;
      numFincaTitular: any;
      portalTitular: any;
      escaleraTitular: any;
      pisoTitular: any;
      puertaTitular: any;
      iban: any;
      CodigoPostalTitular: any; //"08630",----> codigo_postal_titular
      TelefonoFijo: any; //"949201893", ----> telefono_fijo
      TelefonMovil: any; //"689105332", ----> telefono
      Email: any; //"demo@demo.com", --->email_contacto
      TipoCliente: any; //"COMUNIDAD DE PROPIETARIOS", ---> tipo_cliente
      ObservacionesCliente: any; //"OPCIONAL O NULL",----> observaciones
      DireccionPS: any; // "C/ FELIPE SOLANO ANTELO 8A 8º3", ---> direccion_ps

      nombreMunicipioPS: any;
      nombreProvinciaPS: any;
      //nombreAutonomiaPS: any;
      tipoViaPS: any;
      viaPS: any;
      numFincaPS: any;
      portalPS: any;
      escaleraPS: any;
      pisoPS: any;
      puertaPS: any;

      CodigoPostalPS: any; //"08630", ----> codigo_postal_ps
      TelefonoPS: any; //"689105332", ----> telefono;
      ObservacionesPuntoSuministro: any;
      GDPR: boolean; // "opcional o null" ----> observaciones_ps
    },
    companyId:any;
    userId:any;
    cups: string;
    user: any;
    isDisabled: boolean;
    nombreOfertaError: boolean;
    emailError: boolean;
  emailRepreError: boolean;
    nombreTitularError: boolean;
    NIFError: boolean;
    DireccionFiscalError: boolean;
    CodigoPostalTitularError: boolean;
    TelefonoFijoError: boolean;
    TelefonMovilError: boolean;
    DireccionPSError: boolean;
    CodigoPostalPSError: boolean;
    TelefonoPSError: boolean;
    idComercializadoraError: boolean;
    TipoCliente: any;
    apellido1TitularError: boolean;
    ibanError: boolean;
    dicMunicipios: any[];
    client: {
      idCliente: string;
      idTipoTitular: string;
      descIdTipoTitular: string;
      nombreTitular: string;
      apellido1Titular: string;
      apellido2Titular: string;
      codigoProvinciaTitular: string;
      desProvinciaTitular: string;
      codigoPostalTitular: string;
      municipioTitular: string;
      desMunicipioTitular: string;
      tipoViaTitular: string;
      viaTitular: string;
      numFincaTitular: string;
      portalTitular: string;
      escaleraTitular: string;
      pisoTitular: string;
      puertaTitular: string;
      esViviendaHabitual: string;
      CNAE: string;
      actividadCNAE: string;
      CIF: string;
      codigoAutonomiaPS: string;
      desAutonomiaPS: string;
      idBanco: string;
      descBanco: string;
      iban: string;
      idCompany: string
    };
    isDisabledCIF: boolean;
    alert:any;
  }

  export default class SaveModal extends React.Component<
    IModalProps,
    IModalState
    > {
    public formComparativeRef: any = null;
    public formSaveComparativeRef: any = null;
    public state: IModalState = {
      // --- Data
      form: {
        nombreOferta: "",
        correoContacto: "",
        idComercializadora: "",
        nombreComercializadora: "",
        esAprobada: false,
        razonSocial: null,
        NIF: "", //"C19345456", --> CIF
        DireccionFiscal: "", // "C/ FELIPE SOLANO ANTELO 8A 8º3", ---> direccion_titular
        nombreTitular: "",
        apellido1Titular: "",
        apellido2Titular: "",
        nombreRepresentante: "",
        apellido1Representante: "",
        apellido2Representante: "",
        tipoTitular:"",
        NIFRepresentante: "",
          CargoRepresentante:"",
        idRepresentante:"",
        idRepresentanteInterno:"",
        telefonoRepresentante:"",
        correoRepresentante:"",
        companyIdRepresentante:"",
        desAutonomiaTitular: "",
        desProvinciaTitular: "",
        desMunicipioTitular: "",
        tipoViaTitular: "",
        viaTitular: "",
        numFincaTitular: "",
        portalTitular: "",
        escaleraTitular: "",
        pisoTitular: "",
        puertaTitular: "",
        iban: "",
        CodigoPostalTitular: "", //"08630",----> codigo_postal_titular
        TelefonoFijo: "", //"949201893", ----> telefono_fijo
        TelefonMovil: "", //"689105332", ----> telefono
        Email: "", //"demo@demo.com", --->email_contacto
        TipoCliente: "NO DEFINIDO", //"COMUNIDAD DE PROPIETARIOS", ---> tipo_cliente
        ObservacionesCliente: "", //"OPCIONAL O NULL",----> observaciones
        DireccionPS: "", // "C/ FELIPE SOLANO ANTELO 8A 8º3", ---> direccion_ps
        nombreMunicipioPS: "",
        nombreProvinciaPS: "",
        //nombreAutonomiaPS: "",
        tipoViaPS: "",
        viaPS: "",
        numFincaPS: "",
        portalPS: "",
        escaleraPS: "",
        pisoPS: "",
        puertaPS: "",
        CodigoPostalPS: "", //"08630", ----> codigo_postal_ps
        TelefonoPS: "", //"689105332", ----> telefono;
        ObservacionesPuntoSuministro: "",
        GDPR: true, // "opcional o null" ----> observaciones_ps
      },
      cups: "",
      user: {},
      companyId:"",
      userId:"",
      isDisabled: true,
      nombreOfertaError: false,
      emailError: true,
      emailRepreError: false,
      nombreTitularError: true,
      NIFError: true,
      DireccionFiscalError: true,
      CodigoPostalTitularError: true,
      TelefonoFijoError: true,
      TelefonMovilError: true,
      DireccionPSError: true,
      CodigoPostalPSError: true,
      TelefonoPSError: true,
      idComercializadoraError: true,
      apellido1TitularError: true,
      ibanError: false,
      isDisabledCIF: true,
      dicMunicipios: [],
    alert: null,
      client: {
        idCliente: "",
        idTipoTitular: "",
        descIdTipoTitular: "",
        nombreTitular: "",
        apellido1Titular: "",
        apellido2Titular: "",
        codigoProvinciaTitular: "",
        desProvinciaTitular: "",
        codigoPostalTitular: "",
        municipioTitular: "",
        desMunicipioTitular: "",
        tipoViaTitular: "",
        viaTitular: "",
        numFincaTitular: "",
        portalTitular: "",
        escaleraTitular: "",
        pisoTitular: "",
        puertaTitular: "",
        esViviendaHabitual: "",
        CNAE: "",
        actividadCNAE: "",
        CIF: "",
        codigoAutonomiaPS: "",
        desAutonomiaPS: "",
        idBanco: "",
        descBanco: "",
        iban: "",
        idCompany: ""
      },
      TipoCliente: [{ key: "0", cat: "NO DEFINIDO" },
      { key: "1", cat: "PARTICULAR" },
      { key: "2", cat: "PYME INDUSTRIA" },
      { key: "3", cat: "PYME SERVICIO" },
      { key: "4", cat: "ADMINISTRACION PUBLICA" },
      { key: "5", cat: "COMUNIDAD DE PROPIETARIO" }
      ]
    };


    componentWillReceiveProps(newProps) {

  console.log('VALOR DE newProps ----> ', newProps)

      if (!newProps.ubi) {
        this.setState({
            companyId:newProps.companyId,
            userId:newProps.userId,
          form:
          {
            nombreOferta: newProps.nombrePropuesta,
            correoContacto: "",
            idComercializadora: "",
            nombreComercializadora: "",
            esAprobada: false,
            razonSocial: null,
            NIF: null, //"C19345456", --> CIF
            DireccionFiscal: null, // "C/ FELIPE SOLANO ANTELO 8A 8º3", ---> direccion_titular
            nombreTitular: "",
            apellido1Titular: "",
            apellido2Titular: "",
              tipoTitular:"",
            nombreRepresentante: "",
            apellido1Representante: "",
            apellido2Representante: "",
            NIFRepresentante: "",
          CargoRepresentante:"",
            idRepresentante:"",
            idRepresentanteInterno:"",
            telefonoRepresentante:"",
            correoRepresentante:"",
            companyIdRepresentante:"",
            desAutonomiaTitular: "",
            desProvinciaTitular: "",
            desMunicipioTitular: "",
            tipoViaTitular: "",
            viaTitular: "",
            numFincaTitular: "",
            portalTitular: "",
            escaleraTitular: "",
            pisoTitular: "",
            puertaTitular: "",
            iban: "",
            CodigoPostalTitular: "", //"08630",----> codigo_postal_titular
            TelefonoFijo: "", //"949201893", ----> telefono_fijo
            TelefonMovil: "", //"689105332", ----> telefono
            Email: "", //"demo@demo.com", --->email_contacto
            TipoCliente: "NO DEFINIDO", //"COMUNIDAD DE PROPIETARIOS", ---> tipo_cliente
            ObservacionesCliente: "", //"OPCIONAL O NULL",----> observaciones

            DireccionPS: "", // "C/ FELIPE SOLANO ANTELO 8A 8º3", ---> direccion_ps
            nombreMunicipioPS: "",
            nombreProvinciaPS: "",
            //nombreAutonomiaPS: "",
            tipoViaPS: "",
            viaPS: "",
            numFincaPS: "",
            portalPS: "",
            escaleraPS: "",
            pisoPS: "",
            puertaPS: "",

            CodigoPostalPS: "", //"08630", ----> codigo_postal_ps
            TelefonoPS: "", //"689105332", ----> telefono;
            ObservacionesPuntoSuministro: "",
            GDPR: true, // "opcional o null" ----> observaciones_ps
          }
        });
      } else {

        this.setState({
            companyId:newProps.companyId,
            userId:newProps.userId,
          form:
          {
            nombreOferta: newProps.nombrePropuesta,
            correoContacto: "",
            idComercializadora: "",
            nombreComercializadora: "",
            esAprobada: false,
            razonSocial: newProps.ubi.nombre_completo_titular,
            NIF: newProps.ubi.CIF, //"C19345456", --> CIF
            DireccionFiscal: newProps.ubi.direccion_titular, // "C/ FELIPE SOLANO ANTELO 8A 8º3", ---> direccion_titular
            nombreTitular: newProps.ubi.nombre_completo_titular,
            apellido1Titular: "",
            apellido2Titular: "",
              tipoTitular:"",
            nombreRepresentante: "",
            apellido1Representante: "",
            apellido2Representante: "",
            NIFRepresentante: "",
            CargoRepresentante:"",
            idRepresentante:"",
            idRepresentanteInterno:"",
            telefonoRepresentante:"",
            correoRepresentante:"",
            companyIdRepresentante:"",
            desAutonomiaTitular: "",
            desProvinciaTitular: "",
            desMunicipioTitular: "",
            tipoViaTitular: "",
            viaTitular: "",
            numFincaTitular: "",
            portalTitular: "",
            escaleraTitular: "",
            pisoTitular: "",
            puertaTitular: "",
            iban: "",
            CodigoPostalTitular: newProps.ubi.codigo_postal_titular, //"08630",----> codigo_postal_titular
            TelefonoFijo: newProps.ubi.telefono_fijo, //"949201893", ----> telefono_fijo
            TelefonMovil: newProps.ubi.telefono, //"689105332", ----> telefono
            Email: newProps.ubi.email_contacto, //"demo@demo.com", --->email_contacto
            TipoCliente: "NO DEFINIDO", //"COMUNIDAD DE PROPIETARIOS", ---> tipo_cliente
            ObservacionesCliente: newProps.ubi.observaciones, //"OPCIONAL O NULL",----> observaciones

            DireccionPS: newProps.ubi.direccion_ps, // "C/ FELIPE SOLANO ANTELO 8A 8º3", ---> direccion_ps

            nombreMunicipioPS: "",
            nombreProvinciaPS: "",
            //nombreAutonomiaPS: "",
            tipoViaPS: "",
            viaPS: "",
            numFincaPS: "",
            portalPS: "",
            escaleraPS: "",
            pisoPS: "",
            puertaPS: "",

            CodigoPostalPS: newProps.ubi.codigo_postal_ps, //"08630", ----> codigo_postal_ps
            TelefonoPS: newProps.ubi.telefono, //"689105332", ----> telefono;
            ObservacionesPuntoSuministro: "",
            GDPR: true, // "opcional o null" ----> observaciones_ps
          }
        });


      }
}

    componentDidMount() {
      this.fetchItems();
    }

    fetchItems = async () => {

      await client.query({
        query: LIST_DICMUNICIPIOS,
        variables: {}
      })
        .then((result) => {
          let data = result.data.listDicMunicipios;
          this.setState({ dicMunicipios: data })
        });
    }

    UNSAFE_componentWillMount() {


      if (this.state.form.nombreOferta !== '' || this.state.form.nombreOferta !== null || this.state.form.nombreOferta !== '0') {
        this.setState({
          nombreOfertaError: false,
        })
      }

      if (this.state.form.correoContacto !== '' || this.state.form.correoContacto !== null || this.state.form.correoContacto !== '0') {
        const pattern = /[a-zA-Z0-9]+[\.]?([a-zA-Z0-9]+)?[\@][a-z]{3,9}[\.][a-z]{2,5}/g;
        const result = pattern.test(this.state.form.correoContacto);

        if (result === true) {
          this.setState({
            emailError: false
          })
        }

      };

      this.forceUpdate()
    };

    validateEmail = (email) => {

      const pattern = /[a-zA-Z0-9]+[\.]?([a-zA-Z0-9]+)?[\@][a-z]{3,9}[\.][a-z]{2,5}/g;
      //const pattern = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/
      const result = pattern.test(email);
      if (result === true) {
        this.setState({
          emailError: false,
        })
      } else {
        this.setState({
          emailError: true
        })
      }
    }


    validatePhone = (TelefonoFijo) => {
      //const pattern = /[a-zA-Z0-9]+[\.]?([a-zA-Z0-9]+)?[\@][a-z]{3,9}[\.][a-z]{2,5}/g;
      //const pattern = ^\+{0,2}([\-\. ])?(\(?\d{0,3}\))?([\-\. ])?\(?\d{0,3}\)?([\-\. ])?\d{3}([\-\. ])?\d{4}

      const pattern = /^[679]{1}[0-9]{8}$/
      var strPhone = TelefonoFijo.toString().replace(/\s/g, '');
      const result = strPhone.length === 9 && pattern.test(TelefonoFijo);
      if (result === true) {
        this.setState({
          TelefonoFijoError: false,
        })
      } else {
        this.setState({
          TelefonoFijoError: true
        })
      }
    }

    onChangeField = (event) => {
      event.persist()
      let formData = { ...this.state.form };


       let target = event.target;
      let value =
        event.target.type === "checkbox"
          ? event.target.checked
          : event.target.value;
      const name = event.target.name;


      if (event.target.name === 'nombreOferta') {

        if (event.target.value) {
          this.setState({
            nombreOfertaError: false
          })
        } else {
          this.setState({
            nombreOfertaError: true,
          })
        }
      }



      if (event.target.name === 'correoContacto') {


        const pattern = /[a-zA-Z0-9]+[\.]?([a-zA-Z0-9]+)?[\@][a-z]{3,9}[\.][a-z]{2,5}/g;
        //const pattern = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/
        const result = pattern.test(event.target.value);

        if (result === true) {
          this.setState({
            emailError: false,
          })
        } else {
          this.setState({
            emailError: true
          })
        }
      }

      if (event.target.name === 'iban') {

        const resultIban = ibantools.isValidIBAN(event.target.value);

        if (resultIban === true) {
          this.setState({
            ibanError: false,
          })
        } else {
          this.setState({
            ibanError: true
          })
        }
      }



      if (event.target.name === 'apellido1Titular') {

        if (event.target.value) {
          this.setState({
            apellido1TitularError: false
          })
        } else {
          this.setState({
            apellido1TitularError: true,
          })
        }
      }

      if (event.target.name === 'nombreTitular') {

        if (event.target.value) {
          this.setState({
            nombreTitularError: false
          })
        } else {
          this.setState({
            nombreTitularError: true,
          })
        }
      }

      if (event.target.name === 'NIFRepresentante') {
        const result = validateSpanishId(event.target.value)
  console.log('VALOR DE RESULTADO DE VALIDATESpanish------> ', result)

        //if(event.target.value){
        if (result === true) {
            const resultTipo = spainIdType(event.target.value)
            console.log('VALOR DE RESULTADO DE VALIDATE Tipo -------> ', resultTipo)
            formData['tipoTitular'] = resultTipo.toUpperCase();
          this.setState({
            NIFError: false,
            isDisabledCIF: false
          })

          /*  this.setState(
            ({ NIFError }) => ({
              NIFError:false,
                isDisabledCIF:false
            }), () => {
              this.setState(({ NIFError }) => ({
                NIFError:false,
                  isDisabledCIF:false
              }))
            } )*/
        } else {
          this.setState({
            NIFError: true,
          })
        }
      }

      if (event.target.name === 'DireccionFiscal') {

        if (event.target.value) {
          this.setState({
            DireccionFiscalError: false
          })
        } else {
          this.setState({
            DireccionFiscalError: true,
          })
        }
      }

      if (event.target.name === 'CodigoPostalTitular') {

        if (event.target.value) {
          this.setState({
            CodigoPostalTitularError: false
          })
        } else {
          this.setState({
            CodigoPostalTitularError: true,
          })
        }
      }

      if (event.target.name === 'TelefonoFijo') {
        this.validatePhone(event.target.value);
      }

      if (event.target.name === 'DireccionPS') {

        if (event.target.value) {
          this.setState({
            DireccionPSError: false
          })
        } else {
          this.setState({
            DireccionPSError: true,
          })
        }
      }

      if (event.target.name === 'CodigoPostalPS') {

        if (event.target.value) {
          this.setState({
            CodigoPostalPSError: false
          })
        } else {
          this.setState({
            CodigoPostalPSError: true,
          })
        }
      }

      if (name === "idComercializadora" && value !== "0") {

        if (value !== "0") {
          formData.nombreComercializadora = [
            ...event.target.querySelectorAll("option"),
          ].filter((item) => item.value === value)[0].innerText;
          this.setState({
            idComercializadoraError: false,
          })
        } else {
          this.setState({
            idComercializadoraError: true
          })
          value = null;
        }
      }
      var valueTemp: any = [];

      if (name === "TipoCliente") {
        const valorIndex = event.target.options.selectedIndex.toString();
        valueTemp = this.state.TipoCliente.find(x => x.key === valorIndex)
        value = valueTemp.cat;
      }

      if (this.state.nombreOfertaError === false && this.state.idComercializadoraError === false && this.state.emailError === false && this.state.NIFError === false ) {
        this.setState({
          isDisabled: false
        })
      }

      if (this.state.NIFError === false) {
        this.setState({
          isDisabledCIF: false
        })
      }

      if (name == 'desMunicipioTitular') {

        const municipio = this.state.dicMunicipios
        const resultado = municipio.find(municipio => municipio.Nombre === value);

        //console.log('VALOR DE FIND -------------------->', resultado.DescProvincia, resultado.DescAutonomia);

        //const filtered = municipio.filter(entry => Object.values(entry).some(val => typeof val === "string" && val.includes('Barromán')));

        //console.log(filtered);
        formData['desProvinciaTitular'] = resultado.DescProvincia
        formData['desAutonomiaTitular'] = resultado.DescAutonomia

      }


      if (name == 'nombreMunicipioPS') {
        const municipio = this.state.dicMunicipios
        const resultado = municipio.find(municipio => municipio.Nombre === value);
        const filtered = municipio.filter(entry => Object.values(entry).some(val => typeof val === "string" && val.includes('Barromán')));
        formData['nombreProvinciaPS'] = resultado.DescProvincia
        formData['desAutonomiaPS'] = resultado.DescAutonomia
      }



      formData[name] = value;

      this.setState({ form: formData });
    };

////////////////////////////////////////////////////////////////////////////////////

getRepresentante = async () => {

    var companyIdUser = this.state.companyId.toString();
    var user = this.state.userId
    var CIF = this.state.form.NIFRepresentante
if (CIF !== null && CIF != '') {


    var dataFilters = {
        'idCompany': companyIdUser,
        'cups': "",
        'Usuario': "",
        'NIFRepresentante': CIF,
        'idContrato': ""
    };

    const valores = JSON.stringify(dataFilters)
    const headers = {
        'Authorization': 'Bearer my-token',
        'My-Custom-Header': 'foobar',
        'x-api-key': '$2y$10$bcT1Ukm4V/6/z5GvitZv0unu8I91mpixuwUO6z5CyEGev9DDDY28W'
    };

    let direccionFile = "https://datapi.psgestion.es/representante/listado?id=" + companyIdUser + "&user=" + user;

    await axios.get(direccionFile, { headers, params: { valores } }).
        then(result => {

            if(result.data.rows.length>0){
            let data = result.data.rows
            //this.setState({ representantes: data })
            let formData = { ...this.state.form };
            formData['nombreRepresentante'] = data[0].nombreRepresentante
            formData['apellido1Representante'] = data[0].apellido1Representante
            formData['apellido2Representante'] = data[0].apellido2Representante
            formData['NIFRepresentante'] = data[0].NIFRepresentante
            formData['idRepresentante'] = data[0].idRepresentante
            formData['idRepresentanteInterno'] = data[0].idRepresentanteInterno
            formData['CargoRepresentante'] = data[0].CargoRepresentante
            formData['telefonoRepresentante'] = data[0].telefonoRepresentante
            formData['correoRepresentante'] = data[0].correoRepresentante
            this.setState({ form: formData });
            } else {
            notifyError();
                };

        }).catch((error) => {
            console.log('Failure')
            //this.warningAlertDB()
            notifyError();
        });
        } else {
           notifyErrorCIF();


       };

};


/////////////////////////////////////////////////////////////////////////////////////////////

    generateComparative = (event) => {

      event.preventDefault();

      if (this.props.onSave) {

        if (this.state.form.nombreOferta) {
          this.props.onSave(this.state.form);
          this.props.toggle(event);

          var TelefonoPSError: boolean = true;
          if (this.props.ubi.telefono) {

            TelefonoPSError = false;
          } else {
            TelefonoPSError = true;

          }


          var CodigoPostalPSError: boolean = true;
          if (this.props.ubi.codigo_postal_ps) {

            CodigoPostalPSError = false;
          } else {
            CodigoPostalPSError = true;
          }

          var TelefonMovilError: boolean = true;
          if (this.props.ubi.telefono) {

            TelefonMovilError = false;
          } else {
            TelefonMovilError = true;
          }

          var TelefonoFijoError: boolean = true;
          if (this.props.ubi.telefono_fijo) {

            TelefonoFijoError = false;
          } else {
            TelefonoFijoError = true;
          }

          var CodigoPostalTitularError: boolean = true;
          if (this.props.ubi.codigo_postal_titular) {

            CodigoPostalTitularError = false;
          } else {
            CodigoPostalTitularError = true;
          }

          var DireccionFiscalError: boolean = true;
          if (this.props.ubi.direccion_titular) {

            DireccionFiscalError = false;
          } else {
            DireccionFiscalError = true;
          }

          var NIFError: boolean = true;
          if (this.props.ubi.CIF) {

            NIFError = false;
          } else {
            NIFError = true;
          }

          var nombreTitularError: boolean = true;
          if (this.props.ubi.nombre_completo_titular) {

            nombreTitularError = false;
          } else {
            nombreTitularError = true;
          }

          var emailError: boolean = true;
          if (this.props.ubi.email_contacto) {

            emailError = false;
          } else {
            emailError = true;
          }


          var apellido1TitularError: boolean = true;
          apellido1TitularError = true;


          var direccionPSError: boolean = true;
          if (this.props.ubi.direccion_ps) {
            direccionPSError = false;
          } else {
            direccionPSError = true;

          }




          this.setState({
            form: {
              nombreOferta: "",
              correoContacto: "",
              idComercializadora: "",
              nombreComercializadora: "",
              esAprobada: false,
              razonSocial: "",
              NIF: "", //"C19345456", --> CIF
              DireccionFiscal: "", // "C/ FELIPE SOLANO ANTELO 8A 8º3", ---> direccion_titular
              nombreTitular: "",
              apellido1Titular: "",
              apellido2Titular: "",
              tipoTitular:"",
              nombreRepresentante: "",
              apellido1Representante: "",
              apellido2Representante: "",
              NIFRepresentante: "",
              CargoRepresentante:"",
              idRepresentante:"",
              idRepresentanteInterno:"",
              telefonoRepresentante:"",
              correoRepresentante:"",
              companyIdRepresentante:"",
              desAutonomiaTitular: "",
              desProvinciaTitular: "",
              desMunicipioTitular: "",
              tipoViaTitular: "",
              viaTitular: "",
              numFincaTitular: "",
              portalTitular: "",
              escaleraTitular: "",
              pisoTitular: "",
              puertaTitular: "",
              iban: "",
              CodigoPostalTitular: "", //"08630",----> codigo_postal_titular
              TelefonoFijo: "", //"949201893", ----> telefono_fijo
              TelefonMovil: "", //"689105332", ----> telefono
              Email: "", //"demo@demo.com", --->email_contacto
              TipoCliente: "NO DEFINIDO", //"COMUNIDAD DE PROPIETARIOS", ---> tipo_cliente
              ObservacionesCliente: "", //"OPCIONAL O NULL",----> observaciones

              DireccionPS: "", // "C/ FELIPE SOLANO ANTELO 8A 8º3", ---> direccion_ps
              nombreMunicipioPS: "",
              nombreProvinciaPS: "",
              //nombreAutonomiaPS: "",
              tipoViaPS: "",
              viaPS: "",
              numFincaPS: "",
              portalPS: "",
              escaleraPS: "",
              pisoPS: "",
              puertaPS: "",
              CodigoPostalPS: "", //"08630", ----> codigo_postal_ps
              TelefonoPS: "", //"689105332", ----> telefono;
              ObservacionesPuntoSuministro: "",
              GDPR: true, // "opcional o null" ----> observaciones_ps

            },

            nombreOfertaError: false,
            emailError: emailError,
            nombreTitularError: nombreTitularError,
            NIFError: NIFError,
            DireccionFiscalError: DireccionFiscalError,
            CodigoPostalTitularError: CodigoPostalTitularError,
            TelefonoFijoError: TelefonoFijoError,
            TelefonMovilError: TelefonMovilError,
            DireccionPSError: direccionPSError,
            CodigoPostalPSError: CodigoPostalPSError,
            TelefonoPSError: TelefonoPSError,
            apellido1TitularError: apellido1TitularError,
            idComercializadoraError: true
          });
          this.forceUpdate();
          return;
        }
      }
    };


   warningAlert = () => {
    this.setState({
      alert: (
        <ReactBSAlert
          warning
          style={{ display: "block", marginTop: "-100px" }}
          title="Error"
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="warning"
          confirmBtnText="Ok"
          btnSize=""
        >
          No existe representante con el CIF/NIF introducido
        </ReactBSAlert>
      )
    });
  };


      hideAlert = () => {
        this.setState({
          alert: null
        });
      };

    render() {

      const dicMunicipios: any[] = this.state.dicMunicipios;

      const form: any = this.state.form;

      var nombre: string = form.nombreOferta;

      const radioStyle = {
        display: "flex",
        justifyContent: "space-between",
      };
      const tipoCliente: any[] = this.state.TipoCliente;
      return (
        <>
          <Modal
            size="xl"
            className="modal-dialog-centered"
            isOpen={this.props.isOpen}
            toggle={this.props.toggle}
            destroyOnClose
          >
            <div className="modal-header">
              <h3 className="modal-title" id="saveModalOpened">
                Guardar comparativa
              </h3>
              <button
                aria-label="Close"
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={this.props.toggle}
              >
                <span aria-hidden={true}>×</span>
              </button>
            </div>
            <div className="modal-body">
              <Form innerRef={this.formComparativeRef}>

            {this.state.alert}
                <Row>
                  <Col>
                    <FormGroup>
                      <Label className="form-control-label" htmlFor="propuesta">
                        Nombre Comparativa
                      </Label>
                      <Input
                        id="nombreOferta"
                        placeholder="Identificador de la comparativa"
                        name="nombreOferta"
                        onChange={this.onChangeField}
                        value={form.nombreOferta}
                        type="text"
                        required
                      > </Input>

                      {this.state.nombreOfertaError ? <FormText color="red">Por favor introduzca un nombre</FormText> : ''}
                    </FormGroup>

                    <FormGroup style={radioStyle}>
                      <Label className="form-control-label" htmlFor="esAprobada">
                        Aprobada
                      </Label>
                      <Label className="custom-toggle">
                        <Input
                          id="esAprobada"
                          name="esAprobada"
                          onChange={this.onChangeField}
                          checked={form.esAprobada}
                          type="checkbox"
                        />
                        <span className="custom-toggle-slider rounded-circle" />
                      </Label>

                    </FormGroup>
                    <h6 className="">A los efectos de lo dispuesto en la normativa vigente relativa a tratamientos de datos de carácter personal, el Comercializador se compromete al cumplimiento de su deber de guardar secreto de los datos de carácter personal, y adoptará las medidas legalmente previstas y necesarias para evitar su alteración, pérdida, tratamiento o acceso no autorizado, habida cuenta en todo momento del estado de la tecnología aplicable. El Cliente queda informado y acepta todas las cuestiones relacionadas al tratamiento de sus datos de carácter personal por parte de la empresa y terceros, que voluntariamente ha facilitado al Comercializador.</h6>

                    <FormGroup style={radioStyle}>
                      <Label className="form-control-label" htmlFor="GDPR">
                        Autoriza GDPR
                      </Label>
                      <Label className="custom-toggle">
                        <Input
                          id="GDPR"
                          name="GDPR"
                          onChange={this.onChangeField}
                          checked={form.GDPR}
                          type="checkbox"
                        />
                        <span className="custom-toggle-slider rounded-circle" />
                      </Label>
                    </FormGroup>


                    <Row className="bg-light">
                        <h3 className="col item-header">
                            REPRESENTANTE
                        </h3>
                    </Row>
                    <Row>
                      <Col>
                        <FormGroup>

                          <Label className="form-control-label" htmlFor="nombreRepresentante">
                            Nombre
                      </Label>
                          <Input
                            id="nombreRepresentante"
                            placeholder=""
                            name="nombreRepresentante"
                            onChange={this.onChangeField}
                            value={form.nombreRepresentante}
                            type="text"
                            required
                          />
                        </FormGroup>
                      </Col>

                      <Col>
                        <FormGroup>

                          <Label className="form-control-label" htmlFor="apellido1Representante">
                            Primer Apellido
                    </Label>
                          <Input
                            id="apellido1Representante"
                            placeholder=""
                            name="apellido1Representante"
                            onChange={this.onChangeField}
                            value={form.apellido1Representante}
                            type="text"
                            required
                          />
                        </FormGroup>
                      </Col>


                      <Col>
                        <FormGroup>

                          <Label className="form-control-label" htmlFor="apellido2Representante">
                            Segundo Apellido
                      </Label>
                          <Input
                            id="apellido2Representante"
                            placeholder=""
                            name="apellido2Representante"
                            onChange={this.onChangeField}
                            value={form.apellido2Representante}
                            type="text"
                            required
                          />
                        </FormGroup>
                      </Col>



                      <Col>
                        <FormGroup>
                          <Label className="form-control-label" htmlFor="NIFRepresentante">
                            NIF
                      </Label>
                          <Input
                            id="NIFRepresentante"
                            placeholder="Introduzca NIF"
                            name="NIFRepresentante"
                            onChange={this.onChangeField}
                            value={form.NIFRepresentante}
                            type="text"
                            required
                          />
                          {this.state.NIFError ? <FormText color="red">Comprobar NIF/CIF</FormText> : ''}
                        </FormGroup>
                      </Col>
                      <Col  md="2" sm="2" lg="2" >

                        <Button
                          className = "mt-4"
                          position="center"
                          margin
                          onClick={this.getRepresentante}
                          color="primary"
                          type="button"
                          disabled={this.state.isDisabledCIF}
                        >
                          <i className={"bi bi-search"}/> REPRES.
                    </Button>

                  </Col>


  </Row>

  <Row>
      <Col md="2">
        <FormGroup>
          <Label className="form-control-label" htmlFor="CargoRepresentante">
            Cargo
            </Label>
          <Input
            id="CargoRepresentante"
            placeholder=""
            name="CargoRepresentante"
            onChange={this.onChangeField}
            value={form.CargoRepresentante}
            type="text"
            required
          />
        </FormGroup>
      </Col>

      <Col md="3">
        <FormGroup>
          <Label
            className="form-control-label"
            htmlFor="correoRepresentante"
          >
            Correo de contacto
        </Label>
          <Input
            id="correoRepresentante"
            placeholder=""
            name="correoRepresentante"
            onChange={this.onChangeField}
            value={form.correoRepresentante}
            type="text"
            required
          >  </Input>
          {this.state.emailRepreError ? <FormText color="red">Por favor introduzca una dirección de correo electrónico correcto</FormText> : ''}
        </FormGroup>
      </Col>


  </Row>




                    <Row className="bg-light mt-3">
                        <h3 className="col item-header">
                            DATOS GENERALES TITULAR
                        </h3>
                    </Row>

                    <Row>
                      <Col md="5">
                        <FormGroup>
                          <Label
                            className="form-control-label"
                            htmlFor="idComercializadora"
                          >
                            Comercializadora de procedencia
                    </Label>
                          <SipsOption
                            placeholder=""
                            id="idComercializadora"
                            name="idComercializadora"
                            value={form.idComercializadora}
                            required
                            query="LIST_MARKETERS"
                            onChange={this.onChangeField}
                          />
                          {this.state.idComercializadoraError ? <FormText color="red">Por favor introduzca una comercializadora</FormText> : ''}
                        </FormGroup>
                      </Col>

                      <Col md="3">
                        <FormGroup>
                          <Label
                            className="form-control-label"
                            htmlFor="correoContactos"
                          >
                            Correo de contacto
                    </Label>
                          <Input
                            id="correoContactos"
                            placeholder=""
                            name="correoContacto"
                            onChange={this.onChangeField}
                            value={form.correoContacto}
                            type="text"
                            required
                          >  </Input>
                          {this.state.emailError ? <FormText color="red">Por favor introduzca una dirección de correo electrónico correcto</FormText> : ''}
                        </FormGroup>
                      </Col>

                      <Col md="3">
                        <FormGroup>
                          <Label className="form-control-label" htmlFor="cliente">
                            Tipo Cliente
                    </Label>
                          <Input
                            id="TipoCliente"
                            placeholder=""
                            name="TipoCliente"
                            onChange={this.onChangeField}
                            hidden={tipoCliente.length > 0 ? false : true}
                            type="select" multiselect>
                            {tipoCliente.map((item: any, index) => <option value={item.cat} key={index}>{item.cat}</option>)}

                          </Input>
                        </FormGroup>
                      </Col>
                      <Col md="3">
                        <Label className="form-control-label" htmlFor="TelefonoFijo">
                          Teléfono Fijo
                  </Label>
                        <Input
                          id="TelefonoFijo"
                          placeholder=""
                          name="TelefonoFijo"
                          onChange={this.onChangeField}
                          value={form.TelefonoFijo}
                          type="text"
                          required
                        />
                        {this.state.TelefonoFijoError ? <FormText color="red">Por favor compruebe el teléfono del titular</FormText> : ''}
                      </Col>
                      <Col md="3">
                        <Label className="form-control-label" htmlFor="TelefonMovil">
                          Teléfono Móvil
                  </Label>
                        <Input
                          id="TelefonMovil"
                          placeholder=""
                          name="TelefonMovil"
                          onChange={this.onChangeField}
                          value={form.TelefonMovil}
                          type="text"
                          required
                        />
                      </Col>
                      <Col>
                        <Label className="form-control-label" htmlFor="ObservacionesCliente">
                          Observaciones Cliente
                  </Label>
                        <Input
                          id="ObservacionesCliente"
                          placeholder=""
                          name="ObservacionesCliente"
                          onChange={this.onChangeField}
                          value={form.ObservacionesCliente}
                          type="text"
                          required
                        />

                      </Col>

                    </Row>

                    <Row>
                      <Col md="5">
                        <Label className="form-control-label" htmlFor="iban">
                          IBAN
                    </Label>
                        <Input
                          id="iban"
                          placeholder=""
                          name="iban"
                          onChange={this.onChangeField}
                          value={form.iban}
                          type="text"
                          required
                        />
                        {this.state.ibanError ? <FormText color="red">Comprobar IBAN</FormText> : ''}

                      </Col>

                    </Row>
                </Col>
              </Row>

          </Form>
            </div>
            <div className="modal-footer">
              <Button
                color="secondary"
                data-dismiss="modal"
                type="button"
                onClick={this.props.toggle}
              >
                Cerrar
              </Button>
              <Button
                onClick={this.generateComparative}
                color="primary"
                type="button"
                disabled={this.state.isDisabled}
              >
                Guardar Comparativa
              </Button>
            </div>
          </Modal>
        </>
      );
    }
  }
