
export const priceFields = {
    fechaPrecio: "Fecha",
    precioP1: "Precio P1",
    precioP2: "Precio P2"
};

export const consumptionsFields = {

    fechaFinMesConsumo: "Fecha",
    consumoEnWhP1: "Consumo kWhP1",
    consumoEnWhP2: "Consumo kWhP2"
};


export const consumptionsExpandableFields = {};

export const sipsConsumosExpandableFields = {};

export const sipsConsumosFields = {
  fechaInicioMesConsumo: "Fecha Inicio",
  fechaFinMesConsumo: "Fecha Final",
  codigoTarifaPeaje: "Codigo PEAJE",
  consumoEnWhP1: "Consumo kWhP1",
  consumoEnWhP2: "Consumo kWhP2",
  caudalMedioEnWhdia: "Caudal Medio Día Wh",
  caudaMinimoDiario: "Caudal Mínimo Diario",
  caudaMaximoDiario: "Caudal Máximo Diario",
  porcentajeConsumoNocturno: "Porcentaje Consumo Nocturno",
  codigoTipoLectura: "Codigo tipo lectura"
  };
