import gql from "graphql-tag";

export const EXPORT_SIPSGAS = gql`
  query sipsGasExportCSV($page: Int, $limit: Int, $filters: SipsGasFilterInput) {
    sipsGasExportCSV(page: $page, limit: $limit, filters: $filters)
  }
`;

export const EXPORT_SHORT_SIPSGAS = gql`
  query sipsGasExportShortCSV($page: Int, $limit: Int, $filters: SipsGasFilterInput) {
    sipsGasExportShortCSV(page: $page, limit: $limit, filters: $filters)
  }
`;

export const MASSIVE_EXPORT_SIPSGAS = gql`
  query sipsGasMassiveExportCSV(
    $cups: [String!]!
    $exportCups: Boolean
    $exportMeasures: Boolean
    $startDate: DateTime
    $endDate: DateTime
  ) {
    sipsGasMassiveExportCSV(
      cups: $cups
      exportCups: $exportCups
      exportMeasures: $exportMeasures
      startDate: $startDate
      endDate: $endDate
    )
  }
`;

export const LIST_SIPSGAS = gql`
  query listSipsGas($page: Int, $limit: Int, $filters: SipsGasFilterInput) {
    listSipsGas(page: $page, limit: $limit, filters: $filters) {
      currentPage
      pageSize
      pageCount
      count
      items {
        cups
codigoEmpresaDistribuidora
nombreEmpresaDistribuidora
codigoProvinciaPS
desProvinciaPS
codigoPostalPS
municipioPS
desMunicipioPS
tipoViaPS
viaPS
numFincaPS
portalPS
escaleraPS
pisoPS
puertaPS
codigoPresion
descCodigoPresion
codigoPeajeEnVigor
desCodigoPeajeEnVigor
caudalMaximoDiarioEnWh
caudalHorarioEnWh
derechoTUR
fechaUltimaInspeccion
codigoResultadoInspeccion
descCodigoResultadoInspeccion
tipoPerfilConsumo
codigoContador
calibreContador
tipoContador
propiedadEquipoMedida
descPropiedadEquipoMedida
codigoTelemedida
fechaUltimoMovimientoContrato
fechaUltimoCambioComercializador
informacionImpagos
idTipoTitular
descIdTipoTitular
idTitular
nombreTitular
apellido1Titular
apellido2Titularr
codigoProvinciaTitular
desProvinciaTitular
codigoPostalTitular
municipioTitular
desMunicipioTitular
tipoViaTitular
viaTitular
numFincaTitular,
portalTitular
escaleraTitular
pisoTitular
puertaTitular
esViviendaHabitual
CNAE
actividadCNAE
tipoCorrector
descTipoCorrector
codigoAccesibilidadContador
descCodigoAccesibilidadContador
conectadoPlantaSatelite
descConectadoPlantaSatelite
pctd
presionMedida
ConsumoAnualEnWhP1
ConsumoAnualEnWhP2
ConsumoAnualTotal
ConsumoAnualAnteriorEnWhP1
ConsumoAnualAnteriorEnWhP2
ConsumoAnualTotalAnterior
CIF
codigoAutonomiaPS
desAutonomiaPS
      }
    }
  }
`;

export const VIEW_SIPSGAS = gql`
  query viewSipsGas($cups: String!) {
    viewSipsGas(cups: $cups) {
      cups
  codigoEmpresaDistribuidora
  nombreEmpresaDistribuidora
  codigoProvinciaPS
  desProvinciaPS
  codigoPostalPS
  municipioPS
  desMunicipioPS
  tipoViaPS
  viaPS
  numFincaPS
  portalPS
  escaleraPS
  pisoPS
  puertaPS
  codigoPresion
  descCodigoPresion
  codigoPeajeEnVigor
  desCodigoPeajeEnVigor
  caudalMaximoDiarioEnWh
  caudalHorarioEnWh
  derechoTUR
  fechaUltimaInspeccion
  codigoResultadoInspeccion
  descCodigoResultadoInspeccion
  tipoPerfilConsumo
  codigoContador
  calibreContador
  tipoContador
  propiedadEquipoMedida
  descPropiedadEquipoMedida
  codigoTelemedida
  fechaUltimoMovimientoContrato
  fechaUltimoCambioComercializador
  informacionImpagos
  idTipoTitular
  descIdTipoTitular
  idTitular
  nombreTitular
  apellido1Titular
  apellido2Titularr
  codigoProvinciaTitular
  desProvinciaTitular
  codigoPostalTitular
  municipioTitular
  desMunicipioTitular
  tipoViaTitular
  viaTitular
  numFincaTitular,
  portalTitular
  escaleraTitular
  pisoTitular
  puertaTitular
  esViviendaHabitual
  CNAE
  actividadCNAE
  tipoCorrector
  descTipoCorrector
  codigoAccesibilidadContador
  descCodigoAccesibilidadContador
  conectadoPlantaSatelite
  descConectadoPlantaSatelite
  pctd
  presionMedida
  ConsumoAnualEnWhP1
  ConsumoAnualEnWhP2
  ConsumoAnualTotal
  ConsumoAnualAnteriorEnWhP1
  ConsumoAnualAnteriorEnWhP2
  ConsumoAnualTotalAnterior
  CIF
  codigoAutonomiaPS
  desAutonomiaPS
    }
  }
`;

export const LIST_BUSINESSGAS = gql`
  query listBusinessGas($cif: String!) {
    listBusiness(cif: $cif) {
        Nombre
        CIF
        FormaJuridica
        Telefono
        Web
        CNAE
        LiteralCNAE
        Marca
        Localidad
        Provincia
        IngExplota
        Calle
        CodigoPostal
        Proveedor
        Ejercicio
        Consolidada

    }
  }
`;
