
/*eslint-disable*/
import React from "react";
import LoadingOverlay from "react-loading-overlay";

// reactstrap components
import { Card, CardBody, CardTitle, Col, ListGroup, ListGroupItem, Row } from "reactstrap";


interface RateInformationInterface {
  isLoading: boolean,
  cardClass?: string,
  sip: {};
}
class RateInformation extends React.Component<RateInformationInterface> {
  constructor(props) {
    super(props);
  }

  renderCircle(top, bottom) {
    return <Row className="border-pot d-flex ml-0 text-center mt-2" key={top}>
      <Col className="col-auto pt-2 pb-2 radius-pot bg-energia">
        {top}
      </Col>
      <Col className="col pl-4 pr-4 pt-2 pb-2 ml-1 bg-units">
        {bottom} kWh
      </Col>
    </Row>;
  }

  renderCircleP(top, bottom) {
    return <Row className="border-pot d-flex ml-0 text-center mt-2" key={top}>
      <Col className="col-auto pt-2 pb-2 radius-pot bg-potencia">
        {top}
      </Col>
      <Col className="col pl-4 pr-4 pt-2 pb-2 ml-1 bg-units">
        {bottom} kW
      </Col>
    </Row>;
  }

  renderCircles() {
    const sip: any = this.props.sip;
    const numeroPeriodos = parseInt(sip.NumeroPeriodos);
    const consumptions: any = [];
    for (var i = 1; i <= numeroPeriodos; i++) {
      let consumoEnergiaActiva = `ConsumoAnualActivaP${i}`;
      let consumo = parseFloat(sip[consumoEnergiaActiva]);

      switch(sip.codigoTarifaATREnVigor) {
        case '003':
        case '007':
        case '008':
        case '011':
          consumoEnergiaActiva = `ConsumoAnualActivaP${i+3}`;
          consumo += parseFloat(sip[consumoEnergiaActiva]);
          break;
        case '004':
        case '006':
          if (i === 2) {
            //i++;
            consumoEnergiaActiva = `ConsumoAnualActivaP${i}`;
            consumo = parseFloat(sip[consumoEnergiaActiva]);
          }
          break;
      }
      const element = this.renderCircle(`P${i}`, consumo);
      consumptions.push(element);
    }

    return consumptions;
  }

  renderCirclesPot() {
    const sip: any = this.props.sip;
    const numeroPeriodos = parseInt(sip.NumeroPeriodos);
    const consumptionsPot: any = [];
    for (var i = 1; i <= numeroPeriodos; i++) {
      let consumoPotenciaActiva = `potenciasContratadasEnWP${i}`;
      let consumop = parseFloat(sip[consumoPotenciaActiva]);

      switch(sip.codigoTarifaATREnVigor) {
        case '001':
              break;
        case '005':
              break;
        case '003':
        consumoPotenciaActiva = `potenciasContratadasEnWP${i+3}`;
        consumop += parseFloat(sip[consumoPotenciaActiva]);
        break;
        case '007':
              break;
        case '008':
break;
        case '011':
          consumoPotenciaActiva = `potenciasContratadasEnWP${i+3}`;
          consumop += parseFloat(sip[consumoPotenciaActiva]);
          break;
        case '004':
break;
        case '006':
break;
      }
      const element2 = this.renderCircleP(`Pot. P${i} `, consumop);
      //const element2 = this.renderCirclesPot();
      consumptionsPot.push(element2);
    }

    return consumptionsPot;
  }

  render() {
    const sip: any = this.props.sip;

    const consumptions: any = this.renderCircles();
    const consumptionsPot: any = this.renderCirclesPot();

    return (
      // <Card id="rateInformation" className={this.props.cardClass}>
      //   <CardBody>
      //     <CardTitle><h2>Información de la tarifa</h2></CardTitle>
          <LoadingOverlay
            active={this.props.isLoading}
            spinner
            text="Cargando...">
            <Row>
              <Col>
                <div id="consumptions">
                  {consumptions}
              </div>
              </Col>

              <Col>
                <div id="consumptionsPot">
                  {consumptionsPot}
                </div>
              </Col>
            </Row>


          </LoadingOverlay>
        // </CardBody>
      //</Card>
    );
  }
}

export default RateInformation;
