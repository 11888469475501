import React from "react";
import { withApollo } from "react-apollo";
import { Container } from "reactstrap";
import LoadingOverlay from "react-loading-overlay";

import Header from "../../components/Headers/Header";
import { MASSIVE_EXPORT_SIPS } from "../../queries/sips/sips.graphql";
import Environment from "../../constants/environment";
import SipsMassiveExportsFilter from "../../components/Sips/SipsMassiveExportsFilter";

class SipsMassiveExports extends React.Component<any, any> {
  lastFilters = {
    cups: "",
  };
  state = {
    isLoading: false,
    isSearching: false,
    copiedText: null,
    currentPage: 0,
    pageSize: 0,
    pageCount: 0,
    count: 0,
    page: 1,
    limit: 10,
    paginatedData: {
      items: [],
    },
  };

  onSubmit = (filters) => {
    this.lastFilters = filters;

    const cups = filters.cups.split(",");
    let f = filters;
    f.cups = cups;
    this.export(f);
  };

  export = async (filters) => {
    let isLoading = true;
    this.setState({ isLoading });
    const sips = await this.props.client.query({
      query: MASSIVE_EXPORT_SIPS,
      variables: filters,
    });
    const file = sips.data.sipsMassiveExportCSV;
    let tempLink = document.createElement("a");
    tempLink.href = Environment.apiUrl.replace("/graphql", "") + file;

    tempLink.click();
    isLoading = false;
    this.setState({ isLoading });
  };

  render() {
    return (
      <>
        <Header title="Exportación masiva de suministros" />
        <Container className=" mt--7" fluid>
          <LoadingOverlay
            active={this.state.isLoading}
            spinner
            text="Generando archivo..."
          >
            <SipsMassiveExportsFilter onSubmit={this.onSubmit} />
          </LoadingOverlay>
        </Container>
      </>
    );
  }
}

export default withApollo(SipsMassiveExports);
