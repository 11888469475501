import React from "react";
// reactstrap components
import {
  Card,
  CardHeader,
  CardFooter,
  Container,
  Row,
  Col,
  Button,
} from "reactstrap";

import { Link } from 'react-router-dom';
import LoadingOverlay from "react-loading-overlay";

// core components
import Header from "../../components/Headers/Header";
import SipsGasFilter from "../../components/SipsGas/SipsGasFilter";
//import SipsTable from "../../components/Sips/SipsTable";
import { withApollo } from "react-apollo";

import Pagination from "../../components/Pagination/Pagination";
import { LIST_SIPSGAS, EXPORT_SIPSGAS } from "../../queries/sips/sipsGas.graphql";
import Environment from "../../constants/environment";
import DataTable from 'react-data-table-component';
//import Checkbox from '@material-ui/core/Checkbox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import memoize from 'memoize-one';
//import CircularProgress from '@material-ui/core/CircularProgress';
//import Icon from '@material-ui/icons/Apps';
import moment from "moment";
import styled from 'styled-components';
import Reflux from "reflux";
import { UserStore } from "../../store/UserStore";


const userFromStorage = localStorage.getItem("user");

const parsed = JSON.parse(userFromStorage||'{}');

const showIndexButtonCol = parsed.indexada;
const showFreeMarketButtonCol2 = parsed.mercadolibregas;
console.log('VALOR DE showFreeMarketButtonCol', showFreeMarketButtonCol2)

const sortIcon = <ArrowDownward />;




/*const SampleStyle = styled.div`
	padding: 16px;
	display: block;
	width: 100%;

	p {
		font-size: 16px;
		font-weight: 700;
		word-break: break-all;
	}
`;
*/
const customStyles = {
	headRow: {
		style: {
			border: 'none',

		},
	},
	headCells: {
		style: {
			color: '#202124',
			fontSize: '12px',
      fontWeight: 'bold',
      wordBreak: 'break-word',
      paddingLeft: '8px',
      paddingRight: '8px',
      minWidth: '55px'

		},
	},
  cells: {
  style: {
    paddingLeft: '8px',
    paddingRight: '8px',
    minWidth: '55px',
    wordBreak: 'break-word',
    fontFamily: '"Open Sans", sans-serif;'
  },
},
	/*rows: {
		highlightOnHoverStyle: {
			backgroundColor: 'rgb(230, 250, 250)',
			borderBottomColor: '#FFFFFF',
			borderRadius: '25px',
			outline: '1px solid #FFFFFF',
      fontSize: '12px',
		},
	},*/
	pagination: {
		style: {
			border: 'none',
		},
	},
};

class SipsGas extends Reflux.Component<any, any> {
  lastFilters = {};
  state = {
    UserStore:{},
    isLoading: false,
    isSearching: false,
    copiedText: null,
    currentPage: 0,
    pageSize: 0,
    pageCount: 0,
    count: 0,
    page: 1,
    limit: 10,
    gas:false,

    paginatedData: {
      items: [],
    },
    showIndexButton: true,
    showFreeMarketButton: true
  };

  constructor(props) {
    super(props);
    this.store = UserStore;
  }

  componentWillReceiveProps (){

  const usuario:any = JSON.parse(JSON.stringify(this.state.UserStore['data']))

  console.log('VALOR DE USUARIO ----------------->', usuario)


  //const showIndexButtonCol = usuario.indexada;
  const showFreeMarketButton = usuario.mercadolibregas;
  const companyNameGlobal = usuario.company;
  const companyIdUser = usuario.companyId;

  const userGroupId = usuario.user_group_id
  const userId = usuario.id
  const gas = usuario.gas



  //  console.log('VALORES DE indexbutton  ',showIndexButtonCol )
  //const showFreeMarketButtonCol = this.state.UserStore.data.mercadolibre;

  //const companyNameGlobal = this.state.UserStore.data.company;
  //const companyIdUser = this.state.UserStore.data.companyId;

  //const userGroupId = this.state.UserStore.data.user_group_id
  //const userId = this.state.UserStore.data.id

  //  console.log('VALORES DE ',usuario)

    if (userGroupId === 1000){
      this.setState({
        activeUser: true
      })};

      this.setState(
              ({ showFreeMarketButton }) => ({
                showFreeMarketButton:showFreeMarketButton
              }), () => {
                this.setState(({ showFreeMarketButton }) => ({
                  showFreeMarketButton:showFreeMarketButton
                }))
              } )

              console.log('VALOR EN EL DOBLE THIS.State ----->', this.state)

    this.setState({
      companyNameGlobal,
      companyIdUser,
      userGroupId,
      userId,
      gas
    });
  }


/*  componentDidMount() {
    let showIndexButton = true;
    let showFreeMarketButton = true;
    const userFromStorage = localStorage.getItem("user");
    if (userFromStorage) {
      try {
        const parsed = JSON.parse(userFromStorage);

        showIndexButton = parsed.indexada;
        showFreeMarketButton = parsed.mercadolibregas;
      } catch (e) {}
    }
    this.setState({ showIndexButton, showFreeMarketButton });


  }
*/
  onSubmit = (filters) => {
    this.lastFilters = filters;
    this.setState({ page: 1 }, () => {
      this.fetchItems(this.lastFilters);
    });
  };

  export = async () => {
    let isLoading = true;
    this.setState({ isLoading });
    const filters = this.lastFilters;
    let { page, limit } = this.state;
    console.log('VALORES DE filters Y THIS.STATE');
    console.log(filters)
    console.log('-------------------------')
    console.log(this.state);
    if (filters["limit"]) {
      limit = parseInt(filters["limit"]);
      //delete filters["limit"];
    }
    //limit = this.state.pageSize;

    const sips = await this.props.client.query({
      query: EXPORT_SIPSGAS,
      variables: { page, limit, filters },
    });
    const file = sips.data.sipsGasExportCSV;
    let tempLink = document.createElement("a");
    tempLink.href = Environment.apiUrl.replace("/graphql", "") + file;

    tempLink.click();
    isLoading = false;
    this.setState({ isLoading });
  };

  fetchItems = async (filters) => {
    let isLoading = true;
    const isSearching = true;
    this.setState({ isLoading, isSearching });
    this.lastFilters = filters;

    if (this.lastFilters.hasOwnProperty("limit")) {
      this.state.limit = parseInt(filters.limit, 10);
    }
    if (
      (!this.state.limit || this.state.limit === 0) &&
      (this.state.pageSize !== null || this.state.pageSize > 1)
    ) {
      this.state.limit = this.state.pageSize;
    }

    let { page, limit } = this.state;

    if (filters["limit"]) {
      limit = parseInt(filters["limit"]);
      delete filters["limit"];
    }

    if (limit === 0 && this.state.pageSize > 1) {
      limit = this.state.pageSize;
    }

    this.props.client.query({
      query: LIST_SIPSGAS,
      variables: { page, limit, filters },
    }).then( res => {

      const {
        currentPage,
        pageSize,
        pageCount,
        count,
        items,
      } = res.data.listSipsGas;

      const paginatedData = { items };
      isLoading = false;

      this.setState({
        paginatedData,
        currentPage,
        pageSize,
        pageCount,
        count,
        isLoading,
      });
    })
    .catch( err => {
      isLoading = false;
      this.setState({isLoading});
    })

  };

  onPageClick = (page) => {
    this.setState({ page }, () => {
      this.fetchItems(this.lastFilters);
    });
  };

  handleChange = state => {
  // eslint-disable-next-line no-console
  console.log('state', state.selectedRows);
  // eslint-disable-next-line react/no-unused-state
  this.setState({ selectedRows: state.selectedRows });
};

handleButtonClick = () => {
  // eslint-disable-next-line no-console
  console.log('clicked');
};

  beforeRenderItem = (index, item) => {
    if (index.includes("fecha") && item.value) {
      const dateValue = new Date(item.value);
      item.value = dateValue.toISOString().split("T")[0];
    }
    return true;
  };




  render() {
    //const data = this.state.paginatedData.items;

    const usuario:any = JSON.parse(JSON.stringify(this.state.UserStore['data']))

    console.log('VALOR DE USUARIO ----------------->', usuario)


      const showFreeMarketButtonCol = usuario.mercadolibregas;

    const columns = memoize(clickHandler => [
    {
    // eslint-disable-next-line react/button-has-type
    //cell: () => <button className="btn btn-primary btn-sm btn-circle" onClick={clickHandler}></button>,
    //cell: row => <div><div style={{ fontWeight: 700 }}>{row.title}</div>{row.summary}</div>,

    cell: row => <Link
      className="btn btn-primary btn-sm btn-circle"
      data-toggle="modal"
      to={{ pathname: "sipGas-details/" + row.cups }}
      id = "buscartooltip"
    >
      <i className="pe-7s-search text-white"></i>
    </Link>,

    ignoreRowClick: true,
    allowOverflow: true,
    button: true,
    name: 'INFO',
    minWidth: '55px'

    },
    {
    cell: row => <Link
      className="btn btn-info btn-sm btn-circle"
      data-toggle="modal"
      to={{ pathname: "sipGas-comparative-free/" + row.cups }}
      id = "fijotooltip"
    >
      <i className="pe-7s-calculator text-white"></i>
    </Link>,

    ignoreRowClick: true,
    allowOverflow: true,
    button: true,
    omit: showFreeMarketButtonCol,
    name: 'FIJO',
    minWidth: '55px'

    },

      {selector: row => row['cups'] , name: 'CUPS',  width: '190px',		style: {
          color: '#202124',
          fontSize: '12px',
          fontWeight: 500,

        },},

      {selector: row => row['nombreTitular'] , name: 'TITULAR', sortable: true, style: {
          color: 'rgba(0,0,0,.54)',
        },},
      {selector: row => row['CIF'] , name: 'CIF', sortable: true,},
      {selector: row => row['nombreEmpresaDistribuidora']  , name: 'DISTRIBUIDORA', sortable: true, center: true, grow:1, width: '180px'},
    {selector: row => row['desCodigoPeajeEnVigor'] , name: 'ATR', sortable: true, center: true, wrap:true, grow:1, width: '200px'},
    {selector: row => row['ConsumoAnualTotal']  , name: 'T. CONSUMO AÑO ACTUAL', sortable: true, width: '160px', center: true, },
    {selector: row => row['ConsumoAnualEnWhP1']  , name: 'CONSUMO ANUAL KW P1', width: '160px', center: true, },
    {selector: row => row['ConsumoAnualEnWhP2'] , name: 'CONSUMO ANUAL KW P2',   width: '160px', center: true, },
    {selector: row => row['ConsumoAnualTotalAnterior']  , name: 'T. CONSUMO AÑO ANTERIOR', sortable: true, width: '160px', center: true, },
    {selector: row => row['ConsumoAnualAnteriorEnWhP1']  , name: 'CONSUMO AÑO ANTERIOR KW P1',  width: '160px', center: true, },
    {selector: row => row['ConsumoAnualAnteriorEnWhP2'] , name: 'CONSUMO AÑO ANTERIOR KW P2', width: '160px', center: true, },
    {selector: row => row['fechaUltimoMovimientoContrato']  , name: 'ULT. MOVIMIENTO' , sortable: true, width: '120px', right: true, format: (row => moment(row.fechaUltimoMovimientoContrato).isValid() ? moment(row.fechaUltimoMovimientoContrato).format('DD-MM-YYYY') : "")},
    {selector: row => row['fechaUltimoCambioComercializador']  , name: 'ULT. CAMBIO COMERCIAL', sortable: true, width: '110px', right:true,  format: (row => moment(row.fechaUltimoCambioComercializador).isValid() ? moment(row.fechaUltimoCambioComercializador).format('DD-MM-YYYY') : "")},
    {selector: row => row['fechaUltimaInspeccion'] , name: 'FECHA ULT. INSPECCION' , sortable: true, width: '120px', right: true, format: (row => moment(row.fechaAltaSuministro).isValid() ? moment(row.fechaAltaSuministro).format('DD-MM-YYYY') : "")},
    {selector: row => row['descCodigoResultadoInspeccion'] , name: 'RESULTADO INSPECCION', sortable: true, center: true, wrap: true},
    {selector: row => row['informacionImpagos']  , name: 'IMPAGOS', sortable: true, center: true, wrap: true},
    {selector: row => row['desProvinciaPS']  , name: 'DISTRIBUIDORA', sortable: true, },
    {selector: row => row['desMunicipioPS']  , name: 'MUNICIPIO', sortable: true },
    {selector: row => row['codigoPostalPS']  , name: 'COD. POSTAL', sortable: true, width: '120px', center: true, },
    {selector: row => row['apellido1Titular'] , name: 'APELLIDO1 TITULAR',  width: '180px', center: true, },
    {selector: row => row['apellido2Titularr'] , name: 'APELLIDO2 TITULAR',  width: '180px', center: true, },
    {selector: row => row['nombreTitular'] , name: 'NONMBRE TITULAR',  width: '180px', center: true, },
    ]);


    let noResults = <></>;
    if (this.state.paginatedData.items.length === 0 && !this.state.isLoading) {
      if (this.state.isSearching) {
        noResults = (
          <p className="alert alert-danger">No se encontraron resultados</p>
        );
      } else {
        noResults = (
          <p className="alert alert-info">
            Realice su busqueda utilizando el formulario de arriba
          </p>
        );
      }
    }
console.log('VALOR DE RENDER THIS.STATE', this.state)
if(this.state.gas) {
    return (
      <>
        <Header title="Listado" />
        <Container className=" mt--7" fluid>
          <SipsGasFilter onSubmit={this.onSubmit}></SipsGasFilter>
          <Row>
            <div className="col">
              <Card className="shadow">
                <LoadingOverlay
                  active={this.state.isLoading}
                  spinner
                  text="Cargando sips..."
                >
                  <CardHeader className="border-0">
                    <Row>
                      <Col md="8">
                        <h3 className="mb-0">SIPS</h3>
                      </Col>
                      <Col md="4" className="text-right">
                        <Button
                          onClick={this.export}
                          size="sm"
                          disabled={this.state.paginatedData.items.length === 0}
                        >
                          Exportar a CSV
                        </Button>
                      </Col>
                    </Row>
                  </CardHeader>
                  <DataTable
                       title="Puntos de Suministros Gas"
                       columns={columns(this.handleButtonClick)}
                       data = {this.state.paginatedData.items}
                       sortIcon={sortIcon} // use a material icon for our sort icon. rdt will rotate the icon 180 degrees for you
                       fixedHeader = {true}
                       onSelectedRowsChange={this.handleChange}
                       keyField = "cups"
                       highlightOnHover
         				      striped
         				      pointerOnHover
                       selectableRowsHighlight
                       customStyles={customStyles}
                       contextMessage = {{ singular: 'cup', plural: 'cups', message: 'seleccionados' }}
                     />
                  <CardFooter className="py-4">
                    {noResults}

                    <nav aria-label="...">
                      <Pagination
                        {...this.state}
                        onPageClick={this.onPageClick}
                      ></Pagination>
                    </nav>
                  </CardFooter>
                </LoadingOverlay>
              </Card>
            </div>
          </Row>
        </Container>
      </>
    );
  } else {
    return (
      <>
        <Header title={"No tienes permisos para acceder a este apartado. Consulte con nuestro equipo comercial"}/>
      </>
    );
  }
  }
}

export default withApollo(SipsGas);
