import gql from 'graphql-tag';

export const LIST_PRICEATR = gql`
  query listPricesByCompany($companyId: Int, $codigoTarifaATREnVigor: String!){
    listPricesByCompany(companyId: $companyId, codigoTarifaATREnVigor: $codigoTarifaATREnVigor){
      codigoTarifaATREnVigor,
      tarifaATR,
      fechaPrecio,
      precioP1,
      precioP2,
      precioP3,
      precioP4,
      precioP5,
      precioP6,
      NumeroPeriodos
    }
  }
`;
