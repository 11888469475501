import React from "react";
import {
  Button,
  FormGroup,
  Modal,
  Label,
  Col,
  Input,
  Row,
  Form,
  FormText,
  } from "reactstrap";

  import client from "../../api/client";
  //import Multiselect from 'multiselect-react-dropdown';
  import SipsOption from "../../components/Sips/SipsOption";
  import { validateSpanishId } from 'spain-id'
  import { LIST_DICMUNICIPIOS } from "../../queries/options/dic-option.graphql";
const ibantools = require('ibantools');


interface IModalProps {
  isOpen: boolean;
  toggle: any;
  onSave?: Function;
  sip: any;
  ubi: any;
  nombrePropuesta: string;
}

interface IModalState {
  form: {
    nombreOferta: string;
    correoContacto: string;
    idComercializadora: string;
    nombreComercializadora: string;
    esAprobada: boolean;
    razonSocial: any;
    NIF: any;
    DireccionFiscal: any; // "C/ FELIPE SOLANO ANTELO 8A 8º3", ---> direccion_titular
    nombreTitular: any;
    apellido1Titular: any;
    apellido2Titular: any;
    desAutonomiaTitular: any;
    desProvinciaTitular: any;
    desMunicipioTitular: any;
    tipoViaTitular: any;
    viaTitular: any;
    numFincaTitular: any;
    portalTitular: any;
    escaleraTitular: any;
    pisoTitular: any;
    puertaTitular: any;
    iban: any;
    CodigoPostalTitular: any; //"08630",----> codigo_postal_titular
    TelefonoFijo: any; //"949201893", ----> telefono_fijo
    TelefonMovil: any; //"689105332", ----> telefono
    Email: any; //"demo@demo.com", --->email_contacto
    TipoCliente: any; //"COMUNIDAD DE PROPIETARIOS", ---> tipo_cliente
    ObservacionesCliente: any; //"OPCIONAL O NULL",----> observaciones
    //DireccionPS: any; // "C/ FELIPE SOLANO ANTELO 8A 8º3", ---> direccion_ps
    //CodigoPostalPS: any; //"08630", ----> codigo_postal_ps
    //TelefonoPS: any; //"689105332", ----> telefono;
    //ObservacionesPuntoSuministro: any;
    GDPR: boolean; // "opcional o null" ----> observaciones_ps
  },
  cups: string;
  user: any;
  isDisabled:boolean;
  nombreOfertaError: boolean;
  emailError: boolean;
  nombreTitularError: boolean;
  NIFError: boolean;
  DireccionFiscalError: boolean;
  CodigoPostalTitularError: boolean;
  TelefonoFijoError: boolean;
  TelefonMovilError: boolean;
  idComercializadoraError: boolean;
  TipoCliente: any;
  apellido1TitularError:boolean;
  ibanError:boolean;
  dicMunicipios: any[];
}

export default class SaveGasModal extends React.Component<
  IModalProps,
  IModalState
> {
  public formComparativeRef: any = null;
  public formSaveComparativeRef: any = null;
  public state: IModalState = {
    // --- Data
    form: {
      nombreOferta: "",
      correoContacto: "",
      idComercializadora: "",
      nombreComercializadora: "",
      esAprobada: false,
      razonSocial: null,
      NIF: "", //"C19345456", --> CIF
      DireccionFiscal: "", // "C/ FELIPE SOLANO ANTELO 8A 8º3", ---> direccion_titular
      nombreTitular: "",
      apellido1Titular: "",
      apellido2Titular: "",
      desAutonomiaTitular: "",
      desProvinciaTitular: "",
      desMunicipioTitular: "",
      tipoViaTitular: "",
      viaTitular: "",
      numFincaTitular: "",
      portalTitular: "",
      escaleraTitular: "",
      pisoTitular: "",
      puertaTitular: "",
      iban: "",
      CodigoPostalTitular: "", //"08630",----> codigo_postal_titular
      TelefonoFijo: "", //"949201893", ----> telefono_fijo
      TelefonMovil: "", //"689105332", ----> telefono
      Email: "", //"demo@demo.com", --->email_contacto
      TipoCliente: "NO DEFINIDO", //"COMUNIDAD DE PROPIETARIOS", ---> tipo_cliente
      ObservacionesCliente: "", //"OPCIONAL O NULL",----> observaciones
      //DireccionPS: "", // "C/ FELIPE SOLANO ANTELO 8A 8º3", ---> direccion_ps
      //CodigoPostalPS: "", //"08630", ----> codigo_postal_ps
      //TelefonoPS: "", //"689105332", ----> telefono;
      //ObservacionesPuntoSuministro: "",
      GDPR: true, // "opcional o null" ----> observaciones_ps
    },
    cups: "",
    user: {},
    isDisabled:true,
    nombreOfertaError: false,
    emailError: true,
    nombreTitularError: true,
    NIFError: true,
    DireccionFiscalError: true,
    CodigoPostalTitularError: true,
    TelefonoFijoError: true,
    TelefonMovilError: true,
    idComercializadoraError: true,
    apellido1TitularError: true,
    ibanError:false,
    dicMunicipios:[],
    TipoCliente:[{key:"0" , cat:"NO DEFINIDO"},
    {key:"1" , cat:"PARTICULAR"},
    {key:"2" , cat:"PYME INDUSTRIA"},
    {key:"3" , cat:"PYME SERVICIO"},
    {key:"4" , cat:"ADMINISTRACION PUBLICA"},
    {key:"5" , cat:"COMUNIDAD DE PROPIETARIO"}
  ]
  };

  componentWillReceiveProps(newProps) {

    console.log('VALORES DE NEWPROPS ----->' ,newProps)
      if (!newProps.ubi) {
        //const titulartemp =   newProps.ubi.tipoViaTitular+' '+ newProps.ubi.viaTitular+' '+newProps.ubi.numFincaTitular+' '+newProps.ubi.portalTitular+ ' '+newProps.ubi.pisoTitular+ ' '+newProps.ubi.puertaTitular;
        //const pstemp =   newProps.ubi.tipoViaPS+' '+ newProps.ubi.viaPS+' '+newProps.ubi.numFincaPS+' '+newProps.ubi.portalPS+ ' '+newProps.ubi.pisoPS+ ' '+newProps.ubi.puertaPS ;

      this.setState({form:
                     {nombreOferta: newProps.nombrePropuesta,
                      correoContacto: "",
                      idComercializadora: "",
                      nombreComercializadora: "",
                      esAprobada: false,
                      razonSocial: newProps.ubi.nombre_completo_titular,
                      NIF: newProps.ubi.CIF, //"C19345456", --> CIF
                      DireccionFiscal:newProps.ubi.direccion_titular , // "C/ FELIPE SOLANO ANTELO 8A 8º3", ---> direccion_titular
                      nombreTitular: newProps.ubi.nombreTitular,
                      apellido1Titular: newProps.ubi.apellido1Titular,
                      apellido2Titular: newProps.ubi.apellido2Titular,
                      desAutonomiaTitular: newProps.ubi.desAutonomiaTitular,
                      desProvinciaTitular: newProps.ubi.desProvinciaTitular,
                      desMunicipioTitular: newProps.ubi.desMunicipioTitular,
                      tipoViaTitular: newProps.ubi.tipoViaTitular,
                      viaTitular: newProps.ubi.viaTitular,
                      numFincaTitular: newProps.ubi.numFincaTitular,
                      portalTitular: newProps.ubi.portalTitular,
                      escaleraTitular: newProps.ubi.escaleraTitular,
                      pisoTitular: newProps.ubi.pisoTitular,
                      puertaTitular: newProps.ubi.puertaTitular,
                      iban: "",
                      CodigoPostalTitular: newProps.ubi.codigo_postal_titular, //"08630",----> codigo_postal_titular
                      TelefonoFijo: newProps.ubi.telefono_fijo, //"949201893", ----> telefono_fijo
                      TelefonMovil: newProps.ubi.telefono, //"689105332", ----> telefono
                      Email: newProps.ubi.email_contacto, //"demo@demo.com", --->email_contacto
                      TipoCliente: "NO DEFINIDO", //"COMUNIDAD DE PROPIETARIOS", ---> tipo_cliente
                      ObservacionesCliente: newProps.ubi.observaciones, //"OPCIONAL O NULL",----> observaciones
                          GDPR: true, // "opcional o null" ----> observaciones_ps
                      }});
                    }else{
                      //const titulartemp =   newProps.ubi.tipoViaTitular+' '+ newProps.ubi.viaTitular+' '+newProps.ubi.numFincaTitular+' '+newProps.ubi.portalTitular+ ' '+newProps.ubi.pisoTitular+ ' '+newProps.ubi.puertaTitular;
                      //const pstemp =   newProps.ubi.tipoViaPS+' '+ newProps.ubi.viaPS+' '+newProps.ubi.numFincaPS+' '+newProps.ubi.portalPS+ ' '+newProps.ubi.pisoPS+ ' '+newProps.ubi.puertaPS ;


                      this.setState({form:
                                     {  nombreOferta: newProps.nombrePropuesta,
                                      correoContacto: "",
                                      idComercializadora: "",
                                      nombreComercializadora: "",
                                      esAprobada: false,
                                      razonSocial: newProps.ubi.nombre_completo_titular,
                                      NIF: newProps.ubi.CIF, //"C19345456", --> CIF
                                      DireccionFiscal:newProps.ubi.direccion_titular , // "C/ FELIPE SOLANO ANTELO 8A 8º3", ---> direccion_titular
                                      nombreTitular: newProps.ubi.nombreTitular,
                                      apellido1Titular: newProps.ubi.apellido1Titular,
                                      apellido2Titular: newProps.ubi.apellido2Titular,
                                      desAutonomiaTitular: newProps.ubi.desAutonomiaTitular,
                                      desProvinciaTitular: newProps.ubi.desProvinciaTitular,
                                      desMunicipioTitular: newProps.ubi.desMunicipioTitular,
                                      tipoViaTitular: newProps.ubi.tipoViaTitular,
                                      viaTitular: newProps.ubi.viaTitular,
                                      numFincaTitular: newProps.ubi.numFincaTitular,
                                      portalTitular: newProps.ubi.portalTitular,
                                      escaleraTitular: newProps.ubi.escaleraTitular,
                                      pisoTitular: newProps.ubi.pisoTitular,
                                      puertaTitular: newProps.ubi.puertaTitular,
                                      iban: "",
                                      CodigoPostalTitular: newProps.ubi.codigo_postal_titular, //"08630",----> codigo_postal_titular
                                      TelefonoFijo: newProps.ubi.telefono_fijo, //"949201893", ----> telefono_fijo
                                      TelefonMovil: newProps.ubi.telefono, //"689105332", ----> telefono
                                      Email: newProps.ubi.email_contacto, //"demo@demo.com", --->email_contacto
                                      TipoCliente: "NO DEFINIDO", //"COMUNIDAD DE PROPIETARIOS", ---> tipo_cliente
                                      ObservacionesCliente: newProps.ubi.observaciones, //"OPCIONAL O NULL",----> observaciones


                                          GDPR: true, // "opcional o null" ----> observaciones_ps
                                      }});


                    }


                    if(this.props.ubi.nombre_completo_titular){

                      this.setState({
                        nombreTitularError:false,
                      })
                    } else {
                      this.setState({
                        nombreTitularError:true,
                      })

                    };

                    if(this.props.ubi.CIF){
                      this.setState({
                       NIFError:false,
                      })
                    } else {
                      this.setState({
                        NIFError:true,
                      })

                    };

                    // if(this.state.form.DireccionFiscal !=='' || this.state.form.DireccionFiscal !==null || this.state.form.DireccionFiscal !='0' || this.state.form.DireccionFiscal  !==' '){
                  if(this.props.ubi.direccion_titular){
                       this.setState({
                        DireccionFiscalError:false,
                       })
                     } else {
                       this.setState({
                        DireccionFiscalError:true,
                       })

                   };

                   if(this.props.ubi.codigo_postal_titular){
                        this.setState({
                         CodigoPostalTitularError:false,
                        })
                      } else {
                        this.setState({
                         CodigoPostalTitularError:true,
                        })

                    };





                  if(this.props.ubi.telefono_fijo){
                       const pattern = /^[679]{1}[0-9]{8}$/
                      var strPhone = this.state.form.TelefonoFijo.toString().replace(/\s/g, '');
                       const result = strPhone.length === 9 && pattern.test(this.state.form.TelefonoFijo);

                       if(result===true){
                         this.setState({
                           TelefonoFijoError:false,
                         })
                       } else {
                         this.setState({
                          TelefonoFijoError:true,
                         })

                     };
                   }


                   if(this.props.ubi.apellido1Titular){
                        this.setState({
                         apellido1TitularError:false,
                        })
                      } else {
                        this.setState({
                         apellido1TitularError:true,
                        })

                    };





                                       this.forceUpdate();

  }

  componentDidMount() {
        this.fetchItems();
    }

    fetchItems = async () => {

      await client.query({
             query: LIST_DICMUNICIPIOS,
             variables: {}
         })
         .then((result) => {
             let data = result.data.listDicMunicipios;
//             console.log(data)
//             const resultado = data.find( municipio => municipio.Nombre === 'Barromán' );

//    console.log('VALOR DE FIND -------------------->', resultado); // { nombre: 'cerezas', cantidad: 5 }

//    const filtered = data.filter(entry => Object.values(entry).some(val => typeof val === "string" && val.includes('Barromán')));

//    console.log(filtered);


             this.setState({ dicMunicipios: data })
         });
    }



UNSAFE_componentWillMount(){



       if(this.state.form.nombreOferta !== '' || this.state.form.nombreOferta !== null || this.state.form.nombreOferta !== '0' ){
         this.setState({
           nombreOfertaError:false,
         })
       }

       if(this.state.form.correoContacto !== '' || this.state.form.correoContacto !== null || this.state.form.correoContacto !=='0' ){
         const pattern = /[a-zA-Z0-9]+[\.]?([a-zA-Z0-9]+)?[\@][a-z]{3,9}[\.][a-z]{2,5}/g;
        const result = pattern.test(this.state.form.correoContacto);

          if( result===true){
              this.setState({
                emailError:false
              })
            }

     };


     //if(this.props.ubi.nombre_completo_titular !=='' || this.props.ubi.nombre_completo_titular !==null || this.props.ubi.nombre_completo_titular !=='0'){

     if(this.props.ubi.nombre_completo_titular){

       this.setState({
         nombreTitularError:false,
       })
     } else {
       this.setState({
         nombreTitularError:true,
       })

     };

     if(this.props.ubi.CIF){
       this.setState({
        NIFError:false,
       })
     } else {
       this.setState({
         NIFError:true,
       })

     };



    // if(this.state.form.DireccionFiscal !=='' || this.state.form.DireccionFiscal !==null || this.state.form.DireccionFiscal !='0' || this.state.form.DireccionFiscal  !==' '){

  if(this.props.ubi.direccion_titular){
       this.setState({
        DireccionFiscalError:false,
       })
     } else {
       this.setState({
        DireccionFiscalError:true,
       })

   };



   if(this.props.ubi.codigo_postal_titular){
        this.setState({
         CodigoPostalTitularError:false,
        })
      } else {
        this.setState({
         CodigoPostalTitularError:true,
        })

    };
;


  if(this.props.ubi.telefono_fijo){
       const pattern = /^[679]{1}[0-9]{8}$/
      var strPhone = this.state.form.TelefonoFijo.toString().replace(/\s/g, '');
       const result = strPhone.length === 9 && pattern.test(this.state.form.TelefonoFijo);

       if(result===true){
         this.setState({
           TelefonoFijoError:false,
         })
       } else {
         this.setState({
          TelefonoFijoError:true,
         })

     };
   }




}
/*
RazonSocial: "", //":"MANUEL GONZALEZ",--> nombre_completo_titular
NIF: "", //"C19345456", --> CIF
DireccionFiscal: "", // "C/ FELIPE SOLANO ANTELO 8A 8º3", ---> direccion_titular
CodigoPostalTitular: "", //"08630",----> codigo_postal_titular
TelefonoFijo: "", //"949201893", ----> telefono_fijo
TelefonMovil: "", //"689105332", ----> telefono
Email: "", //"demo@demo.com", --->email_contacto
TipoCliente: "", //"COMUNIDAD DE PROPIETARIOS", ---> tipo_cliente
ObservacionesCliente: "", //"OPCIONAL O NULL",----> observaciones
},
T_PuntoSuministro: {
Direccion: "", // "C/ FELIPE SOLANO ANTELO 8A 8º3", ---> direccion_ps
CodigoPostal: "", //"08630", ----> codigo_postal_ps
Telefono: "", //"689105332", ----> telefono;
ObservacionesPuntoSuministro: "", // "opcional o null" ----> observaciones_ps
*/
validateEmail = (email) => {

 const pattern = /[a-zA-Z0-9]+[\.]?([a-zA-Z0-9]+)?[\@][a-z]{3,9}[\.][a-z]{2,5}/g;
 //const pattern = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/
 const result = pattern.test(email);
 if(result===true){
   this.setState({
     emailError:false,
   })
 } else{
   this.setState({
     emailError:true
   })
 }
}

validatePhone = (TelefonoFijo) => {
 //const pattern = /[a-zA-Z0-9]+[\.]?([a-zA-Z0-9]+)?[\@][a-z]{3,9}[\.][a-z]{2,5}/g;
 //const pattern = ^\+{0,2}([\-\. ])?(\(?\d{0,3}\))?([\-\. ])?\(?\d{0,3}\)?([\-\. ])?\d{3}([\-\. ])?\d{4}

 const pattern = /^[679]{1}[0-9]{8}$/
var strPhone = TelefonoFijo.toString().replace(/\s/g, '');
 const result = strPhone.length === 9 && pattern.test(TelefonoFijo);
 if(result===true){
   this.setState({
     TelefonoFijoError:false,
   })
 } else{
   this.setState({
     TelefonoFijoError:true
   })
 }
}




  onChangeField = async (event) => {
    event.persist()
    let formData = { ...this.state.form };
    let value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    const name = event.target.name;


        if(event.target.name==='nombreOferta'){

          if(event.target.value){
            this.setState({
              nombreOfertaError:false
            })
          } else {
            this.setState({
              nombreOfertaError:true,
            })
          }
        }

       if(event.target.name==='correoContacto'){
        //this.validateEmail(event.target.value);

        const pattern = /[a-zA-Z0-9]+[\.]?([a-zA-Z0-9]+)?[\@][a-z]{3,9}[\.][a-z]{2,5}/g;
        //const pattern = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/
        const result = pattern.test(event.target.value);

      if( result===true){
          this.setState({
            emailError:false,
          })
        } else{
          this.setState({
            emailError:true
          })
       }
       }

       if(event.target.name==='iban'){
        //this.validateEmail(event.target.value);
       // console.log('entra en IBAN')
       const resultIban = ibantools.isValidIBAN(event.target.value);

        //console.log(resultIban)//const pattern = /[a-zA-Z0-9]+[\.]?([a-zA-Z0-9]+)?[\@][a-z]{3,9}[\.][a-z]{2,5}/g;
        //const pattern = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/
        //const result = pattern.test(event.target.value);

       if( resultIban===true){
          this.setState({
            ibanError:false,
          })
        } else{
          this.setState({
            ibanError:true
          })
       }
       }


              if(event.target.name==='apellido1Titular'){

               if(event.target.value){
                 this.setState({
                   apellido1TitularError:false
                 })
               } else {
                 this.setState({
                   apellido1TitularError:true,
                 })
               }
              }


              if(event.target.name==='nombreTitular'){

               if(event.target.value){
                 this.setState({
                   nombreTitularError:false
                 })
               } else {
                 this.setState({
                   nombreTitularError:true,
                 })
               }
             }

         if(event.target.name==='NIF'){
          // const result = isValid(event.target.value);
          const result =  validateSpanishId(event.target.value)

        //  if(event.target.value){
        if( result===true){
            this.setState({
              NIFError:false
            })
          } else {
            this.setState({
              NIFError:true,
            })
          }
        }

        if(event.target.name==='DireccionFiscal'){

         if(event.target.value){
           this.setState({
             DireccionFiscalError:false
           })
         } else {
           this.setState({
             DireccionFiscalError:true,
           })
         }
       }

       if(event.target.name==='CodigoPostalTitular'){

        if(event.target.value){
          this.setState({
            CodigoPostalTitularError:false
          })
        } else {
          this.setState({
            CodigoPostalTitularError:true,
          })
        }
       }

       if(event.target.name==='TelefonoFijo'){
       this.validatePhone(event.target.value);
       }



  /*  if (name === "idComercializadora" && value !== "0") {
      if (value !== "0") {
        formData.nombreComercializadora = [
          ...event.target.querySelectorAll("option"),
        ].filter((item) => item.value === value)[0].innerText;
      } else {
        value = null;
      }
    }*/

    if (name === "idComercializadora" && value !== "0") {

      if (value !== "0") {
        formData.nombreComercializadora = [
          ...event.target.querySelectorAll("option"),
        ].filter((item) => item.value === value)[0].innerText;
        this.setState({
          idComercializadoraError:false,
        })
      } else {
        this.setState({
          idComercializadoraError:true
        })
        value = null;
      }
    }


       if(this.state.nombreOfertaError===false && this.state.idComercializadoraError===false  &&  this.state.emailError===false  &&  this.state.nombreTitularError===false  &&  this.state.NIFError===false  &&  this.state.DireccionFiscalError===false &&  this.state.CodigoPostalTitularError===false ){
       this.setState({
         isDisabled:false
       })
    }


    var valueTemp:any=[];

    if (name === "TipoCliente") {

    const valorIndex = event.target.options.selectedIndex.toString();

    valueTemp =   this.state.TipoCliente.find(x => x.key === valorIndex)

    value = valueTemp.cat;


    }


    if (name == 'desMunicipioTitular'){

      const municipio = this.state.dicMunicipios
      const resultado = municipio.find( municipio => municipio.Nombre === value );

    //console.log('VALOR DE FIND -------------------->', resultado.DescProvincia, resultado.DescAutonomia);

    //const filtered = municipio.filter(entry => Object.values(entry).some(val => typeof val === "string" && val.includes('Barromán')));

    //console.log(filtered);
    formData['desProvinciaTitular']=resultado.DescProvincia
    formData['desAutonomiaTitular']= resultado.DescAutonomia

    }



    formData[name] = value;
    if (value === "" || value === null) {
      formData[name] = 0;
    }

    this.setState({ form: formData });
  };

  generateComparative = async (event) => {

    event.preventDefault();
    if (this.props.onSave) {
      if (this.state.form.nombreOferta) {
        this.props.onSave(this.state.form);
        this.props.toggle(event);
        var TelefonoPSError:boolean = true;
        if ( this.props.ubi.telefono){

        TelefonoPSError = false;
        } else {
        TelefonoPSError = true;

        }


        var CodigoPostalPSError:boolean = true;
        if ( this.props.ubi.codigo_postal_ps){

        CodigoPostalPSError = false;
        } else {
        CodigoPostalPSError = true;

        }

        var TelefonMovilError:boolean = true;
        if ( this.props.ubi.telefono){

        TelefonMovilError = false;
        } else {
        TelefonMovilError = true;

        }

        var TelefonoFijoError:boolean = true;
        if ( this.props.ubi.telefono_fijo){

        TelefonoFijoError = false;
        } else {
        TelefonoFijoError = true;

        }

        var apellido1TitularError:boolean = true;
        if ( this.props.ubi.apellido1Titular){

        apellido1TitularError = false;
        } else {
        apellido1TitularError = true;

        }

        var CodigoPostalTitularError:boolean = true;
        if (this.props.ubi.codigo_postal_titular){

        CodigoPostalTitularError = false;
        } else {
        CodigoPostalTitularError = true;

        }

        var DireccionFiscalError:boolean = true;
        if (this.props.ubi.direccion_titular){

        DireccionFiscalError = false;
        } else {
        DireccionFiscalError = true;

        }

        var NIFError:boolean = true;
        if (this.props.ubi.CIF){

        NIFError = false;
        } else {
        NIFError = true;

        }

        var nombreTitularError:boolean = true;
        if (this.props.ubi.nombre_completo_titular){

        nombreTitularError = false;
        } else {
        nombreTitularError = true;

        }

        var emailError:boolean = true;
        if (this.props.ubi.email_contacto){

        emailError = false;
        } else {
        emailError = true;

        }



              this.setState({
                form: {
                nombreOferta: "",
                  correoContacto: "",
                  idComercializadora: "",
                  nombreComercializadora: "",
                  esAprobada: false,
                  razonSocial: "",
                  NIF: "", //"C19345456", --> CIF
                  DireccionFiscal: "", // "C/ FELIPE SOLANO ANTELO 8A 8º3", ---> direccion_titular
                  nombreTitular: "",
                  apellido1Titular: "",
                  apellido2Titular: "",
                  desAutonomiaTitular: "",
                  desProvinciaTitular: "",
                  desMunicipioTitular: "",
                  tipoViaTitular: "",
                  viaTitular: "",
                  numFincaTitular: "",
                  portalTitular: "",
                  escaleraTitular: "",
                  pisoTitular: "",
                  puertaTitular: "",
                  iban: "",
                  CodigoPostalTitular: "", //"08630",----> codigo_postal_titular
                  TelefonoFijo: "", //"949201893", ----> telefono_fijo
                  TelefonMovil: "", //"689105332", ----> telefono
                  Email: "", //"demo@demo.com", --->email_contacto
                  TipoCliente: "NO DEFINIDO", //"COMUNIDAD DE PROPIETARIOS", ---> tipo_cliente
                  ObservacionesCliente: "", //"OPCIONAL O NULL",----> observaciones



                          GDPR: true, // "opcional o null" ----> observaciones_ps

                      },

                      nombreOfertaError: false,
                      emailError: emailError,
                      nombreTitularError: nombreTitularError,
                      NIFError: NIFError,
                      DireccionFiscalError: DireccionFiscalError,
                      CodigoPostalTitularError: CodigoPostalTitularError,
                      TelefonoFijoError: TelefonoFijoError,
                      TelefonMovilError: TelefonMovilError,
  apellido1TitularError: apellido1TitularError,
                      idComercializadoraError: true
              });
              this.forceUpdate();
              return;
            }
          }
  };

  render() {
  //  console.log('EN SAVE');
  //  console.log(this.state);
//    console.log(this.props);

const dicMunicipios: any[] = this.state.dicMunicipios;

    const form: any = this.state.form;
    //const form2:any = this.state.form;



    var nombre: string = form.nombreOferta;

    if(!nombre || nombre === '0'){
      nombre = ""
    }

    if(!form.nombreOferta || form.nombreOferta === '0'){
      form.nombreOferta= ""
    }

    if(!form.correoContacto || form.correoContacto === '0'){
      form.correoContacto= ""
    }
    if(!form.NIF || form.NIF === '0'){
      form.NIF= ""
    }
    if(!form.razonSocial || form.razonSocial === '0'){
      form.razonSocial= ""
    }


    if(!form.apellido1Titular || form.apellido1Titular === '0'){
      form.apellido1Titular= ""
    }
    if(!form.apellido2Titular || form.apellido2Titular === '0'){
      form.apellido2Titular= ""
    }


    if(!form.nombreTitular || form.nombreTitular === '0'){
      form.nombreTitular= ""
    }

    if(!form.desAutonomiaTitular || form.desAutonomiaTitular === '0'){
      form.desAutonomiaTitular= ""
    }
    if(!form.desProvinciaTitular || form.desProvinciaTitular === '0'){
      form.desProvinciaTitular= ""
    }
    if(!form.desMunicipioTitular || form.desMunicipioTitular === '0'){
      form.desMunicipioTitular= ""
    }
    if(!form.tipoViaTitular || form.tipoViaTitular === '0'){
      form.tipoViaTitular= ""
    }

    if(!form.viaTitular || form.viaTitular === '0'){
      form.viaTitular= ""
    }

    if(!form.numFincaTitular || form.numFincaTitular === '0'){
      form.numFincaTitular= ""
    }
    if(!form.portalTitular || form.portalTitular === '0'){
      form.portalTitular= ""
    }
    if(!form.escaleraTitular || form.escaleraTitular === '0'){
      form.escaleraTitular= ""
    }
    if(!form.pisoTitular || form.pisoTitular === '0'){
      form.pisoTitular= ""
    }

    if(!form.puertaTitular || form.puertaTitular === '0'){
      form.puertaTitular= ""
    }




    if(!form.DireccionFiscal || form.DireccionFiscal === '0'){
      form.DireccionFiscal= ""
    }
    if(!form.TelefonoFijo || form.TelefonoFijo === '0'){
      form.TelefonoFijo= ""
    }
    if(!form.CodigoPostalTitular || form.CodigoPostalTitular === '0'){
      form.CodigoPostalTitular= ""
    }
    if(!form.TelefonMovil || form.TelefonMovil === '0'){
      form.TelefonMovil= ""
    }


    if(form.Email === '0'){
      form.Email= ""
    }
    if(!form.ObservacionesCliente || form.ObservacionesCliente === '0'){
      form.ObservacionesCliente= ""
    }


    //form.razonSocial = this.props.ubi.nombre_completo_titular;
    //var razonSocial: String = this.props.ubi.nombre_completo_titular;
    const radioStyle = {
      display: "flex",
      justifyContent: "space-between",
    };
      const tipoCliente: any[] = this.state.TipoCliente;
    return (
      <>
        <Modal
          size="lg"
          className="modal-dialog-centered"
          isOpen={this.props.isOpen}
          toggle={this.props.toggle}
        >
          <div className="modal-header">
            <h3 className="modal-title" id="saveModalOpened">
              Guardar comparativa
            </h3>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={this.props.toggle}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body">
            <Form innerRef={this.formComparativeRef}>
              <Row>
                <Col>
                  <FormGroup>
                    <Label className="form-control-label" htmlFor="propuesta">
                      Nombre Comparativa
                    </Label>
                    <Input
                      id="nombreOferta"
                      placeholder="Identificador de la comparativa"
                      name="nombreOferta"
                      onChange={this.onChangeField}
                      value= {form.nombreOferta}
                      type="text"
                      required
                    > </Input>

                    {this.state.nombreOfertaError ? <FormText color= "red">Por favor introduzca un nombre</FormText> : ''}
                  </FormGroup>

                  <FormGroup style={radioStyle}>
                    <Label className="form-control-label" htmlFor="esAprobada">
                      Aprobada
                    </Label>
                    <Label className="custom-toggle">
                      <Input
                        id="esAprobada"
                        name="esAprobada"
                        onChange={this.onChangeField}
                        checked={form.esAprobada}
                        type="checkbox"
                      />
                      <span className="custom-toggle-slider rounded-circle" />
                    </Label>

                      </FormGroup>
                      <h6 className="">A los efectos de lo dispuesto en la normativa vigente relativa a tratamientos de datos de carácter personal, el Comercializador se compromete al cumplimiento de su deber de guardar secreto de los datos de carácter personal, y adoptará las medidas legalmente previstas y necesarias para evitar su alteración, pérdida, tratamiento o acceso no autorizado, habida cuenta en todo momento del estado de la tecnología aplicable. El Cliente queda informado y acepta todas las cuestiones relacionadas al tratamiento de sus datos de carácter personal por parte de la empresa y terceros, que voluntariamente ha facilitado al Comercializador.</h6>

                      <FormGroup style={radioStyle}>
                      <Label className="form-control-label" htmlFor="GDPR">
                      Autoriza GDPR
                    </Label>
                    <Label className="custom-toggle">
                      <Input
                        id="GDPR"
                        name="GDPR"
                        onChange={this.onChangeField}
                        checked={form.GDPR}
                        type="checkbox"
                      />
                      <span className="custom-toggle-slider rounded-circle" />
                    </Label>
                  </FormGroup>

                  <Row>
                        <Col>
                  <FormGroup>

                    <Label className="form-control-label" htmlFor="nombreTitular">
                      Nombre Titular
                    </Label>
                    <Input
                      id="nombreTitular"
                      placeholder=""
                      name="nombreTitular"
                      onChange={this.onChangeField}
                      value={form.nombreTitular}
                      type="text"
                      required
                    />
                    {this.state.nombreTitularError ? <FormText color= "red">Comprobar titular</FormText> : ''}
                  </FormGroup>
                  </Col>

                  <Col>
                  <FormGroup>

                  <Label className="form-control-label" htmlFor="apellido1Titular">
                  Primer Apellido Titular
                  </Label>
                  <Input
                  id="apellido1Titular"
                  placeholder=""
                  name="apellido1Titular"
                  onChange={this.onChangeField}
                  value={form.apellido1Titular}
                  type="text"
                  required
                  />
                  {this.state.apellido1TitularError ? <FormText color= "red">Comprobar Apellido</FormText> : ''}
                  </FormGroup>
                  </Col>


                    <Col>
                    <FormGroup>

                    <Label className="form-control-label" htmlFor="apellido2Titular">
                  Segundo Apellido Titular
                    </Label>
                    <Input
                    id="apellido2Titular"
                    placeholder=""
                    name="apellido2Titular"
                    onChange={this.onChangeField}
                    value={form.apellido2Titular}
                    type="text"
                    required
                    />
                    </FormGroup>
                    </Col>



                  <Col>
                     <FormGroup>
                    <Label className="form-control-label" htmlFor="NIF">
                      NIF
                    </Label>
                    <Input
                      id="NIF"
                      placeholder="Introduzca NIF"
                      name="NIF"
                      onChange={this.onChangeField}
                      value={form.NIF}
                      type="text"
                      required
                    />
                    {this.state.NIFError ? <FormText color= "red">Comprobar NIF/CIF</FormText> : ''}
                  </FormGroup>
                  </Col>

                  </Row>

                  <Row>
                      <Col md="2">
                  <FormGroup>

                  <Label className="form-control-label" htmlFor="tipoViaTitular">
                    Tipo Via
                  </Label>
                  <Input
                    id="tipoViaTitular"
                    placeholder=""
                    name="tipoViaTitular"
                    onChange={this.onChangeField}
                    value={form.tipoViaTitular}
                    type="text"
                    required
                  />
                  </FormGroup>
                  </Col>

                  <Col md="6">
                  <FormGroup>

                  <Label className="form-control-label" htmlFor="viaTitular">
                  Dirección Titular
                  </Label>
                  <Input
                  id="viaTitular"
                  placeholder=""
                  name="viaTitular"
                  onChange={this.onChangeField}
                  value={form.viaTitular}
                  type="text"
                  required
                  />
                  </FormGroup>
                  </Col>


                  <Col md="2">
                  <FormGroup>

                  <Label className="form-control-label" htmlFor="numFincaTitular">
                  Nº Finca
                  </Label>
                  <Input
                  id="numFincaTitular"
                  placeholder=""
                  name="numFincaTitular"
                  onChange={this.onChangeField}
                  value={form.numFincaTitular}
                  type="text"
                  required
                  />
                  </FormGroup>
                  </Col>



                  <Col md="2">
                   <FormGroup>
                  <Label className="form-control-label" htmlFor="portalTitular">
                  Portal Tit.
                  </Label>
                  <Input
                    id="portalTitular"
                    placeholder=""
                    name="portalTitular"
                    onChange={this.onChangeField}
                    value={form.portalTitular}
                    type="text"
                    required
                  />
                  </FormGroup>
                  </Col>

                  <Col md="1">
                   <FormGroup>
                  <Label className="form-control-label" htmlFor="escaleraTitular">
                  ESC.
                  </Label>
                  <Input
                    id="escaleraTitular"
                    placeholder=""
                    name="escaleraTitular"
                    onChange={this.onChangeField}
                    value={form.escaleraTitular}
                    type="text"
                    required
                  />
                  </FormGroup>
                  </Col>
                  <Col md="1">
                   <FormGroup>
                  <Label className="form-control-label" htmlFor="pisoTitular">
                  Piso
                  </Label>
                  <Input
                    id="pisoTitular"
                    placeholder=""
                    name="pisoTitular"
                    onChange={this.onChangeField}
                    value={form.pisoTitular}
                    type="text"
                    required
                  />
                  </FormGroup>
                  </Col>
                  <Col md="1">
                   <FormGroup>
                  <Label className="form-control-label" htmlFor="puertaTitular">
                  Puerta
                  </Label>
                  <Input
                    id="puertaTitular"
                    placeholder=""
                    name="puertaTitular"
                    onChange={this.onChangeField}
                    value={form.puertaTitular}
                    type="text"
                    required
                  />
                  </FormGroup>
                  </Col>

                  <Col md="2">
                   <FormGroup>
                  <Label className="form-control-label" htmlFor="CodigoPostalTitular">
                  C.P. Titular
                  </Label>
                  <Input
                  id="CodigoPostalTitular"
                  placeholder=""
                  name="CodigoPostalTitular"
                  onChange={this.onChangeField}
                  value={form.CodigoPostalTitular}
                  type="text"
                  required
                  />
                  {this.state.CodigoPostalTitularError ? <FormText color= "red">Por favor compruebe el código postal del titular</FormText> : ''}
                  </FormGroup>
                  </Col>


                  <Col md="3">
                    <FormGroup>
                      <Label className="form-control-label">
                        Municipio:
                      </Label>
                      <SipsOption
                        placeholder="Seleccionar localidad"
  //                     invalid={forceUseLocality}
                        id="desMunicipioTitular"
                        name="desMunicipioTitular"
                        query="LIST_LOCALITIESNAME"
                        onChange={this.onChangeField}
                        value={form.desMunicipioTitular}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="2">
                   <FormGroup>
                  <Label className="form-control-label" htmlFor="desProvinciaTitular">
                  Provincia
                  </Label>
                  <Input
                    id="desProvinciaTitular"
                    placeholder=""
                    name="desProvinciaTitular"
                    onChange={this.onChangeField}
                    value={form.desProvinciaTitular}
                    type="text"
                    required
                  />
                  </FormGroup>
                  </Col>

                  <Col md="3">
                   <FormGroup>
                  <Label className="form-control-label" htmlFor="desAutonomiaTitular">
                  C. Autónoma
                  </Label>
                  <Input
                    id="desAutonomiaTitular"
                    placeholder=""
                    name="desAutonomiaTitular"
                    onChange={this.onChangeField}
                    value={form.desAutonomiaTitular}
                    type="text"
                    required
                  />
                  </FormGroup>
                  </Col>
                  </Row>

                  <Row>
                  <Col md="5">
                  <FormGroup>
                  <Label
                    className="form-control-label"
                    htmlFor="idComercializadora"
                  >
                    Comercializadora de procedencia
                  </Label>
                  <SipsOption
                    placeholder=""
                    id="idComercializadora"
                    name="idComercializadora"
                    value={form.idComercializadora}
                    required
                    query="LIST_MARKETERS"
                    onChange={this.onChangeField}
                  />
                  {this.state.idComercializadoraError ? <FormText color= "red">Por favor introduzca una comercializadora</FormText> : ''}
                  </FormGroup>
                  </Col>

                  <Col md="3">
                  <FormGroup>
                  <Label
                    className="form-control-label"
                    htmlFor="correoContactos"
                  >
                    Correo de contacto
                  </Label>
                  <Input
                    id="correoContactos"
                    placeholder=""
                    name="correoContacto"
                    onChange={this.onChangeField}
                    value={form.correoContacto}
                    type="text"
                    required
                  >  </Input>
                  {this.state.emailError ? <FormText color= "red">Por favor introduzca una dirección de correo electrónico correcto</FormText> : ''}
                  </FormGroup>
                  </Col>

                  <Col md="3">
                  <FormGroup>
                  <Label className="form-control-label" htmlFor="cliente">
                    Tipo Cliente
                  </Label>
                  <Input
                      id="TipoCliente"
                      placeholder=""
                      name="TipoCliente"
                      onChange={this.onChangeField}
                      hidden={ tipoCliente.length > 0 ? false : true }
                      type="select" multiselect>
                          { tipoCliente.map((item:any, index) => <option value={item.cat} key={index}>{item.cat}</option>) }

                  </Input>
                  </FormGroup>
                  </Col>
                  <Col md="3">
                  <Label className="form-control-label" htmlFor="TelefonoFijo">
                  Teléfono Fijo
                  </Label>
                  <Input
                  id="TelefonoFijo"
                  placeholder=""
                  name="TelefonoFijo"
                  onChange={this.onChangeField}
                  value={form.TelefonoFijo}
                  type="text"
                  required
                  />
                  {this.state.TelefonoFijoError ? <FormText color= "red">Por favor compruebe el teléfono del titular</FormText> : ''}
                  </Col>
                  <Col md="3">
                  <Label className="form-control-label" htmlFor="TelefonMovil">
                  Teléfono Móvil
                  </Label>
                  <Input
                  id="TelefonMovil"
                  placeholder=""
                  name="TelefonMovil"
                  onChange={this.onChangeField}
                  value={form.TelefonMovil}
                  type="text"
                  required
                  />
                  </Col>
                  <Col>
                  <Label className="form-control-label" htmlFor="ObservacionesCliente">
                  Observaciones Cliente
                  </Label>
                  <Input
                  id="ObservacionesCliente"
                  placeholder=""
                  name="ObservacionesCliente"
                  onChange={this.onChangeField}
                  value={form.ObservacionesCliente}
                  type="text"
                  required
                  />

                  </Col>

                  </Row>

                  <Row>
                  <Col md="5">
                  <Label className="form-control-label" htmlFor="iban">
                    IBAN
                  </Label>
                  <Input
                    id="iban"
                    placeholder=""
                    name="iban"
                    onChange={this.onChangeField}
                    value={form.iban}
                    type="text"
                    required
                  />
                  {this.state.ibanError ? <FormText color= "red">Comprobar IBAN</FormText> : ''}

                  </Col>
                  </Row>
                </Col>
              </Row>
            </Form>
          </div>
          <div className="modal-footer">
            <Button
              color="secondary"
              data-dismiss="modal"
              type="button"
              onClick={this.props.toggle}
            >
              Close
            </Button>
            <Button
              onClick={this.generateComparative}
              color="primary"
              type="button"
              disabled={this.state.isDisabled}
            >
              Guardar Comparativa
            </Button>
          </div>
        </Modal>
      </>
    );
  }
}
