/*eslint-disable*/
import React from "react";
import LoadingOverlay from "react-loading-overlay";

// reactstrap components
import { Card, CardBody, CardTitle, Col, ListGroup, ListGroupItem, Row } from "reactstrap";
import { isEmptyBindingElement } from "typescript";
import moment from "moment";


interface SupplyGasInformationInterface {
  isLoading: boolean,
  cardClass?: string,
  sip: {};
}
class SupplyGasInformation extends React.Component<SupplyGasInformationInterface> {
  constructor(props) {
    super(props);
  }

  render() {
    const sip: any = this.props.sip;
    if(sip.cups === undefined) {
      return '';
    }
    console.log('RELACION DE FECHAS  ---->')

      console.log(sip.fechaUltimoCambioComercializador)
        console.log(sip.fechaUltimoMovimientoContrato)
          console.log(sip.inspeccionDate)


            //const releaseDate = moment(sip.fechaAltaSuministro).isValid()?moment(sip.fechaAltaSuministro).format('DD-MM-YYYY') : "";
            const lastChangeMarketer = moment(sip.fechaUltimoCambioComercializador).isValid()?moment(sip.fechaUltimoCambioComercializador).format('DD-MM-YYYY') : "";
            const lastContractMovement = moment(sip.fechaUltimoMovimientoContrato).isValid()?moment(sip.fechaUltimoMovimientoContrato).format('DD-MM-YYYY') : "";
            const inspeccionDate = moment(sip.fechaUltimaInspeccion).isValid()?moment(sip.fechaUltimaInspeccion).format('DD-MM-YYYY') : "";
            //const apmDate = moment(sip.fechaEmisionAPM).isValid()?moment(sip.fechaEmisionAPM).format('DD-MM-YYYY') : "";

    const nonContratableReason = sip.codigoResultadoInspeccion != "00" ? <ListGroupItem><strong>Resultado Inspección:</strong> {sip.descCodigoResultadoInspeccion}</ListGroupItem> : 'SIN DATOS';
    const nombreCompletoTitular = sip.apellido1Titular+' '+sip.apellido2Titular+' ,'+sip.nombreTitular;
    const direccionPS = sip.tipoViaTitular+' '+sip.viaTitular+' '+sip.numFincaTitular+ ' ' + sip.portalTitular+ ' '+sip.escaleraTitular+' '+sip.pisoTitular+ ' '+sip.puertaTitular;

    return (
      <Card id="supplyInformation" className={this.props.cardClass}>
        <CardBody>
          <CardTitle>Información del suministro de Gas</CardTitle>
          <LoadingOverlay
            active={this.props.isLoading}
            spinner
            text="Cargando...">
            <Row>
              <Col md="6">
                <ListGroup>
                  <ListGroupItem><strong>CUPS:</strong> {sip.cups}</ListGroupItem>
                  <ListGroupItem><strong>Titular:</strong> {nombreCompletoTitular}</ListGroupItem>
                  <ListGroupItem><strong>NIF/CIF:</strong> {sip.CIF}</ListGroupItem>
                  <ListGroupItem><strong>Direccion:</strong> {direccionPS}</ListGroupItem>
                  <ListGroupItem><strong>Provincia:</strong> {sip.desProvinciaTitular}</ListGroupItem>
                  <ListGroupItem><strong>Localidad:</strong> {sip.desMunicipioTitular}</ListGroupItem>
                  <ListGroupItem><strong>Codigo Autoconsumo:</strong> {sip.codigoAutoconsumo}</ListGroupItem>
                  <ListGroupItem><strong>Punto suministro contratable:</strong> {sip.codigoPSContratable == 1 || sip.codigoPSContratable == null ? 'Si' : 'No' }</ListGroupItem>
                  {nonContratableReason}
                  </ListGroup>
              </Col>
              <Col md="6">
                <ListGroup>
                <ListGroupItem><strong>CNAE:</strong> {sip.CNAE}</ListGroupItem>
                <ListGroupItem><strong>Descripción CNAE:</strong> {sip.actividadCNAE}</ListGroupItem>
                  <ListGroupItem><strong>Ultimo cambio comercializador:</strong> {lastChangeMarketer}</ListGroupItem>
                  <ListGroupItem><strong>Ultimo movimiento contrato:</strong> {lastContractMovement}</ListGroupItem>
                  <ListGroupItem><strong>Fecha Ult. Inspección:</strong> {inspeccionDate}</ListGroupItem>
                  <ListGroupItem><strong>Resultado Inspección:</strong> {nonContratableReason}</ListGroupItem>
                  <ListGroupItem><strong>Derechos TUR:</strong> { sip.derechoTUR === 0 ? 'No' : 'Si' }</ListGroupItem>
                  <ListGroupItem><strong>Impagos:</strong> { sip.informacionImpagos > 0 ? sip.informacionImpagos+'€' : '-' }</ListGroupItem>

                </ListGroup>
              </Col>
            </Row>
          </LoadingOverlay>
        </CardBody>
      </Card>
    );
  }
}

export default SupplyGasInformation;
