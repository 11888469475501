import React, { Component } from 'react'

import {
    Pagination as BootstrapPagination,
    PaginationItem,
    PaginationLink,
} from 'reactstrap'

interface PaginationProps{
    "currentPage": number,
    "pageSize": number,
    "pageCount": number,
    "count": number,
    onPageClick?:Function
}

export default class Pagination extends Component<PaginationProps> {
   maxNumbersItems = 4

   handlePageClick = (e,pageNumber)=>{
    e.preventDefault()
    if(this.props.onPageClick){
        this.props.onPageClick(pageNumber)
    }
   }
  
  renderNumbers = ()=>{
    if(this.props.currentPage<1){
        return {};
    }

    let beforeNumbers:Array<number> = []
    let afterNumbers:Array<number> = [] 
    let c = 0;
    for (let i = 1; i < this.props.currentPage; i++) {
        c++
        if(c>= this.maxNumbersItems) break;
        beforeNumbers.push(i);
    }
    c=0
    for (let i = this.props.currentPage + 1; i <=this.props.pageCount; i++) {
        c++
        if(c>= this.maxNumbersItems) break;
        afterNumbers.push(i);
    }

    const hasPrevius = this.props.currentPage > 1
    const hasNext = this.props.currentPage < this.props.pageCount


    const beforeRender =  beforeNumbers.map((i)=>(
        <PaginationItem key={i}>
        <PaginationLink
          href="#"
          onClick={e => this.handlePageClick(e, i)}
        >
          {i} <span className="sr-only">(current)</span>
        </PaginationLink>
      </PaginationItem>
    )
    )

    const afterRender =  afterNumbers.map((i)=>(
        <PaginationItem key={i}>
        <PaginationLink
          href="#"
          onClick={e => this.handlePageClick(e, i)}
        >
          {i} <span className="sr-only">(current)</span>
        </PaginationLink>
      </PaginationItem>
    )
    )

    const currengPageRender = (
        <PaginationItem className="active">
          <PaginationLink
            href="#"
            onClick={e => this.handlePageClick(e, this.props.currentPage)}
          >
            {this.props.currentPage}
          </PaginationLink>
        </PaginationItem>
        
    )

    const previousRender = (
        <PaginationItem className={hasPrevius ? "" : "disabled"}>
          <PaginationLink
            href="#"
            onClick={e => hasPrevius ? this.handlePageClick(e, this.props.currentPage - 1 ) : e.preventDefault() }
            tabIndex={-1}
          >
            <i className="fas fa-angle-left" />
            <span className="sr-only">Previous</span>
          </PaginationLink>
        </PaginationItem>
    )

    const nextRender = (
        <PaginationItem className={hasNext ? "" : "disabled"}>
          <PaginationLink
            href="#"
            onClick={e => hasNext ? this.handlePageClick(e, this.props.currentPage + 1 ) : e.preventDefault() }
            tabIndex={-1}
          >
            <i className="fas fa-angle-right" />
            <span className="sr-only">Next</span>
          </PaginationLink>
        </PaginationItem>
    )
    

    return {beforeRender,afterRender,currengPageRender,previousRender,nextRender}


  }

  render() {
    const {beforeRender,afterRender,currengPageRender,previousRender,nextRender} = this.renderNumbers()
    if(this.props.count === 0){
      return null;
    }
    return (
    <>
    <BootstrapPagination
        className="pagination justify-content-end mb-0"
        listClassName="justify-content-end mb-0"
      >
          {previousRender}
          {beforeRender}
          {currengPageRender}
          {afterRender}
          {nextRender}
       
      </BootstrapPagination>
     {this.props.currentPage ? (<p className="text-right">Pagina {this.props.currentPage} de {this.props.pageCount}. Resultados encontrados {this.props.count}</p>): null}
      </>
    )
  }
}