import React from "react";
import { Card, CardBody, Form, Input, Row, Table} from "reactstrap";
//import { Bar } from '@reactchartjs/react-chart.js';
//import { withApollo } from "react-apollo";
//import client from "../../../api/client";
//import { Bar,  HorizontalBar  } from "react-chartjs-2";
import SipsTable from "../../../components/Sips/SipsTableNew";
//import { LIST_COMERCIAL_SERVICIOS} from "../../../queries/comercial-producto/comercial-producto";
import moment from 'moment';
//import { canNotDefineSchemaWithinExtensionMessage } from "graphql/validation/rules/LoneSchemaDefinition";

/* const comercialProductoFields = {
    periodo: "Periodo",
    precioPotencia: "Precio potencia €/kW",
    precioEnergia: "Precio Energía €/kWh"
};
*/
//const comercialProductoExpandableFields = {};


interface IComercialProductoMultiProps {
    sip: any,
    periods: number[],
    productoMulti: {
        active: boolean,
        productoSeleccionado: any[],
        costeOperativo: number
    };
    itemsConsumo: any[],
    output: any,
    //ubi: any
}

interface IComercialProductoMultiState {
    sip: any,
    ubi:any,
    periods: number[],

    active: boolean
    producto: any []
    comercialServicios: any[],
    costeOperativo: number;
    rates1XP:any[],
    rates2X: any[],
    rates3X: any[],
    rates2XP: any[],
    rates3XP: any[],
    rates6XP: any[],
    rates1XE: any[],
    rates2XE:  any[],
    rates3XE:  any[],
    rates6XE: any[],
    ratesMonth1:any[],
    ratesMonth2:any[],
    ratesMonth3:any[],
    ratesMonth4:any[],

    itemsTabla: any[];

  idservicio: any,
    idComercializadora: any,
    nombreComercializadora: any,
    nombreServicio: any,
    precioServicio: any,

    formMulti: any[],

    formMultiProducto: {
        sumPotencia: number,
        sumEnergia: number,
        iePercent: number;
        impuestoElectrico: number;
        iva: number;
        bi: number;
        total: number;
        serviciosAdicionalesMonth: number;
        serviciosAdicionales: number;
        alquilerEquipoMedidaMonth: number;
        alquilerEquipoMedida: number;
        codigoATR: any;
        numDaysNew: number;
        numMonthNew: number;
        fechaDesdeConsumo: any;
        fechaHastaConsumo:  any;
        datasetProducto: number[],
        EnergiaActiva: any[],
        Potencia: any[],
        potenciaP1: number,
        potenciaP2: number,
        energiaActivaP1: number,
        energiaActivaP2: number,
        idServicio: any,
        idComercializadora: any,
        //nombreComercializadora: any,
        nombreServicio: any,
        precioServicio: number,
        descuentoMonth: number,
        descuento: number

    },
    formActual: {

        precioTP1: number,
        precioTP2: number,


        precioTEP1: number,
        precioTEP2: number,


        serviciosAdicionalesMonth: number;
        serviciosAdicionales: number;
        alquilerEquipoMedidaMonth: number;
        alquilerEquipoMedida: number;
        codigoATR: any;

        numDaysNew: number;
        numMonthNew: number;
        fechaDesdeConsumo: any;
        fechaHastaConsumo:  any;

        sumPotencia: number;
        sumEnergia: number;
        iePercent: number;
        impuestoElectrico: number;
        iva: number;
        bi: number;
        total: number;


        datasetActual: number[],
        datasetLeyenda:any[],
        descuentoMonth: number,
        descuento: number,

    },
    totalComparacion: any[]
  /*  {
        ahorroAnual: number,
        ahorroPercent: number,
        ahorroPeriodo: number,
        datosAhorro: number[]
    },
  */
    itemsConsumo: any[];
    productSelected: any;
}


export default class


ComercialGasProductoMulti extends React.Component<IComercialProductoMultiProps, IComercialProductoMultiState>
{
	public formActualRef: any = null;
	public formMultiProductoRef: any = null;
	public state: IComercialProductoMultiState = {
        formMulti:[],
        formMultiProducto: {
          descuento:0,
          descuentoMonth:0,
            serviciosAdicionalesMonth: 0,
            serviciosAdicionales: 0,
            alquilerEquipoMedidaMonth: 0,
            alquilerEquipoMedida: 0,
            codigoATR: 0,
            sumPotencia: 0,
            sumEnergia: 0,
            iePercent: 0,
            impuestoElectrico: 0,
            iva: 0,
            bi: 0,
            total: 0,
            EnergiaActiva: [],
            Potencia:[],
            potenciaP1 : 0,
        potenciaP2 : 0,
        energiaActivaP1: 0,
        energiaActivaP2: 0,
            numDaysNew: 0,
            numMonthNew: 0,
            fechaDesdeConsumo: null,
            fechaHastaConsumo:  null,
            datasetProducto: [],
          idServicio : null,
          idComercializadora: null,
        //    nombreComercializadora: null,
            nombreServicio: null,
            precioServicio: 0

        },
        formActual: {

            descuento:0,
            descuentoMonth:0,
            serviciosAdicionalesMonth: 0,
            serviciosAdicionales: 0,
            alquilerEquipoMedidaMonth: 0,
            alquilerEquipoMedida: 0,
            codigoATR: 0,

            numDaysNew: 0,
            numMonthNew: 0,
            fechaDesdeConsumo: null,
            fechaHastaConsumo:  null,

            sumPotencia: 0,
            sumEnergia: 0,
            iePercent: 0,
            impuestoElectrico: 0,
            iva: 0,
            bi: 0,
            total: 0,

            datasetActual: [],
            datasetLeyenda:[],

            precioTP1: 0,
            precioTP2: 0,

            precioTEP1: 0,
            precioTEP2: 0,

        },
        totalComparacion:[],
/*
        {
            ahorroAnual: 0,
            ahorroPercent: 0,
            ahorroPeriodo: 0,
            datosAhorro:[]
        },
  */
        comercialServicios: [],
        rates2X: [
            '001',
            '004',
            '005',
            '006',
            '007',
            '008'
        ],
        rates3X: [
            '011',
            '003',
            '012',
            '013',
            '014',
            '015',
            '016'
        ],
        rates2XP: [
            '018',
        ],
        rates1XP:[
          '001',
          '004',
          '005',
          '006',
          '007',
          '008'
        ],
        rates3XP: [
            '011',
            '003'
        ],
           rates6XP: [
             '012',
             '013',
             '014',
             '015',
             '016',
             '019',
             '020',
             '021',
             '022',
             '023',
             '024',
             '025'
        ],
         rates1XE: [
            '001',
            '005'
        ],
        rates2XE: [
            '004',
            '006'
        ],
        rates3XE: [
            '011',
            '003',
            '007',
            '008',
            '018'
        ],
           rates6XE: [
            '012',
            '013',
            '014',
            '015',
            '016',
            '019',
            '020',
            '021',
            '022',
            '023',
            '024',
            '025'
        ],
        ratesMonth1:[1,2,7,12],
        ratesMonth2:[3,11],
        ratesMonth3:[4,5,10],
        ratesMonth4:[6,8,9],

        idservicio: null,
        idComercializadora: null,
        nombreComercializadora: null,
        nombreServicio: null,
        precioServicio: null,


        active: false,
        sip: {},
        ubi:{},
        periods: [],
        itemsTabla: [],
        itemsConsumo: [],
		producto: [],
        costeOperativo: 0,
        productSelected: null,
	};

    componentWillReceiveProps( newProps: IComercialProductoMultiProps )
    {

        let inputState: any = { ...newProps };

        if( newProps.sip )
        {
            var state = Object.assign(this.state);
            this.setState(state);
        }

        this.setState({
          productSelected:null
        });


        if( newProps.productoMulti.productoSeleccionado )
        {

            let newItemsTabla:any[] = [];
            delete inputState.producto;
            this.fetchItems()

            const producto:any[] = { ...newProps.productoMulti.productoSeleccionado };
            const costeOperativo = newProps.productoMulti.costeOperativo;
            let periodos:number[] = [];

            // console.log('VALORES DE producto en componentWillReceiveProps')
            // console.log(producto)

            newProps.productoMulti.productoSeleccionado.map( row => {

                //console.log('ESTOY DENTRO producto.map')
                var periodosNuevos = 0;
                if(this.state.sip.codigoPeajeEnVigor == '35'){
                  periodosNuevos = 2;
                } else{periodosNuevos = 1};

 console.log('VALOR DE PeriodosNuevos = ', periodosNuevos)

            for( let i=1; i<=periodosNuevos; i++ )
            {


              //  console.log('ESTOY DENTRO del for en el producto.map')
              //  console.log(row)

                // console.log(row['id']);
                let consumoAnual = `ConsumoAnualEnWhP${i}`

                if(this.state.sip[consumoAnual] >= 0 && i<=periodosNuevos)
                {

                    let precioEnergiaField = `precioTE${i}`;
                    if(i<2){
                      var precioPotenciaField = `precioTFijo`;
                    } else{
                      var precioPotenciaField = ``;
                    }
                    let periodo = `P${i}`;
                    periodos.push(i);
                    var precioPotencia = row[precioPotenciaField];
                    if (i===1){
                    precioPotencia = row[precioPotenciaField];}
                    else {precioPotencia = null};
                    //if (precioPotencia === null){ precioPotencia = 0};
                    let precioEnergia = row[precioEnergiaField] + costeOperativo;
                    let id = row['id']

                    /*switch(row['precioTPTipo']) {
                        case 'DIARIO':
                            // console.log('DENTRO DE DIARIO')
                            if (i===1){
                            precioPotencia = row['precioTPPeriodo'];}
                            else {precioPotencia = null};
                            break;
                        case 'MENSUAL':
                            // console.log('DENTRO DE MENSUAL')
                            if (i===1){
                            precioPotencia = row['precioTPPeriodo'];}
                            else {precioPotencia = null};
                            break;
                    console}

                    */


                    console.log('VALORES DE newItemsTabla');
                    console.log(id)
                    console.log(periodo)
                    console.log(precioPotencia)
                    console.log(precioEnergia)


                    newItemsTabla.push({ id: id, periodo:periodo, precioPotencia: precioPotencia, precioEnergia: precioEnergia });
                    // console.log(newItemsTabla)
                  }
                }
                      return row;
                  });

console.log('434 ------------------------')
            console.log(producto)
            console.log(newItemsTabla)
console.log('437 -------------------------')

            inputState.producto = producto;
            inputState.itemsTabla = newItemsTabla;
            inputState.costeOperativo = costeOperativo;
            //inputState.active = newProps.producto.active;
            this.setState(  {
                ...this.state,
                ...inputState
            }, ()=>{
                this.renderFacturaProducto();
                this.renderFacturaActual();

            })
        }
        else
        {
            this.setState(  {
                ...this.state,
                ...inputState
            })
        }

    }

	componentDidMount() {

        this.formActualRef = React.createRef();
        this.formMultiProductoRef = React.createRef();
        this.setState({
          productSelected:null
        }, ()=>{
            this.renderFacturaProducto();


        });
        // console.log("DEFAULT",this.state);
        // this.state.productSelected =  this.state.producto[0].id;
    }


	fetchItems = async () => {

    // aquí empieza la selección de servicios por Comercializadora
/*
    const companyUser = await JSON.parse(await localStorage.getItem("user")!);
    var idComercializadora = this.state.producto.idComercializadora;
    if (idComercializadora == null){
      idComercializadora = '0000'};


   await client.query({
          query: LIST_COMERCIAL_SERVICIOS,
    variables: { idComercializadora: idComercializadora},
      })
      .then((result) => {
          let data = result.data.comercialServicios;

          this.setState({ comercialServicios: data})
      });
      */
    }


    onChangeFieldFormActual= async ( event) => {
        let inputs:any = await this.onChangeField( event );
        console.log('valores de inputs------>', inputs)
        if( inputs )
        {
            this.setState({
                formActual: {
                    ...this.state.formActual,
                    ...inputs
                }
            }, this.renderFacturaActual );
        }
    }

    onChangeFieldformMultiProducto = async ( event ) => {
        let inputs:any = await this.onChangeField( event );
        if( inputs )
        {
            this.setState({
                formMultiProducto: {
                    ...this.state.formMultiProducto,
                    ...inputs
                }
            }, this.renderFacturaProducto);
        }
    }

    onChangeFieldformMultiProductoServicio = async ( event ) => {
        let inputs:any= await this.onChangeField( event );
        let opcion = parseInt(inputs.idServicio)
        let                    idservicio = null;
        let                   idComercializadora = null;
        let                   nombreComercializadora = null;
        let                   nombreServicio = null;
        let                   precioServicio = null;
        // console.log('VALORES DE onChangeFieldformMultiProductoServicio')
        // console.log(inputs)
        // console.log(opcion)

/*
        idservicio: null,
        idComercializadora: null,
        nombreComercializadora: null,
        nombreServicio: null,
        precioServicio: 0,

        id: "09180001"
idComercializadora: "0918"
infoAdicional: null
nombreComercializadora: "ALDRO ENERGIA Y SOLUCIONES S.L"
nombreServicio: "Exprés24"
precio: 5.9
*/

        this.state.comercialServicios.map( row => {
        //   console.log('ESTOY DENTRO 1')
        //   console.log(row['id']);
          let fila = parseInt(row['id']);
        //   console.log(fila)
               if (fila === opcion){
                //    console.log('ESTOY DENTRO')
                   idservicio = row['id'];
                   idComercializadora = row['idComercializadora'];
                   nombreComercializadora = row['nombreComercializadora'];
                   nombreServicio = row['nombreServicio'];
                   precioServicio = row['precio'];
                //    console.log(precioServicio);
               }
               return row;
           });


        {
            this.setState({
                    idservicio: idservicio ,
                    idComercializadora: idComercializadora ,
                    nombreComercializadora: nombreComercializadora ,
                    nombreServicio: nombreServicio ,
                    precioServicio: precioServicio

            }, this.renderFacturaProducto);
        }

    }

    private calculateAhorro = ( periodos:number[], facturaActual: any, facturaProducto: any ) => {


        let totalFacturaActual = facturaActual.total;
        let totalFacturaProducto = facturaProducto.total;
        let total:any = facturaProducto
        console.log('VALOR DE totalFActuraActual')
        console.log(totalFacturaActual)
        console.log(totalFacturaProducto)
        console.log(total)


        let ahorroPeriodo = this.roundNumber((totalFacturaActual - totalFacturaProducto));
        let ahorroPercent = this.roundNumber(ahorroPeriodo * 100/totalFacturaActual);
        let datosAhorro= [0];
        datosAhorro.length = 0;
        datosAhorro[datosAhorro.length]=totalFacturaActual;
        datosAhorro[datosAhorro.length]=totalFacturaProducto;
        datosAhorro[datosAhorro.length] =  ahorroPeriodo;

        return {
            ahorroAnual: ahorroPeriodo,
            ahorroPercent: isNaN(ahorroPercent)?0:ahorroPercent,
            ahorroPeriodo: ahorroPeriodo,
            datosAhorro: datosAhorro
        }
    }


    private calculateAhorroMulti = ( periodos:number[], facturaActual: any, facturaProducto: any ) => {


        let totalFacturaActual = facturaActual.total;
        let totalFacturaProducto = facturaProducto.total;
        let total:any = facturaProducto
        // console.log('VALOR DE totalFActuraActual en Multi actual')
        // console.log(totalFacturaActual)
        // console.log(totalFacturaProducto)
        // console.log(total)


        let ahorroPeriodo = this.roundNumber((totalFacturaActual - totalFacturaProducto));
        let ahorroPercent = this.roundNumber(ahorroPeriodo * 100/totalFacturaActual);
        let datosAhorro= [0];
        datosAhorro.length = 0;
        datosAhorro[datosAhorro.length]=totalFacturaActual;
        datosAhorro[datosAhorro.length]=totalFacturaProducto;
        datosAhorro[datosAhorro.length] =  ahorroPeriodo;

        return {
            ahorroAnual: ahorroPeriodo,
            ahorroPercent: isNaN(ahorroPercent)?0:ahorroPercent,
            ahorroPeriodo: ahorroPeriodo,
            datosAhorro: datosAhorro
        }
    }


    private calculatePrecioProducto = ( periodos: number[], producto: any, factura: any ) => {
        let {
            serviciosAdicionales,
            serviciosAdicionalesMonth,
            alquilerEquipoMedidaMonth,
            alquilerEquipoMedida,
            codigoATR,
            sumPotencia,
            sumEnergia,
            iePercent,
            impuestoElectrico,
            penalizacionPotencia,
            penalizacionEnergia,
            iva,
            bi,
            total,
        numDaysNew,
        numMonthNew,
        fechaDesdeConsumo,
        fechaHastaConsumo,
        Potencia,
        idComercializadora ,
        idServicio,
//        nombreComercializadora ,
        nombreServicio ,
        precioServicio,
        idProducto
        //datasetProducto
        } = factura;

        const sip = this.state.sip;
        const ubi = this.state.ubi;
        let consumoPotencia = {};
        let consumoEnergia = {};
        let precioPotencia = {};
        let precioEnergia = {};
        //let numDays;

        fechaDesdeConsumo = null;
        fechaHastaConsumo = null;
        let potenciaP1 = 0;
        let potenciaP2 = 0;
        var consumoEnergiaTotal = 0;


        let energiaActivaP1 = 0;
        let energiaActivaP2 = 0;


        sumEnergia = 0;
        sumPotencia = 0;

        numDaysNew = 0;
        numMonthNew = 0;
          let monthLectura = 0;


        //console.log('VALORS DE THIS.STATE dentro de calculatePrecioProducto')
        //console.log(this.state);
        //console.log('VALOR DE PRODUCTO')
        //console.log(producto)


        this.state.itemsConsumo.map( row => {

                if (fechaDesdeConsumo === null){
                    fechaDesdeConsumo = row['fechaInicioMesConsumo'];
                     numDaysNew += Math.round(moment.duration(moment(row['fechaFinMesConsumo'], "DD-MM-YYYY").diff(moment(row['fechaInicioMesConsumo'], "DD-MM-YYYY"))).asDays());
                     numMonthNew += (moment.duration(moment(row['fechaFinMesConsumo'], "DD-MM-YYYY").diff(moment(row['fechaInicioMesConsumo'], "DD-MM-YYYY"))).asMonths()).toFixed(2);
                } else if ( moment(fechaDesdeConsumo, "DD-MM-YYYY") > moment(row['fechaInicioMesConsumo'], "DD-MM-YYYY")){
                           fechaDesdeConsumo = row['fechaInicioMesConsumo'];
                           numDaysNew += Math.round(moment.duration(moment(row['fechaFinMesConsumo'], "DD-MM-YYYY").diff(moment(row['fechaInicioMesConsumo'], "DD-MM-YYYY"))).asDays());

                           numMonthNew += (moment.duration(moment(row['fechaFinMesConsumo'], "DD-MM-YYYY").diff(moment(row['fechaInicioMesConsumo'], "DD-MM-YYYY"))).asMonths()).toFixed(2);
                           }
                return row;
            });
                   console.log('En 646 valor de numMonthNew ----> ', numMonthNew.replace(/^0+/, ''))

                   numMonthNew = numMonthNew.replace(/^0+/, '');
   //// HACEMOS LOS CALCULOS DE TOTALES DE POTENCIA //


                       this.state.itemsConsumo.map( row => {
                        potenciaP1 += (Number(sip.potenciasContratadasEnWP1) || 0);
                       potenciaP2 += (Number(sip.potenciasContratadasEnWP1) || 0);
                        return row;
                       });


                                           this.state.itemsConsumo.map( row => {
                                           if (sip.codigoPeajeEnVigor != '35'){
                                              energiaActivaP1  += this.roundNumber((Number(row['consumoEnWhP1']) || 0));

                                            }  else  {
                                              energiaActivaP1  += this.roundNumber((Number(row['consumoEnWhP1']) || 0));
                                              energiaActivaP2  += this.roundNumber((Number(row['consumoEnWhP2']) || 0));
                                             }
                                           return row;
                                           });






         this.state.itemsConsumo.map( row => {
                if (fechaHastaConsumo == null){
                    fechaHastaConsumo = row['fechaFinMesConsumo'];
                } else if ( moment(fechaHastaConsumo, "DD-MM-YYYY") < moment(row['fechaFinMesConsumo'], "DD-MM-YYYY")){
                           fechaDesdeConsumo = row['fechaFinMesConsumo'];
                           }

                return row;
            });


        periodos.map( periodo => {

            precioEnergia[`P${periodo}`] = 0;
            precioPotencia[`P${periodo}`] = 0;
            consumoPotencia[`P${periodo}`] = 0;
            consumoEnergia[`P${periodo}`] = 0;
            //potencia[`P${periodo}`] = 0;
            //numDays = 0;

            Object.keys(producto).map( key => {
                //Producto Energia
                if(key === `precioTE${periodo}`)
                {
                    precioEnergia[`P${periodo}`] = Number(producto[key]);

                }
                //Producto Potencia
                if(key === `precioTFijo`)
                {
              //      if(periodo > 1 && this.state.rates2X.includes(this.state.sip.codigoTarifaATREnVigor)){
              //        precioPotencia[`P${periodo}`] = 0
              //      } else {
                    precioPotencia[`P${periodo}`] = Number(producto[key])}
            //    }
                return key;
            });


            this.state.itemsConsumo.map( row => {

                consumoPotencia[`P${periodo}`] += Number(row[`potenciaDemandadaEnWP${periodo}`]) || 0;
                consumoEnergia[`P${periodo}`] += (Number(row[`consumoEnWhP${periodo}`]) || 0);
                consumoEnergiaTotal += (Number(row[`consumoEnWhP${periodo}`]) || 0);

                return row;

            });


            //datasetProducto.push(this.roundNumber((consumoEnergia[`P${periodo}`] * precioEnergia[`P${periodo}`])));

            sumEnergia += (consumoEnergia[`P${periodo}`] * precioEnergia[`P${periodo}`]);


            console.log('VALOR DE sumENERGIA ----> ', sumEnergia)
            console.log('valor de consumoEnergiaTotal -----> ', consumoEnergiaTotal)

            console.log('VALOR DE PRECIOPOTENCIA ---->', (precioPotencia[`P1`]))

                if (periodo === 1){
                  //const potenciaContratada = sip[`potenciasContratadasEnWP${periodo}`];(periodo)
                  const potenciaContratada = potenciaP1;
                  sumPotencia += (precioPotencia[`P${periodo}`]) * numMonthNew;}

                  if (periodo === 2){
                //const potenciaContratada = sip[`potenciasContratadasEnWP${periodo}`];(periodo)
                const potenciaContratada = potenciaP2;
                sumPotencia += (precioPotencia[`P${periodo}`]) * numMonthNew;}




            return periodo;
        });






        sumEnergia =  this.roundNumber(sumEnergia);
        sumPotencia =  this.roundNumber(sumPotencia);

        iePercent = this.roundNumber((consumoEnergiaTotal) * 0.00234);
        impuestoElectrico = 0;
serviciosAdicionales = 0;
serviciosAdicionalesMonth = 0;
        //serviciosAdicionales = this.roundNumber(this.state.precioServicio*Number(numMonthNew));
        console.log('VALOR DE serviciosAdicionales ----> ',serviciosAdicionales)
alquilerEquipoMedida = 0;
      //alquilerEquipoMedida = this.roundNumber(alquilerEquipoMedidaMonth*Number(numMonthNew));
        //console.log('VALOR DE alquilerEquipoMedida ----> ',alquilerEquipoMedida)
        bi = this.roundNumber((serviciosAdicionales + alquilerEquipoMedida + iePercent+sumEnergia+sumPotencia));
        console.log('VALOR DE BI ----> ',bi)
        iva = this.roundNumber((bi * 0.21));
        console.log('VALOR DE iva ----> ',iva)
        total = this.roundNumber((bi + iva));
        codigoATR = this.state.sip.codigoPeajeEnVigor;
        idServicio = this.state.idservicio;
        idComercializadora = this.state.idComercializadora;
        //nombreComercializadora = this.state.nombreComercializadora;
        nombreServicio =this.state.nombreServicio;

        precioServicio = this.roundNumber(this.state.precioServicio);
        idProducto = producto.id;



        return {
            serviciosAdicionalesMonth: serviciosAdicionalesMonth,
            serviciosAdicionales: serviciosAdicionales,
            alquilerEquipoMedidaMonth: alquilerEquipoMedidaMonth,
            alquilerEquipoMedida: alquilerEquipoMedida,
            codigoATR: codigoATR,
            sumPotencia: sumPotencia,
            sumEnergia: sumEnergia,
            iePercent: iePercent,
            impuestoElectrico: impuestoElectrico,
            iva: iva,
            bi: bi,
            total: total,

        numDaysNew: numDaysNew,
        numMonthNew: numMonthNew,
        fechaDesdeConsumo: fechaDesdeConsumo,
        fechaHastaConsumo: fechaHastaConsumo,
        EnergiaActiva: ["0"],
        Potencia: ["0"],
        potenciaP1: potenciaP1,
        potenciaP2: potenciaP2,

        energiaActivaP1 : energiaActivaP1,
        energiaActivaP2 : energiaActivaP2,



        idServicio : idServicio,
        idComercializadora: idComercializadora,
        //nombreComercializadora: nombreComercializadora,
        nombreServicio:         nombreServicio,
        precioServicio: precioServicio,
        id: idProducto

        }

    }


    private calculatePrecioActual = ( periodos: number[], factura: any ) => {

        let {
            serviciosAdicionalesMonth,
            serviciosAdicionales,
            alquilerEquipoMedidaMonth,
            alquilerEquipoMedida,
            codigoATR,
            sumPotencia,
            sumEnergia,
            iePercent,
            impuestoElectrico,
            iva,
            bi,
            total,
            numDaysNew,
        numMonthNew,
        fechaDesdeConsumo,
        fechaHastaConsumo,
        descuento,
        descuentoMonth

        } = factura;

        const sip = this.state.sip;
        let consumoPotencia = {};
        let consumoEnergia = {};
        let precioPotencia = {};
        let precioEnergia = {};
        //let numDays;
        fechaDesdeConsumo = null;
        fechaHastaConsumo = null;
var consumoEnergiaTotal = 0;

        sumEnergia = 0;
        sumPotencia = 0;


        numDaysNew = 0;
        numMonthNew = 0;




                this.state.itemsConsumo.map( row => {

                        if (fechaDesdeConsumo === null){
                            fechaDesdeConsumo = row['fechaInicioMesConsumo'];
                             numDaysNew += Math.round(moment.duration(moment(row['fechaFinMesConsumo'], "DD-MM-YYYY").diff(moment(row['fechaInicioMesConsumo'], "DD-MM-YYYY"))).asDays());
                             numMonthNew += (moment.duration(moment(row['fechaFinMesConsumo'], "DD-MM-YYYY").diff(moment(row['fechaInicioMesConsumo'], "DD-MM-YYYY"))).asMonths()).toFixed(2);
                        } else if ( moment(fechaDesdeConsumo, "DD-MM-YYYY") > moment(row['fechaInicioMesConsumo'], "DD-MM-YYYY")){
                                   fechaDesdeConsumo = row['fechaInicioMesConsumo'];
                                   numDaysNew += Math.round(moment.duration(moment(row['fechaFinMesConsumo'], "DD-MM-YYYY").diff(moment(row['fechaInicioMesConsumo'], "DD-MM-YYYY"))).asDays());

                                   numMonthNew += (moment.duration(moment(row['fechaFinMesConsumo'], "DD-MM-YYYY").diff(moment(row['fechaInicioMesConsumo'], "DD-MM-YYYY"))).asMonths()).toFixed(2);
                                   }
                        return row;
                    });







                    this.state.itemsConsumo.map( row => {
                           if (fechaHastaConsumo === null){
                               fechaHastaConsumo = row['fechaFinMesConsumo'];
                           } else if ( moment(fechaHastaConsumo, "DD-MM-YYYY") < moment(row['fechaFinMesConsumo'], "DD-MM-YYYY")){
                                      fechaDesdeConsumo = row['fechaFinMesConsumo'];
                                      }

                           return row;
                       });



        periodos.map( periodo => {

            precioEnergia[`P${periodo}`] = 0;
            precioPotencia[`P${periodo}`] = 0;
            consumoPotencia[`P${periodo}`] = 0;
            consumoEnergia[`P${periodo}`] = 0;
            //numDays = 0;


  console.log('ITEMS CONSUMO ---->', this.state.itemsConsumo)




  this.state.itemsConsumo.map( row => {
      consumoPotencia[`P${periodo}`] += Number(row[`potenciaDemandadaEnWP${periodo}`]) || 0;
      consumoEnergia[`P${periodo}`] += (Number(row[`consumoEnWhP${periodo}`]) || 0);
    consumoEnergiaTotal += (Number(row[`consumoEnWhP${periodo}`]) || 0);
      //numDays += moment(row['fechaFinMesConsumo'], "DD-MM-YYYY").daysInMonth();
      //numDays += moment.duration(moment(row['fechaFinMesConsumo'], "DD-MM-YYYY").diff(moment(row['fechaInicioMesConsumo'], "DD-MM-YYYY"))).asDays();
      return row;
  });


  sumEnergia +=  (consumoEnergia[`P${periodo}`] * this.state.formActual[`precioTEP${periodo}`]);
  //sumEnergia += (consumoEnergia[`P${periodo}`] * precioEnergia[`P${periodo}`]);
  console.log('VALOR DE sumENERGIA en actual ----> ', sumEnergia)
  console.log('valor de consumoEnergiaTotal en actual -----> ', consumoEnergiaTotal)

console.log('VALOR DE PRECIOPOTENCIA en actual  ---->', (precioPotencia[`P1`]))

      if (periodo === 1){
        //const potenciaContratada = sip[`potenciasContratadasEnWP${periodo}`];(periodo)
        //const potenciaContratada = potenciaP1;
        sumPotencia += (this.state.formActual[`precioTP${periodo}`]) * numMonthNew;}

        if (periodo === 2){
      //const potenciaContratada = sip[`potenciasContratadasEnWP${periodo}`];(periodo)
      //const potenciaContratada = potenciaP2;
      sumPotencia += (this.state.formActual[`precioTP${periodo}`]) * numMonthNew;}

      console.log('NUMERO DE MESES en factura---->', numMonthNew)



  return periodo;
});


        descuento = this.roundNumber(this.percentage(sumEnergia, descuentoMonth)*numMonthNew);

        sumEnergia =  this.roundNumber(this.roundNumber(sumEnergia)-descuento);
        sumPotencia =  this.roundNumber(sumPotencia);


        iePercent = this.roundNumber((consumoEnergiaTotal) * 0.00234);
        impuestoElectrico = 0;
        serviciosAdicionalesMonth= 0;
        serviciosAdicionales = this.roundNumber(serviciosAdicionalesMonth*numMonthNew);
        alquilerEquipoMedida = this.roundNumber(alquilerEquipoMedidaMonth*numMonthNew);
        bi = this.roundNumber((serviciosAdicionales + alquilerEquipoMedida + iePercent+sumEnergia+sumPotencia));
        iva = this.roundNumber((bi * 0.21));
        total = this.roundNumber((bi + iva));
        codigoATR = this.state.sip.codigoPeajeEnVigor;

        return {
            serviciosAdicionalesMonth: serviciosAdicionalesMonth,
            serviciosAdicionales: serviciosAdicionales,
            alquilerEquipoMedidaMonth: alquilerEquipoMedidaMonth,
            alquilerEquipoMedida: alquilerEquipoMedida,
            codigoATR: codigoATR,
            sumPotencia: sumPotencia,
            sumEnergia: sumEnergia,
            iePercent: iePercent,
            impuestoElectrico: impuestoElectrico,
            iva: iva,
            bi: bi,
            total: total,

            numDaysNew: numDaysNew,
            numMonthNew: numMonthNew,
            fechaDesdeConsumo: fechaDesdeConsumo,
            fechaHastaConsumo: fechaHastaConsumo,
            descuento: descuento,
            descuentoMonth: descuentoMonth,

        }

    }


    private roundNumber( number: number )
    {
        return (Math.round(number * 100) / 100)
    }


    private percentage(num, per)
    {
      return (num/100)*per;
    }

    public renderFacturaProducto = () => {
    //    console.log('DENTRO DE renderFacturaProducto')
    //    console.log(' VALORE DE this.state.peridos---> ', this.state.periods)
    //    console.log(' VALORE DE this.state.producto---> ', this.state.producto)
    //    console.log(' VALORE DE this.state.formMultiProducto---> ', this.state.formMultiProducto)
    //    console.log(' VALORE DE this.state.formMulti---> ', this.state.formMulti)
       //const producto:any = this.state.producto
       let valorlongitud= Object.keys(this.state.producto).length
    //    console.log('VALOR LONGITUD ----> dentro de renderFActuraProducto ', valorlongitud)
       let newfacturaTabla:any[] = [];
       let newAhorroTabla:any[] = [];
       if (this.state && valorlongitud > 0){
//  console.log('dentro de this.state.producto.length > 0')
       Object.keys(this.state.producto).map ( (item, i) => {
      // console.log('VALOR DE ROW ---->', this.state.producto[item])
        //  console.log('Periodos --------------->', this.state.periods)
          //console.log('Producto --------------->', this.state.producto[item])
        //  console.log('THIS State formMultiProducto-------->', this.state.formMultiProducto)
      //let factura: any = this.calculatePrecioProducto( this.state.periods, this.state.producto, this.state.formMultiProducto );
        let factura: any = this.calculatePrecioProducto( this.state.periods, this.state.producto[item], this.state.formMulti );

        console.log(factura)
        newfacturaTabla.push(factura)
        let ahorro: any = this.calculateAhorro( this.state.periods, this.state.formActual, factura );

        newAhorroTabla.push(ahorro)

        return item;
    });

    //formMultiTabla = newFacturaTabla;
    //inputState.itemsTabla = newItemsTabla;

    this.setState({
        formMulti:newfacturaTabla,
        totalComparacion:newAhorroTabla
        }
    ,this.stateOutput)
  }

  this.setState(
          ({ totalComparacion }) => ({
            totalComparacion:newAhorroTabla
          }), () => {
            this.setState(({ totalComparacion }) => ({
              totalComparacion:newAhorroTabla
            }))
          } )





//        let ahorro: any = this.calculateAhorro( this.state.periods, this.state.formActual, factura );

/*        let codigoATR:any = this.state.sip.codigoTarifaATREnVigor;
        this.setState({
            formMultiProducto: {
                serviciosAdicionalesMonth: factura.serviciosAdicionalesMonth,
                serviciosAdicionales: factura.serviciosAdicionales,
                alquilerEquipoMedida: factura.alquilerEquipoMedida,
                alquilerEquipoMedidaMonth: factura.alquilerEquipoMedidaMonth,
                codigoATR: codigoATR,
                sumPotencia: factura.sumPotencia,
                sumEnergia: factura.sumEnergia,
                iePercent: factura.iePercent,
                impuestoElectrico: factura.impuestoElectrico,
                iva: factura.iva,
                bi: factura.bi,
                total: factura.total,
                penalizacionPotencia: factura.penalizacionPotencia,
                penalizacionEnergia: factura.penalizacionEnergia,
                numDaysNew: factura.numDaysNew,
                numMonthNew: factura.numMonthNew,
                fechaDesdeConsumo: factura.fechaDesdeConsumo,
                fechaHastaConsumo: factura.fechaHastaConsumo,
                datasetProducto: factura.datasetProducto,
                EnergiaActiva: ["0"],
                Potencia: ["0"],
                potenciaP1: factura.potenciaP1,
                potenciaP2: factura.potenciaP2,


                energiaActivaP1 : factura.energiaActivaP1,
                energiaActivaP2 : factura.energiaActivaP2,



                idServicio: factura.idServicio,
                nombreServicio : factura.nombreServicio,
                precioServicio : factura.precioServicio,
                idComercializadora: factura.idComercializadora,


            },
            totalComparacion:{
                ...ahorro
            }
        },this.stateOutput)
        */
        // console.log('VALOOOOOR DE this.state')
        // console.log(this.state)
    }

    public stateOutput()
    {
       let { formActual,
         //formMulti,
         formMultiProducto, producto, itemsTabla, costeOperativo, totalComparacion, periods } = this.state;
        // let { formActual, itemsTabla, costeOperativo, periods,producto} = this.state;
        // console.log("PRODUCT");
        // console.log("selected",this.state.productSelected)
        ;
        console.log("map",this.state);
        let totalComp;
        let periodos;
        let save: boolean = false;
        if(this.state.productSelected !== null){
            //if para asegurar de pasar un solo producto: el seleccionado
            producto = Object.values(this.state.producto).find((item) => item.id === this.state.productSelected[0]);
            if(producto !== undefined) {
            let index:string = Object.keys(this.state.producto).find(key => this.state.producto[key] === producto)!;
            //console.log('VALORE DE INDEX')
            //console.log(index)
            //console.log('VALORE DE Producto')
            //console.log(producto)


            console.log('VALORES EN STATEOUTPUT ------> cuando se salva y se accede a continaucion')
            console.log(this.state.totalComparacion[parseInt(index)])

            totalComp = this.state.totalComparacion[parseInt(index)];


            //console.log(totalComp.datosAhorro)
            //delete totalComp.datosAhorro;
            save = true;
          }
            //periodos
            periodos = itemsTabla.filter(item => {
                if(item.id === this.state.productSelected[0]){
                    return item
                }
            });
            //console.log(periodos);
            formMultiProducto = Object.values(this.state.formMulti).find((item) => item.id === this.state.productSelected[0]);
            //console.log('VALORES DE formMultiProducto en stateOutput ------> ', formMultiProducto)
        }

        this.props.output(
            {
                actual: {
                  datos: formActual,
                  datosAdicionales:{
                        serviciosAdicionalesMonth: formActual.serviciosAdicionalesMonth,
                        serviciosAdicionales: formActual.serviciosAdicionales,
                        alquilerEquipoMedida:formActual.alquilerEquipoMedida,
                        codigoATR:formActual.codigoATR,
                        sumPotencia:formActual.sumPotencia,
                        sumEnergia:formActual.sumEnergia,
                        iePercent:formActual.iePercent,
                        impuestoElectrico:formActual.impuestoElectrico,
                        iva:formActual.iva,
                        bi:formActual.bi,
                        total:formActual.total

                    }
              },
                producto: {
                    datos: formMultiProducto,
                    producto: producto,//Object.values(this.state.producto).find((item) => item.id === this.state.productSelected[0]),
                    itemsPeriodos: periodos,
                    periodos: periods,
                    costeOperativo: costeOperativo,
                    totalComparacion: totalComp,
              },
              enabledSave: save
            }
        );
    }

    public renderFacturaActual = ( ) => {

        let factura: any = this.calculatePrecioActual( this.state.periods, this.state.formActual );

        let valorlongitud= Object.keys(this.state.producto).length
        let valorlongitudMulti = Object.keys(this.state.formMulti).length
        console.log('VALOR LONGITUD en renderFacturaActual----> ', valorlongitud)
        console.log('VALOR LONGITUDMulti en renderFacturaActual----> ', valorlongitudMulti)
        console.log('VALOR DE this.state.formMulti ---------->', this.state.formMulti)
        let newAhorroTabla:any[] = [];
        console.log("prova",this.state,valorlongitud,valorlongitudMulti);
        if (this.state && valorlongitud > 0 && valorlongitudMulti !== 43){
            // console.log('renderFacturaActual ----> dentro de this.state.producto.length > 0')
            Object.keys(this.state.formMulti).map ( (item, i) => {
                // console.log('VALOR DE ROW en renderFacturaActual---->', this.state.formMulti[item])

                let ahorro: any = this.calculateAhorroMulti( this.state.periods, this.state.formActual, this.state.formMulti[item] );

                newAhorroTabla.push(ahorro)
          return item;
     });

      let codigoATR:any = this.state.sip.codigoPeajeEnVigor;
        // console.log('ESTOY DENTRO DE RENDERFACTURALACTUAL')
        // console.log(this.stateOutput)
        // console.log(this.props)

        this.setState({
            formActual: {
                ...this.state.formActual,
                serviciosAdicionales: factura.serviciosAdicionales,
                serviciosAdicionalesMonth: factura.serviciosAdicionalesMonth,
                alquilerEquipoMedida: factura.alquilerEquipoMedida,
                alquilerEquipoMedidaMonth: factura.alquilerEquipoMedidaMonth,
                codigoATR: codigoATR,
                sumPotencia: factura.sumPotencia,
                sumEnergia: factura.sumEnergia,
                iePercent: factura.iePercent,
                impuestoElectrico: factura.impuestoElectrico,
                iva: factura.iva,
                bi: factura.bi,
                total: factura.total,

                numDaysNew: factura.numDaysNew,
                numMonthNew: factura.numMonthNew,
                fechaDesdeConsumo: factura.fechaDesdeConsumo,
                fechaHastaConsumo: factura.fechaHastaConsumo,
                descuento: factura.descuento,
                descuentoMonth: factura.descuentoMonth,

            },
           totalComparacion:newAhorroTabla
//                ...ahorro
//            }
        },this.stateOutput);

    }
  }

    private onChangeField = async ( event ) => {

        const name = event.target.name;
        let value = event.target.value;

        let inputs = {};
        if ( value !== "" || value !== null)
        {
            inputs[name] = Number(value);
            return inputs;

        }
        return false;
    }

    selectedItem = async (event) => {
        //console.log("itemSelected",event);
        this.setState({
          productSelected: event,
        });
      };


	render() {

        const itemsTabla = this.state.itemsTabla;
        //const precioServicio = this.state.precioServicio;
        const totalComparacion = this.state.totalComparacion;
        const producto = this.state.producto;
        const formActual = this.state.formActual;

        //console.log("STATE",this.state);

let valorlongitud= Object.keys(this.state.periods).length
var multiFields:any={}

  //console.log('entra en multifields de 3P')
         multiFields = {
            oferta:'Oferta',
            P1potencia: 'Termino Fijo €/mes',
            //P2potencia: 'P2 Potencia',
            sumPotencia: 'Total Termino Fijo',
            P1energia: 'Precio Energia P1 kWh',
            P2energia: 'Precio Energia P2 kWh',
            sumEnergia: 'Total Energia €',
            ahorroAnual:'Ahorro Anual',
            ahorroPercent:'Ahorro Percent',
            ahorroPeriodo:'Ahorro Periodo'
        }



        ///console.log("DAYS",this.state.formMulti);
        //console.log('VALOR DE valorlongitud ---------->',valorlongitud)

var multiTableData:any =[];
  if (valorlongitud <6){
          console.log('entra en multitabla de 3P')
          console.log('VAalor de this.state ------------->', this.state)

        multiTableData = this.state.totalComparacion.map((row, index) => {
            return {
                oferta: this.state.producto[index].nombreOferta,
                id: this.state.producto[index].id,
                P1potencia: this.state.itemsTabla.find(element => element.id == this.state.producto[index].id && element.periodo == 'P1').precioPotencia,
                P1energia: this.state.itemsTabla.find(element => element.id == this.state.producto[index].id && element.periodo == 'P1').precioEnergia,
                //P2potencia: this.state.itemsTabla.find(element => element.id == this.state.producto[index].id && element.periodo == 'P2').precioPotencia,
                //P2energia: this.state.itemsTabla.find(element => element.id == this.state.producto[index].id && element.periodo == 'P2').precioEnergia,
                ahorroAnual: row['ahorroAnual']+' €',
                ahorroPercent: row['ahorroPercent'] + ' %',
                ahorroPeriodo: this.state.formActual? (row['ahorroPeriodo']*(this.state.formActual.numDaysNew/365)).toFixed(2)+' €' : null,
                sumPotencia: this.state.formMulti[index]? this.state.formMulti[index].sumPotencia : null,
                sumEnergia: this.state.formMulti[index]? this.state.formMulti[index].sumEnergia : null,
            }
        });
      }else{
        //console.log('entra en multitabla de 6P')
         multiTableData = this.state.totalComparacion.map((row, index) => {
            return {
                oferta: this.state.producto[index].nombreOferta,
                id: this.state.producto[index].id,
                P1potencia: this.state.itemsTabla.find(element => element.id == this.state.producto[index].id && element.periodo == 'P1').precioPotencia,
                P1energia: this.state.itemsTabla.find(element => element.id == this.state.producto[index].id && element.periodo == 'P1').precioEnergia,
                P2potencia: this.state.itemsTabla.find(element => element.id == this.state.producto[index].id && element.periodo == 'P2').precioPotencia,
                P2energia: this.state.itemsTabla.find(element => element.id == this.state.producto[index].id && element.periodo == 'P2').precioEnergia,
                P3potencia: this.state.itemsTabla.find(element => element.id == this.state.producto[index].id && element.periodo == 'P3').precioPotencia,
                P3energia: this.state.itemsTabla.find(element => element.id == this.state.producto[index].id && element.periodo == 'P3').precioEnergia,
                P4potencia: this.state.itemsTabla.find(element => element.id == this.state.producto[index].id && element.periodo == 'P4').precioPotencia,
                P4energia: this.state.itemsTabla.find(element => element.id == this.state.producto[index].id && element.periodo == 'P4').precioEnergia,
                P5potencia: this.state.itemsTabla.find(element => element.id == this.state.producto[index].id && element.periodo == 'P5').precioPotencia,
                P5energia: this.state.itemsTabla.find(element => element.id == this.state.producto[index].id && element.periodo == 'P5').precioEnergia,
                P6potencia: this.state.itemsTabla.find(element => element.id == this.state.producto[index].id && element.periodo == 'P6').precioPotencia,
                P6energia: this.state.itemsTabla.find(element => element.id == this.state.producto[index].id && element.periodo == 'P6').precioEnergia,
                ahorroAnual: row['ahorroAnual']+' €',
                ahorroPercent: row['ahorroPercent'] + ' %',
                ahorroPeriodo: this.state.formActual? (row['ahorroPeriodo']*(this.state.formActual.numDaysNew/365)).toFixed(2)+' €' : null,
                sumPotencia: this.state.formMulti[index]? this.state.formMulti[index].sumPotencia : null,
                sumEnergia: this.state.formMulti[index]? this.state.formMulti[index].sumEnergia : null,
            }
        });

      }
        // console.log("multiTableData",multiTableData);
        multiTableData.sort((a, b) => parseFloat(b.ahorroAnual) - parseFloat(a.ahorroAnual));
        console.log('VALOR DE MULTITABLA')
        console.log(multiTableData);

        //if (this.state.sip.NumeroPeriodos==="3"){
        //var periodos = [1,2,3]} else {
        var periodos = this.state.periods;
      //};

		return (
			<>
            <Row className="margin-reset w-100">
                    <Card className="mt-3 mb-3 shadow card-billing-template">
                    <CardBody>
                        <Row className="">
                            <h2 className="col item-header">
                                Ahorros por cada uno de los productos seleccionados
                            </h2>
                        </Row>
                        {/* <div>
                                {
                                totalComparacion.map((row, index) => (
                                    <p key={index}> {index} {row['nombreOferta']} {row['ahorroAnual']} {row['ahorroPercent']} {row['ahorroPeriodo']}</p>
                                ))
                            }
                        </div> */}
                        {/* <Table>
                            <thead>
                                <tr>
                                <th></th>
                                <th>Oferta</th>
                                <th>P1</th>
                                <th>P2</th>
                                <th>P3</th>
                                <th>Precio Potencia</th>
                                <th>Precio Energia</th>
                                <th>Ahorro Anual</th>
                                <th>Ahorro Percent</th>
                                <th>Ahorro Periodo</th>
                                </tr>
                            </thead>
                            <tbody>
                            {
                                multiTableData.map((row, index) => (
                                    <tr key={index}>
                                        <td><Input type="radio" value={row['id']} checked={this.state.producto[index].selected} onClick={this.productoChanged}/></td>
                                        <td>{row['oferta']}</td>
                                        <td>{row['P1']}</td>
                                        <td>{row['P2']}</td>
                                        <td>{row['P3']}</td>
                                        <td>{row['precioPotencia']}</td>
                                        <td>{row['precioEnergia']}</td>
                                        <td>{row['ahorroAnual']} €</td>
                                        <td>{row['ahorroPercent']} %</td>
                                        <td>{row['ahorroPeriodo']} €</td>
                                    </tr>
                                ))
                            }
                            </tbody>
                        </Table> */}
                        <SipsTable
                            checkBoxButton={true}
                            hideViewMoreButton={true}
                            items={multiTableData}
                            fields={multiFields}
                            selectedItem={this.selectedItem}
                            expandableFields={false}
                        ></SipsTable>
                    </CardBody>
                    </Card>
                </Row>


                <Row className="margin-reset w-100">
                    <Card className="mt-3 mb-3 shadow card-billing-template">
                        <Form innerRef={this.formActualRef}>
                        <CardBody>
                            <Row className="">
                                <h2 className="col item-header">
                                    Simulación factura comercializadora Gas actual
                                </h2>
                            </Row>
                            <Row>
                            <Table className="table-actual align-items-center table-flush" responsive key="table-data">
                                <thead>
                                    <th>Periodo Lectura</th>
                                    <th>Precio Término Energía €/kWh</th>
                                    <th>Precio Término Fijo €/mes</th>
                                </thead>
                                <tbody>
                                    {periodos.includes(1)?
                                    <tr>
                                        <td>P1</td>
                                        <td>
                                            <div className="input-control">
                                                <Input type="number" step="0.01" id="precioTEP1" name="precioTEP1" onChange={this.onChangeFieldFormActual} value={formActual.precioTEP1}/>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="input-control">
                                                <Input type="number" step="0.01" id="precioTP1" name="precioTP1" onChange={this.onChangeFieldFormActual} value={formActual.precioTP1}/>
                                            </div>
                                        </td>
                                    </tr>
                                    :''}
                                    {periodos.includes(2)?
                                    <tr>
                                        <td>P2</td>
                                        <td>
                                            <div className="input-control">
                                                <Input type="number" step="0.01" id="precioTEP2" name="precioTEP2" onChange={this.onChangeFieldFormActual} value={formActual.precioTEP2}/>

                                            </div>
                                        </td>
                                        <td>
                                            <div className="input-control">
                                                <Input type="number" step="0.01" id="precioTP2" name="precioTP2" onChange={this.onChangeFieldFormActual} value={formActual.precioTP2}/>

                                            </div>
                                        </td>
                                    </tr>
                                    :''}

                                </tbody>
                            </Table>
                            </Row>
                            <Row className="panel-data-resolve">
                                <div className="col">
                                    <div className="item-data">
                                        <h5 className="">Término Energia {formActual.numMonthNew} meses</h5>
                                        <div className="">{formActual.sumEnergia}€</div>
                                    </div>


                                    <div className="item-data">
                                        <h5 className="">Descuento sobre consumo Energía</h5>
                                        <div className="">
                                            <input type="number" step="1.0" name="descuentoMonth" onChange={this.onChangeFieldFormActual} value={formActual.descuentoMonth}></input>€

                                          </div>
                                         <h5 className="">Total Descuentos</h5>
                                        <div>{formActual.descuento}%</div>
                                        </div>

                                    <div className="item-data">
                                        <h5 className="">I.E. Hidrocarburos 0,00234 €/kWh</h5>
                                        <div className="">{formActual.iePercent}€</div>
                                    </div>
                                    <div className="item-data">
                                        <h5 className="">Servicios Adicionales por mes</h5>
                                        <div className="">
                                            <input type="number" step="0.01" name="serviciosAdicionalesMonth" onChange={this.onChangeFieldFormActual} value={formActual.serviciosAdicionalesMonth}></input>€

                                          </div>
                                         <h5 className="">Total Servicios</h5>
                                        <div>{formActual.serviciosAdicionales}€</div>
                                        </div>

                                    <div className="item-data">
                                        <h5 className="">IVA 21%</h5>
                                        <div className="">{formActual.iva}€</div>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="item-data">
                                        <h5>Término Fijo {formActual.numMonthNew} meses</h5>
                                        <div className="">{formActual.sumPotencia}€</div>
                                    </div>

                                    <div className="item-data">
                                        <h5 className="">Base Impuesto Eléctrico</h5>
                                        <div className="">{formActual.impuestoElectrico}€</div>
                                    </div>
                                    <div className="item-data">
                                        <h5 className="">Alquiler del Equipo a Medida por mes</h5>
                                        <div className="">
                                        <input type="number" step="0.01" name="alquilerEquipoMedidaMonth" onChange={this.onChangeFieldFormActual} value={formActual.alquilerEquipoMedidaMonth}></input>€
                                        </div>
                                           <h5 className="">Total Alquiler</h5>
                                        <div>{formActual.alquilerEquipoMedida}€</div>
                                    </div>


                                    <div className="item-data">
                                        <h5 className="">BASE IMPONIBLE</h5>
                                        <div className="">{formActual.bi}€</div>
                                    </div>
                                </div>
                            </Row>
                            <Row className="">
                                <div className="col item-total">
                                    <h2>
                                        TOTAL
                                    </h2>
                                    <div>
                                        {formActual.total}€
                                    </div>
                            </div>
                            </Row>







                        </CardBody>
                        </Form>
                    </Card>

                </Row>



			</>
		);
	}
}
