import React from "react";
// reactstrap components
import { Card, Container, Row, CardTitle, CardBody, Col } from "reactstrap";

import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

import LoadingOverlay from "react-loading-overlay";
import { CSVLink } from "react-csv";

// core components
import Header from "../../components/Headers/Header";

import { withApollo } from "react-apollo";
import {
  VIEW_SIPS_CONSUMOS_TOTAL,
  LIST_SIPS_CONSUMOS,
} from "../../queries/sips-consumo/sip-consumo";
import { VIEW_SIPS } from "../../queries/sips/sips.graphql";

import SipsTable from "../../components/Sips/SipsTable";
import moment from "moment";
import * as echarts from 'echarts'
import ReactECharts from 'echarts-for-react';

echarts.registerTheme('roma', {
        "color": [
            "#e01f54",
            "#001852",
            "#f5e8c8",
            "#b8d2c7",
            "#c6b38e",
            "#a4d8c2",
            "#f3d999",
            "#d3758f",
            "#dcc392",
            "#2e4783",
            "#82b6e9",
            "#ff6347",
            "#a092f1",
            "#0a915d",
            "#eaf889",
            "#6699FF",
            "#ff6666",
            "#3cb371",
            "#d5b158",
            "#38b6b6"
        ],
        "backgroundColor": "rgba(0,0,0,0)",
        "textStyle": {},
        "title": {
            "textStyle": {
                "color": "#333333"
            },
            "subtextStyle": {
                "color": "#aaaaaa"
            }
        },
        "line": {
            "itemStyle": {
                "borderWidth": 1
            },
            "lineStyle": {
                "width": 2
            },
            "symbolSize": 4,
            "symbol": "emptyCircle",
            "smooth": false
        },
        "radar": {
            "itemStyle": {
                "borderWidth": 1
            },
            "lineStyle": {
                "width": 2
            },
            "symbolSize": 4,
            "symbol": "emptyCircle",
            "smooth": false
        },
        "bar": {
            "itemStyle": {
                "barBorderWidth": 0,
                "barBorderColor": "#ccc"
            }
        },
        "pie": {
            "itemStyle": {
                "borderWidth": 0,
                "borderColor": "#ccc"
            }
        },
        "scatter": {
            "itemStyle": {
                "borderWidth": 0,
                "borderColor": "#ccc"
            }
        },
        "boxplot": {
            "itemStyle": {
                "borderWidth": 0,
                "borderColor": "#ccc"
            }
        },
        "parallel": {
            "itemStyle": {
                "borderWidth": 0,
                "borderColor": "#ccc"
            }
        },
        "sankey": {
            "itemStyle": {
                "borderWidth": 0,
                "borderColor": "#ccc"
            }
        },
        "funnel": {
            "itemStyle": {
                "borderWidth": 0,
                "borderColor": "#ccc"
            }
        },
        "gauge": {
            "itemStyle": {
                "borderWidth": 0,
                "borderColor": "#ccc"
            }
        },
        "candlestick": {
            "itemStyle": {
                "color": "#e01f54",
                "color0": "#001852",
                "borderColor": "#f5e8c8",
                "borderColor0": "#b8d2c7",
                "borderWidth": 1
            }
        },
        "graph": {
            "itemStyle": {
                "borderWidth": 0,
                "borderColor": "#ccc"
            },
            "lineStyle": {
                "width": 1,
                "color": "#aaaaaa"
            },
            "symbolSize": 4,
            "symbol": "emptyCircle",
            "smooth": false,
            "color": [
                "#e01f54",
                "#001852",
                "#f5e8c8",
                "#b8d2c7",
                "#c6b38e",
                "#a4d8c2",
                "#f3d999",
                "#d3758f",
                "#dcc392",
                "#2e4783",
                "#82b6e9",
                "#ff6347",
                "#a092f1",
                "#0a915d",
                "#eaf889",
                "#6699FF",
                "#ff6666",
                "#3cb371",
                "#d5b158",
                "#38b6b6"
            ],
            "label": {
                "color": "#eeeeee"
            }
        },
        "map": {
            "itemStyle": {
                "areaColor": "#eeeeee",
                "borderColor": "#444444",
                "borderWidth": 0.5
            },
            "label": {
                "color": "#000000"
            },
            "emphasis": {
                "itemStyle": {
                    "areaColor": "rgba(255,215,0,0.8)",
                    "borderColor": "#444",
                    "borderWidth": 1
                },
                "label": {
                    "color": "rgb(100,0,0)"
                }
            }
        },
        "geo": {
            "itemStyle": {
                "areaColor": "#eeeeee",
                "borderColor": "#444444",
                "borderWidth": 0.5
            },
            "label": {
                "color": "#000000"
            },
            "emphasis": {
                "itemStyle": {
                    "areaColor": "rgba(255,215,0,0.8)",
                    "borderColor": "#444",
                    "borderWidth": 1
                },
                "label": {
                    "color": "rgb(100,0,0)"
                }
            }
        },
        "categoryAxis": {
            "axisLine": {
                "show": true,
                "lineStyle": {
                    "color": "#333"
                }
            },
            "axisTick": {
                "show": true,
                "lineStyle": {
                    "color": "#333"
                }
            },
            "axisLabel": {
                "show": true,
                "color": "#333"
            },
            "splitLine": {
                "show": false,
                "lineStyle": {
                    "color": [
                        "#ccc"
                    ]
                }
            },
            "splitArea": {
                "show": false,
                "areaStyle": {
                    "color": [
                        "rgba(250,250,250,0.3)",
                        "rgba(200,200,200,0.3)"
                    ]
                }
            }
        },
        "valueAxis": {
            "axisLine": {
                "show": true,
                "lineStyle": {
                    "color": "#333"
                }
            },
            "axisTick": {
                "show": true,
                "lineStyle": {
                    "color": "#333"
                }
            },
            "axisLabel": {
                "show": true,
                "color": "#333"
            },
            "splitLine": {
                "show": true,
                "lineStyle": {
                    "color": [
                        "#ccc"
                    ]
                }
            },
            "splitArea": {
                "show": false,
                "areaStyle": {
                    "color": [
                        "rgba(250,250,250,0.3)",
                        "rgba(200,200,200,0.3)"
                    ]
                }
            }
        },
        "logAxis": {
            "axisLine": {
                "show": true,
                "lineStyle": {
                    "color": "#333"
                }
            },
            "axisTick": {
                "show": true,
                "lineStyle": {
                    "color": "#333"
                }
            },
            "axisLabel": {
                "show": true,
                "color": "#333"
            },
            "splitLine": {
                "show": true,
                "lineStyle": {
                    "color": [
                        "#ccc"
                    ]
                }
            },
            "splitArea": {
                "show": false,
                "areaStyle": {
                    "color": [
                        "rgba(250,250,250,0.3)",
                        "rgba(200,200,200,0.3)"
                    ]
                }
            }
        },
        "timeAxis": {
            "axisLine": {
                "show": true,
                "lineStyle": {
                    "color": "#333"
                }
            },
            "axisTick": {
                "show": true,
                "lineStyle": {
                    "color": "#333"
                }
            },
            "axisLabel": {
                "show": true,
                "color": "#333"
            },
            "splitLine": {
                "show": true,
                "lineStyle": {
                    "color": [
                        "#ccc"
                    ]
                }
            },
            "splitArea": {
                "show": false,
                "areaStyle": {
                    "color": [
                        "rgba(250,250,250,0.3)",
                        "rgba(200,200,200,0.3)"
                    ]
                }
            }
        },
        "toolbox": {
            "iconStyle": {
                "borderColor": "#999999"
            },
            "emphasis": {
                "iconStyle": {
                    "borderColor": "#666666"
                }
            }
        },
        "legend": {
            "textStyle": {
                "color": "#333333"
            }
        },
        "tooltip": {
            "axisPointer": {
                "lineStyle": {
                    "color": "#cccccc",
                    "width": 1
                },
                "crossStyle": {
                    "color": "#cccccc",
                    "width": 1
                }
            }
        },
        "timeline": {
            "lineStyle": {
                "color": "#293c55",
                "width": 1
            },
            "itemStyle": {
                "color": "#293c55",
                "borderWidth": 1
            },
            "controlStyle": {
                "color": "#293c55",
                "borderColor": "#293c55",
                "borderWidth": 0.5
            },
            "checkpointStyle": {
                "color": "#e43c59",
                "borderColor": "#c23531"
            },
            "label": {
                "color": "#293c55"
            },
            "emphasis": {
                "itemStyle": {
                    "color": "#a9334c"
                },
                "controlStyle": {
                    "color": "#293c55",
                    "borderColor": "#293c55",
                    "borderWidth": 0.5
                },
                "label": {
                    "color": "#293c55"
                }
            }
        },
        "visualMap": {
            "color": [
                "#e01f54",
                "#e7dbc3"
            ]
        },
        "dataZoom": {
            "backgroundColor": "rgba(47,69,84,0)",
            "dataBackgroundColor": "rgba(47,69,84,0.3)",
            "fillerColor": "rgba(167,183,204,0.4)",
            "handleColor": "#a7b7cc",
            "handleSize": "100%",
            "textStyle": {
                "color": "#333333"
            }
        },
        "markPoint": {
            "label": {
                "color": "#eeeeee"
            },
            "emphasis": {
                "label": {
                    "color": "#eeeeee"
                }
            }
        }
    });



const highPriorityItemsFields = {
  cups: "CUPS",
  nombreCompletoTitular: "Titular",
  nombreEmpresaDistribuidora: "Distribuidora Principal",
  empresaDistribuidora: "Distribuidora",
  comercializadora: "Comercializadora",
  direccion_ps: "Dirección",
  localidad_ps: "Localidad",
  codigoPostalPS: "Código Postal",
  nombreProvinciaPS: "Provincia",
  tarifaATR: "Descripción Tarifa",
  kWhAnual: "kWh Anual",
  fechaUltimoMovimientoContrato: "Fecha Último Movimiento",
  fechaUltimoCambioComercializador: "Fecha Último Cambio Comercializadora",
};
const lowPriorityItemsFields = {
  CIF: "CIF",
  telefono: "Teléfono",
  contactoTitular: "Contacto del titular",
  codigoEmpresaDistribuidora: "Cód. Distribuidora",
  codeComercializadora: "Cód. Comer",
  fechaAltaSuministro: "Fecha Alta",
  codigoTarifaATREnVigor: "Tarifa",
  Tension: "Tensión",
  potenciaMaximaBIEW: "Potencia Máxima BIE",
  potenciaMaximaAPMW: "Potencia Máxima Puesta en Marcha",
  codClasificacionPM: "Tipo PM",
  codigoDisponibilidadICP: "Indicativo ICP",
  perfilConsumo: "Perfil Consumo",
  valorDerechosExtensionW: "Derechos Extension",
  valorDerechosAccesoW: "Derechos Acceso Llano",
  derechosAccesoValle: "Derechos Acceso Valle",
  proEquipoMedida: "Propiedad Equipo Medida",
  propiedadICP: "Propiedad ICP",
  potenciasContratadasEnWP1: "Potencia Contratada Kw P1",
  potenciasContratadasEnWP2: "Potencia Contratada Kw P2",
  potenciasContratadasEnWP3: "Potencia Contratada Kw P3",
  potenciasContratadasEnWP4: "Potencia Contratada Kw P4",
  potenciasContratadasEnWP5: "Potencia Contratada Kw P5",
  potenciasContratadasEnWP6: "Potencia Contratada Kw P6",
  fechaLimiteExtension: "Fecha Límite Extensión",
  fechaUltimaLectura: "Fecha Última Lectura",
  cortes: "Cortes",
  fianza: "Fianza",
  perfilPersona: "Persona",
  esViviendaHabitual: "Primera Vivienda",
  informacionImpagos: "Impago",
  tipoTelegestion: "Telegestionado Activo",
  trimestreCambioContador: "Trimestre Cambio Contador",
  codigoAutoconsumo: "Cod. Autoconsumo",
  ConsumoAnualTotalActiva: "Total Consumo Año Actual Kw",
  ConsumoAnualActivaP1: "Consumo anual Kw P1",
  ConsumoAnualActivaP2: "Consumo anual Kw P2",
  ConsumoAnualActivaP3: "Consumo anual Kw P3",
  ConsumoAnualActivaP4: "Consumo anual Kw P4",
  ConsumoAnualActivaP5: "Consumo anual Kw P5",
  ConsumoAnualActivaP6: "Consumo anual Kw P6",
  ConsumoAnualActivaP1Porcentaje: "Consumo anual P1 (%)",
  ConsumoAnualActivaP2Porcentaje: "Consumo anual P2 (%)",
  ConsumoAnualActivaP3Porcentaje: "Consumo anual P3 (%)",
  ConsumoAnualActivaP4Porcentaje: "Consumo anual P4 (%)",
  ConsumoAnualActivaP5Porcentaje: "Consumo anual P5 (%)",
  ConsumoAnualActivaP6Porcentaje: "Consumo anual P6 (%)",
  ConsumoAnualTotalActivaAnterior: "total Consumo Año Anterior Kw",
  ConsumoAnualActivaP1Anterior: "Consumo año anterior Kw P1",
  ConsumoAnualActivaP2Anterior: "Consumo año anterior Kw P2",
  ConsumoAnualActivaP3Anterior: "Consumo año anterior Kw P3",
  ConsumoAnualActivaP4Anterior: "Consumo año anterior Kw P4",
  ConsumoAnualActivaP5Anterior: "Consumo año anterior Kw P5",
  ConsumoAnualActivaP6Anterior: "Consumo año anterior Kw P6",
  CNAE: "CNAE",
  actividadCNAE: "Desc. CNAE",
  totalEnergiaPuntosGWHAnual: "Total energia Puntos GWh Anual",
  totalPuntos: "Total Puntos",
  precioExcesoPotencia: "Precio Exc. Potencia",
  precioOptimizacionReactiva: "Precio Opt. Reactiva",
  reactivaPotencia: "Reactiva	Total Optimización \n Potencia / Reactiva",
  aplicacionBonoSocial: "Bono Social",
};

const sipsConsumosTotalFields = {
  year: "Año",
  codigoTarifaATR: "Codigo tarifa",
  ConsumoAnualActivaP1: "kWhP1",
  ConsumoAnualActivaP2: "kWhP2",
  ConsumoAnualActivaP3: "kWhP3",
  ConsumoAnualActivaP4: "kWhP4",
  ConsumoAnualActivaP5: "kWhP5",
  ConsumoAnualActivaP6: "kWhP6",
  ConsumoAnualTotalActiva: "Activa total",
  ConsumoAnualReactivaP1: "kVArhP1",
  ConsumoAnualReactivaP2: "kVArhP2",
  ConsumoAnualReactivaP3: "kVArhP3",
  ConsumoAnualReactivaP4: "kVArhP4",
  ConsumoAnualReactivaP5: "kVArhP5",
  ConsumoAnualReactivaP6: "kVArhP6",
  ConsumoAnualTotalReactiva: "Reactiva total",
  PotenciaDemandadaP1: "Demanda kWP1",
  PotenciaDemandadaP2: "Demanda kWP2",
  PotenciaDemandadaP3: "Demanda kWP3",
  PotenciaDemandadaP4: "Demanda kWP4",
  PotenciaDemandadaP5: "Demanda kWP5",
  PotenciaDemandadaP6: "Demanda kWP6",
  PotenciaDemandadaTotal: "Demanda total",
};

const sipsConsumosTotalExpandableFields = {};

const sipsConsumosFields = {
  fechaFinMesConsumo: "Fecha",
  codigoTarifaATR: "Codigo ATR",
  consumoEnergiaActivaEnWhP1: "Energia Activa kWhP1",
  consumoEnergiaActivaEnWhP2: "Energia Activa kWhP2",
  consumoEnergiaActivaEnWhP3: "Energia Activa kWhP3",
  consumoEnergiaActivaEnWhP4: "Energia Activa kWhP4",
  consumoEnergiaActivaEnWhP5: "Energia Activa kWhP5",
  consumoEnergiaActivaEnWhP6: "Energia Activa kWhP6",
  consumoEnergiaReactivaEnVArhP1: "Energia Reactiva kVArhP1",
  consumoEnergiaReactivaEnVArhP2: "Energia Reactiva kVArhP2",
  consumoEnergiaReactivaEnVArhP3: "Energia Reactiva kVArhP3",
  consumoEnergiaReactivaEnVArhP4: "Energia Reactiva kVArhP4",
  consumoEnergiaReactivaEnVArhP5: "Energia Reactiva kVArhP5",
  consumoEnergiaReactivaEnVArhP6: "Energia Reactiva kVArhP6",
  potenciaDemandadaEnWP1: "Potencia demandada kWP1",
  potenciaDemandadaEnWP2: "Potencia demandada kWP2",
  potenciaDemandadaEnWP3: "Potencia demandada kWP3",
  potenciaDemandadaEnWP4: "Potencia demandada kWP4",
  potenciaDemandadaEnWP5: "Potencia demandada kWP5",
  potenciaDemandadaEnWP6: "Potencia demandada kWP6",
  codigoDHEquipoDeMedida: "Codigo Equipo",
  codigoTipoLectura: "Codigo tipo lectura",
};

const sipsConsumosExpandableFields = {};

const monthTickFormatter = (tick) => {
  const date = new Date(tick);
  return (date.getMonth() + 1).toString();

};

const renderQuarterTick = (tickProps) => {
  const { x, y, payload } = tickProps;
  const { value, offset } = payload;
  const date = new Date(value);
  const month = date.getMonth();
  const quarterNo = Math.floor(month / 3) + 1;
  const isMidMonth = month % 3 === 1;

  if (month % 3 === 1) {
    return <text x={x} y={y - 4} textAnchor="middle">{`Q${quarterNo}`}</text>;
  }

  const isLast = month === 11;

  if (month % 3 === 0 || isLast) {
    const pathX = Math.floor(isLast ? x + offset : x - offset) + 0.5;

    return <path d={`M${pathX},${y - 4}v${-35}`} stroke="red" />;
  }
  return <text x={x} y={y - 4} ></text>;
};

class SipDetails extends React.Component<any, any> {
  lastFilters = {};
  state = {
    isLoading: false,
    copiedText: null,
    currentPage: 0,
    pageSize: 0,
    pageCount: 0,
    count: 0,
    cups: "",
    page: 1,
    limit: 10,
    sipsConsumosTotal: [],
    sipsConsumos: [],
    sipsDetail: [],
consumosMesTotal:[{date: "", consumo:0}],
    isLoadingSips: true,
    isLoadingConsumos: true,
    isLoadingConsumosTotales: true,
    paginatedData: {
      items: [],
    },
  };

  sipsCSVData: any = [];
  viewSipsConsumoAnualCSVData: any = [];
  sipsConsumosCSVData: any = [];
  sipstotal: any = [];

  componentDidMount() {
    const {
      match: { params },
    } = this.props;
    this.setState({ cups: params.cups }, this.fetchItems);
  }

  fetchItems = async () => {
    // Sips
    await this.props.client
      .query({
        query: VIEW_SIPS,
        variables: { cups: this.state.cups },
      })
      .then((result) => {
        this.setState({ sipsDetail: result.data.viewSips });
        this.setState({ isLoadingSips: false });
        if (result.data.viewSips) {
          const sipsCSVHeader: any = Object.values(
            highPriorityItemsFields
          ).concat(Object.values(lowPriorityItemsFields)) as any;
          const sipsFields = Object.keys({
            ...highPriorityItemsFields,
            ...lowPriorityItemsFields,
          });

          const csvArray = result.data.viewSips.map((o) => {
            return sipsFields.map((field) => {
              if (field.includes("fecha") && o[field]) {
                const dateValue = new Date(o[field]);

                o[field] = moment(dateValue).format("DD-MM-YYYY");
              }
              return o[field];
            });
          });
          this.sipsCSVData = [];
          this.sipsCSVData = this.sipsCSVData.concat([["Datos de suministro"]]);
          this.sipsCSVData = this.sipsCSVData.concat([[]]);
          this.sipsCSVData = this.sipsCSVData.concat([[""]]);
          this.sipsCSVData = this.sipsCSVData.concat([[]]);
          this.sipsCSVData = this.sipsCSVData.concat([sipsCSVHeader]);
          this.sipsCSVData = this.sipsCSVData.concat(csvArray);
        }
      });
    // Sips Consumos Total
    await this.props.client
      .query({
        query: VIEW_SIPS_CONSUMOS_TOTAL,
        variables: { cups: this.state.cups },
      })
      .then((result) => {
        this.setState({ sipsConsumosTotal: result.data.viewSipsConsumoAnual });
        this.setState({ isLoadingConsumosTotales: false });
        if (result.data.viewSipsConsumoAnual) {
          const viewSipsConsumoAnualCSVHeader: any = Object.values(
            sipsConsumosTotalFields
          );
          const viewSipsConsumoAnualFields = Object.keys(
            sipsConsumosTotalFields
          );

          const csvArray = result.data.viewSipsConsumoAnual.map((o) => {
            return viewSipsConsumoAnualFields.map((field) => o[field]);
          });

          this.viewSipsConsumoAnualCSVData = [];
          this.viewSipsConsumoAnualCSVData = this.viewSipsConsumoAnualCSVData.concat(
            [[]]
          );
          this.viewSipsConsumoAnualCSVData = this.viewSipsConsumoAnualCSVData.concat(
            [[""]]
          );
          this.viewSipsConsumoAnualCSVData = this.viewSipsConsumoAnualCSVData.concat(
            [[]]
          );
          this.viewSipsConsumoAnualCSVData = this.viewSipsConsumoAnualCSVData.concat(
            [["Consumos totales"]]
          );
          this.viewSipsConsumoAnualCSVData = this.viewSipsConsumoAnualCSVData.concat(
            [[]]
          );
          this.viewSipsConsumoAnualCSVData = this.viewSipsConsumoAnualCSVData.concat(
            [[""]]
          );
          this.viewSipsConsumoAnualCSVData = this.viewSipsConsumoAnualCSVData.concat(
            [[]]
          );
          this.viewSipsConsumoAnualCSVData = this.viewSipsConsumoAnualCSVData.concat(
            [viewSipsConsumoAnualCSVHeader]
          );
          this.viewSipsConsumoAnualCSVData = this.viewSipsConsumoAnualCSVData.concat(
            csvArray
          );
        }
      });
    // Sips Consumos
    await this.props.client
      .query({
        query: LIST_SIPS_CONSUMOS,
        variables: { cups: this.state.cups },
      })
      .then((result) => {
        this.setState({ sipsConsumos: result.data.listSipsConsumos });
        this.setState({ isLoadingConsumos: false });
        if (result.data.listSipsConsumos) {
          const listSipsConsumosCSVHeader: any = Object.values(
            sipsConsumosFields
          );

          var consumosMeses:any[] = [];

          result.data.listSipsConsumos.map( row => {
            let  dateValueConsumo = row['year']+"-"+row['month'];
            let  consumosMesTotal = Number(row['consumoEnergiaActivaEnWhP1'])+Number(row['consumoEnergiaActivaEnWhP2'])+Number(row['consumoEnergiaActivaEnWhP3'])+Number(row['consumoEnergiaActivaEnWhP4'])+Number(row['consumoEnergiaActivaEnWhP5'])+Number(row['consumoEnergiaActivaEnWhP6'])
            consumosMeses.push({ date: dateValueConsumo, consumo:consumosMesTotal});
            return row

        })
            var consumosMesesReverse = [...consumosMeses].reverse();

            this.setState({ consumosMesTotal: consumosMesesReverse });



          const listSipsConsumosFields = Object.keys(sipsConsumosFields);

          const csvArray = result.data.listSipsConsumos.map((o) => {
            return listSipsConsumosFields.map((field) => {
              if (field.includes("fecha") && o[field]) {
                const dateValue = new Date(o[field]);
                o[field] = moment(dateValue).format("DD-MM-YYYY");
              }
              return o[field];
            });
          });




          this.sipsConsumosCSVData = [];
          this.sipsConsumosCSVData = this.sipsConsumosCSVData.concat([[]]);
          this.sipsConsumosCSVData = this.sipsConsumosCSVData.concat([[""]]);
          this.sipsConsumosCSVData = this.sipsConsumosCSVData.concat([[]]);
          this.sipsConsumosCSVData = this.sipsConsumosCSVData.concat([
            ["Consumos"],
          ]);
          this.sipsConsumosCSVData = this.sipsConsumosCSVData.concat([[]]);
          this.sipsConsumosCSVData = this.sipsConsumosCSVData.concat([[""]]);
          this.sipsConsumosCSVData = this.sipsConsumosCSVData.concat([[]]);
          this.sipsConsumosCSVData = this.sipsConsumosCSVData.concat([
            listSipsConsumosCSVHeader,
          ]);
          this.sipsConsumosCSVData = this.sipsConsumosCSVData.concat(csvArray);
          this.sipstotal = this.sipsCSVData;
          this.sipstotal = this.sipstotal.concat(
            this.viewSipsConsumoAnualCSVData
          );
          this.sipstotal = this.sipstotal.concat(this.sipsConsumosCSVData);
          this.forceUpdate();
        }
      });
  };

  onPageClick = (page) => {};

  export = () => {};

  render() {
    const disableExport =
      this.state.isLoadingSips ||
      this.state.isLoadingConsumos ||
      this.state.isLoadingConsumosTotales ||
      this.state.isLoadingSips;
    const disableExportClass = disableExport ? " disabled" : "";
    var option2 = {
        dataset: {
            dimensions: ['date', 'consumo'],
            source: this.state.consumosMesTotal
        },
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'shadow'
            }
        },
        grid: {
            containLabel: true
        },
        xAxis: {
            type: 'category'
        },
        yAxis: {},
        visualMap: {
            orient: 'horizontal',
            left: 'center',
            min: 10,
            max: 250,
            text: ['+', '-'],
            // Map the score column to color
            dimension: 1,
            inRange: {
                color: ['#65B581', '#FFCE34', '#FD665F']

            }
        },
        series: [
            {

                type: 'bar',
                barWidth: '60%'

            }
        ]
    };

    return (
      <>
        <Header title={'Suministro Eléctrico "' + this.state.cups + '"'} />
        <Container className="mt--7" fluid>
          <Row>
            <div className="col">
              <Card className="shadow">
                <CardBody>
                  <div className="text-left">
                    <button
                      className="btn btn-sm btn-primary"
                      onClick={this.props.history.goBack}
                    >
                      Atrás
                    </button>
                  </div>
                  <div className="text-right">
                    {this.sipstotal ? (
                      <CSVLink
                        data={this.sipstotal}
                        onClick={() => !disableExport}
                        separator={";"}
                        filename={"sips_" + this.state.cups + ".csv"}
                        className={
                          "btn btn-sm btn-primary" + disableExportClass
                        }
                      >
                        Exportar
                      </CSVLink>
                    ) : null}
                  </div>
                  <CardTitle>Detalles</CardTitle>
                  <LoadingOverlay
                    active={this.state.isLoadingSips}
                    spinner
                    text="Cargando..."
                  >
                    <SipsTable
                      showProblemInfo={true}
                      hideViewMoreButton={true}
                      items={this.state.sipsDetail}
                      fields={highPriorityItemsFields}
                      expandableFields={lowPriorityItemsFields}
                    ></SipsTable>
                  </LoadingOverlay>
                </CardBody>
              </Card>

              <Card className="mt-3 shadow">
                <CardBody>
                  <CardTitle>Consumo totales</CardTitle>
                  <LoadingOverlay
                    active={this.state.isLoadingConsumosTotales}
                    spinner
                    text="Cargando..."
                  >
                    <SipsTable
                      hideViewMoreButton={true}
                      items={this.state.sipsConsumosTotal}
                      fields={sipsConsumosTotalFields}
                      expandableFields={sipsConsumosTotalExpandableFields}
                    ></SipsTable>
                  </LoadingOverlay>
                </CardBody>
              </Card>

              <Card className="mt-3 shadow">
                  <CardBody>
                    <CardTitle>Grafica Consumos</CardTitle>


                              <ReactECharts
                                  option={option2}
                                  style={{ height: 400}}
                                 theme='roma'
                              />
                          </CardBody>
                      </Card>



              <Card className="mt-3 shadow">
                <CardBody>
                  <CardTitle>Consumos</CardTitle>
                  <LoadingOverlay
                    active={this.state.isLoadingConsumos}
                    spinner
                    text="Cargando..."
                  >
                    <SipsTable
                      hideViewMoreButton={true}
                      items={this.state.sipsConsumos}
                      fields={sipsConsumosFields}
                      expandableFields={sipsConsumosExpandableFields}
                    ></SipsTable>
                  </LoadingOverlay>
                </CardBody>
              </Card>

</div>
          </Row>
        </Container>

      </>
    );
  }
}

export default withApollo(SipDetails);
