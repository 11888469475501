import React from "react";
import {
  Alert,
  Card,
  CardHeader,
  CardBody,
  Label,
  FormGroup,
  Input,
  Row,
  Button,
  Col,
  Collapse,
  Form,
} from "reactstrap";
import Reflux from "reflux";
import { UserStore } from "../../store/UserStore";

export default class SipsGasFilter extends Reflux.Component {
  formRef: any = null;
  filters = {
  };

  constructor(props) {
    super(props);
    this.state = {
      showFilters: true,
      filters: this.filters,
      userGroupId: 4
    };
    this.store = UserStore;
    this.formRef = React.createRef();
  }

  componentDidMount() {
    // Local storage last filters
    const lastFiltersMultiGas = localStorage.getItem("lastFiltersMultiGas");
    const user = localStorage.getItem("user");

    if(user) {
      const parsedUser = JSON.parse(user);

      this.setState({
        userGroupId: parsedUser.user_group_id
      })
    }
    if (lastFiltersMultiGas) {
      try {
        const parsedFilters: any = JSON.parse(lastFiltersMultiGas) as any;

        this.setState({ filters: parsedFilters }, this.submit);
      } catch (e) {}
    }
  }

  showFiltersToggle = () => {
    const showFilters = !this.state.showFilters;

    this.setState({ showFilters });
  };

  getFilterIcon = () => {
    return this.state.showFilters ? "ni-bold-up" : "ni-bold-down";
  };

  onChangeField = (event) => {
    const name = event.target.name;
    let value = event.target.value;
    const filters = this.state.filters;
    if (name === "cups" || name === "CIF") {
      value = value.split("\n").join(",");
    }
    filters[name] = value;
    if (event.target.type === "select-one" && value === "0") {
      delete filters[name];
    }

    if (value === "" || value === null) {
      delete filters[name];
    }

    this.setState({ filters });
  };

  async setDate(date, key) {
    const filters = this.state.filters;
    filters[key] = date;
    if (key === "startDate") {
      filters["endDate"] = null;
    }

    await this.setState({ filters });
  }

  clearForm = () => {
    //const filters = this.filters; ---> Eliminado porque no cambiaba campos
    const filters = {};
    this.setState({ filters });
    this.formRef.current.reset();
    localStorage.setItem("lastFiltersMultiGas", JSON.stringify(this.state.filters));
  };

  submit = () => {
    localStorage.setItem("lastFiltersMultiGas", JSON.stringify(this.state.filters));
    const keys = Object.keys(this.state.filters).filter((value) => {
      return value !== "codigoPeajeEnVigor";
    });

    if (keys.length > 0) {
      this.props.onSubmit(this.state.filters);
    }
  };

  buildMaxRecordsOptions() {
    if (!this.state.UserStore.hasData) {
      return null;
    }

    //const steps = [10, 20, 50, 100, 200, 500, 1000, 2000, 3000, 4000, 5000];
    const steps = [200, 300, 400, 600, 800];

    return steps.map((value) =>
      this.state.UserStore.data.query_limit >= value ? (
        <option value={value} key={value}>
          {value}
        </option>
      ) : null
    );
  }

  render() {
    const forceUseFields =
      Object.keys(this.state.filters).filter((value) => {
        return value === "codigoPeajeEnVigor";
      }).length === 1;
    //const forceUseCP =
    //  Object.keys(this.state.filters).filter((value) => {
    //    return value === "codigoPostalPS";
    //  }).length !== 1 && forceUseFields;
    //const forceUseLocality =
    //  Object.keys(this.state.filters).filter((value) => {
    //    return value === "codigoMunicipioPS";
    //  }).length !== 1 && forceUseFields;
    const forceUseAuto =
      Object.keys(this.state.filters).filter((value) => {
        return value === "municipioPS";
      }).length !== 1 && forceUseFields;

    //const infoForceUseFields = forceUseCP || forceUseLocality ? (<Col md="12"><Alert color="warning">
    const infoForceUseFields = forceUseAuto ? (<Col md="12"><Alert color="warning">
      Seleccionar un municipio obligatorio por el volumen de datos a tratar. Consulte con nuestro equipo de ventas para cuestiones relativas a grandes volúmenes de datos
    </Alert></Col>) : null;

    let submitDisabled =
      Object.keys(this.state.filters).filter((key) => {
        return  key !== "codigoPeajeEnVigor";
      }).length < 1;

    //if(forceUseCP || forceUseLocality) {
    if(forceUseAuto) {
      submitDisabled = true;
    }
    //const lgCols = this.state.userGroupId === 4 ? '6' : '4';
    const enableFilter = this.state.userGroupId === 4 ? 'd-none' : '';

    return (
      <Card className="mb-4">
        <CardHeader>
          <Button
            onClick={this.showFiltersToggle}
            size="sm"
            className="float-right"
          >
            <i className={"ni " + this.getFilterIcon()} />
          </Button>
          <h3 className="mb-0">Filtrar</h3>
        </CardHeader>
        <Collapse isOpen={this.state.showFilters}>
          <CardBody>
            <Form innerRef={this.formRef}>
              <Row>
                {infoForceUseFields}
                <Col xs="4" md="6" >
                  <FormGroup>
                    <Label className="form-control-label" htmlFor="cliente">
                      Nombre cliente
                    </Label>
                    <Input
                      id="cliente"
                      placeholder="Ingresar nombre del cliente"
                      name="apellido1Titular"
                      onChange={this.onChangeField}
                      value={this.state.filters.apellido1Titular}
                      type="text"
                    />
                  </FormGroup>
                </Col>

                <Col xs="4" md="6"className={enableFilter}>

                  <FormGroup>
                    <Label
                      className="form-control-label"
                      htmlFor="example5cols3Input"
                    >
                      CIF Cliente:
                    </Label>
                    <Input
                      id="CIF"
                      name="CIF"
                      type="text"
                      style={{
                        resize: "none"
                      }}
                      onChange={this.onChangeField}
                      value={this.state.filters.CIF}
                    ></Input>
                  </FormGroup>
                </Col>
              </Row>
              <Row className={enableFilter}>
                  <Col md="6" sm="12" lg="6">
                  <FormGroup>
                    <Label className="form-control-label">
                      Cantidad de registros:
                    </Label>
                    <Input
                      id="limit"
                      name="limit"
                      type="select"
                      onChange={this.onChangeField}
                      value={this.state.filters.limit}
                    >
                      {this.buildMaxRecordsOptions()}
                    </Input>
                  </FormGroup>
                </Col>

              </Row>

              <Row className={enableFilter}>

                <Col md="6" sm="12" lg="6" className="text-right">
                  <Button onClick={this.clearForm}>
                    <i className={"ni ni-fat-remove"} /> Limpiar
                  </Button>
                  <Button
                    color="primary"
                    onClick={this.submit}
                    disabled={submitDisabled}
                  >
                    <i className={"bi bi-search"}/> Buscar
                  </Button>
                </Col>
              </Row>
            </Form>
          </CardBody>
        </Collapse>
      </Card>
    );
  }
}
