import React from "react";
import Reflux from "reflux";
import { NavLink as NavLinkRRD, Link } from "react-router-dom";
import { PropTypes } from "prop-types";
import {
  Collapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Row,
  Col,
} from "reactstrap";
import classnames from "classnames";
//import SubMenu from "./SubMenu";
import { withApollo } from "react-apollo";
import PerfectScrollbar from "react-perfect-scrollbar";
import GetUserData from "../../components/User/GetUserData";
import { UserStore } from "../../store/UserStore";
import { LOGOUT } from "../../queries/users/users.queries";
import { removeToken } from "../../utils";
// import {Icon} from '@ant-design/compatible';
import { LogoutOutlined} from '@ant-design/icons';

class Sidebar extends Reflux.Component {
  //state = {

    //collapseOpen: false,

  //};
  constructor(props) {
    super(props);
    this.store = UserStore;
    this.activeRoute.bind(this);
    this.logout = this.logout.bind(this);
    this.state = {
      collapseOpen: false,
      ...this.getCollapseStates(props.routes)
    };
  }

  activeRoute(routeName) {
    return this.props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
  }

    onMouseEnterSidenav = () => {
    if (!document.body.classList.contains("g-sidenav-pinned")) {
      document.body.classList.add("g-sidenav-show");
    }
  };
  // makes the sidenav mini on hover (actually when mouse leaves from it)
  onMouseLeaveSidenav = () => {
    if (!document.body.classList.contains("g-sidenav-pinned")) {
      document.body.classList.remove("g-sidenav-show");
    }
  };

  toggleCollapse = () => {
    this.setState({
      collapseOpen: !this.state.collapseOpen,
    });
  };

  closeCollapse = () => {
    this.setState({
      collapseOpen: false,
    });
  };

    getCollapseStates = routes => {
    let initialState = {};
    routes.map((prop, key) => {
      if (prop.collapse) {
        initialState = {
          [prop.state]: this.getCollapseInitialState(prop.views),
          ...this.getCollapseStates(prop.views),
          ...initialState
        };
      }
      return null;
    });
    return initialState;
  };

    getCollapseInitialState(routes) {
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse && this.getCollapseInitialState(routes[i].views)) {
        return true;
      } else if (window.location.href.indexOf(routes[i].path) !== -1) {
        return true;
      }
    }
    return false;
  }
  // this is used on mobile devices, when a user navigates
  // the sidebar will autoclose
  closeSidenav = () => {
    if (window.innerWidth < 1200) {
      this.props.toggleSidenav();
    }
  };

  logout = async () => {
    const client = this.props.client;
    const resp = await client.mutate({
      mutation: LOGOUT,
    });
    const session = resp.data.logout;
    if (session.session_id && !session.active) {
      removeToken();
      localStorage.removeItem("lastFilters");
      localStorage.removeItem("lastFiltersEmpre");
      localStorage.removeItem("lastFiltersMulti");
      localStorage.removeItem('lastFiltersMultiGas');
      localStorage.removeItem('lastFiltersGas');
      localStorage.removeItem("user");
      this.props.client.resetStore();
      this.props.history.push(`/auth/login`);
    } else {
      alert("Problema al cerrar sesión");
    }
  };

  // creates the links that appear in the left menu / Sidebar
  createLinks = (routes) => {
    return routes.map((prop, index) => {
      if (prop.redirect) {
        return null;
      }
      if (prop.collapse) {
        var st = {};
        st[prop["state"]] = !this.state[prop.state];
        return (
          <NavItem key={prop.key}>
            <NavLink

              data-toggle="collapse"
              aria-expanded={this.state[prop.state]}
              className={classnames({
                active: this.getCollapseInitialState(prop.views)
              })}
              onClick={e => {
                e.preventDefault();
                this.setState(st);
              }}
            >
              {prop.icon ? (
                <>
                  <i className={prop.icon} />
                  <span className="nav-link-text">{prop.name}</span>
                </>
              ) : prop.miniName ? (
                <>
                  <span className="sidenav-mini-icon"> {prop.miniName} </span>
                  <span className="sidenav-normal"> {prop.name} </span>
                </>
              ) : null}
            </NavLink>
            <Collapse isOpen={this.state[prop.state]}>
              <Nav className="nav-sm flex-column">
                {this.createLinks(prop.views)}
              </Nav>
            </Collapse>
          </NavItem>
        );
      }
      return (
        <NavItem className={this.activeRoute(prop.layout + prop.path)} key={index}>
          <NavLink
            to={prop.layout + prop.path}
            tag={NavLinkRRD}
            onClick={this.closeSidenav}
            activeClassName="active"

          >
              {prop.icon !== undefined ? (
              <>
                <i className={prop.icon} />
                <span className="nav-link-text">{prop.name}</span>
              </>
            ) : prop.miniName !== undefined ? (
              <>
                <span className="sidenav-mini-icon"> {prop.miniName} </span>
                <span className="sidenav-normal"> {prop.name} </span>
              </>
            ) : (
              prop.name
            )}
          </NavLink>
        </NavItem>
      );
    });
  };

  render() {
    const { routes, logo } = this.props;
    let navbarBrandProps;
    if (logo && logo.innerLink) {
      navbarBrandProps = {
        to: logo.innerLink,
        tag: Link,
      };
    } else if (logo && logo.outterLink) {
      navbarBrandProps = {
        href: logo.outterLink,
        target: "_blank",
      };
    }

const scrollBarInner = (
      <div className="scrollbar-inner">
      <GetUserData></GetUserData>
        <div className="sidenav-header d-flex align-items-center">
        {logo ? (
            <NavbarBrand className="pt-0 pt-md-5 pb-md-5" {...navbarBrandProps}>
              <img
                alt={logo.imgAlt}
                className="navbar-brand-img"
                src={
                  this.state.UserStore.data.app_logo
                    ? this.state.UserStore.data.app_logo
                    : logo.imgSrc
                }
              />
            </NavbarBrand>
          ) : null}
          <div className="ml-auto">

            <div
              className={classnames("sidenav-toggler d-none  d-xl-block", {
                active: this.props.sidenavOpen
              })}
              onClick={this.props.toggleSidenav}
            >
              <div className="sidenav-toggler-inner">
                <i className="sidenav-toggler-line" />
                <i className="sidenav-toggler-line" />
                <i className="sidenav-toggler-line" />
              </div>
            </div>
          </div>
        </div>


        <div className="navbar-inner">
          <Collapse navbar isOpen={true}>

                        {/* Collapse header */}
            <div className="navbar-collapse-header d-md-none">
              <Row>
                {logo ? (
                  <Col className="collapse-brand" xs="6">
                    {logo.innerLink ? (
                      <Link to={logo.innerLink}>
                        <img alt={logo.imgAlt} src={logo.imgSrc} />
                      </Link>
                    ) : (
                      <a href={logo.outterLink}>
                        <img alt={logo.imgAlt} src={logo.imgSrc} />
                      </a>
                    )}
                  </Col>
                ) : null}
                <Col className="collapse-close" xs="6">
                  <button
                    className="navbar-toggler"
                    type="button"
                    onClick={this.toggleCollapse}
                  >
                    <span />
                    <span />
                  </button>
                </Col>
              </Row>
            </div>
            {/* Form */}
            {/* Navigation */}

            <Nav navbar>
              {this.createLinks(routes.filter((r) => !r.invisible))}
              <NavItem key={"logout"}>
                <NavLink onClick={this.logout} href="#">
                  {/* <i className="ni ni-button-power text-red" /> */}
                  <i><LogoutOutlined></LogoutOutlined></i>
                  <span className="nav-link-text">Cerrar sesión</span>
                </NavLink>
              </NavItem>
            </Nav>
            {/* Divider */}
            <hr className="my-3" />
            {/* Heading */}
            <h6 className="navbar-heading p-0 text-muted">
            <span className="docs-normal">Ayuda</span>
            <span className="docs-mini"></span>
            </h6>
            {/* Navigation */}
            <Nav className="mb-md-3" navbar>
              <NavItem>
                <NavLink href="mailto:it@psgestion.es">
                  <i className="ni ni-support-16 text-info" />
                  <span className="nav-link-text">Soporte</span>
                </NavLink>
              </NavItem>
            </Nav>


          </Collapse>

        </div>
      </div>
    );
    return (
      <Navbar
        className={"sidenav navbar-vertical navbar-expand-xs  navbar-light fixed-left "
}
        onMouseEnter={this.onMouseEnterSidenav}
        onMouseLeave={this.onMouseLeaveSidenav}
      >
      {navigator.platform.indexOf("Win") > -1 ? (
          <PerfectScrollbar>{scrollBarInner}</PerfectScrollbar>
        ) : (
          scrollBarInner
        )}

      </Navbar>
    );
  }
}

Sidebar.defaultProps = {
  routes: [{}],
  toggleSidenav: () => {},
  sidenavOpen: false,
  rtlActive: false

};

Sidebar.propTypes = {
    toggleSidenav: PropTypes.func,
  // prop to know if the sidenav is mini or normal
  sidenavOpen: PropTypes.bool,
  routes: PropTypes.arrayOf(PropTypes.object),
  logo: PropTypes.shape({
    innerLink: PropTypes.string,
    outterLink: PropTypes.string,
    imgSrc: PropTypes.string.isRequired,
    imgAlt: PropTypes.string.isRequired,
  }),
};

export default withApollo(Sidebar);
