import gql from "graphql-tag";

export const LIST_SIPS_UBICACION = gql`
  query($cups: String!) {
    findUbicacion(cups: $cups) {
      cups
      cupShort
      nombre_completo_titular
      nombre_titular
      apellidos_titular
      CIF
      direccion_titular
      localidad_titular
      provincia_titular
      codigo_postal_titular
      contacto_titular
      telefono
      direccion_ps
      localidad_ps
      provincia_ps
      codigo_postal_ps
      cod_comercializadora
      comercializadora
      cod_distribuidora
      distribuidora
      distribuidora_principal
      fecha_actualizacion
      pais
      telefono_fijo
      email_contacto
      tipo_cliente
      observaciones
    }
  }
`;

export const LIST_SIPS_UBICACION_CIF = gql`
  query($cif: String!) {
    findTitular(cif: $cif) {
      cups
      cupShort
      nombre_completo_titular
      nombre_titular
      apellidos_titular
      CIF
      direccion_titular
      localidad_titular
      provincia_titular
      codigo_postal_titular
      contacto_titular
      telefono
      fecha_actualizacion
      pais
      telefono_fijo
      email_contacto
      tipo_cliente
      observaciones
    }
  }
`;
