import React, { Component } from 'react'
import { CustomInput, Table, Button, UncontrolledCollapse, UncontrolledTooltip} from "reactstrap";
import { Link } from 'react-router-dom';

interface SipsTableInterface {
  fields: Object,
  expandableFields:Object,
  items: Array<any>,
  hideViewMoreButton?: Boolean,
  showIndexButton?: Boolean;
  showFreeMarketButton?: Boolean;
  showProblemInfo?: Boolean,
  parentState?:Object,
  dateFields?: Object,
  beforeRenderItem?:Function // returns true to render
}
interface SipsTableStateInterface {
  expandedRows: Array<string>
}

export default class SipsTable extends Component<
SipsTableInterface,
SipsTableStateInterface
> {

  state={
    expandedRows: new Array<string>()
  };

  handleRowClick(rowId:string) {
  
    const currentExpandedRows = this.state.expandedRows;
    const isRowCurrentlyExpanded = currentExpandedRows.includes(rowId);
    
    const newExpandedRows = isRowCurrentlyExpanded 
      ? currentExpandedRows.filter(id => id !== rowId) 
      : currentExpandedRows.concat(rowId);
    
    this.setState({expandedRows : newExpandedRows});
}

  renderHeader = () => {
    const keys = Object.values(this.props.fields);

    const keysTh = keys.map((item) => {
      return (
      <th scope="col" key={item}>
        {item}
      </th>
      );
    });

    let ViewMoreButtonSpace = 
    (this.props.hideViewMoreButton && !this.props.showProblemInfo) ? null : (
       <th></th>
       );
    
    return (

      <thead className="thead-light">
        <tr>
          {ViewMoreButtonSpace}
          {keysTh}
        </tr>
      </thead>
    );
  };

  renderItem = (item) => {
      function isEmpty(obj) {
        for(var key in obj) {
            if(obj.hasOwnProperty(key))
                return false;
        }
        return true;
    }
    let clickCallback = () => this.handleRowClick(item.cups);
    const hasExtraFields = !isEmpty(this.props.expandableFields);
    if(!hasExtraFields){
      clickCallback = () => null
    }

    const keys = Object.keys(this.props.fields);
        //console.log('KEYS')
    //console.log(this.props.items)
    const tds = keys.map((index) => {
      let value = item[index]
      if(this.props.dateFields && typeof this.props.dateFields[index] == 'string'){
        const d = new Date(value)
        value = ("0" + d.getDate()).slice(-2) + "-" + ("0"+(d.getMonth()+1)).slice(-2) + "-" + d.getFullYear();

      }
      
                   //console.log('VALUE');
    //console.log(value);
      let renderField = true;
      let objItem = {value,item}
      if(this.props.beforeRenderItem){
        renderField = this.props.beforeRenderItem(index,objItem);
      }

      return <td key={index}>{renderField ? objItem.value : "" }</td>
    }
    )
    
    
                   //console.log('TDS');
    //console.log(tds);
    const expandableKeys = Object.keys(this.props.expandableFields);
    const expandableData = expandableKeys.map((index) => {
      const value = item[index]
      let objItemExpandable = {value,item}
      let renderField = true
      if(this.props.beforeRenderItem){
        renderField = this.props.beforeRenderItem(index,objItemExpandable);
      }
      if(!renderField){
      return null
      }
      return objItemExpandable.value ? ( 
          <p className="mb-0" key={index}>
          <strong>{this.props.expandableFields[index] +": "}</strong> {objItemExpandable.value}
          </p> 
          ) : null;
    })
    
        
    const togglerId = "toggler-"+item.cups;

 const haveProblem =
      typeof item["ProblemaId"] != "undefined" && item["ProblemaId"].length > 0;
    let problemWarning: any = null;
    if (haveProblem) {
      const toolTipId = "ProblemToolTip" + item.cups;
      problemWarning = (
        <>
          <span className="btn btn-danger btn-sm btn-circle" id={toolTipId}>
            <i className="pe-7s-info text-white"></i>
          </span>
          <UncontrolledTooltip placement="right" target={toolTipId}>
            {item.ProblemaIdString}
          </UncontrolledTooltip>
        </>
      );
    }
    const ViewMoreButtonContent = this.props.hideViewMoreButton ? null : (
      <Link
        className="btn btn-primary btn-sm btn-circle"
        data-toggle="modal"
        to={{ pathname: "sip-details/" + item.cups }}
        id = "buscartooltip"
      >
            <UncontrolledTooltip delay={0} placement="top" target="buscartooltip">
        Detalles del PS
      </UncontrolledTooltip>
        <i className="pe-7s-search text-white"></i>
      </Link>
    );
    
    const ComparativeIndexButtonContent = this.props.showIndexButton ? (
      <Link
        className="btn btn-light btn-sm btn-circle"
        data-toggle="modal"
        to={{ pathname: "sip-comparative-index/" + item.cups }}
         id = "indextooltip"
      >
                  <UncontrolledTooltip delay={0} placement="top" target="indextooltip">
        Propuesta Indexada
      </UncontrolledTooltip>
        <i className="pe-7s-date text-white"></i>
      </Link>
      
      
    ) : null;
    
    const ComparativeFreeButtonContent = this.props.showFreeMarketButton ? (
      <Link
        className="btn btn-info btn-sm btn-circle"
        data-toggle="modal"
        to={{ pathname: "sip-comparative-free/" + item.cups }}
        id = "fijotooltip"
      >
                        <UncontrolledTooltip delay={0} placement="top" target="fijotooltip">
        Propuesta Precio Fijo
      </UncontrolledTooltip>
        <i className="pe-7s-calculator text-white"></i>
      </Link>
    ): null;

    const ViewMoreButton =
      this.props.hideViewMoreButton && !this.props.showProblemInfo ? null : (
        <td colSpan={1}>
          {ViewMoreButtonContent}
          {ComparativeIndexButtonContent}
          {ComparativeFreeButtonContent}
          {problemWarning}
        </td>
      );


    const itemClassName = hasExtraFields ?  "cursor-pointer" : ""
    const itemRendered = [
      <tr key={item.cups} onClick={clickCallback} className={itemClassName}>
        {ViewMoreButton}
        {tds}
      </tr>
    ];
    
    if(this.state.expandedRows.includes(item.cups)) {
        const exandableRow = (
          <tr key={"exandable-"+item.cups} >
            <td colSpan={keys.length}>
              {expandableData}
            </td>
          </tr>
        );

        itemRendered.push(exandableRow)
    }

    return (

      itemRendered

    )
  }

  render() {
    const items = this.props.items ? this.props.items.map(this.renderItem) : null
    return (
      <Table className="align-items-center table-flush" responsive>
        {this.renderHeader()}
        <tbody>
          {items}
        </tbody>
      </Table>
    )
  }
}