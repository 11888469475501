import React from "react";
import { Route, Switch, Redirect  } from "react-router-dom";
// reactstrap components
import { Container } from "reactstrap";
// core components
import AdminNavbar from "../components/Navbars/AdminNavbar";
import AdminFooter from "../components/Footers/AdminFooter";
import Sidebar from "../components/Sidebar/Sidebar";
import LogoutModal from "../components/Auth/LogoutModal";
import routes from "../routes";
import { withApollo } from 'react-apollo';
import Page from "../components/Page/Page";

class Admin extends React.Component<any, any> {
  public state = {
    brandName: '',
    sidenavOpen: true,
  }


  componentDidUpdate(e) {
    if (e.history.pathname !== e.location.pathname) {
      document.documentElement.scrollTop = 0;
      document.scrollingElement!.scrollTop = 0;
      //this.refs.mainContent.scrollTop = 0;
    }
  }

  getRoutes = routes => {
    return routes.map((prop, key) => {
      if (prop.layout === "/admin") {
        if (prop.hasSubmenu) {
          const submenu = prop.views.map((submenu, submenuKey) => {
            return (
            <Route
              path={submenu.layout + submenu.path}
              key={submenuKey}
              render={props => (
                <Page {...submenu}
                  component={submenu.component}
                  title={'PsGestion | ' + submenu.name}
                  name={submenu.name}
                  onSetBrandText={this.onSetBrandText}
                />
              )}
            />)
          });

          return submenu;
        } else {
          return (
            <Route
              path={prop.layout + prop.path}
              key={key}
              render={props => (
                <Page {...props}
                  component={prop.component}
                  title={'PsGestion | ' + prop.name}
                  name={prop.name}
                  onSetBrandText={this.onSetBrandText}
                />
              )}

            />
          );
        }
      } else {
        return null;
      }
    });
  };

  onSetBrandText = (title) => {
    this.setState({ brandName: title });
  };

    toggleSidenav = e => {
    if (document.body.classList.contains("g-sidenav-pinned")) {

      document.body.classList.remove("g-sidenav-pinned");
      document.body.classList.add("g-sidenav-hidden");

    } else {

      document.body.classList.add("g-sidenav-pinned");
      document.body.classList.remove("g-sidenav-hidden");

    }
    this.setState({
      sidenavOpen: !this.state.sidenavOpen
     });
  };


  render() {
    return (
      <>
        <LogoutModal {...this.props}></LogoutModal>
        <Sidebar
          {...this.props}
          routes={routes.filter(r => r.layout === '/admin')}
          toggleSidenav={this.toggleSidenav}
          sidenavOpen={this.state.sidenavOpen}
          logo={{
            innerLink: "/admin/index",
            imgSrc: require("../assets/img/brand/dynargy-smart-logo.png"),
            imgAlt: "..."
          }}
        />
        <div className="main-content" ref="mainContent"  onClick={this.props.closeSidenav}>
          <AdminNavbar

            brandText={this.state.brandName}
            toggleSidenav={this.toggleSidenav}
            sidenavOpen={this.state.sidenavOpen}
            {...this.props}

          />
          <Switch>{this.getRoutes(routes)}</Switch>
          <Container fluid>
            <AdminFooter />
          </Container>

        </div>

      </>
    );
  }
}

export default withApollo(Admin);
