import React from "react";

// reactstrap components
import {
    Card, CardBody, CardTitle, Container, Row, Col, Label,
    FormGroup,
    Input, FormText,
    Form, Button,
    UncontrolledTooltip
} from "reactstrap";
import Header from "../../components/Headers/Header";
import { withApollo } from "react-apollo";
import { Link } from 'react-router-dom';
import axios from 'axios';
import moment from "moment";
import 'moment/locale/es';
import Reflux from "reflux";
import { UserStore } from "../../store/UserStore";
import DataTable from 'react-data-table-component';
import Checkbox from '@material-ui/core/Checkbox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
//import memoize from 'memoize-one';
import CircularProgress from '@material-ui/core/CircularProgress';

import { GET_COMERCIAL_DETAIL } from "../../queries/comercial/detail";
import { GET_USER_DATA } from "../../queries/users/users.queries";
import numberWithPoints from "../../utils/numberWithPoints";
import { ThunderboltFilled, FireFilled } from '@ant-design/icons';
import listaSubmin from "../../assets/img/icons/custom/lista_submin.svg";
import listaSubminGas from "../../assets/img/icons/custom/lista_submin_gas.svg";
import listaEmpresas from "../../assets/img/icons/custom/lista_empresas.svg";
import listadoPS from "../../assets/img/icons/custom/listadoPS.svg";
import propuestaMulti from "../../assets/img/icons/custom/propuestaMulti.svg";
import gestionPropuestas from "../../assets/img/icons/custom/gestion_propuestas.svg";
import gestionContratos from "../../assets/img/icons/custom/gestion_contratos.svg";
//import { PieChart, Pie, Sector, BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { scaleOrdinal } from 'd3-scale';
import { schemeCategory10 } from 'd3-scale-chromatic';

//import * as echarts from 'echarts'
import ReactEChartsCore from 'echarts-for-react/lib/core';
// Import the echarts core module, which provides the necessary interfaces for using echarts.
import * as echarts from 'echarts/core';
// Import charts, all with Chart suffix
import {
    LineChart,
    BarChart,
    PieChart,
    // ScatterChart,
    // RadarChart,
    // MapChart,
    // TreeChart,
    // TreemapChart,
    // GraphChart,
    // GaugeChart,
    // FunnelChart,
    // ParallelChart,
    // SankeyChart,
    // BoxplotChart,
    // CandlestickChart,
    // EffectScatterChart,
    // LinesChart,
    // HeatmapChart,
    // PictorialBarChart,
    // ThemeRiverChart,
    // SunburstChart,
    // CustomChart,
} from 'echarts/charts';

// import components, all suffixed with Component
import {
    // GridSimpleComponent,
    GridComponent,
    // PolarComponent,
    // RadarComponent,
    // GeoComponent,
    // SingleAxisComponent,
    // ParallelComponent,
    // CalendarComponent,
    // GraphicComponent,
    // ToolboxComponent,
    TooltipComponent,
    // AxisPointerComponent,
    // BrushComponent,
    TitleComponent,
    // TimelineComponent,
    // MarkPointComponent,
    // MarkLineComponent,
    // MarkAreaComponent,
    LegendComponent,
    // LegendScrollComponent,
    // LegendPlainComponent,
    // DataZoomComponent,
    // DataZoomInsideComponent,
    // DataZoomSliderComponent,
    VisualMapComponent,
    // VisualMapContinuousComponent,
    // VisualMapPiecewiseComponent,
    // AriaComponent,
    // TransformComponent,
    DatasetComponent,
} from 'echarts/components';
// Import renderer, note that introducing the CanvasRenderer or SVGRenderer is a required step
import {
    CanvasRenderer,
    // SVGRenderer,
} from 'echarts/renderers';
//import ReactECharts from 'echarts-for-react';
echarts.use(
    [TitleComponent, TooltipComponent, GridComponent, BarChart, LineChart, PieChart, LegendComponent, VisualMapComponent, DatasetComponent, CanvasRenderer]
);


echarts.registerTheme('roma', {
    "color": [
        "#3fb1e3",
        "#6be6c1",
        "#626c91",
        "#a0a7e6",
        "#c4ebad",
        "#96dee8"
    ],
    "backgroundColor": "rgba(252,252,252,0)",
    "textStyle": {},
    "title": {
        "textStyle": {
            "color": "#666666"
        },
        "subtextStyle": {
            "color": "#999999"
        }
    },
    "line": {
        "itemStyle": {
            "borderWidth": "2"
        },
        "lineStyle": {
            "width": "3"
        },
        "symbolSize": "8",
        "symbol": "emptyCircle",
        "smooth": false
    },
    "radar": {
        "itemStyle": {
            "borderWidth": "2"
        },
        "lineStyle": {
            "width": "3"
        },
        "symbolSize": "8",
        "symbol": "emptyCircle",
        "smooth": false
    },
    "bar": {
        "itemStyle": {
            "barBorderWidth": 0,
            "barBorderColor": "#ccc"
        }
    },
    "pie": {
        "itemStyle": {
            "borderWidth": 0,
            "borderColor": "#ccc"
        }
    },
    "scatter": {
        "itemStyle": {
            "borderWidth": 0,
            "borderColor": "#ccc"
        }
    },
    "boxplot": {
        "itemStyle": {
            "borderWidth": 0,
            "borderColor": "#ccc"
        }
    },
    "parallel": {
        "itemStyle": {
            "borderWidth": 0,
            "borderColor": "#ccc"
        }
    },
    "sankey": {
        "itemStyle": {
            "borderWidth": 0,
            "borderColor": "#ccc"
        }
    },
    "funnel": {
        "itemStyle": {
            "borderWidth": 0,
            "borderColor": "#ccc"
        }
    },
    "gauge": {
        "itemStyle": {
            "borderWidth": 0,
            "borderColor": "#ccc"
        }
    },
    "candlestick": {
        "itemStyle": {
            "color": "#e6a0d2",
            "color0": "transparent",
            "borderColor": "#e6a0d2",
            "borderColor0": "#3fb1e3",
            "borderWidth": "2"
        }
    },
    "graph": {
        "itemStyle": {
            "borderWidth": 0,
            "borderColor": "#ccc"
        },
        "lineStyle": {
            "width": "1",
            "color": "#cccccc"
        },
        "symbolSize": "8",
        "symbol": "emptyCircle",
        "smooth": false,
        "color": [
            "#3fb1e3",
            "#6be6c1",
            "#626c91",
            "#a0a7e6",
            "#c4ebad",
            "#96dee8"
        ],
        "label": {
            "color": "#ffffff"
        }
    },
    "map": {
        "itemStyle": {
            "areaColor": "#eeeeee",
            "borderColor": "#aaaaaa",
            "borderWidth": 0.5
        },
        "label": {
            "color": "#ffffff"
        },
        "emphasis": {
            "itemStyle": {
                "areaColor": "rgba(63,177,227,0.25)",
                "borderColor": "#3fb1e3",
                "borderWidth": 1
            },
            "label": {
                "color": "#3fb1e3"
            }
        }
    },
    "geo": {
        "itemStyle": {
            "areaColor": "#eeeeee",
            "borderColor": "#aaaaaa",
            "borderWidth": 0.5
        },
        "label": {
            "color": "#ffffff"
        },
        "emphasis": {
            "itemStyle": {
                "areaColor": "rgba(63,177,227,0.25)",
                "borderColor": "#3fb1e3",
                "borderWidth": 1
            },
            "label": {
                "color": "#3fb1e3"
            }
        }
    },
    "categoryAxis": {
        "axisLine": {
            "show": true,
            "lineStyle": {
                "color": "#cccccc"
            }
        },
        "axisTick": {
            "show": false,
            "lineStyle": {
                "color": "#333"
            }
        },
        "axisLabel": {
            "show": true,
            "color": "#999999"
        },
        "splitLine": {
            "show": true,
            "lineStyle": {
                "color": [
                    "#eeeeee"
                ]
            }
        },
        "splitArea": {
            "show": false,
            "areaStyle": {
                "color": [
                    "rgba(250,250,250,0.05)",
                    "rgba(200,200,200,0.02)"
                ]
            }
        }
    },
    "valueAxis": {
        "axisLine": {
            "show": true,
            "lineStyle": {
                "color": "#cccccc"
            }
        },
        "axisTick": {
            "show": false,
            "lineStyle": {
                "color": "#333"
            }
        },
        "axisLabel": {
            "show": true,
            "color": "#999999"
        },
        "splitLine": {
            "show": true,
            "lineStyle": {
                "color": [
                    "#eeeeee"
                ]
            }
        },
        "splitArea": {
            "show": false,
            "areaStyle": {
                "color": [
                    "rgba(250,250,250,0.05)",
                    "rgba(200,200,200,0.02)"
                ]
            }
        }
    },
    "logAxis": {
        "axisLine": {
            "show": true,
            "lineStyle": {
                "color": "#cccccc"
            }
        },
        "axisTick": {
            "show": false,
            "lineStyle": {
                "color": "#333"
            }
        },
        "axisLabel": {
            "show": true,
            "color": "#999999"
        },
        "splitLine": {
            "show": true,
            "lineStyle": {
                "color": [
                    "#eeeeee"
                ]
            }
        },
        "splitArea": {
            "show": false,
            "areaStyle": {
                "color": [
                    "rgba(250,250,250,0.05)",
                    "rgba(200,200,200,0.02)"
                ]
            }
        }
    },
    "timeAxis": {
        "axisLine": {
            "show": true,
            "lineStyle": {
                "color": "#cccccc"
            }
        },
        "axisTick": {
            "show": false,
            "lineStyle": {
                "color": "#333"
            }
        },
        "axisLabel": {
            "show": true,
            "color": "#999999"
        },
        "splitLine": {
            "show": true,
            "lineStyle": {
                "color": [
                    "#eeeeee"
                ]
            }
        },
        "splitArea": {
            "show": false,
            "areaStyle": {
                "color": [
                    "rgba(250,250,250,0.05)",
                    "rgba(200,200,200,0.02)"
                ]
            }
        }
    },
    "toolbox": {
        "iconStyle": {
            "borderColor": "#999999"
        },
        "emphasis": {
            "iconStyle": {
                "borderColor": "#666666"
            }
        }
    },
    "legend": {
        "textStyle": {
            "color": "#999999"
        }
    },
    "tooltip": {
        "axisPointer": {
            "lineStyle": {
                "color": "#cccccc",
                "width": 1
            },
            "crossStyle": {
                "color": "#cccccc",
                "width": 1
            }
        }
    },
    "timeline": {
        "lineStyle": {
            "color": "#626c91",
            "width": 1
        },
        "itemStyle": {
            "color": "#626c91",
            "borderWidth": 1
        },
        "controlStyle": {
            "color": "#626c91",
            "borderColor": "#626c91",
            "borderWidth": 0.5
        },
        "checkpointStyle": {
            "color": "#3fb1e3",
            "borderColor": "#3fb1e3"
        },
        "label": {
            "color": "#626c91"
        },
        "emphasis": {
            "itemStyle": {
                "color": "#626c91"
            },
            "controlStyle": {
                "color": "#626c91",
                "borderColor": "#626c91",
                "borderWidth": 0.5
            },
            "label": {
                "color": "#626c91"
            }
        }
    },
    "visualMap": {
        "color": [
            "#2a99c9",
            "#afe8ff"
        ]
    },
    "dataZoom": {
        "backgroundColor": "rgba(255,255,255,0)",
        "dataBackgroundColor": "rgba(222,222,222,1)",
        "fillerColor": "rgba(114,230,212,0.25)",
        "handleColor": "#cccccc",
        "handleSize": "100%",
        "textStyle": {
            "color": "#999999"
        }
    },
    "markPoint": {
        "label": {
            "color": "#ffffff"
        },
        "emphasis": {
            "label": {
                "color": "#ffffff"
            }
        }
    }
});


echarts.registerTheme('macarons', {
    "color": [
        "#2ec7c9",
        "#b6a2de",
        "#5ab1ef",
        "#ffb980",
        "#d87a80",
        "#8d98b3",
        "#e5cf0d",
        "#97b552",
        "#95706d",
        "#dc69aa",
        "#07a2a4",
        "#9a7fd1",
        "#588dd5",
        "#f5994e",
        "#c05050",
        "#59678c",
        "#c9ab00",
        "#7eb00a",
        "#6f5553",
        "#c14089"
    ],
    "backgroundColor": "rgba(0,0,0,0)",
    "textStyle": {},
    "title": {
        "textStyle": {
            "color": "#008acd"
        },
        "subtextStyle": {
            "color": "#aaaaaa"
        }
    },
    "line": {
        "itemStyle": {
            "borderWidth": 1
        },
        "lineStyle": {
            "width": 2
        },
        "symbolSize": 3,
        "symbol": "emptyCircle",
        "smooth": true
    },
    "radar": {
        "itemStyle": {
            "borderWidth": 1
        },
        "lineStyle": {
            "width": 2
        },
        "symbolSize": 3,
        "symbol": "emptyCircle",
        "smooth": true
    },
    "bar": {
        "itemStyle": {
            "barBorderWidth": 0,
            "barBorderColor": "#ccc"
        }
    },
    "pie": {
        "itemStyle": {
            "borderWidth": 0,
            "borderColor": "#ccc"
        }
    },
    "scatter": {
        "itemStyle": {
            "borderWidth": 0,
            "borderColor": "#ccc"
        }
    },
    "boxplot": {
        "itemStyle": {
            "borderWidth": 0,
            "borderColor": "#ccc"
        }
    },
    "parallel": {
        "itemStyle": {
            "borderWidth": 0,
            "borderColor": "#ccc"
        }
    },
    "sankey": {
        "itemStyle": {
            "borderWidth": 0,
            "borderColor": "#ccc"
        }
    },
    "funnel": {
        "itemStyle": {
            "borderWidth": 0,
            "borderColor": "#ccc"
        }
    },
    "gauge": {
        "itemStyle": {
            "borderWidth": 0,
            "borderColor": "#ccc"
        }
    },
    "candlestick": {
        "itemStyle": {
            "color": "#d87a80",
            "color0": "#2ec7c9",
            "borderColor": "#d87a80",
            "borderColor0": "#2ec7c9",
            "borderWidth": 1
        }
    },
    "graph": {
        "itemStyle": {
            "borderWidth": 0,
            "borderColor": "#ccc"
        },
        "lineStyle": {
            "width": 1,
            "color": "#aaaaaa"
        },
        "symbolSize": 3,
        "symbol": "emptyCircle",
        "smooth": true,
        "color": [
            "#2ec7c9",
            "#b6a2de",
            "#5ab1ef",
            "#ffb980",
            "#d87a80",
            "#8d98b3",
            "#e5cf0d",
            "#97b552",
            "#95706d",
            "#dc69aa",
            "#07a2a4",
            "#9a7fd1",
            "#588dd5",
            "#f5994e",
            "#c05050",
            "#59678c",
            "#c9ab00",
            "#7eb00a",
            "#6f5553",
            "#c14089"
        ],
        "label": {
            "color": "#eeeeee"
        }
    },
    "map": {
        "itemStyle": {
            "areaColor": "#dddddd",
            "borderColor": "#eeeeee",
            "borderWidth": 0.5
        },
        "label": {
            "color": "#d87a80"
        },
        "emphasis": {
            "itemStyle": {
                "areaColor": "rgba(254,153,78,1)",
                "borderColor": "#444",
                "borderWidth": 1
            },
            "label": {
                "color": "rgb(100,0,0)"
            }
        }
    },
    "geo": {
        "itemStyle": {
            "areaColor": "#dddddd",
            "borderColor": "#eeeeee",
            "borderWidth": 0.5
        },
        "label": {
            "color": "#d87a80"
        },
        "emphasis": {
            "itemStyle": {
                "areaColor": "rgba(254,153,78,1)",
                "borderColor": "#444",
                "borderWidth": 1
            },
            "label": {
                "color": "rgb(100,0,0)"
            }
        }
    },
    "categoryAxis": {
        "axisLine": {
            "show": true,
            "lineStyle": {
                "color": "#008acd"
            }
        },
        "axisTick": {
            "show": true,
            "lineStyle": {
                "color": "#333"
            }
        },
        "axisLabel": {
            "show": true,
            "color": "#333"
        },
        "splitLine": {
            "show": false,
            "lineStyle": {
                "color": [
                    "#eee"
                ]
            }
        },
        "splitArea": {
            "show": false,
            "areaStyle": {
                "color": [
                    "rgba(250,250,250,0.3)",
                    "rgba(200,200,200,0.3)"
                ]
            }
        }
    },
    "valueAxis": {
        "axisLine": {
            "show": true,
            "lineStyle": {
                "color": "#008acd"
            }
        },
        "axisTick": {
            "show": true,
            "lineStyle": {
                "color": "#333"
            }
        },
        "axisLabel": {
            "show": true,
            "color": "#333"
        },
        "splitLine": {
            "show": true,
            "lineStyle": {
                "color": [
                    "#eee"
                ]
            }
        },
        "splitArea": {
            "show": true,
            "areaStyle": {
                "color": [
                    "rgba(250,250,250,0.3)",
                    "rgba(200,200,200,0.3)"
                ]
            }
        }
    },
    "logAxis": {
        "axisLine": {
            "show": true,
            "lineStyle": {
                "color": "#008acd"
            }
        },
        "axisTick": {
            "show": true,
            "lineStyle": {
                "color": "#333"
            }
        },
        "axisLabel": {
            "show": true,
            "color": "#333"
        },
        "splitLine": {
            "show": true,
            "lineStyle": {
                "color": [
                    "#eee"
                ]
            }
        },
        "splitArea": {
            "show": true,
            "areaStyle": {
                "color": [
                    "rgba(250,250,250,0.3)",
                    "rgba(200,200,200,0.3)"
                ]
            }
        }
    },
    "timeAxis": {
        "axisLine": {
            "show": true,
            "lineStyle": {
                "color": "#008acd"
            }
        },
        "axisTick": {
            "show": true,
            "lineStyle": {
                "color": "#333"
            }
        },
        "axisLabel": {
            "show": true,
            "color": "#333"
        },
        "splitLine": {
            "show": true,
            "lineStyle": {
                "color": [
                    "#eee"
                ]
            }
        },
        "splitArea": {
            "show": false,
            "areaStyle": {
                "color": [
                    "rgba(250,250,250,0.3)",
                    "rgba(200,200,200,0.3)"
                ]
            }
        }
    },
    "toolbox": {
        "iconStyle": {
            "borderColor": "#2ec7c9"
        },
        "emphasis": {
            "iconStyle": {
                "borderColor": "#18a4a6"
            }
        }
    },
    "legend": {
        "textStyle": {
            "color": "#333333"
        }
    },
    "tooltip": {
        "axisPointer": {
            "lineStyle": {
                "color": "#008acd",
                "width": "1"
            },
            "crossStyle": {
                "color": "#008acd",
                "width": "1"
            }
        }
    },
    "timeline": {
        "lineStyle": {
            "color": "#008acd",
            "width": 1
        },
        "itemStyle": {
            "color": "#008acd",
            "borderWidth": 1
        },
        "controlStyle": {
            "color": "#008acd",
            "borderColor": "#008acd",
            "borderWidth": 0.5
        },
        "checkpointStyle": {
            "color": "#2ec7c9",
            "borderColor": "#2ec7c9"
        },
        "label": {
            "color": "#008acd"
        },
        "emphasis": {
            "itemStyle": {
                "color": "#a9334c"
            },
            "controlStyle": {
                "color": "#008acd",
                "borderColor": "#008acd",
                "borderWidth": 0.5
            },
            "label": {
                "color": "#008acd"
            }
        }
    },
    "visualMap": {
        "color": [
            "#5ab1ef",
            "#e0ffff"
        ]
    },
    "dataZoom": {
        "backgroundColor": "rgba(47,69,84,0)",
        "dataBackgroundColor": "#efefff",
        "fillerColor": "rgba(182,162,222,0.2)",
        "handleColor": "#008acd",
        "handleSize": "100%",
        "textStyle": {
            "color": "#333333"
        }
    },
    "markPoint": {
        "label": {
            "color": "#eeeeee"
        },
        "emphasis": {
            "label": {
                "color": "#eeeeee"
            }
        }
    }
});

const colors = scaleOrdinal(schemeCategory10).range();

const sortIcon = <ArrowDownward />;

const columnsDatosComision = [
    { selector: row => row.nombreCompleto, name: 'COMERCIAL', sortable: true, width: '200px', format: (row => capitalize(row.nombreCompleto)) },
    { selector: row => row.comercial, name: 'ID', sortable: true, width: '140px', format: (row => capitalize(row.comercial)) },
    { selector: row => row.cups, name: 'CUPS', sortable: true, width: '200px', center: true, format: (row => capitalize(row.cups)) },
    { selector: row => row.tipoCliente, name: 'TIPO CLIENTE', sortable: true, width: '210px', center: true, format: (row => capitalize(row.tipoCliente)) },
    { selector: row => row.fechaFirmaContrato, name: 'FECHA FIRMA', sortable: true, width: '120px', center: true, format: (row => capitalize(row.fechaFirmaContrato)) },
    { selector: row => row.consumo, name: 'CONSUMO', sortable: true, width: '130px', center: true },
    { selector: row => row.comisionYear1, name: 'COMISION PREVISTA 1 AÑO', width: '130px', sortable: true, center: true }
];

const columnsDatosComisionFactura = [

    { selector: row => row.cups, name: 'CUPS', sortable: true, width: '180px', format: (row => capitalize(row.cups)) },
    { selector: row => row.idFactura, name: 'ID FACTURA', sortable: true, width: '150px', center: true, format: (row => capitalize(row.idFactura)) },
    { selector: row => row.fechAlta, name: 'FECHA FACTURA', sortable: true, width: '150px', center: true },
    { selector: row => row.tipolecdescripcion, name: 'LECTURA', sortable: true, width: '100px', center: true, format: (row => capitalize(row.tipolecdescripcion)) },
    { selector: row => row.consumoM3, name: 'CONSUMO M3', sortable: true, width: '180px', center: true },
    { selector: row => row.consumokwh, name: 'COMISION kw', width: '180px', sortable: true, center: true },
    { selector: row => row.comision, name: 'COMISION SOBRE CONSUMO', sortable: true, width: '180px', center: true, format:(row => row.comision.toLocaleString("es-ES", {maximumFractionDigits: 2})+" €") }
];



const capitalize = (s) => {
    if (typeof s !== 'string') return ''
    return s.toLowerCase().charAt(0).toUpperCase() + s.slice(1)
}

const format= (data) => {
	//data = parseFloat(data);
	return data.toLocaleString('es-ES', {style: 'currency', currency: 'EUR'});
}

const customStyles = {
    headRow: {
        style: {
            border: 'none',

        },
    },
    headCells: {
        style: {
            color: '#202124',
            fontSize: '12px',
            fontWeight: 'bold',
            wordBreak: 'break-word',
            paddingLeft: '8px',
            paddingRight: '8px',
            minWidth: '55px'

        },
    },
    cells: {
        style: {
            paddingLeft: '8px',
            paddingRight: '8px',
            minWidth: '55px',
            wordBreak: 'break-word',
            fontFamily: '"Open Sans", sans-serif;'
        },
    },
    rows: {
        highlightOnHoverStyle: {
            backgroundColor: 'rgb(230, 250, 250)',
            borderBottomColor: '#FFFFFF',
            borderRadius: '25px',
            outline: '1px solid #FFFFFF',
            fontSize: '12px',
        },
    },
    pagination: {
        style: {
            border: 'none',
        },
    },
};



const Circular = () => (
    // we need to add some padding to circular progress to keep it from activating our scrollbar
    <div style={{ padding: '24px' }}>
        <CircularProgress size={75} />
    </div>
);

class Comisiones extends Reflux.Component<any, any> {
    state = {

        items: [],
        result: {
            data: [{
                comercial: "",
                comisionContratoConsumoAgente1: 0,
                comisionContratoConsumoAgente2: 0,
                comisionContratoFija: 0,
                comisionYear1: 0,
                comisionYear2: 0,
                consumo: 0,
                cups: "",
                fechaFirmaContrato: "",
                fechaPropuesta: "",
                idContrato: "",
                tipoCliente: ""
            }]
        },
        UserStore: {},
        companyIdUser: '',
        userName: '',
        companyNameUser: '',
        userGroupId: 1000,
        userId: '',
        idCard: "",
        first_name1: "",
        last_name1: "",
        toggledClearRows: false,
        showIndexButton: false,
        showFreeMarketButton: false,
        isLoading2: false,
        inputDisabled: false,
        comisionDetailTotal: [{
            comercial: "",
            comisionContratoConsumoAgente1: 0,
            comisionContratoConsumoAgente2: 0,
            comisionContratoFija: 0,
            comisionYear1: 0,
            comisionYear2: 0,
            consumo: 0,
            cups: "",
            fechaFirmaContrato: "",
            fechaPropuesta: "",
            idContrato: "",
            tipoCliente: ""

        }],
        comisionDetail: {
            comercial: "",
            comisionContratoConsumoAgente1: 0,
            comisionContratoConsumoAgente2: 0,
            comisionContratoFija: 0,
            comisionYear1: 0,
            comisionYear2: 0,
            consumo: 0,
            cups: "",
            fechaFirmaContrato: "",
            fechaPropuesta: "",
            idContrato: "",
            tipoCliente: ""
        },
        comisionFacturaTotal: [{
            consumoM3: 0,
            consumokwh: 0,
            cups: "",
            fechAlta: "",
            idFactura: "",
            tipolecdescripcion: "",
            comision: 0
        }],
        totalFacturas: 0,
        sumTotalComisionFacturas: 0,
        ultimaFactura: {
            consumoM3: 0,
            consumokwh: 0,
            cups: "",
            fechAlta: "",
            idFactura: "",
            tipolecdescripcion: "",
            comision: 0
        },
        comisionSeleccionado: false,
        idContrato: '',
        formComision: {
            comercial: "",
            comisionContratoConsumoAgente1: 0,
            comisionContratoConsumoAgente2: 0,
            comisionContratoFija: 0,
            comisionYear1: 0,
            comisionYear2: 0,
            consumo: 0,
            cups: "",
            fechaFirmaContrato: "",
            fechaPropuesta: "",
            idContrato: "",
            tipoCliente: ""
        },
        dataResumenContratos: {
            totalAceptados: 0,
            totalContratos: 0,
            totalEnEspera: 0,
            ultimaFecha: ''
        },
        dataResumenContratosChart0: [{
            name: "",
            contratos: 0
        }],
        dataResumenContratosChart1: [{
            name: "",
            contratos: 0
        }],
        dataResumenContratosFirmados: {
            totalFirmaContrato: 0,
            totalContratos: 0,
            totalEnEspera: 0,
            totalContratosGeneradoEnviado: 0,
            totalFechaContrato: 0,
            totalFechaActivacion: 0
        },
        dataResumenContratosFirmadosChart: [{
            name: "Contrato Totales",
            totalFirmaContrato: 0,
            totalContratos: 0,
            totalEnEspera: 0,
            totalContratosGeneradoEnviado: 0,
            totalFechaContrato: 0,
            totalFechaActivacion: 0
        }],
        dataChart1: [{
            Aceptados: 0,
            Anno: 0,
            Baja: 0,
            Contratos: 0,
            Denegados: 0,
            EnEspera: 0,
            Mes: 1,
            Renovacion: 0
        }],
        dataChartContrato1G: [{
            ConsumoAnual: 0,
            ConsumoAnual2: 0,
            Fecha: "",
            Producto: "",
            TarifaPeaje: "B6",
            TotalProducto: 0,
            TotalTarifa: 0,
            TotalTipoEnergia: 0,
            tipoEnergia: "GAS"
        }],
        dataChartContrato1E: [{
            ConsumoAnual: 0,
            ConsumoAnual2: 0,
            Fecha: "",
            Producto: "",
            TarifaPeaje: "B6",
            TotalProducto: 0,
            TotalTarifa: 0,
            TotalTipoEnergia: 0,
            tipoEnergia: "GAS"
        }],
        isLoading: false,
        data: {
            supplier_id: 0,
            num_sites: 0,
            kwhanual: 0,
            supplier_name: "",
            supplier_code_ree: "",
            persona_fisica_total_sites: 0,
            persona_fisica_sumkwhanual: 0,
            persona_juridica_total_sites: 0,
            persona_juridica_sumkwhanual: 0,
        },
        dataAux: [{
            label: "",
            B5: 0
        }]
    };


    constructor(props) {
        super(props);
        this.store = UserStore;
    }


    componentDidMount() {

        this.getUserData();
        this.fetchItems();

    };

    async getUserData() {

        const resp = await this.props.client.query({
            query: GET_USER_DATA,
        });

        const user = resp.data.userData;

        this.setState({

            first_name1: user.first_name,
            last_name1: user.last_name,
        });

        this.fetchComisiones(user.email, user.companyId, user.id, user.user_group_id);

        //this.QueryResumenContratosAlt(user.email, user.companyId, user.id, user.user_group_id);
        //this.QueryResumenContratosFirmadosAlt(user.email, user.companyId, user.id, user.user_group_id);

        //this.QueryResumenChart(user.email, user.companyId, user.id, user.user_group_id);
        //this.QueryResumenChartContrato(user.email, user.companyId, user.id, user.user_group_id);

    }

    componentWillReceiveProps() {


        const usuario: any = JSON.parse(JSON.stringify(this.state.UserStore['data']))

        const companyNameGlobal = usuario.company;
        const companyIdUser = usuario.companyId;

        const userGroupId = usuario.user_group_id
        const userId = usuario.id
        const userName = usuario.first_name + " " + usuario.last_name
        const idCard = usuario.email

        let showIndexButton = usuario.indexada;
        let showFreeMarketButton = usuario.mercadolibre;
        let companyNameUser = usuario.company;

        if (userGroupId === 1000) {
            this.setState({
                activeUser: true
            })
        };

        this.setState({
            companyNameGlobal,
            companyIdUser,
            userGroupId,
            userId,
            showIndexButton,
            userName,
            showFreeMarketButton,
            companyNameUser,
            idCard

        });
    }


    UNSAFE_componentWillMount() {


        var userFromStorageTemp = localStorage.getItem("user");
        var parsedTemp = JSON.parse(userFromStorageTemp || '{}');
        var userIdTemp = parsedTemp.id;
        var companyIdUserTemp = parsedTemp.companyId;
        var userGroupId = parsedTemp.user_group_id;
        var company = parsedTemp.company;
        var idCard = parsedTemp.email;

        this.setState({

            companyIdUser: companyIdUserTemp,
            userId: userIdTemp,
            userGroupId: userGroupId,
            companyNameUser: company,
            idCard: idCard

        });

    };

    ///////////////////////////////////////////////////////////////////////////////////////////

    fetchItems = async () => {
        let isLoading = true;
        this.setState({ isLoading });

        const sips = await this.props.client.query({
            query: GET_COMERCIAL_DETAIL,
        });
        const data = sips.data.getComercialDetail;

        isLoading = false;
        this.setState({ data, isLoading });
    };


    fetchComisiones = async (idcard1, companyIdUser1, userId1, userGroupId1) => {

        var companyIdUser = Number(this.state.companyIdUser);
        var user = this.state.userId.toString()
        var CIF = idcard1
        var nombre = ""
        var apellidos = ""

        if(userGroupId1 == 1000){
            CIF = ""
        }


        var dataFilters = {
            'idCompany': "",
            'cups': "",
            'Usuario': "",
            'NIFComercial': CIF,
            'nombreComercial': nombre,
            'apellido1Comercial': apellidos
        };

        const valores = JSON.stringify(dataFilters)
        const headers = {
            'Authorization': 'Bearer my-token',
            'My-Custom-Header': 'foobar',
            'x-api-key': '$2y$10$bcT1Ukm4V/6/z5GvitZv0unu8I91mpixuwUO6z5CyEGev9DDDY28W'
        };

        let direccionFile = "https://datapi.psgestion.es/comision/listado?id=" + companyIdUser + "&user=" + user;

        await axios.get(direccionFile, { headers, params: { valores } }).
            then(result => {

                if (result.data.rows.length > 0) {
                    let data = result.data.rows
                    //this.state.result.data


                    this.setState({
                        comisionDetailTotal: result.data.rows,
                    });



                } else {
                    console.log('Failure')
                    //this.warningAlertNIF()
                };

            }).catch((error) => {
                console.log('Failure')
                //this.warningAlertDB()
                //this.warningAlert()
            });

    };

    fetchComisionesFacturas = async (idcard1, companyIdUser1, userId1, userGroupId1, cups1) => {

        var companyIdUser = Number(this.state.companyIdUser);
        var user = this.state.userId.toString()
        var CIF = ""
        var nombre = ""
        var apellidos = ""
        var cups = cups1

        var dataFilters = {
            'idCompany': "",
            'cupsFactura': cups,
            'Usuario': "",
            'NIFComercial': CIF,
            'nombreComercial': nombre,
            'apellido1Comercial': apellidos
        };

        const valores = JSON.stringify(dataFilters)
        const headers = {
            'Authorization': 'Bearer my-token',
            'My-Custom-Header': 'foobar',
            'x-api-key': '$2y$10$bcT1Ukm4V/6/z5GvitZv0unu8I91mpixuwUO6z5CyEGev9DDDY28W'
        };

        let direccionFile = "https://datapi.psgestion.es/comision/listadofacturas?id=" + companyIdUser + "&user=" + user;

        await axios.get(direccionFile, { headers, params: { valores } }).
            then(result => {

                if (result.data.rows.length > 0) {
                    let data = result.data.rows
                    let dataNew = []
                    //this.state.result.data

                    data.forEach(value => {
                        let existe = false;
                        // @ts-ignore
                        var comisionCalculo = value.consumoM3 * this.state.comisionDetail.comisionYear1
                        // @ts-ignore
                        value.comision = comisionCalculo
                        //dataNew.push({data, comision:comisionCalculo} )

                    });

                    var mostRecentDate = new Date(Math.max.apply(null, data.map(e => {
                        return new Date(e.fechAlta);
                    })));
                    var mostRecentObject = data.filter(e => {
                        var d = new Date(e.fechAlta);
                        return d.getTime() == mostRecentDate.getTime();
                    })[0];

                    const sumTotalComisionFacturas = data.reduce((accumulator, object) => {
                        return accumulator + object.comision;
                    }, 0);


                    this.setState({
                        comisionFacturaTotal: data,
                        totalFacturas: data.length,
                        ultimaFactura: mostRecentObject,
                        sumTotalComisionFacturas: sumTotalComisionFacturas
                    });

                    /*            comisionFacturaTotal: {
                                    consumoM3: 0,
                                    consumokwh: 0,
                                    cups: "",
                                    fechAlta: "",
                                    idFactura: "",
                                    tipolecdescripcion: "",
                                    comision: 0
                        },

            */

                } else {
                    console.log('Failure')
                    //this.warningAlertNIF()
                };

            }).catch((error) => {
                console.log('Failure')
                //this.warningAlertDB()
                //this.warningAlert()
            });

    };




    QueryResumenChart = async (idcard1, companyIdUser1, userId1, userGroupId1) => {

        let isLoading = true;
        const isSearching = true;
        const companyId = companyIdUser1.toString()
        const userId = '-1'


        var userIdAux = userId1.toString();
        var userQuery = userId1.toString();
        var idCard = idcard1;

        if (userGroupId1 === 1000) {
            userIdAux = "1000"
        } else {
            userIdAux = userId1.toString();
        }

        var userTime = "S"

        const headers = {
            'Authorization': 'Bearer my-token',
            'My-Custom-Header': 'foobar',
            'x-api-key': '$2y$10$bcT1Ukm4V/6/z5GvitZv0unu8I91mpixuwUO6z5CyEGev9DDDY28W'
        };
        let direccionFile = "https://datapi.psgestion.es/cuadro/chartAceptados?id=" + companyId + "&user=" + userIdAux + "&time=" + userTime + "";


        await axios.get(direccionFile, { headers }).
            then(result => {

                const data = result.data.rows

                this.setState({
                    dataChart1: data
                });
            }).catch((error) => {
                console.log('Failure ', error)
            });

    };
    //////////////////////////////////////////////////////////////////////////////////////



    handleClearRows = () => {
        this.setState({ toggledClearRows: !this.state.toggledClearRows })
    };


    handleChange = state => {
        // eslint-disable-next-line no-console

        /*    comisionDetail: {
                comercial: "",
                comisionContratoConsumoAgente1: 0,
                comisionContratoConsumoAgente2: 0,
                comisionContratoFija: 0,
                comisionYear1: 0,
                comisionYear2: 0,
                consumo: 0,
                cups: "",
                fechaFirmaContrato: "",
                fechaPropuesta: "",
                idContrato: "",
                tipoCliente: ""
            },
    */

        var comercial = '';

        if (state.selectedCount === 1) {
            var existeComercial = ''
            comercial = state.selectedRows[0].comercial;

            this.setState({ comisionDetail: state.selectedRows[0] });

            let existeFechaAlta = state.selectedRows[0].createdAt
            let existeFechaBaja = state.selectedRows[0].fechaBaja
            existeComercial = state.selectedRows[0].comercial

            if (existeComercial) {

                let data = state.selectedRows[0]


                this.setState({ idContrato: this.state.comisionDetail.idContrato });
                this.setState({

                    formComision: {
                        comercial: state.selectedRows[0].comercial,
                        comisionContratoConsumoAgente1: state.selectedRows[0].comisionContratoConsumoAgente1,
                        comisionContratoConsumoAgente2: state.selectedRows[0].comisionContratoConsumoAgente2,
                        comisionContratoFija: state.selectedRows[0].comisionContratoFija,
                        comisionYear1: state.selectedRows[0].comisionYear1,
                        comisionYear2: state.selectedRows[0].comisionYear2,
                        consumo: state.selectedRows[0].consumo,
                        cups: state.selectedRows[0].cups,
                        fechaFirmaContrato: state.selectedRows[0].fechaFirmaContrato,
                        fechaPropuesta: state.selectedRows[0].fechaPropuesta,
                        idContrato: state.selectedRows[0].idContrato,
                        tipoCliente: state.selectedRows[0].tipoCliente
                    },

                    comisionSeleccionado: true
                });

                this.fetchComisionesFacturas(this.state.idCard, this.state.companyIdUser, this.state.userId, this.state.userGroupId, state.selectedRows[0].cups)

            } else {
                this.setState({
                    comisionSeleccionado: false,
                    formComision: {
                        comercial: "",
                        comisionContratoConsumoAgente1: 0,
                        comisionContratoConsumoAgente2: 0,
                        comisionContratoFija: 0,
                        comisionYear1: 0,
                        comisionYear2: 0,
                        consumo: 0,
                        cups: "",
                        fechaFirmaContrato: "",
                        fechaPropuesta: "",
                        idContrato: "",
                        tipoCliente: ""
                    }
                });
            }
        } else {

            this.setState({
                comisionSeleccionado: false,
                inputDisabled: false,
                updateRepresentanteButtonDisabled: true,
                formComision: {
                    comercial: "",
                    comisionContratoConsumoAgente1: 0,
                    comisionContratoConsumoAgente2: 0,
                    comisionContratoFija: 0,
                    comisionYear1: 0,
                    comisionYear2: 0,
                    consumo: 0,
                    cups: "",
                    fechaFirmaContrato: "",
                    fechaPropuesta: "",
                    idContrato: "",
                    tipoCliente: ""
                }
            });



        };

    }


    onChangeFieldComision = (event) => {

    };

    ///////////////////////////////////////////////////////////////////////////////////////////



    render() {

        var datosAux = [];
        var datosAuxE = [];
        const formComision: any = this.state.formComision;




        this.state.comisionDetailTotal.forEach(value => {
            let existe = false;
            if (datosAux.length > 0) {
                datosAux.forEach(value2 => {


                    // @ts-ignore
                    //if( value.Fecha === value2.Fecha && value.TarifaPeaje === value2.TarifaPeaje) {
                    if (value.nombreCompleto === value2.name) {

                        // @ts-ignore
                        value2.value = value2.value + (value.comisionYear1 * value.consumo);
                        // @ts-ignore

                        value2.energia = (value2.energia + value.consumo) / 1000
                        existe = true;
                        return existe;
                    }
                });
                if (!existe) {

                    datosAux.push({
                        // @ts-ignore
                        //date : value.Fecha,
                        // @ts-ignore
                        name: value.nombreCompleto,
                        // @ts-ignore
                        value: (value.comisionYear1 * value.consumo),
                        // @ts-ignore
                        energia: value.consumo / 1000
                    })

                }
            } else {

                datosAux.push({
                    // @ts-ignore
                    //date : value.Fecha,
                    // @ts-ignore
                    name: value.nombreCompleto,
                    // @ts-ignore
                    value: (value.comisionYear1 * value.consumo),
                    // @ts-ignore
                    energia: value.consumo
                })

            }
        });


        var option4 = {
            title: {
                text: 'Resumen de comisiones/comerciales',
                left: 'left'
            },
            grid: {
                left: '3%',
                right: '4%',
                bottom: '3%'
            },
            tooltip: {
                trigger: 'item',
                formatter: function (params) {
                var val = format(params.value);
                return val;
                }
            },
            legend: {
                top: 'middle',
                left: 'right',
                orient: 'vertical'
            },
            series: [
                {
                    name: 'Comercial',
                    type: 'pie',
                    radius: ['40%', '70%'],
                    avoidLabelOverlap: false,
                    itemStyle: {
                        borderRadius: 10,
                        borderColor: '#fff',
                        borderWidth: 2
                    },
                    label: {
                        show: false,
                        position: 'center'
                    },
                    emphasis: {
                        label: {
                            show: true,
                            fontSize: '30',
                            fontWeight: 'bold'
                        }
                    },
                    labelLine: {
                        show: false
                    },
                    data: datosAux
                }
            ]
        };

        var option5 = {
            title: {
                text: 'Relación comercial/consumo/comisión',
                left: 'left'
            },
            dataset: {
                dimensions: ['name', 'value', 'energia'],
                source: datosAux
            },
            tooltip: {
                trigger: 'axis',
                formatter: function (params) {
            return ` ${params[0].name} <br />
                Comision: ${params[0].data.value.toLocaleString('es-ES', {style: 'currency', currency: 'EUR'})}<br />
                Energía Contrada: ${params[1].data.energia.toLocaleString("es-ES", {maximumFractionDigits: 2})+" MW"}`;
    }
            },
            grid: {
                left: '15%',
                right: '14%'
            },
            xAxis: {
                type: 'category'
            },
            yAxis: [
                {
                    type: 'value',
                    name: 'Comisión €',
                    position: 'left',
                    alignTicks: true,
                    axisLine: {
                        show: true,
                        lineStyle: {
                            color: colors[0]
                        }
                    },
                },
                {
                    type: 'value',
                    name: 'MW Contratados',
                    position: 'right',
                    alignTicks: true,
                    axisLine: {
                        show: true,
                        lineStyle: {
                            color: colors[1]
                        },
                        axisLabel: {
                            formatter: '{value} MW'
                        }
                    },
                }],
            series: [
                {
                    name: 'Comisión',
                    type: 'bar',
                    yAxisIndex: 0,
                    barWidth: '60%',
                    encode: {
                        x: 'name',

                    }

                },
                {
                    name: 'MW Contratados Anual',
                    type: 'line',
                    yAxisIndex: 1,
                    barWidth: '60%',
                    encode: {
                        y: 'energia',

                    }

                }
            ]
        };


        return (
            <>
                <Header title={'Información Comisiones'} />
                <Container className=" mt--7" fluid>

                    <Card className="shadow">
                        <CardBody>
                            <DataTable
                                title="LISTADO DE COMISIONES"
                                columns={columnsDatosComision}
                                data={this.state.comisionDetailTotal}
                                selectableRows
                                //selectableRowsComponent={radio} // Pass the function only
                                selectableRowsComponentProps={{ type: 'radio' }}// optionally, pass Material Ui supported props down to our custom checkbox
                                selectableRowsSingle
                                sortIcon={sortIcon} // use a material icon for our sort icon. rdt will rotate the icon 180 degrees for you           fixedHeader = {true}
                                noDataComponent={"Sin datos para mostrar"}
                                pagination
                                paginationPerPage={10}
                                paginationRowsPerPageOptions={[10, 15, 20, 25, 30, 40]}
                                onSelectedRowsChange={this.handleChange}
                                clearSelectedRows={this.state.toggledClearRows}
                                highlightOnHover
                                striped
                                pointerOnHover
                                selectableRowsHighlight
                                customStyles={customStyles}
                                contextMessage={{ singular: 'Representante', plural: 'Representantes', message: 'seleccionado' }}
                                subHeader
                                progressComponent={<Circular />}
                            />
                        </CardBody>
                    </Card>

                    <Row >
                        <div className="col m-2 d-flex">
                            <Button color="primary" size="sm" type="button"
                                className="btn-icon btn-sm btn-circle"
                                onClick={this.props.history.goBack}
                            >
                                <span className="bi bi-box-arrow-left"> Volver</span>
                            </Button>

                        </div>
                    </Row>

                    <Row>
                        <div className="col">
                            <Card className="shadow">
                                <CardBody>

                                    <div>
                                        <CardTitle>DATOS DE LA COMISION PREVISTA</CardTitle>
                                    </div>

                                    <Row>
                                        <Col>
                                            <FormGroup>

                                                <Label className="form-control-label" htmlFor="comercial">
                                                    Comercial
                                                                                                </Label>
                                                <Input
                                                    className="text-primary"
                                                    id="comercial"
                                                    placeholder=""
                                                    name="comercial"
                                                    onChange={this.onChangeFieldComision}
                                                    value={formComision.comercial}
                                                    type="text"
                                                    required
                                                    disabled={this.state.inputDisabled}
                                                />
                                            </FormGroup>
                                        </Col>

                                        <Col>
                                            <FormGroup>

                                                <Label className="form-control-label" htmlFor="comisionContratoConsumoAgente1">
                                                    Comisión Producto Año 1
                                                                                                </Label>
                                                <Input
                                                    id="comisionContratoConsumoAgente1"
                                                    placeholder=""
                                                    name="comisionContratoConsumoAgente1"
                                                    onChange={this.onChangeFieldComision}
                                                    value={formComision.comisionContratoConsumoAgente1?.toLocaleString("es-ES", {style: 'currency', currency: 'EUR'})||"0"+" €"}
                                                    type="text"
                                                    required
                                                    disabled={this.state.inputDisabled}
                                                />
                                            </FormGroup>
                                        </Col>

                                        <Col>
                                            <FormGroup>

                                                <Label className="form-control-label" htmlFor="comisionContratoConsumoAgente2">
                                                    Comisión Producto Año 2
                                                                                                </Label>
                                                <Input
                                                    id="comisionContratoConsumoAgente2"
                                                    placeholder=""
                                                    name="comisionContratoConsumoAgente2"
                                                    onChange={this.onChangeFieldComision}
                                                    value={formComision.comisionContratoConsumoAgente2?.toLocaleString("es-ES", {style: 'currency', currency: 'EUR'})||"0"+" €"}
                                                    type="text"
                                                    required
                                                    disabled={this.state.inputDisabled}
                                                />
                                            </FormGroup>
                                        </Col>

                                        <Col>
                                            <FormGroup>

                                                <Label className="form-control-label" htmlFor="cups">
                                                    CUPS
                                                                                                </Label>
                                                <Input
                                                    id="cups"
                                                    placeholder=""
                                                    name="cups"
                                                    onChange={this.onChangeFieldComision}
                                                    value={formComision.cups}
                                                    type="text"
                                                    required
                                                    disabled={this.state.inputDisabled}
                                                />
                                            </FormGroup>
                                        </Col>



                                        <Col>
                                            <FormGroup>
                                                <Label className="form-control-label" htmlFor="idContrato">
                                                    Contrato
                                                                                                </Label>
                                                <Input
                                                    id="idContrato"
                                                    placeholder=""
                                                    name="idContrato"
                                                    onChange={this.onChangeFieldComision}
                                                    value={formComision.idContrato}
                                                    type="text"
                                                    required
                                                    disabled={this.state.inputDisabled}
                                                />
                                            </FormGroup>
                                        </Col>

                                        { /*   comisionYear1: 0,
                                                    comisionYear2: 0,
                                                    fechaPropuesta: "",

                                                    tipoCliente: "" */}

                                        <Col>
                                            <FormGroup>
                                                <Label className="form-control-label" htmlFor="fechaFirmaContrato">
                                                    FIRMA DE CONTRATO
                                                                                                </Label>
                                                <Input
                                                    id="fechaFirmaContrato"
                                                    placeholder=""
                                                    name="fechaFirmaContrato"
                                                    onChange={this.onChangeFieldComision}
                                                    value={formComision.fechaFirmaContrato}
                                                    type="text"
                                                    required
                                                    disabled={this.state.inputDisabled}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md="2">
                                            <FormGroup>

                                                <Label className="form-control-label" htmlFor="consumo">
                                                    Consumo Anual
                                                                                                </Label>
                                                <Input
                                                    className="text-primary"
                                                    id="consumo"
                                                    placeholder=""
                                                    name="consumo"
                                                    onChange={this.onChangeFieldComision}
                                                    value={formComision.consumo}
                                                    type="text"
                                                    required
                                                    disabled={this.state.inputDisabled}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>


                            <Row>
                                <div className="col">
                                    <Card className="shadow">
                                        <CardBody>

                                            <div>
                                                <CardTitle>DATOS DE LA COMISION REAL ACUMULADA</CardTitle>
                                            </div>


                                            <CardBody>
                                                <DataTable
                                                    title="LISTADO DE COMISIONES REALES"
                                                    columns={columnsDatosComisionFactura}
                                                    data={this.state.comisionFacturaTotal}

                                                    sortIcon={sortIcon} // use a material icon for our sort icon. rdt will rotate the icon 180 degrees for you           fixedHeader = {true}
                                                    noDataComponent={"Sin datos para mostrar"}
                                                    pagination
                                                    paginationPerPage={10}
                                                    paginationRowsPerPageOptions={[10, 15, 20, 25, 30, 40]}

                                                    clearSelectedRows={this.state.toggledClearRows}
                                                    highlightOnHover
                                                    striped
                                                    pointerOnHover
                                                    selectableRowsHighlight
                                                    customStyles={customStyles}
                                                    contextMessage={{ singular: 'Representante', plural: 'Representantes', message: 'seleccionado' }}
                                                    subHeader
                                                    progressComponent={<Circular />}
                                                />
                                            </CardBody>

                                            <Row>
                                            </Row>
                                        </CardBody>
                                    </Card>
                                </div>
                            </Row>


                        </div>
                    </Row>

                </Container>
                {/*comercial: "",
                                      comisionContratoConsumoAgente1: 0,
                                      comisionContratoConsumoAgente2: 0,
                                      comisionContratoFija: 0,
                                      comisionYear1: 0,
                                      comisionYear2: 0,
                                      consumo: 0,
                                      cups: "",
                                      fechaFirmaContrato: "",
                                      fechaPropuesta: "",
                                      idContrato: "",
                                      tipoCliente: ""*/}

                <Container fluid>
                    <Row className="margin-reset w-100">
                        <Col md="6" xl="3">
                            <Card className="card-stats">
                                <CardBody>
                                    <Row>
                                        <div className="col">
                                            <CardTitle
                                                tag="h5"
                                                className="text-uppercase text-muted mb-0"
                                            >
                                                TOTAL FACTURAS
         </CardTitle>
                                            <span className="h2 font-weight-bold mb-0">
                                                {this.state.totalFacturas || 0}
                                            </span>
                                        </div>
                                        <Col className="col-auto">
                                            <div className="icon icon-shape bg-gradient-red text-white rounded-circle shadow">
                                                <i className="bi bi-files" />
                                            </div>
                                        </Col>
                                    </Row>
                                    <p className="mt-3 mb-0 text-sm">
                                        <span className="text-nowrap">Facturas emitidas al cliente</span>
                                    </p>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col md="6" xl="3">
                            <Card className="card-stats">
                                <CardBody>
                                    <Row>
                                        <div className="col">
                                            <CardTitle
                                                tag="h5"
                                                className="text-uppercase text-muted mb-0"
                                            >
                                                ULT. FACTURA
                                         </CardTitle>

                                            <span className="h2 font-weight-bold mb-0">
                                                {this.state.ultimaFactura.fechAlta || "SIN DATOS"}
                                            </span>
                                        </div>
                                        <Col className="col-auto">
                                            <div className="icon icon-shape bg-gradient-red text-white rounded-circle shadow">
                                                <i className="bi bi-calendar-event" />
                                            </div>
                                        </Col>
                                    </Row>
                                    <p className="mt-3 mb-0 text-sm">
                                        {" "}
                                        <span className="text-nowrap">Fecha última factura cliente</span>
                                    </p>
                                </CardBody>
                            </Card>
                        </Col>



                        <Col md="6" xl="3">
                            <Card className="card-stats">
                                <CardBody>
                                    <Row>
                                        <div className="col">
                                            <CardTitle
                                                tag="h5"
                                                className="text-uppercase text-muted mb-0"
                                            >
                                                Comisiones
             </CardTitle>

                                            <span className="h2 font-weight-bold mb-0">
                                                {this.state.sumTotalComisionFacturas.toLocaleString("es-ES", {maximumFractionDigits: 2}) || 0} €
                    </span>
                                        </div>
                                        <Col className="col-auto">
                                            <div className="icon icon-shape bg-gradient-red text-white rounded-circle shadow">
                                                <i className="bi bi-calendar-event" />
                                            </div>
                                        </Col>
                                    </Row>
                                    <p className="mt-3 mb-0 text-sm">
                                        {" "}
                                        <span className="text-nowrap">Total comisiones sobre facturas</span>
                                    </p>
                                </CardBody>
                            </Card>
                        </Col>

                    </Row>
                </Container>

                <Container fluid>
                    <Row className="margin-reset w-100">
                        <Col md="6" xl="6">
                            <Card className="card-stats">
                                <CardBody>
                                    <ReactEChartsCore
                                        echarts={echarts}
                                        option={option4}
                                        style={{ height: 300, width: 400 }}
                                        theme={"macarons"}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                        <Col md="6" xl="6">
                            <Card className="card-stats">
                                <CardBody>
                                    <ReactEChartsCore
                                        echarts={echarts}
                                        option={option5}
                                        style={{ height: 300, width: 400 }}
                                        theme={"roma"}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>


            </>
        );
    }
}

export default withApollo(Comisiones);
