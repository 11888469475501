import React from "react";
import Reflux from "reflux";
import axios from 'axios';
import { customAlphabet } from "nanoid";
import {
    Button,
    Card,
    CardBody,
    Label,
    CardTitle,
    Container,
    FormGroup,
    Input,
    Row,
} from "reactstrap";
import { withApollo } from "react-apollo";
import LoadingOverlay from "react-loading-overlay";
import moment from "moment";
import 'moment/locale/es'
//import { uuid } from "uuidv4";
//import { uuid } from 'uuidv4';
import { validateSpanishId, spainIdType } from 'spain-id'
import uuid from "react-uuid";

// core components
import SipsTable from "../../../components/Sips/SipsTableNew";
import SupplyGasInformation from "../../../components/Cards/SupplyGasInformation";
import RateGasInformation from "../../../components/Cards/RateGasInformation";
import Header from "../../../components/Headers/Header";
import { VIEW_SIPSGAS } from "../../../queries/sips/sipsGas.graphql";
import { consumptionsFields, sipsConsumosFields } from "./SipGasComparativesData";
//import { LIST_SIPS_UBICACION } from "../../../queries/ubicacion/sip-ubicacion";
import { LIST_SIPSGAS_CONSUMOS } from "../../../queries/sips-consumo/sipGas-consumo";
import SipGasComparativeFreeModal from "./SipGasComparativeFreeModal";
import SipGasComparativeFreeMultiModal from "./SipGasComparativeFreeMultiModal";
import ComercialGasProducto from "./ComercialGasProducto";
import ComercialGasMultiProducto from "./ComercialGasMultiProducto"
import SaveGasModal from "./SaveGasModal";
import ReactBSAlert from "react-bootstrap-sweetalert";
import {
    CREATE_SIPS_COMPARATIVAS_LIBRE,
    LIST_SIPS_COMPARATIVAS_LIBRE_BY_CUP,
} from "../../../queries/sips-comparativa/sip-comparativa-libre";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import GetUserData from "../../../components/User/GetUserData";
import { UserStore } from "../../../store/UserStore";

const alphabet = '0123456789ABCD';
const nanoid = customAlphabet(alphabet, 11);

const notify = () => toast.success(
    "Oferta guardadas correctamente",
    {
        position: "bottom-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });

interface IComparativeFreeState {
    cups: string;
UserStore: any;
    user: any;
    sip: any;
    nombrePropuesta: string;
    ubicacion: any,
    consumptionsFields: any;
    sipsConsumos: any[];
    periodos: number[];
    producto: {
        active: boolean;
        productoSeleccionado: any;
        costeOperativo: number;
        costeOperativoPotencia: number;
        mibGasPrice: number;
    };
    productoMulti: {
        active: boolean;
        productoSeleccionado: any[];
        costeOperativo: number;
        costeOperativoPotencia: number;
    };
    tableHeaders: any[];
    comparatives: any[];
    isDisabled: boolean;
    isLoading: boolean;
    isLoadingConsumos: boolean;
    itemsSelected: any[];
    openModalComparative: boolean;
    openModalMultiComparative: boolean;
    comercialProductoSelected: any;
    showList: boolean;
    dataComparativeTarget: any;
    saveModalOpened: boolean;
    datosContrato: {
        Usuario: string,
        fechaPropuesta: string,
        FechaAprobacion: string,
        IdOferta: string,
        T_Titular: {
            RazonSocial: string, //":"MANUEL GONZALEZ",--> nombre_completo_titular
            NIF: string, //"C19345456", --> CIF
            DireccionFiscal: string, // "C/ FELIPE SOLANO ANTELO 8A 8º3", ---> direccion_titular
            CodigoPostalTitular: string, //"08630",----> codigo_postal_titular
            TelefonoFijo: string, //"949201893", ----> telefono_fijo
            TelefonMovil: string, //"689105332", ----> telefono
            Email: string, //"demo@demo.com", --->email_contacto
            TipoCliente: string, //"COMUNIDAD DE PROPIETARIOS", ---> tipo_cliente
            ObservacionesCliente: string,
            GDPR: boolean, //"OPCIONAL O NULL",----> observaciones
        },
        T_PuntoSuministro: {
            Direccion: string, // "C/ FELIPE SOLANO ANTELO 8A 8º3", ---> direccion_ps
            CodigoPostal: string, //"08630", ----> codigo_postal_ps
            Telefono: string, //"689105332", ----> telefono;
            ObservacionesPuntoSuministro: string, // "opcional o null" ----> observaciones_ps
        },
        T_CUPs: {
            Distribuidora: string, //"IBERDROLA", ----> distribuidora
            CUPsEle: string, //"ES0022000008945731EY", ------> cupShort
            Tarifa: string, //"2.0 DHS", ----> tarifaATR
            P1: string, //"35.25", ----> potenciasContratadasEnWP1
            P2: string, //"35.25", ----> potenciasContratadasEnWP2
            P3: string, //"35.25", ----> potenciasContratadasEnWP3
            P4: string, //"35.25", ----> potenciasContratadasEnWP4
            P5: string, //"35.25", ----> potenciasContratadasEnWP5
            P6: string, //"25.25", ----> potenciasContratadasEnWP6
            PotMaxBie: string, //"5265.25",   ----->Bie
            TipoServicio: string, //"1 Eléctrico, 2 Gas",  ----> TipoServicio
            Consumo: string, //"525663.25",    -----> sumaConsumoEnergiaActivaTotal
        },
        T_PropuestaComercial: {
            PorcentajeAhorro: string, //"0.00",
            ImporteAhorro: string, //"0.00",
            Estado: string, //"P",
            Comercializadora: string, //"AUDAX RENOVABLES S.A.",
            Producto: string, //"CLASSIC",
            IdProducto: string,
            Anexo: string, //"12 MESES T2",
            TipoPrecio: string, //"0 Fijo 1 Indexado",
            ObservacionesProducto: string, //"opcinal o null",
            GastoOpertativo: string, //"0.00 SOLO CUANDO ES CORPORATE",
            idservicio: string,
            idComercializadora: string,
            nombreServicio: string,
            precioServicio: string,
            productSelected: any
        },

    },
    rates2X: any[];
    rates3X: any[];
    rates2XP: any[];
    rates1XP: any[];
    rates3XP: any[];
    rates6XP: any[];
    rates1XE: any[];
    rates2XE: any[];
    rates3XE: any[];
    rates6XE: any[];
    companyID: any;
    userId: any;
    nombreAgente: any;
    companyName: any;
    correoAgente: any;
    comparativeTemp: {
        producto: any,
        enabledSave: boolean,
        actual: any,
        comparativaGlobal: any
    };
    alert: any
}

interface IComparativeFreeProps { }

class SipGasComparativeFree extends Reflux.Component<any, any> {
    private dataToSave = {
        companyId: null,
        nombreOferta: null,
        idComercializadora: null,
        nombreComercializadora: null,
        idComercializadoraActual: null,
        nombreComercializadoraActual: null,
        datosPreciosActual: null,
        userId: null,
        fechaPropuesta: null,
        codigoPostalPS: null,
        codigoMunicipioPS: null,
        nombreMunicipioPS: null,
        codigoProvinciaPS: null,
        nombreProvinciaPS: null,
        codigoAutonomiaPS: null,
        nombreAutonomiaPS: null,
        esAprobada: false,
        datosPropuesta: null,
        correoContacto: null,
        cups: null,
        //datosContrato: null,
    };
    //public comparativeTemp: any = null;
    //this.state.comparativeTemp.producto
    public enabledSave: boolean = false;
    public formComparativeRef: any = null;
    public formSaveComparativeRef: any = null;
    public UserStore: any = {};

    public state: IComparativeFreeState = {
        comparativeTemp: {
            producto: [{ tarifaATR: '000' }],
            enabledSave: false,
            actual: [],
            comparativaGlobal: {}
        },
        cups: "",
        user: {},
        sip: {},
        ubicacion: {},
        nombrePropuesta: "",
        consumptionsFields: {},
        sipsConsumos: [],
        itemsSelected: [],
        comercialProductoSelected: null,
        tableHeaders: [],
        comparatives: [],
        periodos: [],
        producto: {
            active: false,
            productoSeleccionado: null,
            costeOperativo: 0,
            costeOperativoPotencia: 0,
            mibGasPrice: 0
        },
        productoMulti: {
            active: false,
            productoSeleccionado: [],
            costeOperativo: 0,
            costeOperativoPotencia: 0,
        },

        // --- View State
        isDisabled: true,
        isLoading: true,
        isLoadingConsumos: true,
        openModalComparative: false,
        openModalMultiComparative: false,
        showList: true,
        dataComparativeTarget: null,
        saveModalOpened: false,
        datosContrato: {
            Usuario: "",
            fechaPropuesta: "",
            FechaAprobacion: "",
            IdOferta: "",
            T_Titular: {
                RazonSocial: "", //":"MANUEL GONZALEZ",--> nombre_completo_titular
                NIF: "", //"C19345456", --> CIF
                DireccionFiscal: "", // "C/ FELIPE SOLANO ANTELO 8A 8º3", ---> direccion_titular
                CodigoPostalTitular: "", //"08630",----> codigo_postal_titular
                TelefonoFijo: "", //"949201893", ----> telefono_fijo
                TelefonMovil: "", //"689105332", ----> telefono
                Email: "", //"demo@demo.com", --->email_contacto
                TipoCliente: "", //"COMUNIDAD DE PROPIETARIOS", ---> tipo_cliente
                ObservacionesCliente: "",
                GDPR: false, //"OPCIONAL O NULL",----> observaciones
            },
            T_PuntoSuministro: {
                Direccion: "", // "C/ FELIPE SOLANO ANTELO 8A 8º3", ---> direccion_ps
                CodigoPostal: "", //"08630", ----> codigo_postal_ps
                Telefono: "", //"689105332", ----> telefono;
                ObservacionesPuntoSuministro: "", // "opcional o null" ----> observaciones_ps
            },
            T_CUPs: {
                Distribuidora: "", //"IBERDROLA", ----> distribuidora
                CUPsEle: "", //"ES0022000008945731EY", ------> cupShort
                Tarifa: "", //"2.0 DHS", ----> tarifaATR
                P1: "0", //"35.25", ----> potenciasContratadasEnWP1
                P2: "0", //"35.25", ----> potenciasContratadasEnWP2
                P3: "0", //"35.25", ----> potenciasContratadasEnWP3
                P4: "0", //"35.25", ----> potenciasContratadasEnWP4
                P5: "0", //"35.25", ----> potenciasContratadasEnWP5
                P6: "0", //"25.25", ----> potenciasContratadasEnWP6
                PotMaxBie: "", //"5265.25",   ----->Bie
                TipoServicio: "", //"1 Eléctrico, 2 Gas",  ----> TipoServicio
                Consumo: "", //"525663.25",    -----> sumaConsumoEnergiaActivaTotal
            },
            T_PropuestaComercial: {
                PorcentajeAhorro: "", //"0.00",
                ImporteAhorro: "", //"0.00",
                Estado: "", //"P",
                Comercializadora: "", //"AUDAX RENOVABLES S.A.",
                Producto: "", //"CLASSIC",
                IdProducto: "",
                Anexo: "", //"12 MESES T2",
                TipoPrecio: "", //"0 Fijo 1 Indexado",
                ObservacionesProducto: "", //"opcinal o null",
                GastoOpertativo: "", //"0.00 SOLO CUANDO ES CORPORATE",
                idservicio: "",
                idComercializadora: "",
                nombreServicio: "",
                precioServicio: "",
                productSelected: null

            },
        },
        rates2X: [
            '001',
            '004',
            '005',
            '006',
            '007',
            '008'
        ],
        rates3X: [
            '011',
            '003',
            '012',
            '013',
            '014',
            '015',
            '016'
        ],
        rates2XP: [
            '018',
        ],
        rates1XP: [
            '001',
            '004',
            '005',
            '006',
            '007',
            '008'
        ],
        rates3XP: [
            '011',
            '003'
        ],
        rates6XP: [
            '012',
            '013',
            '014',
            '015',
            '016',
            '019',
            '020',
            '021',
            '022',
            '023',
            '024',
            '025'
        ],
        rates1XE: [
            '001',
            '005'
        ],
        rates2XE: [
            '004',
            '006'
        ],
        rates3XE: [
            '011',
            '003',
            '007',
            '008',
            '018'
        ],
        rates6XE: [
            '012',
            '013',
            '014',
            '015',
            '016',
            '019',
            '020',
            '021',
            '022',
            '023',
            '024',
            '025'
        ],
        companyID: "",
        userId: "",
        nombreAgente: "",
        companyName: "",
        correoAgente: "",
        UserStore: {},
        alert: null
    };

    constructor(props: IComparativeFreeProps) {
        super(props);
        this.showModal = this.showModal.bind(this);
this.store = UserStore;
        this.showMultiModal = this.showMultiModal.bind(this);
        this.toggle = this.toggle.bind(this);
        this.toggleMulti = this.toggleMulti.bind(this);
        this.comparativeDataHandler = this.comparativeDataHandler.bind(this);
        //this.FunctionComponentWithRef = this.FunctionComponentWithRef.bind(this);

        this.changeSave = this.changeSave.bind(this);
    }

    comparativeDataHandler(data) {

        this.state.comparativeTemp = data;
        this.enabledSave = this.state.comparativeTemp.enabledSave
        let enabledSaveTemp: boolean = this.state.comparativeTemp.enabledSave
    }



    componentDidMount() {
        const {
            match: { params },
        } = this.props;

        //const user = localStorage.getItem("user");
        //if (user) {
        //const parsed = JSON.parse(user);
        //this.setState({ user: parsed });
        //}


        const usuario: any = JSON.parse(JSON.stringify(this.state.UserStore['data']))

        const companyNameGlobal = usuario.company;
        const companyIdUser = usuario.companyId;

        const userGroupId = usuario.user_group_id
        const userId = usuario.id
        const nombreAgente = usuario.fullname
        const correoAgente = usuario.email

        //this.setState({ companyID :companyIdUser,
        //userId: userId});
        //any,companyIdUser, companyNameUser });

        this.setState(
            {
                cups: params.cups,
                consumptionsFields: consumptionsFields,
                companyID: companyIdUser,
                userId: userId,
                user: userId,
                companyName: companyNameGlobal,
                nombreAgente: nombreAgente,
                correoAgente: correoAgente

            },
            () => {

                this.fetchSip();
                //this.fetchUbi();
                this.fetchComparatives();
            }
        );
        this.formComparativeRef = React.createRef();

    }

    hideAlert = () => {
        this.setState({
            alert: null
        });
    };

    successAlert = () => {
        this.setState({
            alert: (
                <ReactBSAlert
                    success
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Correcto"
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="success"
                    confirmBtnText="Ok"
                    btnSize=""
                >
                    Propuesta comerciale guardada correctamente
          </ReactBSAlert>
            )
        });
    };


    warningAlert = () => {
        this.setState({
            alert: (
                <ReactBSAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Sin consumos seleccionados"
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="warning"
                    confirmBtnText="Ok"
                    btnSize=""
                >
                    Tienes que seleccionar lecturas de consumos para continuar
         </ReactBSAlert>
            )
        });
    };

    warningAlertProducto = () => {
        this.setState({
            alert: (
                <ReactBSAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Sin producto seleccionado"
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="warning"
                    confirmBtnText="Ok"
                    btnSize=""
                >
                    Tienes que seleccionar un producto para poder guardar
         </ReactBSAlert>
            )
        });
    };


    fetchSip = async () => {
        let tableHeaders = {};
        let periodos: number[] = [];
        await this.props.client
            .query({
                query: VIEW_SIPSGAS,
                variables: { cups: this.state.cups },
            })
            .then((result) => {
                //Sip
                const sip = result.data.viewSipsGas[0];

                //Header tabla
                //let codigoEquipo = "codigoDHEquipoDeMedida";
                let codigoTipoLectura = "codigoTipoLectura";
                let fechaInicioMes = "fechaInicioMesConsumo"
                let fechaFinMes = "fechaFinMesConsumo";
                let codigoTarifa = "codigoTarifaPeaje";
                let caudalMedioEnWhdia = "caudalMedioEnWhdia";
                let caudaMinimoDiario = "caudaMinimoDiario";
                let caudaMaximoDiario = "caudaMaximoDiario";
                let porcentajeConsumoNocturno = "porcentajeConsumoNocturno";

                tableHeaders[fechaInicioMes] = sipsConsumosFields[fechaInicioMes];
                tableHeaders[fechaFinMes] = sipsConsumosFields[fechaFinMes];
                tableHeaders[codigoTarifa] = sipsConsumosFields[codigoTarifa];

                var periodosNuevos = 0;
                if (sip.codigoPeajeEnVigor == '35') {
                    periodosNuevos = 2;
                } else { periodosNuevos = 1 };

                for (let i = 1; i <= periodosNuevos; i++) {
                    let consumoAnual = `ConsumoAnualEnWhP${i}`;
                    //  if (sip[consumoAnual] > 0) {
                    let consumoEnergiaActiva = `consumoEnWhP${i}`;

                    tableHeaders[consumoEnergiaActiva] =
                        sipsConsumosFields[consumoEnergiaActiva];
                    periodos.push(i);
                    //}
                }

                tableHeaders[caudalMedioEnWhdia] = sipsConsumosFields[caudalMedioEnWhdia];
                tableHeaders[caudaMinimoDiario] = sipsConsumosFields[caudaMinimoDiario];
                tableHeaders[caudaMaximoDiario] = sipsConsumosFields[caudaMaximoDiario];
                tableHeaders[porcentajeConsumoNocturno] = sipsConsumosFields[porcentajeConsumoNocturno];
                tableHeaders[codigoTipoLectura] = sipsConsumosFields[codigoTipoLectura];

                this.setState(
                    {
                        sip: sip,
                        isLoading: false,
                        tableHeaders: tableHeaders,
                        periodos: periodos,
                    },
                    this.fetchItems
                );
            });
    };


    fetchItems = async () => {


        await this.props.client
            .query({
                query: LIST_SIPSGAS_CONSUMOS,
                variables: { cups: this.state.cups },
            })
            .then((result) => {

                let listSipsConsumos = [
                    ...result.data.listSipsGasConsumos.map((row) => {
                        //Header tabla
                        let rowResult: any = {};

                        let codigoTipoLectura = "codigoTipoLectura";
                        let fechaInicioMes = "fechaInicioMesConsumo"
                        let fechaFinMes = "fechaFinMesConsumo";
                        let codigoTarifa = "codigoTarifaPeaje";
                        let caudalMedioEnWhdia = "caudalMedioEnWhdia";
                        let caudaMinimoDiario = "caudaMinimoDiario";
                        let caudaMaximoDiario = "caudaMaximoDiario";
                        let porcentajeConsumoNocturno = "porcentajeConsumoNocturno";

                        const dateValueInicio = new Date(row[fechaInicioMes]);
                        let dateInicio = dateValueInicio.toISOString().split("T")[0];
                        dateInicio = moment
                            .parseZone(dateValueInicio.toISOString().split("T")[0])
                            .format("DD-MM-YYYY");

                        const dateValueFin = new Date(row[fechaFinMes]);
                        let dateFin = dateValueFin.toISOString().split("T")[0];
                        dateFin = moment
                            .parseZone(dateValueFin.toISOString().split("T")[0])
                            .format("DD-MM-YYYY");

                        rowResult[fechaInicioMes] = dateInicio;
                        rowResult[fechaFinMes] = dateFin;
                        rowResult[codigoTarifa] = row[codigoTarifa];
                        for (let i of this.state.periodos) {
                            let consumoEnergiaActiva = `consumoEnWhP${i}`;

                            rowResult[consumoEnergiaActiva] = parseFloat(row[consumoEnergiaActiva]);
                        }
                        //  rowResult[codigoEquipo] = row[codigoEquipo];


                        rowResult[caudalMedioEnWhdia] = row[caudalMedioEnWhdia];
                        rowResult[caudaMinimoDiario] = row[caudaMinimoDiario];
                        rowResult[caudaMaximoDiario] = row[caudaMaximoDiario];
                        rowResult[porcentajeConsumoNocturno] = row[porcentajeConsumoNocturno];

                        rowResult[codigoTipoLectura] = row[codigoTipoLectura];

                        if (
                            !moment()
                                .add(-2, "year")
                                .isAfter(moment(rowResult[fechaFinMes], "DD-MM-YYYY"))
                        ) {
                            rowResult.id = this.uuid();
                            return rowResult;
                        }
                        return false;
                    }),
                ].filter((item) => item);
                //console.log(this.state);
                this.setState({
                    sipsConsumos: listSipsConsumos,
                    isLoadingConsumos: false,
                });
                this.forceUpdate();
            });
    };

    selectedItems = async (event) => {
        let items: any[] = [];
        event.map((id) => {
            items.push({
                ...this.state.sipsConsumos.filter((item) => item.id === id)[0],
            });
            return id;
        });
        this.setState({
            itemsSelected: items,
            producto: {
                ...this.state.producto,
                active: false,
            },
        });
    };

    uuid = () => {
        var dt = new Date().getTime();
        var uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
            /[xy]/g,
            function(c) {
                var r = (dt + Math.random() * 16) % 16 | 0;
                dt = Math.floor(dt / 16);
                const uidObj = c === "x" ? r : (r & 0x3) | 0x8;

                return uidObj.toString(16);
            }
        );
        return uuid;
    };

    toggle() {
        this.setState({
            openModalComparative: !this.state.openModalComparative,
        });
    }

    toggleMulti() {
        this.setState({
            openModalMultiComparative: !this.state.openModalMultiComparative,
        });
    }

    toggleModal = (event, state) => {
        event.preventDefault();

        //console.log("MODAL",state);
        //console.log("product?",this.comparativeTemp.producto);
        // if(this.comparativeTemp.producto.producto[0] == undefined){ //Si ya tenemos producto significa que no es un array
        //   this.setState({
        //     [state]: !this.state[state],
        //   });
        // }else{
        //   alert("No has seleccionado ningun producto.");
        // }
        this.setState({
            [state]: !this.state[state],
        });

    };

    fetchComparatives = async () => {
        await this.props.client
            .query({
                query: LIST_SIPS_COMPARATIVAS_LIBRE_BY_CUP,
                fetchPolicy: "no-cache",
                variables: { cups: this.state.cups },
            })
            .then((result) => {
                let data = result.data.comparativasLibresByCups;
                console.log("Comparativas cargadas :::>", data);
                this.setState({ comparatives: data });
            });
    };

    showModal() {
        this.setState({
            openModalComparative: true,
        });
    }

    showMultiModal() {
        this.setState({
            openModalMultiComparative: true,
        });
    }

    openComparativeSection = async (event) => {

        this.setState({
            producto: {
                active: true,
                productoSeleccionado: event.productoSeleccionado,
                costeOperativo: event.costeOperativo,
                costeOperativoPotencia: event.costeOperativoPotencia,
                mibGasPrice: event.mibGasPrice
            },
            showList: false,
        });

    };

    openComparativeMultiSection = async (event) => {

        this.setState({
            productoMulti: {
                active: true,
                productoSeleccionado: event.productoSeleccionado,
                costeOperativo: event.costeOperativo,
                costeOperativoPotencia: event.costeOperativoPotencia
            },
            showList: false,
        });
        //console.log("comparativeMultiSection", this.state)
    };

    onSave = async (modalData) => {

     console.log('VALOR DE MODAL DATA ----> ', modalData)


        const idOferta = nanoid();
        const now = new Date().toISOString();

        const user: any = this.state.user;
        const sip: any = this.state.sip;


        var tipoPrecio ="0";
        if(this.state.comparativeTemp.producto.datos.factork != 0){
           tipoPrecio = "1"
            }

        const producto = this.state.comparativeTemp.producto
            ? JSON.stringify(this.state.comparativeTemp.producto)
            : null;
        const productoJSON = this.state.comparativeTemp.producto
            ? this.state.comparativeTemp.producto
            : {};
        const actual = this.state.comparativeTemp.actual
            ? JSON.stringify(this.state.comparativeTemp.actual)
            : null;
        const actualJSON = this.state.comparativeTemp.actual
            ? this.state.comparativeTemp.actual
            : {};
        const datosContrato = JSON.stringify("");

        const globalJSON = this.state.comparativeTemp.comparativaGlobal
            ? this.state.comparativeTemp.comparativaGlobal
            : {};

        const dataModal: any = {
            ...{
                ...this.dataToSave
            },
            ...modalData,
        };


       console.log( 'VALOR DE DATAMODAL ---->', dataModal)

        var tarifaATRContrato = "";
        var seleccion = ''
        if (this.state.comparativeTemp.producto.producto.tarifaATR) {
            seleccion = this.state.comparativeTemp.producto.producto.tarifaATR

        }



        var tarifaATRContrato = '';

        if (seleccion != undefined) {
            const insertAt = (str, sub, pos) => `${str.slice(0, pos)}${sub}${str.slice(pos)}`;
            tarifaATRContrato = insertAt(seleccion, '.', 1);
        }

        var codigoPostal = dataModal.CodigoPostalTitular.padStart(5, "0")
        var codigoPostalPS = dataModal.CodigoPostalPS.padStart(5, "0")

        var caudalMedioEnWhdia = "0"
        if (this.state.itemsSelected[0].caudalMedioEnWhdia != undefined) {
            caudalMedioEnWhdia = this.state.itemsSelected[0].caudalMedioEnWhdia;
        }

        let gastoOperativo = "0.00"
        let gastoOperativoPotencia = "0.00"
        if (!this.state.comparativeTemp.producto.producto.coIncluido) {
            gastoOperativo = this.state.comparativeTemp.producto.costeOperativo.toString() //"0.00 SOLO CUANDO ES CORPORATE",
            gastoOperativoPotencia = this.state.comparativeTemp.producto.costeOperativoPotencia.toString()
        };

        var fechaPropuesta = moment
            .parseZone(now.split("T")[0])
            .format("YYYY-MM-DD");
        var check = (moment(fechaPropuesta, "YYYY-MM-DD"));
        var monthPropuesta = Number(check.format('M'));
        var yearPropuesta = check.year();

        var fechaAprobacion = moment
            .parseZone(now.split("T")[0])
            .format("YYYY-MM-DD");
        check = (moment(fechaAprobacion, "YYYY-MM-DD"));
        var monthAprobacion = Number(check.format('M'));
        var yearAprobacion = check.year();

        var currentDate = moment().format("YYYY-MM-DDTHH:mm:ss");


        var partes = currentDate.slice(0).split('T');

        //const now = new Date().toISOString();

        var date = partes[0]
        var hour = partes[1]

        var currentDateAprobacion = moment().format("YYYY-MM-DDTHH:mm:ss");
        //var local_date_aprobacion= moment.utc(currentDate).local().format('YYYY-MM-DDTHH:mm:ss');
        var partesAprobacion = currentDateAprobacion.slice(0).split('T');

        //const now = new Date().toISOString();

        var dateAprobacion = partesAprobacion[0]
        var hourAprobacion = partesAprobacion[1]
        var TipoID = "CIF"

        const result = validateSpanishId(dataModal.NIF)

        if (result === true) {
            const resultTipo = spainIdType(dataModal.NIF)
            TipoID = resultTipo.toUpperCase();
        }


        if (dataModal.esAprobada === false) {

            var datosContratoP = {
                Usuario: this.state.userId.toString(),
                compania: this.state.companyID,
                fechaPropuesta: date,
                horaPropuesta: hour,
                mesPropuesta: monthPropuesta,
                anioPropuesta: yearPropuesta,
                fechaAprobacion: '',
                horaAprobacion: '',
                mesAprobacion: 0,
                anioAprobacion: 0,
                esAprobada: false,
                fechaDenegacion: "",
                horaDenegacion: "",
                mesDenegacion: 0,
                anioDenegacion: 0,
                esDenegada: false,
                IdOferta: idOferta,
                IdOfertaInterna: "",
                cups: this.state.sip.cups,
                tipoCliente: dataModal.TipoCliente,
                tipoEnergia: "GAS",
                estado: "ALTA",
                estadoId: "01",
                OfertaTipo: "P",
                ofertaTipoId: "0",
                T_Titular: {
                    NombreTitular: dataModal.nombreTitular?.toUpperCase()||'',
                    Apellido1Titular: dataModal.apellido1Titular?.toUpperCase()||'',
                    Apellido2Titular: dataModal.apellido2Titular?.toUpperCase()||'',
                    NIF: dataModal.NIF, //this.state.ubicacion.CIF,
                    TipoTitular: TipoID,
                    TipoViaTitular: dataModal.tipoViaTitular?.toUpperCase()||'',
                    ViaTitular: dataModal.viaTitular?.toUpperCase()||'',
                    NumFincaTitular: dataModal.numFincaTitular?.toUpperCase()||'',
                    PortalTitular: dataModal.portalTitular?.toUpperCase()||'',
                    EscaleraTitular: dataModal.escaleraTitular?.toUpperCase()||'',
                    PisoTitular: dataModal.pisoTitular?.toUpperCase()||'',
                    PuertaTitular: dataModal.puertaTitular?.toUpperCase()||'',
                    CodigoPostalTitular: codigoPostal,
                    AutonomiaTitular: dataModal.desAutonomiaTitular.toUpperCase(),
                    ProvinciaTitular: dataModal.desProvinciaTitular.toUpperCase(),
                    MunicipioTitular: dataModal.desMunicipioTitular.toUpperCase(),
                    TelefonoFijo: dataModal.TelefonoFijo, //this.state.ubicacion.telefono_fijo,
                    TelefonMovil: dataModal.TelefonMovil, //this.state.ubicacion.telefono,
                    Email: dataModal.Email?dataModal.Email:"",
                    TipoCliente: dataModal.TipoCliente, //this.state.ubicacion.tipo_cliente,
                    ObservacionesCliente: dataModal.ObservacionesCliente,
                    GDPR: dataModal.GDPR,
                    IBAN: dataModal.iban,
                    CNAE: this.state.sip.CNAE,
                    actividadCNAE: this.state.sip.actividadCNAE,
                },
                T_Representante: {
                    nombreRepresentante: dataModal.nombreRepresentante?.toUpperCase()||'',
                    apellido1Representante: dataModal.apellido1Representante?.toUpperCase()||'',
                    apellido2Representante: dataModal.apellido2Representante?.toUpperCase()||'',
                    NIFRepresentante: dataModal.NIFRepresentante?.toUpperCase()||'',
                    TipoTitularRepresentante: dataModal.tipoTitularRepresentante?.toUpperCase()||'',
                    idRepresentante: dataModal.idRepresentante,
                    idRepresentanteInterno: dataModal.idRepresentanteInterno,
                    CargoRepresentante: dataModal.CargoRepresentante?.toUpperCase()||'',
                    telefonoRepresentante: dataModal.telefonoRepresentante,
                    correoRepresentante: dataModal.correoRepresentante,
                },
                T_PuntoSuministro: {
                    TipoViaPS: dataModal.tipoViaPS.toUpperCase(),
                    ViaPS: dataModal.viaPS?.toUpperCase()||'',
                    NumFincaPS: dataModal.numFincaPS?.toUpperCase()||'',
                    PortalPS: dataModal.portalPS?.toUpperCase()||'',
                    EscaleraPS: dataModal.escaleraPS?.toUpperCase()||'',
                    PisoPS: dataModal.pisoPS?.toUpperCase()||'',
                    PuertaPS: dataModal.puertaPS?.toUpperCase()||'',
                    CodigoPostalPS: codigoPostalPS,
                    NombreMunicipioPS: dataModal.nombreMunicipioPS?.toUpperCase()||'',
                    codigoMunicipioPS: sip.codigoMunicipioPS,
                    NombreProvinciaPS: dataModal.nombreProvinciaPS?.toUpperCase()||'',
                    codigoProvinciaPS: sip.codigoProvinciaPS,
                    AutonomiaPS: dataModal.desAutonomiaPS?.toUpperCase()||'',
                    codigoAutonomiaPS: sip.codigoAutonomiaPS,
                    TelefonoPS: dataModal.TelefonoPS,
                    ObservacionesPuntoSuministro: dataModal.ObservacionesPuntoSuministro
                },
                T_CUPs: {
                    Distribuidora: this.state.sip.nombreEmpresaDistribuidora,
                    CUPsEle: this.state.sip.cups,
                    Tarifa: tarifaATRContrato,
                    caudalMedioEnWhdia: caudalMedioEnWhdia,
                    TipoServicio: "2",
                    Consumo: this.state.sip.ConsumoAnualTotal.toString(),
                },
                T_PropuestaComercial: {
                    PorcentajeAhorro: this.state.comparativeTemp.producto.totalComparacion.ahorroPercent.toString(),
                    ImporteAhorro: this.state.comparativeTemp.producto.totalComparacion.ahorroAnual.toString(),
                    Estado: "P", //"P",
                    Comercializadora: this.state.comparativeTemp.producto.producto.nombreComercializadora, //"AUDAX RENOVABLES S.A.",
                    Producto: this.state.comparativeTemp.producto.producto.nombreOferta, //"CLASSIC",
                    TarifaPeaje: this.state.comparativeTemp.producto.producto.tarifaATR,
                    Tarifa: tarifaATRContrato,
                    IdProducto: this.state.comparativeTemp.producto.producto.id.toString(),
                    Anexo: this.state.comparativeTemp.producto.producto.nombreDocumentoContrato,
                    //this.state.comparativeTemp.producto.producto.condicionesPenalizacion, //"12 MESES T2",
                    TipoPrecio: tipoPrecio, //"0 Fijo 1 Indexado",
                    ObservacionesProducto: "",
                    //this.state.comparativeTemp.producto.producto.condicionesRevisionPrecios, //"opcinal o null",
                    GastoOperativo: gastoOperativo, //"0.00 SOLO CUANDO ES CORPORATE",
                    GastoOperativoPotencia: gastoOperativoPotencia,
                    idServicio: this.state.comparativeTemp.producto.datos.idServicio,
                    idComercializadora: this.state.comparativeTemp.producto.datos.idComercializadora?.padStart(4, "0")||'',
                    nombreServicio: this.state.comparativeTemp.producto.datos.nombreServicio,
                    precioServicio: this.state.comparativeTemp.producto.datos.precioServicio.toString(), //"0.00 SOLO CUANDO NO TIENE SERVICIO CONTRATADO",
                    ComercializadoraProcedencia: dataModal.idComercializadora?.padStart(4, "0")||'',
                    idComercializadoraProcedencia: dataModal.nombreComercializadora,
                    comisionContratoFija: this.state.comparativeTemp.producto.datos.comisionContratoFija,
                    comisionContratoConsumoAgente1: this.state.comparativeTemp.producto.datos.comisionContratoConsumoAgente1,
                    comisionContratoConsumoAgente2: this.state.comparativeTemp.producto.datos.comisionContratoConsumoAgente2,
                    comisionContratoConsumoAdmin: this.state.comparativeTemp.producto.datos.comisionContratoConsumoAdmin,
                    propuestaGlobal: globalJSON
                    },
                T_DatosAgenteComercial: {
                    usuario: this.state.userId,
                    nombreUsuario: this.state.nombreAgente,
                    compania: this.state.companyID,
                    nombreCompania: this.state.companyName,
                    correoAgente: this.state.correoAgente,
                    zonaGeografica: ""
                },
                T_Propuesta: {
                    producto: productoJSON,
                    actual: actualJSON,

                },
                T_Seguimiento: [{
                    usuario: this.state.userId,
                    nombreUsuario: this.state.nombreAgente,
                    compania: this.state.companyID,
                    fecha: date,
                    hora: hour,
                    mes: monthPropuesta,
                    anio: yearPropuesta,
                    accion: "ALTA",
                    idAccion: "01",
                    observacion: ""
                }]
            }






        } else {


            var datosContratoP = {
                Usuario: this.state.userId.toString(),
                compania: this.state.companyID,
                fechaPropuesta: date,
                horaPropuesta: hour,
                mesPropuesta: monthPropuesta,
                anioPropuesta: yearPropuesta,
                fechaAprobacion: dateAprobacion,
                horaAprobacion: hourAprobacion,
                mesAprobacion: monthAprobacion,
                anioAprobacion: yearAprobacion,
                esAprobada: true,
                fechaDenegacion: "",
                horaDenegacion: "",
                mesDenegacion: 0,
                anioDenegacion: 0,
                esDenegada: false,
                IdOferta: idOferta,
                IdOfertaInterna: "",
                cups: this.state.sip.cups,
                tipoCliente: dataModal.TipoCliente,
                tipoEnergia: "GAS",
                estado: "APROBADA",
                estadoId: "50",
                OfertaTipo: "P",
                ofertaTipoId: "0",
                T_Titular: {
                    NombreTitular: dataModal.nombreTitular?.toUpperCase()||'',
                    Apellido1Titular: dataModal.apellido1Titular?.toUpperCase()||'',
                    Apellido2Titular: dataModal.apellido2Titular?.toUpperCase()||'',
                    NIF: dataModal.NIF?.toUpperCase()||'', //this.state.ubicacion.CIF,
                    TipoTitular: TipoID,
                    TipoViaTitular: dataModal.tipoViaTitular?.toUpperCase()||'',
                    ViaTitular: dataModal.viaTitular?.toUpperCase()||'',
                    NumFincaTitular: dataModal.numFincaTitular?.toUpperCase()||'',
                    PortalTitular: dataModal.portalTitular?.toUpperCase()||'',
                    EscaleraTitular: dataModal.escaleraTitular?.toUpperCase()||'',
                    PisoTitular: dataModal.pisoTitular?.toUpperCase()||'',
                    PuertaTitular: dataModal.puertaTitular?.toUpperCase()||'',
                    CodigoPostalTitular: codigoPostal,
                    AutonomiaTitular: dataModal.desAutonomiaTitular?.toUpperCase()||'',
                    ProvinciaTitular: dataModal.desProvinciaTitular?.toUpperCase()||'',
                    MunicipioTitular: dataModal.desMunicipioTitular?.toUpperCase()||'',
                    TelefonoFijo: dataModal.TelefonoFijo, //this.state.ubicacion.telefono_fijo,
                    TelefonMovil: dataModal.TelefonMovil, //this.state.ubicacion.telefono,
                    Email: dataModal.Email?dataModal.Email:"", //this.state.ubicacion.email_contacto,
                    TipoCliente: dataModal.TipoCliente, //this.state.ubicacion.tipo_cliente,
                    ObservacionesCliente: dataModal.ObservacionesCliente,
                    GDPR: dataModal.GDPR,
                    IBAN: dataModal.iban,
                    CNAE: this.state.sip.CNAE,
                    actividadCNAE: this.state.sip.actividadCNAE,

                },
                T_Representante: {
                    nombreRepresentante: dataModal.nombreRepresentante?.toUpperCase()||'',
                    apellido1Representante: dataModal.apellido1Representante?.toUpperCase()||'',
                    apellido2Representante: dataModal.apellido2Representante?.toUpperCase()||'',
                    NIFRepresentante: dataModal.NIFRepresentante?.toUpperCase()||'',
                    TipoTitularRepresentante: dataModal.tipoTitularRepresentante?.toUpperCase()||'',
                    idRepresentante: dataModal.idRepresentante,
                    idRepresentanteInterno: dataModal.idRepresentanteInterno,
                    CargoRepresentante: dataModal.CargoRepresentante?.toUpperCase()||'',
                    telefonoRepresentante: dataModal.telefonoRepresentante,
                    correoRepresentante: dataModal.correoRepresentante,
                },
                T_PuntoSuministro: {
                    TipoViaPS: dataModal.tipoViaPS?.toUpperCase()||'',
                    ViaPS: dataModal.viaPS?.toUpperCase()||'',
                    NumFincaPS: dataModal.numFincaPS?.toUpperCase()||'',
                    PortalPS: dataModal.portalPS?.toUpperCase()||'',
                    EscaleraPS: dataModal.escaleraPS?.toUpperCase()||'',
                    PisoPS: dataModal.pisoPS?.toUpperCase()||'',
                    PuertaPS: dataModal.puertaPS?.toUpperCase()||'',
                    CodigoPostalPS: codigoPostalPS,
                    NombreMunicipioPS: dataModal.nombreMunicipioPS?.toUpperCase()||'',
                    codigoMunicipioPS: sip.codigoMunicipioPS,
                    NombreProvinciaPS: dataModal.nombreProvinciaPS?.toUpperCase()||'',
                    codigoProvinciaPS: sip.codigoProvinciaPS,
                    AutonomiaPS: dataModal.desAutonomiaPS.toUpperCase(),
                    codigoAutonomiaPS: sip.codigoAutonomiaPS,
                    TelefonoPS: dataModal.TelefonoPS,
                    ObservacionesPuntoSuministro: dataModal.ObservacionesPuntoSuministro?.toUpperCase()||''
                },
                T_CUPs: {
                    Distribuidora: this.state.sip.nombreEmpresaDistribuidora,
                    CUPsEle: this.state.sip.cups,
                    Tarifa: tarifaATRContrato,
                    caudalMedioEnWhdia: caudalMedioEnWhdia,
                    TipoServicio: "2",
                    Consumo: this.state.sip.ConsumoAnualTotal.toString(),
                },
                T_PropuestaComercial: {
                    PorcentajeAhorro: this.state.comparativeTemp.producto.totalComparacion.ahorroPercent.toString(),
                    ImporteAhorro: this.state.comparativeTemp.producto.totalComparacion.ahorroAnual.toString(),
                    Estado: "P", //"P",
                    Comercializadora: this.state.comparativeTemp.producto.producto.nombreComercializadora, //"AUDAX RENOVABLES S.A.",
                    Producto: this.state.comparativeTemp.producto.producto.nombreOferta, //"CLASSIC",
                    TarifaPeaje: this.state.comparativeTemp.producto.producto.tarifaATR,
                    Tarifa: tarifaATRContrato,
                    IdProducto: this.state.comparativeTemp.producto.producto.id,
                    Anexo: this.state.comparativeTemp.producto.producto.nombreDocumentoContrato,
                    //this.state.comparativeTemp.producto.producto.condicionesPenalizacion, //"12 MESES T2",
                    TipoPrecio: tipoPrecio, //"0 Fijo 1 Indexado",
                    ObservacionesProducto: "",
                    //this.state.comparativeTemp.producto.producto.condicionesRevisionPrecios, //"opcinal o null",
                    GastoOperativo: gastoOperativo, //"0.00 SOLO CUANDO ES CORPORATE",
                    GastoOperativoPotencia: gastoOperativoPotencia,
                    idServicio: this.state.comparativeTemp.producto.datos.idServicio,
                    idComercializadora: this.state.comparativeTemp.producto.datos.idComercializadora?.padStart(4, "0")||'',
                    nombreServicio: this.state.comparativeTemp.producto.datos.nombreServicio,
                    precioServicio: this.state.comparativeTemp.producto.datos.precioServicio.toString(), //"0.00 SOLO CUANDO NO TIENE SERVICIO CONTRATADO",
                    ComercializadoraProcedencia: dataModal.idComercializadora,
                    idComercializadoraProcedencia: dataModal.nombreComercializadora?.padStart(4, "0")||'',
                    comisionContratoFija: this.state.comparativeTemp.producto.datos.comisionContratoFija,
                    comisionContratoConsumoAgente1: this.state.comparativeTemp.producto.datos.comisionContratoConsumoAgente1,
                    comisionContratoConsumoAgente2: this.state.comparativeTemp.producto.datos.comisionContratoConsumoAgente2,
                    comisionContratoConsumoAdmin: this.state.comparativeTemp.producto.datos.comisionContratoConsumoAdmin,
                    propuestaGlobal: globalJSON
                },
                T_DatosAgenteComercial: {
                    usuario: this.state.userId,
                    nombreUsuario: this.state.nombreAgente,
                    compania: this.state.companyID,
                    nombreCompania: this.state.companyName,
                    correoAgente: this.state.correoAgente,
                    zonaGeografica: ""
                },
                T_Propuesta: {
                    producto: productoJSON,
                    actual: actualJSON
                },
                T_Seguimiento: [{
                    usuario: this.state.userId,
                    nombreUsuario: this.state.nombreAgente,
                    compania: this.state.companyID,
                    fecha: date,
                    hora: hour,
                    mes: monthPropuesta,
                    anio: yearPropuesta,
                    accion: "ALTA",
                    idAccion: "01",
                    observacion: ""
                }, {
                    usuario: this.state.userId,
                    nombreUsuario: this.state.nombreAgente,
                    compania: this.state.companyID,
                    fecha: dateAprobacion,
                    hora: hourAprobacion,
                    mes: monthAprobacion,
                    anio: yearAprobacion,
                    accion: "APROBADA",
                    idAccion: "50",
                    observacion: ""
                }]
            };

        };
        //const datosContratoJSON = JSON.stringify("");
        const datosContratoJSON: string = JSON.stringify(datosContratoP).toString();

        var data: any = []

        if (dataModal.esAprobada === false) {

            data = {
                ...{
                    ...this.dataToSave,
                    companyId: Number(user.companyId),
                    nombreOferta: "",
                    idComercializadora: null,
                    nombreComercializadora: null,
                    idComercializadoraActual: sip.codigoComercializadora,
                    nombreComercializadoraActual: sip.comercializadora,
                    datosPreciosActual: actual,
                    userId: Number(user.id),
                    fechaPropuesta: moment
                        .parseZone(now.split("T")[0])
                        .format("YYYY-MM-DD"),
                    codigoPostalPS: sip.codigoPostalPS,
                    codigoMunicipioPS: sip.municipioPS.toString() ? sip.municipioPS.toString() : "",
                    nombreMunicipioPS: sip.desMunicipioPS,
                    codigoProvinciaPS: sip.codigoProvinciaPS,
                    nombreProvinciaPS: sip.desProvinciaPS,
                    codigoAutonomiaPS: sip.codigoAutonomiaPS ? sip.codigoAutonomiaPS : "",
                    nombreAutonomiaPS: sip.desAutonomiaPS ? sip.desAutonomiaPS : "",
                    esAprobada: false,
                    datosPropuesta: producto,
                    correoContacto: "",
                    cups: this.state.cups,
                    tipo: "G",
                    datosContrato: datosContratoJSON

                },
                ...modalData,
            };
        } else {
            data = {
                ...{
                    ...this.dataToSave,
                    companyId: Number(user.companyId),
                    nombreOferta: "",
                    idComercializadora: null,
                    nombreComercializadora: null,
                    idComercializadoraActual: sip.codigoComercializadora,
                    nombreComercializadoraActual: sip.comercializadora,
                    datosPreciosActual: actual,
                    userId: Number(user.id),
                    fechaPropuesta: moment
                        .parseZone(now.split("T")[0])
                        .format("YYYY-MM-DD"),
                    codigoPostalPS: sip.codigoPostalPS,
                    codigoMunicipioPS: sip.municipioPS.toString() ? sip.municipioPS.toString() : "",
                    nombreMunicipioPS: sip.desMunicipioPS,
                    codigoProvinciaPS: sip.codigoProvinciaPS,
                    nombreProvinciaPS: sip.desProvinciaPS,
                    codigoAutonomiaPS: sip.codigoAutonomiaPS ? sip.codigoAutonomiaPS : "",
                    nombreAutonomiaPS: sip.desAutonomiaPS ? sip.desAutonomiaPS : "",
                    esAprobada: true,
                    datosPropuesta: producto,
                    correoContacto: "",
                    cups: this.state.cups,
                    tipo: "G",
                    datosContrato: datosContratoJSON

                },
                ...modalData,
            };


        }


        const companyIdAux = this.state.companyID.toString();
        const userIdAux = this.state.userId.toString();
        //const idContrato = this.state.contrato;


        const headers = {
            'Authorization': 'Bearer my-token',
            'My-Custom-Header': 'foobar',
            'x-api-key': '$2y$10$bcT1Ukm4V/6/z5GvitZv0unu8I91mpixuwUO6z5CyEGev9DDDY28W'
        };
        let direccionFile = "https://datapi.psgestion.es/propuesta/create?id=" + companyIdAux + "&user=" + userIdAux;


        axios.post(direccionFile, datosContratoP, { headers, responseType: 'json' })
            .then(res => {
                console.log('Actualización correcta', res)
                                //notify();
                //this.clearForm();
            }).catch(error => {
                console.log('Failure')
                console.log('Error en el proceso: ', error)
                //notifyErrorDB();
            });




    };

    openModalComparativa = async (event) => {

        if (this.state.itemsSelected.length) {
            this.showModal();
            return;
        }
    this.warningAlert();
    };


    openModalMultiComparativa = async (event) => {
        if (this.state.itemsSelected.length) {
            this.showMultiModal();
            return;
        }
        this.warningAlert()
    };

    closeComparativeSection = async (event) => {
        this.setState({
            producto: {
                active: false,
                producto: null,
                costeOperativo: 0,
                costeOperativoPotencia: 0
            },
        });
    };

    comparativeSelected = async (event) => {
        let id = event.target.value;
        const win = window.open("/print/sipgas-comparative-print/" + id, "_blank");
        if (win != null) {
            win.focus();
        }
    };

    clearInvoices = () => {
        //this.state.comparativeTemp = [];

        this.setState({
            producto: {
                active: false,
                producto: null,
                costeOperativo: 0,
                costeOperativoPotencia: 0
            },
            showList: true,
            productSelected: null
        });
        if (this.state.productoMulti) {

            this.setState({
                productoMulti: {
                    active: false,
                    productoSeleccionado: null,
                    costeOperativo: 0,
                    costeOperativoPotencia: 0
                },
                showList: true,
                productSelected: null
            });
        }

    };


    changeSave = (e) => {
        let s = true;
        if (e.target.value.length && this.enabledSave == true) {
            s = false;
            this.setState({ nombrePropuesta: e.target.value })
        }
        if (s === true) {

            if (e.target.value.length > 0) {//alert("Recuerde seleccionar un producto para guardar la propuesta comercial");}
                this.warningAlertProducto()
            }
        } else {
            this.setState({ isDisabled: s });
        }
    }


/* Para Añadir Botón MultiComparativa

<Row>
    <div className="col m-2 d-flex justify-content-end">
        <button
            onClick={this.openModalMultiComparativa}
            className="btn btn-sm btn-primary"
        >
            Multi Comparativa
</button>
    </div>
</Row>

 */

    render() {

        const disable_button = this.enabledSave
        const showComercialProducto = !this.state.producto.active
            ? { display: "none" }
            : {};
        const showComercialMultiProducto = !this.state.productoMulti.active
            ? { display: "none" }
            : {};
        const showList = !this.state.showList ? { display: "none" } : {};

        let freeModal = <></>;

        if (this.state.openModalComparative == true && this.state.openModalMultiComparative == false) {

            freeModal = (
                <SipGasComparativeFreeModal
                    sip={this.state.sip}
                    itemsSelected={this.state.itemsSelected}
                    isOpen={this.state.openModalComparative}
                    toggle={this.toggle}
                    periods={this.state.periodos}
                    generateComparative={this.openComparativeSection}
                ></SipGasComparativeFreeModal>
            );
        } else if (this.state.openModalComparative == false && this.state.openModalMultiComparative == true) {
            freeModal = (
                <SipGasComparativeFreeMultiModal
                    sip={this.state.sip}
                    itemsSelected={this.state.itemsSelected}
                    isOpen={this.state.openModalMultiComparative}
                    toggleMulti={this.toggleMulti}
                    periods={this.state.periodos}
                    generateComparative={this.openComparativeMultiSection}
                ></SipGasComparativeFreeMultiModal>
            );
        }


        return (
            <>
                <Header title="Comparativa de Contrato Precio Fijo" />
                <Container id="comparative" className=" mt--7" fluid>
                    <Row className="card-deck">
                        <SupplyGasInformation
                            isLoading={this.state.isLoading}
                            sip={this.state.sip}
                            cardClass="col-6"
                        ></SupplyGasInformation>
                        <RateGasInformation
                            isLoading={this.state.isLoading}
                            sip={this.state.sip}
                            cardClass="col-6"
                        ></RateGasInformation>
                    </Row>
                    <Row className="card-deck">
                        <Card className="mt-3 shadow">
                            <CardBody>
                                <Row style={showComercialProducto}>
                                    <div className="col m-2 d-flex">
                                        <div className="text-left">

                                            <Button color="primary" size="sm" type="button" className="btn btn-sm btn-primary"
                                                onClick={this.clearInvoices}
                                            >

                                                <span className="bi bi-arrow-return-left"> Volver</span>

                                            </Button>

                                        </div>
                                    </div>
                                </Row>
                                <Row style={showComercialMultiProducto}>
                                    <div className="col m-2 d-flex">
                                        <div className="text-left">
                                            <Button
                                                onClick={this.clearInvoices}
                                                className="btn btn-sm btn-primary"
                                            >
                                                Volver
                      </Button>
                                        </div>
                                    </div>
                                </Row>
                                <Row>
                                    <div className="col m-2 d-flex">
                                        <CardTitle>Comparativas</CardTitle>
                                    </div>
                                    <div
                                        className="col m-2 justify-content-end"
                                        style={showComercialProducto}
                                    >
                                        <div className="input-control">

                                            <FormGroup>
                                                <Label className="form-control-label" htmlFor="nombre">
                                                    Nombre Comparativa
                              </Label>
                                                <Input
                                                    id="nombreOferta-id"
                                                    placeholder="Introduzca el nombre"
                                                    name="nombreOferta"
                                                    onChange={this.changeSave}
                                                    type="text"
                                                    required
                                                />
                                            </FormGroup>


                                            <Button
                                                disabled={this.state.isDisabled}
                                                onClick={(event) =>
                                                    this.toggleModal(event, "saveModalOpened")
                                                }
                                                className="btn btn-sm btn-primary"
                                            >
                                                Guardar Oferta
                                </Button>
                                        </div>
                                    </div>
                                    <div
                                        className="col m-2 justify-content-end"
                                        style={showComercialMultiProducto}
                                    >
                                        <div className="input-control">

                                            <FormGroup>
                                                <Label className="form-control-label" htmlFor="nombre">
                                                    Nombre Comparativa
                              </Label>
                                                <Input
                                                    id="nombreOferta-id"
                                                    placeholder="Introduzca el nombre"
                                                    name="nombreOferta"
                                                    onChange={this.changeSave}
                                                    type="text"
                                                    required
                                                />
                                            </FormGroup>


                                            <Button
                                                disabled={this.state.isDisabled}
                                                onClick={(event) =>
                                                    this.toggleModal(event, "saveModalOpened")
                                                }
                                                className="btn btn-sm btn-primary"
                                            >
                                                Guardar Oferta
                                </Button>
                                        </div>

                                    </div>
                                </Row>
                                <Row>
                                    <div className="col m-2 d-flex justify-content-end">
                                        <FormGroup>
                                            <Input
                                                id="comparativaId"
                                                type="select"
                                                onChange={this.comparativeSelected}
                                                hidden={this.state.comparatives.length > 0 ? false : true}
                                            >
                                                <option value="none">Cargar Comparativa</option>
                                                {this.state.comparatives.map((item: any, index) => (
                                                    <option value={item.id} key={index}>
                                                        {item.nombreOferta}
                                                    </option>
                                                ))}
                                            </Input>
                                        </FormGroup>
                                    </div>
                                </Row>
                            </CardBody>
                        </Card>
                    </Row>

                    {this.state.alert}

                    <Row className="card-deck" style={showList}>
                        <Card className="mt-3 shadow">
                            <CardBody>
                                <CardTitle>Consumos</CardTitle>
                                <LoadingOverlay
                                    active={this.state.isLoadingConsumos}
                                    spinner
                                    text="Cargando..."
                                >
                                    <Row>
                                        <div className="col m-2 d-flex justify-content-end">
                                            <button
                                                onClick={this.openModalComparativa}
                                                className="btn btn-sm btn-primary"
                                            >
                                                Comparativa
                      </button>
                                        </div>
                                    </Row>

                                    <Row>
                                        <SipsTable
                                            checkBoxButton={true}
                                            hideViewMoreButton={true}
                                            items={this.state.sipsConsumos}
                                            fields={this.state.tableHeaders}
                                            selectedItem={this.selectedItems}
                                            expandableFields={false}
                                        ></SipsTable>
                                    </Row>
                                </LoadingOverlay>
                            </CardBody>
                        </Card>
                    </Row>



                    <Row className="card-deck" style={showComercialProducto}>
                        <ComercialGasProducto
                            sip={this.state.sip}
                            producto={this.state.producto}
                            periods={this.state.periodos}
                            itemsConsumo={this.state.itemsSelected}
                            output={this.comparativeDataHandler}
                        ></ComercialGasProducto>
                    </Row>
                    <Row className="card-deck" style={showComercialMultiProducto}>
                        <ComercialGasMultiProducto
                            sip={this.state.sip}
                            productoMulti={this.state.productoMulti}
                            periods={this.state.periodos}
                            itemsConsumo={this.state.itemsSelected}
                            output={this.comparativeDataHandler}
                        >
                        </ComercialGasMultiProducto>
                    </Row>
                </Container>

                {freeModal}

                <SaveGasModal
                    isOpen={this.state.saveModalOpened}
                    toggle={(event) => this.toggleModal(event, "saveModalOpened")}
                    onSave={this.onSave}
                    sip={this.state.sip}
                    ubi={this.state.ubicacion}
                    nombrePropuesta={this.state.nombrePropuesta}
                    companyId={this.state.companyID}
                    userId={this.state.userId}
                ></SaveGasModal>
            </>
        );
    }
}

export default withApollo(SipGasComparativeFree);
