import React from "react";
import { Card, CardTitle, CardBody, Button, CardHeader, UncontrolledTooltip, Form, Input, Row, Table, Col, FormGroup, Label } from "reactstrap";
//import { Bar } from '@reactchartjs/react-chart.js';
//import { withApollo } from "react-apollo";
//import client from "../../../api/client";
//import { Bar,  HorizontalBar  } from "react-chartjs-2";
import SipsTable from "../../components/Sips/SipsTableNew";
import { PieChart, Pie, Sector, BarChart, Bar, ReferenceLine, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import DataTable from 'react-data-table-component';
import Checkbox from '@material-ui/core/Checkbox';
//import { LIST_COMERCIAL_SERVICIOS} from "../../../queries/comercial-producto/comercial-producto";
import moment from 'moment';


const options = [
    { value: '0', label: 'Sin Impuestos' },
    { value: '0.21', label: '21 %' },
    { value: '0.10', label: '10%' },
];

const reactSelectStyles = {
    container: (provided, state) => ({
        ...provided,
        padding: 0,
        height: '25px',
        zIndex: 4
    }),
    control: (provided, state) => ({
        ...provided,
        borderWidth: 0,
        minHeight: '25px',
        height: '25px'
    }),
    indicatorsContainer: (provided, state) => ({
        ...provided,
        height: '25px'
    }),
    input: (provided, state) => ({
        ...provided,
        height: '20px'
    })
};

const customStyles = {
    control: (provided, state) => ({
        ...provided,
        background: '#fff',
        borderColor: '#9e9e9e',
        minHeight: '25px',
        height: '25px',
        boxShadow: state.isFocused ? null : null,
    }),
    valueContainer: (provided, state) => ({
        ...provided,
        height: '25px',
        padding: '0 6px'
    }),

    input: (provided, state) => ({
        ...provided,
        margin: '0px',
    }),
    indicatorSeparator: state => ({
        display: 'none',
    }),
    indicatorsContainer: (provided, state) => ({
        ...provided,
        height: '25px',
    }),
};

const rates6XPotencia = [
    '012',
    '013',
    '014',
    '015',
    '016',
    '019',
    '020',
    '021',
    '022',
    '023',
    '024',
    '025'
];




//const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];
//const COLORS = ['#0088FE', '#887cf5', '#c16ee1', '#e861c5', '#ff59a4','#ff5e82,'#ff6c60','#ff8042' ];

const columnsDatosComparativa6p = [
    {
        selector: row => row['oferta'], name: 'PRODUCTO', width: '260px', style: {
            color: 'rgba(0,0,0,.54)',
        },
    },
    { selector: row => row['sumEnergia'], name: 'COSTE ENE.', width: '150px', center: true, cell: row => (Number(row['sumEnergia'])) + ' €' },
    { selector: row => row['sumPotencia'], name: 'COSTE POT.', width: '150px', center: true, cell: row => (Number(row['sumPotencia'])) + ' €' },
    { name: 'COSTE TOTAL', width: '150px', center: true, cell: row => (Number(row['sumEnergia']) + Number(row['sumPotencia'])) + ' €' },
    { selector: row => row['P1energia'], name: 'P.E. P1', width: '150px', sortable: true, center: true, format: (row => Number(row.P1energia)) },
    { selector: row => row['P2energia'], name: 'P.E. P2', width: '150px', sortable: true, center: true, format: (row => Number(row.P2energia)) },
    { selector: row => row['P3energia'], name: 'P.E. P3', width: '150px', sortable: true, center: true, format: (row => Number(row.P3energia)) },
    { selector: row => row['P4energia'], name: 'P.E. P4', width: '150px', sortable: true, center: true, format: (row => Number(row.P4energia)) },
    { selector: row => row['P5energia'], name: 'P.E. P5', width: '150px', sortable: true, center: true, format: (row => Number(row.P5energia)) },
    { selector: row => row['P6energia'], name: 'P.E. P6', width: '150px', sortable: true, center: true, format: (row => Number(row.P6energia)) },
    { selector: row => row['P1potencia'], name: 'P.T. P1', width: '150px', sortable: true, center: true, format: (row => Number(row.P1potencia)) },
    { selector: row => row['P2potencia'], name: 'P.T. P2', width: '150px', sortable: true, center: true, format: (row => Number(row.P2potencia)) },
    { selector: row => row['P3potencia'], name: 'P.T. P3', width: '150px', sortable: true, center: true, format: (row => Number(row.P3potencia)) },
    { selector: row => row['P4potencia'], name: 'P.T. P4', width: '150px', sortable: true, center: true, format: (row => Number(row.P4potencia)) },
    { selector: row => row['P5potencia'], name: 'P.T. P5', width: '150px', sortable: true, center: true, format: (row => Number(row.P5potencia)) },
    { selector: row => row['P6potencia'], name: 'P.T. P6', width: '150px', sortable: true, center: true, format: (row => Number(row.P6potencia)) }
];

const columnsDatosComparativa3p = [
    {
        selector: row => row['oferta'], name: 'PRODUCTO', width: '260px', style: {
            color: 'rgba(0,0,0,.54)',
        },
    },
    { selector: row => row['sumEnergia'], name: 'COSTE ENE.', width: '150px', center: true, cell: row => (Number(row['sumEnergia'])) + ' €' },
    { selector: row => row['sumPotencia'], name: 'COSTE POT.', width: '150px', center: true, cell: row => (Number(row['sumPotencia'])) + ' €' },
    { name: 'COSTE TOTAL', width: '150px', center: true, cell: row => (Number(row['sumEnergia']) + Number(row['sumPotencia'])) + ' €' },
    { selector: row => row['P1energia'], name: 'P.E. P1', width: '150px', sortable: true, center: true, format: (row => Number(row.P1energia)) },
    { selector: row => row['P2energia'], name: 'P.E. P2', width: '150px', sortable: true, center: true, format: (row => Number(row.P2energia)) },
    { selector: row => row['P3energia'], name: 'P.E. P3', width: '150px', sortable: true, center: true, format: (row => Number(row.P3energia)) },
    { selector: row => row['P1potencia'], name: 'P.T. P1', width: '150px', sortable: true, center: true, format: (row => Number(row.P1potencia)) },
    { selector: row => row['P2potencia'], name: 'P.T. P2', width: '150px', sortable: true, center: true, format: (row => Number(row.P2potencia)) }
];

const columnsDatosProductoDetail = [
    {
        selector: row => row['nombreOferta'], name: 'Producto', width: '230px', style: {
            color: 'rgba(0,0,0,.54)',
        },
    },
    { selector: row => row['nombreComercializadora'], name: 'Comercializadora', width: '200px', center: true },
    { selector: row => row['tarifaATR'], name: 'ATR', width: '100px', center: true },
    { selector: row => row['verde'], name: 'E. Verde', width: '100px', center: true },
    { selector: row => row['precioTEP1'], name: 'P.E. P1', width: '100px', sortable: true, center: true, format: (row => Number(row.precioTEP1)) },
    { selector: row => row['precioTEP2'], name: 'P.E. P2', width: '100px', sortable: true, center: true, format: (row => Number(row.precioTEP2)) },
    { selector: row => row['precioTEP3'], name: 'P.E. P3', width: '100px', sortable: true, center: true, format: (row => Number(row.precioTEP3)) },
    { selector: row => row['precioTEP4'], name: 'P.E. P4', width: '100px', sortable: true, center: true, format: (row => Number(row.precioTEP4)) },
    { selector: row => row['precioTEP5'], name: 'P.E. P5', width: '100px', sortable: true, center: true, format: (row => Number(row.precioTEP5)) },
    { selector: row => row['precioTEP6'], name: 'P.E. P6', width: '100px', sortable: true, center: true, format: (row => Number(row.precioTEP6)) },
    { selector: row => row['precioTP1'], name: 'P.T. P1', width: '100px', sortable: true, center: true, format: (row => Number(row.precioTP1)) },
    { selector: row => row['precioTP2'], name: 'P.T. P2', width: '100px', sortable: true, center: true, format: (row => Number(row.precioTP2)) },
    { selector: row => row['precioTP3'], name: 'P.T. P3', width: '100px', sortable: true, center: true, format: (row => Number(row.precioTP3)) },
    { selector: row => row['precioTP4'], name: 'P.T. P4', width: '100px', sortable: true, center: true, format: (row => Number(row.precioTP4)) },
    { selector: row => row['precioTP5'], name: 'P.T. P5', width: '100px', sortable: true, center: true, format: (row => Number(row.precioTP5)) },
    { selector: row => row['precioTP6'], name: 'P.T. P6', width: '100px', sortable: true, center: true, format: (row => Number(row.precioTP6)) },
];


const COLORS = ['#0088FE', '#a177ef', '#e163cb', '#ff599d', '#ff656e', '#ff8042'];

const renderActiveShape = (props) => {
    const RADIAN = Math.PI / 180;
    const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, value, name } = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? 'start' : 'end';

    return (
        <g>
            <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
                {payload.name}
            </text>
            <Sector
                cx={cx}
                cy={cy}
                innerRadius={innerRadius}
                outerRadius={outerRadius}
                startAngle={startAngle}
                endAngle={endAngle}
                fill={COLORS[name % COLORS.length]}
            />
            <Sector
                cx={cx}
                cy={cy}
                startAngle={startAngle}
                endAngle={endAngle}
                innerRadius={outerRadius + 6}
                outerRadius={outerRadius + 10}
                fill={COLORS[name % COLORS.length]}
            />
            <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
            <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
            <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333">{`ENERGIA ${payload.periodo} ${value} Kwh`}</text>
            <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill="#999">
                {`(Rate ${(percent * 100).toFixed(2)}%)`}
            </text>
        </g>
    );
};

interface IComercialProductoMultiProps {
    sip: any,
    periods: number[],
    productoMulti: {
        active: boolean,
        productoSeleccionado: any[],
        costeOperativo: number,
        costeOperativoPotencia: number
    };
    itemsConsumo: any[],
    output: any,
    ubi: any,
    tarifaATR: string,
    tarifaATRDescripcion: string
}

interface IComercialProductoMultiState {
    sip: any,
    ubi: any,
    periods: number[],

    active: boolean
    producto: any[]
    comercialServicios: any[],
    costeOperativo: number,
    costeOperativoPotencia: number,
    daysOperacion: number,
    cambioPotencia: number,
    rates1XP: any[],
    rates2X: any[],
    rates3X: any[],
    rates2XP: any[],
    rates3XP: any[],
    rates6XP: any[],
    rates1XE: any[],
    rates2XE: any[],
    rates3XE: any[],
    rates6XE: any[],
    ratesMonth1: any[],
    ratesMonth2: any[],
    ratesMonth3: any[],
    ratesMonth4: any[],

    itemsTabla: any[];

    idservicio: any,
    idComercializadora: any,
    nombreComercializadora: any,
    nombreServicio: any,
    precioServicio: any,

    formMulti: any[],

    formDatosContrato: {
        tarifaATR: any,
        bie: number,
        potenciaP1: number,
        potenciaP2: number,
        potenciaP3: number,
        potenciaP4: number,
        potenciaP5: number,
        potenciaP6: number,
        potenciaContratoP1: number,

        potenciaContratoP2: number,
        potenciaContratoP3: number,
        potenciaContratoP4: number,
        potenciaContratoP5: number,
        potenciaContratoP6: number,
        energiaActivaP1: number,
        energiaActivaP2: number,
        energiaActivaP3: number,
        energiaActivaP4: number,
        energiaActivaP5: number,
        energiaActivaP6: number

    },

    formMultiProducto: {
        sumPotencia: number,
        sumEnergia: number,
        iePercent: number;
        impuestoElectrico: number;
        iva: number;
        ivaPorcentaje: number;
        bi: number;
        total: number;
        serviciosAdicionalesMonth: number;
        serviciosAdicionales: number;
        alquilerEquipoMedidaMonth: number;
        alquilerEquipoMedida: number;
        codigoATR: any;
        numDaysNew: number;
        numMonthNew: number;
        fechaDesdeConsumo: any;
        fechaHastaConsumo: any;
        penalizacionPotencia: number,
        penalizacionEnergia: number,
        datasetProducto: number[],
        EnergiaActiva: any[],
        Potencia: any[],
        potenciaP1: number,
        potenciaP2: number,
        potenciaP3: number,
        potenciaP4: number,
        potenciaP5: number,
        potenciaP6: number,

        potenciaContratoP1: number,
        potenciaContratoP2: number,
        potenciaContratoP3: number,
        potenciaContratoP4: number,
        potenciaContratoP5: number,
        potenciaContratoP6: number,

        energiaActivaP1: number,
        energiaActivaP2: number,
        energiaActivaP3: number,
        energiaActivaP4: number,
        energiaActivaP5: number,
        energiaActivaP6: number,

        energiaReactivaP1: number,
        energiaReactivaP2: number,
        energiaReactivaP3: number,
        energiaReactivaP4: number,
        energiaReactivaP5: number,
        energiaReactivaP6: number,
        idServicio: any,
        idComercializadora: any,
        //nombreComercializadora: any,
        nombreServicio: any,
        precioServicio: number

    },
    formActual: {

        precioTP1: number,
        precioTP2: number,
        precioTP3: number,
        precioTP4: number,
        precioTP5: number,
        precioTP6: number,

        precioTEP1: number,
        precioTEP2: number,
        precioTEP3: number,
        precioTEP4: number,
        precioTEP5: number,
        precioTEP6: number,

        serviciosAdicionalesMonth: number;
        serviciosAdicionales: number;
        alquilerEquipoMedidaMonth: number;
        alquilerEquipoMedida: number;
        codigoATR: any;

        numDaysNew: number;
        numMonthNew: number;
        fechaDesdeConsumo: any;
        fechaHastaConsumo: any;

        sumPotencia: number;
        sumEnergia: number;
        iePercent: number;
        impuestoElectrico: number;
        iva: number;
        ivaPorcentaje: number;
        bi: number;
        total: number;

        penalizacionPotencia: number,
        penalizacionEnergia: number;

        datasetActual: number[],
        datasetLeyenda: any[],
        descuentoMonth: number,
        descuento: number,
        descuentoPotencia: number,
        descuentoPotenciaMonth: number
    },
    totalComparacion: any[]
    /*  {
          ahorroAnual: number,
          ahorroPercent: number,
          ahorroPeriodo: number,
          datosAhorro: number[]
      },
    */
    itemsConsumo: any[];
    saveButtonDisabled: boolean;
    productSelected: any;
    productSelectedTemp: any;
    selectedOption: string;
    activeIndex: number,
    penalizacionPotenciaCalculo: number;
    penalizacionReactivaCalculo: number;
    saveModalOpened: boolean;
    toggledClearRows: boolean;

    consumosMaxMin: {
        max: {
            P1: number,
            P2: number,
            P3: number,
            P4: number,
            P5: number,
            P6: number,
        },
        min: {
            P1: number,
            P2: number,
            P3: number,
            P4: number,
            P5: number,
            P6: number,
        }
    }
}


export default class ComercialProductoMulti extends React.Component<IComercialProductoMultiProps, IComercialProductoMultiState>
{
    public formActualRef: any = null;
    public formMultiProductoRef: any = null;
    public state: IComercialProductoMultiState = {
        formMulti: [],

        formDatosContrato: {
            tarifaATR: '',
            bie: 0,
            potenciaP1: 0,
            potenciaP2: 0,
            potenciaP3: 0,
            potenciaP4: 0,
            potenciaP5: 0,
            potenciaP6: 0,
            potenciaContratoP1: 0,

            potenciaContratoP2: 0,
            potenciaContratoP3: 0,
            potenciaContratoP4: 0,
            potenciaContratoP5: 0,
            potenciaContratoP6: 0,
            energiaActivaP1: 0,
            energiaActivaP2: 0,
            energiaActivaP3: 0,
            energiaActivaP4: 0,
            energiaActivaP5: 0,
            energiaActivaP6: 0
        },
        formMultiProducto: {
            serviciosAdicionalesMonth: 0,
            serviciosAdicionales: 0,
            alquilerEquipoMedidaMonth: 0,
            alquilerEquipoMedida: 0,
            codigoATR: 0,

            sumPotencia: 0,
            sumEnergia: 0,
            iePercent: 0,
            impuestoElectrico: 0,
            iva: 0,
            ivaPorcentaje: 0,
            bi: 0,
            total: 0,

            EnergiaActiva: [],
            Potencia: [],

            potenciaP1: 0,

            potenciaP2: 0,
            potenciaP3: 0,
            potenciaP4: 0,
            potenciaP5: 0,
            potenciaP6: 0,

            potenciaContratoP1: 0,

            potenciaContratoP2: 0,
            potenciaContratoP3: 0,
            potenciaContratoP4: 0,
            potenciaContratoP5: 0,
            potenciaContratoP6: 0,

            energiaActivaP1: 0,
            energiaActivaP2: 0,
            energiaActivaP3: 0,
            energiaActivaP4: 0,
            energiaActivaP5: 0,
            energiaActivaP6: 0,

            energiaReactivaP1: 0,
            energiaReactivaP2: 0,
            energiaReactivaP3: 0,
            energiaReactivaP4: 0,
            energiaReactivaP5: 0,
            energiaReactivaP6: 0,

            numDaysNew: 0,
            numMonthNew: 0,
            fechaDesdeConsumo: null,
            fechaHastaConsumo: null,

            penalizacionPotencia: 0,
            penalizacionEnergia: 0,

            datasetProducto: [],
            idServicio: null,
            idComercializadora: null,
            //    nombreComercializadora: null,
            nombreServicio: null,
            precioServicio: 0


        },
        formActual: {

            descuento: 0,
            descuentoMonth: 0,
            descuentoPotencia: 0,
            descuentoPotenciaMonth: 0,
            serviciosAdicionalesMonth: 0,
            serviciosAdicionales: 0,
            alquilerEquipoMedidaMonth: 0,
            alquilerEquipoMedida: 0,
            codigoATR: 0,

            numDaysNew: 0,
            numMonthNew: 0,
            fechaDesdeConsumo: null,
            fechaHastaConsumo: null,

            sumPotencia: 0,
            sumEnergia: 0,
            iePercent: 0,
            impuestoElectrico: 0,
            iva: 0,
            ivaPorcentaje: 0,
            bi: 0,
            total: 0,

            penalizacionPotencia: 0,
            penalizacionEnergia: 0,

            datasetActual: [],
            datasetLeyenda: [],

            precioTP1: 0,
            precioTP2: 0,
            precioTP3: 0,
            precioTP4: 0,
            precioTP5: 0,
            precioTP6: 0,
            precioTEP1: 0,
            precioTEP2: 0,
            precioTEP3: 0,
            precioTEP4: 0,
            precioTEP5: 0,
            precioTEP6: 0,
        },
        totalComparacion: [],
        /*
                {
                    ahorroAnual: 0,
                    ahorroPercent: 0,
                    ahorroPeriodo: 0,
                    datosAhorro:[]
                },
          */
        comercialServicios: [],
        rates2X: [
            '001',
            '004',
            '005',
            '006',
            '007',
            '008'
        ],
        rates3X: [
            '011',
            '003',
            '012',
            '013',
            '014',
            '015',
            '016'
        ],
        rates2XP: [
            '018',
        ],
        rates1XP: [
            '001',
            '004',
            '005',
            '006',
            '007',
            '008'
        ],
        rates3XP: [
            '011',
            '003'
        ],
        rates6XP: [
            '012',
            '013',
            '014',
            '015',
            '016',
            '019',
            '020',
            '021',
            '022',
            '023',
            '024',
            '025'
        ],
        rates1XE: [
            '001',
            '005'
        ],
        rates2XE: [
            '004',
            '006'
        ],
        rates3XE: [
            '011',
            '003',
            '007',
            '008',
            '018'
        ],
        rates6XE: [
            '012',
            '013',
            '014',
            '015',
            '016',
            '019',
            '020',
            '021',
            '022',
            '023',
            '024',
            '025'
        ],
        ratesMonth1: [1, 2, 7, 12],
        ratesMonth2: [3, 11],
        ratesMonth3: [4, 5, 10],
        ratesMonth4: [6, 8, 9],

        idservicio: null,
        idComercializadora: null,
        nombreComercializadora: null,
        nombreServicio: null,
        precioServicio: null,
        daysOperacion: 0,


        active: false,
        sip: {},
        ubi: {},
        periods: [],
        itemsTabla: [],
        itemsConsumo: [],
        producto: [],
        costeOperativo: 0,
        costeOperativoPotencia: 0,
        productSelected: null,
        productSelectedTemp: {},
        selectedOption: '',
        cambioPotencia: 0,
        penalizacionPotenciaCalculo: 0,
        penalizacionReactivaCalculo: 0,
        activeIndex: 0,
        saveButtonDisabled: false,
        saveModalOpened: false,
        toggledClearRows: false,
        consumosMaxMin: {
            max: {
                P1: 0,
                P2: 0,
                P3: 0,
                P4: 0,
                P5: 0,
                P6: 0
            },
            min: {
                P1: 0,
                P2: 0,
                P3: 0,
                P4: 0,
                P5: 0,
                P6: 0
            }
        }

    };

    componentWillReceiveProps(newProps: IComercialProductoMultiProps) {

        let inputState: any = { ...newProps };

        if (newProps.sip) {
            var state = Object.assign(this.state, {
                formActual: Object.assign(this.state.formActual, {
                    penalizacionPotencia: 0,
                    penalizacionEnergia: 0
                }),
                formMulti: Object.assign(this.state.formMultiProducto, {
                    penalizacionPotencia: 0,
                    penalizacionEnergia: 0
                }),
                formMultiProducto: Object.assign(this.state.formMultiProducto, {
                    penalizacionPotencia: 0,
                    penalizacionEnergia: 0
                })
            });
            this.setState(state);
        }

        this.setState({
            productSelected: null
        });


        if (newProps.productoMulti.productoSeleccionado) {

            let newItemsTabla: any[] = [];
            delete inputState.producto;

            let maxP1 = 0;
            let minP1 = 0;
            let maxP2 = 0;
            let minP2 = 0;
            let maxP3 = 0;
            let minP3 = 0;
            let maxP4 = 0;
            let minP4 = 0;
            let maxP5 = 0;
            let minP5 = 0;
            let maxP6 = 0;
            let minP6 = 0;


            const producto: any[] = { ...newProps.productoMulti.productoSeleccionado };
            const costeOperativo = newProps.productoMulti.costeOperativo;
            const costeOperativoPotencia = newProps.productoMulti.costeOperativoPotencia;
            let itemsConsumo = { ...newProps.sip }



            minP1 = 0
            maxP1 = 0

            minP2 = 0
            maxP2 = 0
            minP3 = 0
            maxP3 = 0
            minP4 = 0
            maxP4 = 0
            minP5 = 0
            maxP5 = 0
            minP6 = 0
            maxP6 = 0








            let periodos: number[] = [];



            newProps.productoMulti.productoSeleccionado.map(row => {


                var periodosNuevos = 0
                if (newProps.tarifaATR == '001' || newProps.tarifaATR == '004' || newProps.tarifaATR == '005' || newProps.tarifaATR == '006' || newProps.tarifaATR == '007' || newProps.tarifaATR == '008') {
                    periodosNuevos = 3
                } else if (newProps.tarifaATR == '018') {
                    periodosNuevos = 3
                } else { periodosNuevos = 6 }



                for (let i = 1; i <= periodosNuevos; i++) {

                    let consumoAnual = `ConsumoAnualActivaP${i}`

                    if (i <= periodosNuevos) {
                        let precioPotenciaField = `precioTP${i}`;
                        let precioEnergiaField = `precioTEP${i}`;
                        let periodo = `P${i}`;
                        periodos.push(i);
                        let precioPotencia = 0;
                        let precioEnergia = 0;
                        if (row['coIncluido'] === false) {

                            precioPotencia = row[precioPotenciaField] + costeOperativoPotencia;
                            precioEnergia = row[precioEnergiaField] + costeOperativo;
                        } else {
                            precioPotencia = row[precioPotenciaField];
                            precioEnergia = row[precioEnergiaField];
                        }
                        let id = row['id']

                        newItemsTabla.push({ id: id, periodo: periodo, precioPotencia: precioPotencia, precioEnergia: precioEnergia });

                    }
                }
                return row;
            });

            var consumosMaxMin = {
                max: {
                    P1: maxP1,
                    P2: maxP2,
                    P3: maxP3,
                    P4: maxP4,
                    P5: maxP5,
                    P6: maxP6
                },
                min: {
                    P1: minP1,
                    P2: minP2,
                    P3: minP3,
                    P4: minP4,
                    P5: minP5,
                    P6: minP6
                }
            };

            let potenciaContratoP1 = 0
            let potenciaContratoP2 = 0
            let potenciaContratoP3 = 0
            let potenciaContratoP4 = 0
            let potenciaContratoP5 = 0
            let potenciaContratoP6 = 0

            let tarifaATR = newProps.tarifaATR
            let bie = 0;
            var formDatosContrato = {
                tarifaATR: tarifaATR,
                bie: bie,
                potenciaP1: 0,
                potenciaP2: 0,
                potenciaP3: 0,
                potenciaP4: 0,
                potenciaP5: 0,
                potenciaP6: 0,
                potenciaContratoP1: potenciaContratoP1,

                potenciaContratoP2: potenciaContratoP2,
                potenciaContratoP3: potenciaContratoP3,
                potenciaContratoP4: potenciaContratoP4,
                potenciaContratoP5: potenciaContratoP5,
                potenciaContratoP6: potenciaContratoP6
            }

            inputState.producto = producto;
            inputState.itemsTabla = newItemsTabla;
            inputState.costeOperativo = costeOperativo;
            inputState.costeOperativoPotencia = costeOperativoPotencia;
            //inputState.active = newProps.producto.active;
            this.setState({
                ...this.state,
                ...inputState,
                consumosMaxMin: consumosMaxMin,
                formDatosContrato: formDatosContrato
            }, () => {
                this.renderFacturaProducto();

            })
        }
        else {
            this.setState({
                ...this.state,
                ...inputState
            })
        }

    }

    componentDidMount() {

        this.formActualRef = React.createRef();
        this.formMultiProductoRef = React.createRef();
        this.setState({
            productSelected: null
        });

    }


    onChangeFieldformMultiProductoServicio = async (event) => {
        let inputs: any = await this.onChangeField(event);
        let opcion = parseInt(inputs.idServicio)
        let idservicio = null;
        let idComercializadora = null;
        let nombreComercializadora = null;
        let nombreServicio = null;
        let precioServicio = null;


        /*
                idservicio: null,
                idComercializadora: null,
                nombreComercializadora: null,
                nombreServicio: null,
                precioServicio: 0,

                id: "09180001"
        idComercializadora: "0918"
        infoAdicional: null
        nombreComercializadora: "ALDRO ENERGIA Y SOLUCIONES S.L"
        nombreServicio: "Exprés24"
        precio: 5.9
        */

        this.state.comercialServicios.map(row => {
            let fila = parseInt(row['id']);
            if (fila === opcion) {
                idservicio = row['id'];
                idComercializadora = row['idComercializadora'];
                nombreComercializadora = row['nombreComercializadora'];
                nombreServicio = row['nombreServicio'];
                precioServicio = row['precio'];
            }
            return row;
        });


        {
            this.setState({
                idservicio: idservicio,
                idComercializadora: idComercializadora,
                nombreComercializadora: nombreComercializadora,
                nombreServicio: nombreServicio,
                precioServicio: precioServicio

            }, this.renderFacturaProducto);
        }

    }

    private calculateAhorro = (periodos: number[], facturaActual: any, facturaProducto: any) => {


        let totalFacturaProducto = facturaProducto.sumEnergia + facturaProducto.sumPotencia;
        let totalFacturaProductoEnergia = facturaProducto.sumEnergia;
        let totalFacturaProductoPotencia = facturaProducto.sumPotencia;
        let total: any = facturaProducto

        let datosAhorro = [0];
        datosAhorro.length = 0;
        datosAhorro[datosAhorro.length] = totalFacturaProducto;
        datosAhorro[datosAhorro.length] = totalFacturaProductoEnergia;
        datosAhorro[datosAhorro.length] = totalFacturaProductoPotencia;

        return {
            importeTotalEnergia: totalFacturaProductoEnergia,
            importeTotalPotencia: totalFacturaProductoPotencia,
            importeTotal: totalFacturaProducto,
            datosAhorro: datosAhorro
        }
    }


    private calculateAhorroMulti = (periodos: number[], facturaActual: any, facturaProducto: any) => {



        let totalFacturaActual = facturaActual.total;
        let totalFacturaProducto = facturaProducto.total;
        let total: any = facturaProducto

        let ahorroPeriodo = this.roundNumber((totalFacturaActual - totalFacturaProducto));
        let ahorroPercent = this.roundNumber(ahorroPeriodo * 100 / totalFacturaActual);
        let datosAhorro = [0];
        datosAhorro.length = 0;
        datosAhorro[datosAhorro.length] = totalFacturaActual;
        datosAhorro[datosAhorro.length] = totalFacturaProducto;
        datosAhorro[datosAhorro.length] = ahorroPeriodo;

        return {
            ahorroAnual: ahorroPeriodo,
            ahorroPercent: isNaN(ahorroPercent) ? 0 : ahorroPercent,
            ahorroPeriodo: ahorroPeriodo,
            datosAhorro: datosAhorro
        }
    }


    private calculatePrecioProducto = (periodos: number[], producto: any, factura: any) => {
        let {
            serviciosAdicionales,
            serviciosAdicionalesMonth,
            alquilerEquipoMedidaMonth,
            alquilerEquipoMedida,
            codigoATR,
            sumPotencia,
            sumEnergia,
            iePercent,
            impuestoElectrico,
            penalizacionPotencia,
            penalizacionEnergia,
            iva,
            ivaPorcentaje,
            bi,
            total,
            numDaysNew,
            numMonthNew,
            fechaDesdeConsumo,
            fechaHastaConsumo,
            Potencia,
            idComercializadora,
            idServicio,
            nombreServicio,
            precioServicio,
            idProducto
        } = factura;
        let formData = { ...this.state.formDatosContrato };

        const costeOperativo = this.state.costeOperativo;
        const costeOperativoPotencia = this.state.costeOperativoPotencia
        const sip = this.state.sip;
        const ubi = this.state.ubi;
        let consumoPotencia = {};
        let consumoEnergia = {};
        let precioPotencia = {};
        let precioEnergia = {};
        fechaDesdeConsumo = null;
        fechaHastaConsumo = null;
        let potenciaP1 = 0;
        let potenciaP2 = 0;
        let potenciaP3 = 0;
        let potenciaP4 = 0;
        let potenciaP5 = 0;
        let potenciaP6 = 0;

        let potenciaContratoP1 = 0;
        let potenciaContratoP2 = 0;
        let potenciaContratoP3 = 0;
        let potenciaContratoP4 = 0;
        let potenciaContratoP5 = 0;
        let potenciaContratoP6 = 0;

        let energiaActivaP1 = 0;
        let energiaActivaP2 = 0;
        let energiaActivaP3 = 0;
        let energiaActivaP4 = 0;
        let energiaActivaP5 = 0;
        let energiaActivaP6 = 0;

        let energiaReactivaP1 = 0;
        let energiaReactivaP2 = 0;
        let energiaReactivaP3 = 0;
        let energiaReactivaP4 = 0;
        let energiaReactivaP5 = 0;
        let energiaReactivaP6 = 0;

        let energiaReactivaExcesoP1 = 0;
        let energiaReactivaExcesoP2 = 0;
        let energiaReactivaExcesoP3 = 0;
        let energiaReactivaExcesoP4 = 0;
        let energiaReactivaExcesoP5 = 0;
        let energiaReactivaExcesoP6 = 0;

        let penalizacionP1 = 0;
        let penalizacionP2 = 0;
        let penalizacionP3 = 0;
        let penalizacionP4 = 0;
        let penalizacionP5 = 0;
        let penalizacionP6 = 0;

        let penalizacionReactivaP1 = 0;
        let penalizacionReactivaP2 = 0;
        let penalizacionReactivaP3 = 0;
        let penalizacionReactivaP4 = 0;
        let penalizacionReactivaP5 = 0;
        let penalizacionReactivaP6 = 0;

        sumEnergia = 0;
        sumPotencia = 0;
        let sumPenalizacionPotencia = 0
        let sumPenalizacionReactiva = 0

        numDaysNew = 0;
        let numDaysNewP = 0;
        let numDaysPotencia = 0;
        let numDaysP1 = 0;
        let numDaysP2 = 0;
        let numDaysP3 = 0;
        let numDaysP4 = 0;
        let numDaysP5 = 0;
        let numDaysP6 = 0;

        let numDaysPE1 = 0;
        let numDaysPE2 = 0;
        let numDaysPE3 = 0;
        let numDaysPE4 = 0;
        let numDaysPE5 = 0;
        let numDaysPE6 = 0;
        let sumatotalPotencia = 0;
        let sumatotalReactiva = 0;

        numMonthNew = 0;
        let monthLectura = 0;
        let dayscheck = 0;
        let valorPhi = 0;

        this.state.itemsConsumo.map(row => {
            if (fechaDesdeConsumo == null) {
                fechaDesdeConsumo = row['fechaInicioMesConsumo'];
                numDaysNew += Math.round(moment.duration(moment(row['fechaFinMesConsumo'], "DD-MM-YYYY").diff(moment(row['fechaInicioMesConsumo'], "DD-MM-YYYY"))).asDays());
                numMonthNew += Math.round(moment.duration(moment(row['fechaFinMesConsumo'], "DD-MM-YYYY").diff(moment(row['fechaInicioMesConsumo'], "DD-MM-YYYY"))).asMonths());
            } else if (moment(fechaDesdeConsumo, "DD-MM-YYYY") > moment(row['fechaInicioMesConsumo'], "DD-MM-YYYY")) {
                fechaDesdeConsumo = row['fechaInicioMesConsumo'];
                numDaysNew += Math.round(moment.duration(moment(row['fechaFinMesConsumo'], "DD-MM-YYYY").diff(moment(row['fechaInicioMesConsumo'], "DD-MM-YYYY"))).asDays());

                numMonthNew += Math.round(moment.duration(moment(row['fechaFinMesConsumo'], "DD-MM-YYYY").diff(moment(row['fechaInicioMesConsumo'], "DD-MM-YYYY"))).asMonths());
            }
            return row;
        });

        this.setState({
            daysOperacion: 365,
        })

        //// REALIZAMOS LA LOGICA PARA REPRESENTAR LOS DATOS DE Potencia
        //// HACEMOS LOS CALCULOS DE TOTALES DE POTENCIA //

        this.state.itemsConsumo.map(row => {

            if (producto.tarifaATR === "018") {
                potenciaP1 += this.roundNumber((row['potenciasContratadasEnWP1']) || 0);
                potenciaP2 += this.roundNumber((row['potenciasContratadasEnWP2']) || 0);
            } else {

                potenciaP1 += this.roundNumber((row['potenciasContratadasEnWP1']) || 0);
                potenciaP2 += this.roundNumber((row['potenciasContratadasEnWP2']) || 0);
                potenciaP3 += this.roundNumber((row['potenciasContratadasEnWP3']) || 0);
                potenciaP4 += this.roundNumber((row['potenciasContratadasEnWP4']) || 0);
                potenciaP5 += this.roundNumber((row['potenciasContratadasEnWP5']) || 0);
                potenciaP6 += this.roundNumber((row['potenciasContratadasEnWP6']) || 0);
            }


            sumatotalPotencia += potenciaP1 + potenciaP2 + potenciaP3 + potenciaP4 + potenciaP5 + potenciaP6
            return row;
        });


        this.state.itemsConsumo.map(row => {

            if (producto.tarifaATR === "018") {
                energiaActivaP1 += this.roundNumber(Number(row['ConsumoAnualActivaP1']) || 0);
                energiaActivaP2 += this.roundNumber(Number(row['ConsumoAnualActivaP2']) || 0);
                energiaActivaP3 += this.roundNumber(Number(row['ConsumoAnualActivaP3']) || 0);

            } else {
                energiaActivaP1 += this.roundNumber(Number(row['ConsumoAnualActivaP1']) || 0);
                energiaActivaP2 += this.roundNumber(Number(row['ConsumoAnualActivaP2']) || 0);
                energiaActivaP3 += this.roundNumber(Number(row['ConsumoAnualActivaP3']) || 0);
                energiaActivaP4 += this.roundNumber(Number(row['ConsumoAnualActivaP4']) || 0);
                energiaActivaP5 += this.roundNumber(Number(row['ConsumoAnualActivaP5']) || 0);
                energiaActivaP6 += this.roundNumber(Number(row['ConsumoAnualActivaP6']) || 0);
            }

            return row;
        });


        this.setState({
            daysOperacion: 365,
        })


        numDaysNew = 365;


        periodos.map(periodo => {

            precioEnergia[`P${periodo}`] = 0;
            precioPotencia[`P${periodo}`] = 0;
            consumoPotencia[`P${periodo}`] = 0;
            consumoEnergia[`P${periodo}`] = 0;



            Object.keys(producto).map(key => {
                //Producto Energia
                if (key === `precioTEP${periodo}`) {
                    if (producto['coIncluido'] === false) {
                        precioEnergia[`P${periodo}`] = Number(producto[key]) + costeOperativo;
                    } else {
                        precioEnergia[`P${periodo}`] = Number(producto[key]);
                    }
                }
                //Producto Potencia
                if (key === `precioTP${periodo}`) {
                    if (producto['coIncluido'] === false) {
                        precioPotencia[`P${periodo}`] = Number(producto[key]) + costeOperativoPotencia;
                    } else {
                        precioPotencia[`P${periodo}`] = Number(producto[key]);
                    }
                }
                return key;
            });


            this.state.itemsConsumo.map(row => {

                consumoPotencia[`P${periodo}`] += (Number(row[`potenciasContratadasEnWP${periodo}`]) * 12) || 0;
                consumoEnergia[`P${periodo}`] += (Number(row[`ConsumoAnualActivaP${periodo}`]) || 0);

                return row;

            });


            sumEnergia += (consumoEnergia[`P${periodo}`] * precioEnergia[`P${periodo}`]);


            if (producto.tarifaATR == '018') {

                if (periodo == 1) {

                    const potenciaContratada = potenciaP1;

                    sumPotencia += (potenciaContratada * precioPotencia[`P${periodo}`]) * numDaysNew;

                }

                if (periodo == 2) {

                    const potenciaContratada = potenciaP2;

                    sumPotencia += (potenciaContratada * precioPotencia[`P${periodo}`]) * numDaysNew;

                }

            } else {

                if (periodo === 1) {
                    const potenciaContratada = potenciaP1;
                    sumPotencia += (potenciaContratada * precioPotencia[`P${periodo}`]) * numDaysNew;
                }

                if (periodo === 2) {
                    const potenciaContratada = potenciaP2;
                    sumPotencia += (potenciaContratada * precioPotencia[`P${periodo}`]) * numDaysNew;
                }

                if (periodo === 3) {
                    const potenciaContratada = potenciaP3;
                    sumPotencia += (potenciaContratada * precioPotencia[`P${periodo}`]) * numDaysNew;
                }
                if (periodo === 4) {
                    const potenciaContratada = potenciaP4;
                    sumPotencia += (potenciaContratada * precioPotencia[`P${periodo}`]) * numDaysNew;
                }
                if (periodo === 5) {
                    const potenciaContratada = potenciaP5;
                    sumPotencia += (potenciaContratada * precioPotencia[`P${periodo}`]) * numDaysNew;
                }
                if (periodo === 6) {
                    const potenciaContratada = potenciaP6;
                    sumPotencia += (potenciaContratada * precioPotencia[`P${periodo}`]) * numDaysNew;
                }

            }

            return periodo;
        });
        formData['potenciaP1'] = potenciaP1
        formData['potenciaP2'] = potenciaP2
        formData['potenciaP3'] = potenciaP3
        formData['potenciaP4'] = potenciaP4
        formData['potenciaP5'] = potenciaP5
        formData['potenciaP6'] = potenciaP6

        formData['energiaActivaP1'] = energiaActivaP1
        formData['energiaActivaP2'] = energiaActivaP2
        formData['energiaActivaP3'] = energiaActivaP3
        formData['energiaActivaP4'] = energiaActivaP4
        formData['energiaActivaP5'] = energiaActivaP5
        formData['energiaActivaP6'] = energiaActivaP6

        this.setState({ formDatosContrato: formData });

        serviciosAdicionalesMonth = 0;
        penalizacionPotencia = this.roundNumber(Number(sumatotalPotencia || 0));
        penalizacionEnergia = this.roundNumber(Number(sumatotalReactiva || 0));

        sumEnergia = this.roundNumber(sumEnergia);
        sumPotencia = this.roundNumber(sumPotencia);

        iePercent = this.roundNumber((sumEnergia + sumPotencia) * 0.0511);
        impuestoElectrico = this.roundNumber((sumEnergia + sumPotencia));
        serviciosAdicionales = this.roundNumber(this.state.precioServicio || 0 * numMonthNew);
        alquilerEquipoMedida = this.roundNumber(alquilerEquipoMedidaMonth || 0 * numMonthNew);
        bi = this.roundNumber((penalizacionPotencia + penalizacionEnergia + serviciosAdicionales + alquilerEquipoMedida + impuestoElectrico + iePercent));

        var isbefore = moment().isBefore('20220601')


        if (isbefore && (parseInt(this.state.sip.potenciasContratadasEnWP1) < 10 && parseInt(this.state.sip.potenciasContratadasEnWP2) < 10 && parseInt(this.state.sip.potenciasContratadasEnWP3) < 10 && parseInt(this.state.sip.potenciasContratadasEnWP4) < 10 && parseInt(this.state.sip.potenciasContratadasEnWP5) < 10 && parseInt(this.state.sip.potenciasContratadasEnWP6) < 10)) {
            iva = this.roundNumber((bi * 0.10));
            ivaPorcentaje = 10;

        } else if (isbefore && (parseInt(this.state.sip.potenciasContratadasEnWP1) < 15 && parseInt(this.state.sip.potenciasContratadasEnWP2) < 15 && parseInt(this.state.sip.potenciasContratadasEnWP3) < 15 && parseInt(this.state.sip.potenciasContratadasEnWP4) < 15 && parseInt(this.state.sip.potenciasContratadasEnWP5) < 15 && parseInt(this.state.sip.potenciasContratadasEnWP6) < 15) && sip.perfilPersona == 'J') {
            iva = this.roundNumber((bi * 0.10));
            ivaPorcentaje = 10;
        } else if (isbefore && this.state.sip.aplicacionBonoSocial == "1") {
            iva = this.roundNumber((bi * 0.10));
            ivaPorcentaje = 10;
        } else {
            iva = this.roundNumber((bi * 0.21));
            ivaPorcentaje = 21;
        }


        total = this.roundNumber((bi + iva));
        codigoATR = this.state.sip.codigoTarifaATREnVigor;
        idServicio = this.state.idservicio;
        idComercializadora = this.state.idComercializadora;
        nombreServicio = this.state.nombreServicio;

        precioServicio = this.roundNumber(this.state.precioServicio);
        idProducto = producto.id;


        this.setState({
            penalizacionPotenciaCalculo: penalizacionPotencia,
            penalizacionReactivaCalculo: penalizacionEnergia
        })


        return {
            serviciosAdicionalesMonth: serviciosAdicionalesMonth,
            serviciosAdicionales: serviciosAdicionales,
            alquilerEquipoMedidaMonth: alquilerEquipoMedidaMonth,
            alquilerEquipoMedida: alquilerEquipoMedida,
            codigoATR: codigoATR,
            sumPotencia: sumPotencia,
            sumEnergia: sumEnergia,
            iePercent: iePercent,
            impuestoElectrico: impuestoElectrico,
            iva: iva,
            ivaPorcentaje: ivaPorcentaje,
            bi: bi,
            total: total,
            penalizacionPotencia: penalizacionPotencia,
            penalizacionEnergia: penalizacionEnergia,
            numDaysNew: numDaysNew,
            numMonthNew: numMonthNew,
            fechaDesdeConsumo: fechaDesdeConsumo,
            fechaHastaConsumo: fechaHastaConsumo,
            EnergiaActiva: ["0"],
            Potencia: ["0"],
            potenciaP1: potenciaP1,
            potenciaP2: potenciaP2,
            potenciaP3: potenciaP3,
            potenciaP4: potenciaP4,
            potenciaP5: potenciaP5,
            potenciaP6: potenciaP6,
            potenciaContratoP1: potenciaP1,
            potenciaContratoP2: potenciaP2,
            potenciaContratoP3: potenciaP3,
            potenciaContratoP4: potenciaP4,
            potenciaContratoP5: potenciaP5,
            potenciaContratoP6: potenciaP6,
            energiaActivaP1: energiaActivaP1,
            energiaActivaP2: energiaActivaP2,
            energiaActivaP3: energiaActivaP3,
            energiaActivaP4: energiaActivaP4,
            energiaActivaP5: energiaActivaP5,
            energiaActivaP6: energiaActivaP6,

            energiaReactivaP1: energiaReactivaP1,
            energiaReactivaP2: energiaReactivaP2,
            energiaReactivaP3: energiaReactivaP3,
            energiaReactivaP4: energiaReactivaP4,
            energiaReactivaP5: energiaReactivaP5,
            energiaReactivaP6: energiaReactivaP6,
            idServicio: idServicio,
            idComercializadora: idComercializadora,
            //nombreComercializadora: nombreComercializadora,
            nombreServicio: nombreServicio,
            precioServicio: precioServicio,
            id: idProducto

        }

    }


    private calculatePrecioActual = (periodos: number[], factura: any) => {

        let {
            serviciosAdicionalesMonth,
            serviciosAdicionales,
            alquilerEquipoMedidaMonth,
            alquilerEquipoMedida,
            codigoATR,
            sumPotencia,
            sumEnergia,
            iePercent,
            impuestoElectrico,
            penalizacionPotencia,
            penalizacionEnergia,
            iva,
            ivaPorcentaje,
            bi,
            total,
            numDaysNew,
            numMonthNew,
            fechaDesdeConsumo,
            fechaHastaConsumo,
            descuento,
            descuentoMonth,
            descuentoPotencia,
            descuentoPotenciaMonth

        } = factura;

        const sip = this.state.sip;
        let consumoPotencia = {};
        let consumoEnergia = {};
        let precioPotencia = {};
        let precioEnergia = {};
        fechaDesdeConsumo = null;
        fechaHastaConsumo = null;


        sumEnergia = 0;
        sumPotencia = 0;


        numDaysNew = 0;
        numMonthNew = 0;



        this.state.itemsConsumo.map(row => {
            if (fechaDesdeConsumo == null) {
                fechaDesdeConsumo = row['fechaInicioMesConsumo'];
                numDaysNew += Math.round(moment.duration(moment(row['fechaFinMesConsumo'], "DD-MM-YYYY").diff(moment(row['fechaInicioMesConsumo'], "DD-MM-YYYY"))).asDays());
                numMonthNew += Math.round(moment.duration(moment(row['fechaFinMesConsumo'], "DD-MM-YYYY").diff(moment(row['fechaInicioMesConsumo'], "DD-MM-YYYY"))).asMonths());
            } else if (moment(fechaDesdeConsumo, "DD-MM-YYYY") > moment(row['fechaInicioMesConsumo'], "DD-MM-YYYY")) {
                fechaDesdeConsumo = row['fechaInicioMesConsumo'];
                numDaysNew += Math.round(moment.duration(moment(row['fechaFinMesConsumo'], "DD-MM-YYYY").diff(moment(row['fechaInicioMesConsumo'], "DD-MM-YYYY"))).asDays());

                numMonthNew += Math.round(moment.duration(moment(row['fechaFinMesConsumo'], "DD-MM-YYYY").diff(moment(row['fechaInicioMesConsumo'], "DD-MM-YYYY"))).asMonths());
            }
            return row;
        });

        this.state.itemsConsumo.map(row => {
            if (fechaHastaConsumo == null) {
                fechaHastaConsumo = row['fechaFinMesConsumo'];
            } else if (moment(fechaHastaConsumo, "DD-MM-YYYY") < moment(row['fechaFinMesConsumo'], "DD-MM-YYYY")) {
                fechaDesdeConsumo = row['fechaFinMesConsumo'];
            }
            return row;
        });


        periodos.map(periodo => {

            precioEnergia[`P${periodo}`] = 0;
            precioPotencia[`P${periodo}`] = 0;
            consumoPotencia[`P${periodo}`] = 0;
            consumoEnergia[`P${periodo}`] = 0;

            this.state.itemsConsumo.map(row => {
                consumoPotencia[`P${periodo}`] += Number(row[`potenciaDemandadaEnWP${periodo}`]) || 0;
                consumoEnergia[`P${periodo}`] += (Number(row[`consumoEnergiaActivaEnWhP${periodo}`]) || 0)
                return row;

            });

            sumEnergia += (consumoEnergia[`P${periodo}`] * this.state.formActual[`precioTEP${periodo}`]);

            if (consumoPotencia[`P${periodo}`] === 0 && this.state.rates2X.includes(sip.codigoTarifaATREnVigor)) {

                const potenciaContratada = sip[`potenciasContratadasEnWP${periodo}`];
                sumPotencia += (potenciaContratada * this.state.formActual[`precioTP${periodo}`]) * numDaysNew;
                //sumPotencia += (potenciaContratada * this.state.formActual[`precioTP${periodo}`]);

            } else if (consumoPotencia[`P${periodo}`] === 0 && sip.codigoTarifaATREnVigor === '018') {
                const potenciaContratada = sip[`potenciasContratadasEnWP${periodo}`];
                sumPotencia += (potenciaContratada * this.state.formActual[`precioTP${periodo}`]) * numDaysNew;
                //sumPotencia += (potenciaContratada * this.state.formActual[`precioTP${periodo}`]);

            } else if (sip.codigoTarifaATREnVigor === '019' || sip.codigoTarifaATREnVigor === '020' || sip.codigoTarifaATREnVigor === '021' || sip.codigoTarifaATREnVigor === '022' || sip.codigoTarifaATREnVigor === '023' || sip.codigoTarifaATREnVigor === '024' || sip.codigoTarifaATREnVigor === '025') {
                sumPotencia += (consumoPotencia[`P${periodo}`] * this.state.formActual[`precioTP${periodo}`]) * numDaysNew;
            } else {
                if (this.state.rates3X.includes(sip.codigoTarifaATREnVigor)) {
                    if (sip.codigoTarifaATREnVigor === '003' || sip.codigoTarifaATREnVigor === '018') {
                        const potenciaContratada = sip[`potenciasContratadasEnWP${periodo}`];
                        sumPotencia += (potenciaContratada * this.state.formActual[`precioTP${periodo}`]) * numDaysNew;

                    } else {
                        sumPotencia += (consumoPotencia[`P${periodo}`] * this.state.formActual[`precioTP${periodo}`]) * numDaysNew;
                    }

                }
            }

            return periodo;
        });
        penalizacionPotencia = this.roundNumber(Number(penalizacionPotencia));
        penalizacionEnergia = this.roundNumber(Number(penalizacionEnergia));
        descuento = this.roundNumber(this.percentage(sumEnergia, descuentoMonth) * numMonthNew);
        descuentoPotencia = this.roundNumber(this.percentage(sumPotencia, descuentoPotenciaMonth) * numMonthNew);
        sumEnergia = this.roundNumber(this.roundNumber(sumEnergia) - descuento);
        sumPotencia = this.roundNumber(sumPotencia);
        serviciosAdicionalesMonth = 0;

        iePercent = this.roundNumber((sumEnergia + sumPotencia) * 0.0511);
        impuestoElectrico = this.roundNumber((sumEnergia + sumPotencia));
        serviciosAdicionales = this.roundNumber(serviciosAdicionalesMonth * numMonthNew);
        alquilerEquipoMedida = this.roundNumber(alquilerEquipoMedidaMonth * numMonthNew);
        bi = this.roundNumber((penalizacionPotencia + penalizacionEnergia + serviciosAdicionales + alquilerEquipoMedida + impuestoElectrico + iePercent));
        iva = this.roundNumber((bi * this.state.formActual.ivaPorcentaje));
        ivaPorcentaje = this.state.formActual.ivaPorcentaje;
        total = this.roundNumber((bi + iva));
        codigoATR = this.state.sip.codigoTarifaATREnVigor;

        return {
            serviciosAdicionalesMonth: serviciosAdicionalesMonth,
            serviciosAdicionales: serviciosAdicionales,
            alquilerEquipoMedidaMonth: alquilerEquipoMedidaMonth,
            alquilerEquipoMedida: alquilerEquipoMedida,
            codigoATR: codigoATR,
            sumPotencia: sumPotencia,
            sumEnergia: sumEnergia,
            iePercent: iePercent,
            impuestoElectrico: impuestoElectrico,
            iva: iva,
            ivaPorcentaje: ivaPorcentaje,
            bi: bi,
            total: total,
            penalizacionPotencia: penalizacionPotencia,
            penalizacionEnergia: penalizacionEnergia,
            numDaysNew: numDaysNew,
            numMonthNew: numMonthNew,
            fechaDesdeConsumo: fechaDesdeConsumo,
            fechaHastaConsumo: fechaHastaConsumo,
            descuento: descuento,
            descuentoMonth: descuentoMonth,
            descuentoPotencia: descuentoPotencia,
            descuentoPotenciaMonth: descuentoPotenciaMonth
        }

    }


    private roundNumber(number: number) {
        return (Math.round(number * 100) / 100)
    }


    private percentage(num, per) {
        return (num / 100) * per;
    }

    public renderFacturaProducto = () => {

        //const producto:any = this.state.producto
        let valorlongitud = Object.keys(this.state.producto).length
        let newfacturaTabla: any[] = [];
        let newAhorroTabla: any[] = [];
        if (this.state && valorlongitud > 0) {

            Object.keys(this.state.producto).map((item, i) => {

                let factura: any = this.calculatePrecioProducto(this.state.periods, this.state.producto[item], this.state.formMulti);
                newfacturaTabla.push(factura)
                let ahorro: any = this.calculateAhorro(this.state.periods, this.state.formActual, factura);
                newAhorroTabla.push(ahorro)
                return item;
            });

            //formMultiTabla = newFacturaTabla;
            //inputState.itemsTabla = newItemsTabla;

            this.setState({
                formMulti: newfacturaTabla,
                totalComparacion: newAhorroTabla
            }
                , this.stateOutput)
        }

    }

    public stateOutput() {
        let { formActual,
            formMultiProducto, producto, itemsTabla, costeOperativo, costeOperativoPotencia, totalComparacion, periods } = this.state;
        console.log("map", this.state);
        let totalComp;
        let periodos;
        let save: boolean = false;
        if (this.state.productSelected !== null && typeof this.state.productSelected !== "undefined") {

            //if para asegurar de pasar un solo producto: el seleccionado
            producto = Object.values(this.state.producto).find((item) => item.id === this.state.productSelected.id);
            if (producto !== undefined) {
                let index: string = Object.keys(this.state.producto).find(key => this.state.producto[key] === producto)!;
                totalComp = this.state.totalComparacion[parseInt(index)];
                //delete totalComp.datosAhorro;
                save = true;
            }
            //periodos
            periodos = itemsTabla.filter(item => {
                if (item.id === this.state.productSelected[0]) {
                    return item
                }
            });
            formMultiProducto = Object.values(this.state.formMulti).find((item) => item.id === this.state.productSelected[0]);


            this.props.output(
                {
                    actual: {
                        datos: formActual,
                        datosAdicionales: {
                            serviciosAdicionalesMonth: formActual.serviciosAdicionalesMonth,
                            serviciosAdicionales: formActual.serviciosAdicionales,
                            alquilerEquipoMedida: formActual.alquilerEquipoMedida,
                            codigoATR: formActual.codigoATR,
                            sumPotencia: formActual.sumPotencia,
                            sumEnergia: formActual.sumEnergia,
                            iePercent: formActual.iePercent,
                            impuestoElectrico: formActual.impuestoElectrico,
                            iva: formActual.iva,
                            ivaPorcentaje: formActual.ivaPorcentaje,
                            bi: formActual.bi,
                            total: formActual.total,
                            penalizacionPotencia: formActual.penalizacionPotencia,
                            penalizacionEnergia: formActual.penalizacionEnergia,
                        }
                    },
                    producto: {
                        datos: formMultiProducto,
                        producto: producto,//Object.values(this.state.producto).find((item) => item.id === this.state.productSelected[0]),
                        itemsPeriodos: periodos,
                        periodos: periods,
                        costeOperativo: costeOperativo,
                        costeOperativoPotencia: costeOperativoPotencia,
                        totalComparacion: totalComp,
                    },
                    enabledSave: save
                }
            );
        } else {

            this.props.output(
                {
                    actual: {
                        datos: formActual,
                        datosAdicionales: {
                            serviciosAdicionalesMonth: formActual.serviciosAdicionalesMonth,
                            serviciosAdicionales: formActual.serviciosAdicionales,
                            alquilerEquipoMedida: formActual.alquilerEquipoMedida,
                            codigoATR: formActual.codigoATR,
                            sumPotencia: formActual.sumPotencia,
                            sumEnergia: formActual.sumEnergia,
                            iePercent: formActual.iePercent,
                            impuestoElectrico: formActual.impuestoElectrico,
                            iva: formActual.iva,
                            ivaPorcentaje: formActual.ivaPorcentaje,
                            bi: formActual.bi,
                            total: formActual.total,
                            penalizacionPotencia: formActual.penalizacionPotencia,
                            penalizacionEnergia: formActual.penalizacionEnergia,
                        }
                    },
                    producto: {
                        datos: [],
                        producto: {},//Object.values(this.state.producto).find((item) => item.id === this.state.productSelected[0]),
                        itemsPeriodos: periodos,
                        periodos: periods,
                        costeOperativo: costeOperativo,
                        costeOperativoPotencia: costeOperativoPotencia,
                        totalComparacion: totalComp,
                    },
                    enabledSave: false
                }
            );

        }
    };


    onPieEnter = (_, index) => {
        this.setState({
            activeIndex: index,
        });
    };

    private onChangeField = async (event) => {
        event.persist()
        const name = event.target.name;
        let value = event.target.value;

        let inputs = {};
        if (value !== "" || value !== null) {
            inputs[name] = Number(value);
            return inputs;

        }
        return false;
    }



    handleChangeContrato = state => {

        var oferta: any = {}
        oferta = state.selectedRows[0]

        this.setState(
            ({ productSelected }) => ({
                productSelected: oferta
            }), () => {
                this.setState(({ productSelected }) => ({
                    productSelected: oferta
                }))
            });

        var productoSel: any = {}
        if (state.selectedCount > 0) {
            productoSel = Object.values(this.state.producto).find((item) => item.id === oferta.id);
        } else {
            productoSel = {}
        }
        this.setState(
            ({ productSelectedTemp }) => ({

                productSelectedTemp: productoSel
            }), () => {
                this.setState(({ productSelectedTemp }) => ({

                    productSelectedTemp: productoSel
                }))
            })


    }


    productosResumen = (props) => {

        const tipoTarifa = props.tipoTarifa;
        const multiTableData: any = props.valores
        if (tipoTarifa === '018') {
            return (


                <DataTable
                    title="Productos"
                    columns={columnsDatosComparativa3p}
                    data={multiTableData}
                    selectableRows
                    selectableRowsComponent={Checkbox} // Pass the function only
                    onSelectedRowsChange={this.handleChangeContrato}

                    fixedHeader={true}
                    highlightOnHover
                    striped
                    pointerOnHover
                    selectableRowsHighlight
                    selectableRowsSingle
                    selectableRowsNoSelectAll={true}

                    noDataComponent={"Sin datos para mostrar"}
                    contextMessage={{ singular: 'Oferta Comercial', plural: 'Ofertas Comerciales', message: 'seleccionada' }}

                    clearSelectedRows={this.state.toggledClearRows}

                />

            )

        } else {
            return (

                <DataTable
                    title="Productos"
                    columns={columnsDatosComparativa6p}
                    data={multiTableData}
                    selectableRows
                    selectableRowsComponent={Checkbox} // Pass the function only
                    onSelectedRowsChange={this.handleChangeContrato}
                    fixedHeader={true}
                    highlightOnHover
                    striped
                    pointerOnHover
                    selectableRowsHighlight
                    selectableRowsSingle
                    selectableRowsNoSelectAll={true}
                    responsive={true}
                    noDataComponent={"Sin datos para mostrar"}
                    contextMessage={{ singular: 'Oferta Comercial', plural: 'Ofertas Comerciales', message: 'seleccionada' }}
                    clearSelectedRows={this.state.toggledClearRows}

                />

            )
        }
    }


    render() {



        const itemsTabla = this.state.itemsTabla;
        //const precioServicio = this.state.precioServicio;
        const totalComparacion = this.state.totalComparacion;
        const producto = this.state.producto;
        const formActual = this.state.formActual;
        const bie = this.state.formDatosContrato.bie
        var tarifaATRProducto = '';

        let penalizacionPotenciaActual = this.state.penalizacionPotenciaCalculo || 0;
        let penalizacionReactivaCalculo = this.state.penalizacionReactivaCalculo || 0;

        const productoSel = this.state.productSelectedTemp





        let valorlongitud = Object.keys(this.state.periods).length


        var multiFields: any = {}
        if (valorlongitud < 6) {

            multiFields = {
                oferta: 'Oferta',
                P1potencia: 'P1 Potencia',
                P2potencia: 'P2 Potencia',
                P3potencia: 'P3 Potencia',
                sumPotencia: 'Suma potencia',
                P1energia: 'P1 Energia',
                P2energia: 'P2 Energia',
                P3energia: 'P3 Energia',
                sumEnergia: 'Suma Energia',
                ahorroAnual: 'Ahorro Anual',
                ahorroPercent: 'Ahorro Percent',
                ahorroPeriodo: 'Ahorro Periodo'
            }
        } else {

            multiFields = {
                oferta: 'Oferta',
                P1potencia: 'P1 Potencia',
                P2potencia: 'P2 Potencia',
                P3potencia: 'P3 Potencia',
                P4potencia: 'P4 Potencia',
                P5potencia: 'P5 Potencia',
                P6potencia: 'P6 Potencia',
                sumPotencia: 'Suma potencia',
                P1energia: 'P1 Energia',
                P2energia: 'P2 Energia',
                P3energia: 'P3 Energia',
                P4energia: 'P4 Energia',
                P5energia: 'P5 Energia',
                P6energia: 'P6 Energia',
                sumEnergia: 'Suma Energia',
                ahorroAnual: 'Ahorro Anual',
                ahorroPercent: 'Ahorro Percent',
                ahorroPeriodo: 'Ahorro Periodo'
            }
        }


        var multiTableData: any = [];
        if (valorlongitud < 6) {

            multiTableData = this.state.totalComparacion.map((row, index) => {
                return {
                    oferta: this.state.producto[index].nombreOferta,
                    id: this.state.producto[index].id,
                    P1potencia: this.state.itemsTabla.find(element => element.id == this.state.producto[index].id && element.periodo == 'P1').precioPotencia,
                    P1energia: this.state.itemsTabla.find(element => element.id == this.state.producto[index].id && element.periodo == 'P1').precioEnergia,
                    P2potencia: this.state.itemsTabla.find(element => element.id == this.state.producto[index].id && element.periodo == 'P2').precioPotencia,
                    P2energia: this.state.itemsTabla.find(element => element.id == this.state.producto[index].id && element.periodo == 'P2').precioEnergia,
                    P3potencia: this.state.itemsTabla.find(element => element.id == this.state.producto[index].id && element.periodo == 'P3').precioPotencia,
                    P3energia: this.state.itemsTabla.find(element => element.id == this.state.producto[index].id && element.periodo == 'P3').precioEnergia,
                    ahorroAnualNum: row['ahorroAnual'],
                    ahorroAnual: row['ahorroAnual'] + ' €',
                    ahorroPercent: row['ahorroPercent'] + ' %',
                    ahorroPeriodo: this.state.formActual ? (row['ahorroPeriodo'] * (this.state.formActual.numDaysNew / 365)).toFixed(2) + ' €' : null,
                    sumPotencia: this.state.formMulti[index] ? this.state.formMulti[index].sumPotencia : null,
                    sumEnergia: this.state.formMulti[index] ? this.state.formMulti[index].sumEnergia : null,
                }
            });
        } else {

            multiTableData = this.state.totalComparacion.map((row, index) => {
                return {
                    oferta: this.state.producto[index].nombreOferta,
                    id: this.state.producto[index].id,
                    P1potencia: this.state.itemsTabla.find(element => element.id == this.state.producto[index].id && element.periodo == 'P1').precioPotencia,
                    P1energia: this.state.itemsTabla.find(element => element.id == this.state.producto[index].id && element.periodo == 'P1').precioEnergia,
                    P2potencia: this.state.itemsTabla.find(element => element.id == this.state.producto[index].id && element.periodo == 'P2').precioPotencia,
                    P2energia: this.state.itemsTabla.find(element => element.id == this.state.producto[index].id && element.periodo == 'P2').precioEnergia,
                    P3potencia: this.state.itemsTabla.find(element => element.id == this.state.producto[index].id && element.periodo == 'P3').precioPotencia,
                    P3energia: this.state.itemsTabla.find(element => element.id == this.state.producto[index].id && element.periodo == 'P3').precioEnergia,
                    P4potencia: this.state.itemsTabla.find(element => element.id == this.state.producto[index].id && element.periodo == 'P4').precioPotencia,
                    P4energia: this.state.itemsTabla.find(element => element.id == this.state.producto[index].id && element.periodo == 'P4').precioEnergia,
                    P5potencia: this.state.itemsTabla.find(element => element.id == this.state.producto[index].id && element.periodo == 'P5').precioPotencia,
                    P5energia: this.state.itemsTabla.find(element => element.id == this.state.producto[index].id && element.periodo == 'P5').precioEnergia,
                    P6potencia: this.state.itemsTabla.find(element => element.id == this.state.producto[index].id && element.periodo == 'P6').precioPotencia,
                    P6energia: this.state.itemsTabla.find(element => element.id == this.state.producto[index].id && element.periodo == 'P6').precioEnergia,
                    ahorroAnualNum: row['ahorroAnual'],
                    ahorroAnual: row['ahorroAnual'] + ' €',
                    ahorroPercent: row['ahorroPercent'] + ' %',
                    ahorroPeriodo: this.state.formActual ? (row['ahorroPeriodo'] * (this.state.formActual.numDaysNew / 365)).toFixed(2) + ' €' : null,
                    sumPotencia: this.state.formMulti[index] ? this.state.formMulti[index].sumPotencia : null,
                    sumEnergia: this.state.formMulti[index] ? this.state.formMulti[index].sumEnergia : null,
                }
            });

        }

        multiTableData.sort((a, b) => parseFloat(b.ahorroAnual) - parseFloat(a.ahorroAnual));


        var datosConsumosEnergia: any = []





        for (let i = 1; i <= valorlongitud; i++) {

            let periodoPE = `P${i}`;
            let valor = 0;


            if (i == 1) {
                valor = this.state.formDatosContrato.energiaActivaP1;
            } else if (i == 2) {
                valor = this.state.formDatosContrato.energiaActivaP2;
            } else if (i == 3) {
                valor = this.state.formDatosContrato.energiaActivaP3;
            } else if (i == 4) {
                valor = this.state.formDatosContrato.energiaActivaP4;
            } else if (i == 5) {
                valor = this.state.formDatosContrato.energiaActivaP5;
            } else {
                valor = this.state.formDatosContrato.energiaActivaP6;
            }



            datosConsumosEnergia.push({ periodo: periodoPE, energia: valor });

        };

        //if (this.state.sip.NumeroPeriodos==="3"){
        //var periodos = [1,2,3]} else {


        var periodos = this.state.periods;
        //};
        const { selectedOption } = this.state;

        var iconVerde;

        if (productoSel.verde == true) {
            iconVerde = "SI"
        }
        else if (productoSel.verde == false) {
            iconVerde = "NO"
        } else { iconVerde = "SIN DATOS" }

        var iconAtencion;

        if (productoSel.atencionCliente == true) {
            iconAtencion = "SI"
        }
        else if (productoSel.atencionCliente == false) {
            iconAtencion = "NO"
        } else { iconAtencion = "SIN DATOS" }

        var iconTelefono;

        if (productoSel.ofertaTel == true) {
            iconTelefono = "SI"
        }
        else if (productoSel.ofertaTel == false) {
            iconTelefono = "NO"
        } else { iconTelefono = "SIN DATOS" }


        return (
            <>
                <Row className="margin-reset w-100">
                    <Card className="mt-3 mb-3 shadow card-billing-template">
                        <CardBody>
                            <Row className="">
                                <h2 className="col item-header">
                                    Propuesta Contrato.
                            </h2>
                            </Row>

                            <Row className="">
                                <h2 className="col item-header">
                                    Ahorros por cada uno de los productos seleccionados
                            </h2>
                            </Row>


                            <this.productosResumen tipoTarifa={this.state.formDatosContrato.tarifaATR} valores={multiTableData} />


                        </CardBody>
                    </Card>

                </Row>

                <Row className="margin-reset w-100">
                    <Card className="mt-3 mb-3 shadow card-billing-template">
                        <ResponsiveContainer width="100%" height="100%">

                            <BarChart
                                width={700}
                                height={300}
                                data={multiTableData}
                                margin={{
                                    top: 40,
                                    right: 30,
                                    left: 20,
                                    bottom: 5,
                                }}
                            >
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="oferta" />
                                <YAxis label={{ value: 'Importe en €', angle: -90, position: 'insideLeft', textAnchor: 'middle' }} />
                                <Tooltip />
                                <Legend />

                                <ReferenceLine y={0} stroke="#000" />
                                <Bar dataKey="sumEnergia" fill="#0088fe" legendType="none" name='Importe Energía' />
                                <Bar dataKey="sumPotencia" fill="#f65cb5" legendType="none" name='Importe Potencia' />
                                <Bar dataKey="ahorroAnualNum" fill="#ff8042" legendType="none" name='Importe Ahorro Anual' /></BarChart>
                        </ResponsiveContainer>

                    </Card>

                    <Card className="mt-3 mb-3 shadow card-billing-template">
                        <ResponsiveContainer aspect={2}>
                            <PieChart width={400} height={400}>
                                <Pie
                                    activeIndex={this.state.activeIndex}
                                    activeShape={renderActiveShape}
                                    data={datosConsumosEnergia}
                                    cx="50%"
                                    cy="50%"
                                    innerRadius={60}
                                    outerRadius={80}
                                    fill="#8884d8"
                                    dataKey="energia"
                                    onMouseEnter={this.onPieEnter}
                                />
                            </PieChart>


                        </ResponsiveContainer>




                    </Card>
                </Row>

                <Row className="margin-reset w-100">


                    <Col md="6" xl="3">
                        <Card className="card-stats">
                            <CardBody>
                                <Row>
                                    <div className="col">
                                        <CardTitle
                                            tag="h5"
                                            className="text-uppercase text-muted mb-0"
                                        >
                                            ENERGIA VERDE
                        </CardTitle>
                                        <span className="h2 font-weight-bold mb-0">
                                            {iconVerde}
                                        </span>
                                    </div>
                                    <Col className="col-auto">
                                        <div className="icon icon-shape bg-gradient-red text-white rounded-circle shadow">
                                            <i className="bi bi-recycle fa-3x" />
                                        </div>
                                    </Col>
                                </Row>
                                <p className="mt-3 mb-0 text-sm">
                                    <span className="text-nowrap">Electricidad de origen verde</span>
                                </p>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col md="6" xl="3">
                        <Card className="card-stats">
                            <CardBody>
                                <Row>
                                    <div className="col">
                                        <CardTitle
                                            tag="h5"
                                            className="text-uppercase text-muted mb-0"
                                        >
                                            Atención al cliente
                        </CardTitle>
                                        <span className="h2 font-weight-bold mb-0">
                                            {iconAtencion}
                                        </span>
                                    </div>
                                    <Col className="col-auto">
                                        <div className="icon icon-shape bg-gradient-green text-white rounded-circle shadow">
                                            <i className="bi bi-headset" />
                                        </div>
                                    </Col>
                                </Row>
                                <p className="mt-3 mb-0 text-sm">
                                    {" "}
                                    <span className="text-nowrap">Con servicios de atención</span>
                                </p>
                            </CardBody>
                        </Card>
                    </Col>

                    <Col md="6" xl="3">
                        <Card className="card-stats">
                            <CardBody>
                                <Row>
                                    <div className="col">
                                        <CardTitle
                                            tag="h5"
                                            className="text-uppercase text-muted mb-0"
                                        >
                                            Oferta Telefónica
                        </CardTitle>
                                        <span className="h2 font-weight-bold mb-0">
                                            {iconTelefono}
                                        </span>
                                    </div>
                                    <Col className="col-auto">
                                        <div className="icon icon-shape bg-gradient-blue text-white rounded-circle shadow">
                                            <i className="bi bi-telephone-outbound" />
                                        </div>
                                    </Col>
                                </Row>
                                <p className="mt-3 mb-0 text-sm">
                                    {" "}
                                    <span className="text-nowrap">Tramitación telefónica</span>
                                </p>
                            </CardBody>
                        </Card>
                    </Col>


                    <Col md="6" xl="3">
                        <Card className="card-stats">
                            <CardBody>
                                <Row>
                                    <div className="col">
                                        <CardTitle
                                            tag="h5"
                                            className="text-uppercase text-muted mb-0"
                                        >
                                            Comercializadora
                        </CardTitle>
                                        <span className="text-xs font-weight-bold mb-0">
                                            {productoSel.nombreComercializadora || "SIN DATOS"}
                                        </span>
                                    </div>
                                    <Col className="col-auto">
                                        <div className="icon icon-shape bg-gradient-red text-white rounded-circle shadow">
                                            <i className="bi bi-building" />
                                        </div>
                                    </Col>
                                </Row>
                                <p className="mt-3 mb-0 text-sm">
                                    {" "}
                                    <span className="text-nowrap">Compañía comercializadora</span>
                                </p>
                            </CardBody>
                        </Card>
                    </Col>

                </Row>



            </>
        );
    }
}
