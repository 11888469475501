import gql from "graphql-tag";

export const EXPORT_SIPS = gql`
  query sipsExportCSV($page: Int, $limit: Int, $filters: SipsFilterInput) {
    sipsExportCSV(page: $page, limit: $limit, filters: $filters)
  }
`;

export const EXPORT_SHORT_SIPS = gql`
  query sipsExportShortCSV($page: Int, $limit: Int, $filters: SipsFilterInput) {
    sipsExportShortCSV(page: $page, limit: $limit, filters: $filters)
  }
`;

export const MASSIVE_EXPORT_SIPS = gql`
  query sipsMassiveExportCSV(
    $cups: [String!]!
    $exportCups: Boolean
    $exportMeasures: Boolean
    $exportPowerOptimization: Boolean
    $exportReactivePowerOptimization: Boolean
    $startDate: DateTime
    $endDate: DateTime
  ) {
    sipsMassiveExportCSV(
      cups: $cups
      exportCups: $exportCups
      exportMeasures: $exportMeasures
      exportPowerOptimization: $exportPowerOptimization
      exportReactivePowerOptimization: $exportReactivePowerOptimization
      startDate: $startDate
      endDate: $endDate
    )
  }
`;

export const LIST_SIPS = gql`
  query listSips($page: Int, $limit: Int, $filters: SipsFilterInput) {
    listSips(page: $page, limit: $limit, filters: $filters) {
      currentPage
      pageSize
      pageCount
      count
      items {
        cups
        empresaDistribuidora
        codigoEmpresaDistribuidora
        nombreEmpresaDistribuidora
        codigoPostalPS
        codigoMunicipioPS
        nombreMunicipioPS
        codigoProvinciaPS
        nombreProvinciaPS
        codigoAutonomiaPS
        nombreAutonomiaPS
        fechaAltaSuministro
        codigoTarifaATREnVigor
        tarifaATR
        codigoTensionV
        Tension
        potenciaMaximaBIEW
        potenciaMaximaAPMW
        codClasificacionPM
        codigoDisponibilidadICP
        perfilConsumo
        valorDerechosExtensionW
        valorDerechosAccesoW
        proEquipoMedida
        codigoPropiedadEquipoMedida
        propiedadICP
        codigoPropiedadICP
        potenciasContratadasEnWP1
        potenciasContratadasEnWP2
        potenciasContratadasEnWP3
        potenciasContratadasEnWP4
        potenciasContratadasEnWP5
        potenciasContratadasEnWP6
        fechaUltimoMovimientoContrato
        fechaUltimoCambioComercializador
        fechaLimiteDerechosReconocidos
        fechaUltimaLectura
        informacionImpagos
        importeDepositoGarantiaEuros
        docIdentidad
        esViviendaHabitual
        codigoComercializadora
        codigoTelegestion
        tipoTelegestion
        codigoFasesEquipoMedida
        codigoAutoconsumo
        descAutoconsumo
        codigoTipoContrato
        tipoContrato
        codigoPeriodicidadFacturacion
        periodoFactura
        codigoBIE
        fechaEmisionBIE
        fechaCaducidadBIE
        codigoAPM
        fechaEmisionAPM
        fechaCaducidadAPM
        relacionTransformacionIntensidad
        CNAE
        actividadCNAE
        codigoModoControlPotencia
        potenciaCGPW
        codigoDHEquipoDeMedida
        discriminacionHoraria
        codigoAccesibilidadContador
        accesoContador
        codigoPSContratable
        motivoEstadoNoContratable
        codigoTensionMedida
        tensionEm
        codigoClaseExpediente
        codigoMotivoExpediente
        descripcionMotivoExpediente
        codigoTipoSuministro
        descripcionSuministro
        aplicacionBonoSocial
        nombreCompletoTitular
        direccion_ps
        localidad_ps
        provincia_ps
        codigoPostal_ps
        codeComercializadora
        comercializadora
        codeDistribuidora
        distribuidora
        distribuidoraPrincipal
        perfilPersona
        pais
        kWhAnual
        datosConsumo
        kWhContrato
        ambitoGeografico
        CIF

        ConsumoAnualTotalActiva
        ConsumoAnualActivaP1
        ConsumoAnualActivaP2
        ConsumoAnualActivaP3
        ConsumoAnualActivaP4
        ConsumoAnualActivaP5
        ConsumoAnualActivaP6
        ConsumoAnualActivaP1Porcentaje
        ConsumoAnualActivaP2Porcentaje
        ConsumoAnualActivaP3Porcentaje
        ConsumoAnualActivaP4Porcentaje
        ConsumoAnualActivaP5Porcentaje
        ConsumoAnualActivaP6Porcentaje
        ConsumoAnualTotalActivaAnterior
        ConsumoAnualActivaP1Anterior
        ConsumoAnualActivaP2Anterior
        ConsumoAnualActivaP3Anterior
        ConsumoAnualActivaP4Anterior
        ConsumoAnualActivaP5Anterior
        ConsumoAnualActivaP6Anterior

        ProblemaId
        ProblemaIdString
      }
    }
  }
`;

export const VIEW_SIPS = gql`
  query viewSips($cups: String!) {
    viewSips(cups: $cups) {
      cups
      empresaDistribuidora
      codigoEmpresaDistribuidora
      nombreEmpresaDistribuidora
      codigoPostalPS
      codigoMunicipioPS
      nombreMunicipioPS
      codigoProvinciaPS
      nombreProvinciaPS
      codigoAutonomiaPS
      nombreAutonomiaPS
      fechaAltaSuministro
      codigoTarifaATREnVigor
      tarifaATR
      codigoTensionV
      Tension
      potenciaMaximaBIEW
      potenciaMaximaAPMW
      codClasificacionPM
      codigoDisponibilidadICP
      perfilConsumo
      valorDerechosExtensionW
      valorDerechosAccesoW
      proEquipoMedida
      codigoPropiedadEquipoMedida
      propiedadICP
      codigoPropiedadICP
      potenciasContratadasEnWP1
      potenciasContratadasEnWP2
      potenciasContratadasEnWP3
      potenciasContratadasEnWP4
      potenciasContratadasEnWP5
      potenciasContratadasEnWP6
      fechaUltimoMovimientoContrato
      fechaUltimoCambioComercializador
      fechaLimiteDerechosReconocidos
      fechaUltimaLectura
      informacionImpagos
      importeDepositoGarantiaEuros
      docIdentidad
      esViviendaHabitual
      codigoComercializadora
      codigoTelegestion
      tipoTelegestion
      codigoFasesEquipoMedida
      codigoAutoconsumo
      descAutoconsumo
      codigoTipoContrato
      tipoContrato
      codigoPeriodicidadFacturacion
      periodoFactura
      codigoBIE
      fechaEmisionBIE
      fechaCaducidadBIE
      codigoAPM
      fechaEmisionAPM
      fechaCaducidadAPM
      relacionTransformacionIntensidad
      CNAE
      actividadCNAE
      codigoModoControlPotencia
      potenciaCGPW
      codigoDHEquipoDeMedida
      discriminacionHoraria
      codigoAccesibilidadContador
      accesoContador
      codigoPSContratable
      motivoEstadoNoContratable
      codigoTensionMedida
      tensionEm
      codigoClaseExpediente
      codigoMotivoExpediente
      descripcionMotivoExpediente
      codigoTipoSuministro
      descripcionSuministro
      aplicacionBonoSocial
      nombreCompletoTitular
      direccion_ps
      localidad_ps
      provincia_ps
      codigoPostal_ps
      codeComercializadora
      comercializadora
      codeDistribuidora
      distribuidora
      distribuidoraPrincipal
      perfilPersona
      pais
      kWhAnual
      datosConsumo
      kWhContrato
      ambitoGeografico
      CIF

      ConsumoAnualTotalActiva
      ConsumoAnualActivaP1
      ConsumoAnualActivaP2
      ConsumoAnualActivaP3
      ConsumoAnualActivaP4
      ConsumoAnualActivaP5
      ConsumoAnualActivaP6
      ConsumoAnualActivaP1Porcentaje
      ConsumoAnualActivaP2Porcentaje
      ConsumoAnualActivaP3Porcentaje
      ConsumoAnualActivaP4Porcentaje
      ConsumoAnualActivaP5Porcentaje
      ConsumoAnualActivaP6Porcentaje
      ConsumoAnualTotalActivaAnterior
      ConsumoAnualActivaP1Anterior
      ConsumoAnualActivaP2Anterior
      ConsumoAnualActivaP3Anterior
      ConsumoAnualActivaP4Anterior
      ConsumoAnualActivaP5Anterior
      ConsumoAnualActivaP6Anterior

      ProblemaId
      ProblemaIdString

      penalizacionPotencia
      penalizacionReactiva
      NumeroPeriodos
      NumeroPeriodosPotencia
    }
  }
`;

export const LIST_BUSINESS = gql`
  query listBusiness($cif: String!) {
    listBusiness(cif: $cif) {
        Nombre
        CIF
        FormaJuridica
        Telefono
        Web
        CNAE
        LiteralCNAE
        Marca
        Localidad
        Provincia
        IngExplota
        Calle
        CodigoPostal
        Proveedor
        Ejercicio
        Consolidada

    }
  }
`;
