import React from "react";
import {
    Button,
    FormGroup,
    Modal,
    Label,
    Col,
    Input,
    Row,
    Form,
} from "reactstrap";
import ReactBSAlert from "react-bootstrap-sweetalert";
//import client from "../../../api/client";
import axios from 'axios';
//import { LIST_COMERCIAL_PRODUCTOS } from "../../../queries/comercial-producto/comercial-producto";


interface IModalComparativeProps {
    itemsSelected: Array<any>;
    isOpen: boolean;
    toggle: any;
    sip: any;
    periods: number[];
    generateComparative?: Function;
}

interface IModalComparativeState {
    form: {
        idProducto: any,
        costeOperativo: number,
        costeOperativoPotencia: number,
        esNuevoContrato: boolean

    },
    cups: string,
    user: any,
    sip: any,
    periods: number[],
    itemsSelected: any[],
    comercialProductos: any[],
    alert: any
}

export default class SipComparativeFreeModal extends React.Component<IModalComparativeProps, IModalComparativeState> {
    public formComparativeRef: any = null;
    public formSaveComparativeRef: any = null;
    public state: IModalComparativeState = {
        // --- Data
        form: {
            costeOperativo: 0,
            costeOperativoPotencia: 0,
            idProducto: null,
            esNuevoContrato: true
        },
        cups: "",
        user: {},
        sip: {},
        periods: [],
        itemsSelected: [],
        comercialProductos: [],
        alert: null
    };

    UNSAFE_componentWillReceiveProps(newProps) {
        this.setState({ itemsSelected: newProps.itemsSelected });
    }

    componentDidMount() {
        this.formComparativeRef = React.createRef();
        this.fetchItems();
    }

    fetchItems = async () => {
        //console.log('VALOR DE this.props.sip en sipComparativeFreeModal ------------->',this.props)


        //if (this.props.sip.codigoTarifaATREnVigor == '001' ||
        var periodosCupsPotencia = ''
        var periodosCupString = ''

        if (['001', '004', '005', '006', '007', '008', '018'].indexOf(this.props.sip.codigoTarifaATREnVigor) >= 0) {
            periodosCupString = '3';
            periodosCupsPotencia = '2';
        } else if (this.props.sip.codigoTarifaATREnVigor === '003' || this.props.sip.codigoTarifaATREnVigor === '019') {
            periodosCupString = '4';
            periodosCupsPotencia = '6';
        } else {
            periodosCupString = '6';
            periodosCupsPotencia = '6';

        }

        const companyUser = await JSON.parse(await localStorage.getItem("user")!);
        const companyIdUser = companyUser.companyId;
        //const periodosCupString =this.props.sip.NumeroPeriodos;
        //const periodosCupsPotencia = this.props.sip.NumeroPeriodosPotencia

        var dataFilters = {
            'companyId': "",
            'idComercializadora': "",
            'id': "",
            'nombreOferta': "",
            'tipoContrato': "L",
            'esActiva': true,
            'periodoValidezDesde':"",
            'periodoValidezHasta': "",
            'peaje': periodosCupString,
            'tarifaATR': this.props.sip.codigoTarifaATREnVigor
        };

//'periodosPotencia': periodosCupsPotencia

        const valores = JSON.stringify(dataFilters)
        const headers = {
            'Authorization': 'Bearer my-token',
            'My-Custom-Header': 'foobar',
            'x-api-key': '$2y$10$bcT1Ukm4V/6/z5GvitZv0unu8I91mpixuwUO6z5CyEGev9DDDY28W'
        };
        let direccionFile = "https://datapi.psgestion.es/producto/listado?id=" + companyIdUser;

        axios.get(direccionFile, { headers, params: { valores } }).
            then(result => {
                console.log('resultado del listados')
                if(result.data.rows.length>0){
                let data = result.data.rows

                this.setState({ comercialProductos: data });

                } else {
                this.warningAlertNIF()
                };
            }).catch((error) => {
                console.log('Failure')
                this.warningAlertDB();
            });



        /*await client.query({
            query: LIST_COMERCIAL_PRODUCTOS,
            variables: { companyId: companyIdUser, periodos: periodosCupString, periodosPotencia: periodosCupsPotencia },
        })
            .then((result) => {
                let data = result.data.comercialProductos;

                this.setState({ comercialProductos: data })
            });*/
    };

    hideAlert = () => {
        this.setState({
            alert: null
        });
    };

    warningAlert = () => {
        this.setState({
            alert: (
                <ReactBSAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Error en selección"
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="warning"
                    confirmBtnText="Ok"
                    btnSize=""
                >
                    Es necesario seleccionar un producto
         </ReactBSAlert>
            )
        });
    };

    warningAlertDB = () => {
        this.setState({
            alert: (
                <ReactBSAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Error en la base de datos"
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="warning"
                    confirmBtnText="Ok"
                    btnSize=""
                >
                    Existe un error en la Base de datos.
         </ReactBSAlert>
            )
        });
    };


    warningAlertNIF = () => {
        this.setState({
            alert: (
                <ReactBSAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title="No existen registros que cumplan los requisitos"
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="warning"
                    confirmBtnText="Ok"
                    btnSize=""
                >
                    Revise los requisitos introducidos
     </ReactBSAlert>
            )
        });
    };


    generateComparative = async (event) => {

        console.log('VALOR DE THIS.STE EN GENERATE COMPARATIVE -----> ', this.state)

        if (this.props.generateComparative) {
            if (this.state.form.idProducto) {
                let producto = this.state.comercialProductos.filter(item => item.id === this.state.form.idProducto)[0];
                this.props.generateComparative({
                    productoSeleccionado: producto,
                    costeOperativo: Number(this.state.form.costeOperativo),
                    costeOperativoPotencia: Number(this.state.form.costeOperativoPotencia),
                    esNuevoContrato: this.state.form.esNuevoContrato
                });
                this.props.toggle();
                this.setState({
                    form: {
                        costeOperativo: 0,
                        costeOperativoPotencia: 0,
                        idProducto: null,
                        esNuevoContrato: true
                    }
                })
                return;
            } else{
            this.warningAlert();
            }
        }
    }

    onChangeField = async (event) => {
        const name = event.target.name;

        let value =
            event.target.type === "checkbox"
                ? event.target.checked
                : event.target.value;


        let inputs = {};

        if (value !== "" || value !== null) {
            inputs[name] = value;
            this.setState({
                form: {
                    ...this.state.form,
                    ...inputs
                }
            });
        }
    }

    render() {
        const sip: any = this.props.sip;
        const form: any = this.state.form;
        const productos: any[] = this.state.comercialProductos;
        const radioStyle = {
            display: "flex",
            justifyContent: "space-between",
        };

        return (
            <>
                <Modal
                    size="sm"
                    className="modal-dialog-centered modal-sip-comparative-free"
                    isOpen={this.props.isOpen}
                    toggle={this.props.toggle}>
                    <div className="modal-body">
                        <Row>
                            <div className="modal-info">
                                <h1>{sip.tarifaATR}</h1>
                            </div>
                        </Row>
                        <Form innerRef={this.formComparativeRef}>
                            <Row>
                                <Col>
                                    <FormGroup>
                                        <Label className="form-control-label" htmlFor="fee">
                                            Tarifa de acceso
                                        </Label>
                                        <Input
                                            id="tarifaAcceso"
                                            placeholder=""
                                            name="tarifaAcceso"
                                            value={sip.tarifaATR}
                                            type="text"
                                            readOnly />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label className="form-control-label"
                                            htmlFor="idProducto">
                                            Seleccione un producto
                                        </Label>
                                        <span hidden={productos.length > 0 ? true : false}><br /> No existen productos para tus criterios de búsqueda</span>
                                        <Input
                                            id="idProducto"
                                            placeholder=""
                                            name="idProducto"
                                            onChange={this.onChangeField}
                                            hidden={productos.length > 0 ? false : true}
                                            type="select" multiselect>
                                            <option value="">Seleccionar Producto</option>
                                            {productos.map((item: any, index) => <option value={item.id} key={index}>{item.nombreOferta}</option>)}

                                        </Input>
                                    </FormGroup>
{this.state.alert}

<FormGroup style={radioStyle}>
    <Label className="form-control-label" htmlFor="esNuevoContrato">
        Nuevo Contrato
</Label>
    <Label className="custom-toggle">
        <Input
            id="esNuevoContrato"
            name="esNuevoContrato"
            onChange={this.onChangeField}
            checked={form.esNuevoContrato}
            type="checkbox"
        />
        <span className="custom-toggle-slider rounded-circle"> </span>
    </Label>

</FormGroup>

                                    <FormGroup>
                                        <Label className="form-control-label" htmlFor="costeOperativo">
                                            CO ENERGIA c€/kWh
                                        </Label>
                                        <Input
                                            id="costeOperativo"
                                            placeholder=""
                                            value={form.costeOperativo}
                                            name="costeOperativo"
                                            type="number"
                                            onChange={this.onChangeField}
                                            required />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label className="form-control-label" htmlFor="costeOperativoPotencia">
                                            CO POTENCIA c€/kWh
                                        </Label>
                                        <Input
                                            id="costeOperativoPotencia"
                                            placeholder=""
                                            value={form.costeOperativoPotencia}
                                            name="costeOperativoPotencia"
                                            type="number"
                                            onChange={this.onChangeField}
                                            required />
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Form>
                    </div>
                    <div className="modal-footer">
                        <Button
                            onClick={this.props.toggle}
                            color="secondary"
                            data-dismiss="modal"
                            type="button">
                            Cerrar
	                    </Button>
                        <Button onClick={this.generateComparative} color="primary" type="button">
                            Generar Comparativa
	                    </Button>
                    </div>
                </Modal>
            </>
        );
    }
}
