import React from "react";
// reactstrap components
import { Card, Container, Row, CardTitle, CardBody } from "reactstrap";

import LoadingOverlay from "react-loading-overlay";
import { CSVLink } from "react-csv";

// core components
import Header from "../../components/Headers/Header";

import { withApollo } from "react-apollo";
import {
  VIEW_SIPSGAS_CONSUMOS_TOTAL,
  LIST_SIPSGAS_CONSUMOS,
} from "../../queries/sips-consumo/sipGas-consumo";
import { VIEW_SIPSGAS } from "../../queries/sips/sipsGas.graphql";

import SipsTable from "../../components/Sips/SipsTable";
import moment from "moment";

const highPriorityItemsFields = {
  cups: "CUPS",
  codigoEmpresaDistribuidora:"Código Distribuidora",
  nombreEmpresaDistribuidora: "Distribuidora",
  codigoPresion: "Código Presión",
  descCodigoPresion: "Descripción Presión",
  codigoPeajeEnVigor: "Código Peaje",
  desCodigoPeajeEnVigor: "Descripción Peaje En Vigor",
  fechaUltimaInspeccion: "Fecha Ultima Inspección",
  codigoResultadoInspeccion: "Código Resultado Inspección",
  descCodigoResultadoInspeccion: "Descripción Resultado",
  fechaUltimoMovimientoContrato: "Fecha Ultimo Movimiento Contrato",
  fechaUltimoCambioComercializador: "Fecha Ultimo Cambio Comercializadora",
  CNAE: "CNAE",
  actividadCNAE:"Descripción CNAE",
  codigoProvinciaPS: "Código Provincia PS",
  desProvinciaPS: "Provincia PS",
  codigoPostalPS: "Código Postal PS",
  municipioPS: "Código Municipio PS",
  desMunicipioPS:"Municipio PS",
  tipoViaPS: "Tipo Vía PS",
  viaPS: "Vía PS",
  numFincaPS: "Número Finca PS",
  portalPS: "Portal PS",
  escaleraPS: "Escalera PS",
  pisoPS: "Piso PS",
  puertaPS:"Puerta PS",
};
const lowPriorityItemsFields = {
  caudalMaximoDiarioEnWh: "Caudal Máximo Diario en Wh",
  caudalHorarioEnWh: "Caudal Horario En Wh",
  derechoTUR: "Derecho TUR",
  tipoPerfilConsumo: "Perfil Consumo",
  codigoContador: "Código Contador",
  calibreContador: "Calibre Contador",
  tipoContador: "Tipo Contador",
  propiedadEquipoMedida: "Propiedad Equipo Medida",
  descPropiedadEquipoMedida: "Descripción Propiedad Equipo",
  codigoTelemedida: "Código Telemedida",
  informacionImpagos: "Información Impagos",
  idTipoTitular: "Id Tipo Titular",
  descIdTipoTitular:"Descripción Id",
  idTitular: "id Titular",
  nombreTitular: "Nombre Titular",
  apellido1Titular: "Apellido1 Titular",
  apellido2Titular: "Apellido2 Titular",
  codigoProvinciaTitular: "Código Provincia Titular",
  desProvinciaTitular: "Provincia",
  codigoPostalTitular: "Código Postal Titular",
  municipioTitular: "Código Municipio Titular",
  desMunicipioTitular: "Municipio Titular",
  tipoViaTitular: "Tipo Vía Titular",
  viaTitular: "Vía Titular",
  numFincaTitular: "Número Finca Titular",
  portalTitular: "Portal Titular",
  escaleraTitular: "Escalera Titular",
  pisoTitular: "Piso Titular",
  puertaTitular: "Puerta Titular",
  esViviendaHabitual: "Vivienda Habitual",
  tipoCorrector:"Tipo Corrector",
  descTipoCorrector:"Descripción Tipo",
  codigoAccesibilidadContador: "Código Accesibilidad Contador",
  descCodigoAccesibilidadContador: "Descripción Accesibilidad",
  conectadoPlantaSatelite: "Código Conexión Planta Satelite",
  descConectadoPlantaSatelite: "Descripción Conexión",
  pctd: "PCTD",
  presionMedida: "Presión Medida",
  ConsumoAnualEnWhP1: "Consumo Anual En Wh P1",
  ConsumoAnualEnWhP2: "Consumo Anual En Wh P2",
  ConsumoAnualTotal: "Consumo Anual Total",
  ConsumoAnualAnteriorEnWhP1: "Consumo Anual Anterior En Wh P1",
  ConsumoAnualAnteriorEnWhP2: "Consumo Anual Anterior En Wh P2",
  ConsumoAnualTotalAnterior: "Consumo Anual Total Anterior",
  CIF: "CIF",
};

const sipsConsumosTotalFields = {

  year: "Año",
  codigoTarifaPeaje: "Codigo Tarifa",
  ConsumoAnualEnWhP1: "kWh P1",
  ConsumoAnualEnWhP2: "kWh P2",
  ConsumoAnualTotal: "Consumo Total",

};

const sipsConsumosTotalExpandableFields = {};

const sipsConsumosFields = {
  fechaFinMesConsumo: "Fecha",
  codigoTarifaPeaje: "Codigo PEAJE",
  consumoEnWhP1: "Consumo kWhP1",
  consumoEnWhP2: "Consumo kWhP2",
  caudalMedioEnWhdia: "Caudal Medio Día Wh",
  caudaMinimoDiario: "Caudal Mínimo Diario",
  caudaMaximoDiario: "Caudal Máximo Diario",
  porcentajeConsumoNocturno: "Porcentaje Consumo Nocturno",
  codigoTipoLectura: "Codigo tipo lectura",
};


const sipsConsumosExpandableFields = {};

class SipGasDetails extends React.Component<any, any> {
  lastFilters = {};
  state = {
    isLoading: false,
    copiedText: null,
    currentPage: 0,
    pageSize: 0,
    pageCount: 0,
    count: 0,
    cups: "",
    page: 1,
    limit: 10,
    sipsConsumosTotal: [],
    sipsConsumos: [],
    sipsDetail: [],
    isLoadingSips: true,
    isLoadingConsumos: true,
    isLoadingConsumosTotales: true,
    paginatedData: {
      items: [],
    },
  };

  sipsCSVData: any = [];
  viewSipsConsumoAnualCSVData: any = [];
  sipsConsumosCSVData: any = [];
  sipstotal: any = [];

  componentDidMount() {
    const {
      match: { params },
    } = this.props;
    this.setState({ cups: params.cups }, this.fetchItems);
  }

  fetchItems = async () => {
    // Sips
    await this.props.client
      .query({
        query: VIEW_SIPSGAS,
        variables: { cups: this.state.cups },
      })
      .then((result) => {
        this.setState({ sipsDetail: result.data.viewSipsGas });
        this.setState({ isLoadingSips: false });
        if (result.data.viewSipsGas) {
          const sipsCSVHeader: any = Object.values(
            highPriorityItemsFields
          ).concat(Object.values(lowPriorityItemsFields)) as any;
          const sipsFields = Object.keys({
            ...highPriorityItemsFields,
            ...lowPriorityItemsFields,
          });

          const csvArray = result.data.viewSipsGas.map((o) => {
            return sipsFields.map((field) => {
              if (field.includes("fecha") && o[field]) {
                const dateValue = new Date(o[field]);

                o[field] = moment(dateValue).format("DD-MM-YYYY");
              }
              return o[field];
            });
          });
          this.sipsCSVData = [];
          this.sipsCSVData = this.sipsCSVData.concat([["Datos de suministro"]]);
          this.sipsCSVData = this.sipsCSVData.concat([[]]);
          this.sipsCSVData = this.sipsCSVData.concat([[""]]);
          this.sipsCSVData = this.sipsCSVData.concat([[]]);
          this.sipsCSVData = this.sipsCSVData.concat([sipsCSVHeader]);
          this.sipsCSVData = this.sipsCSVData.concat(csvArray);
        }
      });
    // Sips Consumos Total
    await this.props.client
      .query({
        query: VIEW_SIPSGAS_CONSUMOS_TOTAL,
        variables: { cups: this.state.cups },
      })
      .then((result) => {
        this.setState({ sipsConsumosTotal: result.data.viewSipsGasConsumoAnual });
        this.setState({ isLoadingConsumosTotales: false });
        if (result.data.viewSipsGasConsumoAnual) {
          const viewSipsConsumoAnualCSVHeader: any = Object.values(
            sipsConsumosTotalFields
          );
          const viewSipsConsumoAnualFields = Object.keys(
            sipsConsumosTotalFields
          );

          const csvArray = result.data.viewSipsGasConsumoAnual.map((o) => {
            return viewSipsConsumoAnualFields.map((field) => o[field]);
          });

          this.viewSipsConsumoAnualCSVData = [];
          this.viewSipsConsumoAnualCSVData = this.viewSipsConsumoAnualCSVData.concat(
            [[]]
          );
          this.viewSipsConsumoAnualCSVData = this.viewSipsConsumoAnualCSVData.concat(
            [[""]]
          );
          this.viewSipsConsumoAnualCSVData = this.viewSipsConsumoAnualCSVData.concat(
            [[]]
          );
          this.viewSipsConsumoAnualCSVData = this.viewSipsConsumoAnualCSVData.concat(
            [["Consumos totales"]]
          );
          this.viewSipsConsumoAnualCSVData = this.viewSipsConsumoAnualCSVData.concat(
            [[]]
          );
          this.viewSipsConsumoAnualCSVData = this.viewSipsConsumoAnualCSVData.concat(
            [[""]]
          );
          this.viewSipsConsumoAnualCSVData = this.viewSipsConsumoAnualCSVData.concat(
            [[]]
          );
          this.viewSipsConsumoAnualCSVData = this.viewSipsConsumoAnualCSVData.concat(
            [viewSipsConsumoAnualCSVHeader]
          );
          this.viewSipsConsumoAnualCSVData = this.viewSipsConsumoAnualCSVData.concat(
            csvArray
          );
        }
      });
    // Sips Consumos
    await this.props.client
      .query({
        query: LIST_SIPSGAS_CONSUMOS,
        variables: { cups: this.state.cups },
      })
      .then((result) => {
        this.setState({ sipsConsumos: result.data.listSipsGasConsumos });
        this.setState({ isLoadingConsumos: false });
        if (result.data.listSipsGasConsumos) {
          const listSipsConsumosCSVHeader: any = Object.values(
            sipsConsumosFields
          );
          const listSipsConsumosFields = Object.keys(sipsConsumosFields);

          const csvArray = result.data.listSipsGasConsumos.map((o) => {
            return listSipsConsumosFields.map((field) => {
              if (field.includes("fecha") && o[field]) {
                const dateValue = new Date(o[field]);
                o[field] = moment(dateValue).format("DD-MM-YYYY");
              }
              return o[field];
            });
          });

          this.sipsConsumosCSVData = [];
          this.sipsConsumosCSVData = this.sipsConsumosCSVData.concat([[]]);
          this.sipsConsumosCSVData = this.sipsConsumosCSVData.concat([[""]]);
          this.sipsConsumosCSVData = this.sipsConsumosCSVData.concat([[]]);
          this.sipsConsumosCSVData = this.sipsConsumosCSVData.concat([
            ["Consumos"],
          ]);
          this.sipsConsumosCSVData = this.sipsConsumosCSVData.concat([[]]);
          this.sipsConsumosCSVData = this.sipsConsumosCSVData.concat([[""]]);
          this.sipsConsumosCSVData = this.sipsConsumosCSVData.concat([[]]);
          this.sipsConsumosCSVData = this.sipsConsumosCSVData.concat([
            listSipsConsumosCSVHeader,
          ]);
          this.sipsConsumosCSVData = this.sipsConsumosCSVData.concat(csvArray);
          this.sipstotal = this.sipsCSVData;
          this.sipstotal = this.sipstotal.concat(
            this.viewSipsConsumoAnualCSVData
          );
          this.sipstotal = this.sipstotal.concat(this.sipsConsumosCSVData);
          this.forceUpdate();
        }
      });
  };

  onPageClick = (page) => {};

  export = () => {};

  render() {
    const disableExport =
      this.state.isLoadingSips ||
      this.state.isLoadingConsumos ||
      this.state.isLoadingConsumosTotales ||
      this.state.isLoadingSips;
    const disableExportClass = disableExport ? " disabled" : "";

    return (
      <>
        <Header title={'Suministro Gas "' + this.state.cups + '"'} />
        <Container className=" mt--7" fluid>
          <Row>
            <div className="col">
              <Card className="shadow">
                <CardBody>
                  <div className="text-left">
                    <button
                      className="btn btn-sm btn-primary"
                      onClick={this.props.history.goBack}
                    >
                      Atrás
                    </button>
                  </div>
                  <div className="text-right">
                    {this.sipstotal ? (
                      <CSVLink
                        data={this.sipstotal}
                        onClick={() => !disableExport}
                        separator={";"}
                        filename={"sips_" + this.state.cups + ".csv"}
                        className={
                          "btn btn-sm btn-primary" + disableExportClass
                        }
                      >
                        Exportar
                      </CSVLink>
                    ) : null}
                  </div>
                  <CardTitle>Detalles</CardTitle>
                  <LoadingOverlay
                    active={this.state.isLoadingSips}
                    spinner
                    text="Cargando..."
                  >
                    <SipsTable
                      showProblemInfo={true}
                      hideViewMoreButton={true}
                      items={this.state.sipsDetail}
                      fields={highPriorityItemsFields}
                      expandableFields={lowPriorityItemsFields}
                    ></SipsTable>
                  </LoadingOverlay>
                </CardBody>
              </Card>

              <Card className="mt-3 shadow">
                <CardBody>
                  <CardTitle>Consumo totales</CardTitle>
                  <LoadingOverlay
                    active={this.state.isLoadingConsumosTotales}
                    spinner
                    text="Cargando..."
                  >
                    <SipsTable
                      hideViewMoreButton={true}
                      items={this.state.sipsConsumosTotal}
                      fields={sipsConsumosTotalFields}
                      expandableFields={sipsConsumosTotalExpandableFields}
                    ></SipsTable>
                  </LoadingOverlay>
                </CardBody>
              </Card>
              <Card className="mt-3 shadow">
                <CardBody>
                  <CardTitle>Consumos</CardTitle>
                  <LoadingOverlay
                    active={this.state.isLoadingConsumos}
                    spinner
                    text="Cargando..."
                  >
                    <SipsTable
                      hideViewMoreButton={true}
                      items={this.state.sipsConsumos}
                      fields={sipsConsumosFields}
                      expandableFields={sipsConsumosExpandableFields}
                    ></SipsTable>
                  </LoadingOverlay>
                </CardBody>
              </Card>
            </div>
          </Row>
        </Container>
      </>
    );
  }
}

export default withApollo(SipGasDetails);
