import gql from "graphql-tag";

export const LIST_SIPS_CONSUMOS = gql`
  query($cups: String!) {
    listSipsConsumos(cups: $cups) {
      cups
      fechaInicioMesConsumo
      fechaFinMesConsumo
      year
      month
      codigoTarifaATR
      consumoEnergiaActivaEnWhP1
      consumoEnergiaActivaEnWhP2
      consumoEnergiaActivaEnWhP3
      consumoEnergiaActivaEnWhP4
      consumoEnergiaActivaEnWhP5
      consumoEnergiaActivaEnWhP6
      consumoEnergiaReactivaEnVArhP1
      consumoEnergiaReactivaEnVArhP2
      consumoEnergiaReactivaEnVArhP3
      consumoEnergiaReactivaEnVArhP4
      consumoEnergiaReactivaEnVArhP5
      consumoEnergiaReactivaEnVArhP6
      potenciaDemandadaEnWP1
      potenciaDemandadaEnWP2
      potenciaDemandadaEnWP3
      potenciaDemandadaEnWP4
      potenciaDemandadaEnWP5
      potenciaDemandadaEnWP6
      codigoDHEquipoDeMedida
      codigoTipoLectura
    }
  }
`;

export const VIEW_SIPS_CONSUMOS_TOTAL = gql`
  query($cups: String!) {
    viewSipsConsumoAnual(cups: $cups) {
      cups
      year
      codigoTarifaATR
      ConsumoAnualActivaP1
      ConsumoAnualActivaP2
      ConsumoAnualActivaP3
      ConsumoAnualActivaP4
      ConsumoAnualActivaP5
      ConsumoAnualActivaP6
      ConsumoAnualTotalActiva
      ConsumoAnualReactivaP1
      ConsumoAnualReactivaP2
      ConsumoAnualReactivaP3
      ConsumoAnualReactivaP4
      ConsumoAnualReactivaP5
      ConsumoAnualReactivaP6
      ConsumoAnualTotalReactiva
      PotenciaDemandadaP1
      PotenciaDemandadaP2
      PotenciaDemandadaP3
      PotenciaDemandadaP4
      PotenciaDemandadaP5
      PotenciaDemandadaP6
      PotenciaDemandadaTotal
    }
  }
`;
