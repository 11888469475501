import React from "react";
import {
  Modal,
  //Alert,
  //Card,
  //CardHeader,
  //CardBody,
  Label,
  FormGroup,
  Input,
  Row,
  Button,
  Col,
  //Collapse,
  FormText,
  Form,
} from "reactstrap";
import client from "../../../api/client";
import axios from 'axios';

import SipsOption from "../../../components/Sips/SipsOption";
import { validateSpanishId, spainIdType } from 'spain-id'
import { LIST_DICMUNICIPIOS } from "../../../queries/options/dic-option.graphql";
import { CLIENT_DATA } from "../../../queries/sips-comparativa/sip-comparativa-general";
import { ToastContainer, toast } from 'react-toastify';
const ibantools = require('ibantools');

const notifyError = () => toast.error(
  "No existe cliente con el CIF/NIF introducido",
  {
    position: "top-center",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });

const notifyErrorCIF = () => toast.error(
  "Introduzca CIF/NIF para poder hacer la búsqueda",
  {
    position: "top-center",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });

interface IModalProps {
  isOpen: boolean;
  toggle: any;
  onSave?: Function;
  sip: any;
  ubi: any;
  nombrePropuesta: string;
  companyId: any;
  userId: any;
}

interface IModalState {
  form: {
    nombreOferta: string;
    correoContacto: string;
    idComercializadora: string;
    nombreComercializadora: string;
    esAprobada: boolean;
    razonSocial: any;
    NIF: any;
    DireccionFiscal: any; // "C/ FELIPE SOLANO ANTELO 8A 8º3", ---> direccion_titular
    nombreTitular: any;
    apellido1Titular: any;
    apellido2Titular: any;
    tipoTitular: any;
    tipoTitularRepresentante: any;
    nombreRepresentante: any;
    apellido1Representante: any;
    apellido2Representante: any;
    NIFRepresentante: any;
    CargoRepresentante: any;
    idRepresentante: any;
    idRepresentanteInterno: any;
    telefonoRepresentante: any;
    correoRepresentante: any;
    companyIdRepresentante: any;
desAutonomiaPS:any;
    desAutonomiaTitular: any;
    desProvinciaTitular: any;
    desMunicipioTitular: any;
    tipoViaTitular: any;
    viaTitular: any;
    numFincaTitular: any;
    portalTitular: any;
    escaleraTitular: any;
    pisoTitular: any;
    puertaTitular: any;
    iban: any;
    CodigoPostalTitular: any; //"08630",----> codigo_postal_titular
    TelefonoFijo: any; //"949201893", ----> telefono_fijo
    TelefonMovil: any; //"689105332", ----> telefono
    Email: any; //"demo@demo.com", --->email_contacto
    TipoCliente: any; //"COMUNIDAD DE PROPIETARIOS", ---> tipo_cliente
    ObservacionesCliente: any; //"OPCIONAL O NULL",----> observaciones
    DireccionPS: any; // "C/ FELIPE SOLANO ANTELO 8A 8º3", ---> direccion_ps

    nombreMunicipioPS: any;
    nombreProvinciaPS: any;
    //
    tipoViaPS: any;
    viaPS: any;
    numFincaPS: any;
    portalPS: any;
    escaleraPS: any;
    pisoPS: any;
    puertaPS: any;

    CodigoPostalPS: any; //"08630", ----> codigo_postal_ps
    TelefonoPS: any; //"689105332", ----> telefono;
    ObservacionesPuntoSuministro: any;
    GDPR: boolean; // "opcional o null" ----> observaciones_ps
  },
  companyId: any;
  userId: any;
  cups: string;
  user: any;
  isDisabled: boolean;
  nombreOfertaError: boolean;
  emailError: boolean;
emailRepreError: boolean;
  nombreTitularError: boolean;
  NIFError: boolean;
NIFRepreError: boolean;
  DireccionFiscalError: boolean;
  CodigoPostalTitularError: boolean;
  TelefonoFijoError: boolean;
  TelefonMovilError: boolean;
  DireccionPSError: boolean;
  CodigoPostalPSError: boolean;
  TelefonoPSError: boolean;
  idComercializadoraError: boolean;
  TipoCliente: any;
  apellido1TitularError: boolean;
  ibanError: boolean;
  dicMunicipios: any[];
  client: {
    idCliente: string;
    idTipoTitular: string;
    descIdTipoTitular: string;
    nombreTitular: string;
    apellido1Titular: string;
    apellido2Titular: string;
    codigoProvinciaTitular: string;
    desProvinciaTitular: string;
    codigoPostalTitular: string;
    municipioTitular: string;
    desMunicipioTitular: string;
    tipoViaTitular: string;
    viaTitular: string;
    numFincaTitular: string;
    portalTitular: string;
    escaleraTitular: string;
    pisoTitular: string;
    puertaTitular: string;
    esViviendaHabitual: string;
    CNAE: string;
    actividadCNAE: string;
    CIF: string;
    codigoAutonomiaPS: string;
    desAutonomiaPS: string;
    idBanco: string;
    descBanco: string;
    iban: string;
    idCompany: string
  };
  isDisabledCIF: boolean;
}

export default class SaveGasModal extends React.Component<
  IModalProps,
  IModalState
  > {
  public formComparativeRef: any = null;
  public formSaveComparativeRef: any = null;
  public state: IModalState = {
    // --- Data
    form: {
        nombreOferta: "",
        correoContacto: "",
        idComercializadora: "",
        nombreComercializadora: "",
        esAprobada: false,
        razonSocial: null,
        NIF: "", //"C19345456", --> CIF
        DireccionFiscal: "", // "C/ FELIPE SOLANO ANTELO 8A 8º3", ---> direccion_titular
        nombreTitular: "",
        apellido1Titular: "",
        apellido2Titular: "",
        nombreRepresentante: "",
        apellido1Representante: "",
        apellido2Representante: "",
        tipoTitular: "",
        tipoTitularRepresentante: "",
        NIFRepresentante: "",
        CargoRepresentante: "",
        idRepresentante: "",
        idRepresentanteInterno: 0,
        telefonoRepresentante: "",
        correoRepresentante: "",
        companyIdRepresentante: "",
        desAutonomiaPS:"",
        desAutonomiaTitular: "",
        desProvinciaTitular: "",
        desMunicipioTitular: "",
        tipoViaTitular: "",
        viaTitular: "",
        numFincaTitular: "",
        portalTitular: "",
        escaleraTitular: "",
        pisoTitular: "",
        puertaTitular: "",
        iban: "",
        CodigoPostalTitular: "", //"08630",----> codigo_postal_titular
        TelefonoFijo: "", //"949201893", ----> telefono_fijo
        TelefonMovil: "", //"689105332", ----> telefono
        Email: "", //"demo@demo.com", --->email_contacto
        TipoCliente: "NO DEFINIDO", //"COMUNIDAD DE PROPIETARIOS", ---> tipo_cliente
        ObservacionesCliente: "", //"OPCIONAL O NULL",----> observaciones
        DireccionPS: "", // "C/ FELIPE SOLANO ANTELO 8A 8º3", ---> direccion_ps
        nombreMunicipioPS: "",
        nombreProvinciaPS: "",
        //nombreAutonomiaPS: "",
        tipoViaPS: "",
        viaPS: "",
        numFincaPS: "",
        portalPS: "",
        escaleraPS: "",
        pisoPS: "",
        puertaPS: "",
        CodigoPostalPS: "", //"08630", ----> codigo_postal_ps
        TelefonoPS: "", //"689105332", ----> telefono;
        ObservacionesPuntoSuministro: "",
        GDPR: true, // "opcional o null" ----> observaciones_ps
    },
    cups: "",
    user: {},
    companyId: "",
    userId: "",
    isDisabled: true,
    nombreOfertaError: false,
    emailError: true,
    emailRepreError: false,
    nombreTitularError: true,
    NIFError: true,
    NIFRepreError: true,
    DireccionFiscalError: true,
    CodigoPostalTitularError: true,
    TelefonoFijoError: true,
    TelefonMovilError: true,
    DireccionPSError: true,
    CodigoPostalPSError: true,
    TelefonoPSError: true,
    idComercializadoraError: true,
    apellido1TitularError: true,
    ibanError: false,
    isDisabledCIF: true,
    dicMunicipios: [],
    client: {
      idCliente: "",
      idTipoTitular: "",
      descIdTipoTitular: "",
      nombreTitular: "",
      apellido1Titular: "",
      apellido2Titular: "",
      codigoProvinciaTitular: "",
      desProvinciaTitular: "",
      codigoPostalTitular: "",
      municipioTitular: "",
      desMunicipioTitular: "",
      tipoViaTitular: "",
      viaTitular: "",
      numFincaTitular: "",
      portalTitular: "",
      escaleraTitular: "",
      pisoTitular: "",
      puertaTitular: "",
      esViviendaHabitual: "",
      CNAE: "",
      actividadCNAE: "",
      CIF: "",
      codigoAutonomiaPS: "",
      desAutonomiaPS: "",
      idBanco: "",
      descBanco: "",
      iban: "",
      idCompany: ""
    },
    TipoCliente: [{ key: "0", cat: "NO DEFINIDO" },
    { key: "1", cat: "PARTICULAR" },
    { key: "2", cat: "PYME INDUSTRIA" },
    { key: "3", cat: "PYME SERVICIO" },
    { key: "4", cat: "ADMINISTRACION PUBLICA" },
    { key: "5", cat: "COMUNIDAD DE PROPIETARIO" }
    ]
  };


  componentWillReceiveProps(newProps) {

console.log('VALOR DE newProps ----> ', newProps)
    if (!newProps.ubi) {
      const titulartemp = newProps.sip.tipoViaTitular + ' ' + newProps.sip.viaTitular + ' ' + newProps.sip.numFincaTitular + ' ' + newProps.sip.portalTitular + ' ' + newProps.sip.pisoTitular + ' ' + newProps.sip.puertaTitular;
      const pstemp = newProps.sip.tipoViaPS + ' ' + newProps.sip.viaPS + ' ' + newProps.sip.numFincaPS + ' ' + newProps.sip.portalPS + ' ' + newProps.sip.pisoPS + ' ' + newProps.sip.puertaPS;
var TipoTitular = ""
      if (newProps.sip.CIF) {
          const result = validateSpanishId(newProps.sip.CIF)
          if (result === true) {
              const resultTipo = spainIdType(newProps.sip.CIF)
              console.log('VALOR DE RESULTADO DE VALIDATE Tipo -------> ', resultTipo)
              TipoTitular = resultTipo.toUpperCase();
              this.setState({
                  NIFError: false,
                  isDisabledCIF: false
              })

              /*  this.setState(
                ({ NIFError }) => ({
                  NIFError:false,
                    isDisabledCIF:false
                }), () => {
                  this.setState(({ NIFError }) => ({
                    NIFError:false,
                      isDisabledCIF:false
                  }))
                } )*/
          } else {
              this.setState({
                  NIFError: true,
              })
          }

      };


      this.setState({
          companyId: newProps.companyId,
          userId: newProps.userId,
        form:
        {
          nombreOferta: newProps.nombrePropuesta,
          correoContacto: "",
          idComercializadora: "",
          nombreComercializadora: "",
          esAprobada: false,
          razonSocial: newProps.sip.nombreTitular + ' ' + newProps.sip.apellido1Titular + ' ' + newProps.sip.apellido2Titularr,
          NIF: newProps.sip.CIF, //"C19345456", --> CIF
          DireccionFiscal: titulartemp, // "C/ FELIPE SOLANO ANTELO 8A 8º3", ---> direccion_titular
          nombreTitular: newProps.sip.nombreTitular,
          apellido1Titular: newProps.sip.apellido1Titular,
          apellido2Titular: newProps.sip.apellido2Titularr,
          tipoTitular: "",
          tipoTitularRepresentante: "",
          nombreRepresentante: "",
          apellido1Representante: "",
          apellido2Representante: "",
          NIFRepresentante: "",
          CargoRepresentante: "",
          idRepresentante: "",
          idRepresentanteInterno: 0,
          telefonoRepresentante: "",
          correoRepresentante: "",
          companyIdRepresentante: "",
          desAutonomiaTitular: newProps.sip.desAutonomiaTitular,
          desProvinciaTitular: newProps.sip.desProvinciaTitular,
          desMunicipioTitular: newProps.sip.desMunicipioTitular,
          tipoViaTitular: newProps.sip.tipoViaTitular,
          viaTitular: newProps.sip.viaTitular,
          numFincaTitular: newProps.sip.numFincaTitular,
          portalTitular: newProps.sip.portalTitular,
          escaleraTitular: newProps.sip.escaleraTitular,
          pisoTitular: newProps.sip.pisoTitular,
          puertaTitular: newProps.sip.puertaTitular,
          iban: "",
          CodigoPostalTitular: newProps.sip.codigoPostalTitular, //"08630",----> codigo_postal_titular
          TelefonoFijo: newProps.ubi.telefono_fijo, //"949201893", ----> telefono_fijo
          TelefonMovil: newProps.ubi.telefono, //"689105332", ----> telefono
          Email: "", //"demo@demo.com", --->email_contacto
          TipoCliente: "NO DEFINIDO", //"COMUNIDAD DE PROPIETARIOS", ---> tipo_cliente
          ObservacionesCliente: newProps.ubi.observaciones, //"OPCIONAL O NULL",----> observaciones

          nombreMunicipioPS: newProps.sip.desMunicipioPS,
          nombreProvinciaPS: newProps.sip.desProvinciaPS,
          desAutonomiaPS: newProps.sip.desAutonomiaPS,
          tipoViaPS: newProps.sip.tipoViaPS,
          viaPS: newProps.sip.viaPS,
          numFincaPS: newProps.sip.numFincaPS,
          portalPS: newProps.sip.portalPS,
          escaleraPS: newProps.sip.escaleraPS,
          pisoPS: newProps.sip.pisoPS,
          puertaPS: newProps.sip.puertaPS,

          DireccionPS: pstemp, // "C/ FELIPE SOLANO ANTELO 8A 8º3", ---> direccion_ps
          CodigoPostalPS: newProps.sip.codigoPostalPS, //"08630", ----> codigo_postal_ps
          TelefonoPS: newProps.ubi.telefono, //"689105332", ----> telefono;
          ObservacionesPuntoSuministro: "",
          GDPR: true, // "opcional o null" ----> observaciones_ps
        }
      });
    } else {
      const titulartemp = newProps.sip.tipoViaTitular + ' ' + newProps.sip.viaTitular + ' ' + newProps.sip.numFincaTitular + ' ' + newProps.sip.portalTitular + ' ' + newProps.sip.pisoTitular + ' ' + newProps.sip.puertaTitular;
      const pstemp = newProps.sip.tipoViaPS + ' ' + newProps.sip.viaPS + ' ' + newProps.sip.numFincaPS + ' ' + newProps.sip.portalPS + ' ' + newProps.sip.pisoPS + ' ' + newProps.sip.puertaPS;
      this.setState({
          companyId: newProps.companyId,
          userId: newProps.userId,
        form:
        {
          nombreOferta: newProps.nombrePropuesta,
          correoContacto: "",
          idComercializadora: "",
          nombreComercializadora: "",
          esAprobada: false,
          razonSocial: newProps.sip.nombreTitular + ' ' + newProps.sip.apellido1Titular + ' ' + newProps.sip.apellido2Titularr,
          NIF: newProps.sip.CIF, //"C19345456", --> CIF
          DireccionFiscal: titulartemp, // "C/ FELIPE SOLANO ANTELO 8A 8º3", ---> direccion_titular

          nombreTitular: newProps.sip.nombreTitular,
          apellido1Titular: newProps.sip.apellido1Titular,
          apellido2Titular: newProps.sip.apellido2Titularr,
          tipoTitular: "",
          tipoTitularRepresentante: "",
          nombreRepresentante: "",
          apellido1Representante: "",
          apellido2Representante: "",
          NIFRepresentante: "",
          CargoRepresentante: "",
          idRepresentante: "",
          idRepresentanteInterno: 0,
          telefonoRepresentante: "",
          correoRepresentante: "",
          companyIdRepresentante: "",
          desAutonomiaTitular: newProps.sip.desAutonomiaTitular,
          desProvinciaTitular: newProps.sip.desProvinciaTitular,
          desMunicipioTitular: newProps.sip.desMunicipioTitular,
          tipoViaTitular: newProps.sip.tipoViaTitular,
          viaTitular: newProps.sip.viaTitular,
          numFincaTitular: newProps.sip.numFincaTitular,
          portalTitular: newProps.sip.portalTitular,
          escaleraTitular: newProps.sip.escaleraTitular,
          pisoTitular: newProps.sip.pisoTitular,
          puertaTitular: newProps.sip.puertaTitular,
          iban: "",
          CodigoPostalTitular: newProps.sip.codigoPostalTitular, //"08630",----> codigo_postal_titular
          TelefonoFijo: newProps.ubi.telefono_fijo, //"949201893", ----> telefono_fijo
          TelefonMovil: newProps.ubi.telefono, //"689105332", ----> telefono
          Email: "", //"demo@demo.com", --->email_contacto
          TipoCliente: "NO DEFINIDO", //"COMUNIDAD DE PROPIETARIOS", ---> tipo_cliente
          ObservacionesCliente: newProps.ubi.observaciones, //"OPCIONAL O NULL",----> observaciones


          nombreMunicipioPS: newProps.sip.nombreMunicipioPS,
          nombreProvinciaPS: newProps.sip.nombreProvinciaPS,
          desAutonomiaPS: newProps.sip.nombreAutonomiaPS,
          tipoViaPS: newProps.sip.tipoViaPS,
          viaPS: newProps.sip.viaPS,
          numFincaPS: newProps.sip.numFincaPS,
          portalPS: newProps.sip.portalPS,
          escaleraPS: newProps.sip.escaleraPS,
          pisoPS: newProps.sip.pisoPS,
          puertaPS: newProps.sip.puertaPS,

          DireccionPS: pstemp, // "C/ FELIPE SOLANO ANTELO 8A 8º3", ---> direccion_ps
          CodigoPostalPS: newProps.sip.codigoPostalPS, //"08630", ----> codigo_postal_ps
          TelefonoPS: newProps.ubi.telefono, //"689105332", ----> telefono;
          ObservacionesPuntoSuministro: "",
          GDPR: true, // "opcional o null" ----> observaciones_ps
        }
      });


    }



    if (this.props.sip.nombreTitular) {

      this.setState({
        nombreTitularError: false,
      })
    } else {
      this.setState({
        nombreTitularError: true,
      })

    };

    if (this.props.sip.CIF) {
      this.setState({
        NIFError: false,
      })
    } else {
      this.setState({
        NIFError: true,
      })

    };

    // if(this.state.form.DireccionFiscal !=='' || this.state.form.DireccionFiscal !==null || this.state.form.DireccionFiscal !='0' || this.state.form.DireccionFiscal  !==' '){
    if (this.props.sip.viaTitular) {
      this.setState({
        DireccionFiscalError: false,
      })
    } else {
      this.setState({
        DireccionFiscalError: true,
      })

    };

    if (this.props.sip.codigoPostalTitular) {
      this.setState({
        CodigoPostalTitularError: false,
      })
    } else {
      this.setState({
        CodigoPostalTitularError: true,
      })

    };



    if (this.props.ubi.telefono_fijo) {
      const pattern = /^[679]{1}[0-9]{8}$/
      var strPhone = this.state.form.TelefonoFijo.toString().replace(/\s/g, '');
      const result = strPhone.length === 9 && pattern.test(this.state.form.TelefonoFijo);

      if (result === true) {
        this.setState({
          TelefonoFijoError: false,
        })
      } else {
        this.setState({
          TelefonoFijoError: true,
        })

      };
    }


    if (this.props.sip.viaPS) {
      this.setState({
        DireccionPSError: false,
      })
    } else {
      this.setState({
        DireccionPSError: true,
      })

    };

    if (this.props.sip.codigoPostalPS) {
      this.setState({
        CodigoPostalPSError: false,
      })
    } else {
      this.setState({
        CodigoPostalPSError: true,
      })

    };



    if (this.props.sip.apellido1Titular) {
      this.setState({
        apellido1TitularError: false,
      })
    } else {
      this.setState({
        apellido1TitularError: true,
      })

    };


    this.forceUpdate();
  }

componentDidMount() {
    this.fetchItems();
  }

  fetchItems = async () => {

    await client.query({
      query: LIST_DICMUNICIPIOS,
      variables: {}
    })
      .then((result) => {
        let data = result.data.listDicMunicipios;
        this.setState({ dicMunicipios: data })
      });
}


UNSAFE_componentWillMount() {

    if (this.state.form.nombreOferta !== '' || this.state.form.nombreOferta !== null || this.state.form.nombreOferta !== '0') {
      this.setState({
        nombreOfertaError: false,
      })
    }

    if (this.state.form.correoContacto !== '' || this.state.form.correoContacto !== null || this.state.form.correoContacto !== '0') {
      const pattern = /[a-zA-Z0-9]+[\.]?([a-zA-Z0-9]+)?[\@][a-z]{3,9}[\.][a-z]{2,5}/g;
      const result = pattern.test(this.state.form.correoContacto);

      if (result === true) {
        this.setState({
          emailError: false
        })
      }

};


    //if(this.props.ubi.nombre_completo_titular !=='' || this.props.ubi.nombre_completo_titular !==null || this.props.ubi.nombre_completo_titular !=='0'){

    if (this.props.sip.apellido1Titular) {

      this.setState({
        apellido1TitularError: false,
      })
    } else {
      this.setState({
        apellido1TitularError: true,
      })

    };

    if (this.props.ubi.CIF) {
        const result = validateSpanishId(this.props.ubi.CIF)
        if (result === true) {
            const resultTipo = spainIdType(this.props.ubi.CIF)
            console.log('VALOR DE RESULTADO DE VALIDATE Tipo -------> ', resultTipo)
            FormData['tipoTitular'] = resultTipo.toUpperCase();
            this.setState({
                NIFError: false,
                isDisabledCIF: false
            })

        } else {
            this.setState({
                NIFError: true,
            })
        }

    };



    // if(this.state.form.DireccionFiscal !=='' || this.state.form.DireccionFiscal !==null || this.state.form.DireccionFiscal !='0' || this.state.form.DireccionFiscal  !==' '){
    if (this.props.sip.viaTitular) {
      this.setState({
        DireccionFiscalError: false,
      })
    } else {
      this.setState({
        DireccionFiscalError: true,
      })

    };

    if (this.props.sip.codigoPostalTitular) {
      this.setState({
        CodigoPostalTitularError: false,
      })
    } else {
      this.setState({
        CodigoPostalTitularError: true,
      })

    };



    if (this.props.ubi.telefono_fijo) {
      const pattern = /^[679]{1}[0-9]{8}$/
      var strPhone = this.state.form.TelefonoFijo.toString().replace(/\s/g, '');
      const result = strPhone.length === 9 && pattern.test(this.state.form.TelefonoFijo);

      if (result === true) {
        this.setState({
          TelefonoFijoError: false,
        })
      } else {
        this.setState({
          TelefonoFijoError: true,
        })

      };
    }


    if (this.props.sip.viaPS) {
      this.setState({
        DireccionPSError: false,
      })
    } else {
      this.setState({
        DireccionPSError: true,
      })

    };

    if (this.props.sip.codigoPostalPS) {
      // console.log('VALOR DE CP PS------------------------->', this.props.ubi.codigo_postal_ps)
      this.setState({
        CodigoPostalPSError: false,
      })
    } else {
      this.setState({
        CodigoPostalPSError: true,
      })

    };

    this.forceUpdate()
  };


  validateEmail = (email) => {

    const pattern = /[a-zA-Z0-9]+[\.]?([a-zA-Z0-9]+)?[\@][a-z]{3,9}[\.][a-z]{2,5}/g;
    //const pattern = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/
    const result = pattern.test(email);
    if (result === true) {
      this.setState({
        emailError: false,
      })
    } else {
      this.setState({
        emailError: true
      })
    }
  }


  validatePhone = (TelefonoFijo) => {
    //const pattern = /[a-zA-Z0-9]+[\.]?([a-zA-Z0-9]+)?[\@][a-z]{3,9}[\.][a-z]{2,5}/g;
    //const pattern = ^\+{0,2}([\-\. ])?(\(?\d{0,3}\))?([\-\. ])?\(?\d{0,3}\)?([\-\. ])?\d{3}([\-\. ])?\d{4}

    const pattern = /^[679]{1}[0-9]{8}$/
    var strPhone =""
    if(TelefonoFijo){
    strPhone = TelefonoFijo.toString().replace(/\s/g, '');
    }
    const result = strPhone.length === 9 && pattern.test(TelefonoFijo);
    if (result === true) {
      this.setState({
        TelefonoFijoError: false,
      })
    } else {
      this.setState({
        TelefonoFijoError: true
      })
    }
  }

  onChangeField = (event) => {
    event.persist()


    let formData = { ...this.state.form };
    let target = event.target;
    let value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    const name = event.target.name;

    if (event.target.name === 'nombreOferta') {

      if (event.target.value) {
        this.setState({
          nombreOfertaError: false
        })
      } else {
        this.setState({
          nombreOfertaError: true,
        })
      }
    }

    if (event.target.name === 'correoContacto') {
      //this.validateEmail(event.target.value);

      const pattern = /[a-zA-Z0-9]+[\.]?([a-zA-Z0-9]+)?[\@][a-z]{3,9}[\.][a-z]{2,5}/g;
      //const pattern = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/
      const result = pattern.test(event.target.value);

      if (result === true) {
        this.setState({
          emailError: false,
        })
      } else {
        this.setState({
          emailError: true
        })
      }
    }

    if (event.target.name === 'Email') {


        const pattern = /[a-zA-Z0-9]+[\.]?([a-zA-Z0-9]+)?[\@][a-z]{3,9}[\.][a-z]{2,5}/g;
        //const pattern = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/
        const result = pattern.test(event.target.value);

        if (result === true) {
            this.setState({
                emailError: false,
            })
        } else {
            this.setState({
                emailError: true
            })
        }
    }

    if (event.target.name === 'iban') {
      //this.validateEmail(event.target.value);
      //console.log('entra en IBAN')
      const resultIban = ibantools.isValidIBAN(event.target.value);

      //console.log(resultIban)//const pattern = /[a-zA-Z0-9]+[\.]?([a-zA-Z0-9]+)?[\@][a-z]{3,9}[\.][a-z]{2,5}/g;
      //const pattern = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/
      //const result = pattern.test(event.target.value);

      if (resultIban === true) {
        this.setState({
          ibanError: false,
        })
      } else {
        this.setState({
          ibanError: true
        })
      }
    }

    if (event.target.name === 'apellido1Titular') {

      if (event.target.value) {
        this.setState({
          apellido1TitularError: false
        })
      } else {
        this.setState({
          apellido1TitularError: true,
        })
      }
    }

    if (event.target.name === 'nombreTitular') {

      if (event.target.value) {
        this.setState({
          nombreTitularError: false
        })
      } else {
        this.setState({
          nombreTitularError: true,
        })
      }
    }

    if (event.target.name === 'NIF') {
        const result = validateSpanishId(event.target.value)
        console.log('VALOR DE RESULTADO DE VALIDATESpanish------> ', result)

        //if(event.target.value){
        if (result === true) {
            const resultTipo = spainIdType(event.target.value)
            console.log('VALOR DE RESULTADO DE VALIDATE Tipo -------> ', resultTipo)
            formData['tipoTitular'] = resultTipo.toUpperCase();
            this.setState({
                NIFError: false,
                isDisabledCIF: false
            })

            /*  this.setState(
              ({ NIFError }) => ({
                NIFError:false,
                  isDisabledCIF:false
              }), () => {
                this.setState(({ NIFError }) => ({
                  NIFError:false,
                    isDisabledCIF:false
                }))
              } )*/
        } else {
            this.setState({
                NIFError: true,
            })
        }
    };


    if (event.target.name === 'NIFRepresentante') {
        const result = validateSpanishId(event.target.value)
        console.log('VALOR DE RESULTADO DE VALIDATESpanish------> ', result)

        //if(event.target.value){
        if (result === true) {
            const resultTipo = spainIdType(event.target.value)
            console.log('VALOR DE RESULTADO DE VALIDATE Tipo -------> ', resultTipo)
            formData['tipoTitularRepresentante'] = resultTipo.toUpperCase();
            this.setState({
                NIFRepreError: false,
                isDisabledCIF: false
            })

            /*  this.setState(
              ({ NIFError }) => ({
                NIFError:false,
                  isDisabledCIF:false
              }), () => {
                this.setState(({ NIFError }) => ({
                  NIFError:false,
                    isDisabledCIF:false
                }))
              } )*/
        } else {
            this.setState({
                NIFRepreError: true,
            })
        }
    };

    if (event.target.name === 'DireccionFiscal') {

      if (event.target.value) {
        this.setState({
          DireccionFiscalError: false
        })
      } else {
        this.setState({
          DireccionFiscalError: true,
        })
      }
    }

    if (event.target.name === 'CodigoPostalTitular') {

      if (event.target.value) {
        this.setState({
          CodigoPostalTitularError: false
        })
      } else {
        this.setState({
          CodigoPostalTitularError: true,
        })
      }
    }

    if (event.target.name === 'TelefonoFijo') {
      this.validatePhone(event.target.value);
    }

    if (event.target.name === 'DireccionPS') {

      if (event.target.value) {
        this.setState({
          DireccionPSError: false
        })
      } else {
        this.setState({
          DireccionPSError: true,
        })
      }
    }

    if (event.target.name === 'CodigoPostalPS') {

      if (event.target.value) {
        this.setState({
          CodigoPostalPSError: false
        })
      } else {
        this.setState({
          CodigoPostalPSError: true,
        })
      }
    }

    if (name === "idComercializadora" && value !== "0") {

        if (value !== "0") {
            formData['nombreComercializadora'] = [
                ...event.target.querySelectorAll("option"),
            ].filter((item) => item.value === value)[0].innerText;
            formData['idComercializadora'] = value;
            this.setState({
                idComercializadoraError: false,
            })
        } else {
            this.setState({
                idComercializadoraError: true
            })
            value = null;
        }
    };

    var valueTemp: any = [];

    if (name === "TipoCliente") {
      const valorIndex = event.target.options.selectedIndex.toString();
      valueTemp = this.state.TipoCliente.find(x => x.key === valorIndex)
      value = valueTemp.cat;
    }

    if (this.state.nombreOfertaError === false && this.state.idComercializadoraError === false && this.state.emailError === false && this.state.nombreTitularError === false && this.state.NIFError === false && this.state.DireccionFiscalError === false && this.state.CodigoPostalTitularError === false && this.state.DireccionPSError === false && this.state.CodigoPostalPSError === false) {
      this.setState({
        isDisabled: false
      })
    }

    if (this.state.NIFError === false) {
      this.setState({
        isDisabledCIF: false
      })
    }

    formData[name] = value;
    //if (value === "" || value === null) {
    //  formData[name] = 0;
//    }

    if (name == 'desMunicipioTitular') {

      const municipio = this.state.dicMunicipios
      const resultado = municipio.find(municipio => municipio.Nombre === value);

      //console.log('VALOR DE FIND -------------------->', resultado.DescProvincia, resultado.DescAutonomia);

      //const filtered = municipio.filter(entry => Object.values(entry).some(val => typeof val === "string" && val.includes('Barromán')));

      //console.log(filtered);
      formData['desProvinciaTitular'] = resultado.DescProvincia
      formData['desAutonomiaTitular'] = resultado.DescAutonomia

    }


    if (name == 'nombreMunicipioPS') {
      const municipio = this.state.dicMunicipios
      const resultado = municipio.find(municipio => municipio.Nombre === value);
      const filtered = municipio.filter(entry => Object.values(entry).some(val => typeof val === "string" && val.includes('Barromán')));
      formData['nombreProvinciaPS'] = resultado.DescProvincia
      formData['desAutonomiaPS'] = resultado.DescAutonomia
    }



    formData[name] = value;
    this.setState({ form: formData });
  };



/*
  getClient = async () => {

      var companyId = this.state.companyId.toString();
      var user = this.state.userId
      var CIF = this.state.form.NIF

    if (CIF !== null && CIF != '') {
      await client.query({
        query: CLIENT_DATA,
        variables: { companyId, CIF }
      })
        .then((result) => {
          var data = result.data.getClientData;

          if (data) {
            this.setState({ client: data })

            if (data.nombreTitular !== null && data.nombreTitular != '') {
              this.setState({ nombreTitularError: false })
            } else {
              this.setState({ nombreTitularError: true })
            }
            if (data.apellido1Titular !== null && data.apellido1Titular != '') {
              this.setState({ apellido1TitularError: false })
            } else {
              this.setState({ apellido1TitularError: true })
            }
            if (data.codigoPostalTitular !== null && data.codigoPostalTitular != '') {
              this.setState({ CodigoPostalTitularError: false })
            } else {
              this.setState({ CodigoPostalTitularError: true })
            }
            if (data.correoElectronico !== null && data.correoElectronico != '') {
              const pattern = /[a-zA-Z0-9]+[\.]?([a-zA-Z0-9]+)?[\@][a-z]{3,9}[\.][a-z]{2,5}/g;
              const result = pattern.test(data.correoElectronico);

              if (result === true) {
                this.setState({
                  emailError: false
                })
              } else {
                this.setState({
                  emailError: true
                })
              }
            }

            if (data.telefonoFijo !== null && data.telefonoFijo != '') {
              this.validatePhone(data.telefonoFijo);
            }


            let nombreOferta = this.state.form.nombreOferta
            //let correoContacto  = this.state.form.correoContacto
            let idComercializadora = this.state.form.idComercializadora
            let nombreComercializadora = this.state.form.nombreComercializadora
            let esAprobada = this.state.form.esAprobada
            let razonSocial = this.state.form.razonSocial
            let NIF = this.state.form.NIF
            let DireccionFiscal = this.state.form.DireccionFiscal
            let TipoCliente = this.state.form.TipoCliente
            let ObservacionesCliente = this.state.form.ObservacionesCliente
            let DireccionPS = this.state.form.DireccionPS
            let nombreMunicipioPS = this.state.form.nombreMunicipioPS
            let nombreProvinciaPS = this.state.form.nombreProvinciaPS
            //   let nombreAutonomiaPS = this.state.form.nombreAutonomiaPS
            let tipoViaPS = this.state.form.tipoViaPS
            let viaPS = this.state.form.viaPS
            let numFincaPS = this.state.form.numFincaPS
            let portalPS = this.state.form.portalPS
            let escaleraPS = this.state.form.escaleraPS
            let pisoPS = this.state.form.pisoPS
            let puertaPS = this.state.form.puertaPS
            let CodigoPostalPS = this.state.form.CodigoPostalPS
            let TelefonoPS = this.state.form.TelefonoPS
            let ObservacionesPuntoSuministro = this.state.form.ObservacionesPuntoSuministro
            let GDPR = this.state.form.GDPR

            this.setState({
              form: {
                nombreOferta: nombreOferta,
                correoContacto: data.correoElectronico,
                idComercializadora: idComercializadora,
                nombreComercializadora: nombreComercializadora,
                esAprobada: esAprobada,
                razonSocial: razonSocial,
                NIF: NIF,
                DireccionFiscal: DireccionFiscal,
                nombreTitular: data.nombreTitular,
                apellido1Titular: data.apellido1Titular,
                apellido2Titular: data.apellido2Titular,
                desProvinciaTitular: data.desProvinciaTitular,
                desMunicipioTitular: data.desMunicipioTitular,
                desAutonomiaTitular: data.desAutonomiaPS,
                tipoViaTitular: data.tipoViaTitular,
                viaTitular: data.viaTitular,
                numFincaTitular: data.numFincaTitular,
                portalTitular: data.portalTitular,
                escaleraTitular: data.escaleraTitular,
                pisoTitular: data.pisoTitular,
                puertaTitular: data.puertaTitular,
                iban: data.iban,
                CodigoPostalTitular: data.codigoPostalTitular, //"08630",----> codigo_postal_titular
                TelefonoFijo: data.telefonoFijo, //"data.codigoPostalTitular49201893", ----> telefono_fijo
                TelefonMovil: data.telefonoMovil, //"689105332", ----> telefono
                Email: data.correoElectronico, //"demo@demo.com", --->email_contacto
                TipoCliente: TipoCliente, //"COMUNIDAD DE PROPIETARIOS", ---> tipo_cliente
                ObservacionesCliente: ObservacionesCliente, //"OPCIONAL O NULL",----> observaciones

                DireccionPS: DireccionPS, // "C/ FELIPE SOLANO ANTELO 8A 8º3", ---> direccion_ps
                nombreMunicipioPS: nombreMunicipioPS,
                nombreProvinciaPS: nombreProvinciaPS,
                //               nombreAutonomiaPS: nombreAutonomiaPS,
desAutonomiaPS:"",
                tipoViaPS: tipoViaPS,
                viaPS: viaPS,
                numFincaPS: numFincaPS,
                portalPS: portalPS,
                escaleraPS: escaleraPS,
                pisoPS: pisoPS,
                puertaPS: puertaPS,
                CodigoPostalPS: CodigoPostalPS, //"08630", ----> codigo_postal_ps
                TelefonoPS: TelefonoPS, //"689105332", ----> telefono;
                ObservacionesPuntoSuministro: ObservacionesPuntoSuministro,
                GDPR: GDPR, // "opcional o null" ----> observaciones_ps

              },
            });
          } else {
            notifyError();
          };

        });
    } else {
      notifyErrorCIF();


    };
  };
*/

getClient = async () => {

            console.log('VALOR DE THIS STATE EN GETCLIENT ----> ', this.state)

    var companyId = this.state.companyId.toString();
    var user = this.state.userId
    var CIF = this.state.form.NIF


    if (CIF !== null && CIF != '') {


            var dataFilters = {
                'idCompany': companyId,
                'cups': "",
                'Usuario': "",
                'NIF': CIF,
                'idContrato': ""
            };

            const valores = JSON.stringify(dataFilters)
            const headers = {
                'Authorization': 'Bearer my-token',
                'My-Custom-Header': 'foobar',
                'x-api-key': '$2y$10$bcT1Ukm4V/6/z5GvitZv0unu8I91mpixuwUO6z5CyEGev9DDDY28W'
            };

            let direccionFile = "https://datapi.psgestion.es/cliente/listadoID?id=" + companyId + "&user=" + user;

            await axios.get(direccionFile, { headers, params: { valores } }).
                then(result => {

                console.log('VALORES DE RESULT CON CLIENTES -----------> ', result)
                console.log('valores de result con clientes.data -----------> ', result.data)
                console.log('longitud ---> ',result.data.rows.length )

                    if(result.data.rows.length>0){
                    let data = result.data.rows[0]
                    console.log('VALOR DE DAA EN CONSULTA ', data)

                    this.setState({ client: data })
                    if (data.NombreTitular !== null && data.NombreTitular != '') {
                        this.setState({ nombreTitularError: false })
                    } else {
                        this.setState({ nombreTitularError: true })
                    }
                    if (data.Apellido1Titular !== null && data.Apellido1Titular != '') {
                        this.setState({ apellido1TitularError: false })
                    } else {
                        this.setState({ apellido1TitularError: true })
                    }
                    if (data.CodigoPostalTitular !== null && data.CodigoPostalTitular != '') {
                        this.setState({ CodigoPostalTitularError: false })
                    } else {
                        this.setState({ CodigoPostalTitularError: true })
                    }
                    if (data.Email !== null && data.Email != '') {
                        const pattern = /[a-zA-Z0-9]+[\.]?([a-zA-Z0-9]+)?[\@][a-z]{3,9}[\.][a-z]{2,5}/g;
                        const result = pattern.test(data.Email);

                        if (result === true) {
                            this.setState({
                                emailError: false
                            })
                        } else {
                            this.setState({
                                emailError: true
                            })
                        }
                    }

                    if (data.TelefonoFijo !== null && data.TelefonoFijo != '') {
                        this.validatePhone(data.TelefonoFijo);
                    }


                    let nombreOferta = this.state.form.nombreOferta
                    //let correoContacto  = this.state.form.correoContacto
                    let idComercializadora = this.state.form.idComercializadora
                    let nombreComercializadora = this.state.form.nombreComercializadora
                    let esAprobada = this.state.form.esAprobada
                    let razonSocial = this.state.form.razonSocial
                    let NIF = this.state.form.NIF
                    let DireccionFiscal = this.state.form.DireccionFiscal
                    let TipoCliente = this.state.form.TipoCliente
                    let ObservacionesCliente = this.state.form.ObservacionesCliente
                    let DireccionPS = this.state.form.DireccionPS
                    let nombreMunicipioPS = this.state.form.nombreMunicipioPS
                    let nombreProvinciaPS = this.state.form.nombreProvinciaPS
                    //   let nombreAutonomiaPS = this.state.form.nombreAutonomiaPS
                    let tipoViaPS = this.state.form.tipoViaPS
                    let viaPS = this.state.form.viaPS
                    let numFincaPS = this.state.form.numFincaPS
                    let portalPS = this.state.form.portalPS
                    let escaleraPS = this.state.form.escaleraPS
                    let pisoPS = this.state.form.pisoPS
                    let puertaPS = this.state.form.puertaPS
                    let CodigoPostalPS = this.state.form.CodigoPostalPS
                    let TelefonoPS = this.state.form.TelefonoPS
                    let ObservacionesPuntoSuministro = this.state.form.ObservacionesPuntoSuministro
                    let GDPR = this.state.form.GDPR

                    this.setState({
                        form: {
                            nombreOferta: nombreOferta,
                            correoContacto: data.correoElectronico,
                            idComercializadora: idComercializadora,
                            nombreComercializadora: nombreComercializadora,
                            esAprobada: esAprobada,
                            razonSocial: razonSocial,
                            NIF: NIF,
                            DireccionFiscal: DireccionFiscal,
                            nombreTitular: data.NombreTitular,
                            apellido1Titular: data.Apellido1Titular,
                            apellido2Titular: data.Apellido2Titular,
                            tipoTitular: data.descIdTipoTitular,
                            tipoTitularRepresentante: "",
                            nombreRepresentante: "",
                            apellido1Representante: "",
                            apellido2Representante: "",
                            NIFRepresentante: "",
                            CargoRepresentante: "",
                            idRepresentante: "",
                            idRepresentanteInterno: 0,
                            telefonoRepresentante: "",
                            correoRepresentante: "",
                            companyIdRepresentante: "",
                            desProvinciaTitular: data.ProvinciaTitular,
                            desMunicipioTitular: data.MunicipioTitular,
                            desAutonomiaTitular: data.AutonomiaTitular,
                            tipoViaTitular: data.TipoViaTitular,
                            viaTitular: data.ViaTitular,
                            numFincaTitular: data.NumFincaTitular,
                            portalTitular: data.PortalTitular,
                            escaleraTitular: data.EscaleraTitular,
                            pisoTitular: data.PisoTitular,
                            puertaTitular: data.PuertaTitular,
                            desAutonomiaPS:"",
                            iban: data.IBAN,
                            CodigoPostalTitular: data.CodigoPostalTitular, //"08630",----> codigo_postal_titular
                            TelefonoFijo: data.TelefonoFijo, //"data.codigoPostalTitular49201893", ----> telefono_fijo
                            TelefonMovil: data.TelefonMovil, //"689105332", ----> telefono
                            Email: data.Email, //"demo@demo.com", --->email_contacto
                            TipoCliente: TipoCliente, //"COMUNIDAD DE PROPIETARIOS", ---> tipo_cliente
                            ObservacionesCliente: ObservacionesCliente, //"OPCIONAL O NULL",----> observaciones

                            DireccionPS: DireccionPS, // "C/ FELIPE SOLANO ANTELO 8A 8º3", ---> direccion_ps
                            nombreMunicipioPS: nombreMunicipioPS,
                            nombreProvinciaPS: nombreProvinciaPS,
                            //               nombreAutonomiaPS: nombreAutonomiaPS,
                            tipoViaPS: tipoViaPS,
                            viaPS: viaPS,
                            numFincaPS: numFincaPS,
                            portalPS: portalPS,
                            escaleraPS: escaleraPS,
                            pisoPS: pisoPS,
                            puertaPS: puertaPS,
                            CodigoPostalPS: CodigoPostalPS, //"08630", ----> codigo_postal_ps
                            TelefonoPS: TelefonoPS, //"689105332", ----> telefono;
                            ObservacionesPuntoSuministro: ObservacionesPuntoSuministro,
                            GDPR: GDPR, // "opcional o null" ----> observaciones_ps

                            }
                        });
                    } else {
                        notifyError();
                    };

                }).catch((error) => {
                    console.log('Failure')
                    console.log('VALOR DE ERROR----> ', error)
                    //this.warningAlertDB()
                    notifyError();
                });
                } else {
                   notifyErrorCIF();


               };


};

///////////////////////////////////////////////////////////////////////////

getRepresentante = async () => {

    var companyIdUser = this.state.companyId.toString();
    var user = this.state.userId
    var CIF = this.state.form.NIFRepresentante
if (CIF !== null && CIF != '') {


    var dataFilters = {
        'idCompany': companyIdUser,
        'cups': "",
        'Usuario': "",
        'NIFRepresentante': CIF,
        'idContrato': ""
    };

    const valores = JSON.stringify(dataFilters)
    const headers = {
        'Authorization': 'Bearer my-token',
        'My-Custom-Header': 'foobar',
        'x-api-key': '$2y$10$bcT1Ukm4V/6/z5GvitZv0unu8I91mpixuwUO6z5CyEGev9DDDY28W'
    };

    let direccionFile = "https://datapi.psgestion.es/representante/listado?id=" + companyIdUser + "&user=" + user;

    await axios.get(direccionFile, { headers, params: { valores } }).
        then(result => {

            if(result.data.rows.length>0){
            let data = result.data.rows
            //this.setState({ representantes: data })
            let formData = { ...this.state.form };
            formData['nombreRepresentante'] = data[0].nombreRepresentante
            formData['apellido1Representante'] = data[0].apellido1Representante
            formData['apellido2Representante'] = data[0].apellido2Representante
            formData['NIFRepresentante'] = data[0].NIFRepresentante
            formData['idRepresentante'] = data[0].idRepresentante
            formData['idRepresentanteInterno'] = data[0].idRepresentanteInterno
            formData['CargoRepresentante'] = data[0].CargoRepresentante
            formData['telefonoRepresentante'] = data[0].telefonoRepresentante
            formData['correoRepresentante'] = data[0].correoRepresentante
            this.setState({ form: formData });
            } else {
            notifyError();
                };

        }).catch((error) => {
            console.log('Failure')
            //this.warningAlertDB()
            notifyError();
        });
        } else {
           notifyErrorCIF();


       };

};

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////7



  generateComparative = (event) => {

    event.preventDefault();

    if (this.props.onSave) {

      if (this.state.form.nombreOferta) {
        this.props.onSave(this.state.form);
        this.props.toggle(event);

        var TelefonoPSError: boolean = true;
        if (this.props.sip.telefono) {

          TelefonoPSError = false;
        } else {
          TelefonoPSError = true;

        }


        var CodigoPostalPSError: boolean = true;
        if (this.props.sip.codigoPostalPS) {

          CodigoPostalPSError = false;
        } else {
          CodigoPostalPSError = true;

        }

        var TelefonMovilError: boolean = true;
        if (this.props.sip.telefono) {

          TelefonMovilError = false;
        } else {
          TelefonMovilError = true;

        }

        var TelefonoFijoError: boolean = true;
        if (this.props.sip.telefono_fijo) {

          TelefonoFijoError = false;
        } else {
          TelefonoFijoError = true;

        }

        var CodigoPostalTitularError: boolean = true;
        if (this.props.sip.codigo_postal_titular) {

          CodigoPostalTitularError = false;
        } else {
          CodigoPostalTitularError = true;

        }

        var DireccionFiscalError: boolean = true;
        if (this.props.sip.viaTitular) {

          DireccionFiscalError = false;
        } else {
          DireccionFiscalError = true;

        }

        var NIFError: boolean = true;
        if (this.props.sip.CIF) {

          NIFError = false;
        } else {
          NIFError = true;

        }


        var apellido1TitularError: boolean = true;
        if (this.props.sip.apellido1Titular) {

          apellido1TitularError = false;
        } else {
          apellido1TitularError = true;

        }


        var nombreTitularError: boolean = true;
        if (this.props.sip.nombreTitular) {

          nombreTitularError = false;
        } else {
          nombreTitularError = true;

        }

        var emailError: boolean = true;
        if (this.props.ubi.email_contacto) {

          emailError = false;
        } else {
          emailError = true;

        }


        var direccionPSError: boolean = true;
        if (this.props.ubi.direccion_ps) {

          direccionPSError = false;
        } else {
          direccionPSError = true;

        }


        this.setState({
            form: {
                nombreOferta: "",
                correoContacto: "",
                idComercializadora: "",
                nombreComercializadora: "",
                esAprobada: false,
                razonSocial: "",
                NIF: "", //"C19345456", --> CIF
                DireccionFiscal: "", // "C/ FELIPE SOLANO ANTELO 8A 8º3", ---> direccion_titular
                nombreTitular: "",
                apellido1Titular: "",
                apellido2Titular: "",
                tipoTitular: "",
                tipoTitularRepresentante: "",
                nombreRepresentante: "",
                apellido1Representante: "",
                apellido2Representante: "",
                NIFRepresentante: "",
                CargoRepresentante: "",
                idRepresentante: "",
                idRepresentanteInterno: 0,
                desAutonomiaPS:"",
                telefonoRepresentante: "",
                correoRepresentante: "",
                companyIdRepresentante: "",
                desAutonomiaTitular: "",
                desProvinciaTitular: "",
                desMunicipioTitular: "",
                tipoViaTitular: "",
                viaTitular: "",
                numFincaTitular: "",
                portalTitular: "",
                escaleraTitular: "",
                pisoTitular: "",
                puertaTitular: "",
                iban: "",
                CodigoPostalTitular: "", //"08630",----> codigo_postal_titular
                TelefonoFijo: "", //"949201893", ----> telefono_fijo
                TelefonMovil: "", //"689105332", ----> telefono
                Email: "", //"demo@demo.com", --->email_contacto
                TipoCliente: "NO DEFINIDO", //"COMUNIDAD DE PROPIETARIOS", ---> tipo_cliente
                ObservacionesCliente: "", //"OPCIONAL O NULL",----> observaciones

                DireccionPS: "", // "C/ FELIPE SOLANO ANTELO 8A 8º3", ---> direccion_ps
                nombreMunicipioPS: "",
                nombreProvinciaPS: "",
                //nombreAutonomiaPS: "",
                tipoViaPS: "",
                viaPS: "",
                numFincaPS: "",
                portalPS: "",
                escaleraPS: "",
                pisoPS: "",
                puertaPS: "",
                CodigoPostalPS: "", //"08630", ----> codigo_postal_ps
                TelefonoPS: "", //"689105332", ----> telefono;
                ObservacionesPuntoSuministro: "",
                GDPR: true, // "opcional o null" ----> observaciones_ps

            },

            nombreOfertaError: false,
            emailError: emailError,
            nombreTitularError: nombreTitularError,
            NIFError: NIFError,
            DireccionFiscalError: DireccionFiscalError,
            CodigoPostalTitularError: CodigoPostalTitularError,
            TelefonoFijoError: TelefonoFijoError,
            TelefonMovilError: TelefonMovilError,
            DireccionPSError: direccionPSError,
            CodigoPostalPSError: CodigoPostalPSError,
            TelefonoPSError: TelefonoPSError,
            apellido1TitularError: apellido1TitularError,
            idComercializadoraError: true
        });
        this.forceUpdate();
        return;
      }
    }
  };






 render() {
      ;
      const dicMunicipios: any[] = this.state.dicMunicipios;

      const form: any = this.state.form;

      var nombre: string = form.nombreOferta;

      const radioStyle = {
          display: "flex",
          justifyContent: "space-between",
      };
      const tipoCliente: any[] = this.state.TipoCliente;
      return (
          <>
              <Modal
                  size="xl"
                  className="modal-dialog-centered"
                  isOpen={this.props.isOpen}
                  toggle={this.props.toggle}
                  destroyOnClose
              >
                  <div className="modal-header">
                      <h3 className="modal-title" id="saveModalOpened">
                          Guardar comparativa
          </h3>
                      <button
                          aria-label="Close"
                          className="close"
                          data-dismiss="modal"
                          type="button"
                          onClick={this.props.toggle}
                      >
                          <span aria-hidden={true}>×</span>
                      </button>
                  </div>
                  <div className="modal-body">
                      <Form innerRef={this.formComparativeRef}>
                          <Row>
                              <Col>
                                  <FormGroup>
                                      <Label className="form-control-label" htmlFor="propuesta">
                                          Nombre Comparativa
                  </Label>
                                      <Input
                                          id="nombreOferta"
                                          placeholder="Identificador de la comparativa"
                                          name="nombreOferta"
                                          onChange={this.onChangeField}
                                          value={form.nombreOferta}
                                          type="text"
                                          required
                                      > </Input>

                                      {this.state.nombreOfertaError ? <FormText color="red">Por favor introduzca un nombre</FormText> : ''}
                                  </FormGroup>

                                  <FormGroup style={radioStyle}>
                                      <Label className="form-control-label" htmlFor="esAprobada">
                                          Aprobada
                  </Label>
                                      <Label className="custom-toggle">
                                          <Input
                                              id="esAprobada"
                                              name="esAprobada"
                                              onChange={this.onChangeField}
                                              checked={form.esAprobada}
                                              type="checkbox"
                                          />
                                          <span className="custom-toggle-slider rounded-circle" />
                                      </Label>

                                  </FormGroup>
                                  <h6 className="">A los efectos de lo dispuesto en la normativa vigente relativa a tratamientos de datos de carácter personal, el Comercializador se compromete al cumplimiento de su deber de guardar secreto de los datos de carácter personal, y adoptará las medidas legalmente previstas y necesarias para evitar su alteración, pérdida, tratamiento o acceso no autorizado, habida cuenta en todo momento del estado de la tecnología aplicable. El Cliente queda informado y acepta todas las cuestiones relacionadas al tratamiento de sus datos de carácter personal por parte de la empresa y terceros, que voluntariamente ha facilitado al Comercializador.</h6>

                                  <FormGroup style={radioStyle}>
                                      <Label className="form-control-label" htmlFor="GDPR">
                                          Autoriza GDPR
                  </Label>
                                      <Label className="custom-toggle">
                                          <Input
                                              id="GDPR"
                                              name="GDPR"
                                              onChange={this.onChangeField}
                                              checked={form.GDPR}
                                              type="checkbox"
                                          />
                                          <span className="custom-toggle-slider rounded-circle" />
                                      </Label>
                                  </FormGroup>
                                  <Row className="bg-light">
                                      <h3 className="col item-header">
                                          TITULAR
                    </h3>
                                  </Row>
                                  <Row>
                                      <Col>
                                          <FormGroup>

                                              <Label className="form-control-label" htmlFor="nombreTitular">
                                                  Nombre
                  </Label>
                                              <Input
                                                  id="nombreTitular"
                                                  placeholder=""
                                                  name="nombreTitular"
                                                  onChange={this.onChangeField}
                                                  value={form.nombreTitular}
                                                  type="text"
                                                  required
                                              />
                                              {this.state.nombreTitularError ? <FormText color="red">Comprobar titular</FormText> : ''}
                                          </FormGroup>
                                      </Col>

                                      <Col>
                                          <FormGroup>

                                              <Label className="form-control-label" htmlFor="apellido1Titular">
                                                  Primer Apellido
                </Label>
                                              <Input
                                                  id="apellido1Titular"
                                                  placeholder=""
                                                  name="apellido1Titular"
                                                  onChange={this.onChangeField}
                                                  value={form.apellido1Titular}
                                                  type="text"
                                                  required
                                              />
                                              {this.state.apellido1TitularError ? <FormText color="red">Comprobar Apellido</FormText> : ''}
                                          </FormGroup>
                                      </Col>


                                      <Col>
                                          <FormGroup>

                                              <Label className="form-control-label" htmlFor="apellido2Titular">
                                                  Segundo Apellido
                  </Label>
                                              <Input
                                                  id="apellido2Titular"
                                                  placeholder=""
                                                  name="apellido2Titular"
                                                  onChange={this.onChangeField}
                                                  value={form.apellido2Titular}
                                                  type="text"
                                                  required
                                              />
                                          </FormGroup>
                                      </Col>
                                      <Col md="1">
                                          <FormGroup>

                                              <Label className="form-control-label" htmlFor="tipoTitular">
                                                  Tipo
                  </Label>
                                              <Input
                                                  id="tipoTitular"
                                                  placeholder=""
                                                  name="tipoTitular"
                                                  onChange={this.onChangeField}
                                                  value={form.tipoTitular}
                                                  type="text"
                                                  required
                                              />
                                          </FormGroup>
                                      </Col>


                                      <Col>
                                          <FormGroup>
                                              <Label className="form-control-label" htmlFor="NIF">
                                                  NIF
                  </Label>
                                              <Input
                                                  id="NIF"
                                                  placeholder="Introduzca NIF"
                                                  name="NIF"
                                                  onChange={this.onChangeField}
                                                  value={form.NIF}
                                                  type="text"
                                                  required
                                              />
                                              {this.state.NIFError ? <FormText color="red">Comprobar NIF/CIF</FormText> : ''}
                                          </FormGroup>
                                      </Col>
                                      <Col md="2" sm="2" lg="2" >

                                          <Button
                                              className="mt-4"
                                              position="center"
                                              margin
                                              onClick={this.getClient}
                                              color="primary"
                                              type="button"
                                              disabled={this.state.isDisabledCIF}
                                          >
                                              <i className={"bi bi-search"} /> CIF/NIF
                </Button>


                                      </Col>

                                  </Row>

                                  <Row className="bg-light">
                                      <h3 className="col item-header">
                                          REPRESENTANTE
                    </h3>
                                  </Row>
                                  <Row>
                                      <Col>
                                          <FormGroup>

                                              <Label className="form-control-label" htmlFor="nombreRepresentante">
                                                  Nombre
                  </Label>
                                              <Input
                                                  id="nombreRepresentante"
                                                  placeholder=""
                                                  name="nombreRepresentante"
                                                  onChange={this.onChangeField}
                                                  value={form.nombreRepresentante}
                                                  type="text"
                                                  required
                                              />
                                          </FormGroup>
                                      </Col>

                                      <Col>
                                          <FormGroup>

                                              <Label className="form-control-label" htmlFor="apellido1Representante">
                                                  Primer Apellido
                </Label>
                                              <Input
                                                  id="apellido1Representante"
                                                  placeholder=""
                                                  name="apellido1Representante"
                                                  onChange={this.onChangeField}
                                                  value={form.apellido1Representante}
                                                  type="text"
                                                  required
                                              />
                                          </FormGroup>
                                      </Col>


                                      <Col>
                                          <FormGroup>

                                              <Label className="form-control-label" htmlFor="apellido2Representante">
                                                  Segundo Apellido
                  </Label>
                                              <Input
                                                  id="apellido2Representante"
                                                  placeholder=""
                                                  name="apellido2Representante"
                                                  onChange={this.onChangeField}
                                                  value={form.apellido2Representante}
                                                  type="text"
                                                  required
                                              />
                                          </FormGroup>
                                      </Col>



                                      <Col>
                                          <FormGroup>
                                              <Label className="form-control-label" htmlFor="NIFRepresentante">
                                                  NIF
                  </Label>
                                              <Input
                                                  id="NIFRepresentante"
                                                  placeholder="Introduzca NIF"
                                                  name="NIFRepresentante"
                                                  onChange={this.onChangeField}
                                                  value={form.NIFRepresentante||''}
                                                  type="text"
                                                  required
                                              />
                                              {this.state.NIFRepreError ? <FormText color="red">Comprobar NIF/CIF</FormText> : ''}
                                          </FormGroup>
                                      </Col>
                                      <Col md="2" sm="2" lg="2" >

                                          <Button
                                              className="mt-4"
                                              position="center"
                                              margin
                                              onClick={this.getRepresentante}
                                              color="primary"
                                              type="button"
                                              disabled={this.state.isDisabledCIF}
                                          >
                                              <i className={"bi bi-search"} /> REPRES.
                </Button>

                                      </Col>


                                  </Row>

                                  <Row>
                                      <Col md="2">
                                          <FormGroup>
                                              <Label className="form-control-label" htmlFor="CargoRepresentante">
                                                  Cargo
</Label>
                                              <Input
                                                  id="CargoRepresentante"
                                                  placeholder=""
                                                  name="CargoRepresentante"
                                                  onChange={this.onChangeField}
                                                  value={form.CargoRepresentante}
                                                  type="text"
                                                  required
                                              />
                                          </FormGroup>
                                      </Col>

                                      <Col md="3">
                                          <FormGroup>
                                              <Label
                                                  className="form-control-label"
                                                  htmlFor="correoRepresentante"
                                              >
                                                  Correo de contacto
</Label>
                                              <Input
                                                  id="correoRepresentante"
                                                  placeholder=""
                                                  name="correoRepresentante"
                                                  onChange={this.onChangeField}
                                                  value={form.correoRepresentante}
                                                  type="text"
                                                  required
                                              >  </Input>
                                              {this.state.emailRepreError ? <FormText color="red">Por favor introduzca una dirección de correo electrónico correcto</FormText> : ''}
                                          </FormGroup>
                                      </Col>


                                  </Row>




                                  <Row className="bg-light mt-3">
                                      <h3 className="col item-header">
                                          DATOS GENERALES TITULAR
                    </h3>
                                  </Row>

                                  <Row>
                                      <Col md="2">
                                          <FormGroup>

                                              <Label className="form-control-label" htmlFor="tipoViaTitular">
                                                  Tipo Via
                </Label>
                                              <Input
                                                  id="tipoViaTitular"
                                                  placeholder=""
                                                  name="tipoViaTitular"
                                                  onChange={this.onChangeField}
                                                  value={form.tipoViaTitular}
                                                  type="text"
                                                  required
                                              />
                                          </FormGroup>
                                      </Col>

                                      <Col md="6">
                                          <FormGroup>

                                              <Label className="form-control-label" htmlFor="viaTitular">
                                                  Dirección Titular
              </Label>
                                              <Input
                                                  id="viaTitular"
                                                  placeholder=""
                                                  name="viaTitular"
                                                  onChange={this.onChangeField}
                                                  value={form.viaTitular}
                                                  type="text"
                                                  required
                                              />
                                          </FormGroup>
                                      </Col>


                                      <Col md="2">
                                          <FormGroup>

                                              <Label className="form-control-label" htmlFor="numFincaTitular">
                                                  Nº Finca
                </Label>
                                              <Input
                                                  id="numFincaTitular"
                                                  placeholder=""
                                                  name="numFincaTitular"
                                                  onChange={this.onChangeField}
                                                  value={form.numFincaTitular}
                                                  type="text"
                                                  required
                                              />
                                          </FormGroup>
                                      </Col>



                                      <Col md="2">
                                          <FormGroup>
                                              <Label className="form-control-label" htmlFor="portalTitular">
                                                  Portal Tit.
                </Label>
                                              <Input
                                                  id="portalTitular"
                                                  placeholder=""
                                                  name="portalTitular"
                                                  onChange={this.onChangeField}
                                                  value={form.portalTitular}
                                                  type="text"
                                                  required
                                              />
                                          </FormGroup>
                                      </Col>

                                      <Col md="1">
                                          <FormGroup>
                                              <Label className="form-control-label" htmlFor="escaleraTitular">
                                                  ESC.
                </Label>
                                              <Input
                                                  id="escaleraTitular"
                                                  placeholder=""
                                                  name="escaleraTitular"
                                                  onChange={this.onChangeField}
                                                  value={form.escaleraTitular}
                                                  type="text"
                                                  required
                                              />
                                          </FormGroup>
                                      </Col>
                                      <Col md="1">
                                          <FormGroup>
                                              <Label className="form-control-label" htmlFor="pisoTitular">
                                                  Piso
                </Label>
                                              <Input
                                                  id="pisoTitular"
                                                  placeholder=""
                                                  name="pisoTitular"
                                                  onChange={this.onChangeField}
                                                  value={form.pisoTitular}
                                                  type="text"
                                                  required
                                              />
                                          </FormGroup>
                                      </Col>
                                      <Col md="1">
                                          <FormGroup>
                                              <Label className="form-control-label" htmlFor="puertaTitular">
                                                  Puerta
                </Label>
                                              <Input
                                                  id="puertaTitular"
                                                  placeholder=""
                                                  name="puertaTitular"
                                                  onChange={this.onChangeField}
                                                  value={form.puertaTitular}
                                                  type="text"
                                                  required
                                              />
                                          </FormGroup>
                                      </Col>

                                      <Col md="2">
                                          <FormGroup>
                                              <Label className="form-control-label" htmlFor="CodigoPostalTitular">
                                                  C.P. Titular
              </Label>
                                              <Input
                                                  id="CodigoPostalTitular"
                                                  placeholder=""
                                                  name="CodigoPostalTitular"
                                                  onChange={this.onChangeField}
                                                  value={form.CodigoPostalTitular}
                                                  type="text"
                                                  required
                                              />
                                              {this.state.CodigoPostalTitularError ? <FormText color="red">Por favor compruebe el código postal del titular</FormText> : ''}
                                          </FormGroup>
                                      </Col>

                                      <Col md="3">
                                          <FormGroup>
                                              <Label className="form-control-label">
                                                  Municipio:
                  </Label>
                                              <SipsOption
                                                  placeholder="Seleccionar localidad"
                                                  //                     invalid={forceUseLocality}
                                                  id="desMunicipioTitular"
                                                  name="desMunicipioTitular"
                                                  query="LIST_LOCALITIESNAME"
                                                  onChange={this.onChangeField}
                                                  value={form.desMunicipioTitular}
                                              />
                                          </FormGroup>
                                      </Col>


                                      <Col md="2">
                                          <FormGroup>
                                              <Label className="form-control-label" htmlFor="desProvinciaTitular">
                                                  Provincia
                </Label>
                                              <Input
                                                  id="desProvinciaTitular"
                                                  placeholder=""
                                                  name="desProvinciaTitular"
                                                  onChange={this.onChangeField}
                                                  value={form.desProvinciaTitular}
                                                  type="text"
                                                  required
                                              />
                                          </FormGroup>
                                      </Col>

                                      <Col md="3">
                                          <FormGroup>
                                              <Label className="form-control-label" htmlFor="desAutonomiaTitular">
                                                  C. Autónoma
                </Label>
                                              <Input
                                                  id="desAutonomiaTitular"
                                                  placeholder=""
                                                  name="desAutonomiaTitular"
                                                  onChange={this.onChangeField}
                                                  value={form.desAutonomiaTitular}
                                                  type="text"
                                                  required
                                              />
                                          </FormGroup>
                                      </Col>
                                  </Row>

                                  <Row>
                                      <Col md="5">
                                          <FormGroup>
                                              <Label
                                                  className="form-control-label"
                                                  htmlFor="idComercializadora"
                                              >
                                                  Comercializadora de procedencia
                </Label>
                                              <SipsOption
                                                  placeholder=""
                                                  id="idComercializadora"
                                                  name="idComercializadora"
                                                  value={form.idComercializadora}
                                                  required
                                                  query="LIST_MARKETERS_GAS"
                                                  onChange={this.onChangeField}
                                              />
                                              {this.state.idComercializadoraError ? <FormText color="red">Por favor introduzca una comercializadora</FormText> : ''}
                                          </FormGroup>
                                      </Col>

                                      <Col md="3">
                                          <FormGroup>
                                              <Label
                                                  className="form-control-label"
                                                  htmlFor="Email"
                                              >
                                                  Correo de contacto
                </Label>
                                              <Input
                                                  id="Email"
                                                  placeholder=""
                                                  name="Email"
                                                  onChange={this.onChangeField}
                                                  value={form.Email}
                                                  type="text"
                                                  required
                                              >  </Input>
                                              {this.state.emailError ? <FormText color="red">Por favor introduzca una dirección de correo electrónico correcto</FormText> : ''}
                                          </FormGroup>
                                      </Col>

                                      <Col md="3">
                                          <FormGroup>
                                              <Label className="form-control-label" htmlFor="cliente">
                                                  Tipo Cliente
                </Label>
                                              <Input
                                                  id="TipoCliente"
                                                  placeholder=""
                                                  name="TipoCliente"
                                                  onChange={this.onChangeField}
                                                  hidden={tipoCliente.length > 0 ? false : true}
                                                  type="select" multiselect>
                                                  {tipoCliente.map((item: any, index) => <option value={item.cat} key={index}>{item.cat}</option>)}

                                              </Input>
                                          </FormGroup>
                                      </Col>
                                      <Col md="3">
                                          <Label className="form-control-label" htmlFor="TelefonoFijo">
                                              Teléfono Fijo
              </Label>
                                          <Input
                                              id="TelefonoFijo"
                                              placeholder=""
                                              name="TelefonoFijo"
                                              onChange={this.onChangeField}
                                              value={form.TelefonoFijo}
                                              type="text"
                                              required
                                          />
                                          {this.state.TelefonoFijoError ? <FormText color="red">Por favor compruebe el teléfono del titular</FormText> : ''}
                                      </Col>
                                      <Col md="3">
                                          <Label className="form-control-label" htmlFor="TelefonMovil">
                                              Teléfono Móvil
              </Label>
                                          <Input
                                              id="TelefonMovil"
                                              placeholder=""
                                              name="TelefonMovil"
                                              onChange={this.onChangeField}
                                              value={form.TelefonMovil}
                                              type="text"
                                              required
                                          />
                                      </Col>
                                      <Col>
                                          <Label className="form-control-label" htmlFor="ObservacionesCliente">
                                              Observaciones Cliente
              </Label>
                                          <Input
                                              id="ObservacionesCliente"
                                              placeholder=""
                                              name="ObservacionesCliente"
                                              onChange={this.onChangeField}
                                              value={form.ObservacionesCliente}
                                              type="text"
                                              required
                                          />

                                      </Col>

                                  </Row>

                                  <Row>
                                      <Col md="5">
                                          <Label className="form-control-label" htmlFor="iban">
                                              IBAN
                </Label>
                                          <Input
                                              id="iban"
                                              placeholder=""
                                              name="iban"
                                              onChange={this.onChangeField}
                                              value={form.iban}
                                              type="text"
                                              required
                                          />
                                          {this.state.ibanError ? <FormText color="red">Comprobar IBAN</FormText> : ''}

                                      </Col>
                                  </Row>



                                  <Row className="bg-light  mt-3">
                                      <h3 className="col item-header">
                                          PUNTO DE SUMINISTRO
                    </h3>
                                  </Row>

                                  <Row>
                                      <Col md="2">
                                          <FormGroup>

                                              <Label className="form-control-label" htmlFor="tipoViaPS">
                                                  Tipo Via
</Label>
                                              <Input
                                                  id="tipoViaPS"
                                                  placeholder=""
                                                  name="tipoViaPS"
                                                  onChange={this.onChangeField}
                                                  value={form.tipoViaPS}
                                                  type="text"
                                                  required
                                              />
                                          </FormGroup>
                                      </Col>

                                      <Col md="6">
                                          <FormGroup>

                                              <Label className="form-control-label" htmlFor="viaPS">
                                                  Dirección Suministro
</Label>
                                              <Input
                                                  id="viaPS"
                                                  placeholder=""
                                                  name="viaPS"
                                                  onChange={this.onChangeField}
                                                  value={form.viaPS}
                                                  type="text"
                                                  required
                                              />
                                          </FormGroup>
                                      </Col>


                                      <Col md="2">
                                          <FormGroup>

                                              <Label className="form-control-label" htmlFor="numFincaPS">
                                                  Nº Finca
</Label>
                                              <Input
                                                  id="numFincaPS"
                                                  placeholder=""
                                                  name="numFincaPS"
                                                  onChange={this.onChangeField}
                                                  value={form.numFincaPS}
                                                  type="text"
                                                  required
                                              />
                                          </FormGroup>
                                      </Col>



                                      <Col md="2">
                                          <FormGroup>
                                              <Label className="form-control-label" htmlFor="portalPS">
                                                  Portal
</Label>
                                              <Input
                                                  id="portalPS"
                                                  placeholder=""
                                                  name="portalPS"
                                                  onChange={this.onChangeField}
                                                  value={form.portalPS}
                                                  type="text"
                                                  required
                                              />
                                          </FormGroup>
                                      </Col>

                                      <Col md="1">
                                          <FormGroup>
                                              <Label className="form-control-label" htmlFor="escaleraPS">
                                                  ESC.
</Label>
                                              <Input
                                                  id="escaleraPS"
                                                  placeholder=""
                                                  name="escaleraPS"
                                                  onChange={this.onChangeField}
                                                  value={form.escaleraPS}
                                                  type="text"
                                                  required
                                              />
                                          </FormGroup>
                                      </Col>
                                      <Col md="1">
                                          <FormGroup>
                                              <Label className="form-control-label" htmlFor="pisoPS">
                                                  Piso
</Label>
                                              <Input
                                                  id="pisoPS"
                                                  placeholder=""
                                                  name="pisoPS"
                                                  onChange={this.onChangeField}
                                                  value={form.pisoPS}
                                                  type="text"
                                                  required
                                              />
                                          </FormGroup>
                                      </Col>
                                      <Col md="1">
                                          <FormGroup>
                                              <Label className="form-control-label" htmlFor="puertaPS">
                                                  Puerta
</Label>
                                              <Input
                                                  id="puertaPS"
                                                  placeholder=""
                                                  name="puertaPS"
                                                  onChange={this.onChangeField}
                                                  value={form.puertaPS}
                                                  type="text"
                                                  required
                                              />
                                          </FormGroup>
                                      </Col>

                                      <Col md="2">
                                          <FormGroup>
                                              <Label className="form-control-label" htmlFor="CodigoPostalPS">
                                                  C.P.
</Label>
                                              <Input
                                                  id="CodigoPostalPS"
                                                  placeholder=""
                                                  name="CodigoPostalPS"
                                                  onChange={this.onChangeField}
                                                  value={form.CodigoPostalPS}
                                                  type="text"
                                                  required
                                              />
                                          </FormGroup>
                                      </Col>

                                      <Col md="3">
                                          <FormGroup>
                                              <Label className="form-control-label">
                                                  Localidad cliente:
  </Label>
                                              <SipsOption
                                                  placeholder="Seleccionar localidad"
                                                  //                     invalid={forceUseLocality}
                                                  id="nombreMunicipioPS"
                                                  name="nombreMunicipioPS"
                                                  query="LIST_LOCALITIESNAME"
                                                  onChange={this.onChangeField}
                                                  value={form.nombreMunicipioPS}
                                              />
                                          </FormGroup>
                                      </Col>


                                      <Col md="2">
                                          <FormGroup>
                                              <Label className="form-control-label" htmlFor="nombreProvinciaPS">
                                                  Provincia
</Label>
                                              <Input
                                                  id="nombreProvinciaPS"
                                                  placeholder=""
                                                  name="nombreProvinciaPS"
                                                  onChange={this.onChangeField}
                                                  value={form.nombreProvinciaPS}
                                                  type="text"
                                                  required
                                              />
                                          </FormGroup>
                                      </Col>

                                      <Col md="3">
                                          <FormGroup>
                                              <Label className="form-control-label" htmlFor="desAutonomiaPS">
                                                  C. Autónoma
</Label>
                                              <Input
                                                  id="desAutonomiaPS"
                                                  placeholder=""
                                                  name="desAutonomiaPS"
                                                  onChange={this.onChangeField}
                                                  value={form.desAutonomiaPS}
                                                  type="text"
                                                  required
                                              />
                                          </FormGroup>
                                      </Col>
                                  </Row>

                                  <Label className="form-control-label" htmlFor="TelefonoPS">
                                      Teléfono Contacto Punto Suministro
</Label>
                                  <Input
                                      id="TelefonoPS"
                                      placeholder=""
                                      name="TelefonoPS"
                                      onChange={this.onChangeField}
                                      value={form.TelefonoPS}
                                      type="text"
                                      required
                                  />






                                  <FormGroup>
                                      <Label className="form-control-label" htmlFor="titular">
                                          Dirección Titular
                  </Label>
                                      <Input
                                          id="DireccionFiscal"
                                          name="DireccionFiscal"
                                          onChange={this.onChangeField}
                                          value={form.DireccionFiscal}
                                          type="text"
                                          required
                                      />
                                      {this.state.DireccionFiscalError ? <FormText color="red">Por favor introduzca una dirección</FormText> : ''}


                                      <Label className="form-control-label" htmlFor="DireccionPS">
                                          Dirección Punto Suministro
                  </Label>
                                      <Input
                                          id="DireccionPS"
                                          placeholder=""
                                          name="DireccionPS"
                                          onChange={this.onChangeField}
                                          value={form.DireccionPS}
                                          type="text"
                                          required
                                      />
                                      {this.state.DireccionPSError ? <FormText color="red">Por favor compruebe la dirección del punto de suministro </FormText> : ''}


                                  </FormGroup>
                              </Col>
                          </Row>
                      </Form>
                  </div>
                  <div className="modal-footer">
                      <Button
                          color="secondary"
                          data-dismiss="modal"
                          type="button"
                          onClick={this.props.toggle}
                      >
                          Cerrar
          </Button>
                      <Button
                          onClick={this.generateComparative}
                          color="primary"
                          type="button"
                          disabled={this.state.isDisabled}
                      >
                          Guardar Comparativa
          </Button>
                  </div>
              </Modal>
          </>
      );
  }



}
