import React, { Component } from "react";
import { Table } from "reactstrap";

interface PriceAtrTableInterface {
  id: string;
  fields: Object;
  items: Array<any>;
  hideViewMoreButton?: Boolean;
  showProblemInfo?: Boolean;
  parentState?: Object;
  dateFields?: Object;
  beforeRenderItem?: Function;
}
interface PriceAtrTableStateInterface {
}

export default class PriceAtrTable extends Component<
  PriceAtrTableInterface,
  PriceAtrTableStateInterface
> {
  state = {
  };

  renderHeader = () => {
    const keys = Object.values(this.props.fields);

    const keysTh = keys.map((item) => {
      return (
        <th scope="col" key={item}>
          {item}
        </th>
      );
    });

    return (
      <thead className="thead-light">
        <tr>
          {keysTh}
        </tr>
      </thead>
    );
  };

  renderItem = (item) => {
    const keys = Object.keys(this.props.fields);
    const tds = keys.map((index) => {
      let value = item[index];
      let renderField = true;
      let objItem = { value, item };
      if (this.props.beforeRenderItem) {
        renderField = this.props.beforeRenderItem(index, objItem);
      }

      return <td key={index}>{renderField ? objItem.value : ""}</td>;
    });

    const itemRendered = [
      <tr key={item.fechaPrecio}>
        {tds}
      </tr>,
    ];

    return itemRendered;
  };

  render() {
    const items = this.props.items
      ? this.props.items.map(this.renderItem)
      : null;
    return (
      <Table className="align-items-center table-flush" responsive>
        {this.renderHeader()}
        <tbody key={"tb" + this.props.id }>{items}</tbody>
      </Table>
    );
  }
}
