import React from "react";
// reactstrap components
import {
  Card,
  CardHeader,
  CardFooter,
  Container,
  Row,
  Col,
  Button,
} from "reactstrap";

import LoadingOverlay from "react-loading-overlay";

// core components
import Header from "../../components/Headers/Header";
import SipsFilter from "../../components/Sips/SipsFilterShort";
import SipsTable from "../../components/Sips/SipsTable";
import { withApollo } from "react-apollo";

import Pagination from "../../components/Pagination/ReportPagination";
import { LIST_SIPS, EXPORT_SIPS, EXPORT_SHORT_SIPS } from "../../queries/sips/sips.graphql";
import Environment from "../../constants/environment";
import Reflux from "reflux";
import { UserStore } from "../../store/UserStore";

const userFromStorage = localStorage.getItem("user");

const parsed = JSON.parse(userFromStorage||'{}');

const showIndexButtonCol = parsed.indexada;
const showFreeMarketButtonCol = parsed.mercadolibre;


const highPriorityItemsFields = {
  cups: "CUPS",
  nombreCompletoTitular: "Titular",
  nombreEmpresaDistribuidora: "Distribuidora Principal",
  empresaDistribuidora: "Distribuidora",
  comercializadora: "Comercializadora",
  direccion_ps: "Dirección",
  localidad_ps: "Localidad",
  codigoPostalPS: "Código Postal",
  nombreProvinciaPS: "Provincia",
  descripcionTarifa: "Descripción Tarifa",
  kWhAnual: "kWh Anual",
  fechaUltimoMovimientoContrato: "Fecha Último Movimiento",
  fechaUltimoCambioComercializador: "Fecha Último Cambio Comercializadora"
};


const lowPriorityItemsFieldsNO = {

};

const lowPriorityItemsFields = {
  CIF: "CIF",
  telefono: "Teléfono",
  contactoTitular: "Contacto del titular",
  codigoEmpresaDistribuidora: "	Cód. Distribuidora",
  codeComercializadora: "Cód. Comer",
  fechaAltaSuministro: "Fecha Alta",
  tarifaATR: "Tarifa",
  Tension: "Tensión",
  potenciaMaximaBIEW: "	Potencia Máxima BIE",
  potenciaMaximaAPMW: "Potencia Máxima Puesta en Marcha",
  codClasificacionPM: "Tipo PM",
  codigoDisponibilidadICP: "Indicativo ICP",
  perfilConsumo: "Perfil Consumo",
  valorDerechosExtensionW: "Derechos Extension",
  valorDerechosAccesoW: "Derechos Acceso Llano",
  derechosAccesoValle: "Derechos Acceso Valle",
  proEquipoMedida: "Propiedad Equipo Medida",
  propiedadICP: "Propiedad ICP",
  potenciasContratadasEnWP1: "Potencia Contratada Kw P1",
  potenciasContratadasEnWP2: "Potencia Contratada Kw P2",
  potenciasContratadasEnWP3: "Potencia Contratada Kw P3",
  potenciasContratadasEnWP4: "Potencia Contratada Kw P4",
  potenciasContratadasEnWP5: "Potencia Contratada Kw P5",
  potenciasContratadasEnWP6: "Potencia Contratada Kw P6",
  fechaLimiteExtension: "Fecha Límite Extensión",
  fechaUltimaLectura: "Fecha Última Lectura",
  cortes: "Cortes",
  fianza: "Fianza",
  perfilPersona: "Persona",
  esViviendaHabitual: "Primera Vivienda",
  informacionImpagos: "Impago",
  tipoTelegestion: "Telegestionado Activo",
  trimestreCambioContador: "Trimestre Cambio Contador",
  codigoAutoconsumo: "Cod. Autoconsumo",
  ConsumoAnualTotalActiva: "Total Consumo Año Actual",
  ConsumoAnualActivaP1: "Consumo anual Kw P1",
  ConsumoAnualActivaP2: "Consumo anual Kw P2",
  ConsumoAnualActivaP3: "Consumo anual Kw P3",
  ConsumoAnualActivaP4: "Consumo anual Kw P4",
  ConsumoAnualActivaP5: "Consumo anual Kw P5",
  ConsumoAnualActivaP6: "Consumo anual Kw P6",
  ConsumoAnualActivaP1Porcentaje: "Consumo anual P1(%)",
  ConsumoAnualActivaP2Porcentaje: "Consumo anual P2(%)",
  ConsumoAnualActivaP3Porcentaje: "Consumo anual P3(%)",
  ConsumoAnualActivaP4Porcentaje: "Consumo anual P4(%)",
  ConsumoAnualActivaP5Porcentaje: "Consumo anual P5(%)",
  ConsumoAnualActivaP6Porcentaje: "Consumo anual P6(%)",
  ConsumoAnualTotalActivaAnterior: "Total Consumo Año Anterior",
  ConsumoAnualActivaP1Anterior: "Consumo año anterior Kw P1",
  ConsumoAnualActivaP2Anterior: "Consumo año anterior Kw P2",
  ConsumoAnualActivaP3Anterior: "Consumo año anterior Kw P3",
  ConsumoAnualActivaP4Anterior: "Consumo año anterior Kw P4",
  ConsumoAnualActivaP5Anterior: "Consumo año anterior Kw P5",
  ConsumoAnualActivaP6Anterior: "Consumo año anterior Kw P6",
  CNAE: "CNAE",
  actividadCNAE: "Desc. CNAE",
  totalEnergiaPuntosGWHAnual: "Total energia Puntos GWh Anual",
  totalPuntos: "Total Puntos",
  precioOptimizacionPotencia: "Precio Optimización \n Potencia",
  precioOptimizacionReactiva: "Precio Optimización \n Reactiva",
  reactivaPotencia: "Reactiva	Total Optimización \n Potencia / Reactiva",
  aplicacionBonoSocial: "Bono Social",
};

class Sips extends Reflux.Component<any, any> {
  lastFilters = {};
  state = {
    UserStore:{},
    isLoading: false,
    isSearching: false,
    copiedText: null,
    currentPage: 0,
    pageSize: 0,
    pageCount: 0,
    count: 0,
    page: 1,
    limit: 10,
    empresa: false,
    paginatedData: {
      items: [],
    },
    showIndexButton: false,
    showFreeMarketButton: false
  };

  constructor(props) {
    super(props);
    this.store = UserStore;
  }

  componentWillReceiveProps (){

  const usuario:any = JSON.parse(JSON.stringify(this.state.UserStore['data']))

  console.log('VALOR DE USUARIO ----------------->', usuario)


  //const showIndexButtonCol = usuario.indexada;
  //const showFreeMarketButtonCol = usuario.mercadolibre;
  const companyNameGlobal = usuario.company;
  const companyIdUser = usuario.companyId;

  const userGroupId = usuario.user_group_id
  const userId = usuario.id
  const empresa = usuario.empresa



  //  console.log('VALORES DE indexbutton  ',showIndexButtonCol )
  //const showFreeMarketButtonCol = this.state.UserStore.data.mercadolibre;

  //const companyNameGlobal = this.state.UserStore.data.company;
  //const companyIdUser = this.state.UserStore.data.companyId;

  //const userGroupId = this.state.UserStore.data.user_group_id
  //const userId = this.state.UserStore.data.id

  //  console.log('VALORES DE ',usuario)

    if (userGroupId === 1000){
      this.setState({
        activeUser: true
      })};

    this.setState({
      companyNameGlobal,
      companyIdUser,
      userGroupId,
      userId,
      empresa

    });
  }


  componentDidMount() {
    let showIndexButton = false;
    let showFreeMarketButton = false;
    const userFromStorage = localStorage.getItem("user");
    if (userFromStorage) {
      try {
        const parsed = JSON.parse(userFromStorage);

        showIndexButton = parsed.indexada;
        showFreeMarketButton = parsed.mercadolibre;
      } catch (e) {}
    }
    this.setState({ showIndexButton, showFreeMarketButton });
  }

  onSubmit = (filters) => {
    this.lastFilters = filters;
    this.setState({ page: 1 }, () => {
      this.fetchItems(this.lastFilters);
    });
  };

  export = async () => {

    let isLoading = true;
    this.setState({ isLoading });
    const filters = this.lastFilters;
    let { page, limit } = this.state;
    console.log('VALORES DE filters Y THIS.STATE');
    console.log(filters)
    console.log('-------------------------')
    console.log(this.state);
    if (filters["limit"]) {
      limit = parseInt(filters["limit"]);
      //delete filters["limit"];
    }
    //limit = this.state.pageSize;

    const sips = await this.props.client.query({
      query: EXPORT_SIPS,
      variables: { page, limit, filters },
    });
    const file = sips.data.sipsExportCSV;
    let tempLink = document.createElement("a");
    tempLink.href = Environment.apiUrl.replace("/graphql", "") + file;

    tempLink.click();
    isLoading = false;
    this.setState({ isLoading });
  };


  exportShort = async () => {

    let isLoading = true;
    this.setState({ isLoading });
    const filters = this.lastFilters;
    let { page, limit } = this.state;
    console.log('VALORES DE filters Y THIS.STATE');
    console.log(filters)
    console.log('-------------------------')
    console.log(this.state);
    if (filters["limit"]) {
      limit = parseInt(filters["limit"]);
      //delete filters["limit"];
    }
    //limit = this.state.pageSize;

    const sips = await this.props.client.query({
      query: EXPORT_SHORT_SIPS,
      variables: { page, limit, filters },
    });
    const file = sips.data.sipsExportShortCSV;
    let tempLink = document.createElement("a");
    tempLink.href = Environment.apiUrl.replace("/graphql", "") + file;

    tempLink.click();
    isLoading = false;
    this.setState({ isLoading });
  };




  fetchItems = async (filters) => {
    let isLoading = true;
    const isSearching = true;
    this.setState({ isLoading, isSearching });
    this.lastFilters = filters;

    if (this.lastFilters.hasOwnProperty("limit")) {
      this.state.limit = parseInt(filters.limit, 10);
    }
    if (
      (!this.state.limit || this.state.limit === 0) &&
      (this.state.pageSize !== null || this.state.pageSize > 1)
    ) {
      this.state.limit = this.state.pageSize;
    }

    let { page, limit } = this.state;

    if (filters["limit"]) {
      limit = parseInt(filters["limit"]);
      delete filters["limit"];
    }

    if (limit === 0 && this.state.pageSize > 1) {
      limit = this.state.pageSize;
    }

    this.props.client.query({
      query: LIST_SIPS,
      variables: { page, limit, filters },
    }).then( res => {

      const {
        currentPage,
        pageSize,
        pageCount,
        count,
        items,
      } = res.data.listSips;

      const paginatedData = { items };
      isLoading = false;

      this.setState({
        paginatedData,
        currentPage,
        pageSize,
        pageCount,
        count,
        isLoading,
      });
    })
    .catch( err => {
      isLoading = false;
      this.setState({isLoading});
    })

  };

  onPageClick = (page) => {
    this.setState({ page }, () => {
      this.fetchItems(this.lastFilters);
    });
  };

  beforeRenderItem = (index, item) => {
    if (index.includes("fecha") && item.value) {
      const dateValue = new Date(item.value);
      item.value = dateValue.toISOString().split("T")[0];
    }
    return true;
  };

  render() {
    let noResults = <></>;
    if (this.state.paginatedData.items.length === 0 && !this.state.isLoading) {
      if (this.state.isSearching) {
        noResults = (
          <p className="alert alert-danger">No se encontraron resultados</p>
        );
      } else {
        noResults = (
          <p className="alert alert-info">
            Realice su busqueda utilizando el formulario de arriba
          </p>
        );
      }
    }
if(this.state.empresa) {
    return (
      <>
        <Header title="Listado" />
        <Container className=" mt--7" fluid>
          <SipsFilter onSubmit={this.onSubmit}></SipsFilter>
          <Row>
            <div className="col">
              <Card className="shadow">


                  <CardHeader className="border-0">
                    <Row>
                      <Col md="8">
                        <h3 className="mb-0">SIPS</h3>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="4" className="text-right">
                        <Button
                          onClick={this.exportShort}
                          size="sm"
                          >
                          Exportación Corta a CSV
                        </Button>
                      </Col>
                      <Col md="4" className="text-right">
                        <Button
                          onClick={this.export}
                          size="sm"
                          >
                          Exportación a CSV
                        </Button>
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardFooter className="py-4">
                    {noResults}

                    <nav aria-label="...">
                      <Pagination
                        {...this.state}
                        onPageClick={this.onPageClick}
                      ></Pagination>
                    </nav>
                  </CardFooter>

              </Card>
            </div>
          </Row>
        </Container>
      </>
    );
  } else {
    return (
      <>
        <Header title={"No tienes permisos para acceder a este apartado. Consulte con nuestro equipo comercial"}/>
      </>
    );
  }

  }
}

export default withApollo(Sips);
