import React from "react";
import {
    Button,
    FormGroup,
	Modal,
	Label,
	Col,
	Input,
    Row,
Card,
    Form,
} from "reactstrap";
import client from "../../api/client";
import { DATA_REPRE_CONTRATO_GENERAL, UPDATE_REPRE_CONTRATO } from "../../queries/sips-comparativa/contratacion";
import CircularProgress from '@material-ui/core/CircularProgress';
import DataTable from 'react-data-table-component';
import Checkbox from '@material-ui/core/Checkbox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';

interface IModalRepresentanteProps {
    cifEmpresa: string;
    companyId: string;
    isOpen: boolean;
    toggle: any;
    representante: Function;
//    generateComparative?: Function;
}

interface IModalRepresentanteState {
    cups: string,
    //representanteSel:any[],
    user: any,
    sip: any,
    cifEmpresa: string,
    representantes:any[],
    representanteSeleccionado: any[]
}
const sortIcon = <ArrowDownward />;

const columnsDatosRepresentante = [

  {  selector:  row => row.nombreRepresentante, name: 'NOMBRE', sortable: true, width: '200px', format: (row => capitalize(row.nombreRepresentante)) },
 {  selector:  row => row.apellido1Representante, name: 'APELLIDO 1', sortable: true, width: '240px', center: true, format: (row => capitalize(row.apellido1Representante)) },
  {  selector:  row => row.apellido2Representante, name: 'APELLIDO 2', sortable: true, width: '210px', center: true, format: (row => capitalize(row.apellido2Representante)) },
  {  selector:  row => row.CIFRepre, name: 'CIF', sortable: true, width: '100px', center: true, format: (row => capitalize(row.CIFRepre)) },
  {  selector:  row => row.CargoRepre, name: 'CARGO', sortable: true, width: '250px', center: true, format: (row => capitalize(row.CargoRepre)) },
  {  selector:  row => row.telefonoRepresentante, name: 'TELEFONO', width: '180px', sortable: true, center: true, format: (row => capitalize(row.telefonoRepresentante))}
  ];

  const capitalize = (s) => {
    if (typeof s !== 'string') return ''
    return s.toLowerCase().charAt(0).toUpperCase() + s.slice(1)
  }

          const customStyles = {
              headRow: {
                  style: {
                      border: 'none',

                  },
              },
              headCells: {
                  style: {
                      color: '#202124',
                      fontSize: '12px',
                      fontWeight: 'bold',
                      wordBreak: 'break-word',
                      paddingLeft: '8px',
                      paddingRight: '8px',
                      minWidth: '55px'

                  },
              },
              cells: {
                  style: {
                      paddingLeft: '8px',
                      paddingRight: '8px',
                      minWidth: '55px',
                      wordBreak: 'break-word',
                      fontFamily: '"Open Sans", sans-serif;'
                  },
              },
              rows: {
                  highlightOnHoverStyle: {
                      backgroundColor: 'rgb(230, 250, 250)',
                      borderBottomColor: '#FFFFFF',
                      borderRadius: '25px',
                      outline: '1px solid #FFFFFF',
                      fontSize: '12px',
                  },
              },
              pagination: {
                  style: {
                      border: 'none',
                  },
              },
          };


const Circular = () => (
  	// we need to add some padding to circular progress to keep it from activating our scrollbar
  	<div style={{ padding: '24px' }}>
  		<CircularProgress size={75} />
  	</div>
  );


export default class ContratoRepresentanteFreeModal extends React.Component<IModalRepresentanteProps,IModalRepresentanteState> {
	public formComparativeRef: any = null;
	public formSaveComparativeRef: any = null;
	public state: IModalRepresentanteState = {
        // --- Data
		cups: "",
		user: {},
        sip: {},
        cifEmpresa: "",
        representantes:[],
        representanteSeleccionado: []
	};

    componentWillReceiveProps(newProps) {
        this.setState({cifEmpresa: newProps.cifEmpresa});
console.log('VALOR DE CIFEMPRESA')
console.log('ESTOY EN COMPONENTEWILLRECEIVEPROPS')
    }

	componentDidMount() {
console.log('ESTOY EN COMPONENTdidMount')
        this.formComparativeRef = React.createRef();
        this.fetchItems();
    }

	fetchItems = async () => {

      const companyUser = await JSON.parse(await localStorage.getItem("user")!);
      const companyIdUser = companyUser.companyId.toString();
      const empresa = this.props.cifEmpresa

     await client.query({
            query: DATA_REPRE_CONTRATO_GENERAL,
			variables: { companyIdRepresentante: companyIdUser, CIFEmpresa: empresa},
        })
        .then((result) => {
            let data = result.data.representanteDataGeneral;

            this.setState({ representantes: data })
        });
	}


    handleChange = state => {
        // eslint-disable-next-line no-console
        console.log('state', state.selectedRows[0]);
        console.log(state.selectedCount)

         var contrato = '';


    console.log('VALOR DE this.state', this.state)    //
    if(state.selectedCount === 1){
    var existeRepre = ''


        this.setState({ representanteSeleccionado: state.selectedRows[0]});
        //    console.log('XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX')
        //    console.log('VALORE DE FECHA CONTRATO EN RESULT.DATA ------> ', state.selectedRows[0].fechaContrato)
        //    this.setState({fechaContrato: state.selectedRows[0].fechaContrato});
    }

    console.log('VALOR DE this.state despues', this.state)
}










	representante = async ( event ) => {
            if( this.state.representanteSeleccionado)
            {
                let seleccion = this.state.representanteSeleccionado;
console.log(this.props)
              this.props.representante({
                   representante: seleccion
                   });
                this.props.toggle();

                return;
            }
            alert('Representante No seleccionado');
        }



	render() {


console.log(this.props)

		return (
			<>
				<Modal
                    size="lg"
                    className="modal-dialog-centered modal-sip-comparative-free"
                    isOpen={this.props.isOpen}
                    toggle={this.props.toggle}>
                    <div className="modal-body">
<Form innerRef={this.formComparativeRef}>
                        <Row>
                            <Card className="shadow">
                              <DataTable
                                title="REPRESENTANTES"
                                columns={columnsDatosRepresentante}
                                data={this.state.representantes}
                                selectableRows
                                selectableRowsComponent={Checkbox} // Pass the function only
                                selectableRowsComponentProps={{ inkdisabled: "true" }} // optionally, pass Material Ui supported props down to our custom checkbox
                                selectableRowsSingle
                                sortIcon={sortIcon} // use a material icon for our sort icon. rdt will rotate the icon 180 degrees for you           fixedHeader = {true}
                                noDataComponent={"Sin datos para mostrar"}
                                pagination
                                paginationPerPage={10}
                                paginationRowsPerPageOptions={[10, 15, 20, 25, 30, 40]}
                                onSelectedRowsChange={this.handleChange}
                                highlightOnHover
                                striped
                                pointerOnHover
                                selectableRowsHighlight
                            customStyles={customStyles}
                                contextMessage={{ singular: 'Representante', plural: 'Representantes', message: 'seleccionado' }}
                                subHeader
                                progressComponent={<Circular />}
                              />
                            </Card>
                        </Row>

                        </Form>

                    </div>

                    <div className="modal-footer">
                        <Button
                            onClick={this.props.toggle}
                            color="secondary"
                            data-dismiss="modal"
                            type="button">
                            Cerrar
                        </Button>
                        <Button onClick={this.representante} color="primary" type="button">
                            Guardar Representante
                        </Button>
                    </div>
                </Modal>
			</>
		);
	}
}
