import Environment from '../../constants/environment';
import React, { forwardRef } from "react";
import Reflux from "reflux";
import axios from 'axios';
import memoize from 'memoize-one';
import client from "../../api/client";
import { validateSpanishId, spainIdType} from 'spain-id';
import {
    Card, Container, Row, CardTitle, CardBody, Label,
    FormGroup,
    Input, FormText,
    Form, Col, Button,
    UncontrolledTooltip, CardHeader, Collapse
} from "reactstrap";
import {
    Link,
    useRouteMatch
} from 'react-router-dom';
import { CSVLink, CSVDownload } from "react-csv";

// core components
import Header from "../../components/Headers/Header";

import { withApollo } from "react-apollo";
import moment from "moment";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import GetUserData from "../../components/User/GetUserData";
import { UserStore } from "../../store/UserStore";
import DataTable from 'react-data-table-component';
import Checkbox from '@material-ui/core/Checkbox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';

import CircularProgress from '@material-ui/core/CircularProgress';
import { saveAs } from 'file-saver';
import SipsOption from "../../components/Sips/SipsOption";
import SipsGasOption from "../../components/SipsGas/SipsGasOption";
import { LIST_DICMUNICIPIOS } from "../../queries/options/dic-option.graphql";

const ibantools = require('ibantools');

var DatePicker = require("reactstrap-date-picker");

const FileDownload = require('js-file-download');
const formato = require('xml-formatter');
const spanishDayLabels = ['Dom', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sab'];
const spanishMonthLabels = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];

const notify = () => toast.success(
    "Proceso de facturación lanzado correctamente",
    {
        position: "bottom-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });

    const notifyErrorDB = () => toast.error(
        "Se ha producido un error en la BBDD. Si persite ponte en contacto con soporte",
        {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });

const sortIcon = <ArrowDownward />;

const columnsDatosClientes = [

    { selector: row => row.uuid, name: 'ID REMESA', sortable: true, width: '140px', format: (row => capitalize(row.uuid)) },
    { selector: row => row.numfactura, name: 'ID FACTURA DISTRIBUIDORA', sortable: true, width: '220px', format: (row => capitalize(row.numfactura)) },
    { selector: row => row.numerofacturas, name: 'Nº FACTURAS', sortable: true, width: '220px', center: true},
    { selector: row => row.sumaTotal, name: 'IMPORTE REMESA', sortable: true, width: '180px', center: true },
];

const capitalize = (s) => {
    if (typeof s !== 'string') return ''
    return s.toLowerCase().charAt(0).toUpperCase() + s.slice(1)
}

const estado = (s) => {
    if (s !== true) return <i className="fas fa-exclamation-circle fa-2x" style={{color: "#ff1900"}} ></i>
    return <i className="far fa-check-circle fa-2x" style={{color: "#7ee06e"}} ></i>
}


const customStyles = {
    headRow: {
        style: {
            border: 'none',

        },
    },
    headCells: {
        style: {
            color: '#202124',
            fontSize: '12px',
            fontWeight: 'bold',
            wordBreak: 'break-word',
            paddingLeft: '8px',
            paddingRight: '8px',
            minWidth: '55px'

        },
    },
    cells: {
        style: {
            paddingLeft: '8px',
            paddingRight: '8px',
            minWidth: '55px',
            wordBreak: 'break-word',
            fontFamily: '"Open Sans", sans-serif;'
        },
    },
    rows: {
        highlightOnHoverStyle: {
            backgroundColor: 'rgb(230, 250, 250)',
            borderBottomColor: '#FFFFFF',
            borderRadius: '25px',
            outline: '1px solid #FFFFFF',
            fontSize: '12px',
        },
    },
    pagination: {
        style: {
            border: 'none',
        },
    },
};


const Circular = () => (
    // we need to add some padding to circular progress to keep it from activating our scrollbar
    <div style={{ padding: '24px' }}>
        <CircularProgress size={75} />
    </div>
);





class facturacionERP extends Reflux.Component<any, any> {
    public maxFileSize = 3;
    public fileInput: any = null;
    public lecturasRef: any = [];
    public inputEl: any = null;
    public formRef: any = null;
    public validFileExtensions = ["jpg", "jpeg", "png"];
    lastFilters = {};
    state = {
        filters: {
            razonSocial:"",
            remesaSearch: "",
            cifClienteSearch: "",
            cifEmisorSearch:"",
            facturaDesde: "",
            facturaHasta:""
        },
        UserStore: {},
        nombreFichero: "",
        isLoading: false,
        copiedText: null,
        currentPage: 0,
        pageSize: 0,
        pageCount: 0,
        count: 0,
        contrato: "",
        tipocliente: "",
        facturaDetailTotal: [],
        facturaSeleccionada: false,
        idCliente: '',
        facturaDetail: {
            uuid: "",
            numerofacturas: null,
            numfactura: "",
            sum: null

        },
        formFactura: {
            cups:"",
            codigoEmpresaEmisora: "",
            nombreEmpresaEmisora:"",
            fechacomunic:null,
            numdocumento:"",
            nombre:"",
            apellido1:"",
            tipofactura:"",
            clasefact:"",
            fecfactura: null,
            numfactura: "",
            tipofacturacion: "",
            tipopeaje: "",
            importetotal: null,
            tipopenalizacion :"",
            metodofacturacion:"",
            psrltvimporte: null,
            psrltvcodconcepto: "",
            psrltvdesconcepto:"",
            psrltfimporte: null,
            psrltfcodconcepto: "",
            psrltfdesconcepto: "",
            psrttvimporte: null,
            psrttvcodconcepto: "",
            psrttvdesconcepto: "",
            psrttfimporte: null,
            psrttfcodconcepto: "",
            psrttfdesconcepto: "",
            ocrtfimporte: null,
            ocrtfcodconcepto: "",
            ocrtfdesconcepto: "",
            ctfimporte: null,
            ctfcodconcepto: "",
            ctfdesconcepto: "",
            cgtsimporte: null,
            cgtscodconcepto: "",
            cgtsdesconcepto: "",
            tcnmcimporte: null,
            tcnmccodconcepto: "",
            tcnmcdesconcepto: "",
            ifaimporte: null,
            ifacodconcepto: "",
            ifadesconcepto: "",
            ibiimporte: null,
            ibicodconcepto: "",
            ibidesconcepto: "",
            iiimporte: null,
            iicodconcepto: "",
            iidesconcepto: "",
            itfimporte: null,
            itfcodconcepto: "",
            itfdesconcepto: "",
            tfpimporte: null,
            tfpcodconcepto: "",
            tfpdesconcepto: "",
            tvpimporte: null,
            tvpcodconcepto: "",
            tvpdesconcepto: "",
            ipimporte: null,
            ipicodconcepto: "",
            ipidesconcepto: "",
            procesoFactura:false,
            uuidFactura:"",
            fechAlta:null,
            factork:null,
            consumo:null,
            fecinicioperiodo:null,
            fecfinperiodo:null,
            consumoperiodo:null
        },

        companyIdUser: '',
        companyNameGlobal: '',
        userGroupId: 1000,
        userId: '',
        esAgencia: false,
        esComercializadora: false,
        esAdFincas: false,
        saveDataCliente: false,
        updateDataCliente: false,
        saveClienteButtonDisabled: true,
        updateClienteButtonDisabled: true,
        facturazipButtonDisabled: true,
        buscarButtonDisabled: true,
        CIFClienteError: false,
        show: false,
        imgCollection: [],
        facturaButtonDisabled: true,
        showFilters: true,
        toggledClearRows: false,
        cupsError: false,
        cifError: false,
        cifErrorEmisor: false,
        locale: 'es',
        inputIdDisabled: true,
        inputDisabled: true,
        facturaDesde: '',
        facturaHasta: '',
        clienteHasta: '',
        createdAt: '',
        updatedAt: '',
        periodoValidezHasta: '',
        periodoValidezDesde: '',
        CodigoPostalTitularError: false,
        apellido1TitularError:  false,
        emailError: false,
        nombreTitularError: false,
        NIFError:false,
        DireccionFiscalError:false,
        TelefonoFijoError: false,
        TelefonMovilError: false,
        dicMunicipios: [],
        cnaeAux:"",
        ibanError: false

    };

    sipsCSVData: any = [];
    viewSipsConsumoAnualCSVData: any = [];
    sipsConsumosCSVData: any = [];
    sipstotal: any = [];





    constructor(props) {
        super(props);
        this.store = UserStore;
        this.formRef = React.createRef();

    }


    componentWillReceiveProps() {

        const usuario: any = JSON.parse(JSON.stringify(this.state.UserStore['data']))

        console.log('VALORES DE USUARIO EN componentWillReceiveProps')
        console.log(usuario)

        const companyNameGlobal = usuario.company;
        const companyIdUser = usuario.companyId;

        const userGroupId = usuario.user_group_id
        const userId = usuario.id
        const esComercializadora = usuario.comercializadora
        const esAgencia = usuario.agencia
        const esAdFincas = usuario.adFincas



        if (userGroupId === 1000) {
            this.setState({
                activeUser: true
            })
        };

        this.setState({
            companyNameGlobal,
            companyIdUser,
            userGroupId,
            userId,
            esComercializadora,
            esAgencia,
            esAdFincas

        });
    }



    componentDidMount() {
      this.fetchItems();
    }


    fetchItems = async () => {

      await client.query({
        query: LIST_DICMUNICIPIOS,
        variables: {}
      })
        .then((result) => {
          let data = result.data.listDicMunicipios;
          this.setState({ dicMunicipios: data })
        });
    }


    onChangeFieldSearch = (event) => {
        event.persist()
        var filtersData = { ...this.state.filters };
        const name = event.target.name;
        let value = event.target.value;
        const filters = this.state.filters;
        console.log(event)
        console.log(name)
        console.log(value)



        var filtersData = { ...this.state.filters };
        filters[name] = value;
        /*if (event.target.type === "select-one" && value === "0") {
           delete filters[name];
         }

         if (value === "" || value === null) {
           delete filters[name];
         }
*/


if (event.target.name === 'razonSocial' && event.target.value.length > 0) {
console.log('entra en razon social')

    this.setState({
        buscarButtonDisabled: false

    })

}

if (event.target.name === 'remesaSearch' && event.target.value.length > 0) {

            this.setState({
                buscarButtonDisabled: false

            })

        }


        if (event.target.name === 'cifClienteSearch' && event.target.value.length == 0)   {

                this.setState({

                    cifError: false,
                    buscarButtonDisabled:true,
                })
            } else if(event.target.name === 'cifClienteSearch' && event.target.value.length > 0){

            const result = validateSpanishId(event.target.value)

                    if (result === true) {
                        this.setState({
                            cifError: false,
                            buscarButtonDisabled:false,
                        })

                    } else {
                        this.setState({
                            cifError: true,
                            buscarButtonDisabled:true,
                         })
                    }
                }


                if (event.target.name === 'cifEmisorSearch' && event.target.value.length == 0)   {

                        this.setState({

                            cifErrorEmision: false,
                            buscarButtonDisabled:true,
                        })
                    } else if(event.target.name === 'cifEmisorSearch' && event.target.value.length > 0){
console.log('entra en if....')

                    const result = validateSpanishId(event.target.value)
console.log('valor de result en if ', result)
                            if (result === true) {
 console.log('1')
                                this.setState({
                                    cifErrorEmisor: false,
                                    buscarButtonDisabled:false,
                                })

                            } else {
 console.log('2')
                                this.setState({
                                    cifErrorEmisor: true,
                                    buscarButtonDisabled:true,
                                 })
                            }
                        }


                filtersData[name] = value;
                //if (value === "" || value === null) {
                //    formData[name] = 0;
                //}
                this.setState({ filters: filtersData });
    }



    onChangeFieldFactura = (event) => {
        /*event.persist()

        var formData = { ...this.state.formFactura };
        var target = event.target;
        var value =
            event.target.type === "checkbox"
                ? event.target.checked
                : event.target.value;
        const name = event.target.name;
        console.log('Valor de event name: ', event.target.name)
        console.log('Valoe de event value: ', event.target.value)

        const nameFilters = ['idCliente', 'idTipoTitular', 'descIdTipoTitular', 'nombreTitular', 'apellido1Titular', 'apellido2Titular', 'codigoProvinciaTitular', 'desProvinciaTitular', 'codigoPostalTitular', 'municipioTitular', 'desMunicipioTitular', 'tipoViaTitular', 'viaTitular', 'numFincaTitular', 'portalTitular', 'escaleraTitular', 'pisoTitular', 'puertaTitular', 'esViviendaHabitual', 'CNAE', 'actividadCNAE', 'CIF', 'codigoAutonomiaPS', 'desAutonomiaPS', 'idBanco', 'descBanco', 'iban', 'createdAt', 'updatedAt', 'createdBy', 'updatedBy', 'idCompany', 'correoElectronico', 'telefonoFijo', 'telefonoMovil'];



        if (this.state.facturaSeleccionada) {

            if (nameFilters.indexOf(event.target.name) != -1) {
                this.setState({ updateDataCliente: true });
                this.setState({
                    saveClienteButtonDisabled: true,
                    updateClienteButtonDisabled: false
                })
            }
            if(event.target.name =='nombreTitular' && event.target.value.length > 0 ){

                this.setState ({updateDataCliente : true});
                this.setState({saveClienteButtonDisabled:true,
                              updateClienteButtonDisabled:false})
            }
            if(event.target.name =='apellido1Titular' && event.target.value.length > 0 ){

                this.setState ({updateDataCliente : true});
                this.setState({saveClienteButtonDisabled:true,
                              updateClienteButtonDisabled:false})
            }
            if(event.target.name =='nombreTitular' && event.target.value.length > 0 ){
                //console.log('VALOR NOMBRE REPRESENTANTE ------> ', value, event.target.name)
                //this.setState ({representanteDetail.nombreRepresentante : event.target.value});
                this.setState ({updateDataCliente : true});
                this.setState({saveClienteButtonDisabled:true,
                              updateClienteButtonDisabled:false})
            }

            if (event.target.name === 'CIF' && event.target.value.length == 0)   {
                this.setState({CIFClienteError: true})
                } else if(event.target.name === 'CIF' && event.target.value.length > 0){
                    const result = validateSpanishId(event.target.value)
                        if (result === true) {
                            this.setState ({updateDataCliente : true,
                                            CIFClienteError: false});
                            this.setState({saveClienteButtonDisabled:true,
                                          updateClienteButtonDisabled:false})
                            const result = spainIdType(event.target.value)
                            formData['descIdTipoTitular'] = result.toUpperCase();
                            this.setState({ formFactura: formData });
                        } else {
                            this.setState({
                                CIFClienteError: true,
                                updateDataCliente : false,
                                bupdateClienteButtonDisabled:true
                             })
                        }
                }


                    if (event.target.name === 'correoElectronico') {


                      const pattern = /[a-zA-Z0-9]+[\.]?([a-zA-Z0-9]+)?[\@][a-z]{3,9}[\.][a-z]{2,5}/g;
                      //const pattern = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/
                      const result = pattern.test(event.target.value);

                      if (result === true) {
                        this.setState({
                          emailError: false,
                        })
                      } else {
                        this.setState({
                          emailError: true
                        })
                      }
                    }

                    if (event.target.name === 'iban') {

                      const resultIban = ibantools.isValidIBAN(event.target.value);

                      if (resultIban === true) {
                        this.setState({
                          ibanError: false,
                        })
                      } else {
                        this.setState({
                          ibanError: true
                        })
                      }
                    }

                    if (name == 'desMunicipioTitular') {

                      const municipio: any = this.state.dicMunicipios
                      const resultado = municipio.find(municipio => municipio?.Nombre === value);

                      //console.log('VALOR DE FIND -------------------->', resultado.DescProvincia, resultado.DescAutonomia);

                      //const filtered = municipio.filter(entry => Object.values(entry).some(val => typeof val === "string" && val.includes('Barromán')));

                      //console.log(filtered);
                      formData['desProvinciaTitular'] = resultado.DescProvincia
                      formData['desAutonomiaPS'] = resultado.DescAutonomia

                    }


                    if (name == 'nombreMunicipioPS') {
                      const municipio:any  = this.state.dicMunicipios
                      const resultado = municipio.find(municipio => municipio?.Nombre === value);
                      const filtered = municipio.filter(entry => Object.values(entry).some(val => typeof val === "string" && val.includes('Barromán')));
                      formData['nombreProvinciaPS'] = resultado.DescProvincia
                      formData['desAutonomiaPS'] = resultado.DescAutonomia
                    }



            if (event.target.name == 'CNAE') {
                //this.setState ({facturaDetail.nombreOferta : event.target.value});
                this.setState({ updateDataCliente: true });
                this.setState({
                    saveClienteButtonDisabled: true,
                    updateClienteButtonDisabled: false
                })
                const descripcionCNAE = event.target.value
                console.log('valores de target.selectedOptions ----- ',event.target.selectedOptions[0] )
                console.log('valores de target.selectedOptions ----- ',event.target.selectedOptions[0].innerText )
                value = event.target.selectedOptions[0].innerText
                console.log(formData)
                formData['actividadCNAE'] = descripcionCNAE;
                //if (value === "" || value === null) {
                //    formData[name] = 0;
                //}
                this.setState({ formFactura: formData,
                                cnaeAux: descripcionCNAE });
            }

            if (event.target.name === 'esEmitida' && event.target.value == 'Selecciona...') {
                value = null

            }

        }

        formData[name] = value;
        //if (value === "" || value === null) {
        //    formData[name] = 0;
        //}
        this.setState({ formFactura: formData });
*/

    };


    showFiltersToggle = () => {
        const showFilters = !this.state.showFilters;

        this.setState({ showFilters });
    };

    getFilterIcon = () => {
        return this.state.showFilters ? "ni-bold-up" : "ni-bold-down";
    };



    clearForm = () => {
            const filters = {};
        this.setState({
            filters: {
                razonSocial:"",
                remesaSearch: "",
                cifClienteSearch: "",
                cifEmisorSearch:"",
                facturaDesde: "",
                facturaHasta:""
            },
            inputIdDisabled: false,
            inputDisabled: false,
            buscarButtonDisabled: true,
            facturaSeleccionada: false,
            facturaDetail:[],
            facturaDetailTotal: [],
            formFactura: {
                cups:"",
                codigoEmpresaEmisora: "",
                nombreEmpresaEmisora:"",
                fechacomunic:null,
                numdocumento:"",
                nombre:"",
                apellido1:"",
                tipofactura:"",
                clasefact:"",
                fecfactura: null,
                numfactura: "",
                tipofacturacion: "",
                tipopeaje: "",
                importetotal: null,
                tipopenalizacion :"",
                metodofacturacion:"",
                psrltvimporte: null,
                psrltvcodconcepto: "",
                psrltvdesconcepto:"",
                psrltfimporte: null,
                psrltfcodconcepto: "",
                psrltfdesconcepto: "",
                psrttvimporte: null,
                psrttvcodconcepto: "",
                psrttvdesconcepto: "",
                psrttfimporte: null,
                psrttfcodconcepto: "",
                psrttfdesconcepto: "",
                ocrtfimporte: null,
                ocrtfcodconcepto: "",
                ocrtfdesconcepto: "",
                ctfimporte: null,
                ctfcodconcepto: "",
                ctfdesconcepto: "",
                cgtsimporte: null,
                cgtscodconcepto: "",
                cgtsdesconcepto: "",
                tcnmcimporte: null,
                tcnmccodconcepto: "",
                tcnmcdesconcepto: "",
                ifaimporte: null,
                ifacodconcepto: "",
                ifadesconcepto: "",
                ibiimporte: null,
                ibicodconcepto: "",
                ibidesconcepto: "",
                iiimporte: null,
                iicodconcepto: "",
                iidesconcepto: "",
                itfimporte: null,
                itfcodconcepto: "",
                itfdesconcepto: "",
                tfpimporte: null,
                tfpcodconcepto: "",
                tfpdesconcepto: "",
                tvpimporte: null,
                tvpcodconcepto: "",
                tvpdesconcepto: "",
                ipimporte: null,
                ipicodconcepto: "",
                ipidesconcepto: "",
                procesoFactura:false,
                uuidFactura:"",
                fechAlta:null,
                factork:null,
                consumo:null,
                fecinicioperiodo:null,
                fecfinperiodo:null,
                consumoperiodo:null
            },
            toggledClearRows: !this.state.toggledClearRows,
            saveClienteButtonDisabled: true,
            facturaButtonDisabled: true,
            updateClienteButtonDisabled: true,
            createdAt: '',
            updatedAt: '',
            inputDisabledP3456: false,
            inputDisabledE456: false
        });

        this.formRef.current.reset();

    };


    buscarFacturas = async () => {

        console.log('VALOR DE this.state.companyIdUser', this.state)

        var dataFilters = {
            'companyId': this.state.companyIdUser,
            'apellido1': this.state.filters.razonSocial.toUpperCase(),
            'uuid': this.state.filters.remesaSearch.toUpperCase(),
            'numdocumento': this.state.filters.cifClienteSearch.toUpperCase(),
            'numdocumentoEmpresaEmisora': this.state.filters.cifEmisorSearch.toUpperCase(),
            'itffecdesde': this.state.filters.facturaDesde,
            'itffechasta':this.state.filters.facturaHasta
        };

        const valores = JSON.stringify(dataFilters)
        const headers = {
            'Authorization': 'Bearer my-token',
            'My-Custom-Header': 'foobar',
            'x-api-key': '$2y$10$bcT1Ukm4V/6/z5GvitZv0unu8I91mpixuwUO6z5CyEGev9DDDY28W'
        };
        let direccionFile = "https://datapi.psgestion.es/facturas/erp/listado?id=" + this.state.companyIdUser;

        axios.get(direccionFile, { headers, params: { valores } }).
            then(result => {
                console.log('resultado del listados')
                console.log(result)
                this.setState({ facturaDetailTotal: result.data,
                        facturazipButtonDisabled: true})
            }).catch(function() {
                console.log('Failure')
                notifyErrorDB();
            });

    };





    showTable = () => {
        if (this.state.show === false) {
            this.setState({
                show: true
            });

            const reftp = document.getElementById("tablaLecturas")
            console.log('VALORES DE REFTP', reftp)
            reftp ?.scrollIntoView({ behavior: "smooth" })

} else {

            this.setState({
                show: false
            });
        }
    }




    updateHandler(props) {

        if (this.state.updateDataCliente && this.state.formFactura.numdocumento) {

            this.onUpdate(props)

        } else {
            notifyErrorDB();
        }
    };


    onUpdate = async (modalData) => {

    };


    handleChangeDate = (value, formattedValue, id) => {

        console.log('VALORES DE FECHA DE ALATA')
        console.log(value)
        console.log(formattedValue)
        console.log(id)

        var formData = { ...this.state.formFactura };


        const name = id;
        var valueDay = formattedValue




        if (name == 'facturaDesde') {
            //console.log('VALOR NOMBRE REPRESENTANTE ------> ', value, event.target.name)
            //this.setState ({facturaDetail.nombreOferta : event.target.value});
            this.setState({ facturaDesde: value });

        }

        if (name == 'clienteHasta') {
            //this.setState ({facturaDetail.nombreOferta : event.target.value});
            this.setState({ clienteHasta: value });
        }


        if (name == 'createdAt') {
            //console.log('VALOR NOMBRE REPRESENTANTE ------> ', value, event.target.name)
            //this.setState ({facturaDetail.nombreOferta : event.target.value});
            this.setState({ createdAt: value });

        }

        if (name == 'updatedAt') {
            //this.setState ({facturaDetail.nombreOferta : event.target.value});
            this.setState({ updatedAt: value });
        }

        if (name == 'periodoValidezDesde') {
            //console.log('VALOR NOMBRE REPRESENTANTE ------> ', value, event.target.name)
            //this.setState ({facturaDetail.nombreOferta : event.target.value});
            this.setState({ periodoValidezDesde: value });

        }

        if (name == 'periodoValidezHasta') {
            //this.setState ({facturaDetail.nombreOferta : event.target.value});
            this.setState({ periodoValidezHasta: value });
        }


        console.log(formData)
        formData[name] = valueDay;
        //if (value === "" || value === null) {
        //    formData[name] = 0;
        //}
        this.setState({ filters: formData });
    }

    handleChangeDateFilter = (value, formattedValue, id) => {

        console.log('VALORES DE FECHA DE ALATA')
        console.log(value)
        console.log(formattedValue)
        console.log(id)

        var formData = { ...this.state.filters };


        const name = id;
        var valueDay = formattedValue


        if (name == 'facturaDesde') {
            //console.log('VALOR NOMBRE REPRESENTANTE ------> ', value, event.target.name)
            //this.setState ({facturaDetail.nombreOferta : event.target.value});
            this.setState({ facturaDesde: value });
            this.setState({
                buscarButtonDisabled:false,
            })

        }

        if (name == 'facturaHasta') {
            //this.setState ({facturaDetail.nombreOferta : event.target.value});
            this.setState({ facturaHasta: value });
            this.setState({
                buscarButtonDisabled:false,
            })
        }


        console.log(formData)
        formData[name] = valueDay;
        //if (value === "" || value === null) {
        //    formData[name] = 0;
        //}
        this.setState({ filters: formData });
    }

handleChange = state => {
        // eslint-disable-next-line no-console
        //console.log('state', state.selectedRows[0]);
        //console.log(state.selectedCount)

            //var existeFactura = ''
            //var factura = state.selectedRows[0].uuid;
            //console.log(factura)
            this.setState({ facturaDetail: state.selectedRows});
            //let existeFechaAlta = state.selectedRows[0].createdAt
            //let existeFechaBaja = state.selectedRows[0].updatedAt
            //existeFactura = state.selectedRows[0].uuid

            if(state.selectedCount > 0){
              this.setState({facturaButtonDisabled:false})
            }else{
              this.setState({facturaButtonDisabled:true})
            }


    }

facturacionERPHandler(props){


        // eslint-disable-next-line no-console
        var currentDate = moment().format("YYYY-MM-DDTHH:mm:ss");
        var partes = currentDate.slice(0).split('T');
        var datefile = partes[0]
        var hourfile = partes[1]
        const id = this.state.companyIdUser;
        const file = datefile+'_facturas_Comprimidas.zip';
        const headers = {
            'Authorization': 'Bearer my-token',
            'My-Custom-Header': 'foobar',
            'x-api-key': '$2y$10$bcT1Ukm4V/6/z5GvitZv0unu8I91mpixuwUO6z5CyEGev9DDDY28W'
        };

        let direccionFileList = "https://datapi.psgestion.es/files/download/facturas?id="+this.state.companyIdUser;

        console.log(direccionFileList)
        axios.get(direccionFileList,
            { headers,
                responseType: 'blob' }).
                then((response) => {
                    FileDownload(response.data, file);
                }).catch (function() {
                    console.log ('Failure')
                });
};



    facturacionHandler(props){

          console.log('VALORES DE LA SELECCION DE FILAS', props);

          var facturas :any = this.state.facturaDetail;

          var facturasId = facturas.map(function ( factura, index, array) {

              return factura.numfactura;

          });
          //this.onSave(props)


console.log('VALOR DE FACTURAS ID', facturasId)
console.log('VALOR DE FACTURAS EN STRING', facturasId.toString())

var facturasIdtxt = facturasId.toString().replace('\'', '"')
var facturacionData = {}
facturacionData ={
                 'facturas': facturasIdtxt
}



const companyId = this.state.companyIdUser.toString();
const userId = this.state.userId.toString();


const headers = {
    'Authorization': 'Bearer my-token',
    'My-Custom-Header': 'foobar',
    'x-api-key': '$2y$10$bcT1Ukm4V/6/z5GvitZv0unu8I91mpixuwUO6z5CyEGev9DDDY28W'
};
let direccionFile = "https://datapi.psgestion.es/facturas/createremesaERP?id="+this.state.companyIdUser+"&user="+userId;


axios.post(direccionFile, facturacionData, { headers, responseType: 'json'})
     .then (res=> {
                console.log('Actualización correcta', res)
                //console.log('Datpos del registro: ', res.data)
                notify();
                this.setState({facturazipButtonDisabled: false})
                this.clearForm();
     }).catch (error =>{
                console.log ('Failure')
                console.log('Error en el proceso: ', error)
                notifyErrorDB();
                this.setState({facturazipButtonDisabled: true})
     });




      }

    handleClearRows = () => {
        this.setState({ toggledClearRows: !this.state.toggledClearRows })
    }


    render() {

        const formFactura: any = this.state.formFactura;
        console.log('VALOR DE THIS:STATE EN RENDER SIPDETAILSCONTRATOS ------> ', this.state)
        const createdAt = this.state.createdAt
        const updatedAt = this.state.updatedAt

        const facturaDesde = this.state.facturaDesde
        const facturaHasta = this.state.facturaHasta
        const clienteHasta = this.state.clienteHasta


        return (
            <>
                <Header title={'Envío de facturas a ERP'} />
                <Container className=" mt--7" fluid>

                    <Card className="mb-4">
                        <CardHeader>
                            <Button
                                onClick={this.showFiltersToggle}
                                size="sm"
                                className="float-right"
                            >
                                <i className={"ni " + this.getFilterIcon()} />
                            </Button>
                            <h3 className="mb-0">Filtrar</h3>
                        </CardHeader>
                        <Collapse isOpen={this.state.showFilters}>
                            <CardBody>
                                <Form innerRef={this.formRef}>
                                    <Row>
                                        <Col md="12" lg="3">

                                            <FormGroup>
                                                <Label className="form-control-label" htmlFor="clienteRef">
                                                    APELLIDOS/RAZON SOCIAL
                                            </Label>
                                                <Input
                                                    id="razonSocial"
                                                    placeholder="Ingresar Apellidos/Razón Social del cliente"
                                                    name="razonSocial"
                                                    maxLength={80}
                                                    onChange={this.onChangeFieldSearch}
                                                    value={this.state.filters.razonSocial}
                                                    type="text"
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col  lg="2">
                                          <FormGroup>
                                             <Label className="form-control-label" htmlFor="cifClienteSearch">
                                                  DNI/CIF CLIENTE
                                            </Label>
                                              <Input
                                                  id="cifClienteSearch"
                                                  placeholder="Ingresar CIF del cliente"
                                                  name="cifClienteSearch"
                                                    maxLength={16}
                                                  onChange={this.onChangeFieldSearch}
                                                  value={this.state.filters.cifClienteSearch}
                                                  type="text"

                                              />
                                              {this.state.cifError ? <FormText color="red">Comprobar CIF/DNI</FormText> : ''}
                                            </FormGroup>
                                          </Col>

                                          <Col md="2">
                                              <FormGroup>
                                                  <Label className="form-control-label">Desde</Label>
                                                  <DatePicker id="facturaDesde"
                                                      value={facturaDesde}
                                                      onChange={(v, f) => this.handleChangeDateFilter(v, f, "facturaDesde")}
                                                      instanceCount={1}
                                                      dateFormat={"DD-MM-YYYY"}
                                                      dayLabels={spanishDayLabels}
                                                      monthLabels={spanishMonthLabels}
                                                  />
                                              </FormGroup>
                                          </Col>

                                          <Col md="2">
                                              <FormGroup>
                                                  <Label className="form-control-label">Hasta</Label>
                                                  <DatePicker id="facturaHasta"
                                                      value={facturaHasta}
                                                      onChange={(v, f) => this.handleChangeDateFilter(v, f, "facturaHasta")}
                                                      instanceCount={2}
                                                      dateFormat={"DD-MM-YYYY"}
                                                      dayLabels={spanishDayLabels}
                                                      monthLabels={spanishMonthLabels}
                                                  />
                                              </FormGroup>
                                          </Col>

                                        </Row>
                                        <Row>

                                        <Col  lg="2">
                                          <FormGroup>
                                             <Label className="form-control-label" htmlFor="cifEmisorSearch">
                                                  DNI/CIF DISTRIBUIDORA
                                            </Label>
                                              <Input
                                                  id="cifEmisorSearch"
                                                  placeholder="Ingresar CIF del emisor"
                                                  name="cifEmisorSearch"
                                                    maxLength={16}
                                                  onChange={this.onChangeFieldSearch}
                                                  value={this.state.filters.cifEmisorSearch}
                                                  type="text"

                                              />
                                              {this.state.cifErrorEmisor ? <FormText color="red">Comprobar CIF/DNI</FormText> : ''}
                                            </FormGroup>
                                          </Col>

                                          <Col md="12" lg="3">

                                              <FormGroup>
                                                  <Label className="form-control-label" htmlFor="clienteRef">
                                                      ID. REMESA
                                              </Label>
                                                  <Input
                                                      id="remesaSearch"
                                                      placeholder="Ingresar identificador de remesa"
                                                      name="remesaSearch"
                                                      maxLength={12}
                                                      onChange={this.onChangeFieldSearch}
                                                      value={this.state.filters.remesaSearch}
                                                      type="text"
                                                  />
                                              </FormGroup>
                                          </Col>

                                        <Col className="text-right">
                                            <Button onClick={this.clearForm}>
                                                <i className={"ni ni-fat-remove"} /> Limpiar
                                    </Button>
                                            <Button
                                                color="primary"
                                                onClick={this.buscarFacturas}
                                                disabled={this.state.buscarButtonDisabled}
                                            >
                                                <i className={"bi bi-search"}/> Buscar
                                    </Button>
                                        </Col>
                                    </Row>

                                    </Form>
                            </CardBody>
                        </Collapse>
                    </Card>




                    <Row>

                        <div className="col">


                            <Card className="shadow">
<CardBody>

<Row>
                                <Col>
                                    <button
                                        className="btn btn-sm btn-primary"
                                        onClick={this.props.history.goBack}
                                    >
                                        Atrás
                                    </button>
                                </Col>
</Row>
</CardBody>
                                <DataTable
                                    title="LISTADO DE FACTURAS SIN ENVIAR A ERP"
                                    columns={columnsDatosClientes}
                                    data={this.state.facturaDetailTotal}
                                    selectableRows
                                    //selectableRowsComponent={radio} // Pass the function only
                                    //selectableRowsComponentProps={{ type: 'radio' }}// optionally, pass Material Ui supported props down to our custom checkbox
                                    //selectableRowsSingle
                                    sortIcon={sortIcon} // use a material icon for our sort icon. rdt will rotate the icon 180 degrees for you           fixedHeader = {true}
                                    noDataComponent={"Sin datos para mostrar"}
                                    pagination
                                    paginationPerPage={10}
                                    paginationRowsPerPageOptions={[10, 15, 20, 25, 30, 40]}
                                    onSelectedRowsChange={this.handleChange}
                                    clearSelectedRows={this.state.toggledClearRows}
                                    highlightOnHover
                                    striped
                                    pointerOnHover
                                    selectableRowsHighlight
                                    customStyles={customStyles}
                                    contextMessage={{ singular: 'factura', plural: 'facturas', message: 'seleccionadas' }}
                                    subHeader
                                    subHeaderComponent={
                                         <>


                                            <CSVLink
                                              data={this.state.facturaDetailTotal}
                                              filename={"FacturasGEneradas_RESUMEN.csv"}
                                              className="btn btn-default btn-sm btn-circle"
                                                color="default"
                                              target="_blank"
                                                separator={";"}
                                            >

                                              <span className="btn-inner--icon">
                                                  <i className={"bi bi-file-earmark-spreadsheet"} style={{ fontSize: 25 }}></i>
                                              </span>
                                              <span className="btn-inner--text">Fichero CSV</span>

                                            </CSVLink>

                                            <Button  disabled={this.state.facturazipButtonDisabled} id = "gpsProptooltip" color="#2dce89"  className="btn btn-info btn-sm btn-circle"
                                                    onClick={() => {if(window.confirm('Quieres realizar el proceso de envío al ERP de los elementos seleccionados?')){ this.facturacionERPHandler(this.state.facturaDetail)};}} >
                                                     <span className="btn-inner--icon">
                                                         <i className={"bi bi-journal-arrow-down"} style={{ fontSize: 25 }}></i>
                                                     </span>
                                                     <span className="btn-inner--text">BAJAR FACTURAS</span>
                                                     <UncontrolledTooltip delay={0} placement="top" target="gpsProptooltip">
                                                         BAJAR FACTURAS ENVIADAS A ERP
                                                     </UncontrolledTooltip>
                                                 </Button>



                                         <Button  disabled={this.state.facturaButtonDisabled} id = "gpsProptooltip" color="#2dce89"  className="btn btn-primary btn-sm btn-circle"
                                                 onClick={() => {if(window.confirm('Quieres realizar el proceso de envío al ERP de los elementos seleccionados?')){ this.facturacionHandler(this.state.facturaDetail)};}} >
                                                  <span className="btn-inner--icon">
                                                      <i className={"bi bi-journal-arrow-up"} style={{ fontSize: 25 }}></i>
                                                  </span>
                                                  <span className="btn-inner--text">Enviar ERP</span>
                                                  <UncontrolledTooltip delay={0} placement="top" target="gpsProptooltip">
                                                      LANZAR PROCESO DE ENVIO A ERP
                                                  </UncontrolledTooltip>
                                              </Button>

                                    </>
                                     }
                                    progressComponent={<Circular />}
                                />
                            </Card>



                        </div>
                    </Row>
                </Container>

            </>
        );
    }
}


export default withApollo(facturacionERP);
