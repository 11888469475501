import Environment from '../../constants/environment';
import React, { forwardRef } from "react";
import Reflux from "reflux";
import axios from 'axios';
import memoize from 'memoize-one';
import client from "../../api/client";
import { validateSpanishId, spainIdType } from 'spain-id';
import {
    Card, Container, Row, CardTitle, CardBody, Label,
    FormGroup,
    Input, FormText,
    Form, Col, Button,
    UncontrolledTooltip, CardHeader, Collapse
} from "reactstrap";
import {
    Link,
    useRouteMatch
} from 'react-router-dom';
import { CSVLink, CSVDownload } from "react-csv";

// core components
import Header from "../../components/Headers/Header";

import { withApollo } from "react-apollo";
import moment from "moment";
import 'moment/locale/es'

import ReactBSAlert from "react-bootstrap-sweetalert";

import GetUserData from "../../components/User/GetUserData";
import { UserStore } from "../../store/UserStore";
import DataTable from 'react-data-table-component';
import Checkbox from '@material-ui/core/Checkbox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';

import CircularProgress from '@material-ui/core/CircularProgress';
import { saveAs } from 'file-saver';
import SipsOption from "../../components/Sips/SipsOption";
import SipsGasOption from "../../components/SipsGas/SipsGasOption";
import { LIST_DICMUNICIPIOS } from "../../queries/options/dic-option.graphql";

//const ibantools = require('ibantools');
const ibantools = require('ibantools');

var DatePicker = require("reactstrap-date-picker");

const FileDownload = require('js-file-download');
const formato = require('xml-formatter');
const spanishDayLabels = ['Dom', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sab'];
const spanishMonthLabels = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];

const sortIcon = <ArrowDownward />;

const columnsDatosClientes = [

    { selector: row => row.NIF, name: 'NIF/NIE', sortable: true, width: '220px', format: (row => capitalize(row.NIF)) },
    { selector: row => row.esActiva, name: 'ACTIVO', sortable: true, width: '100px', center: true },
    { selector: row => row.Apellido1Titular, name: 'APELLIDO/RAZON SOCIAL', sortable: true, width: '240px', center: true, format: (row => capitalize(row.Apellido1Titular)) },
    { selector: row => row.Apellido2Titular, name: 'APELLIDO 2', sortable: true, width: '240px', center: true, format: (row => capitalize(row.Apellido2Titular)) },
    { selector: row => row.NombreTitular, name: 'NOMBRE', sortable: true, width: '240px', center: true, format: (row => capitalize(row.NombreTitular)) },
    { selector: row => row.CodigoPostalTitular, name: 'C.P.', sortable: true, width: '240px', center: true, format: (row => capitalize(row.CodigoPostalTitular)) },
    { selector: row => row.MunicipioTitular, name: 'MUNICIPIO', sortable: true, width: '240px', center: true, format: (row => capitalize(row.MunicipioTitular)) },
    { selector: row => row.ProvinciaTitular, name: 'PROVINCIA', sortable: true, width: '240px', center: true, format: (row => capitalize(row.ProvinciaTitular)) },
    { selector: row => row.AutonomiaTitular, name: 'AUTONOMIA', sortable: true, width: '240px', center: true, format: (row => capitalize(row.AutonomiaTitular)) },
    { selector: row => row.createdAt, name: 'ALTA', sortable: true, width: '240px', center: true, format: (row => capitalize(row.createdAt)) },

];


const capitalize = (s) => {
    if (typeof s !== 'string') return ''
    return s.toLowerCase().charAt(0).toUpperCase() + s.slice(1)
}

const customStyles = {
    headRow: {
        style: {
            border: 'none',

        },
    },
    headCells: {
        style: {
            color: '#202124',
            fontSize: '12px',
            fontWeight: 'bold',
            wordBreak: 'break-word',
            paddingLeft: '8px',
            paddingRight: '8px',
            minWidth: '55px'

        },
    },
    cells: {
        style: {
            paddingLeft: '8px',
            paddingRight: '8px',
            minWidth: '55px',
            wordBreak: 'break-word',
            fontFamily: '"Open Sans", sans-serif;'
        },
    },
    rows: {
        highlightOnHoverStyle: {
            backgroundColor: 'rgb(230, 250, 250)',
            borderBottomColor: '#FFFFFF',
            borderRadius: '25px',
            outline: '1px solid #FFFFFF',
            fontSize: '12px',
        },
    },
    pagination: {
        style: {
            border: 'none',
        },
    },
};


const Circular = () => (
    // we need to add some padding to circular progress to keep it from activating our scrollbar
    <div style={{ padding: '24px' }}>
        <CircularProgress size={75} />
    </div>
);





class Cliente extends Reflux.Component<any, any> {
    public maxFileSize = 3;
    public fileInput: any = null;
    public lecturasRef: any = [];
    public inputEl: any = null;
    public formRef: any = null;
    public validFileExtensions = ["jpg", "jpeg", "png"];
    lastFilters = {};
    state = {
        filters: {
            apellido1ClienteSearch: "",
            NIFSearch: "",
            tipoEstadoSearch: "",
            clienteDesde: ""
        },
        UserStore: {},
        nombreFichero: "",
        isLoading: false,
        copiedText: null,
        currentPage: 0,
        pageSize: 0,
        pageCount: 0,
        count: 0,
        contrato: "",
        TipoCliente: "",
        clienteDetailTotal: [],
        clienteSeleccionado: false,
        idCliente: '',
        clienteDetail: {
            id:'',
            idCliente:'',
            NombreTitular:'',
            Apellido1Titular:'',
            Apellido2Titular:'',
            NIF:'',
            idTipoTitular:'',
            descIdTipoTitular:'',
            TipoViaTitular:'',
            ViaTitular:'',
            NumFincaTitular:'',
            PortalTitular:'',
            EscaleraTitular:'',
            PisoTitular:'',
            PuertaTitular:'',
            CodigoPostalTitular:'',
            AutonomiaTitular:'',
            ProvinciaTitular:'',
            MunicipioTitular:'',
            TelefonoFijo:'',
            TelefonMovil:'',
            Email:'',
            TipoCliente:'',
            ObservacionesCliente:'',
            GDPR:true,
            IBAN:'',
            idBanco:'',
            descBanco:'',
            CNAE:'',
            actividadCNAE:'',
            idCompany:0,
            idUser:'',
            createdAt:'',
            updatedAt:'',
            esActiva:true

        },
        formCliente: {
            id:'',
            idCliente:'',
            NombreTitular:'',
            Apellido1Titular:'',
            Apellido2Titular:'',
            NIF:'',
            idTipoTitular:'',
            descIdTipoTitular:'',
            TipoViaTitular:'',
            ViaTitular:'',
            NumFincaTitular:'',
            PortalTitular:'',
            EscaleraTitular:'',
            PisoTitular:'',
            PuertaTitular:'',
            CodigoPostalTitular:'',
            AutonomiaTitular:'',
            ProvinciaTitular:'',
            MunicipioTitular:'',
            TelefonoFijo:'',
            TelefonMovil:'',
            Email:'',
            TipoCliente:'',
            ObservacionesCliente:'',
            GDPR:true,
            IBAN:'',
            idBanco:'',
            descBanco:'',
            CNAE:'',
            actividadCNAE:'',
            idCompany:0,
            idUser:'',
            createdAt:'',
            updatedAt:'',
            esActiva:true
        },

        companyIdUser: '',
        companyNameGlobal: '',
        userGroupId: 1000,
        userId: '',
        esAgencia: false,
        esComercializadora: false,
        esAdFincas: false,
        saveDataCliente: false,
        updateDataCliente: false,
        saveClienteButtonDisabled: true,
        updateClienteButtonDisabled: true,
        saveXMLButtonDisabled: true,
        buscarButtonDisabled: true,
        NIFClienteError: false,
        show: false,
        imgCollection: [],
        saveButtonDisabled: true,
        showFilters: true,
        toggledClearRows: false,
        cupsError: false,
        NIFError: false,
        locale: 'es',
        inputIdDisabled: true,
        inputDisabled: true,
        clienteDesde: '',
        clienteHasta: '',
        createdAt: '',
        updatedAt: '',
        periodoValidezHasta: '',
        periodoValidezDesde: '',
        CodigoPostalTitularError: false,
        Apellido1TitularError: false,
        emailError: false,
        NombreTitularError: false,
        DireccionFiscalError: false,
        TelefonoFijoError: false,
        TelefonMovilError: false,
        dicMunicipios: [],
        cnaeAux: "",
        IBANError: false,
        alert: null

    };

    sipsCSVData: any = [];
    viewSipsConsumoAnualCSVData: any = [];
    sipsConsumosCSVData: any = [];
    sipstotal: any = [];





    constructor(props) {
        super(props);
        this.store = UserStore;
        this.formRef = React.createRef();

    }


    componentWillReceiveProps() {

        const usuario: any = JSON.parse(JSON.stringify(this.state.UserStore['data']))

        console.log('VALORES DE USUARIO EN componentWillReceiveProps')
        console.log(usuario)

        const companyNameGlobal = usuario.company;
        const companyIdUser = usuario.companyId;

        const userGroupId = usuario.user_group_id
        const userId = usuario.id
        const esComercializadora = usuario.comercializadora
        const esAgencia = usuario.agencia
        const esAdFincas = usuario.adFincas



        if (userGroupId === 1000) {
            this.setState({
                activeUser: true
            })
        };

        this.setState({
            companyNameGlobal,
            companyIdUser,
            userGroupId,
            userId,
            esComercializadora,
            esAgencia,
            esAdFincas

        });
    }



    componentDidMount() {
        this.fetchItems();
    };

    //////////////////////////////////////////////////////////////////////////////////////7

    hideAlert = () => {
        this.setState({
            alert: null
        });
    };

    warningAlert = () => {
        this.setState({
            alert: (
                <ReactBSAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Error en la base de datos"
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="warning"
                    confirmBtnText="Ok"
                    btnSize=""
                >
                    Existe un error en la Base de datos.
     </ReactBSAlert>
            )
        });
    };

    warningAlertNIF = () => {
        this.setState({
            alert: (
                <ReactBSAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title="No existen registros que cumplan los requisitos"
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="warning"
                    confirmBtnText="Ok"
                    btnSize=""
                >
                    Revise los requisitos introducidos
     </ReactBSAlert>
            )
        });
    };

    warningAlertFiltros = () => {
        this.setState({
            alert: (
                <ReactBSAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Existen campos obligatorios sin información"
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="warning"
                    confirmBtnText="Ok"
                    btnSize=""
                >
                    Revise los datos introducidos
     </ReactBSAlert>
            )
        });
    };

    confirmAlertSave = () => {
        this.setState({
            alert: (
                <ReactBSAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title="¿Desea crear el cliente?"
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.onUpdate()}
                    showCancel
                    confirmBtnBsStyle="secondary"
                    confirmBtnText="Cancelar"
                    cancelBtnBsStyle="danger"
                    cancelBtnText="Guardar"
                    btnSize=""
                >
                    No se podrá volver atrás!
            </ReactBSAlert>
            )
        });
    };

    confirmAlertUpdate = () => {
        this.setState({
            alert: (
                <ReactBSAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title="¿Desea actualizar los datos del cliente?"
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.onUpdate()}
                    showCancel
                    confirmBtnBsStyle="secondary"
                    confirmBtnText="Cancelar"
                    cancelBtnBsStyle="danger"
                    cancelBtnText="Actualizar"
                    btnSize=""
                >
                    No se podrá volver atrás!
            </ReactBSAlert>
            )
        });
    };

    successAlert = () => {
        this.setState({
            alert: (
                <ReactBSAlert
                    success
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Correcto"
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="success"
                    confirmBtnText="Ok"
                    btnSize=""
                >
                    Cliente guardado correctamente
        </ReactBSAlert>
            )
        });
    };

    //////////////////////////////////////////////////////////////////////////////////////



    fetchItems = async () => {

        await client.query({
            query: LIST_DICMUNICIPIOS,
            variables: {}
        })
            .then((result) => {
                let data = result.data.listDicMunicipios;
                this.setState({ dicMunicipios: data })
            });
    };

    ///////////////////////////////////////////////////////////////////////////////////////////


    onChangeFieldSearch = (event) => {
        event.persist()
        var filtersData = { ...this.state.filters };
        const name = event.target.name;
        let value = event.target.value;
        const filters = this.state.filters;
        console.log(event)
        console.log(name)
        console.log(value)

        var filtersData = { ...this.state.filters };
        filters[name] = value;
        /*if (event.target.type === "select-one" && value === "0") {
           delete filters[name];
         }

         if (value === "" || value === null) {
           delete filters[name];
         }
*/

        if (event.target.name === 'apellido1ClienteSearch' && event.target.value.length == 0) {
            this.setState({
                cupsError: false,
                buscarButtonDisabled: true,
                inputIdDisabled: false
            })
        } else if (event.target.name === 'apellido1ClienteSearch' && event.target.value.length > 0) {

            this.setState({
                inputIdDisabled: true,
                buscarButtonDisabled: false

            })

        }


        if (event.target.name === 'NIFSearch' && event.target.value.length == 0) {

            this.setState({

                NIFError: false,
                buscarButtonDisabled: true,
            })
        } else if (event.target.name === 'NIFSearch' && event.target.value.length > 0) {

            const result = validateSpanishId(event.target.value)

            if (result === true) {
                this.setState({
                    NIFError: false,
                    buscarButtonDisabled: false,
                })

            } else {
                this.setState({
                    NIFError: true,
                    buscarButtonDisabled: true,
                })
            }
        }

        if (event.target.name === 'tipoEstadoSearch' && event.target.value == 'Selecciona...') {
            value = null

        }

        filtersData[name] = value;
        //if (value === "" || value === null) {
        //    formData[name] = 0;
        //}
        this.setState({ filters: filtersData });
    };

    ////////////////////////////////////////////////////////////////////////////////////////

    onChangeFieldCliente = (event) => {
        event.persist()

        var formData = { ...this.state.formCliente };
        var target = event.target;
        var value =
            event.target.type === "checkbox"
                ? event.target.checked
                : event.target.value;
        const name = event.target.name;
        console.log('Valor de event name: ', event.target.name)
        console.log('Valoe de event value: ', event.target.value)

        const nameFilters = ['idCliente', 'idTipoTitular', 'descIdTipoTitular', 'NombreTitular', 'Apellido1Titular', 'Apellido2Titular', 'codigoProvinciaTitular', 'ProvinciaTitular', 'CodigoPostalTitular', 'MunicipioTitular', 'MunicipioTitular', 'TipoViaTitular', 'viaTitular', 'numFincaTitular', 'PortalTitular', 'EscaleraTitular', 'PisoTitular', 'PuertaTitular', 'CNAE', 'actividadCNAE', 'NIF', 'codigoAutonomiaPS', 'AutonomiaTitular', 'idBanco', 'descBanco', 'IBAN', 'createdAt', 'updatedAt', 'createdBy', 'updatedBy', 'idCompany', 'Email', 'TelefonoFijo', 'TelefonoMovil', 'esActiva'];



        if (this.state.clienteSeleccionado) {

            if (nameFilters.indexOf(event.target.name) != -1) {
                this.setState({ updateDataCliente: true });
                this.setState({
                    saveClienteButtonDisabled: true,
                    updateClienteButtonDisabled: false
                })
            }
            if (event.target.name == 'NombreTitular' && event.target.value.length > 0) {

                this.setState({ updateDataCliente: true });
                this.setState({
                    saveClienteButtonDisabled: true,
                    updateClienteButtonDisabled: false
                })
            }
            if (event.target.name == 'Apellido1Titular' && event.target.value.length > 0) {

                this.setState({ updateDataCliente: true });
                this.setState({
                    saveClienteButtonDisabled: true,
                    updateClienteButtonDisabled: false
                })
            }
            if (event.target.name == 'NombreTitular' && event.target.value.length > 0) {
                //console.log('VALOR NOMBRE REPRESENTANTE ------> ', value, event.target.name)
                //this.setState ({representanteDetail.nombreRepresentante : event.target.value});
                this.setState({ updateDataCliente: true });
                this.setState({
                    saveClienteButtonDisabled: true,
                    updateClienteButtonDisabled: false
                })
            }

            if (event.target.name === 'NIF' && event.target.value.length == 0) {
                this.setState({ NIFClienteError: true })
            } else if (event.target.name === 'NIF' && event.target.value.length > 0) {
                const result = validateSpanishId(event.target.value)
                if (result === true) {
                    this.setState({
                        updateDataCliente: true,
                        NIFClienteError: false
                    });
                    this.setState({
                        saveClienteButtonDisabled: true,
                        updateClienteButtonDisabled: false
                    })
                    const result = spainIdType(event.target.value)
                    formData['descIdTipoTitular'] = result.toUpperCase();
                    this.setState({ formCliente: formData });
                } else {
                    this.setState({
                        NIFClienteError: true,
                        updateDataCliente: false,
                        updateClienteButtonDisabled: true
                    })
                }
            }


            if (event.target.name === 'Email') {


                const pattern = /[a-zA-Z0-9]+[\.]?([a-zA-Z0-9]+)?[\@][a-z]{3,9}[\.][a-z]{2,5}/g;
                //const pattern = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/
                const result = pattern.test(event.target.value);

                if (result === true) {
                    this.setState({
                        emailError: false,
                    })
                } else {
                    this.setState({
                        emailError: true
                    })
                }
            }

            if (event.target.name === 'IBAN') {

                const resultIBAN = ibantools.isValidIBAN(event.target.value);

                if (resultIBAN === true) {
                    this.setState({
                        IBANError: false,
                    })
                } else {
                    this.setState({
                        IBANError: true
                    })
                }
            }

            if (name == 'MunicipioTitular') {

                const municipio: any = this.state.dicMunicipios
                const resultado = municipio.find(municipio => municipio ?.Nombre === value);

                console.log('VALOR DE FIND -------------------->', resultado.DescProvincia, resultado.DescAutonomia);

                //const filtered = municipio.filter(entry => Object.values(entry).some(val => typeof val === "string" && val.includes('Barromán')));

                formData['ProvinciaTitular'] = resultado.DescProvincia
                formData['AutonomiaTitular'] = resultado.DescAutonomia

            }


            if (name == 'nombreMunicipioPS') {
                const municipio: any = this.state.dicMunicipios
                const resultado = municipio.find(municipio => municipio ?.Nombre === value);
                const filtered = municipio.filter(entry => Object.values(entry).some(val => typeof val === "string" && val.includes('Barromán')));
                formData['nombreProvinciaPS'] = resultado.DescProvincia
                formData['AutonomiaTitular'] = resultado.DescAutonomia
            }



            if (event.target.name == 'CNAE') {
                //this.setState ({clienteDetail.nombreOferta : event.target.value});
                this.setState({ updateDataCliente: true });
                this.setState({
                    saveClienteButtonDisabled: true,
                    updateClienteButtonDisabled: false
                })
                const descripcionCNAE = event.target.value
                console.log('valores de target.selectedOptions ----- ', event.target.selectedOptions[0])
                console.log('valores de target.selectedOptions ----- ', event.target.selectedOptions[0].innerText)
                value = event.target.selectedOptions[0].innerText
                console.log(formData)
                formData['actividadCNAE'] = descripcionCNAE;
                //if (value === "" || value === null) {
                //    formData[name] = 0;
                //}
                this.setState({
                    formCliente: formData,
                    cnaeAux: descripcionCNAE
                });
            }

            if (event.target.name === 'esActiva' && event.target.value == 'Selecciona...') {
                value = null

            }

        }

        formData[name] = value;
        //if (value === "" || value === null) {
        //    formData[name] = 0;
        //}
        this.setState({ formCliente: formData });

    };


    ///////////////////////////////////////////////////////////////////////////////////////

    showFiltersToggle = () => {
        const showFilters = !this.state.showFilters;

        this.setState({ showFilters });
    };

    //////////////////////////////////////////////////////////////////////////////////////

    getFilterIcon = () => {
        return this.state.showFilters ? "ni-bold-up" : "ni-bold-down";
    };

    ////////////////////////////////////////////////////////////////////////////////////

    clearForm = () => {
        const filters = {};
        this.setState({
            filters: {
                apellido1ClienteSearch: '',
                NIFSearch: '',
                idSearch: ''
            },
            inputIdDisabled: false,
            inputDisabled: false,
            buscarButtonDisabled: true,
            clienteSeleccionado: false,
            clienteDetailTotal: [],
            formCliente: {
                id:'',
                idCliente:'',
                NombreTitular:'',
                Apellido1Titular:'',
                Apellido2Titular:'',
                NIF:'',
                idTipoTitular:'',
                descIdTipoTitular:'',
                TipoViaTitular:'',
                ViaTitular:'',
                NumFincaTitular:'',
                PortalTitular:'',
                EscaleraTitular:'',
                PisoTitular:'',
                PuertaTitular:'',
                CodigoPostalTitular:'',
                AutonomiaTitular:'',
                ProvinciaTitular:'',
                MunicipioTitular:'',
                TelefonoFijo:'',
                TelefonMovil:'',
                Email:'',
                TipoCliente:'',
                ObservacionesCliente:'',
                GDPR:true,
                IBAN:'',
                idBanco:'',
                descBanco:'',
                CNAE:'',
                actividadCNAE:'',
                idCompany:0,
                idUser:'',
                createdAt:'',
                updatedAt:'',
                esActiva:true

            },
            toggledClearRows: !this.state.toggledClearRows,
            saveClienteButtonDisabled: true,
            updateClienteButtonDisabled: true,
            createdAt: '',
            updatedAt: ''
        });

        this.formRef.current.reset();

    };

    //////////////////////////////////////////////////////////////////////////////////////

    buscarCliente = async () => {

        console.log('VALOR DE this.state.companyIdUser', this.state)

        var dataFilters = {
            'idCompany': this.state.companyIdUser,
            'Apellido1Titular': this.state.filters.apellido1ClienteSearch.toUpperCase(),
            'NIF': this.state.filters.NIFSearch.toUpperCase(),
            'createdAt': this.state.filters.clienteDesde,
            'esActiva': this.state.filters.tipoEstadoSearch
        };

        const valores = JSON.stringify(dataFilters)
        const headers = {
            'Authorization': 'Bearer my-token',
            'My-Custom-Header': 'foobar',
            'x-api-key': '$2y$10$bcT1Ukm4V/6/z5GvitZv0unu8I91mpixuwUO6z5CyEGev9DDDY28W'
        };
        let direccionFile = "https://datapi.psgestion.es/cliente/listado?id=" + this.state.companyIdUser;

        axios.get(direccionFile, { headers, params: { valores } }).
            then(result => {
                console.log('resultado del listados')
                if(result.data.rows.length>0){
                let data = result.data.rows

                this.setState({ clienteDetailTotal: result.data.rows});

            } else {
            this.warningAlertNIF()
                };

        }).catch((error) =>{
                console.log('Failure')
                this.warningAlert()
            });

    };

///////////////////////////////////////////////////////////////////////////////////

    showTable = () => {
        if (this.state.show === false) {
            this.setState({
                show: true
            });

            const reftp = document.getElementById("tablaLecturas")
            console.log('VALORES DE REFTP', reftp)
            reftp ?.scrollIntoView({ behavior: "smooth" })

} else {

            this.setState({
                show: false
            });
        }
    }

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////


    updateHandler(props) {

        if (this.state.updateDataCliente && (this.state.formCliente.NombreTitular && this.state.formCliente.NIF)) {

            this.confirmAlertUpdate()

        } else {
            this.warningAlertFiltros()
        }
    };



    onUpdate = async () => {
        console.log('ESTAMOS EN ONSAVE VALORE DE THIS.STATE-------->', this.state.updateDataCliente)

        //const fechaActivacion = this.state.fechaActivacion;
        const companyId = this.state.companyIdUser.toString();
        const userId = this.state.userId.toString();

        if (this.state.updateDataCliente == true) {

            console.log('ENTRA EN SAVE DATOS producto')
            console.log('DATOS DE THIS.STATE   --------> ', this.state)

            var updatedAt = this.state.formCliente.updatedAt;
            if (!updatedAt) {
                updatedAt = moment().format("YYYY-MM-DDTHH:mm:ss");

            }
            var createdAt = this.state.formCliente.createdAt;

            if (!createdAt) {

                createdAt = moment().format("YYYY-MM-DDTHH:mm:ss");
            }

            var productoDataUpdate = {}



            productoDataUpdate = {

                'id': this.state.formCliente.id,
                'idCliente': this.state.formCliente.idCliente,
                'NombreTitular': this.state.formCliente.NombreTitular.toUpperCase(),
                'Apellido1Titular': this.state.formCliente.Apellido1Titular.toUpperCase(),
                'Apellido2Titular': this.state.formCliente.Apellido2Titular.toUpperCase(),
                'NIF': this.state.formCliente.NIF,
                'idTipoTitular': this.state.formCliente.idTipoTitular.toUpperCase(),
                'descIdTipoTitular': this.state.formCliente.descIdTipoTitular.toUpperCase(),
                'TipoViaTitular': this.state.formCliente.TipoViaTitular.toUpperCase(),
                'ViaTitular': this.state.formCliente.ViaTitular.toUpperCase(),
                'NumFincaTitular': this.state.formCliente.NumFincaTitular.toUpperCase(),
                'PortalTitular': this.state.formCliente.PortalTitular.toUpperCase(),
                'EscaleraTitular': this.state.formCliente.EscaleraTitular.toUpperCase(),
                'PisoTitular': this.state.formCliente.PisoTitular.toUpperCase(),
                'PuertaTitular': this.state.formCliente.PuertaTitular.toUpperCase(),
                'CodigoPostalTitular': this.state.formCliente.CodigoPostalTitular,
                'AutonomiaTitular': this.state.formCliente.AutonomiaTitular.toUpperCase(),
                'ProvinciaTitular': this.state.formCliente.ProvinciaTitular.toUpperCase(),
                'MunicipioTitular': this.state.formCliente.MunicipioTitular.toUpperCase(),
                'TelefonoFijo': this.state.formCliente.TelefonoFijo,
                'TelefonMovil': this.state.formCliente.TelefonMovil,
                'Email': this.state.formCliente.Email,
                'TipoCliente': this.state.formCliente.TipoCliente,
                'ObservacionesCliente': this.state.formCliente.ObservacionesCliente.toUpperCase(),
                'GDPR': this.state.formCliente.GDPR,
                'IBAN': this.state.formCliente.IBAN,
                'idBanco': this.state.formCliente.idBanco,
                'descBanco': this.state.formCliente.descBanco.toUpperCase(),
                'CNAE': this.state.formCliente.CNAE,
                'actividadCNAE': this.state.formCliente.actividadCNAE,
                'idCompany': this.state.formCliente.idCompany,
                'idUser': this.state.formCliente.idUser,
                'createdAt':createdAt,
                'updatedAt':updatedAt,
                'esActiva': this.state.formCliente.esActiva
            };


            var resultadoUpdate: any = [];
            var resultadoView: any = [];



            const headers = {
                'Authorization': 'Bearer my-token',
                'My-Custom-Header': 'foobar',
                'x-api-key': '$2y$10$bcT1Ukm4V/6/z5GvitZv0unu8I91mpixuwUO6z5CyEGev9DDDY28W'
            };
            let direccionFile = "https://datapi.psgestion.es/cliente/update?id=" + this.state.companyIdUser + "&user=" + userId;


            axios.post(direccionFile, productoDataUpdate, { headers })
                .then(res => {
                    console.log('Actualización correcta', res)
                    console.log('Datpos del registro: ', res.data)
                    this.successAlert();
                    this.setState({
                        inputDisabled: false,
                        clienteSeleccionado: false,
                        clienteDetailTotal: [],
                        toggledClearRows: !this.state.toggledClearRows,
                        saveClienteButtonDisabled: true,
                        updateClienteButtonDisabled: true,
                        createdAt: '',
                        updatedAt: '',
                        formCliente: {
                            id:'',
                            idCliente:'',
                            NombreTitular:'',
                            Apellido1Titular:'',
                            Apellido2Titular:'',
                            NIF:'',
                            idTipoTitular:'',
                            descIdTipoTitular:'',
                            TipoViaTitular:'',
                            ViaTitular:'',
                            NumFincaTitular:'',
                            PortalTitular:'',
                            EscaleraTitular:'',
                            PisoTitular:'',
                            PuertaTitular:'',
                            CodigoPostalTitular:'',
                            AutonomiaTitular:'',
                            ProvinciaTitular:'',
                            MunicipioTitular:'',
                            TelefonoFijo:'',
                            TelefonMovil:'',
                            Email:'',
                            TipoCliente:'',
                            ObservacionesCliente:'',
                            GDPR:true,
                            IBAN:'',
                            idBanco:'',
                            descBanco:'',
                            CNAE:'',
                            actividadCNAE:'',
                            idCompany:0,
                            idUser:'',
                            createdAt:'',
                            updatedAt:'',
                            esActiva:true
                        }

                    });

                }).catch((error) => {
                    console.log('Failure')
                    console.log('Error en el proceso: ', error)
                    this.warningAlert();
                });

            await this.buscarCliente();


        }

    };


    handleChangeDate = (value, formattedValue, id) => {

        console.log('VALORES DE FECHA DE ALATA')
        console.log(value)
        console.log(formattedValue)
        console.log(id)

        var formData = { ...this.state.formCliente };


        const name = id;
        var valueDay = formattedValue




        if (name == 'clienteDesde') {
            //console.log('VALOR NOMBRE REPRESENTANTE ------> ', value, event.target.name)
            //this.setState ({clienteDetail.nombreOferta : event.target.value});
            this.setState({ clienteDesde: value });

        }

        if (name == 'clienteHasta') {
            //this.setState ({clienteDetail.nombreOferta : event.target.value});
            this.setState({ clienteHasta: value });
        }


        if (name == 'createdAt') {
            //console.log('VALOR NOMBRE REPRESENTANTE ------> ', value, event.target.name)
            //this.setState ({clienteDetail.nombreOferta : event.target.value});
            this.setState({ createdAt: value });

        }

        if (name == 'updatedAt') {
            //this.setState ({clienteDetail.nombreOferta : event.target.value});
            this.setState({ updatedAt: value });
        }

        if (name == 'periodoValidezDesde') {
            //console.log('VALOR NOMBRE REPRESENTANTE ------> ', value, event.target.name)
            //this.setState ({clienteDetail.nombreOferta : event.target.value});
            this.setState({ periodoValidezDesde: value });

        }

        if (name == 'periodoValidezHasta') {
            //this.setState ({clienteDetail.nombreOferta : event.target.value});
            this.setState({ periodoValidezHasta: value });
        }


        console.log(formData)
        formData[name] = value
        //if (value === "" || value === null) {
        //    formData[name] = 0;
        //}
        this.setState({ filters: formData });
    }

    handleChangeDateFilter = (value, formattedValue, id) => {

        console.log('VALORES DE FECHA DE ALATA')
        console.log(value)
        console.log(formattedValue)
        console.log(id)

        var formData = { ...this.state.filters };


        const name = id;
        var valueDay = formattedValue




        if (name == 'clienteDesde') {
            //console.log('VALOR NOMBRE REPRESENTANTE ------> ', value, event.target.name)
            //this.setState ({clienteDetail.nombreOferta : event.target.value});
            this.setState({ clienteDesde: value });

        }

        if (name == 'clienteHasta') {
            //this.setState ({clienteDetail.nombreOferta : event.target.value});
            this.setState({ clienteHasta: value });
        }


        if (name == 'createdAt') {
            //console.log('VALOR NOMBRE REPRESENTANTE ------> ', value, event.target.name)
            //this.setState ({clienteDetail.nombreOferta : event.target.value});
            this.setState({ createdAt: value });

        }

        if (name == 'updatedAt') {
            //this.setState ({clienteDetail.nombreOferta : event.target.value});
            this.setState({ updatedAt: value });
        }

        if (name == 'periodoValidezDesde') {
            //console.log('VALOR NOMBRE REPRESENTANTE ------> ', value, event.target.name)
            //this.setState ({clienteDetail.nombreOferta : event.target.value});
            this.setState({ periodoValidezDesde: value });

        }

        if (name == 'periodoValidezHasta') {
            //this.setState ({clienteDetail.nombreOferta : event.target.value});
            this.setState({ periodoValidezHasta: value });
        }


        console.log(formData)
        formData[name] = valueDay;
        //if (value === "" || value === null) {
        //    formData[name] = 0;
        //}
        this.setState({ filters: formData });
    }

    handleChange = state => {
        // eslint-disable-next-line no-console
        //console.log('state', state.selectedRows[0]);
        //console.log(state.selectedCount)

        var producto = '';

        if (state.selectedCount === 1) {
            var existeCliente = ''
            var cliente = state.selectedRows[0].idCliente;
            console.log(cliente)
            this.setState({ clienteDetail: state.selectedRows[0] });
            let existeFechaAlta = state.selectedRows[0].createdAt
            let existeFechaBaja = state.selectedRows[0].updatedAt
            existeCliente = state.selectedRows[0].idCliente

            if (existeFechaAlta && existeFechaBaja) {
                console.log('ENTRA EN EXISTE FECHA DE ALTA Y BAJA')

                this.setState({
                    inputDisabled: true,
                    updateClienteButtonDisabled: false
                })
            } else if (!existeFechaAlta && !existeFechaBaja) {
                console.log('ENTRA EN no EXISTE FECHA DE ALTA Y BAJA')
                this.setState({
                    inputDisabled: false,
                    updateClienteButtonDisabled: true
                })
            }



            if (existeCliente) {
                console.log('VALORE DE clienteDetail  ------> ', this.state.clienteDetail)
                this.setState({ idCliente: this.state.clienteDetail.idCliente });
                this.setState({
                    formCliente: {
                        id: state.selectedRows[0].id,
                        idCliente: state.selectedRows[0].idCliente,
                        NombreTitular: state.selectedRows[0].NombreTitular.toUpperCase(),
                        Apellido1Titular: state.selectedRows[0].Apellido1Titular.toUpperCase(),
                        Apellido2Titular: state.selectedRows[0].Apellido2Titular.toUpperCase(),
                        NIF: state.selectedRows[0].NIF,
                        idTipoTitular: state.selectedRows[0].idTipoTitular.toUpperCase(),
                        descIdTipoTitular: state.selectedRows[0].descIdTipoTitular.toUpperCase(),
                        TipoViaTitular: state.selectedRows[0].TipoViaTitular.toUpperCase(),
                        ViaTitular: state.selectedRows[0].ViaTitular.toUpperCase(),
                        NumFincaTitular: state.selectedRows[0].NumFincaTitular.toUpperCase(),
                        PortalTitular: state.selectedRows[0].PortalTitular.toUpperCase(),
                        EscaleraTitular: state.selectedRows[0].EscaleraTitular.toUpperCase(),
                        PisoTitular: state.selectedRows[0].PisoTitular.toUpperCase(),
                        PuertaTitular: state.selectedRows[0].PuertaTitular.toUpperCase(),
                        CodigoPostalTitular: state.selectedRows[0].CodigoPostalTitular,
                        AutonomiaTitular: state.selectedRows[0].AutonomiaTitular.toUpperCase(),
                        ProvinciaTitular: state.selectedRows[0].ProvinciaTitular.toUpperCase(),
                        MunicipioTitular: state.selectedRows[0].MunicipioTitular.toUpperCase(),
                        TelefonoFijo: state.selectedRows[0].TelefonoFijo,
                        TelefonMovil: state.selectedRows[0].TelefonoMovil,
                        Email: state.selectedRows[0].Email,
                        TipoCliente: state.selectedRows[0].TipoCliente,
                        ObservacionesCliente: state.selectedRows[0].ObservacionesCliente?.toUpperCase() || '',
                        GDPR: state.selectedRows[0].GDPR,
                        IBAN: state.selectedRows[0].IBAN,
                        idBanco: state.selectedRows[0].idBanco,
                        descBanco: state.selectedRows[0].descBanco.toUpperCase(),
                        CNAE: state.selectedRows[0].CNAE,
                        actividadCNAE: state.selectedRows[0].actividadCNAE,
                        idCompany: state.selectedRows[0].idCompany,
                        idUser: state.selectedRows[0].idUser,
                        createdAt: this.state.formCliente.createdAt,
                        updatedAt: this.state.formCliente.updatedAt,
                        esActiva: state.selectedRows[0].esActiva

                    },
                    clienteSeleccionado: true,
                    inputDisabled: false
                });

            } else {
                console.log('ENTRA EN NO existeCliente')
                this.setState({
                    clienteSeleccionado: false,
                    formCliente: {
                        id:'',
                        idCliente:'',
                        NombreTitular:'',
                        Apellido1Titular:'',
                        Apellido2Titular:'',
                        NIF:'',
                        idTipoTitular:'',
                        descIdTipoTitular:'',
                        TipoViaTitular:'',
                        ViaTitular:'',
                        NumFincaTitular:'',
                        PortalTitular:'',
                        EscaleraTitular:'',
                        PisoTitular:'',
                        PuertaTitular:'',
                        CodigoPostalTitular:'',
                        AutonomiaTitular:'',
                        ProvinciaTitular:'',
                        MunicipioTitular:'',
                        TelefonoFijo:'',
                        TelefonMovil:'',
                        Email:'',
                        TipoCliente:'',
                        ObservacionesCliente:'',
                        GDPR:true,
                        IBAN:'',
                        idBanco:'',
                        descBanco:'',
                        CNAE:'',
                        actividadCNAE:'',
                        idCompany:0,
                        idUser:'',
                        createdAt:'',
                        updatedAt:'',
                        esActiva:true
                    }
                });
            }
        } else {

            this.setState({
                clienteSeleccionado: false,
                inputDisabled: false,
                updateClienteButtonDisabled: true,
                formCliente: {
                    id:'',
                    idCliente:'',
                    NombreTitular:'',
                    Apellido1Titular:'',
                    Apellido2Titular:'',
                    NIF:'',
                    idTipoTitular:'',
                    descIdTipoTitular:'',
                    TipoViaTitular:'',
                    ViaTitular:'',
                    NumFincaTitular:'',
                    PortalTitular:'',
                    EscaleraTitular:'',
                    PisoTitular:'',
                    PuertaTitular:'',
                    CodigoPostalTitular:'',
                    AutonomiaTitular:'',
                    ProvinciaTitular:'',
                    MunicipioTitular:'',
                    TelefonoFijo:'',
                    TelefonMovil:'',
                    Email:'',
                    TipoCliente:'',
                    ObservacionesCliente:'',
                    GDPR:true,
                    IBAN:'',
                    idBanco:'',
                    descBanco:'',
                    CNAE:'',
                    actividadCNAE:'',
                    idCompany:0,
                    idUser:'',
                    createdAt:'',
                    updatedAt:'',
                    esActiva:true
                }
            });



        };

    }



    handleClearRows = () => {
        this.setState({ toggledClearRows: !this.state.toggledClearRows })
    }



    render() {

        const formCliente: any = this.state.formCliente;
        console.log('VALOR DE THIS:STATE EN RENDER SIPDETAILSCONTRATOS ------> ', this.state)
        const createdAt = this.state.createdAt
        const updatedAt = this.state.updatedAt

        const clienteDesde = this.state.clienteDesde
        const clienteHasta = this.state.clienteHasta

if(this.state.userGroupId == 1000) {
        return (
            <>
                <Header title={'Información Clientes'} />
                <Container className=" mt--7" fluid>

                    <Card className="mb-4">
                        <CardHeader>
                            <Button
                                onClick={this.showFiltersToggle}
                                size="sm"
                                className="float-right"
                            >
                                <i className={"ni " + this.getFilterIcon()} />
                            </Button>
                            <h3 className="mb-0">Filtrar</h3>
                        </CardHeader>
                        <Collapse isOpen={this.state.showFilters}>
                            <CardBody>
                                <Form innerRef={this.formRef}>
                                    <Row>
                                        <Col md="12" lg="3">
                                            <FormGroup>
                                                <Label className="form-control-label" htmlFor="clienteRef">
                                                    APELLIDOS/RAZON SOCIAL
                                  </Label>
                                                <Input
                                                    id="apellido1ClienteSearch"
                                                    placeholder="Ingresar Apellidos/Razón Social del cliente"
                                                    name="apellido1ClienteSearch"
                                                    maxLength={80}
                                                    onChange={this.onChangeFieldSearch}
                                                    value={this.state.filters.apellido1ClienteSearch}
                                                    type="text"
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col lg="2">
                                            <FormGroup>
                                                <Label className="form-control-label" htmlFor="NIFSearch">
                                                    DNI/NIF
                                            </Label>
                                                <Input
                                                    id="NIFSearch"
                                                    placeholder="Ingresar NIF del representante"
                                                    name="NIFSearch"
                                                    maxLength={16}
                                                    onChange={this.onChangeFieldSearch}
                                                    value={this.state.filters.NIFSearch}
                                                    type="text"

                                                />
                                                {this.state.NIFError ? <FormText color="red">Comprobar NIF/DNI</FormText> : ''}
                                            </FormGroup>
                                        </Col>


                                        <Col md="2">
                                            <FormGroup>
                                                <Label className="form-control-label">Desde</Label>
                                                <DatePicker id="clienteDesde"
                                                    value={clienteDesde}
                                                    onChange={(v, f) => this.handleChangeDateFilter(v, f, "clienteDesde")}
                                                    instanceCount={1}
                                                    dateFormat={"DD-MM-YYYY"}
                                                    dayLabels={spanishDayLabels}
                                                    monthLabels={spanishMonthLabels}
                                                />
                                            </FormGroup>
                                        </Col>

                                        <Col md="2" lg="2">
                                            <FormGroup>
                                                <Label className="form-control-label" for="tipoEstadoProducto">ESTADO</Label>
                                                <Input type="select" name="tipoEstadoSearch" id="tipoEstadoSearch"
                                                    onChange={this.onChangeFieldSearch}
                                                    value={this.state.filters.tipoEstadoSearch}>
                                                    <option selected>Selecciona...</option>
                                                    <option value="true">ACTIVO</option>
                                                    <option value="false">BAJA</option>
                                                </Input>
                                            </FormGroup>
                                        </Col>

                                        <Col className="text-right">
                                            <Button onClick={this.clearForm}>
                                                <i className={"ni ni-fat-remove"} /> Limpiar
                                    </Button>
                                            <Button
                                                color="primary"
                                                onClick={this.buscarCliente}
                                                disabled={this.state.buscarButtonDisabled}
                                            >
                                                <i className={"bi bi-search"} /> Buscar
                                    </Button>
                                        </Col>
                                    </Row>

                                    <Card className="shadow">
                                        <DataTable
                                            title="LISTADO DE CLIENTES"
                                            columns={columnsDatosClientes}
                                            data={this.state.clienteDetailTotal}
                                            selectableRows
                                            //selectableRowsComponent={radio} // Pass the function only
                                            selectableRowsComponentProps={{ type: 'radio' }}// optionally, pass Material Ui supported props down to our custom checkbox
                                            selectableRowsSingle
                                            sortIcon={sortIcon} // use a material icon for our sort icon. rdt will rotate the icon 180 degrees for you           fixedHeader = {true}
                                            noDataComponent={"Sin datos para mostrar"}
                                            pagination
                                            paginationPerPage={10}
                                            paginationRowsPerPageOptions={[10, 15, 20, 25, 30, 40]}
                                            onSelectedRowsChange={this.handleChange}
                                            clearSelectedRows={this.state.toggledClearRows}
                                            highlightOnHover
                                            striped
                                            pointerOnHover
                                            selectableRowsHighlight
                                            customStyles={customStyles}
                                            contextMessage={{ singular: 'producto', plural: 'productos', message: 'seleccionado' }}
                                            subHeader
                                            subHeaderComponent={

                                                <div style={{ display: 'flex', alignItems: 'center' }}>

                                                    <CSVLink
                                                        data={this.state.clienteDetailTotal}
                                                        filename={"ClientesRegistrados.csv"}
                                                        className="btn btn-default btn-sm btn-circle"
                                                        color="default"
                                                        target="_blank"
                                                        separator={";"}
                                                    >

                                                        <span className="btn-inner--icon">
                                                            <i className={"bi bi-file-earmark-spreadsheet"} style={{ fontSize: 25 }}></i>
                                                        </span>
                                                        <span className="btn-inner--text">Fichero CSV</span>

                                                    </CSVLink>
                                                </div>
                                            }
                                            progressComponent={<Circular />}
                                        />
                                    </Card>

                                </Form>
                            </CardBody>
                        </Collapse>
                    </Card>




                    <Row>
                        <div className="col">
                            <Card className="shadow">
                                <CardBody>
                                    <Row>
                                        <Col>
                                            <button
                                                className="btn btn-sm btn-primary"
                                                onClick={this.props.history.goBack}
                                            >
                                                Atrás
                                            </button>
                                        </Col>

                                        <Col className="col-auto d-flex">
                                            <Card className="p-3 bg-light">
                                                <FormGroup>

                                                    <Button disabled={this.state.updateClienteButtonDisabled} id="UpdateRepreProptooltip" color="danger" size="m" type="button" className="btn-icon btn-sm btn-circle"
                                                        onClick={() => { this.updateHandler(this.state.updateDataCliente)  }}
                                                    >
                                                        <span className="btn-inner--icon">
                                                            <i className="bi bi-person-lines-fill" style={{ fontSize: 30 }}></i>
                                                        </span>
                                                        <span className="btn-inner--text">Actualizar</span>
                                                        <UncontrolledTooltip delay={0} placement="top" target="UpdateRepreProptooltip">
                                                            MODIFICAR CLIENTE EXISTENTE
                                                    </UncontrolledTooltip>
                                                    </Button>

                                                    <Link
                                                        color="info" type="button" className="btn btn-primary btn-sm btn-circle"
                                                        data-toggle="modal"
                                                        to={{ pathname: "nuevo-cliente" }}
                                                        id="newElecProduct"
                                                    >
                                                        <span className="btn-inner--icon">
                                                            <i className="bi bi-person-plus-fill" style={{ fontSize: 30 }}></i>
                                                        </span>
                                                        <span className="btn-inner--text">Nuevo Cliente</span>

                                                        <UncontrolledTooltip delay={0} placement="top" target="newElecProduct">
                                                            CREAR NUEVO CLIENTE
                                                </UncontrolledTooltip>
                                                    </Link>

                                                </FormGroup>

                                            </Card>
                                        </Col>

                                    </Row>
                                    <div>
                                        <CardTitle>DATOS GENERALES CLIENTE</CardTitle>
                                    </div>

                                    <Row>
                                        <Col>
                                            <FormGroup>

                                                <Label className="form-control-label" htmlFor="NombreTitular">
                                                    Nombre Cliente
                                                </Label>
                                                <Input
                                                    className="text-primary"
                                                    id="NombreTitular"
                                                    placeholder=""
                                                    name="NombreTitular"
                                                    maxLength={40}
                                                    onChange={this.onChangeFieldCliente}
                                                    value={formCliente.NombreTitular}
                                                    type="text"
                                                    disabled={this.state.inputDisabled}
                                                />
                                            </FormGroup>
                                        </Col>

                                        <Col>
                                            <FormGroup>

                                                <Label className="form-control-label" htmlFor="Apellido1Titular">
                                                    Primer Apellido/Razón Social
                                                </Label>
                                                <Input
                                                    id="Apellido1Titular"
                                                    placeholder=""
                                                    name="Apellido1Titular"
                                                    maxLength={50}
                                                    onChange={this.onChangeFieldCliente}
                                                    value={formCliente.Apellido1Titular}
                                                    type="text"
                                                    disabled={this.state.inputDisabled}
                                                />
                                            </FormGroup>
                                        </Col>


                                        <Col>
                                            <FormGroup>

                                                <Label className="form-control-label" htmlFor="Apellido2Titular">
                                                    Segundo Apellido
                                                </Label>
                                                <Input
                                                    id="Apellido2Titular"
                                                    placeholder=""
                                                    name="Apellido2Titular"
                                                    maxLength={50}
                                                    onChange={this.onChangeFieldCliente}
                                                    value={formCliente.Apellido2Titular}
                                                    type="text"
                                                    disabled={this.state.inputDisabled}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col>
                                            <FormGroup>
                                                <Label className="form-control-label" htmlFor="NIF">
                                                    NIF/NIF
                                                </Label>
                                                <Input
                                                    id="NIF"
                                                    placeholder=""
                                                    name="NIF"
                                                    maxLength={16}
                                                    onChange={this.onChangeFieldCliente}
                                                    value={formCliente.NIF}
                                                    type="text"
                                                    disabled={this.state.inputDisabled}
                                                />
                                                {this.state.NIFClienteError ? <FormText color="red">Comprobar NIF/NIF</FormText> : ''}
                                            </FormGroup>
                                        </Col>

                                    </Row>
                                    <Row>
                                        <Col md="2">
                                            <FormGroup>

                                                <Label className="form-control-label" htmlFor="TipoViaTitular">
                                                    Tipo Via
                                    </Label>
                                                <Input
                                                    id="TipoViaTitular"
                                                    placeholder=""
                                                    name="TipoViaTitular"
                                                    maxLength={12}
                                                    onChange={this.onChangeFieldCliente}
                                                    value={formCliente.TipoViaTitular}
                                                    type="text"
                                                    disabled={this.state.inputDisabled}
                                                />
                                            </FormGroup>
                                        </Col>

                                        <Col md="6">
                                            <FormGroup>

                                                <Label className="form-control-label" htmlFor="ViaTitular">
                                                    Dirección Titular
                                   </Label>
                                                <Input
                                                    id="ViaTitular"
                                                    placeholder=""
                                                    name="ViaTitular"
                                                    maxLength={60}
                                                    onChange={this.onChangeFieldCliente}
                                                    value={formCliente.ViaTitular}
                                                    type="text"
                                                    disabled={this.state.inputDisabled}

                                                />
                                            </FormGroup>
                                        </Col>


                                        <Col md="2">
                                            <FormGroup>

                                                <Label className="form-control-label" htmlFor="NumFincaTitular">
                                                    Nº Finca
                                    </Label>
                                                <Input
                                                    id="NumFincaTitular"
                                                    placeholder=""
                                                    name="NumFincaTitular"
                                                    maxLength={10}
                                                    onChange={this.onChangeFieldCliente}
                                                    value={formCliente.NumFincaTitular}
                                                    type="text"
                                                    disabled={this.state.inputDisabled}

                                                />
                                            </FormGroup>
                                        </Col>



                                        <Col md="2">
                                            <FormGroup>
                                                <Label className="form-control-label" htmlFor="PortalTitular">
                                                    Portal Tit.
                                    </Label>
                                                <Input
                                                    id="PortalTitular"
                                                    placeholder=""
                                                    name="PortalTitular"
                                                    maxLength={10}
                                                    onChange={this.onChangeFieldCliente}
                                                    value={formCliente.PortalTitular}
                                                    type="text"
                                                    disabled={this.state.inputDisabled}

                                                />
                                            </FormGroup>
                                        </Col>

                                        <Col md="1">
                                            <FormGroup>
                                                <Label className="form-control-label" htmlFor="EscaleraTitular">
                                                    ESC.
                                    </Label>
                                                <Input
                                                    id="EscaleraTitular"
                                                    placeholder=""
                                                    name="EscaleraTitular"
                                                    maxLength={5}
                                                    onChange={this.onChangeFieldCliente}
                                                    value={formCliente.EscaleraTitular}
                                                    type="text"
                                                    disabled={this.state.inputDisabled}

                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md="1">
                                            <FormGroup>
                                                <Label className="form-control-label" htmlFor="PisoTitular">
                                                    Piso
                                    </Label>
                                                <Input
                                                    id="PisoTitular"
                                                    placeholder=""
                                                    name="PisoTitular"
                                                    maxLength={5}
                                                    onChange={this.onChangeFieldCliente}
                                                    value={formCliente.PisoTitular}
                                                    type="text"
                                                    disabled={this.state.inputDisabled}

                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md="1">
                                            <FormGroup>
                                                <Label className="form-control-label" htmlFor="PuertaTitular">
                                                    Puerta
                                    </Label>
                                                <Input
                                                    id="PuertaTitular"
                                                    placeholder=""
                                                    name="PuertaTitular"
                                                    maxLength={5}
                                                    onChange={this.onChangeFieldCliente}
                                                    value={formCliente.PuertaTitular}
                                                    type="text"
                                                    disabled={this.state.inputDisabled}

                                                />
                                            </FormGroup>
                                        </Col>

                                        <Col md="2">
                                            <FormGroup>
                                                <Label className="form-control-label" htmlFor="CodigoPostalTitular">
                                                    C.P. Titular
                                   </Label>
                                                <Input
                                                    id="CodigoPostalTitular"
                                                    placeholder=""
                                                    name="CodigoPostalTitular"
                                                    maxLength={5}
                                                    onChange={this.onChangeFieldCliente}
                                                    value={formCliente.CodigoPostalTitular}
                                                    type="text"
                                                    disabled={this.state.inputDisabled}

                                                />
                                                {this.state.CodigoPostalTitularError ? <FormText color="red">Por favor compruebe el código postal del titular</FormText> : ''}
                                            </FormGroup>
                                        </Col>

                                        <Col md="3">
                                            <FormGroup>
                                                <Label className="form-control-label">
                                                    Municipio
                                      </Label>
                                                <SipsOption
                                                    placeholder="Seleccionar localidad"
                                                    //                     invalid={forceUseLocality}
                                                    id="MunicipioTitular"
                                                    name="MunicipioTitular"
                                                    maxLength={60}
                                                    query="LIST_LOCALITIESNAME"
                                                    disabled={this.state.inputDisabled}
                                                    onChange={this.onChangeFieldCliente}
                                                    value={formCliente.MunicipioTitular}
                                                />
                                            </FormGroup>
                                        </Col>


                                        <Col md="2">
                                            <FormGroup>
                                                <Label className="form-control-label" htmlFor="ProvinciaTitular">
                                                    Provincia
                                    </Label>
                                                <Input
                                                    id="ProvinciaTitular"
                                                    placeholder=""
                                                    name="ProvinciaTitular"
                                                    maxLength={40}
                                                    onChange={this.onChangeFieldCliente}
                                                    value={formCliente.ProvinciaTitular}
                                                    type="text"
                                                    disabled={this.state.inputDisabled}

                                                />
                                            </FormGroup>
                                        </Col>

                                        <Col md="3">
                                            <FormGroup>
                                                <Label className="form-control-label" htmlFor="AutonomiaTitular">
                                                    C. Autónoma
                                    </Label>
                                                <Input
                                                    id="AutonomiaTitular"
                                                    placeholder=""
                                                    name="AutonomiaTitular"
                                                    maxLength={80}
                                                    onChange={this.onChangeFieldCliente}
                                                    value={formCliente.AutonomiaTitular}
                                                    type="text"
                                                    disabled={this.state.inputDisabled}

                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>



                                    <Row>
                                        <Col md="2">
                                            <FormGroup>

                                                <Label className="form-control-label" htmlFor="TelefonoFijo">
                                                    Teléfono Contacto
                                                </Label>
                                                <Input
                                                    className="text-primary"
                                                    id="TelefonoFijo"
                                                    placeholder=""
                                                    name="TelefonoFijo"
                                                    maxLength={20}
                                                    onChange={this.onChangeFieldCliente}
                                                    value={formCliente.TelefonoFijo}
                                                    type="text"
                                                    disabled={this.state.inputDisabled}
                                                />
                                            </FormGroup>
                                        </Col>

                                        <Col md="2">
                                            <FormGroup>

                                                <Label className="form-control-label" htmlFor="TelefonoMovil">
                                                    Teléfono Móvil
                                                </Label>
                                                <Input
                                                    className="text-primary"
                                                    id="TelefonoMovil"
                                                    placeholder=""
                                                    name="TelefonoMovil"
                                                    maxLength={20}
                                                    onChange={this.onChangeFieldCliente}
                                                    value={formCliente.TelefonoMovil}
                                                    type="text"
                                                    disabled={this.state.inputDisabled}
                                                />
                                            </FormGroup>
                                        </Col>

                                        <Col md="3">
                                            <FormGroup>

                                                <Label className="form-control-label" htmlFor="Email">
                                                    Correo Contacto
                                                </Label>
                                                <Input
                                                    id="Email"
                                                    placeholder=""
                                                    name="Email"
                                                    maxLength={80}
                                                    onChange={this.onChangeFieldCliente}
                                                    value={formCliente.Email}
                                                    type="text"
                                                    disabled={this.state.inputDisabled}

                                                />
                                            </FormGroup>
                                        </Col>

                                        <Col md="2">
                                            <FormGroup>

                                                <Label className="form-control-label" htmlFor="createdAt">
                                                    Fecha Alta Registro
                                                </Label>

                                                <DatePicker id="createdAt"
                                                    value={createdAt}
                                                    onChange={(v, f) => this.handleChangeDate(v, f, "createdAt")}
                                                    instanceCount={1}
                                                    dateFormat={"DD-MM-YYYY"}
                                                    dayLabels={spanishDayLabels}
                                                    monthLabels={spanishMonthLabels}
                                                    disabled={this.state.inputDisabled}
                                                />
                                            </FormGroup>

                                        </Col>
                                    </Row>





                                </CardBody>
                            </Card>

                            <Card className="shadow">
                                <CardBody>
                                    <div>
                                        <CardTitle>OTROS DATOS</CardTitle>
                                    </div>

                                    <Row>
                                        <Col md="2" lg="2">
                                            <FormGroup>
                                                <Label className="form-control-label" for="tipoverde">Cliente Activo</Label>
                                                <Input type="select" name="esActiva" id="esActiva"
                                                    disabled={this.state.inputDisabled}

                                                    onChange={this.onChangeFieldCliente}
                                                    value={formCliente.esActiva}>
                                                    <option selected>Selecciona...</option>
                                                    <option value="true">SI</option>
                                                    <option value="false">NO</option>
                                                </Input>
                                            </FormGroup>
                                        </Col>

                                    </Row>
                                    <Row>

                                        <Col md="12" lg="4">
                                            <FormGroup>
                                                <Label
                                                    className="form-control-label"
                                                    htmlFor="CNAE"
                                                >
                                                    CNAE
                                                    </Label>
                                                <SipsOption
                                                    placeholder="Seleccionar Código CNAE"
                                                    id="CNAE"
                                                    name="CNAE"
                                                    value={this.state.cnaeAux}
                                                    query="LIST_CNAE"
                                                    onChange={this.onChangeFieldCliente}
                                                    disabled={this.state.inputDisabled}

                                                />
                                            </FormGroup>
                                        </Col>

                                        <Col md="3">
                                            <FormGroup>

                                                <Label className="form-control-label" htmlFor="actividadCNAE">
                                                    Actividad CNAE
                                                    </Label>
                                                <Input
                                                    id="actividadCNAE"
                                                    placeholder=""
                                                    name="actividadCNAE"
                                                    onChange={this.onChangeFieldCliente}
                                                    value={formCliente.actividadCNAE}
                                                    type="text"
                                                    disabled={this.state.inputDisabled}

                                                />
                                            </FormGroup>
                                        </Col>

                                        <Col md="5">
                                            <Label className="form-control-label" htmlFor="IBAN">
                                                IBAN
                                          </Label>
                                            <Input
                                                id="IBAN"
                                                placeholder=""
                                                name="IBAN"
                                                maxLength={40}
                                                onChange={this.onChangeFieldCliente}
                                                value={formCliente.IBAN}
                                                disabled={this.state.inputDisabled}

                                                type="text"
                                            />
                                            {this.state.IBANError ? <FormText color="red">Comprobar IBAN</FormText> : ''}

                                        </Col>

                                        <Col md="2">
                                            <Label className="form-control-label" htmlFor="descBanco">
                                                ENTIDAD BANCARIA
                                          </Label>
                                            <Input
                                                id="descBanco"
                                                placeholder=""
                                                name="descBanco"
                                                maxLength={100}
                                                onChange={this.onChangeFieldCliente}
                                                value={formCliente.descBanco}
                                                type="text"
                                                disabled={this.state.inputDisabled}

                                            />
                                        </Col>





                                    </Row>

                                </CardBody>
                            </Card>
    {this.state.alert}

                        </div>
                    </Row>
                </Container>

            </>
        );
    } else {
      return (
        <>
          <Header title={"No tienes permisos para acceder a este apartado. Consulte con nuestro equipo comercial"}/>
        </>
      );
    }
    }
}


export default withApollo(Cliente);
