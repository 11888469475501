import React from "react";

// reactstrap components
import {
  Card,
  //CardBody,
  CardHeader,
  Container,
  Col,
  Row,
  //Table,
} from "reactstrap";
//import { Query } from "react-apollo";
//import { Pie } from "react-chartjs-2";
//import graphql2chartjs from "graphql2chartjs";

import Header from "../components/Headers/StatisticHeader";
import GetUserData from "../components/User/GetUserData";
//import {
//  GET_COMERCIAL_RATE,
//  GET_COMERCIAL_RATE_CHART,
//} from "../queries/comercial/rate";
//import numberWithPoints from "../utils/numberWithPoints";
//import { chartOptions } from "../variables/charts";

class Index extends React.Component {
  state = {
    activeNav: 1,
    chartExample1Data: "data1",
  };
  toggleNavs = (e, index) => {
    e.preventDefault();
    this.setState({
      activeNav: index,
      chartExample1Data:
        this.state.chartExample1Data === "data1" ? "data2" : "data1",
    });
    let wow = () => {
      console.log(this.state);
    };
    wow.bind(this);
    setTimeout(() => wow(), 1000);
  };
  render() {
    return (
       <>
        <GetUserData></GetUserData>
        <Header />
        <Container className="mt--7" fluid>
          <Row className="mb-5">
            <Col className="mb-5 mb-xl-0" xl="12">
              <Card className="shadow">

              </Card>
            </Col>
            <Col className="mb-5 mb-xl-0 d-none" xl="4">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <div className="col">
                      <h3 className="mb-0">Tarifas/Cups</h3>
                    </div>
                    <div className="col text-right"></div>
                  </Row>
                </CardHeader>

              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default Index;
