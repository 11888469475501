import gql from "graphql-tag";

export const CREATE_SIPS_COMPARATIVAS = gql`
mutation createComparativa( $data: ComparativaInput! ) {
    createComparativa( data: $data ){
      companyId
      nombreOferta
      idComercializadora
      nombreComercializadora
      userId
      fechaPropuesta
      codigoPostalPS
      codigoMunicipioPS
      nombreMunicipioPS
      codigoProvinciaPS
      nombreProvinciaPS
      codigoAutonomiaPS
      nombreAutonomiaPS
      correoContacto
      cups
      datosPropuesta
      esAprobada
    }
    }
`;


export const LIST_SIPS_COMPARATIVAS_BY_CUP = gql`
query comparativasByCups( $cups: String! ) {
    comparativasByCups( cups: $cups ){
      id
      companyId
      nombreOferta
      idComercializadora
      nombreComercializadora
      userId
      fechaPropuesta
      codigoPostalPS
      codigoMunicipioPS
      nombreMunicipioPS
      codigoProvinciaPS
      nombreProvinciaPS
      codigoAutonomiaPS
      nombreAutonomiaPS
      correoContacto
      cups
      datosPropuesta
      esAprobada
    }
    }
`;


export const DATA_SIPS_COMPARATIVA_BY_ID = gql`
query comparativaData( $id: Int! ) {
    comparativaData( id: $id ){
      id
      companyId
      nombreOferta
      idComercializadora
      nombreComercializadora
      userId
      fechaPropuesta
      codigoPostalPS
      codigoMunicipioPS
      nombreMunicipioPS
      codigoProvinciaPS
      nombreProvinciaPS
      codigoAutonomiaPS
      nombreAutonomiaPS
      correoContacto
      cups
      datosPropuesta
      esAprobada
    }
    }
`;


